<template>
    <div class="d-flex h-100 overflow-auto flex-column">
        <FormHeader :form-id="formId" :show-add-btn="true" :show-save-and-close-btn="true" :show-save-btn="true"/>
        <FormNavAndForm :form-id="formId" :show-archive-btn="true" :show-duplicate-btn="true" :show-history-btn="true"/>
    </div>
</template>

<script>
import FormHeader from '../../common/form/FormHeader'
import FormNavAndForm from '../../common/form/FormNavAndForm'

export default {
    name: "Edit",
    components: {
        FormHeader,
        FormNavAndForm,
    },
    data() {
        return {
            formId: undefined,
        }
    },
    computed: {
        tableName() {
            if (this.$route.name === 'pageContent') {
                return 'content'
            }

            return this.$route.params.component
        },
        id() {
            if (this.$route.name === 'pageContent') {
                const pageContentId = parseInt(this.$route.params.pageContentId)
                const pageContentItem = this.$store.state.pageContentData.items.find(o => o.id === pageContentId)
                return pageContentItem.contentId
            }

            return parseInt(this.$route.params.id)
        }
    },
    created() {
        this.init()
    },
    watch: {
        id() {
            this.init()
        }
    },
    methods: {
        init() {
            this.formId = 'form:' + this.tableName + ':' + this.id

            this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.id
            })

            this.setCmsDataNavigationComponent()
        },
        setCmsDataNavigationComponent() {
            let navigationComponent = (
                this.tableName
                && this.tableName.indexOf('product_variations__') === 0
            )
                ? 'm_products_products'
                : this.tableName

            this.$store.commit('navigationComponent', navigationComponent)
        }
    }
}
</script>

<style scoped>

</style>