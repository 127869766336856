var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "dropdown", staticClass: "dropdown" },
    [
      _c(
        "Button",
        {
          staticClass: "h-100 rounded-0",
          class: { active: _vm.isActive },
          attrs: { "data-bs-toggle": "dropdown", title: "Settings" },
        },
        [
          _vm.user
            ? _c("UserAvatar", {
                staticClass: "navigation-user-avatar",
                attrs: { "user-id": _vm.user.id },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown-menu dropdown-menu-end" },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "user-access",
                  rawName: "v-user-access",
                  value: "account_settings",
                  expression: "'account_settings'",
                },
              ],
              staticClass: "dropdown-item",
              class: { active: this.$route.path === "/settings" },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.goTo("settings")
                },
              },
            },
            [_vm._v("\n            Settings\n        ")]
          ),
          _vm._v(" "),
          _vm.subscriptionNavItemEnabled()
            ? _c(
                "button",
                {
                  staticClass: "dropdown-item",
                  class: { active: this.$route.path === "/subscription-plan" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.goTo("subscription-plan")
                    },
                  },
                },
                [_vm._v("\n            Subscription plan\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "user-access",
                  rawName: "v-user-access",
                  value: "users",
                  expression: "'users'",
                },
              ],
              staticClass: "dropdown-item",
              class: { active: this.$route.path === "/users" },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.goTo("users")
                },
              },
            },
            [_vm._v("\n            Manage users\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "user-access",
                  rawName: "v-user-access",
                  value: "users_groups",
                  expression: "'users_groups'",
                },
              ],
              staticClass: "dropdown-item",
              class: { active: this.$route.path === "/users_groups" },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.goTo("users_groups")
                },
              },
            },
            [_vm._v("\n            Manage roles\n        ")]
          ),
          _vm._v(" "),
          _vm.reportsLink
            ? _c(
                "router-link",
                {
                  staticClass: "dropdown-item",
                  attrs: { to: { name: "reports" } },
                },
                [_vm._v("\n            Reports\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "dropdown-item clear-the-cache-item d-flex align-items-center",
              class: {
                "clear-the-cache-item-active":
                  this.$store.state.cacheNeedsClearing,
              },
              attrs: { type: "button" },
              on: { click: _vm.clearTheCache },
            },
            [_c("span", { staticClass: "me-2" }, [_vm._v("Clear the cache")])]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "dropdown-item d-flex align-items-center",
              attrs: { type: "button" },
              on: { click: _vm.toggleDarkMode },
            },
            [
              _c("span", { staticClass: "me-2" }, [
                _vm._v(_vm._s(_vm.darkMode ? "Light mode" : "Dark mode")),
              ]),
              _vm._v(" "),
              _c(
                "small",
                { staticClass: "badge text-bg-warning fw-normal p-1" },
                [_vm._v("beta")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "dropdown-item d-flex align-items-center",
              attrs: { type: "button" },
              on: { click: _vm.logout },
            },
            [
              _c("span", { staticClass: "me-2 bi-box-arrow-right" }),
              _vm._v("\n            Log out\n        "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }