export default {
    namespaced: true,
    state: {
        componentId: 0,
        component: {},
        fieldsets: [],
        fieldsetFormData: {},
        activeFieldsetId: 0,
        items: [],
        getModifiedData: undefined,
        
        // Component fields only
        activeEditId: undefined
    },
    mutations: {
        fieldsets(state, fieldsets) {
            state.fieldsets = JSON.parse(JSON.stringify(fieldsets))
        },
        activeFieldsetId(state, activeFieldsetId) {
            state.activeFieldsetId = activeFieldsetId
        },
        items(state, items) {
            state.items = JSON.parse(JSON.stringify(items))
        },
        componentId(state, componentId) {
            state.componentId = componentId
        },
        component(state, component) {
            state.component = component
        },
        
        // START: Component fields only
        
        activeEditId(state, activeEditId) {
            state.activeEditId = activeEditId || 0
        },
        reset(state) {
            state.items = []
            state.activeEditId = undefined
        }
        
        // END: Component fields only
        
    },
    getters: {
        isForm: (state) => {
            return state.component.navigationGroup && state.component.navigationGroup === 'Forms'
        },
        isContentType: (state) => {
            return state.component.tableName && state.component.tableName.indexOf('component_') === 0
        },
        // Convert strings to camelcase and removes special chars
        toCamelCase: () => (str) => {
            str = str.replace(/[^a-zA-Z 0-9]+/g, '') // Remove all special characters
            str = str.substring(0, 1).toLowerCase() + str.substring(1) // First character to lowercase
            // Replace all double spaces left by removing special chars
            while (str.match('  ')) {
                str = str.replace('  ', ' ')
            }
            
            return str.replace(/ (.)/g, (match, group1) => {
                return group1.toUpperCase()
            })
        },
        getFields: (state, getters) => (type) => {
            
            const fields = [
                'title', // Label
                'columnName',
                
                // The fields list group displays type icons, removing the need to show the type in the form.
                //'type',
            ]
            
            // Create attribute forms
            if (state.activeEditId === 0) {
                return [
                    ...fields,
                    'type'
                ]
            }
            
            switch (type) {
                case 'checkbox':
                    return [
                        ...fields,
                        'checked',
                        'readonly',
                        'required',
                        'helpText',
                    ]
                
                case 'dateDate':
                case 'datetime-local':
                case 'time':
                    return [
                        ...fields,
                        // These cannot be support using the system's current min field because the date's
                        //  min requires a yyyy-mm-dd value.
                        //'max',
                        //'min',
                        'readonly',
                        'required',
                        'step',
                        'helpText',
                    ]
                
                case 'email':
                case 'tel':
                case 'url':
                    return [
                        ...fields,
                        'placeholder',
                        'readonly',
                        'required',
                        'uniqueValuesOnly',
                        'helpText',
                    ]
                
                case 'password':
                case 'text':
                    return [
                        ...fields,
                        'placeholder',
                        'minlength',
                        'maxlength',
                        'pattern',
                        'readonly',
                        'required',
                        'size',
                        'uniqueValuesOnly',
                        'helpText',
                    ]
                
                case 'file':
                case 'image':
                    return [
                        ...fields,
                        'required',
                        'helpText',
                    ]
                
                case 'internalLink':
                    return [
                        ...fields,
                        'targetContent',
                        'helpText',
                    ]
                
                case 'number':
                    return [
                        ...fields,
                        'min',
                        'max',
                        'step',
                        'readonly',
                        'required',
                        'defaultValue',
                        'helpText',
                    ]
                
                case 'range':
                    return [
                        ...fields,
                        'min',
                        'max',
                        'step',
                        'helpText',
                    ]
                
                case 'relationshipOneToMany':
                case 'relationshipManyToMany':
                    return [
                        ...fields,
                        'categoryComponentId',
                        'required',
                        'helpText',
                    ]
                
                case 'select':
                    return [
                        ...fields,
                        'firstOption',
                        'options',
                        'readonly',
                        'required',
                        //'disabled',
                        //'multiple', // todo
                        //'size', // Requires multiple.
                        'helpText',
                    ]
                
                case 'textarea':
                    return [
                        ...fields,
                        'placeholder',
                        'readonly',
                        'required',
                        'minlength',
                        'maxlength',
                        'rows',
                        'cols',
                        'wrap',
                        'helpText',
                    ]
                
                case 'texteditor':
                    return [
                        ...fields,
                        'readonly',
                        'required',
                        'helpText',
                    ]
                
                default:
                    return fields
            }
        }
    },
    actions: {
        init({state, commit, getters, dispatch}, {componentId}) {
            // Reset state
            commit('items', [])
            
            commit('fieldsets', [])
            
            commit('componentId', componentId)
            
            dispatch('setComponent')
            dispatch('setFieldsets')
            dispatch('setItems')
        },
        setComponent({state, commit, rootState}) {
            let component = rootState.components.items.find(o => o.id === state.componentId)
            commit('component', component)
        },
        setFieldsets({state, commit, getters, dispatch, rootState}) {
            const fieldsets = rootState.fieldsets.items.filter(o => o.componentId === state.componentId)
            commit('fieldsets', fieldsets)
        },
        setItems({state, commit, getters, dispatch, rootState}) {
            const params = {
                modulecomponentsID: state.componentId,
                sort: 'displayOrder',
            }
            
            if (!rootState.alphaMode) {
                params.hidden = 0
            }
            
            if (state.activeFieldsetId) {
                params.fieldsetId = state.activeFieldsetId
            }
            
            dispatch('request/get', {
                url: 'api/component/modulestructure',
                params: params
            }, {root: true})
                .then((obj) => {
                    let items = obj.data.items
                    
                    // KC broke a CT when he was in betaMode, so it's best to never show these fields.
                    if (!rootState.alphaMode) {
                        if (getters.isContentType) {
                            items = items.filter(obj => obj.columnName !== 'contentId')
                        } else if (getters.isForm) {
                            items = items.filter(obj => obj.columnName !== 'emailMessageId')
                        }
                    }
                    
                    commit('items', items)
                })
        },
        deleteField({state, commit, getters, dispatch}, {fieldId, router}) {
            dispatch('request/delete', {
                url: 'api/component/modulestructure/' + fieldId
            }, {root: true})
                .then(() => {
                    
                    // Get the id of the item being deleted
                    const i = state.items.map(o => o.id).indexOf(state.activeEditId)
                    
                    // Remove the item from state/the UI
                    state.items.splice(i, 1)
                    
                    // Select the item before or after that delete
                    const item = state.items[i - 1] || state.items[i]
                    if (item) {
                        commit('activeEditId', item.id)
                    }
                    
                    // If this not the page component editor it must update the path
                    if (!getters.isContentType) {
                        // Removing the deleted fieldId param roots to /fields/{fieldsetId}
                        router.push({params: {fieldId: null}})
                    }
                })
        },
        reloadFieldsets({dispatch}) {
            return dispatch('fieldsets/init', null, {root: true})
                .then(() => {
                    return dispatch('setFieldsets')
                })
        }
    },
}