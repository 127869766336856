var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Tags")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("TagsForm", {
            attrs: { id: _vm.options.id, "table-name": _vm.options.tableName },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }