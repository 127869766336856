var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c("span", { staticClass: "table-cell-hidden-icon-parent" }, [
        _c("small", [
          _vm.value ? _c("code", [_vm._v(_vm._s(_vm.value))]) : _vm._e(),
        ]),
        _vm._v(" "),
        !_vm.item.regex && _vm.value
          ? _c("a", {
              staticClass: "bi-box-arrow-up-right table-cell-hidden-icon",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openLink.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }