<template>
    <div class="card">
        <div class="card-body">
            <table class="table table-sm mb-0">
                <thead>
                <tr>
                    <th v-for="column in columns" :key="column.id">{{ column.title }}</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td v-for="column in columns" v-if="viewId" :key="column.id">
                        <TableCell :id="item.id" :column-id="column.id" :view-id="viewId"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import view from '../../store/modules/view'

import TableCell from '../../common/table-cell/TableCell'

export default {
    name: "FormControlTypeTable",
    components: {
        TableCell,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        viewId() {
            let viewId = 'view:' + this.tableName
            // As users move to and from listings we only want to register modules which don't yet exist, so as to
            // preserve the user's settings for each module.
            if (!this.$store.hasModule(viewId)) {
                this.$store.registerModule(viewId, view)
            }
            this.$store.dispatch(viewId + '/init', {
                listingName: this.tableName,
            })

            return viewId
        },
        column() {
            let items = this.$store.state.componentStructure.items
            return items.filter(obj => obj.id === this.field.tableTargetColumn)[0]
        },
        componentId() {
            return this.column.modulecomponentsID
        },
        table() {
            let items = this.$store.state.components.items
            return items.filter(obj => obj.id === this.componentId)[0]
        },
        tableName() {
            return this.table.tableName
        },
        columns() {
            let columns = []
            let items = this.$store.getters['componentStructure/get'](this.componentId)
            items.forEach((item) => {
                if (item.browser) {
                    columns.push(item)
                }
            })
            return columns
        }
    },
    asyncComputed: {
        items() {
            return this.$store.dispatch('request/get', {
                url: 'api/component/' + this.tableName,
                params: {
                    // E.g. orderId: 123
                    [this.column.columnName]: this.currentData.id
                }
            }).then((obj) => {
                return obj.data.items
            })
        }
    }
}
</script>

<style scoped>
.table {
    font-size: .8rem;
}
</style>