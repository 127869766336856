<template>
    <div v-if="items" class="toast-container position-fixed top-0 end-0 p-3">
        <Toast
            v-for="(item, index) in items"
            :key="index"
            :title="item.title"
            :body="item.body"
            :disable-auto-hide="item.disableAutoHide"
            :percentage="item.percentage"
            :colour-scheme="item.colourScheme"
            :created-by="item.createdBy"
            :download-url="item.downloadUrl"
        />
    </div>
</template>

<script>
import Toast from "./Toast"

export default {
    name: "Toasts",
    components: {
        Toast
    },
    computed: {
        items() {
            return this.$store.state.toasts.items
        }
    }
}
</script>

<style scoped>

</style>