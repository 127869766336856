var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.count
    ? _c(
        "span",
        {
          staticClass: "badge bg-secondary",
          attrs: {
            title:
              "Assigned to " +
              _vm.count +
              " item" +
              (_vm.count > 1 ? "s" : "") +
              ".",
          },
        },
        [_vm._v(_vm._s(_vm.count))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }