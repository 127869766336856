<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add item</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <Form :id="id" :form-id="formId" :show-legends="true"/>

                <footer class="text-center">
                    <FormSaveBtn :button-text="'Save'" :form-id="formId"/>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import Form from '../../common/form/Form'
import FormSaveBtn from '../../common/form/FormSaveBtn'

export default {
    name: "AddItemModal",
    components: {
        Form,
        FormSaveBtn,
    },
    props: {
        modal: Object,
        options: Object,
    },
    data() {
        return {
            formId: '',
            tableName: this.options.tableName,
            id: 0,
            onAddItem: this.options.onAddItem,
        }
    },
    created() {
        this.formId = 'form:' + this.tableName + ':' + this.id

        this.modal.formId = this.formId

        this.$store.dispatch('forms/createForm', {
            tableName: this.tableName,
            id: this.id,
            onSave: (obj) => {
                this.$emit('hide-modal')

                if (this.onAddItem) {
                    this.onAddItem({
                        id: obj.id
                    })
                }
            },
        })
    }
}
</script>

<style scoped>

</style>