<template>
    <Button @click.native="toggle" class="rounded-0"><i class="bi" :class="iconClass"></i></Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbarShowBtn",
    components: {Button},
    computed: {
        showEmptyContentAreas() {
            return this.$store.state.pageEditor.showEmptyContentAreas
        },
        iconClass() {
            return this.showEmptyContentAreas ? 'bi-eye-fill text-danger' : 'bi-eye'
        }
    },
    methods: {
        toggle() {
            this.$store.state.pageEditor.showEmptyContentAreas = !this.$store.state.pageEditor.showEmptyContentAreas
        }
    }
}
</script>

<style scoped>

</style>