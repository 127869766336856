<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Notes</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                        <textarea
                            v-model="row.notes"
                            placeholder="Type here…"
                            rows="10"
                            style="padding: 15px; width: 100%; font-size: 18px; background: #fff; border: none;"
                            @change="saveNotes"
                        />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShowNotesModal",
    props: {
        options: Object,
    },
    data() {
        return {
            row: this.options.row,
        }
    },
    methods: {
        saveNotes() {
            this.$store.dispatch('itemData/update', {
                tableName: 'courses_bookings',
                id: this.row.id,
                data: {
                    notes: this.row.notes
                }
            })
        }
    },
}

</script>

<style scoped>

</style>