<template>
    <i v-if="value" class="bi-check-lg"/>
</template>

<script>
export default {
    name: "TableCellTypeCheckbox",
    props: {
        value: Number,
    }
}
</script>

<style scoped>

</style>