var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userData
    ? _c(
        "span",
        {
          ref: "tooltip",
          staticClass: "user-avatar",
          class: {
            "user-avatar-with-text": _vm.initials,
            "user-avatar-sm": _vm.isSmall,
          },
          attrs: {
            "data-bs-placement": _vm.tooltipPlacement,
            title: _vm.userData.displayName,
            "data-bs-toggle": "tooltip",
          },
        },
        [
          _vm.imgSrc
            ? _c("img", { attrs: { src: _vm.imgSrc, alt: "" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.initials ? _c("span", [_vm._v(_vm._s(_vm.initials))]) : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }