export default {
    inserted: function(el, binding, vnode, old) {
        
        // Only select if v-select is *not* set with a value or if the value is true.
        if (
            binding.value === undefined
            || binding.value === true
        ) {
            el.select()
        }
    }
}