<template>
    <div class="d-flex align-items-center">
        <button class="btn btn-sm btn-light" type="button" @click="showModal()">
            View old
        </button>
        <i class="mx-2 bi-caret-right-fill"/>
        <button class="btn btn-sm btn-light" type="button" @click="showModal(true)">
            View new
        </button>
    </div>
</template>

<script>
export default {
    name: "ItemHistoryChangeTypeCodeEditor",
    props: {
        oldValue: String,
        newValue: String,
        mode: String,
        label: String,
    },
    methods: {
        showModal(viewNew) {
            this.$store.dispatch('modals/show', {
                componentName: 'HistoryCodeEditorModal',
                obj: {
                    value: viewNew ? this.newValue : this.oldValue,
                    mode: this.mode,
                    label: this.label
                }
            })
        }
    }
}
</script>

<style scoped>

</style>