<template>
    <div class="pt-4 px-3 d-flex h-100 overflow-auto">
        <div class="row d-flex h-100 overflow-auto flex-fill">
            <template v-if="hasFieldsets">
                <div class="col-3 col-lg-2 pb-3 overflow-auto h-100">
                    <ContentTypeFieldsetsList/>
                    <ContentTypeNewFieldsetBtn/>
                    <ContentTypeFieldsetOffcanvas/>
                </div>

                <div v-if="fieldsetId" class="col-3 col-lg-2 pb-3 overflow-auto h-100">
                    <ContentTypeFieldsList/>
                    <ContentTypeNewAttributeBtn/>
                </div>

                <div class="col-6 col-lg-8 overflow-auto h-100">
                    <ContentTypeFieldEditForm v-if="activeEditId >= 0"/>
                </div>
            </template>

            <template v-else>
                <div class="col-sm-3 overflow-auto h-100">
                    <ContentTypeFieldsList/>
                    <ContentTypeNewAttributeBtn/>
                </div>

                <div class="col-sm-9 overflow-auto h-100">
                    <ContentTypeFieldEditForm v-if="activeEditId >= 0"/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import ContentTypeFieldEditForm from '../../common/content-type/ContentTypeFieldEditForm'
import ContentTypeNewAttributeBtn from '../../common/content-type/ContentTypeNewAttributeBtn'
import ContentTypeFieldsList from '../../common/content-type/ContentTypeFieldsList'
import ContentTypeFieldsetsList from "../../common/content-type/ContentTypeFieldsetsList.vue"
import ContentTypeNewFieldsetBtn from "../../common/content-type/ContentTypeNewFieldsetBtn.vue"
import ContentTypeFieldsetOffcanvas from "../../common/content-type/ContentTypeFieldsetOffcanvas.vue"

export default {
    name: "ComponentFields",
    components: {
        ContentTypeFieldsetOffcanvas,
        ContentTypeNewFieldsetBtn,
        ContentTypeFieldsetsList,
        ContentTypeFieldEditForm,
        ContentTypeFieldsList,
        ContentTypeNewAttributeBtn,
    },
    computed: {
        fieldsetId() {
            return parseInt(this.$route.params.fieldsetId)
        },
        fieldId() {
            return parseInt(this.$route.params.fieldId)
        },
        tableName() {
            return this.$route.params.component
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        activeEditId() {
            return this.$store.state.componentFields.activeEditId
        },
        hasFieldsets() {
            return this.$store.state.fieldsets.items.length
        },
        activeFieldsetId() {
            return this.$store.state.componentFields.activeFieldsetId
        }
    },
    watch: {
        fieldsetId: {
            immediate: true,
            handler(newValue) {
                this.$store.state.componentFields.activeFieldsetId = newValue
            }
        },
        fieldId: {
            immediate: true,
            handler(newValue) {
                this.$store.state.componentFields.activeEditId = newValue
            }
        },
        // Required when jumping between component's attributes e.g. /foo/attributes -> /bar/attributes
        componentId: {
            immediate: true,
            handler(newValue) {
                this.$store.dispatch('componentFields/init', {
                    componentId: newValue
                })
            }
        },
        activeFieldsetId(newValue) {
            this.$store.dispatch('componentFields/init', {
                componentId: this.componentId
            })
        }
    },
    destroyed() {
        this.$store.commit('componentFields/reset')
    }
}
</script>

<style scoped>
.ghost {
    background: #666;
    border-color: #666;
    color: #fff;
}
</style>