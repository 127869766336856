<template>
    <Button class="h-100 rounded-0" title="Show help" @click.native="openSupportModal">
        <i class="bi-life-preserver"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "NavigationSupportBtn",
    components: {Button},
    methods: {
        openSupportModal() {
            if (this.$store.state.alphaMode) {
                this.$store.commit('showSupport', true)
            } else {
                this.$store.dispatch('modals/show', {
                    componentName: 'ZendeskModal',
                })
            }
        }
    }
}
</script>

<style scoped>

</style>