var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formData.franchiseId,
          expression: "formData.franchiseId",
        },
      ],
      staticClass: "ms-2 form-select form-select-sm",
      attrs: { name: "franchiseId", required: "" },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.$set(
            _vm.formData,
            "franchiseId",
            $event.target.multiple ? $$selectedVal : $$selectedVal[0]
          )
        },
      },
    },
    [
      _vm.allowAll
        ? _c("option", { domProps: { value: 0 } }, [_vm._v("- All -")])
        : _c("option", { attrs: { value: "", selected: "", disabled: "" } }, [
            _vm._v("Select…"),
          ]),
      _vm._v(" "),
      _vm._l(_vm.franchises, function (franchise) {
        return _vm.franchises
          ? _c(
              "option",
              { key: franchise.id, domProps: { value: franchise.id } },
              [_vm._v("\n        " + _vm._s(franchise.title) + "\n    ")]
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }