var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm.items
        ? _c(
            "div",
            { staticClass: "mb-2 list-group" },
            _vm._l(_vm.items, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "list-group-item px-2" },
                [
                  _c("CollectionFilter", {
                    attrs: { "content-id": _vm.contentId, item: item },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-light",
          attrs: { type: "button" },
          on: { click: _vm.addFilter },
        },
        [_c("i", { staticClass: "bi-plus-lg" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }