var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c("span", { staticClass: "table-cell-hidden-icon-parent" }, [
        _vm._v("\n    " + _vm._s(_vm.value) + "\n    "),
        _c("a", {
          staticClass: "bi-telephone table-cell-hidden-icon",
          attrs: { href: "tel:" + _vm.value },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }