<template>
    <table class="table table-condensed">
        <thead>
        <tr>
            <td class="col-xs-7"></td>
            <td v-for="option in options" :key="option.id" class="col-xs-1">
                {{ option.title }}
            </td>
        </tr>
        </thead>

        <tbody>
        <tr v-for="component in groupedComponents" :key="component.id">
            <th class="roles-component" @click="toggleAllCheckboxes">
                {{ component.title }}
            </th>

            <td v-for="option in options" :key="option.id">
                <FormControlUsersGroupsRolesCheckbox :component="component" :form-id="formId" :option="option"
                                                     :roles-group="rolesGroup"/>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import FormControlUsersGroupsRolesCheckbox from './FormControlUsersGroupsRolesCheckbox'

export default {
    name: "FormControlUsersGroupsRolesGroup",
    components: {
        FormControlUsersGroupsRolesCheckbox
    },
    props: {
        formId: String,
        rolesGroup: String,
    },
    computed: {
        options() {
            return this.$store.state.userRoles.options
        },
        groupedComponents() {
            return this.$store.state.components.items.filter(obj => obj.roles && obj.rolesGroup === this.rolesGroup)
        }
    },
    methods: {
        toggleAllCheckboxes(e) {
            let parent = e.target.parentNode
            let checkboxes = parent.querySelectorAll('input[type="checkbox"]:not(:checked)')
            if (checkboxes.length === 0) {
                checkboxes = parent.querySelectorAll('input[type="checkbox"]')
            }
            checkboxes.forEach(checkbox => checkbox.click())
        }
    }
}
</script>

<style scoped>

</style>