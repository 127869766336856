<template>

</template>

<script>
var beautify_js = require('js-beautify').js_beautify

export default {
    name: "ItemHistoryChangeTypeJson",
    props: {
        oldValue: String,
        newValue: String
    },
    created() {
        if (this.oldValue) {
            // Some legacy data was stored as an object.
            let value = typeof this.oldValue === 'string' ? this.oldValue : JSON.stringify(this.oldValue)

            value = beautify_js(value, {indent_size: 2})

            console.warn("ItemHistoryChangeTypeJson's CodeMirror not configured")
            /*
            CodeMirror($element.get(0), {
                value: value,
                //matchBrackets: true,
                //autoCloseBrackets: true,
                mode: "application/ld+json",
                //lineNumbers: true,
                lineWrapping: true,
                readOnly: true,
                indentUnit: 4,
                //tabMode: 'spaces',
                //enterMode: 'indent',
                //electricChars: false//, // Prevents '{' from resetting the indent
                //viewportMargin: 'Infinity'
            });
             */
        }
    }
}
</script>

<style scoped>

</style>