<template>
    <div class="d-inline">
        <Button v-if="show" class="rounded-0" data-bs-toggle="offcanvas" @click.native="onClick"
                data-bs-target="#offcanvasSiteTree" aria-controls="offcanvasSiteTree">
            <i class="bi-diagram-3-fill"/>
        </Button>

        <div ref="offcanvas" id="offcanvasSiteTree" aria-labelledby="offcanvasSiteTreeLabel"
             class="offcanvas offcanvas-start" tabindex="-1">
            <div class="offcanvas-header">
                <SiteTreeNavigationMenu :site-tree-id="siteTreeId" class="w-auto"/>
                <button ref="closeBtn" type="button" class="btn-close" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>

            <div class="offcanvas-body">
                <SiteTree :select-content="selectContent" :select-page="selectPage" :site-tree-id="siteTreeId"/>
            </div>
        </div>
    </div>
</template>

<script>
import SiteTreeNavigationMenu from "./site-tree/SiteTreeNavigationMenu.vue"
import siteTree from "../store/modules/siteTree"
import SiteTree from "./site-tree/SiteTree.vue"
import Button from "./Button.vue"

export default {
    name: "ShowSiteTreeBtn",
    components: {
        Button,
        SiteTree,
        SiteTreeNavigationMenu
    },
    props: {
        formId: String,
        viewId: String,
        pageEditor: Boolean,
        onPageSelect: Function
    },
    computed: {
        isSiteTree() {
            return this.$route.path.split('/')[1] === 'site_tree'
        },
        show() {
            return (
                (
                    this.tableName === 'pages'
                    || this.tableName === 'content'
                    || this.pageEditor
                )
                && (
                    !this.isSiteTree
                    || !this.$store.state.sidebarToggle['site_treeLeft']
                )
            )
        },
        tableName() {
            return this.formId
                ? this.$store.state[this.formId].tableName
                : this.viewId
                    ? this.$store.state[this.viewId].tableName
                    : ''
        },
        id() {
            if (this.formId) {
                return this.$store.state[this.formId].id
            } else if (this.viewId) {
                let length = this.$store.state[this.viewId].selectedIds.length
                return this.$store.state[this.viewId].selectedIds[length - 1]
            }
        }
    },
    created() {
        this.siteTreeId = 'siteTreeModal'
        if (this.$store.hasModule(this.siteTreeId)) {
            this.$store.unregisterModule(this.siteTreeId)
        }
        this.$store.registerModule(this.siteTreeId, siteTree)
        this.$store.dispatch(this.siteTreeId + '/init')
    },
    methods: {
        hide() {
            this.$refs.closeBtn.click()
        },
        selectPage(pageId) {
            if (this.onPageSelect) {
                this.onPageSelect({
                    pageId: pageId
                })
            } else if (this.onSelect) {
                this.onSelect({
                    pageId: pageId
                })
            }

            this.hide()
        },
        // todo - This is not in use. The ShowSiteTreeBtn is only used for navigating pages.
        selectContent(contentId) {
            const pageContentLinkItem = this.$store.state.pageContentData.items.find(o => o.id === contentId)

            this.onSelect({
                pageContentLinkId: contentId,
                contentId: pageContentLinkItem.contentId
            })

            this.hide()
        },
        onSelect(obj) {
            if (this.isSiteTree) {
                if (obj.pageId) {
                    window.location = '/#/site_tree/' + obj.pageId
                } else if (obj.contentId) {
                    window.location = '/#/site_tree/c' + obj.pageContentLinkId
                }
            } else {
                if (obj.pageId) {
                    window.location = '/#/pages/' + obj.pageId
                } else if (obj.contentId) {
                    window.location = '/#/content/' + obj.contentId
                }
            }
        },
        onClick() {
            let pageId = 0
            let pageContentLinkId = 0

            if (this.tableName === 'pages') {
                pageId = this.id
            } else if (this.isSiteTree) {
                pageContentLinkId = parseInt(this.$route.params.pageOrContentId.substring(1))
            }

            if (pageId) {
                this.$store.dispatch(this.siteTreeId + '/setPageId', pageId)
                this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', pageId)
            } else if (pageContentLinkId) {
                this.$store.dispatch(this.siteTreeId + '/setContentId', pageContentLinkId)
                const pageContentLinkItem = this.$store.state.pageContentData.items.find(o => o.id === pageContentLinkId)
                pageId = pageContentLinkItem.pageId
                this.$store.commit(this.siteTreeId + '/addExpandedPageId', pageId)
                this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', pageId)
            }
        }
    }
}
</script>

<style scoped>

</style>