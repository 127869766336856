<template>
    <div ref="container">
        <!--
          This hidden field is used to apply "required" validation to the buttons. When one or more buttons are
          selected the input is populated, and so the field passes the required validation.
          Required fields cannot be hidden, otherwise the browser's built in validation fails and generates a "An
          invalid form control with name='' is not focusable." error. So using the following style fixes it.
        -->
        <input
            v-model="inputValue"
            :name="field.name"
            required
            style=""
            tabindex="-1"
        >

        <button
            v-for="variableId in variableIds"
            :key="variableId"
            :class="{ 'active': selectedVariableIds.indexOf(variableId) !== -1 }"
            class="btn btn-light btn-sm m-1 ms-0 mt-0"
            type="button"
            @click="selectOption($event, variableId)"
        >
            {{ titles[variableId] }}
        </button>
    </div>
</template>

<script>
import searchParams from '../../vue/mixins/search-params'

export default {
    name: "FormControlVariationsOption",
    mixins: [searchParams],
    props: {
        field: Object,
        formId: String,
        currentData: Object,
    },
    data() {
        return {
            lastClickedVariationId: undefined,
        }
    },
    computed: {
        categoryFilter() {
            return this.$store.state[this.tableName].categoryFilter
        },
        selectedVariableIds() {
            return this.currentData[this.field.name]
        },
        inputValue() {
            return this.selectedVariableIds.length ? '1' : ''
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        titles() {
            let titles = {}
            if (this.items) {
                this.items.forEach((item) => {
                    titles[item.id] = item.title
                })
            }
            return titles
        },
        variableIds() {
            let variableIds = []
            if (this.items) {
                this.items.forEach((item) => {
                    variableIds.push(item.id)
                })
            }
            return variableIds
        }
    },
    asyncComputed: {
        items() {
            // I'm not sure of the point in this condition. Users should not be able to create a variation without a
            // productId being preset, and the variable sets the form data so that would be a better reference.
            let params = this.getSearchParams()
            if (params.productId) {
                return this.$store.dispatch('request/post', {
                        url: 'api/products/get-type-from-table-name',
                        postData: {
                            tableName: this.tableName
                        }
                    })
                    .then((obj) => {
                        let typeId = obj.data.id
                        // todo - If I could provide the table name to this controller then it would eliminate the
                        // todo - previous step.
                        return this.$store.dispatch('request/post', {
                            url: 'api/products/get-types-column-names',
                            postData: {
                                typeId: typeId
                            }
                        })
                    })
                    .then((obj) => {
                        let optionId
                        for (let _optionId in obj.data.columnNames) {
                            if (obj.data.columnNames.hasOwnProperty(_optionId)) {
                                let columnName = obj.data.columnNames[_optionId]
                                if (columnName === this.field.name) {
                                    optionId = _optionId
                                }
                            }
                        }

                        if (optionId) {
                            return this.$store.dispatch('request/get', {
                                url: 'api/component/product_variables',
                                params: {
                                    optionId: optionId,
                                    sort: 'displayOrder'
                                }
                            })
                        }
                    })
                    .then((obj) => {
                        return obj.data.items
                    })
            }
        }
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: []
        })

        this.assignSelectAllEventToFormLabels()
    },
    methods: {
        selectOption(e, variableId) {
            // Shift clicking.
            if (
                e.shiftKey
                && this.lastClickedVariationId !== variableId
            ) {
                // Use the last clicked and current clicked button's indexes to determine the range of buttons to
                // be selected.
                let index1 = this.variableIds.indexOf(variableId)
                let index2 = this.variableIds.indexOf(this.lastClickedVariationId)
                let start = index1 < index2 ? index1 : index2
                let end = index1 > index2 ? index1 : index2

                for (let i = start; i <= end; i++) {
                    let varId = this.variableIds[i]
                    let index = this.currentData[this.field.name].indexOf(varId)
                    if (index === -1) {
                        this.currentData[this.field.name].push(varId)
                    }
                }
            } else {
                let i = this.currentData[this.field.name].indexOf(variableId)
                i === -1
                    ? this.currentData[this.field.name].push(variableId)
                    : this.currentData[this.field.name].splice(i, 1)
            }

            this.lastClickedVariationId = variableId
        },
        assignSelectAllEventToFormLabels() {
            this.$nextTick(() => {
                var label = this.$refs.container.closest('[data-form-group]').getElementsByTagName('label')[0]
                label.addEventListener('click', () => {
                    if (this.selectedVariableIds.length === this.variableIds.length) {
                        // Remove all items
                        this.selectedVariableIds.splice(0)
                    } else {
                        // Add all items
                        this.variableIds.forEach((id) => {
                            if (this.selectedVariableIds.indexOf(id) === -1) {
                                this.selectedVariableIds.push(id)
                            }
                        })
                    }
                })
            })
        }
    }
}

</script>

<style scoped>
input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0;
}
</style>