var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content && !_vm.content.isArchived
    ? _c(
        "div",
        {
          staticClass: "site-tree-content-item",
          class: {
            selected: _vm.isSelected,
            "selected-secondary": _vm.isLastSelected,
          },
          attrs: { "data-content-id": _vm.pageContentId },
        },
        [
          _c(
            "a",
            {
              staticClass: "site-tree-content-title",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.selectContent(_vm.pageContentId)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.content.title) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.content
            ? _c("ContentTypeLabel", {
                attrs: { "content-type-id": _vm.content.contentType },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.content.groupAccess && _vm.content.groupAccess.length,
                expression: "content.groupAccess && content.groupAccess.length",
              },
            ],
            staticClass: "bi-person-fill",
            attrs: { title: "This page is assigned group access" },
          }),
          _vm._v(" "),
          _vm.content
            ? _c(
                "small",
                { staticClass: "badge bg-secondary" },
                [
                  _c("ContentUsageCount", {
                    attrs: { "page-content-id": _vm.pageContentId },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isUnpublished
            ? _c("i", {
                staticClass: "bi-x-circle-fill",
                attrs: { title: "Unpublished" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isScheduled
            ? _c("i", {
                staticClass: "bi-clock-history",
                class: {
                  "text-success": _vm.isPending,
                  "text-warning": _vm.isExpiring,
                  "text-danger": _vm.isExpired,
                },
                attrs: { title: _vm.schedulingTitle },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }