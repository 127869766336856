<template>
    <!-- This is just a duplicate of the checkbox, but it could be replaced by a tick icon -->
    <input
        v-if="field.readonly"
        v-model="value"
        disabled
        false-value="0"
        true-value="1"
        type="checkbox"
        class="form-check-input"
    >
    <input
        v-else
        :id="field.id"
        v-model="value"
        :disabled="field.disabled"
        :name="field.name"
        :required="field.required"
        false-value="0"
        true-value="1"
        type="checkbox"
        @click="onClick"
        class="form-check-input"
    >
</template>

<script>
export default {
    name: "FormControlTypeCheckbox",
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            value: this.currentData[this.field.name],
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    watch: {
        value(newValue) {
            this.currentData[this.field.name] = newValue === '1' ? 1 : 0
        }
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: this.field.checked || 0
        })
    },
    methods: {
        onClick(e) {
            if (
                this.tableName === 'modulecomponents'
                && [
                    'showMetadata',
                    'showTags',
                    'showScripts',
                    'useArchive',
                    'createdModified',
                    'showDisplayOrder',
                    'showScheduling',
                    'showStatus'
                ].indexOf(this.field.name) !== -1
                && parseInt(this.currentData[this.field.name])
            ) {
                this.$store.dispatch('modals/show', {
                    componentName: 'ConfirmModal',
                    obj: {
                        modalTitle: 'Are you sure?',
                        modalBody: `
                        <p class="alert alert-danger">
                            This action will result in loss of data.
                        </p>
                        <p>
                            This action <strong>cannot</strong> be undone.
                        </p>
                    `,
                        onConfirm: () => {
                            this.value = 0
                        }
                    }
                })

                e.preventDefault()
            }
        },
    }
}
</script>

<style scoped>

</style>