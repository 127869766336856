<template>
    <div v-if="templateId">
        <div class="mt-1">
            <Button ref="button" @click.native="toggleText" class="btn-sm" data-bs-toggle="collapse"
                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                {{ buttonText }}
            </Button>
        </div>
        <div class="collapse" id="collapseExample">
            <p class="form-text ms-2 mt-0 mb-2" style="font-size: 13px;">
                <template v-if="items?.length">
                    Content embedded directly into the page template.
                    <br>
                    Changes will affect all pages using the same template.
                </template>
                <template v-else>
                    No content.
                </template>
            </p>
            <ul class="list-group">
                <li v-for="item in items" class="list-group-item py-1 pe-2 d-flex align-items-center">
                    <!--
                    todo Error message to be output for IDs referencing missing content:
                    <template v-if="item">-->
                    <div class="flex-fill">
                        <FormPageContentField :content-id="item.id"/>
                    </div>

                    <!--<ContentUsageCount :page-content-id="pageContentData.id" :show-menu="true" class="btn btn-light btn-sm"/>-->

                    <ItemStatusIcons :id="item.id" :table-name="'content'"/>

                    <Button class="btn-sm" @click.native="goToContent(item.id)">
                        <i class="bi-pencil-square"/>
                    </Button>
                    <!--</template>
                    <span v-else>Doesn't exist</span>
                    -->
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import FormPageContentField from "../form/FormPageContentField.vue"
import ContentUsageCount from "../ContentUsageCount.vue"
import ItemStatusIcons from "../ItemStatusIcons.vue"
import Button from "../Button.vue"

export default {
    name: "PageContentTemplateContent",
    components: {
        Button,
        ItemStatusIcons, ContentUsageCount, FormPageContentField
    },
    props: {
        templateId: Number,
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        buttonText() {
            return this.open ? 'Hide template content' : 'Show template content'
        }
    },
    asyncComputed: {
        contentIds() {
            return this.$store.dispatch('pageTemplatesContentAreas/getPageTemplatesContentIds', this.templateId)
        },
        items() {
            return this.$store.dispatch('itemData/preload', {
                tableName: 'content',
                ids: this.contentIds
            })
        }
    },
    methods: {
        goToContent(contentId) {
            window.location = '/#/content/' + contentId
        },
        toggleText() {
            this.open = this.$refs.button.$el.getAttribute('aria-expanded') === 'true'
        }
    }
}
</script>

<style scoped>

</style>