<template>
    <div>
        <button class="btn" :class="{'btn-primary': !hasFieldsets, 'btn-light': hasFieldsets}" type="button"
                data-bs-toggle="offcanvas" data-bs-target="#contentTypeFieldsetOffcanvas" role="button"
                aria-controls="contentTypeFieldsetOffcanvas">
            <i class="bi-plus-lg"/>
            Fieldset
        </button>
    </div>
</template>

<script>
export default {
    name: "ContentTypeNewFieldsetBtn",
    computed: {
        hasFieldsets() {
            return this.$store.state.componentFields.fieldsets.length > 0
        }
    }
}
</script>

<style scoped>

</style>