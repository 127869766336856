export default {
    namespaced: true,
    state: {
        layouts: {
            'No header or footer': {
                'full_width': {
                    title: 'Full width',
                    contentAreas: '',
                    className: 'full-width'
                },
                'left_sidebar': {
                    title: 'Left sidebar',
                    contentAreas: 'left',
                    className: 'sidebar-left'
                },
                'right_sidebar': {
                    title: 'Right sidebar',
                    contentAreas: 'right',
                    className: 'sidebar-right'
                },
                'both_sidebars': {
                    title: 'Both sidebars',
                    contentAreas: 'left,right',
                    className: 'sidebars'
                },
                '2_columns': {
                    title: 'Two columns',
                    contentAreas: 'right',
                    className: 'two-column'
                },
                '3_columns': {
                    title: 'Three columns',
                    contentAreas: 'left,right',
                    className: 'three-column'
                }
            },
            'Header only': {
                'header_full_width': {
                    title: 'Header with full width',
                    contentAreas: 'header',
                    className: 'header full-width'
                },
                'header_left_sidebar': {
                    title: 'Header with left sidebar',
                    contentAreas: 'header,left',
                    className: 'header sidebar-left'
                },
                'header_right_sidebar': {
                    title: 'Header with right sidebar',
                    contentAreas: 'header,right',
                    className: 'header sidebar-right'
                },
                'header_both_sidebars': {
                    title: 'Header with both sidebars',
                    contentAreas: 'header,left,right',
                    className: 'header sidebars'
                },
                'header_2_columns': {
                    title: 'Header with two columns',
                    contentAreas: 'header,right',
                    className: 'header two-column'
                },
                'header_3_columns': {
                    title: 'Header with three columns',
                    contentAreas: 'header,left,right',
                    className: 'header three-column'
                }
            },
            'Footer only': {
                'footer_full_width': {
                    title: 'Footer with full width',
                    contentAreas: 'footer',
                    className: 'footer full-width'
                },
                'footer_left_sidebar': {
                    title: 'Footer with left sidebar',
                    contentAreas: 'footer,left',
                    className: 'footer sidebar-left'
                },
                'footer_right_sidebar': {
                    title: 'Footer with right sidebar',
                    contentAreas: 'footer,right',
                    className: 'footer sidebar-right'
                },
                'footer_both_sidebars': {
                    title: 'Footer with both sidebars',
                    contentAreas: 'footer,left,right',
                    className: 'footer sidebars'
                },
                'footer_2_columns': {
                    title: 'Footer with two columns',
                    contentAreas: 'footer,right',
                    className: 'footer two-column'
                },
                'footer_3_columns': {
                    title: 'Footer with three columns',
                    contentAreas: 'footer,left,right',
                    className: 'footer three-column'
                }
            },
            'Header & footer': {
                'header_footer_full_width': {
                    title: 'Header & footer with full width',
                    contentAreas: 'header,footer',
                    className: 'header-footer full-width'
                },
                'header_footer_left_sidebar': {
                    title: 'Header & footer with left sidebar',
                    contentAreas: 'header,footer,left',
                    className: 'header-footer sidebar-left'
                },
                'header_footer_right_sidebar': {
                    title: 'Header & footer with right sidebar',
                    contentAreas: 'header,footer,right',
                    className: 'header-footer sidebar-right'
                },
                'header_footer_both_sidebars': {
                    title: 'Header & footer with both sidebars',
                    contentAreas: 'header,footer,left,right',
                    className: 'header-footer sidebars'
                },
                'header_footer_2_columns': {
                    title: 'Header & footer with two columns',
                    contentAreas: 'header,footer,right',
                    className: 'header-footer two-column'
                },
                'header_footer_3_columns': {
                    title: 'Header & footer with three columns',
                    contentAreas: 'header,footer,left,right',
                    className: 'header-footer three-column'
                }
            },
            'Narrow': {
                'narrow': {
                    title: 'Narrow',
                    contentAreas: '',
                    className: 'narrow'
                },
                'narrow_header': {
                    title: 'Narrow with header',
                    contentAreas: 'header',
                    className: 'narrow narrow-header'
                },
                'narrow_footer': {
                    title: 'Narrow with footer',
                    contentAreas: 'footer',
                    className: 'narrow narrow-footer'
                },
                'narrow_header_footer': {
                    title: 'Narrow with header & footer',
                    contentAreas: 'header,footer',
                    className: 'narrow narrow-header-footer'
                }
                
            }
        }
    },
    mutations: {},
    actions: {},
}