var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.readonly
    ? _c("FormControlPlainText", {
        attrs: { value: _vm.currentData[_vm.field.name] },
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentData[_vm.field.name],
            expression: "currentData[field.name]",
          },
          {
            name: "select",
            rawName: "v-select",
            value: _vm.field.autofocus,
            expression: "field.autofocus",
          },
        ],
        attrs: {
          id: _vm.field.id,
          disabled: _vm.field.disabled,
          max: _vm.field.max,
          min: _vm.field.min,
          name: _vm.field.name,
          step: _vm.field.step,
          autocomplete: "off",
          type: "range",
        },
        domProps: { value: _vm.currentData[_vm.field.name] },
        on: {
          __r: function ($event) {
            return _vm.$set(
              _vm.currentData,
              _vm.field.name,
              $event.target.value
            )
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }