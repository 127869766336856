var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "th",
    {
      class: [_vm.typeClass, { disabled: !_vm.isClickable }],
      attrs: { role: "button" },
      on: { click: _vm.onClick },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("span", {
        staticClass: "text-danger",
        class: {
          "bi-chevron-up": _vm.sortedAsc,
          "bi-chevron-down": _vm.sortedDesc,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }