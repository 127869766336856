<template>
    <div ref="toast" class="toast" :class="containerColourClass" role="alert" aria-live="assertive" aria-atomic="true">
        <template v-if="title">
            <div class="toast-header">
                <UserAvatar v-if="createdBy" :user-id="createdBy" class="me-2"/>

                <!--<img src="..." class="rounded me-2" alt="...">-->
                <strong class="me-auto">{{ title }}</strong>
                <!--<small class="text-muted">just now</small>-->

                <small v-if="createdDate" class="text-muted">
                    <Timeago :datetime="createdDate * 1000"/>
                </small>

                <button v-if="!disableClose" type="button" class="btn-close small pe-2" :class="buttonColourClass"
                        data-bs-dismiss="toast" aria-label="Close"></button>
            </div>

            <div class="toast-body">
                <div v-html="body"></div>

                <a href v-if="downloadUrl" @click.prevent="downloadExport">Download</a>

                <ProgressBar v-if="percentage >= 0 && percentage < 100" :percentage="percentage" class="mt-1"/>

                <div v-if="moreInfo" v-html="moreInfo" class="mt-2 py-1 px-2 border rounded bg-light small"></div>
            </div>
        </template>

        <!-- Simpler toast without a header -->
        <div v-else class="d-flex">
            <div class="toast-body" v-html="body"></div>

            <button v-if="!disableClose" type="button" class="btn-close me-2 m-auto" :class="buttonColourClass"
                    data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
import {Toast} from 'bootstrap'
import ProgressBar from "../../vue/common/ProgressBar"
import UserAvatar from "../UserAvatar"

export default {
    name: "Toast",
    components: {UserAvatar, ProgressBar},
    props: {
        createdBy: Number,
        createdDate: Number,
        title: String,
        body: String,
        // BS Toasts auto hide by default, so the option must be to disable it
        disableAutoHide: Boolean,
        downloadUrl: String,
        percentage: Number,
        colourScheme: String,
        disableAnimation: false,
        disableClose: false,
        moreInfo: String,
        onClose: Function
    },
    computed: {
        containerColourClass() {
            return this.colourScheme ? 'text-bg-' + this.colourScheme + ' border-0' : ''
        },
        buttonColourClass() {
            if (['primary', 'danger'].indexOf(this.colourScheme) > -1) {
                return 'btn-close-white'
            }
        }
    },
    mounted() {
        const toastEl = this.$refs.toast

        const toast = new Toast(toastEl, {
            autohide: !this.disableAutoHide,
            animation: !this.disableAnimation
        })
        toast.show()

        if (this.onClose) {
            toastEl.addEventListener('hidden.bs.toast', () => {
                this.onClose()
            })
        }

        // todo This was an attempt to remove items' data from toasts.items when Toast are hidden but it resulted
        //      in multiple toasts being removed. The fact is that there's no need to remove them immediately, but
        //      it would be good to purge them from time to time, so perhaps a better approach would be to somehow
        //      clear the array when ALL toasts have hidden.
        //toastEl.addEventListener('hidden.bs.toast', () => {
        //    const i = this.$store.state.toasts.items.findIndex(o => o.id === this.id)
        //    this.$store.state.toasts.items.splice(i, 1)
        //})
    },
    methods: {
        downloadExport() {
            window.location = this.downloadUrl
        }
    }
}
</script>

<style scoped>

</style>