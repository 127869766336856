<template>
    <div style="margin-bottom: 40px">

        <div v-if="currentData.gatewayName === 'PayPal'" class="well well-sm">
            <div class="mb-1 row">
                <label class="col-xl-4 form-label">PayPal ID or email address</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.payPalId"
                        class="form-control"
                        name="settingsPayPalId"
                    >
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">PayPal test client ID</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.payPalTestClientId"
                        class="form-control"
                        name="settingsPayPalTestClientId"
                    >
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">PayPal test secret</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.payPalTestSecret"
                        class="form-control"
                        name="settingsPayPalTestSecret"
                        type="password"
                    >
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">PayPal client ID</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.payPalClientId"
                        class="form-control"
                        name="settingsPayPalClientId"
                    >
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">PayPal secret</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.payPalSecret"
                        class="form-control"
                        name="settingsPayPalSecret"
                        type="password"
                    >
                </div>
            </div>
        </div>

        <div v-else-if="currentData.gatewayName === 'Pay360'" class="well well-sm">
            <div class="mb-1 row">
                <label class="col-xl-4 form-label">API username</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.apiUsername"
                        :class="{ 'is-invalid': isRequired('settingsApiUsername') }"
                        class="form-control"
                        name="settingsApiUsername"
                        required
                    >
                    <span class="form-text">Required.</span>
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">API password</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.apiPassword"
                        :class="{ 'is-invalid': isRequired('settingsApiPassword') }"
                        class="form-control"
                        name="settingsApiPassword"
                        required
                        type="password"
                    >
                    <span class="form-text">Required.</span>
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Hosted cashier installation ID</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.installationId"
                        :class="{ 'is-invalid': isRequired('settingsInstallationId') }"
                        class="form-control"
                        name="settingsInstallationId"
                        required
                    >
                    <span class="form-text">Required.</span>
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Sandbox API username</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.sandboxApiUsername"
                        :class="{ 'is-invalid': isRequired('settingsSandboxApiUsername') }"
                        class="form-control"
                        name="settingsSandboxApiUsername"
                    >
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Sandbox API password</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.sandboxApiPassword"
                        :class="{ 'is-invalid': isRequired('settingsSandboxApiPassword') }"
                        class="form-control"
                        name="settingsSandboxApiPassword"
                        type="password"
                    >
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Sandbox hosted cashier installation ID</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.sandboxInstallationId"
                        :class="{ 'is-invalid': isRequired('settingsSandboxInstallationId') }"
                        class="form-control"
                        name="settingsSandboxInstallationId"
                    >
                    <span class="form-text">Required.</span>
                </div>
            </div>
        </div>

        <div v-else-if="currentData.gatewayName === 'SecPay'" class="well well-sm">
            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Merchant ID</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.merchantId"
                        :class="{ 'is-invalid': isRequired('settingsMerchantId') }"
                        class="form-control"
                        name="settingsMerchantId"
                        required
                    >
                    <span class="form-text">Required.</span>
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Digest key</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.digestKey"
                        :class="{ 'is-invalid': isRequired('settingsDigestKey') }"
                        class="form-control"
                        name="settingsDigestKey"
                        required
                    >
                    <span class="form-text">Required.</span>
                </div>
            </div>
        </div>

        <div v-else-if="currentData.gatewayName === 'Stripe'" class="well well-sm">
            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Secret key</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.secretKey"
                        :class="{ 'is-invalid': isRequired('settingsSecretKey') }"
                        class="form-control"
                        name="settingsSecretKey"
                        required
                    >
                    <span class="form-text">Required.</span>
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Publishable key</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.publishableKey"
                        :class="{ 'is-invalid': isRequired('settingsPublishableKey') }"
                        class="form-control"
                        name="settingsPublishableKey"
                        required
                    >
                    <span class="form-text">Required.</span>
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Test secret key</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.testSecretKey"
                        class="form-control"
                        name="settingsTestSecretKey"
                        required
                    >
                </div>
            </div>

            <div class="mb-1 row">
                <label class="col-xl-4 form-label">Test publishable key</label>
                <div class="col-xl-8 col-lg-9 control-wrapper">
                    <input
                        v-model="currentData.settings.testPublishableKey"
                        class="form-control"
                        name="settingsTestPublishableKey"
                        required
                    >
                </div>
            </div>
        </div>

        <Dump>{{ gatewayName }}</Dump>
    </div>
</template>

<script>
export default {
    name: "FormControlMBasketMerchantdetailsSettings",
    props: {
        field: Object,
        formId: String,
        currentData: Object,
    },
    computed: {
        gatewayName() {
            return this.$store.state[this.formId].currentData.gatewayName
        }
    },
    watch: {
        gatewayName(newValue) {
            this.currentData.settings = {}
        },
    },
    created() {
        this.field.replaceFormgroup = true
    },
    methods: {
        isRequired(name) {
            return false

            let field = $scope.settingsForm[name]
            if (
                field.$error.required
                && (
                    field.$touched // When the user tabs the field.
                    || field.$dirty // When the user submits the form. See form-directive's $scope.save()
                )
            ) {
                return true
            }
        }
    }
}
</script>

<style scoped>
.well {
    overflow: hidden;
}
</style>