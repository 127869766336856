<script>
import FormControlTypeText from "../form-control-type/FormControlTypeText.vue"

export default {
    name: "FormControlModulecomponentsDisplayName",
    extends: FormControlTypeText,
    watch: {
        currentData: {
            handler(newValue) {
                // visibility must be used here, and not hidden, because the latter removes this component and so the
                // script won't run.
                this.field.visibility = newValue.nameField === 0 ? 'hidden' : ''
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>