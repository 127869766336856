<template>
    <a v-if="href" :href="href" :title="fileName">{{ fileName }}</a>
</template>

<script>
export default {
    name: "TableCellTypeFile",
    props: {
        value: Number,
    },
    data() {
        return {
            href: '',
            fileName: '',
        }
    },
    created() {
        this.$store.dispatch('itemData/get', {
                tableName: 'files',
                id: this.value
            })
            .then((obj) => {
                if (obj) {
                    this.href = this.$store.getters.fileSrc(obj.directory, obj.fileName)
                    this.fileName = obj.fileName
                }
            })
    }
}
</script>

<style scoped>

</style>