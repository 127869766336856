<template>
    <span v-html="value"/>
</template>

<script>
export default {
    name: "TableCellTypeRange",
    props: {
        value: String
    }
}
</script>

<style scoped>

</style>