var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contentTypeTitle
    ? _c("section", { staticClass: "d-flex flex-column h-100" }, [
        _c("header", [
          _c("h2", { staticClass: "h5" }, [
            _c("small", [_vm._v(_vm._s(_vm.contentTypeTitle))]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-fill" },
          [
            _vm.tableNameId
              ? _c("PageEditorContentPanelForm", {
                  key: _vm.tableNameId,
                  attrs: { "table-name-id": _vm.tableNameId },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "footer",
          { staticClass: "pb-3 text-center" },
          [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("PageEditorContentMoveUpBtn"),
                _vm._v(" "),
                _c("PageEditorContentMoveDownBtn"),
              ],
              1
            ),
            _vm._v(" "),
            _c("PageEditorContentRemoveBtn"),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }