var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard-panel-state-history card" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        Your viewing history\n    "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body small" },
      _vm._l(_vm.items, function (item, index) {
        return item.fullPath
          ? _c(
              "div",
              {
                key: item.id,
                staticClass: "breadcrumb",
                class: { "mb-1": index !== _vm.items.length - 1 },
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    return _vm.open(item.fullPath)
                  },
                },
              },
              [
                _c("span", { staticClass: "breadcrumb-item" }, [
                  _vm._v(_vm._s(item.componentTitle)),
                ]),
                _vm._v(" "),
                item.itemTitle
                  ? _c("span", { staticClass: "breadcrumb-item" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.itemTitle) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e()
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }