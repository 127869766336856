var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "small",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
    },
    [
      _c("span", { staticClass: "from" }, [_vm._v(_vm._s(_vm.from))]),
      _vm._v("\n    - "),
      _c("span", { staticClass: "to" }, [_vm._v(_vm._s(_vm.to))]),
      _vm._v("\n    of "),
      _c("span", { staticClass: "total" }, [_vm._v(_vm._s(_vm.itemCount))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }