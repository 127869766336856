var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown" },
    [
      _c(
        "Button",
        {
          staticClass: "btn-sm dropdown-toggle",
          attrs: { "data-bs-toggle": "dropdown" },
        },
        [_vm._v("\n        " + _vm._s(_vm.selectedSectionTitle) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown-menu" },
        _vm._l(_vm.sectionsData, function (section) {
          return _c(
            "button",
            {
              key: section.tableName,
              staticClass: "dropdown-item",
              class: { active: section.tableName === _vm.sectionName },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.setSectionName(section.tableName)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(section.title) + "\n        ")]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }