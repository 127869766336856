var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inner" },
    _vm._l(_vm.items, function (item) {
      return _c("div", { key: item.id, staticClass: "card" }, [
        _c("div", { staticClass: "card-header clearfix" }, [
          _c("span", { staticClass: "float-end" }, [
            _vm._v(_vm._s(_vm.getDate(item.createdDate * 1000))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "dl",
            { staticClass: "dl-horizontal" },
            [
              _vm._l(item.data, function (value, name) {
                return _vm.showField(name)
                  ? [
                      _c("dt", [_vm._v(_vm._s(name))]),
                      _vm._v(" "),
                      _c("dd", [_vm._v(_vm._s(value))]),
                    ]
                  : _vm._e()
              }),
            ],
            2
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }