<template>
    <div class="card mb-3">
        <div class="card-header py-1 pe-2 d-flex align-items-center">
            <span class="flex-fill">{{ contentArea }}</span>

            <Button class="btn-sm" @click.native="showAddContentModal">
                <i class="bi-plus-lg"/>
            </Button>
        </div>

        <ul v-if="items.length" class="list-group list-group-flush">
            <PageContentMobileContent v-for="(item, index) in items" :key="item.id" :content-areas="contentAreas"
                                      :content-area="contentArea" :content-area-first="contentAreaFirst"
                                      :content-area-last="contentAreaLast" :content-index="index"
                                      :content-last="index === items.length - 1" :page-content-link-id="item.id"/>
        </ul>
    </div>
</template>

<script>
import PageContentMobileContent from './PageContentMobileContent'
import Button from "../Button.vue"

export default {
    name: "PageContentMobileContentArea",
    components: {
        Button,
        PageContentMobileContent
    },
    props: {
        pageId: Number,
        contentAreas: Array,
        contentArea: String,
        contentAreaFirst: Boolean,
        contentAreaLast: Boolean,
    },
    computed: {
        items() {
            return this.$store.state.pageContentData.items.filter((obj) => {
                return (
                    obj.pageId === this.pageId
                    && obj.contentArea === this.contentArea
                )
            })
        }
    },
    methods: {
        showAddContentModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'AddContentModal',
                obj: {
                    onSelect: (obj) => {
                        this.$store.dispatch('request/post', {
                                url: 'api/save-content-to-page',
                                postData: {
                                    pageId: this.pageId,
                                    contentId: obj.contentId,
                                    contentArea: this.contentArea
                                }
                            })
                            .then(() => {
                                return this.$store.dispatch('pageContentData/init')
                            })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.card-header:last-child {
    border-bottom: none; /* Remove the double width bottom border */
}
</style>