<template>
    <span v-html="title"/>
</template>

<script>
export default {
    name: "ListViewRowTitle",
    props: {
        viewId: String,
        id: Number,
    },
    computed: {
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
        title() {
            if (this.item) {
                return this.item[this.nameField]
            }
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
                .then((obj) => {
                    return obj
                })
        }
    }
}
</script>

<style scoped>

</style>