var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.value
    ? _c("FormControlTypeNumber", {
        ref: "input",
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm.description
    ? _c(
        "div",
        [
          _vm._l(_vm.description, function (name, value) {
            return [
              _vm._v("\n        " + _vm._s(name) + ": " + _vm._s(value)),
              _c("br"),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }