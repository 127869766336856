export default {
    namespaced: true,
    state: {
        items: undefined
    },
    mutations: {
        items(state, items) {
            state.items = items
        }
    },
    getters: {
        pageTemplates(state) {
            return state.items.filter(o => o.type === 'page')
        },
        contentTypes(state) {
            return state.items.filter(o => o.type === 'contentType')
        }
    },
    actions: {
        init({state, commit, dispatch}, refresh) {
            if (
                state.items === undefined
                || refresh
            ) {
                return dispatch('request/get', {
                    url: 'api/component/templates_templates',
                    params: {
                        sort: 'title'
                    }
                }, {root: true})
                    .then(o =>
                        commit('items', o.data.items)
                    )
                    
            }
        }
    }
}