var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "btn-sm mx-1 p-0 px-1",
      attrs: { disabled: !_vm.hasEditAccess },
      nativeOn: {
        click: function ($event) {
          return _vm.gotToThemesEditor.apply(null, arguments)
        },
      },
    },
    [_vm._v("\n    " + _vm._s(_vm.contentTypeTitle) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }