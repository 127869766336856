<template>
    <button class="btn btn-light m-3" type="button" @click="addFilter">
        <i class="bi-plus-lg"/>
    </button>
</template>

<script>
export default {
    name: "SearchFilterAddFilterBtn",
    props: {
        listingName: String,
        viewId: String,
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        tableName() {
            return this.listingName.split('.')[0]
        }
    },
    methods: {
        addFilter() {
            let filterData = {
                columnName: this.tableName + 'ID',
                condition: false,
                conditionText: 'is',
                type: 'text'
            }

            let newFilterId = false
            this.$store.dispatch('advancedFilters/save', {
                    tableName: this.tableName,
                    id: false,
                    filterData: filterData,
                })
                .then((obj) => {
                    newFilterId = obj.data.id
                    return this.$store.dispatch('advancedFilters/get', {
                        tableName: this.tableName,
                        refresh: false,
                    })
                })
                .then((data) => {
                    data.push({
                        id: newFilterId,
                        filterData: filterData
                    })

                    // Use $set to make the new property reactive.
                    this.$set(this.filters.advancedFilter, newFilterId, undefined)
                })
        }
    }
}
</script>

<style scoped>

</style>