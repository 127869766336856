export default {
    inserted: function(el, binding, vnode, old) {
        
        let field = vnode.context.field
        
        // Do not enable auto-grow if the textarea is set with a rows attribute.
        if (
            field
            && field.rows !== ''
            && field.rows > 0
        ) {
            return false
        }
        
        setHeight()
        el.oninput = setHeight
        
        function setHeight() {
            el.style.height = ''
            
            // If the field is empty its scroll height will be zero.
            let h = el.scrollHeight
            if (h) {
                el.style.height = h + 'px'
            }
        }
    },
}