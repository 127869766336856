<template>
    <FormSidebarBtn v-if="show" :disabled="order.gatewayId === 0" @click.prevent.native="printInvoice">
        <i class="me-2 bi-printer"/>
        {{ text }}
    </FormSidebarBtn>
</template>

<script>
import OrderRequestReview from './OrderRequestReview'
import FormSidebarBtn from "../../common/form/FormSidebarBtn.vue"

export default {
    name: "PrintBtn",
    components: {FormSidebarBtn, OrderRequestReview},
    props: {
        formId: String,
        isInvoice: Boolean
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        show() {
            return this.tableName === 'm_basket_orders'
        },
        text() {
            return this.isInvoice ? 'Print invoice' : 'Print delivery'
        }
    },
    asyncComputed: {
        order: {
            default: {},
            get() {
                if (this.show) {
                    return this.$store.dispatch('itemData/get', {
                            tableName: 'm_basket_orders',
                            id: this.id
                        })
                        .then((obj) => {
                            return obj
                        })
                }
            }
        }
    },
    methods: {
        printInvoice() {
            let deliveryBool = this.isInvoice ? 0 : 1
            window.open('print-invoice.html#/?orderId=' + this.order.id + '&delivery=' + deliveryBool)
        }
    }
}
</script>

<style scoped>
button:disabled {
    background: none;
}
</style>