var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDebited
    ? _c("span", [_vm._v("(" + _vm._s(_vm.formattedValue) + ")")])
    : _c("span", [_vm._v(_vm._s(_vm.formattedValue))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }