<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasPageEditorContent"
         aria-labelledby="offcanvasPageEditorContentLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasPageEditorContentLabel">Offcanvas</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvasPageEditorContent"
                    aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <p>
                <PageEditorContentMoveUpBtn/>
                <PageEditorContentMoveDownBtn/>
            </p>
        </div>

        <div class="offcanvas-footer">
            <div class="p-3 text-end">
                <PageEditorContentRemoveBtn/>
            </div>
        </div>
    </div>
</template>

<script>
import PageEditorContentRemoveBtn from "./PageEditorContentRemoveBtn.vue"
import PageEditorContentMoveUpBtn from "./PageEditorContentMoveUpBtn.vue"
import PageEditorContentMoveDownBtn from "./PageEditorContentMoveDownBtn.vue"

export default {
    name: "PageEditorOffCanvas",
    components: {
        PageEditorContentMoveDownBtn, PageEditorContentMoveUpBtn, PageEditorContentRemoveBtn
    },
    mounted() {
        // BS' offcanvas close buttons don't work in Vue for some reason
        const myOffcanvas = document.getElementById('offcanvasPageEditorContent')
        myOffcanvas.querySelector('[data-bs-dismiss]').addEventListener('click', () => {
            this.$store.dispatch('pageEditor/hideOffcanvas')
        })
    }
}
</script>

<style scoped>

</style>