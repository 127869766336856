var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column h-100 overflow-hidden" },
    [
      _c("PageEditorToolbar"),
      _vm._v(" "),
      _vm.pageId
        ? _c(
            "div",
            {
              ref: "container",
              staticClass: "flex-fill d-flex h-100 overflow-hidden",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-fill position-relative overflow-hidden w-100 h-100 d-flex",
                },
                [
                  _c("iframe", {
                    ref: "iframe",
                    staticClass: "flex-fill",
                    attrs: { srcdoc: _vm.iframeSrcdoc },
                    on: { load: _vm.init },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.contentAreas, function (contentArea) {
                    return _vm.contentAreas.length
                      ? [
                          _c("PageEditorContentAreaIframe", {
                            key: contentArea.name,
                            attrs: { "content-area": contentArea },
                          }),
                          _vm._v(" "),
                          _vm._l(
                            _vm.contentAreaContent(contentArea.name),
                            function (o) {
                              return [
                                _c("PageEditorContentIframe", {
                                  key: o.pageContentId,
                                  attrs: { "content-item": o },
                                }),
                              ]
                            }
                          ),
                        ]
                      : _vm._e()
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "aside",
                {
                  staticClass: "p-3 pb-0 border-start overflow-y-scroll",
                  staticStyle: { flex: "0 0 250px" },
                },
                [
                  _vm.selectedContentAreaName
                    ? _c("PageEditorContentAreaPanel")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activePageContentId
                    ? _c("PageEditorContentPanel")
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hoverContentAreaName
        ? _c("PageEditorContentAreaOverlay", { key: _vm.hoverContentAreaName })
        : _vm._e(),
      _vm._v(" "),
      _vm.hoverPageContentId
        ? _c("PageEditorContentOverlay", { key: _vm.hoverPageContentId })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }