<template>
    <FormSidebarBtn v-if="show" @click.prevent.native="onClick">
        <i class="me-2 bi-archive"/>
        {{ buttonText }}
    </FormSidebarBtn>
</template>

<script>
import FormSidebarBtn from "./FormSidebarBtn.vue"

export default {
    name: "FormArchiveBtn",
    components: {FormSidebarBtn},
    props: {
        formId: String,
    },
    computed: {
        isArchived() {
            return this.$store.state[this.formId].currentData.isArchived
        },
        buttonText() {
            return this.isArchived ? 'Remove from archive' : 'Add to archive'
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        show() {
            if (!this.id) {
                return false
            }

            // The home page cannot be archived
            if (this.id === this.$store.state.settings.homePage) {
                return false
            }

            // Content should not be archived from the site tree, as this removes the content from all pages.
            // Users must archive it explicitly from the Content component.
            if (
                this.tableName === 'content'
                && window.location.hash.split('/')[1] === 'site_tree'
            ) {
                return false
            }

            if (this.hasAccess) {
                let component = this.$store.state.components.items.find(obj => obj.id === this.componentId)
                return !!component.useArchive
            }
        },
        hasAccess() {
            return this.$store.getters['user/access'](this.tableName, 'archive')
        },
    },
    methods: {
        onClick() {
            let modalTitle = this.isArchived
                ? 'Remove from archive'
                : 'Add to archive'

            let modalBodyStr = this.isArchived
                ? 'remove this item from.'
                : 'add this item to'

            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: modalTitle,
                    modalBody: `
                          <p>
                            Are you sure you wish to ` + modalBodyStr + ` the archive?
                          </p>
                        `,
                    onConfirm: () => {
                        // The isArchived column stores 1|0, not true|false.
                        let value = this.isArchived ? 0 : 1

                        this.$store.dispatch('itemData/archive', {
                                tableName: this.tableName,
                                id: this.id,
                                doArchive: value,
                            })
                            .then(() => {
                                this.$store.commit(this.formId + '/presetData', {
                                    name: 'isArchived',
                                    value: value,
                                })

                                // Refresh site tree pane
                                this.$store.dispatch('siteTree/init')
                            })
                            .catch((error) => {
                                //console.log('b', error);
                            })
                    }
                }
            })
        }
    },
}
</script>

<style scoped>

</style>