var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item && _vm.show
    ? _c(
        "figure",
        {
          class: { active: _vm.selectedIds.indexOf(_vm.item.id) !== -1 },
          on: {
            dblclick: _vm.onDblClick,
            click: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "left", 37, $event.key, [
                    "Left",
                    "ArrowLeft",
                  ])
                )
                  return null
                if ("button" in $event && $event.button !== 0) return null
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                )
                  return null
                return _vm.onClick.apply(null, arguments)
              },
              function ($event) {
                if (!$event.metaKey) return null
                return _vm.onMetaClick.apply(null, arguments)
              },
              function ($event) {
                if (!$event.shiftKey) return null
                return _vm.onShiftClick.apply(null, arguments)
              },
            ],
          },
        },
        [
          _vm.file.id && _vm.showThumbnail
            ? _c("Picture", {
                attrs: {
                  alt: _vm.item[_vm.nameField],
                  "file-id": _vm.file.id,
                  "on-click": _vm.showLightbox,
                  sizes: "17vw",
                },
              })
            : _vm.file.id && !_vm.showThumbnail
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v("\n        not an image\n    "),
              ])
            : _c("div", { staticClass: "text-center" }, [
                _vm._v("\n        no file\n    "),
              ]),
          _vm._v(" "),
          _c("figcaption", { staticClass: "text-center" }, [
            _c("small", [_vm._v(_vm._s(_vm.item[_vm.nameField]))]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }