var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "div",
        {
          staticClass:
            "mb-1 p-1 border bg-light-subtle rounded position-relative",
        },
        [
          _c(
            "Button",
            {
              staticClass: "position-absolute end-0 me-1 p-1 py-0 btn-sm",
              nativeOn: {
                click: function ($event) {
                  return _vm.remove.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n        ×\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "small" }, [
            _c("div", { staticClass: "small" }, [_vm._t("default")], 2),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }