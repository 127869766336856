<template>
    <button v-if="hasAccess" class="btn btn-light btn-sm" type="button" @click="edit">
        Edit
    </button>
</template>

<script>
export default {
    name: "TableViewEditBtn",
    props: {
        viewId: String,
        id: Number,
        selectMode: Boolean,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        hasAccess() {
            if (
                this.tableName.indexOf('product_variations__') === 0
                || this.selectMode
            ) {
                return false
            }

            return this.$store.getters['user/access'](this.tableName, 'edit')
        },
    },
    methods: {
        edit() {
            window.location = '/#/' + this.tableName + '/' + this.id
        }
    }
}
</script>

<style scoped>

</style>