var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-xl" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Select an item")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("header", { staticClass: "d-flex pb-3" }, [
            _c(
              "div",
              { staticClass: "flex-fill" },
              [
                _vm.show
                  ? _c(
                      "Button",
                      {
                        staticClass: "rounded-0",
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showAddItemModal.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "bi-plus-lg" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("ListingOptionsSearchModalBtn", {
                  attrs: { "view-id": _vm.viewId },
                }),
                _vm._v(" "),
                _c("SearchFiltersResetBtn", {
                  attrs: { "view-id": _vm.viewId },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("ListingOptionsPaginationMetrics", {
                  attrs: { "view-id": _vm.viewId },
                }),
                _vm._v(" "),
                _c("ListingOptionsPreviousBtn", {
                  staticClass: "ms-1",
                  attrs: { "view-id": _vm.viewId },
                }),
                _vm._v(" "),
                _c("ListingOptionsNextBtn", {
                  staticClass: "ms-1",
                  attrs: { "view-id": _vm.viewId },
                }),
                _vm._v(" "),
                _c("ListingOptionsMenu", {
                  staticClass: "ms-1",
                  attrs: { "view-id": _vm.viewId },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isOdp
            ? _c(
                "div",
                { staticClass: "pb-3" },
                [
                  _c("OdpCourseDatesCoursesMenu", {
                    attrs: { "view-id": _vm.viewId },
                  }),
                  _vm._v(" "),
                  _c("OdpCourseDatesSchoolsMenu", {
                    attrs: { "view-id": _vm.viewId },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.viewMode === "table" || !_vm.viewMode
            ? _c("TableView", { attrs: { "view-id": _vm.viewId } })
            : _vm.viewMode === "thumbnail"
            ? _c("GridView", { attrs: { "view-id": _vm.viewId } })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }