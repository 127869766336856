var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.componentIsEmpty && _vm.betaMode
    ? _c("div", { staticClass: "text-center" }, [
        _c(
          "div",
          { staticClass: "mt-1" },
          [
            _c("Button", { attrs: { "data-bs-toggle": "dropdown" } }, [
              _c("i", { staticClass: "bi-three-dots" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-menu" }, [
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.resetIds.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                Reset IDs (auto increment)\n            "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }