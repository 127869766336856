<template>
    <tr v-if="show" :class="{ 'table-active': selectedIds.indexOf(id) > -1 }" @dblclick="onDblClick" @click.left.exact="onClick" @click.meta="onMetaClick"
        @click.shift="onShiftClick">

        <td v-if="!selectMode" class="table-cell-select" :class="cellBgColour">
            <small :class="{ 'bi-square': selectedIds.indexOf(this.id) === -1, 'bi-check-square': selectedIds.indexOf(this.id) > -1 }"
                   @click.stop.left.exact="onMetaClick" @click.stop.shift="onShiftClick"/>
        </td>

        <td v-if="item" data-name="id" :class="cellBgColour">
            {{ item.id }}

            <UserTrackingAvatar :item-id="id" :table-name="tableName"/>
        </td>

        <TableCell :class="cellBgColour" v-for="(columnId, index) in columns" :id="id" :key="columnId"
                   :column-id="columnId" :view-id="viewId"/>
    </tr>
</template>

<script>
import UserTrackingAvatar from '../UserTrackingAvatar'
import TableCell from '../table-cell/TableCell'

export default {
    name: "TableViewRow",
    components: {
        UserTrackingAvatar,
        TableCell,
    },
    props: {
        viewId: String,
        id: Number,
    },
    data() {
        return {
            show: true,
            item: undefined,
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        selectedIds() {
            return this.$store.state[this.viewId].selectedIds
        },
        selectMode() {
            return this.$store.state[this.viewId].selectMode
        },
        allSelected() {
            return this.$store.state[this.viewId].allSelected
        },
        columns() {
            return this.$store.getters[this.viewId + '/visibleColumnIds']
        },
        isDarkMode() {
            return this.$store.getters['user/pref']('darkMode')
        },
        cellBgColour() {
            const className = this.isDarkMode ? 'text-bg-secondary' : 'text-bg-warning'
            return this.selectedIds.indexOf(this.id) > -1 ? className : ''
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
                .then((obj) => {
                    return obj
                })
        },
    },
    watch: {
        item() {
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch(this.viewId + '/onClick', this.id)
        },
        onDblClick() {
            this.$store.dispatch(this.viewId + '/onDblClick')
                .then((hasAccess) => {
                    if (hasAccess) {
                        this.$router.push({
                            name: 'form',
                            params: {id: this.id},
                            query: this.$route.query
                        })
                    }
                })
        },
        onShiftClick() {
            this.$store.commit(this.viewId + '/onShiftClick', this.id)
        },
        onMetaClick() {
            this.$store.commit(this.viewId + '/onMetaClick', this.id)
        },
    }
}
</script>

<style scoped>

</style>