var render, staticRenderFns
import script from "./FormControlModulestructureEndYear.vue?vue&type=script&lang=js&"
export * from "./FormControlModulestructureEndYear.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46f07dc7",
  null
  
)

export default component.exports