<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ options.label }}</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <textarea id="HistoryCodeEditorModalEditor">{{ options.value }}</textarea>
            </div>

            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HistoryCodeEditorModal",
    props: {
        options: Object,
    },
    computed: {
        theme() {
            return this.$store.getters['user/pref']('codeMirror_theme')
        },
        lineNumbers() {
            return this.$store.getters['user/pref']('codeMirror_lineNumbers')
        },
        lineWrapping() {
            return this.$store.getters['user/pref']('codeMirror_lineWrapping')
        }
    },
    created() {
        let options = {
            //value: this.options.value,
            mode: this.options.mode,
            lineNumbers: true,
            // Line wrapping must be enabled when presenting CodeMirror in a form else long lines will
            // stretch the form beyond the viewport.
            lineWrapping: true,
            readOnly: true,
            indentUnit: 4,
            tabMode: 'spaces',
            enterMode: 'indent',
            electricChars: false//, // Prevents '{' from resetting the indent
        }

        if (this.theme) {
            options['theme'] = this.theme
        }

        if (this.lineNumbers !== undefined) {
            options['lineNumbers'] = this.lineNumbers
        }

        if (this.lineWrapping !== undefined) {
            options['lineWrapping'] = this.lineWrapping
        }

        this.$nextTick(() => {
            CodeMirror.fromTextArea(document.getElementById('HistoryCodeEditorModalEditor'), options)
        })
    }
}
</script>

<style scoped>

</style>