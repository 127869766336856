<template>
    <PageContentMobile :page-id="pageId" :hide-modal="() => {}"/>
</template>

<script>
import PageContentMobile from '../../common/page-content/PageContentMobile'

export default {
    name: "FormControlPagesContent",
    components: {
        PageContentMobile
    },
    props: {
        currentData: Object,
        field: Object,
    },
    computed: {
        pageId() {
            return this.currentData.id
        }
    },
    created() {
        if (!this.currentData.id) {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>