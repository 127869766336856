var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.readonly || _vm.isTitleColumn
    ? _c("FormControlPlainText", {
        attrs: { value: _vm.currentData[_vm.field.name] },
      })
    : _c(
        "div",
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentData[_vm.field.name],
                expression: "currentData[field.name]",
              },
              {
                name: "select",
                rawName: "v-select",
                value: _vm.field.autofocus,
                expression: "field.autofocus",
              },
              { name: "form-validation", rawName: "v-form-validation" },
            ],
            ref: "field",
            staticClass: "form-control",
            attrs: {
              list: "datalist-" + _vm.field.id,
              id: _vm.field.id,
              disabled: _vm.field.disabled,
              minlength: _vm.field.minlength,
              name: _vm.field.name,
              pattern: _vm.field.pattern || false,
              placeholder: _vm.field.placeholder || false,
              required: _vm.field.required,
              size: _vm.field.size,
              autocomplete: "off",
              type: "text",
            },
            domProps: { value: _vm.currentData[_vm.field.name] },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.currentData, _vm.field.name, $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.datalist
            ? _c(
                "datalist",
                { attrs: { id: "datalist-" + _vm.field.id } },
                _vm._l(_vm.datalist, function (value) {
                  return _c("option", { domProps: { value: value } })
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.field.maxlength
            ? _c("MaxLengthCounter", {
                attrs: {
                  maxlength: _vm.field.maxlength,
                  value: _vm.currentData[_vm.field.name],
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }