var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg site-tree-modal" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c(
        "div",
        { staticClass: "modal-header" },
        [
          _c("SiteTreeNavigationMenu", {
            staticClass: "w-auto",
            attrs: { "site-tree-id": _vm.siteTreeId },
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn-close",
            attrs: { "aria-label": "Close", type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("hide-modal")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("SiteTree", {
            attrs: {
              "select-content": _vm.selectContent,
              "select-page": _vm.selectPage,
              "selected-page-ids": _vm.selectedPageIds,
              "show-content": _vm.showContent,
              "site-tree-id": _vm.siteTreeId,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _vm.selectedPageIds
          ? _c(
              "form",
              {
                staticClass: "d-flex align-items-center",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveSelectedPageIds.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedContentArea,
                        expression: "selectedContentArea",
                      },
                    ],
                    staticClass: "form-select",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedContentArea = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.contentAreas, function (option) {
                    return _c("option", { domProps: { value: option.value } }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(option.text) +
                          "\n                    "
                      ),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ms-1 btn",
                    class: {
                      "btn-light": _vm.isSaveBtnDisabled,
                      "btn-warning": !_vm.isSaveBtnDisabled,
                    },
                    attrs: { disabled: _vm.isSaveBtnDisabled },
                  },
                  [_vm._v("\n                    Save\n                ")]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }