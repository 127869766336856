export default {
    namespaced: true,
    state: {
        data: {},
    },
    mutations: {
        data(state, {tableName, columnName, data}) {
            if (state.data[tableName] === undefined) {
                this._vm.$set(state.data, tableName, {})
            }
            
            if (state.data[tableName][columnName] === undefined) {
                this._vm.$set(state.data[tableName], columnName, {})
            }
            
            state.data[tableName][columnName] = data
        },
        itemData(state, {tableName, columnName, itemId, data}) {
            if (state.data[tableName] === undefined) {
                this._vm.$set(state.data, tableName, {})
            }
            
            if (state.data[tableName][columnName] === undefined) {
                this._vm.$set(state.data[tableName], columnName, {})
            }
    
            state.data[tableName][columnName] = state.data[tableName][columnName]
                // Remove the item's old category data
                .filter((obj) => obj.itemId !== itemId)
                // Add the new data
                .concat(data)
        }
    },
    getters: {
        get: (state) => (tableName, columnName, itemId) => {
            return (
                state.data[tableName]
                && state.data[tableName][columnName]
            )
                ? state.data[tableName][columnName].filter(obj => obj.itemId === itemId)
                : undefined
        },
        getContentIds: (state) => (tableName, columnName, categoryId) => {
            return (
                state.data[tableName]
                && state.data[tableName][columnName]
            )
                ? state.data[tableName][columnName].filter(obj => obj.catId === categoryId).map(o => o.itemId)
                : undefined
        },
        getContentIdsMulti: (state) => (tableName, columnName, categoryIds) => {
            return (
                state.data[tableName]
                && state.data[tableName][columnName]
            )
                ? state.data[tableName][columnName].filter(obj =>  categoryIds.indexOf(obj.catId) > -1).map(o => o.itemId)
                : undefined
        }
    },
    actions: {
        init({commit, dispatch}) {
            dispatch('request/get', {
                url: 'api/categories/get-all-data',
            }, {root: true})
                .then((obj) => {
                    for (const [tableName, columns] of Object.entries(obj.data)) {
                        for (const [columnName, data] of Object.entries(columns)) {
                            commit('data', {tableName, columnName, data})
                        }
                    }
                })
        },
        modifiedItemsUpdate({commit, getters, dispatch}, {tableName, columnName, itemId}) {
            let data = getters['get'](tableName, columnName, itemId)
            
            // Because state.data is already set by init(), if data is undefined it means that the provided columnName
            // is NOT a category column, and so no request is sent.
            if (data !== undefined) {
                dispatch('request/get', {
                    url: 'api/categories/get-table-column-item-data',
                    params: {
                        tableName,
                        columnName,
                        itemId
                    }
                }, {root: true})
                    .then((obj) => {
                        commit('itemData', {
                            tableName: tableName,
                            columnName: columnName,
                            itemId: itemId,
                            data: obj.data
                        })
                    })
            }
        }
    },
}