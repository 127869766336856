<template>
    <button @click="moveUp" :disabled="isDisabled" class="btn btn-dark btn-sm">
        <i class="bi bi-chevron-up"></i>
    </button>
</template>

<script>
export default {
    name: "PageEditorContentMoveUpBtn",
    computed: {
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        content() {
            return this.$store.state.pageEditor.contentItems.find(o => o.pageContentId === this.pageContentId)
        },
        isDisabled() {
            return !this.pageContentId || this.isFirst
        },
        isFirst() {
            return false

            const pageContent = this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)
            const contentItems = this.$store.state.pageContentData.items.filter(o => o.pageId === pageContent.pageId && o.contentArea === pageContent.contentArea)
            const index = contentItems.map(o => o.id).indexOf(this.pageContentId)
            return index === 0
        }
    },
    methods: {
        moveUp() {
            this.$store.dispatch('pageEditor/moveUp', {
                pageContentId: this.pageContentId
            })
        }
    }
}
</script>

<style scoped>
[disabled] {
    color: #999;
}
</style>