var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.codeEditorButtonText
    ? _c("CodemirrorShowModalBtn", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
          "modal-title": _vm.modalTitle,
          mode: _vm.mode,
        },
      })
    : !_vm.field.codeEditorButtonText
    ? _c("div", [
        _c("textarea", {
          ref: "codeMirror",
          staticStyle: { display: "none" },
          domProps: { value: _vm.value },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }