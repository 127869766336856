<template>
    <button class="dropdown-item d-flex align-items-center" type="button" @click="onClick">
        <span :class="{ 'bi-square': !lineWrapping, 'bi-check-square': lineWrapping }" class="me-2"/>
        Line wrapping
    </button>
</template>

<script>
export default {
    name: "CodemirrorLineWrappingBtn",
    computed: {
        lineWrapping() {
            return this.$store.getters['user/pref']('codeMirror_lineWrapping')
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch('user/setPref', {
                name: 'codeMirror_lineWrapping',
                value: !this.lineWrapping
            })
        }
    }
}
</script>

<style scoped>

</style>