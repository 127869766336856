var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.navigationGroups.length
    ? _c(
        "nav",
        {
          staticClass: "d-flex align-items-stretch border-bottom",
          attrs: { role: "navigation" },
        },
        [
          _c(
            "Button",
            {
              staticClass: "px-3 rounded-0 h-100 d-flex align-items-center",
              nativeOn: {
                click: function ($event) {
                  return _vm.$router.push({ name: "dashboard" })
                },
              },
            },
            [
              _c("img", {
                staticStyle: { width: "40px" },
                attrs: { src: _vm.logo, alt: "Go to dashboard" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("NavigationMobile"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-none d-md-flex flex-fill" },
            _vm._l(_vm.navigationGroups, function (groupName, index) {
              return _c("NavigationGroup", {
                key: index,
                attrs: { "group-name": groupName },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-items-stretch" },
            [
              _c("NavigationSupportBtn"),
              _vm._v(" "),
              _c("NavigationPreviewBtn"),
              _vm._v(" "),
              _c("NotificationsMenu"),
              _vm._v(" "),
              _c("NavigationUserMenu"),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }