<template>
    <div :class="{ 'mt-2': showAvatar }" class="position-relative">
        <UserAvatar class="position-absolute" v-if="showAvatar" :user-id="item.createdBy" :is-small="true"
                    :tooltip-placement="'right'"/>

        <div class="ms-4 ps-2 pt-1 small">
            <span v-if="item.componentId" class="breadcrumb mb-0" role="button" @click="goToItem">
                <span v-if="component" class="breadcrumb-item">{{ component.title }}</span>
                <span class="breadcrumb-item">{{ title }}</span>
            </span>
            <span v-else>
                {{ item.activity }}
            </span>

            <div>
                <small class="text-secondary">
                    <Timeago :datetime="item.createdDate * 1000"/>
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import UserAvatar from '../../common/UserAvatar'

export default {
    name: "DashboardActivityStreamItem",
    components: {
        UserAvatar,
    },
    props: {
        item: Object,
        getUserId: Function,
        setUserId: Function,
    },
    data() {
        return {
            showAvatar: false,
        }
    },
    computed: {
        component() {
            return this.$store.state.components.items
                .find(obj => obj.id === this.item.componentId)
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.item.componentId)
        },
    },
    asyncComputed: {
        title() {
            if (this.component) {
                return this.$store.dispatch('itemData/get', {
                        tableName: this.component.tableName,
                        id: this.item.rowId
                    })
                    .then((obj) => {
                        return obj[this.nameField]
                    })
            }
        }
    },
    created() {
        this.setShowAvatar()
    },
    methods: {
        goToItem() {
            let themesComponents = [
                'templates_templates',
                'themes_css',
                'themes_javascript',
                'themes_images',
                'themes_fonts',
            ]
            window.location = themesComponents.indexOf(this.component.tableName) > -1
                ? '/#/themes_editor/?section=' + this.component.tableName + '&id=' + this.item.rowId
                : '/#/' + this.component.tableName + '/' + this.item.rowId
        },
        setShowAvatar() {
            let currentUserId = this.getUserId()
            if (
                !currentUserId
                || currentUserId !== this.item.createdBy
            ) {
                this.showAvatar = true
                this.setUserId(this.item.createdBy)
            }
        }
    }
}
</script>

<style scoped>

</style>