var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex h-100 overflow-auto flex-column" },
    [
      _c("FormHeader", {
        attrs: { "form-id": _vm.formId, "show-save-btn": true },
      }),
      _vm._v(" "),
      _c("FormNavAndForm", {
        staticClass: "flex-grow-1",
        attrs: { "form-id": _vm.formId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }