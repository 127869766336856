<template>
    <i class="bi-grip-vertical" style="cursor: grab"/>
</template>

<script>
export default {
    name: "DragHandle"
}
</script>

<style scoped>

</style>