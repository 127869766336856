export default {
    namespaced: true,
    state() {
        return {
            propagatedContentOriginalData: [],
            propagatedContentData: []
        }
    },
    mutations: {
        propagatedContentData(state, data) {
            state.propagatedContentData.length = 0
            state.propagatedContentData = Object.assign(state.propagatedContentData, data)
    
            // Retain a copy of the data so that
            state.propagatedContentOriginalData = Object.assign([], state.propagatedContentData)
        }
    }
}