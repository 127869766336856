var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "form-control-tags-usage--heading" }, [
      _vm._v("\n        " + _vm._s(_vm.component.title) + "\n    "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group" },
      _vm._l(_vm.items, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass:
              "list-group-item form-control-tags-usage--list-group-item",
          },
          [
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onClick(item.id)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(item[_vm.nameField]) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.removeTag(item.id)
                  },
                },
              },
              [_c("i", { staticClass: "bi-trash3" })]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }