<template>
    <span>{{ formattedDate }}</span>
</template>

<script>
import moment from "moment/moment"

export default {
    name: "TableCellTypeDatetimeLocal",
    props: {
        value: String,
    },
    computed: {
        formattedDate() {
            if (this.value) {
                return moment(this.value).format('DD MMM YYYY, HH:mm')
            }
        }
    }
}
</script>

<style scoped>

</style>