var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-light",
      attrs: { type: "button" },
      on: { click: _vm.onClick },
    },
    [_vm._v("\n    " + _vm._s(_vm.field.buttonText || "Edit") + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }