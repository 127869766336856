var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "container" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        attrs: { name: _vm.field.name, required: "", tabindex: "-1" },
        domProps: { value: _vm.inputValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.inputValue = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.variableIds, function (variableId) {
        return _c(
          "button",
          {
            key: variableId,
            staticClass: "btn btn-light btn-sm m-1 ms-0 mt-0",
            class: {
              active: _vm.selectedVariableIds.indexOf(variableId) !== -1,
            },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.selectOption($event, variableId)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.titles[variableId]) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }