export default {
    namespaced: true,
    state: {
        componentIds: false,
        items: []
    },
    mutations: {
        componentIds(state, componentIds) {
            state.componentIds = componentIds
        },
        items(state, items) {
            state.items = items
        }
    },
    getters: {
        nameField: (state, getters, rootState, rootGetters) => (componentId) => {
            let component = state.items.find(o => o.id === componentId)
    
            let nameField = 'id'
            if (component.nameField) {
                const fields = rootGetters['componentStructure/get'](componentId)
                const field = fields.find(o => o.id === component.nameField)
                nameField = field.columnName
            } else if (component.displayName) {
                nameField = 'displayName'
            } else if (rootGetters['componentStructure/get'](componentId).find(o => o.columnName === 'title')) {
                nameField = 'title'
            }
            
            return nameField
        }
    },
    actions: {
        init({commit, dispatch}) {
            return dispatch('request/get', {
                url: 'api/component/modulecomponents',
                params: {
                    sort: 'displayOrder',
                }
            }, {root: true})
                .then((obj) => {
                    let items = obj.data.items
                    
                    dispatch('itemData/setItems', {
                        tableName: 'modulecomponents',
                        objs: items,
                    }, {root: true})
                    commit('items', items)
                    
                    let componentIds = {}
                    items.forEach((item) => {
                        componentIds[item.tableName] = item.id
                    })
                    commit('componentIds', componentIds)
                    
                    dispatch('navigation/setGroupsComponents', null, {root: true})
                })
        }
    }
}