<template>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Fields</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-xs-4 col-md-4">
                        <ContentTypeFieldsList/>
                        <ContentTypeNewAttributeBtn/>
                    </div>

                    <div class="col-xs-8 col-md-8">
                        <ContentTypeFieldEditForm v-if="activeEditId >= 0" :modal="modal"/>
                    </div>
                </div>
            </div>

            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
import ContentTypeFieldsList from './ContentTypeFieldsList'
import ContentTypeNewAttributeBtn from './ContentTypeNewAttributeBtn'
import ContentTypeFieldEditForm from './ContentTypeFieldEditForm'

export default {
    name: "ContentTypeFieldsModal",
    components: {
        ContentTypeFieldsList,
        ContentTypeNewAttributeBtn,
        ContentTypeFieldEditForm,
    },
    props: {
        modal: Object,
        options: Object,
    },
    data() {
        return {
            componentId: this.options.componentId,
        }
    },
    computed: {
        activeEditId() {
            return this.$store.state.componentFields.activeEditId
        },
    },
    destroyed() {
        this.$store.commit('componentFields/reset')
    },
    created() {
        this.$store.commit('componentFields/activeFieldsetId', 0)

        this.$store.dispatch('componentFields/init', {
            componentId: this.componentId
        })
    }
}
</script>

<style scoped>
.ghost {
    background: #666;
    border-color: #666;
    color: #fff;
}
</style>