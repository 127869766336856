export default {
    namespaced: true,
    state: {
        open: false,
        active: false,
        // Only get items from the last month.
        timeStamp: Math.floor(Date.now() / 1000) - (60 * 60 * 24 * 30),
        promise: undefined,
        items: []
    },
    mutations: {
        items(state, items) {
            state.items.length = 0
            state.items = state.items.concat(items)
        },
        promise(state, promise) {
            if (state.promise) {
                clearInterval(state.promise)
            }
            
            state.promise = promise
        }
    },
    actions: {
        openMenu({state, dispatch, commit}) {
            state.open = true
            state.active = false
            
            // Do an immediate check so that on page load the button becomes active immediately, or when
            // clicking the icon, the icon becomes deactivated immediately.
            dispatch('setItems')
            
            const promise = setInterval(() => {
                dispatch('setItems')
            }, 1000)
            
            commit('promise', promise)
        },
        hideMenu({state, commit}) {
            state.open = false
            
            commit('promise', 0)
        },
        setItems({state, commit, dispatch, rootState}) {
            // When a user JWT has expired the activity requests generate errors.
            if (!rootState.user.exists) {
                return
            }
            
            dispatch('request/get', {
                url: 'api/notifications',
            }, {root: true})
                .then((obj) => {
                    // If the number of items listed has changed, and the menu is closed, then
                    state.active = state.items.length !== obj.data.items.length
                        && !state.open
                    
                    commit('items', obj.data.items)
                })
        },
        removeItem({dispatch}, {itemId}) {
            dispatch('request/patch', {
                url: 'api/component/notification/' + itemId,
                postData: {
                    hidden: 1
                }
            }, {root: true})
        },
        clearActiveItems({state, dispatch}) {
            // This used to set activity items with isActive: 0, but has fallen over since migrating notifications
            // to the notification table. As there's no isActive column in that component there's no way for this to
            // work. Perhaps an alternative would be to enable publishing an use the status column.
        }
    }
}