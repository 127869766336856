<template>
    <div v-if="content && !content.isArchived" :class="{ 'selected': isSelected, 'selected-secondary': isLastSelected }"
         :data-content-id="pageContentId" class="site-tree-content-item"
    >
        <a class="site-tree-content-title" href @click.prevent="selectContent(pageContentId)">
            {{ content.title }}
        </a>

        <ContentTypeLabel v-if="content" :content-type-id="content.contentType"/>

        <i v-show="content.groupAccess && content.groupAccess.length" class="bi-person-fill"
           title="This page is assigned group access"/>

        <small v-if="content" class="badge bg-secondary">
            <ContentUsageCount :page-content-id="pageContentId"/>
        </small>

        <i v-if="isUnpublished" class="bi-x-circle-fill" title="Unpublished"/>

        <i v-if="isScheduled"
           :class="{ 'text-success': isPending, 'text-warning': isExpiring, 'text-danger': isExpired }"
           :title="schedulingTitle" class="bi-clock-history"/>
    </div>
</template>

<script>
import ContentTypeLabel from '../../common/ContentTypeLabel'
import ContentUsageCount from '../../common/ContentUsageCount'

export default {
    name: "SiteTreeContentItem",
    components: {
        ContentTypeLabel,
        ContentUsageCount,
    },
    props: {
        pageContentId: Number,
        siteTreeId: String,
        selectContent: Function,
    },
    asyncComputed: {
        content() {
            return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
                .then((obj) => {
                    return obj
                })
        }
    },
    computed: {
        contentId() {
            return this.$store.state.pageContentData.items.filter((obj) => {
                return (
                    obj.id === this.pageContentId
                )
            })[0].contentId
        },
        selectedContentId() {
            return this.$store.state[this.siteTreeId]?.selectedContentId
        },
        lastSelectedContentId() {
            return this.$store.state[this.siteTreeId]?.lastSelectedContentId
        },
        isPending() {
            let now = Date.now() / 1000
            return (
                this.content
                && this.content.validFrom
                && this.content.validFrom >= now
            )
        },
        isUnpublished() {
            return (
                this.content
                && this.content.status === 0
            )
        },
        isScheduled() {
            return (
                this.isPending
                || this.isExpiring
                || this.isExpired
            )
        },
        isExpiring() {
            let now = Date.now() / 1000
            return (
                this.content
                && this.content.validTo
                && this.content.validTo >= now
            )
        },
        isExpired() {
            let now = Date.now() / 1000
            return (
                this.content
                && this.content.validTo
                && this.content.validTo <= now
            )
        },
        schedulingTitle() {
            switch (true) {
                case this.isPending:
                    this.schedulingTitle = 'Pending'
                    break
                case this.isExpiring:
                    this.schedulingTitle = 'Expiring'
                    break
                case this.isExpired:
                    this.schedulingTitle = 'Expired'
                    break
            }
        },
        isSelected() {
            return this.selectedContentId === this.pageContentId
        },
        isLastSelected() {
            return (
                this.selectedContentId === 0
                && this.lastSelectedContentId === this.pageContentId
            )
        },
    }
}
</script>

<style scoped>
.site-tree-content-item {
    display: flex;
    align-items: center;
    padding: 3px 5px;
}

.site-tree-content-item > * {
    margin-left: 5px;
}

.site-tree-content-item > :first-child {
    margin-left: 0;
}

.site-tree-content-title {
    color: #000;
    margin-left: 0;
    padding: 3px 5px;
    border-radius: 10px;
    text-decoration: none;
}

.site-tree-content-title:hover {
    text-decoration: none;
    color: #333;
}

.site-tree-content-item.selected > .site-tree-content-title {
    background: #fff;
    color: #1a73e8;
    font-weight: 600;
}

.site-tree-content-item.selected-secondary > .site-tree-content-title {
    background: #999;
    color: #fff;
}
</style>