var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "dropdown-item d-flex align-items-center",
      attrs: { type: "button" },
      on: { click: _vm.openThemesEditorAddModal },
    },
    [_c("i", { staticClass: "me-2 bi-plus-lg" }), _vm._v("\n    New\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }