<template>
    <a v-if="user.administrator" href @click.prevent="onClick">{{ value }}</a>
    <span v-else>{{ value }}</span>
</template>

<script>
export default {
    name: "TableCellOrderItemsSku",
    props: {
        item: Object,
        value: String,
    },
    computed: {
        user() {
            return this.$store.state.user.user
        }
    },
    methods: {
        onClick() {
            this.$router.push({
                name: 'form',
                params: {
                    component: 'm_basket_orderitems',
                    id: this.item.id
                }
            })
        }
    }
}
</script>

<style scoped>

</style>