var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "Button",
        {
          staticClass: "rounded-0",
          attrs: {
            disabled: _vm.isSaving,
            title: "Save and close",
            "active-class": "btn-warning",
            "active-value": _vm.isModified,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-chevron-right" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }