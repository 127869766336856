var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "d-flex flex-column h-100" }, [
    _c("header", [
      _c("h2", { staticClass: "h5 mb-1" }, [
        _vm._v(_vm._s(_vm.contentAreaName)),
        _c("br"),
        _c("small", { staticClass: "lh-1 text-body-tertiary" }, [
          _vm._v("area"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-fill py-4" },
      [_c("PageEditorAddContentBtn")],
      1
    ),
    _vm._v(" "),
    _c("footer"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }