<template>
    <span>{{ formattedDate }}</span>
</template>

<script>
import moment from "moment"

export default {
    name: "TableCellTypeDateDate",
    props: {
        value: String,
    },
    computed: {
        formattedDate() {
            if (this.value) {
                return moment(this.value).format("DD MMM YYYY")
            }
        }
    }
}
</script>

<style scoped>

</style>