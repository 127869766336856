<template>
    <span
        v-if="value"
        :class="className"
        class="badge"
        style="text-transform: capitalize"
    >{{ value }}</span>
</template>

<script>
export default {
    name: "TableCellEmailEventEvent",
    props: {
        value: String,
    },
    computed: {
        className() {
            let labels = {
                accepted: 'bg-warning text-dark',
                rejected: 'bg-danger',
                delivered: 'bg-success',
                failed: 'bg-danger',
                opened: 'bg-success',
                clicked: 'bg-success',
                unsubscribed: 'bg-danger',
                complained: 'bg-danger',
                stored: 'bg-warning text-dark'
            }

            if (
                this.value
                && labels.hasOwnProperty(this.value)
            ) {
                return labels[this.value]
            }
        }
    }
}
</script>

<style scoped>

</style>