export default store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        
        // Not all mutations should reload the view. For example, doing it for onMetaClick would cause the view to
        // refresh everytime the user selected an item.
        let permittedViewMutations = [
            'onMetaClick',
            'onShiftClick',
            'selectAll',
            'setGroupLoadedCategoryData',
        ]
        
        if (
            mutation.type.indexOf('view:') === 0
            // The view:<viewId>:setGroupLoadedCategoryData is mutated from inside the store itself, so it must be
            // ignored here else it will create a loop.
            && permittedViewMutations.indexOf(mutation.type.split('/').pop()) === -1
        ) {
            //console.log('mutation', mutation.type);
            
            let m = mutation.type.split('/')[0]
            //if (store.hasModule(m)) {
            store.dispatch(m + '/scheduleLoad')
            //}
        }
        
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    })
};