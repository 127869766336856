<template>
    <nav v-if="navigationGroups.length" class="d-flex align-items-stretch border-bottom" role="navigation">
        <Button @click.native="$router.push({name: 'dashboard'})" class="px-3 rounded-0 h-100 d-flex align-items-center">
            <img :src="logo" alt="Go to dashboard" style="width: 40px">
        </Button>

        <NavigationMobile/>

        <div class="d-none d-md-flex flex-fill">
            <NavigationGroup v-for="(groupName, index) in navigationGroups" :key="index" :group-name="groupName"/>
        </div>

        <div class="d-flex align-items-stretch">
            <NavigationSupportBtn/>
            <NavigationPreviewBtn/>
            <NotificationsMenu/>
            <NavigationUserMenu/>
        </div>
    </nav>
</template>

<script>
import NavigationGroup from './NavigationGroup'
import NavigationMobile from './NavigationMobile'
import NavigationSupportBtn from './NavigationSupportBtn'
import NavigationPreviewBtn from './NavigationPreviewBtn'
import NotificationsMenu from '../notifications-menu/NotificationsMenu'
import NavigationUserMenu from './NavigationUserMenu'
import Button from "../Button.vue"

export default {
    name: "Navigation",
    components: {
        Button,
        NavigationGroup,
        NavigationMobile,
        NavigationSupportBtn,
        NavigationPreviewBtn,
        NotificationsMenu,
        NavigationUserMenu
    },
    computed: {
        logo() {
            return require('../../img/creativecms-logo-icons.png')
        },
        navigationGroups() {
            return this.$store.state.navigation.groups
        }
    }
}
</script>

<style scoped>

</style>