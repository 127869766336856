var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.item.value,
          expression: "item.value",
        },
      ],
      staticClass:
        "form-control form-control-sm form-control-color d-inline-block rounded-0 rounded-start",
      attrs: { type: "color" },
      domProps: { value: _vm.item.value },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.item, "value", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.item.value,
          expression: "item.value",
        },
      ],
      staticClass:
        "form-control form-control-sm d-inline-block w-auto rounded-0 rounded-end border-start-0",
      attrs: {
        maxlength: "7",
        minlength: "7",
        placeholder: "#000000",
        size: "8",
      },
      domProps: { value: _vm.item.value },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.item, "value", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }