var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentData.type === "currency"
    ? _c("FormControlTypeCurrency", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm.currentData.type === "date"
    ? _c("FormControlTypeCheckbox", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm.currentData.type === "number"
    ? _c("FormControlTypeNumber", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm.currentData.type === "select"
    ? _c("FormControlTypeText", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm.currentData.type === "text"
    ? _c("FormControlTypeText", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm.currentData.type === "textarea"
    ? _c("FormControlTypeTextarea", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm.currentData.type === "texteditor"
    ? _c("FormControlTypeTextarea", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }