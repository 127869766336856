<template>
    <section v-if="items?.length">
        <div class="ms-2">
            <label class="form-label mb-1 small">Unassigned content</label>
            <p class="form-text mt-0 mb-2" style="font-size: 13px">
                Content not assigned to a page template's content area will not be displayed.
            </p>
        </div>

        <div class="list-group">
            <PageContentOrphanedItem v-for="item in items" :key="item.id" :page-content-data-item="item"
                                     :last-content-area="lastContentArea" class="list-group-item"/>
        </div>
    </section>
</template>

<script>
import PageContentOrphanedItem from "./PageContentOrphanedItem.vue"

export default {
    name: "PageContentOrphaned",
    components: {PageContentOrphanedItem},
    props: {
        pageId: Number,
        contentAreas: Array
    },
    computed: {
        items() {
            if (this.contentAreas) {
                return this.$store.state.pageContentData.items.filter((o) => {
                    return (
                        o.pageId === this.pageId
                        && this.contentAreas.indexOf(o.contentArea) === -1
                    )
                })
            }
        },
        lastContentArea() {
            const l = this.contentAreas.length
            return this.contentAreas[l - 1]
        }
    }
}
</script>

<style scoped>

</style>