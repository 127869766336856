<template>
    <input type="datetime-local" v-model="item.value" class="form-control form-control-sm d-inline-block w-auto"/>
</template>

<script>

export default {
    name: "CollectionFilterControlTypeDate",
    props: {
        field: Object,
        item: Object
    },
}
</script>

<style scoped>

</style>