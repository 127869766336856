var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SidebarToggle",
    {
      staticClass: "d-flex h-100 overflow-auto",
      attrs: { name: "site_tree" },
      scopedSlots: _vm._u([
        {
          key: "leftSidebar",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "m-3 me-2 flex-grow-1" },
                [
                  _c("SiteTreeNavigationMenu", {
                    staticClass: "mb-3",
                    attrs: { "site-tree-id": _vm.siteTreeId },
                  }),
                  _vm._v(" "),
                  _c("SiteTree", {
                    attrs: {
                      "select-content": _vm.selectContent,
                      "select-page": _vm.selectPage,
                      "site-tree-id": _vm.siteTreeId,
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "d-flex h-100 overflow-auto flex-grow-1 flex-column" },
        [
          _vm.pageEditorMode && _vm.pageId
            ? [
                _c("PageEditorIframe", {
                  key: _vm.pageId,
                  attrs: { "site-tree-page-id": _vm.pageId },
                }),
              ]
            : [
                _vm.formId
                  ? _c("FormHeader", {
                      attrs: {
                        "form-id": _vm.formId,
                        "show-add-btn": true,
                        "show-save-and-close-btn": _vm.showSaveAndCloseBtn,
                        "show-save-btn": true,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formId
                  ? _c("FormNavAndForm", {
                      attrs: {
                        "form-id": _vm.formId,
                        "show-archive-btn": true,
                        "show-duplicate-btn": true,
                        "show-history-btn": true,
                      },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }