var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "list-group-item py-1 pe-2 d-flex align-items-center",
      attrs: { title: "#c" + _vm.pageContentLinkId },
    },
    [
      _c(
        "div",
        { staticClass: "flex-fill" },
        [
          _c("FormPageContentField", {
            attrs: { "content-id": _vm.pageContentData.contentId },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ContentUsageCount", {
        staticClass: "me-2 lh-1",
        attrs: { "page-content-id": _vm.pageContentData.id, "show-menu": true },
      }),
      _vm._v(" "),
      _c("ItemStatusIcons", {
        attrs: { id: _vm.content.id, "table-name": "content" },
      }),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "btn-sm",
          nativeOn: {
            click: function ($event) {
              return _vm.goToContent.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-pencil-square" })]
      ),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "btn-sm",
          attrs: { disabled: !_vm.contentIndex && _vm.contentAreaFirst },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.moveUp.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-chevron-up" })]
      ),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "btn-sm",
          attrs: { disabled: _vm.contentLast && _vm.contentAreaLast },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.moveDown.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-chevron-down" })]
      ),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "btn-sm",
          nativeOn: {
            click: function ($event) {
              return _vm.removeContent.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-x-lg" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }