<template>
    <FormControlTypeCheckbox
        v-if="show"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />
</template>

<script>
import FormControlTypeCheckbox from '../form-control-type/FormControlTypeCheckbox'

export default {
    name: "FormControlTemplatesTemplatesDisplayInSitemap",
    components: {
        FormControlTypeCheckbox,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        show() {
            if (
                this.currentData.contentType === 'Listing'
                && this.currentData.componentId
            ) {
                this.field.visibility = ''
                return true
            }

            this.field.visibility = 'hidden'

            return false
        }
    },
}
</script>

<style scoped>

</style>