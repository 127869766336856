<template>
    <span v-if="value" class="table-cell-hidden-icon-parent">
        {{ value }}
        <a :href="value" class="table-cell-hidden-icon">
            <i class="bi-box-arrow-up-right"/>
        </a>
    </span>
</template>

<script>
export default {
    name: "TableCellTypeUrl",
    props: {
        value: String
    },
}
</script>

<style scoped>

</style>