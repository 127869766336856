var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasEditAccess && _vm.viewMode === "thumbnail"
    ? _c(
        "Button",
        {
          staticClass: "rounded-0",
          class: { disabled: _vm.selectedIds.length !== 1 },
          attrs: { title: "Edit" },
          nativeOn: {
            click: function ($event) {
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-pencil-square" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }