<template>
    <div class="list-group">
        <a v-for="(fileName, objectName, index) in currentData[field.name]" :key="index" class="list-group-item" href
           @click.prevent="openFile(objectName)">
            {{ fileName }}
        </a>
    </div>
</template>

<script>
export default {
    name: "FormControlEmailMessageAttachments",
    props: {
        currentData: Object,
        field: Object,
    },
    methods: {
        openFile(objectName) {
            window.open(
                'https://storage.googleapis.com/' +
                'creativecms-london--' + this.$store.state.sitename + '/' +
                objectName
            )
        }
    }
}
</script>

<style scoped>

</style>