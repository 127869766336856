var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Payment log")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _vm.items
          ? _c(
              "div",
              { staticClass: "list-group" },
              _vm._l(_vm.items, function (item) {
                return _c("div", { staticClass: "list-group-item" }, [
                  _c("div", [
                    _vm._v(
                      "\n                        £" +
                        _vm._s(item.amount) +
                        " - " +
                        _vm._s(item.label) +
                        "\n                        "
                    ),
                    item.paymentNote
                      ? _c("span", [_vm._v(" - " + _vm._s(item.paymentNote))])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "date font-monospace" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.getDate(item.date)) +
                        "\n                        "
                    ),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }