var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "d-flex align-items-center",
      attrs: { type: "button" },
      on: { click: _vm.openModal },
    },
    [
      _c("i", { staticClass: "me-2 bi-list-columns-reverse" }),
      _vm._v("\n    Columns\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }