<template>
    <OrdersEmails
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />
</template>

<script>
import OrdersEmails from '../form-control/OrdersEmails'

export default {
    name: "FormControlMBasketOrdersInternalEmailMessageId",
    components: {
        OrdersEmails
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
}
</script>

<style scoped>

</style>