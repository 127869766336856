<template>
    <Button :disabled="isDisabled" class="rounded-0" @click.native="onClick">
        <i class="bi-chevron-left"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsPreviousBtn",
    components: {Button},
    props: {
        viewId: String,
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        page() {
            return this.$store.state[this.viewId].page
        },
        isDisabled() {
            return this.page === 1
        },
        isNamespacedListing() {
            return this.listingName.indexOf('.') > -1
        }
    },
    methods: {
        onClick() {
            if (this.page > 1) {
                this.$store.commit(this.viewId + '/pageDown')

                if (!this.isNamespacedListing) {
                    const query = this.$route.query
                    this.$router.push({
                        query: {
                            ...query,
                            page: this.$store.state[this.viewId].page
                        }
                    })
                }
            }
        }
    }
}
</script>

<style scoped>

</style>