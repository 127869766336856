var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showEditable
    ? _c(
        "div",
        [
          _c("FormControlTypeCheckbox", {
            attrs: {
              "current-data": _vm.currentData,
              field: _vm.field,
              "form-id": _vm.formId,
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "ms-3" }, [
            _c("label", [
              _c("small", [_vm._v("Make editable:")]),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "checkbox" },
                on: { checked: _vm.isEditable, click: _vm.toggleEditable },
              }),
            ]),
          ]),
        ],
        1
      )
    : _c("FormControlTypeCheckbox", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }