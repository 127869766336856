var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass:
        "w-100 text-start btn-sm p-1 ps-2 d-flex align-items-center visible-on-hover",
      attrs: { "active-class": "btn-primary", "active-value": _vm.isActive },
      nativeOn: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex-fill overflow-hidden text-nowrap small" },
        [
          _c("UserTrackingAvatar", {
            attrs: { "item-id": _vm.item.id, "table-name": _vm.tableName },
          }),
          _vm._v("\n\n        " + _vm._s(_vm.item.title) + "\n\n        "),
          _vm._v(" "),
          _vm.item.mode === "text/x-scss"
            ? _c("span", { staticClass: "ms-1 badge text-primary-emphasis" }, [
                _vm._v("SCSS"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.mode === "text/x-less"
            ? _c("span", { staticClass: "ms-1 badge text-primary-emphasis" }, [
                _vm._v("LESS"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("ThemesEditorEditBtn", { attrs: { item: _vm.item } }),
      _vm._v(" "),
      _c("ThemesEditorUsage", {
        staticClass: "ms-1",
        attrs: { id: _vm.item.id },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }