var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "dropdown",
    },
    [
      _c(
        "Button",
        {
          ref: "button",
          staticClass: "px-3 h-100 btn-sm rounded-0",
          class: { active: _vm.groupName === _vm.activeGroup },
          attrs: { "data-bs-toggle": "dropdown" },
        },
        [_vm._v("\n        " + _vm._s(_vm.groupName) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dropdown-menu" },
        [
          _vm._l(_vm.components, function (component) {
            return _c(
              "button",
              {
                key: component.id,
                staticClass: "dropdown-item",
                class: {
                  active:
                    _vm.$store.state.navigationComponent ===
                    component.tableName,
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.goTo(component.tableName)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(component.title) + "\n        "
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm.formAccess
            ? _c(
                "button",
                {
                  staticClass: "dropdown-item",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.openNewContentTypeModal(true)
                    },
                  },
                },
                [_vm._v("\n            + New form\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.createContentTypeAccess()
            ? _c(
                "button",
                {
                  staticClass: "dropdown-item",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.openNewContentTypeModal()
                    },
                  },
                },
                [_vm._v("\n            + New collection\n        ")]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }