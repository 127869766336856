<template>
    <span>{{ displayOrder }}</span>
</template>

<script>
export default {
    name: "TableCellDisplayOrder",
    props: {
        viewId: String,
        item: Object,
    },
    computed: {
        displayOrder() {
            return this.$store.state.displayOrders.data[this.tableName][this.item.id]
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
    }
}
</script>

<style scoped>

</style>