var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dateString
    ? _c("FormControlPlainText", { attrs: { value: _vm.dateString } })
    : _c("FormControlPlainText", [
        _c("i", { staticClass: "small text-dark-emphasis" }, [_vm._v("unset")]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }