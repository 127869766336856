var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Import CSV")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        !_vm.importComplete
          ? _c("div", [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("AttachmentDropzone", {
                    attrs: {
                      "set-file": _vm.setFile,
                      "use-import-service": true,
                    },
                  }),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("div", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n                        Processing data…\n                    "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.importingMessage
                    ? _c("div", { staticClass: "text-center" }, [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.importingMessage) },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-offset-4 col-sm-4" },
                            [
                              _c("ProgressBar", {
                                attrs: { percentage: _vm.percentage },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors.length && !_vm.loading
                    ? _c("div", { staticClass: "panel panel-danger" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "list-group" },
                          _vm._l(_vm.errors, function (error, index) {
                            return _c("li", {
                              key: index,
                              staticClass: "list-group-item",
                              domProps: { innerHTML: _vm._s(error) },
                            })
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.error
                    ? _c("div", { staticClass: "panel panel-danger" }, [
                        _c("div", { staticClass: "card-header" }, [
                          _c("h3", {
                            staticClass: "panel-title",
                            domProps: { innerHTML: _vm._s(_vm.error.title) },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "card-body",
                          domProps: { innerHTML: _vm._s(_vm.error.body) },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rowCount && !_vm.loading && !_vm.importingMessage
                    ? _c("div", { staticClass: "panel panel-info" }, [
                        _c("div", { staticClass: "card-header" }, [
                          _c("h3", { staticClass: "panel-title" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.rowCount) + " rows"),
                            ]),
                            _vm._v(
                              " ready to import\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: { type: "button" },
                                on: { click: _vm.importData },
                              },
                              [
                                _vm._v(
                                  "\n                                    Import\n                                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.importComplete
                ? _c(
                    "div",
                    { staticClass: "alert alert-success text-center" },
                    [
                      _vm._v(
                        "\n                    Import successful!\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", { staticClass: "panel-title" }, [
        _vm._v("The data contains the following error(s)."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }