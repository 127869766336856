var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-dialog modal-fullscreen text-light" },
    [
      _c("div", { staticClass: "modal-content bg-transparent" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("button", {
            staticClass: "btn-close btn-close-white fs-4 opacity-100",
            attrs: { "aria-label": "Close", type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("hide-modal")
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modal-body d-flex align-items-center text-center",
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.$emit("hide-modal")
              },
            },
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-lg btn-link fs-1 text-light",
                class: { invisible: _vm.itemNumber <= 1 },
                attrs: { type: "button" },
              },
              [
                _c("i", {
                  staticClass: "bi-chevron-left",
                  on: { click: _vm.showPrevious },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex-fill" }, [
              _c("img", {
                staticClass: "shadow",
                attrs: { alt: _vm.item.title, src: _vm.item.src },
              }),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-lg btn-link fs-1 text-light",
                class: { invisible: _vm.itemNumber >= _vm.itemCount },
                attrs: { type: "button" },
              },
              [
                _c("i", {
                  staticClass: "bi-chevron-right",
                  on: { click: _vm.showNext },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex align-items-center px-3 fs-6 text-light" },
          [
            _c("p", { staticClass: "flex-fill" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.item.title) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "count" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.itemNumber) +
                  " of " +
                  _vm._s(_vm.itemCount) +
                  "\n            "
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }