<template>
    <span>{{ contentTypeTitle }}</span>
</template>

<script>
export default {
    name: "PageEditorToolbarContentDropdownContentType",
    props: {
        pageContentId: Number
    },
    computed: {
        contentId() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)?.contentId
        },
        contentTypeTitle() {
            return this.$store.getters['templates/contentTypes'].find(o => o.id === this.content?.contentType)?.title
        }
    },
    asyncComputed: {
        content() {
            if (this.contentId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
            }
        }
    }
}
</script>

<style scoped>

</style>