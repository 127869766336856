<template>
    <button v-if="show" class="btn btn-light btn-sm" type="button" @click="login">
        Login
    </button>
</template>

<script>
export default {
    name: "FormControlMRegistrantsRegistrantsLogin",
    props: {
        currentData: Object,
        field: Object,
    },
    asyncComputed: {
        show() {
            // First check if the customer has a username.
            if (
                !this.currentData.username
                || this.currentData.username.trim().length === 0
            ) {
                return false
            }

            let categoryIds = this.$store.getters['categories/get']('m_registrants_registrants', 'contactGroup', this.currentData.id)
                .map(o => o.catId)

            // Now check that they're assigned to an access group.

            if (categoryIds.length) {
                return this.$store.dispatch('itemData/preload', {
                        tableName: 'm_registrants_groups',
                        ids: categoryIds,
                    })
                    .then((objs) => {
                        return objs.filter(o => !!o.accessGroup).length > 0
                    })
            }

            return false
        }
    },
    watch: {
        show() {
            this.field.hidden = !this.show
        }
    },
    methods: {
        login() {
            this.$store.dispatch('request/get', {
                    url: 'api/customer/get-auto-login-key',
                    params: {
                        customerId: this.currentData.id
                    }
                })
                .then((obj) => {
                    if (obj.data.key) {
                        window.open(
                            this.$store.state.websiteEndpoint + '/controller/Customers/autoLogin/' + obj.data.key
                        )
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>