var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.componentName, {
    tag: "component",
    attrs: {
      "current-data": _vm.currentData,
      field: _vm.field,
      "form-id": _vm.formId,
      value: _vm.currentData[_vm.field.name],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }