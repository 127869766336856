<template>
    <div>
        <form class="mb-3" @submit.prevent="createTag">
            <div>
                <input v-model="tagStr" class="form-control" ng-select type="text">

                <button class="btn btn-light" type="submit">
                    <i class="bi-plus-lg"/>
                </button>
            </div>
        </form>

        <div class="d-flex align-items-center">
            <span v-for="(tag, index) in tags" :key="tag.id" class="me-2 bg-dark text-light border rounded p-1 ps-2 d-flex align-items-center">
                {{ tag.tag }}
                <button aria-label="Close" class="btn-close btn-close-white ms-1" type="button" @click="removeTag(index)"></button>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "TagsForm",
    props: {
        tableName: String,
        id: Number
    },
    data() {
        return {
            tags: [],
            tagStr: '',
        }
    },
    methods: {
        setTags() {
            this.$store.dispatch('request/get', {
                    url: 'api/tags/tag-ids/' + this.tableName + '/' + this.id
                })
                .then((obj) => {
                    let tagIds = obj.data.tagIds
                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'tags',
                        ids: tagIds,
                    })
                })
                .then((objs) => {
                    this.tags = objs
                })
        },

        createTag() {
            let str = this.tagStr

            if (/,/.test(str)) {
                let array = str.split(',')
                array.forEach((str) => {
                    if (str.length) {
                        this.saveTag(str.trim())
                    }
                })
            } else {
                this.saveTag(str)
            }
        },

        removeTag(i) {
            let tag = this.tags[i]
            this.tags.splice(i, 1)
            this.$store.dispatch('request/post', {
                url: 'api/tags/remove-tag-from-item',
                postData: {
                    tableName: this.tableName,
                    itemId: this.id,
                    tagId: tag.id
                }
            })
        },

        saveTag(tag) {
            this.$store.dispatch('request/post', {
                    url: 'api/tags/save-tag',
                    postData: {
                        tableName: this.tableName,
                        itemId: this.id,
                        tag: tag
                    }
                })
                .then((obj) => {
                    let item = obj.data.item
                    this.tags.push(item)
                    this.tagStr = ''
                })
        }
    },
    created() {
        this.setTags()
    }
}
</script>

<style scoped>

</style>