var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.href
    ? _c("a", { attrs: { href: _vm.href, title: _vm.fileName } }, [
        _vm._v(_vm._s(_vm.fileName)),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }