var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.categoryTableName
      ? _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { type: "button" },
            on: { click: _vm.selectCategory },
          },
          [
            !_vm.value ? _c("span", [_vm._v("select")]) : _vm._e(),
            _vm._v(" "),
            _vm.value
              ? _c("FormControlCategoryTitle", {
                  attrs: { id: _vm.value, "table-name": _vm.categoryTableName },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }