var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.contentIds.length,
          expression: "contentIds.length",
        },
      ],
      staticClass: "site-tree-content-area clearfix",
    },
    [
      _c("div", { staticClass: "site-tree-content-area-label" }, [
        _vm._v("\n        " + _vm._s(_vm.contentArea) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "site-tree-content-area-inner" },
        _vm._l(_vm.contentIds, function (contentId) {
          return _c("SiteTreeContentItem", {
            key: contentId,
            attrs: {
              "page-content-id": contentId,
              "select-content": _vm.selectContent,
              "site-tree-id": _vm.siteTreeId,
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }