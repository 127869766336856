<template>
    <div>
        <p class="text-center">
            <span class="btn-group">
                <button :disabled="selectedIds.length === 0" class="btn btn-light" type="button"
                        @click="sendConfirmationEmails">
                    Confirmation email
                </button>
                <button :disabled="selectedIds.length === 0" class="btn btn-light" type="button"
                        @click="sendEquipmentSalesEmails">
                    Equipment sales email
                </button>
                <button :disabled="selectedIds.length === 0" class="btn btn-light" type="button"
                        @click="sendRequestReviewEmails">
                    Request review email
                </button>
            </span>
            <span class="btn-group">
                <button :disabled="selectedIds.length === 0" class="btn btn-light" type="button"
                        @click="printDocument(104, 'Arrival sheet', {})">
                    Arrivals sheet
                </button>
                <button :disabled="selectedIds.length === 0" class="btn btn-light" type="button"
                        @click="printAirportTransferSheet(0)">
                    Pickups
                </button>
                <button :disabled="selectedIds.length === 0" class="btn btn-light" type="button"
                        @click="printAirportTransferSheet(1)">
                    Departures
                </button>
            </span>
            <button :disabled="selectedIds.length === 0" class="btn btn-light" type="button" @click="archiveBookings">
                Archive bookings
            </button>
        </p>

        <table class="table">
            <thead>
            <tr>
                <td>
                    <input :checked="allSelected" type="checkbox" @click="toggleAllCheckboxes">
                </td>
                <td v-for="(title, name) in columns" :key="name" @click="sortColumns(name)">
                    <i v-show="orderBy === name || orderBy === '-' + name"
                       :class="{ 'bi-caret-down-fill': name === orderBy && orderDesc, 'bi-caret-up-fill': name === orderBy && !orderDesc }"/>
                    {{ title }}
                </td>
            </tr>
            </thead>

            <tbody>
            <tr v-for="row in orderedRows" :key="row.id">
                <td>
                    <input :checked="selectedIds.indexOf(row.id) > -1" type="checkbox" @click="selectCheckbox(row.id)">
                </td>
                <td>
                    <a href @click.prevent="goToBooking(row.id)">
                        {{ row.id }}
                    </a>
                </td>
                <td>
                    {{ row.firstName }}
                </td>
                <td>
                    {{ row.lastName }}
                </td>
                <td>
                    <i v-if="customerIdsWithConsecutiveBookings?.indexOf(row.customerId) > -1" class="bi bi-check2"></i>
                </td>
                <td>
                    {{ row.accommodation }}
                </td>
                <td>
                    {{ row.tandemFlight ? '€200 extra' : '' }}
                </td>
                <td>
                    {{ row.trikeFlyingWithWheels ? '€300 extra' : '' }}
                </td>
                <td>
                    <input v-model="row.price" class="form-control input-sm" size="5"
                           @input="saveAmount($event, row.id, 'price')">
                </td>
                <td>
                    <input v-model="row.depositAmountPaid" class="form-control input-sm" size="5"
                           @input="saveAmount($event, row.id, 'depositAmountPaid')">
                </td>
                <td>
                    <input v-model="row.finalPaymentAmountPaid" class="form-control input-sm" size="5"
                           @input="saveAmount($event, row.id, 'finalPaymentAmountPaid')">
                </td>
                <!--<td>{{ (row.confirmationEmailLastSent ? row.confirmationEmailLastSent * 1000 : null) | date:'d/M/yy HH:mm' }}</td>-->
                <td>
                    {{ row.equipmentSalesEmailLastSent ? getDate(row.equipmentSalesEmailLastSent * 1000) : null }}
                </td>
                <td>
                    <button :class="{ 'active': row.notes }" class="btn btn-light btn-sm" type="button"
                            @click="showNotes(row)">
                        Edit
                    </button>
                </td>
            </tr>
            </tbody>

        </table>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "FormControlCoursesDatesBookings",
    props: {
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            orderBy: 'id',
            orderDesc: false,
            selectedIds: [],
            allSelected: false,
            notesRow: {},
            notes: '', // Textarea v-model
            columns: {
                id: 'ID',
                firstName: 'First name',
                lastName: 'Last name',
                multiple: '',
                accommodation: 'Accommodation',
                tandemFlight: 'Tandem flight',
                trikeFlyingWithWheels: 'Trike',
                price: 'Course price',
                depositAmountPaid: 'Deposit payment',
                finalPaymentAmountPaid: 'Final payment',
                //confirmationEmailLastSent: 'Confirmation email',
                equipmentSalesEmailLastSent: 'Equipment sales',
                notes: 'Notes'
            }
        }
    },
    computed: {
        orderedRows() {
            let rows = this.rows?.sort((a, b) => (a[this.orderBy] > b[this.orderBy]) ? 1 : -1)

            if (this.orderDesc) {
                rows.reverse()
            }

            return rows
        },
        courseDateId() {
            return this.currentData.id
        },
        customerIds() {
            return this.bookings?.map(o => o.customerId)
        }
    },
    asyncComputed: {
        courseDate() {
            return this.$store.dispatch('itemData/get', {
                    tableName: 'courses_dates',
                    id: this.currentData.id
                })
                .then((obj) => {
                    return obj
                })
        },
        bookings() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/courses_bookings',
                    params: {
                        courseDateId: this.courseDateId,
                        isArchived: 0,
                    }
                })
                .then((obj) => {
                    return obj.data.items
                })
        },
        customers() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/m_registrants_registrants',
                    params: {
                        m_registrants_registrantsID: this.customerIds
                    }
                })
                .then((obj) => {
                    return obj.data.items
                })
        },
        customerIdsWithConsecutiveBookings() {
            if (this.courseDate && this.customerIds?.length) {
                let bookings = []

                return this.$store.dispatch('request/get', {
                        url: 'api/component/courses_bookings',
                        params: {
                            customerId: this.customerIds
                        }
                    })
                    .then((obj) => {
                        bookings = obj.data.items
                        const courseDateIds = bookings.map(o => o.courseDateId)

                        const start = moment(this.courseDate.endDate).subtract(1, 'days').format('Y-MM-DD')
                        const end = moment(this.courseDate.endDate).add(2, 'days').format('Y-MM-DD')

                        return this.$store.dispatch('request/get', {
                            url: 'api/component/courses_dates',
                            params: {
                                field: 'id',
                                id: courseDateIds,
                                startDate: {gt: start, lt: end}
                            }
                        })
                    })
                    .then((obj) => {
                        const courseDateIds = obj.data.values

                        // If a match was found, backtrack through the data to determine which customers the
                        // course dates apply to.
                        if (courseDateIds.length) {
                            bookings = bookings.filter(o => courseDateIds.indexOf(o.courseDateId) > -1)
                            return bookings.map(o => o.customerId)
                        }
                    })
            }
        },
        rows() {
            let rows = []

            this.bookings?.forEach((booking) => {
                let row = {
                    customerId: booking.customerId,
                    id: booking.id,
                    accommodation: booking.accommodation,
                    tandemFlight: booking.tandemFlight,
                    trikeFlyingWithWheels: booking.trikeFlyingWithWheels,
                    price: booking.price,
                    depositAmountPaid: booking.depositAmountPaid,
                    finalPaymentAmountPaid: booking.finalPaymentAmountPaid,
                    //confirmationEmailLastSent: booking.confirmationEmailLastSent,
                    equipmentSalesEmailLastSent: booking.equipmentSalesEmailLastSent,
                    notes: booking.notes
                }

                let customer = this.customers?.filter((customer) => {
                    return customer.id === booking.customerId
                })[0]
                if (customer) {
                    Object.assign(row, {
                        firstName: customer.firstName,
                        lastName: customer.lastName
                    })
                }

                rows.push(row)
            })

            return rows
        }
    },
    created() {
        this.field.replaceFormgroup = true
    },
    methods: {
        showNotes(row) {
            this.$store.dispatch('modals/show', {
                componentName: 'ShowNotesModal',
                obj: {
                    row: row,
                }
            })
        },
        saveAmount(event, id, name) {
            let value = event.target.value
            this.$store.dispatch('itemData/update', {
                tableName: 'courses_bookings',
                id: id,
                data: {
                    [name]: value
                }
            })
        },
        sortColumns(columnName) {
            this.orderDesc = (
                this.orderBy === columnName
                && !this.orderDesc
            )

            this.orderBy = columnName
        },
        toggleAllCheckboxes() {
            let allSelected = this.selectedIds.length === this.rows.length
            this.selectedIds = []
            if (!allSelected) {
                this.rows.forEach((row) => {
                    this.selectedIds.push(row.id)
                })
                this.allSelected = true
            } else {
                this.allSelected = false
            }
        },
        selectCheckbox(id) {
            let i = this.selectedIds.indexOf(id)
            if (i > -1) {
                this.selectedIds.splice(i, 1)
            } else {
                this.selectedIds.push(id)
            }
            this.allSelected = this.selectedIds.length === this.rows.length
        },
        sendConfirmationEmails() {
            this.selectedIds.forEach((bookingId) => {
                let booking
                let customer
                this.$store.dispatch('itemData/get', {
                        tableName: 'courses_bookings',
                        id: bookingId
                    })
                    .then((obj) => {
                        booking = obj
                        return this.$store.dispatch('itemData/get', {
                            tableName: 'm_registrants_registrants',
                            id: booking.customerId
                        })
                    })
                    .then((obj) => {
                        customer = obj

                        return this.sendEmail({
                            to: customer.email,
                            subject: customer.firstName + ', Please complete your Fly Spain booking. Ref: ' + booking.id + '.',
                            templateId: 87,
                            templateVars: {
                                booking_id: bookingId
                            }
                        })
                    })
                    .then(() => {
                        this.$store.dispatch('itemData/update', {
                            tableName: 'courses_bookings',
                            id: bookingId,
                            data: {
                                confirmationEmailLastSent: Math.ceil(Date.now() / 1000)
                            }
                        })
                    })
            })

            this.$store.dispatch('toasts/add', {
                body: 'Emails sent.'
            })

            // Refresh to show updated confirmationEmailLastSent.
            //this.setRows()
        },
        sendRequestReviewEmails() {
            this.selectedIds.forEach((bookingId) => {
                let booking
                let customer
                this.$store.dispatch('itemData/get', {
                        tableName: 'courses_bookings',
                        id: bookingId
                    })
                    .then((obj) => {
                        booking = obj
                        return this.$store.dispatch('itemData/get', {
                            tableName: 'm_registrants_registrants',
                            id: booking.customerId
                        })
                    })
                    .then((obj) => {
                        customer = obj

                        this.sendEmail({
                            to: customer.email,
                            subject: customer.firstName + ' review request.',
                            templateId: 88,
                            templateVars: {
                                booking_id: bookingId
                            }
                        })
                    })
            })

            this.$store.dispatch('toasts/add', {
                body: 'Emails sent.'
            })
        },
        sendEquipmentSalesEmails() {
            this.selectedIds.forEach((bookingId) => {
                let booking
                let customer

                this.$store.dispatch('itemData/get', {
                        tableName: 'courses_bookings',
                        id: bookingId
                    })
                    .then((obj) => {
                        booking = obj
                        return this.$store.dispatch('itemData/get', {
                            tableName: 'm_registrants_registrants',
                            id: booking.customerId
                        })
                    })
                    .then((obj) => {
                        customer = obj

                        return this.sendEmail({
                            to: customer.email,
                            subject: 'Hi ' + customer.firstName + ', need any paragliding equipment for your trip?',
                            templateId: 89,
                            templateVars: {
                                booking_id: bookingId
                            }
                        })
                    })
                    .then(() => {
                        this.$store.dispatch('itemData/update', {
                            tableName: 'courses_bookings',
                            id: bookingId,
                            data: {
                                equipmentSalesEmailLastSent: Math.ceil(Date.now() / 1000)
                            }
                        })
                    })
            })

            this.$store.dispatch('toasts/add', {
                body: 'Emails sent.'
            })

            // Refresh to show updated confirmationEmailLastSent.
            //this.setRows()
        },
        printAirportTransferSheet(departures) {
            const documentTitle = departures ? 'Departures' : 'Pickups'
            this.printDocument(109, documentTitle, {
                templateVars: {
                    departures: departures
                }
            })
        },
        printDocument(templateId, documentTitle, params) {
            params.bookingIds = this.selectedIds

            this.$store.dispatch('request/get', {
                    url: 'api/controller/document/' + templateId,
                    params: params
                })
                .then((o) => {
                    const html = o.data
                    const newWindow = window.open()
                    newWindow.document.write('<title>' + documentTitle + '</title>')
                    newWindow.document.write(html)
                })
        },
        archiveBookings() {
            if (confirm('Are you sure?')) {
                this.selectedIds.forEach((bookingId) => {
                    this.$store.dispatch('itemData/update', {
                        tableName: 'courses_bookings',
                        id: bookingId,
                        data: {
                            isArchived: 1
                        }
                    })
                })

                // Hide the booking's row from the table.
                this.rows.forEach((row, key) => {
                    if (this.selectedIds.indexOf(row.id) > -1) {
                        this.rows.splice(key, 1)
                    }
                })
            }
        },
        goToBooking(bookingId) {
            window.location = '/#/courses_bookings/' + bookingId
        },
        sendEmail(params) {
            return this.$store.dispatch('request/post', {
                url: 'api/email/send',
                postData: params
            })
        },
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YYYY, HH:mm')
        }
    }
}
</script>

<style scoped>

</style>