var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center small" }, [
    _c(
      "div",
      { staticClass: "flex-fill d-flex align-items-center" },
      [
        _c(
          "div",
          { staticClass: "dropup ms-1 p-1" },
          [
            _c(
              "Button",
              {
                staticClass: "btn-sm",
                attrs: { "data-bs-toggle": "dropdown" },
              },
              [_c("i", { staticClass: "bi-three-dots" })]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "dropdown-menu",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "li",
                  { staticClass: "dropdown-item text-center" },
                  [_c("CodemirrorModeMenu")],
                  1
                ),
                _vm._v(" "),
                _c("CodemirrorLineNumbersBtn"),
                _vm._v(" "),
                _c("CodemirrorLineWrappingBtn"),
                _vm._v(" "),
                _c("CodemirrorTwigModeBtn"),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isContentType
          ? _c("ThemesEditorContentTypeFieldsBtn", {
              attrs: { id: _vm.editor.id },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      [
        _c("small", { staticClass: "me-1 text-body-secondary" }, [
          _c(
            "strong",
            [
              _vm.item
                ? _c("Timeago", {
                    attrs: {
                      datetime:
                        (_vm.item.modifiedDate || _vm.item.createdDate) * 1000,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v("\n            by\n            "),
          _c(
            "strong",
            { staticClass: "text-secondary" },
            [
              _vm.modifiedBy
                ? _c("UserName", {
                    attrs: {
                      id: _vm.modifiedBy,
                      "no-user-found-str": "user not found",
                      "no-user-str": "system",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "Button",
          {
            staticClass: "me-1 btn-sm",
            attrs: {
              "active-value": _vm.editor.isModified,
              "active-class": "btn-warning",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.saveEditor.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "bi-save" })]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }