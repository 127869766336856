var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items
    ? _c(
        "div",
        { staticClass: "toast-container position-fixed top-0 end-0 p-3" },
        _vm._l(_vm.items, function (item, index) {
          return _c("Toast", {
            key: index,
            attrs: {
              title: item.title,
              body: item.body,
              "disable-auto-hide": item.disableAutoHide,
              percentage: item.percentage,
              "colour-scheme": item.colourScheme,
              "created-by": item.createdBy,
              "download-url": item.downloadUrl,
            },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }