<template>
    <div>
        <FormControlTagsUsageComponent
            v-for="(itemIds, componentId) in componentItemIds"
            :key="componentId"
            :component-id="Number(componentId)"
            :item-ids="itemIds"
            :tag-id="id"
        />
    </div>
</template>

<script>
import FormControlTagsUsageComponent from './FormControlTagsUsageComponent'

export default {
    name: "FormControlTagsTagUsage",
    components: {
        FormControlTagsUsageComponent,
    },
    props: {
        formId: String,
    },
    computed: {
        id() {
            return this.$store.state[this.formId].id
        },
    },
    asyncComputed: {
        componentItemIds() {
            return this.$store.dispatch('request/get', {
                    url: 'api/tags/usage',
                    params: {
                        tagId: this.id
                    }
                })
                .then((obj) => {
                    return obj.data.componentItemIds
                })
        }
    }
}
</script>

<style scoped>

</style>