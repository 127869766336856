var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _c("input", {
        attrs: { id: _vm.field.id, type: "checkbox" },
        domProps: { checked: _vm.isTracked },
        on: { click: _vm.toggleTrackStock },
      }),
      _vm._v(" "),
      _c("FormControlTypeNumber", {
        ref: "input",
        staticClass: "ms-2",
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      }),
      _vm._v(" "),
      _vm.basketCount && _vm.isTracked
        ? _c("span", { staticClass: "ms-2" }, [
            _vm._v("Basket count: " + _vm._s(_vm.basketCount)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }