<template>
    <span v-html="title"/>
</template>

<script>
export default {
    name: "TableCellTypeRelationshipOneToMany",
    props: {
        column: Object,
        value: Number,
    },
    computed: {
        component() {
            return this.$store.state.components.items.find(o => o.id === this.column.categoryComponentId)
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.column.categoryComponentId)
        },
        title() {
            return this.item ? this.item[this.nameField] : ''
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.component.tableName,
                    id: this.value
                })
                .then((obj) => {
                    return obj
                })
        }
    }
}
</script>

<style scoped>

</style>