<template>
    <div class="d-md-none navigation-mobile d-flex align-items-stretch">
        <div ref="dropdown" class="dropdown">
            <Button class="rounded-0 h-100" data-bs-toggle="dropdown">
                <i class="bi-list"/>
            </Button>

            <NavigationMobileMenu :close-menu="closeMenu" aria-labelledby="mobileDropdownMenu"
                                  class="dropdown-menu dropdown-mobile-fullscreen navigation-mobile-menu"/>
        </div>
    </div>
</template>

<script>
import NavigationMobileMenu from './NavigationMobileMenu'
import Button from "../Button.vue"

export default {
    name: "NavigationMobile",
    components: {
        Button,
        NavigationMobileMenu,
    },
    methods: {
        closeMenu() {
            this.$refs.dropdown.click()
        }
    }
}
</script>

<style scoped>
.dropdown-mobile-fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.navigation-mobile {
    flex: auto;
}

.navigation-mobile-menu {
    margin: 0;
    padding: 10px;
    line-height: initial;
    border-top-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>