var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    directives: [
      { name: "show", rawName: "v-show", value: _vm.show, expression: "show" },
    ],
    ref: "iframe",
    class: { hoverActive: _vm.isHoverActive, active: _vm.isActive },
    on: { mouseleave: _vm.onMouseleave, mouseenter: _vm.onMouseenter },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }