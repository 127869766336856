var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "div",
        [
          _vm.formId
            ? _c("Form", {
                attrs: { "form-id": _vm.formId, "show-legends": true },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "clearfix text-center mt-3" },
            [
              _c("FormSaveBtn", {
                attrs: { "button-text": "Save", "form-id": _vm.formId },
              }),
              _vm._v(" "),
              _vm.item.id
                ? _c(
                    "Button",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.confirmDelete($event, _vm.item)
                        },
                      },
                    },
                    [_vm._v("\n            Delete\n        ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }