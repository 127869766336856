var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "tr",
        {
          class: { "table-active": _vm.selectedIds.indexOf(_vm.id) > -1 },
          on: {
            dblclick: _vm.onDblClick,
            click: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "left", 37, $event.key, [
                    "Left",
                    "ArrowLeft",
                  ])
                )
                  return null
                if ("button" in $event && $event.button !== 0) return null
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                )
                  return null
                return _vm.onClick.apply(null, arguments)
              },
              function ($event) {
                if (!$event.metaKey) return null
                return _vm.onMetaClick.apply(null, arguments)
              },
              function ($event) {
                if (!$event.shiftKey) return null
                return _vm.onShiftClick.apply(null, arguments)
              },
            ],
          },
        },
        [
          !_vm.selectMode
            ? _c(
                "td",
                { staticClass: "table-cell-select", class: _vm.cellBgColour },
                [
                  _c("small", {
                    class: {
                      "bi-square": _vm.selectedIds.indexOf(this.id) === -1,
                      "bi-check-square": _vm.selectedIds.indexOf(this.id) > -1,
                    },
                    on: {
                      click: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "left", 37, $event.key, [
                              "Left",
                              "ArrowLeft",
                            ])
                          )
                            return null
                          $event.stopPropagation()
                          if ("button" in $event && $event.button !== 0)
                            return null
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          )
                            return null
                          return _vm.onMetaClick.apply(null, arguments)
                        },
                        function ($event) {
                          $event.stopPropagation()
                          if (!$event.shiftKey) return null
                          return _vm.onShiftClick.apply(null, arguments)
                        },
                      ],
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item
            ? _c(
                "td",
                { class: _vm.cellBgColour, attrs: { "data-name": "id" } },
                [
                  _vm._v("\n        " + _vm._s(_vm.item.id) + "\n\n        "),
                  _c("UserTrackingAvatar", {
                    attrs: { "item-id": _vm.id, "table-name": _vm.tableName },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.columns, function (columnId, index) {
            return _c("TableCell", {
              key: columnId,
              class: _vm.cellBgColour,
              attrs: {
                id: _vm.id,
                "column-id": columnId,
                "view-id": _vm.viewId,
              },
            })
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }