<template>
    <iframe v-show="show" ref="iframe" @mouseleave="onMouseleave" @mouseenter="onMouseenter"
            :class="{ active: isActive, empty: showEmpty }"/>
</template>

<script>
export default {
    name: "PageEditorContentAreaIframe",
    props: {
        contentArea: Object,
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        contentAreaName() {
            return this.contentArea.name
        },
        activeContentAreaName() {
            return this.pageEditor.hoverContentAreaName || this.pageEditor.selectedContentAreaName || this.pageEditor.highlightContentAreaName
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        activeContent() {
            return this.pageEditor.contentItems.find(o => o.pageContentId === this.activePageContentId)
        },
        show() {
            return this.activeContentAreaName === this.contentAreaName
                || this.showEmpty
        },
        isActive() {
            return this.activeContentAreaName === this.contentAreaName
            // Stops issue where moving the last content out of an area, leaving it empty, shows the area's border
            // Update: This meant that highlighting content areas whilst content is selected fails to highlight
            //&& !this.activePageContentId
        },
        isEmpty() {
            return this.pageEditor.contentItems.filter(o => o.contentAreaName === this.contentAreaName).length === 0
        },
        showEmpty() {
            return this.isEmpty && this.pageEditor.showEmptyContentAreas
        }
    },
    mounted() {
        this.contentArea.iframe = this.$refs.iframe
        this.setIframeStyling()
        this.positionIframe()
    },
    watch: {
        showEmpty(bool) {
            const contentAreaEl = this.pageEditor.contentAreas.find(o => o.name === this.contentAreaName).el
            contentAreaEl.style.minHeight = bool ? '50px' : ''
            contentAreaEl.style.marginBottom = bool ? '10px' : ''
        }
    },
    methods: {
        onMouseenter() {
            this.$store.commit('pageEditor/hoverContentAreaName', this.contentAreaName)
        },
        onMouseleave() {
            this.$store.commit('pageEditor/hoverContentAreaName', '')
        },
        positionIframe() {
            this.$store.dispatch('pageEditor/positionIframe', {
                iframe: this.$refs.iframe,
                contentEl: this.contentArea.el
            })
        },
        setIframeStyling() {
            const iframe = this.$refs.iframe

            addLink(iframe, 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css')
            addLink(iframe, 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css')

            const bodyClassList = iframe.contentWindow.document.body.classList
            // Bootstrap sets the body's bg to white, but it must be transparent
            bodyClassList.add('bg-transparent')
            bodyClassList.add('lh-1')
            bodyClassList.add('overflow-hidden')

            function addLink(iframe, src) {
                const cssLink = document.createElement('link')
                cssLink.href = src
                cssLink.rel = 'stylesheet'
                //cssLink.type = 'text/css';
                iframe.contentDocument.head.appendChild(cssLink)
            }
        }
    }
}
</script>

<style scoped>
iframe {
    position: absolute;
    z-index: 998; /* Bootstrap's dropdown z-indexes are set to 1000 */
    /*z-index: 1053; Bootstrap's modals' z-indexes start at 1055 */
    /*z-index: 2147483647; https://stackoverflow.com/questions/491052/minimum-and-maximum-value-of-z-index; */
    overflow: hidden;
    /*border: 1px solid blue;*/
}

.empty,
.active {
    background: rgba(255, 255, 255, 0.75);
    border: 1px dashed !important;
    border-radius: 5px;
}

.empty {
    min-height: 50px;
    border-color: red !important;
}

.empty:hover {
    border-color: #000 !important;
}

.active,
.active:hover {
    border-color: blue !important;
}

</style>