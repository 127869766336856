<template>
    <button v-if="isFilterActive" class="btn btn-warning rounded-0" type="button" @click="resetFilters">
        <i class="bi-x"/>
    </button>
</template>

<script>
export default {
    name: "SearchFiltersResetBtn",
    props: {
        viewId: String,
    },
    computed: {
        isFilterActive() {
            return this.$store.getters[this.viewId + '/isFilterActive']()
        }
    },
    methods: {
        resetFilters() {
            this.$store.dispatch(this.viewId + '/resetFilters')

            this.$router.push({
                query: null
            })
        }
    }
}
</script>

<style scoped>

</style>