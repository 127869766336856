<template>
    <FormControlPlainText v-if="franchiseId" :value="franchiseTitle"/>
    <!-- Legacy support for users without a franchise.
         todo - When this feature is rolled out, user's Franchise option MUST be mandatory -->
    <div v-else>
        <FormControlTypeRelationshipOneToMany :current-data="currentData" :field="field" :form-id="formId"/>
    </div>
</template>

<script>
import FormControlTypeRelationshipOneToMany from '../../common/form-control-type/FormControlTypeRelationshipOneToMany'
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlFranchiseId",
    components: {
        FormControlPlainText,
        FormControlTypeRelationshipOneToMany
    },
    props: {
        currentData: Object,
        field: Object,
        formId: String,
    },
    computed: {
        franchiseId() {
            return this.$store.state.user.user.franchiseId
        },
        franchiseTableName() {
            return this.$store.state.sitename.indexOf('outdoorsproject') === 0
                ? 'odp_franchises'
                : 'franchise'
        }
    },
    asyncComputed: {
        franchiseTitle() {
            if (this.franchiseId) {
                return this.$store.dispatch('itemData/get', {
                        tableName: this.franchiseTableName,
                        id: this.franchiseId
                    })
                    .then((obj) => {
                        return obj.title
                    })
            }
        }
    },
    created() {
        if (this.franchiseId) {
            this.$store.commit(this.formId + '/presetData', {
                name: 'franchiseId',
                value: this.franchiseId
            })
        }
    }
}
</script>

<style scoped>

</style>