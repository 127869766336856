<template>
    <div class="d-flex flex-column h-100">
        <div v-if="userExists" class="main-container d-flex flex-column h-100">
            <AjaxLoading/>

            <div class="d-flex h-100 overflow-auto flex-column">
                <Navigation/>

                <div class="main-content flex-grow-1">
                    <router-view/>
                </div>
            </div>

            <Support/>

            <!--<div wizard/>-->

            <Modals/>
        </div>
        <div v-else-if="!userExists && tokenExists()" class="ajax-spinner">
            <span class="ajax-spinner-icon"/>
        </div>

        <LoginForm v-else-if="!userExists"/>

        <Toasts/>

        <!--<noscript>
            <div>
                <h1>CreativeCMS requires JavaScript.</h1>
                <p>Please use a JavaScript enabled browser.</p>
            </div>
        </noscript>-->

        <!--<pre>{{ user }}</pre>-->
    </div>
</template>

<script>
import AjaxLoading from './common/AjaxLoading'
import Navigation from './common/navigation/Navigation'
import LoginForm from './common/LoginForm'
import Support from './common/support/Support'
import Modals from './vue/modals/Modals'
import Toasts from "./common/toasts/Toasts"

export default {
    name: "App",
    components: {
        Toasts,
        AjaxLoading,
        Navigation,
        LoginForm,
        Support,
        Modals
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        userExists() {
            return this.$store.state.user.exists
        },
        tableName() {
            return this.$route.params.component
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        access() {
            return this.$store.getters['user/access'](this.tableName)
        }
    },
    created() {
        // Redirect erroneous legacy AngularJS URLs to the dashboard.
        if (window.location.href.indexOf('/#/!/') > -1) {
            window.location = window.location.href.split('#')[0]
        }

        this.$store.dispatch('setParams')
            .then(() => {
                return this.init()
            })
            // Ensure that the user is set before userAccess
            .then(() => {
                this.userAccess()
            })

        this.createFavIconLinkTags()
        this.createCKEditorScript()

        /* todo - This cannot work here. It should be added to the form's $destroy event.
        // Unsaved data check.
        if (FormData.getModifiedData()) {
            if (confirm('You have unsaved changes. Are you sure?')) {
                FormData.reset();
            } else {
                return false;
            }
        } else {
            // Ensure that the form data service has no data when changing components, to avoid an
            // issue where the new component's form presented the previous component's fields and data.
            // For example, moving from account_settings/1 to the site tree was showing the former's fields.
            FormData.reset();
        }
        */

        this.setRouteProperties()

        // todo This was to highlight the notification menu icon when "active" notifications exist, but since
        //      implementing the notification component this field no longer exists, so needs reinstating.
        //this.$store.dispatch('notificationsMenu/activate')
        //this.$store.dispatch('userTracking/init');
    },
    watch: {
        $route(to, from) {
            this.$store.dispatch('modifiedItems/clearModifiedItems')

            this.setRouteProperties()
            this.userAccess()
        }
    },
    methods: {
        createFavIconLinkTags() {
            let link = document.createElement('link')
            link.setAttribute('rel', 'apple-touch-icon')
            link.setAttribute('href', '/apple-touch-icon.png')
            link.setAttribute('sizes', '180x180')
            document.head.appendChild(link)

            link = document.createElement('link')
            link.setAttribute('rel', 'icon')
            link.setAttribute('type', 'image/png')
            link.setAttribute('href', '/favicon-32x32.png')
            link.setAttribute('sizes', '32x32')
            document.head.appendChild(link)

            link = document.createElement('link')
            link.setAttribute('rel', 'icon')
            link.setAttribute('type', 'image/png')
            link.setAttribute('href', '/favicon-16x16.png')
            link.setAttribute('sizes', '16x16')
            document.head.appendChild(link)
        },
        createCKEditorScript() {
            const s = document.createElement('script')
            s.setAttribute('src', '/ckeditor/ckeditor.js')
            document.head.appendChild(s)
        },
        setRouteProperties() {
            let name = this.$route.name
            let component = this.$route.params.component
            let id = this.$route.params.id

            this.$store.dispatch('stateHistory/set', {
                stateName: name,
                tableName: component,
                id: id,
                fullPath: this.$route.fullPath
            })

            this.$store.dispatch('documentTitle/set', {
                stateName: name,
                tableName: component,
                itemId: id,
            })
        },
        tokenExists() {
            return localStorage.getItem('token')
        },
        init() {
            let token = localStorage.getItem('token')
            if (token) {
                // If a token exists then attempt to load the token's user.
                let user
                return this.$store.dispatch('request/get', {
                        url: 'api/users/get-current-user'
                    })
                    .then((obj) => {
                        user = obj.data
                        return this.$store.dispatch('request/get', {
                            url: 'api/user/' + user.id + '/access'
                        })
                    })
                    .then((obj) => {
                        user.access = obj.data
                        return this.$store.dispatch('user/set', user)
                    })
                    .then(() => {
                        this.$store.dispatch('user/applyDarkMode')
                    })
            }
        },
        userAccess() {

            // Admin only route restriction
            const adminRouteNames = ['themesEditor']
            if (
                this.$route.name.indexOf(adminRouteNames) > -1
                && !this.user.administrator
            ) {
                console.error("Access denied! Only administrators have access to the '" + this.$route.name + "' route.")
                this.$router.push({name: 'dashboard'})
                return
            }

            if (
                this.user
                && this.tableName
            ) {
                let id = parseInt(this.$route.params.id)
                let component = this.$store.state.components.items.find(o => o.id === this.componentId)

                // todo - When a user first logs in their access data is not yet set.
                if (
                    this.tableName === 'users'
                    && id === this.user.id
                ) {
                    // Allow users to edit their own user account, even if they haven't access to the users tableName.
                }

                else if (
                    this.user.access?.__formAccess
                    && component.navigationGroup === 'Forms'
                ) {
                    //console.log('form access')
                }

                // No access to the component whatsoever
                else if (!this.access) {
                    // Without the use of nextTick, users clicking links to restricted content (e.g. from the Activity
                    // Stream) generated errors and failed to redirect.
                    this.$nextTick(() => {
                        console.log('ss')
                        this.$router.push({name: 'dashboard'})
                    })
                }

                // If an attempt is made to edit or add without permissions then redirect to the listing.
                else if (!!id) {
                    let privilege = (id === 0) ? 'add' : 'edit'
                    if (!this.access[privilege]) {
                        console.log("'" + privilege + "' privilege denied!")
                        this.$router.push({name: 'list', params: {component: this.tableName}})
                    }

                } else {
                    // devOnly component restriction.
                    // If the component's devOnly is enabled and the betaMode parameter is not enabled
                    // redirect to the dashboard.
                    if (
                        component
                        && component.devOnly
                        && !this.$store.state.betaMode
                    ) {
                        this.$router.push({name: 'dashboard'})
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.main-content {
    overflow-y: auto;
}

.ajax-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>