var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      !_vm.subscriptionActive
        ? [
            _c("h1", [_vm._v("\n            Subscribe to a plan\n        ")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center plans" },
              [
                !_vm.plans.length
                  ? _c("div", { staticClass: "ajax-spinner" }, [
                      _c("span", { staticClass: "ajax-spinner-icon mt-3" }),
                    ])
                  : _vm._l(_vm.plans, function (plan, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "card plan",
                          class: { active: index === _vm.activeIndex },
                        },
                        [
                          _c("div", { staticClass: "card-body text-left" }, [
                            _c("h2", { staticClass: "plan-heading" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(plan.name) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "price" }, [
                              _vm._v(
                                "\n                        £" +
                                  _vm._s(plan.amount) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._m(0, true),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-light",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectPlan(index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        index === _vm.activeIndex
                                          ? "Selected"
                                          : "Select"
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.selectedPlan
              ? _c("div", { staticClass: "card subscription-panel" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("p", { staticClass: "subscription-panel-info" }, [
                      _vm._v("\n                    → Total due now "),
                      _c("strong", [
                        _vm._v("£" + _vm._s(_vm.selectedPlan.amount)),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v("\n                    → Subscribing to "),
                      _c("strong", [_vm._v(_vm._s(_vm.selectedPlan.name))]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "form",
                        {
                          attrs: {
                            id: "payment-form",
                            disabled: _vm.subscribing,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.onSubmit()
                            },
                          },
                        },
                        [
                          _c("label", [_vm._v("Card")]),
                          _vm._v(" "),
                          _c("div", { attrs: { id: "card-element" } }),
                          _vm._v(" "),
                          _vm.cardError
                            ? _c(
                                "div",
                                {
                                  staticClass: "alert alert-danger",
                                  attrs: {
                                    id: "card-element-errors",
                                    role: "alert",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.cardError) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning",
                                class: { active: _vm.subscribing },
                                attrs: {
                                  disabled: _vm.subscribing,
                                  type: "submit",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.subscribing
                                        ? "Subscribing"
                                        : "Subscribe"
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.subscriptionActive && _vm.plan && _vm.paymentMethod
        ? [
            _c("h1", [_vm._v("\n            Current plan\n        ")]),
            _vm._v(" "),
            _c("div", { staticClass: "card subscribed-panel" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h2", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.plan.name) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "subscription-panel-info" }, [
                  _vm._v("\n                    Amount "),
                  _c("strong", { staticClass: "float-end" }, [
                    _vm._v("£" + _vm._s(_vm.plan.amount)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v("\n                    Credit card "),
                  _c("strong", { staticClass: "float-end" }, [
                    _vm._v(
                      _vm._s(_vm.brand) +
                        " •••• " +
                        _vm._s(_vm.paymentMethod.card.last4)
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "price-label" }, [
      _vm._v("\n                        Per month\n                        "),
      _c("br"),
      _vm._v("\n                        Billed monthly\n                    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "subscription-card-header" }, [
      _vm._v(
        "\n                    Enter your card details.\n                    "
      ),
      _c("br"),
      _vm._v("\n                    Your subscription will start "),
      _c("span", { staticClass: "highlight" }, [_vm._v("XXX")]),
      _vm._v(".\n                "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }