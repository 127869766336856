<template>

    <Listing
        :table-name="tableName"
    />

</template>

<script>
import Listing from '../common/listing/Listing'

export default {
    name: "List",
    components: {
        Listing,
    },
    computed: {
        tableName() {
            let tableName = this.$route.params.component

            // Deprecated table name Support.
            switch (tableName) {
                case 'orders':
                    tableName = 'm_basket_orders'
                    break
                case 'order_items':
                    tableName = 'm_basket_orderitems'
                    break
            }

            this.$store.commit('navigationComponent', tableName)

            return tableName
        }
    }
}
</script>

<style scoped>

</style>