export default {
    namespaced: true,
    actions: {
        get({dispatch}, {pageId}) {
            /* todo - Add support for page content IDs
            if (this.pageContentId) {
                let pageId = this.$store.state.pageContentData.items.filter((obj) => {
                    return obj.id === this.pageContentId
                })[0].pageId
        
                return this.getPath(pageId)
                    .then((path) => {
                        return path + '#' + this.pageContentId
                    });
            }*/
            
            return dispatch('getPath', {pageId});
        },
        getPath({dispatch}, {pageId}) {
            let path = '/'
        
            if (this.suffix) {
                path += '#' + this.suffix
            }
        
            return dispatch('itemData/get', {
                    tableName: 'pages',
                    id: pageId,
                }, {root: true})
                .then((obj) => {
                    path = '/' + obj.url + path
                
                    return dispatch('loadParent', {
                        parent: obj.parent,
                        path: path
                    })
                })
        },
        loadParent({dispatch}, {parent, path}) {
            if (
                parent
                && parent !== '0'
            ) {
                return dispatch('itemData/get', {
                        tableName: 'pages',
                        id: parent,
                    }, {root: true})
                    .then((obj) => {
                        path = '/' + obj.url + path
    
                        return dispatch('loadParent', {
                            parent: obj.parent,
                            path: path
                        })
                    })
            }
        
            return path
        }
    }
}