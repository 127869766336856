<template>
    <FormControlTypeRelationshipOneToMany :current-data="currentData" :field="field" :form-id="formId"/>
</template>

<script>
import FormControlTypeRelationshipOneToMany from '../../common/form-control-type/FormControlTypeRelationshipOneToMany'

export default {
    name: "FormControlOdpCourseDatesCourseId",
    components: {
        FormControlTypeRelationshipOneToMany,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    data() {
        return {
            courseType: undefined,
        }
    },
    asyncComputed: {
        courseType() {
            if (!this.currentData.courseId) {
                this.showCourseTypeFields(false)
                return false
            }

            return this.$store.dispatch('itemData/get', {
                    tableName: 'odp_courses',
                    id: this.currentData.courseId,
                })
                .then((obj) => {
                    this.showCourseTypeFields(obj.courseType)
                    return obj.courseType
                })
        }
    },
    methods: {
        showCourseTypeFields(courseType) {

            let toggleField = (name, show) => {
                let fields = this.$store.state[this.formId].fields

                // Hide school
                let field = fields.find(o => o.columnName === name)
                field.hidden = !show

                // The field's values must be unset in case they're used in display names.
                // They must only be unset if populated, otherwise it breaks their Vue reactivity
                if (!show) {
                    switch (name) {
                        case 'schoolId':
                            if (this.currentData[name]) {
                                this.currentData[name] = 0
                            }
                            break

                        case 'location':
                            if (this.currentData[name]) {
                                this.currentData[name] = ''
                            }
                    }
                }
            }

            if (courseType === 'After school club') {

                // Hide location
                toggleField('location', false)
                // Show school
                toggleField('schoolId', true)

                //form.find('[name="location"]').val('').trigger('change');
            } else if (courseType) {
                // Event, Mac's Farm event, Forest school

                // Show location
                toggleField('location', true)
                // Hide school
                toggleField('schoolId', false)

                //form.find('[name="schoolId"]').val(0).trigger('change');
            } else {
                // Hide location
                toggleField('location', false)
                // Hide school
                toggleField('schoolId', false)
            }
        }
    }
}
</script>

<style scoped>

</style>