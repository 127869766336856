<template>
    <span v-if="json">[object]</span>
</template>

<script>
export default {
    name: "TableCellTypeJson",
    props: {
        value: [String, Object],
    },
    data() {
        return {
            json: undefined,
        }
    },
    created() {
        if (
            this.value
            && this.value.length !== 0 // Omits [] values
        ) {
            this.json = this.value
        }
    }
}
</script>

<style scoped>

</style>