<template>
    <div>
        <div v-if="franchises.length" ref="container">
            <nav class="navbar navbar-default">
                <div class="container-fluid">
                    <form ref="form" class="float-start d-flex align-items-center">
                        <ReportMenu/>

                        <template v-if="formData.reportName === 'birthdayDates'">
                            <MonthsFilter/>
                            <FranchisesFilter :allow-all="true"/>
                        </template>

                        <template v-if="formData.reportName === 'birthdayPartyEnquiries'">
                            <FranchisesFilter :allow-all="true"/>
                            <DateRangeFilter/>
                        </template>

                        <template v-if="formData.reportName === 'bookingsReport'">
                            <DateRangeFilter/>
                            <FranchisesFilter :allow-all="true"/>
                        </template>

                        <template v-if="formData.reportName === 'courseDatesData'">
                            <DateRangeFilter/>
                            <FranchisesFilter :allow-all="true"/>
                        </template>

                        <template v-if="formData.reportName === 'customerBookingData'">
                            <DateRangeFilter/>
                            <FranchisesFilter :allow-all="true"/>
                        </template>

                        <template v-if="formData.reportName === 'customerCredit'">
                            <FranchisesFilter :allow-all="true"/>
                        </template>

                        <template v-if="formData.reportName === 'customerPostcodes'">
                            <!--<span class="text-muted">Created: </span>-->
                            <DateRangeFilter/>
                            <!--<span class="text-muted">Franchise: </span>-->
                            <FranchisesFilter :allow-all="true"/>
                        </template>

                        <template v-if="formData.reportName === 'marketingReport'">
                            <DateRangeFilter/>
                        </template>

                        <template v-if="formData.reportName === 'postcodes'">
                            <FranchisesFilter :allow-all="true"/>
                        </template>

                        <template v-if="formData.reportName === 'schoolParents'">
                            <SchoolsFilter/>
                            <span class="ms-2 text-nowrap text-muted">in the last</span>
                            <MonthInTheLastFilter/>
                        </template>

                        <button v-if="formData.reportName !== ''" class="ms-2 btn btn-primary btn-sm"
                                @click.prevent="submitForm()">
                            Go
                        </button>

                        <span v-if="rows && rows.length" class="ms-2 text-nowrap">
                            {{ rows.length | numberFormat }} records found
                        </span>

                        <span v-if="$store.state.odpReports.isLoading" class="ms-2 ajax-spinner">
                            <span class="ajax-spinner-icon ajax-spinner-icon-sm"/>
                        </span>
                    </form>

                    <div v-if="rows && rows.length" class="float-end">
                        <button class="btn btn-default btn-transparent" data-target="#printModal"
                                data-toggle="modal" type="button">
                            <i class="bi-printer"/>
                            <span class="sr-only">Print report</span>
                        </button>

                        <button class="btn btn-default btn-transparent" type="button"
                                @click.prevent="downloadCSVData">
                            <i class="bi-download"/>
                            <span class="sr-only">Download CSV</span>
                        </button>
                    </div>

                </div>
            </nav>

            <p v-if="rows && rows.length === 0" class="mx-2 alert alert-warning">
                No records match your search.
            </p>

            <p v-if="error" class="mx-2 alert alert-warning" v-html="error"></p>

            <div v-if="rows && rows.length" class="padding-sm table-wrapper">
                <table class="table table-condensed table-striped">
                    <thead>
                    <tr>
                        <td v-for="(value, heading) in rows[0]" @click="setSortBy(heading)">
                            <span v-if="heading === sortBy"
                                  :class="{ 'bi-chevron-up': sortDir === 'asc', 'bi-chevron-down': sortDir === 'desc' }"/>
                            {{ heading }}
                        </td>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="row in sortedRows">
                        <td v-for="(value, heading) in row">
                            {{ value }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!--<pre>{{ $store.state.odpReports.formData }}</pre>-->
        </div>

        <PrintReport v-if="rows" ref="toPrint" :end-date="endDate" :heading="reportLabel" :rows="sortedRows"
                     :start-date="startDate" style="display: none"/>

        <div id="printModal" ref="modal" aria-labelledby="myModalLabel" class="modal fade" role="dialog" tabindex="-1">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <h4 class="modal-title">
                            Print report
                        </h4>
                    </div>

                    <div class="modal-body">
                        <iframe ref="iframe" :srcdoc="iframeHtml"/>
                    </div>

                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>

        <!--<pre>{{ {sortBy, sortDir} }}</pre>-->
    </div>
</template>

<script>
import PrintReport from './PrintReport'
import moment from 'moment'
import * as CSV from 'csv-string'
import _ from 'lodash'
import ReportMenu from '../../bespoke/odp/report/ReportMenu'
import MonthsFilter from "../../bespoke/odp/report/MonthsFilter"
import FranchisesFilter from "../../bespoke/odp/report/FranchisesFilter"
import DateRangeFilter from "../../bespoke/odp/report/DateRangeFilter"
import SchoolsFilter from "../../bespoke/odp/report/SchoolsFilter"
import MonthInTheLastFilter from "../../bespoke/odp/report/MonthInTheLastFilter"

export default {
    name: 'OdpReports',
    components: {
        MonthInTheLastFilter,
        SchoolsFilter,
        DateRangeFilter,
        FranchisesFilter,
        MonthsFilter,
        ReportMenu,
        PrintReport
    },
    data() {
        return {
            error: '',
            iframeHtml: '',
            sortBy: '',
            sortDir: 'asc',
        }
    },
    computed: {
        formData() {
            return this.$store.state.odpReports.formData
        },
        sortedRows() {
            return this.rows.sort((a, b) => {
                // todo There's currently no way to determine which columns are decimal, so this is hardcoded.
                //      The fix will be to refactor the data, so a headings array is created, instead of setting all
                //      rows with property names,
                if (this.sortBy === 'Credit amount') {
                    if (this.sortDir === 'desc') {
                        return b[this.sortBy] - a[this.sortBy]
                    } else {
                        return a[this.sortBy] - b[this.sortBy]
                    }
                } else {
                    if (this.sortDir === 'desc') {
                        return b[this.sortBy] > a[this.sortBy] ? 1 : -1
                    } else {
                        return a[this.sortBy] > b[this.sortBy] ? 1 : -1
                    }
                }
            })
            //return _.orderBy(this.rows, this.sortBy, this.sortDir)
        },
        startDate() {
            return this.$store.state.odpReports.formData.startDate
        },
        endDate() {
            return this.$store.state.odpReports.formData.endDate
        },
        franchises() {
            return this.$store.state.odpReports.franchises
        },
        reportLabel() {
            return this.$store.state.odpReports.reports.find(o => o.name === this.formData.reportName).label
        },
        rows() {
            return this.$store.state.odpReports.rows
        }
    },
    mounted() {
        this.$store.dispatch('odpReports/init', {
            query: this.$route.query
        })

        $(this.$refs.modal)
            .on('show.bs.modal', () => {
                let toPrint = this.$refs.toPrint

                this.iframeHtml = `
<html>
    <body>
        <link rel="stylesheet" href="//maxcdn.bootstrapcdn.com/bootstrap/3.2.0/css/bootstrap.min.css">` +
                    toPrint.$el.innerHTML +
                    `<script>setTimeout(function() { window.print() }, 200)<\/script>
    </body>
</html>
`
            })
    },
    methods: {
        setSortBy(heading) {
            this.sortDir = this.sortBy === heading
                ? (this.sortDir === 'asc' ? 'desc' : 'asc')
                : 'asc'

            this.sortBy = heading
        },
        submitForm() {
            const form = this.$refs.form

            this.error = ''

            if (!form.checkValidity()) {
                Array.from(form.elements).forEach((element) => {
                    if (element.validity.valid === false && this.error === '') {
                        this.error += element.validationMessage
                    }
                })
                return
            }

            // Apply the form's properties to the query string
            this.$router.push({
                query: this.formData
            })

            this.$store.dispatch('odpReports/loadRows')
        },
        downloadCSVData() {
            // Headings
            let headings = Object.keys(this.rows[0])
            let csv = CSV.stringify(headings)

            // Body
            this.rows.forEach((row) => {
                let values = Object.values(row)
                csv += CSV.stringify(values)
            })

            const anchor = document.createElement('a')
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
            anchor.target = '_blank'
            anchor.download = this.reportLabel + ' ' + this.getDate(new Date()) + '.csv'
            anchor.click()
        },
        getDate(timestamp) {
            return moment(timestamp).format('DD-MM-Y')
        }
    },
    filters: {
        numberFormat(value) {
            return new Intl.NumberFormat().format(value)
        }
    }
}
</script>

<style scoped>
.modal-dialog {
    height: 100%
}

.ajax-spinner {
    vertical-align: bottom;
}

.modal-content {
    height: calc(100% - 60px)
}

.modal-body {
    height: calc(100% - 60px)
}

.table-wrapper {
    overflow-x: auto;
}

iframe {
    width: 100%;
    height: 100%;
    border: none
}
</style>