var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex h-100" }, [
    _c(
      "aside",
      { staticClass: "w-25 overflow-hidden" },
      [_c("ThemesEditorSidebar")],
      1
    ),
    _vm._v(" "),
    _c("main", { staticClass: "flex-fill w-100 overflow-hidden" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-column h-100" },
        [
          _c(
            "div",
            {
              staticClass: "themes-editor-toolbar overflow-x-scroll",
              attrs: { "data-themes-editor-toolbar": "" },
            },
            [_c("ThemesEditorTabs")],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.editors, function (editor, index) {
            return index === _vm.activeEditorKey
              ? _c(
                  "div",
                  {
                    key: editor.key,
                    staticClass: "themes-editor--editor-container",
                  },
                  [
                    _c("ThemesEditorCodemirror", {
                      staticClass: "d-flex flex-column h-100",
                      attrs: {
                        editor: editor,
                        focused: editor.focused,
                        "latest-code": editor.latestCode,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e()
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }