var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-group-item py-1 pe-2 d-flex align-items-center" },
    [
      _c(
        "div",
        { staticClass: "flex-fill" },
        [
          _c("FormPageContentField", {
            attrs: { "content-id": _vm.contentId },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ContentUsageCount", {
        staticClass: "me-2 lh-1",
        attrs: {
          "page-content-id": _vm.pageContentDataItem.id,
          "show-menu": true,
        },
      }),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "btn-sm",
          nativeOn: {
            click: function ($event) {
              return _vm.goToContent.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-pencil-square" })]
      ),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "btn-sm",
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.moveUp.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-chevron-up" })]
      ),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "btn-sm",
          nativeOn: {
            click: function ($event) {
              return _vm.removeContent.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi-x-lg" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }