<template>
    <span>
        <button class="btn btn-sm btn-light me-2" type="button" @click="openSiteTreeModal">
            Browse…
        </button>

        <PagePath class="small" v-if="pageId" :page-id="pageId"/>
    </span>
</template>

<script>
import PagePath from '../../common/PagePath'

export default {
    name: "CollectionFilterControlTypeInternalLink",
    components: {
        PagePath
    },
    props: {
        field: Object,
        item: Object
    },
    computed: {
        pageId() {
            return parseInt(this.item.value)
        }
    },
    methods: {
        openSiteTreeModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    linkOptions: 'ip',
                    pageId: this.pageId,
                    onSelect: (obj) => {
                        this.item.value = obj.pageId
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>