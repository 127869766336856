<script>
import FormControlTypeText from '../form-control-type/FormControlTypeText'

export default {
    name: "FormControlModulestructureNumRows",
    extends: FormControlTypeText,
    created() {
        // todo - Why is this field not a number field? I should check all sites and maybe convert.
        if (this.currentData.type === 'textarea') {
            this.field.size = 4

            // todo - This serves no purpose because the field will already have been saved before the type is set.
            //  Once it's convert to a number field it can be removed anyway.
            this.$store.dispatch(this.formId + '/setDefaultValue', {
                name: this.field.name,
                value: '0'
            })

        } else {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>