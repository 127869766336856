var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column h-100" },
    [
      _vm.userExists
        ? _c(
            "div",
            { staticClass: "main-container d-flex flex-column h-100" },
            [
              _c("AjaxLoading"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex h-100 overflow-auto flex-column" },
                [
                  _c("Navigation"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "main-content flex-grow-1" },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("Support"),
              _vm._v(" "),
              _c("Modals"),
            ],
            1
          )
        : !_vm.userExists && _vm.tokenExists()
        ? _c("div", { staticClass: "ajax-spinner" }, [
            _c("span", { staticClass: "ajax-spinner-icon" }),
          ])
        : !_vm.userExists
        ? _c("LoginForm")
        : _vm._e(),
      _vm._v(" "),
      _c("Toasts"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }