export default {
    inserted: function(el, binding, vnode, old) {
        
        let field = vnode.context.field
        
        function validate() {
            // Error must be unset otherwise the error of a valid field
            // which was previously invalid won't be removed.
            field.error = ''
            
            el.checkValidity()
        }
        
        //el.onfocus = validate
        el.onkeyup = validate
        el.onblur = validate
        
        // Assigning the Validation API's validationMessage to the field triggers the presentation of the error
        // in the FormGroup component.
        el.oninvalid = () => {
            // If the element has a title attribute present that instead.
            // Why is this required? For number inputs with a step, to restrict the number of decimals, all Safari
            // outputs is "Enter a valid value", so FormControlTypeCurrency sets a more meaningful title based on the
            // number in the step.
            field.error = el.title || el.validationMessage
        }
        
    },
}