import { render, staticRenderFns } from "./SidebarToggleBar.vue?vue&type=template&id=8323127e&scoped=true&"
import script from "./SidebarToggleBar.vue?vue&type=script&lang=js&"
export * from "./SidebarToggleBar.vue?vue&type=script&lang=js&"
import style0 from "./SidebarToggleBar.vue?vue&type=style&index=0&id=8323127e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8323127e",
  null
  
)

export default component.exports