<template>
    <FormControlTypeRelationshipOneToMany
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />
</template>

<script>
import FormControlTypeRelationshipOneToMany from '../../common/form-control-type/FormControlTypeRelationshipOneToMany'

export default {
    name: "FormControlOdpCourseBookingsCourseDateId",
    components: {
        FormControlTypeRelationshipOneToMany,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: Number
    },
    computed: {
        itemId() {
            return this.currentData.id || 0
        },
        agreedPrice() {
            return this.currentData.agreedPrice
        }
    },
    watch: {
        value(newCourseDateId) {
            if (
                !this.itemId
                // As number inputs are strings, a "0" value equals true, whereas "" (empty) is false
                // https://creativecms.atlassian.net/browse/ODP4-353
                // We agreed it should keep updating the new booking's price in case a user selects the wrong date.
                //&& !this.agreedPrice
                && newCourseDateId
            ) {
                this.$store.dispatch('itemData/get', {
                    tableName: 'odp_course_dates',
                    id: newCourseDateId
                })
                .then((obj) => {
                    this.$store.commit(this.formId + '/set', {
                        name: 'agreedPrice',
                        value: obj.price,
                    })
                })
            }
        }
    }
}
</script>

<style scoped>

</style>