var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.readonly
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "form-check-input",
        attrs: {
          disabled: "",
          "false-value": "0",
          "true-value": "1",
          type: "checkbox",
        },
        domProps: {
          checked: Array.isArray(_vm.value)
            ? _vm._i(_vm.value, null) > -1
            : _vm._q(_vm.value, "1"),
        },
        on: {
          change: function ($event) {
            var $$a = _vm.value,
              $$el = $event.target,
              $$c = $$el.checked ? "1" : "0"
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value = $$c
            }
          },
        },
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "form-check-input",
        attrs: {
          id: _vm.field.id,
          disabled: _vm.field.disabled,
          name: _vm.field.name,
          required: _vm.field.required,
          "false-value": "0",
          "true-value": "1",
          type: "checkbox",
        },
        domProps: {
          checked: Array.isArray(_vm.value)
            ? _vm._i(_vm.value, null) > -1
            : _vm._q(_vm.value, "1"),
        },
        on: {
          click: _vm.onClick,
          change: function ($event) {
            var $$a = _vm.value,
              $$el = $event.target,
              $$c = $$el.checked ? "1" : "0"
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.value = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.value = $$c
            }
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }