<template>
    <div>
        <div v-for="item in items" class="text-nowrap d-flex align-items-center">
            <span v-tooltip :class="getClass(item.event)" :title="'Delivery status: ' + item.status"
                  class="me-2 d-inline-block rounded-circle p-2" data-placement="right"/>

            {{ item.address }}
        </div>
    </div>
</template>

<script>
import tooltip from '../../vue/directives/tooltip'

export default {
    name: "TableCellEmailMessageTo",
    directives: {
        tooltip
    },
    props: {
        item: Object,
        value: String
    },
    computed: {
        items() {
            const items = []

            const addresses = this.value.split(',')

            addresses.forEach((address) => {
                if (address.indexOf('<') > -1) {
                    address = address.split('<')[1].split('>')[0]
                }

                const item = {
                    address: address
                }

                if (this.allEvents) {
                    item.event = this.allEvents.find(o => o.recipient === address)?.event
                }

                item.status = item.event ? item.event.toUpperCase() : 'Unknown'

                items.push(item)
            })

            return items
        }
        /*items() {
            if (this.allEvents) {
                // We only want to present the most recent event for each recipient, otherwise it will
                // clutter the UI with lots of "Accepted" events. So by sorting by -eventDate (above)
                // we can just include the first event for each recipient.
                const items = []
                const recipients = []
                this.allEvents.forEach((o) => {
                    if (recipients.indexOf(o.recipient) === -1) {
                        items.push(o)
                        recipients.push(o.recipient)
                    }
                })

                return items
            }
        }*/
    },
    asyncComputed: {
        allEvents() {
            return this.$store.dispatch('emailEvents/get', this.item.id)
        }
    },
    methods: {
        getClass(event) {
            if (!event) {
                return 'bg-light'
            }

            let labels = {
                accepted: 'bg-warning',
                rejected: 'bg-danger',
                delivered: 'bg-success',
                failed: 'bg-danger',
                opened: 'bg-success',
                clicked: 'bg-success',
                unsubscribed: 'bg-danger',
                complained: 'bg-danger',
                stored: 'bg-warning'
            }

            if (
                event
                && labels.hasOwnProperty(event)
            ) {
                return labels[event]
            }
        }
    }
}
</script>

<style scoped>
span {
    min-width: 16px;
}
</style>