<template>
    <div ref="modal" class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Find</h4>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <input v-model="searchStr" v-select autocomplete="off" class="form-control input-lg" placeholder="Search"
                       type="text" @input="search">

                <div v-if="items" class="my-3 list-group list-group-numbered list-group-flush">
                    <div v-for="(item, index) in items" :key="index" :class="{ 'active': index === findModalItemIndex }"
                         class="list-group-item d-flex py-1 px-3" @click="selectItem(index)"
                         @dblclick="openItem(index)">
                        <div class="flex-fill ps-1 text-nowrap overflow-hidden" v-html="item.line"></div>

                        <div class="flex-shrink-1 text-nowrap text-end ps-3">
                            {{ item.title }} {{ item.lineNumber + 1 }}
                        </div>
                    </div>
                </div>

                <div v-if="value">
                    <textarea ref="codeMirror" v-html="value"/>
                </div>
            </div>

            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ThemesFindModal",
    props: {
        options: Object,
    },
    data() {
        return {
            codeMirror: undefined,
            items: [],
            searchTimeout: undefined,
            value: undefined,
            selections: [],
        }
    },
    computed: {
        searchStr: {
            get() {
                return this.$store.state.themes.searchStr
            },
            set(newValue) {
                this.$store.state.themes.searchStr = newValue
            }
        },
        findModalItemIndex() {
            return this.$store.state.themes.findModalItemIndex
        }
    },
    created() {
        // If a search string is provided when opening the modal.
        if (this.searchStr) {
            this.search()
        }
    },
    methods: {
        search() {
            if (this.searchStr === '') {
                this.items = false
                this.$store.state.themes.findModalItemIndex = 0
                this.value = undefined
            } else {
                // A timeout delays the search so that it's not called on every keyup.
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(() => {
                    this.setItems()
                        .then(() => {
                            if (this.items.length) {
                                this.selectItem(this.findModalItemIndex)
                            }
                        })
                }, 500)
            }
        },
        setItems() {
            return this.$store.dispatch('request/get', {
                    url: 'api/themes-find',
                    params: {
                        search: this.searchStr
                    }
                })
                .then((obj) => {
                    this.items = obj.data.items
                })
        },
        selectItem(i) {
            this.$store.state.themes.findModalItemIndex = i

            this.value = false

            let item = this.items[i]
            this.$store.dispatch('itemData/get', {
                    tableName: item.tableName,
                    id: item.id
                })
                .then((obj) => {
                    let code
                    let mode
                    switch (item.tableName) {
                        case 'templates_templates':
                            code = obj.templateHtml
                            mode = 'text/html'
                            break
                        case 'themes_css':
                            code = obj.css
                            mode = 'css'
                            break
                        case 'themes_javascript':
                            code = obj.script
                            mode = 'javascript'
                            break
                    }
                    this.showCodeMirror(code, mode, item.lineNumber, item.positions)
                })
        },
        showCodeMirror(code, mode, lineNumber, positions) {
            this.value = code

            let codeMirrorOptions = {
                mode: mode,
                styleActiveLine: {
                    nonEmpty: true
                },
                lineNumbers: true,
                lineWrapping: false,
                readOnly: true
            }

            let height = this.getCodeMirrorHeight()


            const codeMirror = this.$store.getters['user/pref']('codeMirror')

            if (codeMirror.theme) {
                codeMirrorOptions['theme'] = codeMirror.theme
            }

            /*if (
                ctrl.data.mode === 'javascript'
                || ctrl.data.mode === 'css'
            ) {
                angular.extend(codeMirrorOptions, {
                    gutters: ['CodeMirror-lint-markers'],
                    lint: true
                });
            }*/

            // todo - Without this fromTextArea() generates an error.
            this.$nextTick(() => {
                this.codeMirror = CodeMirror.fromTextArea(this.$refs.codeMirror, codeMirrorOptions)

                this.codeMirror.setSize(null, height)

                this.setSelections(positions, lineNumber)
                this.codeMirror.setSelections(this.selections)
            })
        },
        setSelections(positions, lineNumber) {
            this.selections = []
            let len = this.searchStr.length

            positions.forEach((position) => {
                this.selections.push({
                    // Start position
                    anchor: {
                        line: lineNumber,
                        ch: position
                    },
                    // End position
                    head: {
                        line: lineNumber,
                        ch: position + len
                    }
                })
            })
        },
        openItem(i) {
            this.$store.commit('themes/findModalSelections', this.selections)

            let item = this.items[i]
            this.$router.push({path: '/themes_editor', query: {section: item.tableName, id: item.id}})
            this.$emit('hide-modal')
        },
        getCodeMirrorHeight() {

            // The height of the modal without content.
            let modal = this.$refs.modal
            let bottom = modal.getBoundingClientRect().bottom
            let marginBottom = parseInt(window.getComputedStyle(modal).marginBottom, 10) // 30px -> 30
            let modalHeight = bottom + marginBottom

            return window.innerHeight - modalHeight
        }
    }
}
</script>

<style scoped>
.list-group {
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Remove BS's border-radius: inherit which results in pixelation when active */
.list-group-item:first-child,
.list-group-item:last-child {
    border-radius: 0 !important;
}
</style>