var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.componentItemIds, function (itemIds, componentId) {
      return _c("FormControlTagsUsageComponent", {
        key: componentId,
        attrs: {
          "component-id": Number(componentId),
          "item-ids": itemIds,
          "tag-id": _vm.id,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }