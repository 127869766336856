var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items
    ? _c("table", { staticClass: "table table-responsive" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.items, function (item) {
            return _c("tr", { key: item.id }, [
              _c("td", [
                _c(
                  "span",
                  {
                    staticClass: "badge",
                    class: _vm.getClass(item.event),
                    staticStyle: { "text-transform": "capitalize" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.event) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.recipient))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.getDate(item.eventDate * 1000)))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.eventData.deliveryStatus.message))]),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Event")]),
        _vm._v(" "),
        _c("th", [_vm._v("Recipient")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Message")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }