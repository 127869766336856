<template>
    <div v-if="item && show" class="d-flex align-items-center" @dblclick="onDblClick" @click.left.exact="onClick"
         @click.shift="onShiftClick" @click.meta="onMetaClick">
        <div class="flex-fill">
            <UserTrackingAvatar :item-id="id" :table-name="tableName"/>
            <ListViewRowTitle :id="id" :view-id="viewId"/>
        </div>

        <span class="text-nowrap">
            <TableViewEditBtn :id="id" :select-mode="selectMode" :view-id="viewId"/>
        </span>
    </div>
</template>

<script>
import UserTrackingAvatar from '../UserTrackingAvatar'
import ListViewRowTitle from './ListViewRowTitle'
import TableViewEditBtn from './TableViewEditBtn'

export default {
    name: "ListViewRow",
    components: {
        UserTrackingAvatar,
        ListViewRowTitle,
        TableViewEditBtn,
    },
    props: {
        viewId: String,
        id: Number,
    },
    data() {
        return {
            show: true,
            item: undefined,
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        selectMode() {
            return this.$store.state[this.viewId].selectMode
        },
        selectedIds() {
            return this.$store.state[this.viewId].selectedIds
        },
        allSelected() {
            return this.$store.state[this.viewId].allSelected
        },
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
                .then((obj) => {
                    return obj
                })
        },
    },
    watch: {
        item() {
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch(this.viewId + '/onClick', this.id)
        },
        onDblClick() {
            if (!this.onSelect) {
                this.$store.dispatch(this.viewId + '/onDblClick')
                    .then((hasAccess) => {
                        if (hasAccess) {
                            this.$router.push({
                                name: 'form',
                                params: {id: this.id},
                                query: this.$route.query
                            })
                        }
                    })
            }
        },
        onShiftClick() {
            this.$store.commit(this.viewId + '/onShiftClick', this.id)
        },
        onMetaClick() {
            this.$store.commit(this.viewId + '/onMetaClick', this.id)
        }
    }
}
</script>

<style scoped>

</style>