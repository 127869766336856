var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-3 pt-3 mb-3 pb-3" }, [
    !_vm.bookings.length ? _c("p", [_c("em", [_vm._v("empty")])]) : _vm._e(),
    _vm._v(" "),
    _vm.bookings.length
      ? _c("p", [
          _vm.courseType === "After school club" ||
          _vm.courseType === "Forest school"
            ? _c(
                "button",
                {
                  staticClass: "mb-2 btn btn-primary",
                  attrs: {
                    disabled: _vm.isButtonDisabled(false),
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openWorksheet(78, false, "Contacts worksheet")
                    },
                  },
                },
                [_vm._v("\n            Contacts worksheet\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.courseType === "After school club" ||
          _vm.courseType === "Forest school"
            ? _c(
                "button",
                {
                  staticClass: "mb-2 btn btn-primary",
                  attrs: {
                    disabled: _vm.isButtonDisabled(false),
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openWorksheet(80, false, "Sign in worksheet")
                    },
                  },
                },
                [_vm._v("\n            Sign in worksheet\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.courseType === "Event" ||
          _vm.courseType === "Mac's Farm event" ||
          _vm.courseType === "Saplings" ||
          _vm.courseType === "Other event"
            ? _c(
                "button",
                {
                  staticClass: "mb-2 btn btn-primary",
                  attrs: {
                    disabled: _vm.isButtonDisabled(false),
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openWorksheet(
                        79,
                        false,
                        "Holiday Clubs registration form"
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            Holiday Clubs registration form\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "mb-2 btn btn-primary",
              class: { "btn-warning": _vm.parentsEmailSending },
              attrs: {
                disabled:
                  _vm.isButtonDisabled(false) || _vm.parentsEmailSending,
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.sendParentsEmail(false)
                },
              },
            },
            [_vm._v("\n            Email parents\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "mb-2 btn btn-primary",
              class: { "btn-warning": _vm.confirmationEmailSending },
              attrs: {
                disabled:
                  _vm.isButtonDisabled(false) || _vm.confirmationEmailSending,
                type: "button",
              },
              on: { click: _vm.sendConfirmationEmail },
            },
            [_vm._v("\n            Confirmation email\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "mb-2 btn btn-primary",
              attrs: { disabled: _vm.isButtonDisabled(false), type: "button" },
              on: {
                click: function ($event) {
                  return _vm.openWorksheet(136, false, "Booking table")
                },
              },
            },
            [_vm._v("\n            Open booking table\n        ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.bookings.length
      ? _c("table", { staticClass: "table table-sm small" }, [
          _c("thead", [
            _c("tr", [
              _c("td", [
                _c("input", {
                  attrs: { type: "checkbox" },
                  domProps: { checked: _vm.allCheckboxesChecked },
                  on: {
                    click: function ($event) {
                      return _vm.selectAllCheckboxes()
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("td"),
              _vm._v(" "),
              _c("td", [_vm._v("Child")]),
              _vm._v(" "),
              _c("td", [_vm._v("Parent")]),
              _vm._v(" "),
              _c("td", [_vm._v("Agreed price")]),
              _vm._v(" "),
              _c("td", [_vm._v("Amount paid")]),
              _vm._v(" "),
              _c("td", [_vm._v("Credit amount")]),
              _vm._v(" "),
              _c("td", [_vm._v("Payment reminder (last sent)")]),
              _vm._v(" "),
              _c("td", [_vm._v("Voucher provider")]),
              _vm._v(" "),
              _c("td", [_vm._v("Voucher payment")]),
              _vm._v(" "),
              _c("td", [_vm._v("Booking note")]),
              _vm._v(" "),
              _vm.hasArchiveAccess ? _c("td") : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.bookings, function (booking, index) {
              return _c("tr", { key: booking.id }, [
                _c("td", [
                  _c("input", {
                    attrs: { type: "checkbox" },
                    domProps: { checked: _vm.isChecked(booking.id) },
                    on: {
                      click: function ($event) {
                        return _vm.toggleCheckbox(booking.id)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      attrs: { title: booking.id, href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goToBooking(booking.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(index + 1) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm.childNames
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.goToChild(booking.childId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.childNames[booking.childId]))]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm.parents[booking.childId]
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.goToParent(
                                  _vm.parents[booking.childId].id
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.parents[booking.childId].firstName) +
                                " " +
                                _vm._s(_vm.parents[booking.childId].lastName) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.parents[booking.childId].email) +
                            "\n                    "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.parents[booking.childId].telephone) +
                            "\n                "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("formatCurrency")(booking.agreedPrice)) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [
                    _c(
                      "a",
                      {
                        attrs: { href: "", title: "Open Payment Log modal" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openPaymentLogModal(booking.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("formatCurrency")(booking.amountPaid)
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("formatCurrency")(booking.creditAmountUsed)
                      ) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  booking.placeConfirmed
                    ? _c("span", [_vm._v("Confirmed")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isNotPaid(booking.amountPaid) &&
                  !booking.placeConfirmed &&
                  !booking.franchiseVoucherCodeId
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            ref: "sendPaymentReminderBtn",
                            refInFor: true,
                            staticClass: "btn btn-light btn-sm",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.sendPaymentReminder(
                                  $event,
                                  booking.id
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Send\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        booking.paymentReminderLastSent
                          ? _c("small", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.getDate(
                                      booking.paymentReminderLastSent * 1000
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "span",
                    {
                      ref: "tooltip",
                      refInFor: true,
                      style: {
                        color: booking.voucherPaymentConfirmed
                          ? "#5cb85c"
                          : "#ec971f",
                      },
                      attrs: {
                        title: booking.voucherPaymentConfirmed
                          ? "Voucher payment confirmed"
                          : "Voucher payment NOT confirmed",
                        "data-bs-toggle": "tooltip",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.voucherCodeProviders[
                            booking.franchiseVoucherCodeId
                          ]
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  booking.voucherPaymentConfirmed
                    ? _c("span", [_vm._v("Confirmed")])
                    : _vm._e(),
                  _vm._v(" "),
                  booking.franchiseVoucherCodeId &&
                  !booking.voucherPaymentConfirmed
                    ? _c("span", [
                        _c(
                          "button",
                          {
                            ref: "sendVoucherPaymentReminderBtn",
                            refInFor: true,
                            staticClass: "btn btn-light btn-sm",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.sendVoucherPaymentReminder(
                                  $event,
                                  booking.id
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Chase voucher\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        booking.voucherPaymentReminderLastSent
                          ? _c("small", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.getDate(
                                      booking.voucherPaymentReminderLastSent *
                                        1000
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("td", [
                  !booking.paymentNote
                    ? _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.showNotes(booking)
                            },
                          },
                        },
                        [_c("i", { staticClass: "bi-pencil-square" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showNotes(booking)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(booking.paymentNote) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.hasArchiveAccess
                  ? _c("td", { staticClass: "text-end" }, [
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-sm",
                            attrs: {
                              "data-bs-toggle": "dropdown",
                              type: "button",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Action\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu dropdown-menu-end",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.archiveBooking(booking.id)
                                  },
                                },
                              },
                              [_vm._v("Archive")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelBooking(booking.id)
                                  },
                                },
                              },
                              [_vm._v("Cancel and credit")]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "my-3 py-3" }, [
      _c("h2", [_vm._v("\n            Waiting list\n        ")]),
      _vm._v(" "),
      _vm.waitingListBookings.length
        ? _c("p", [
            _vm.hasArchiveAccess
              ? _c(
                  "button",
                  {
                    staticClass: "mb-2 btn btn-primary",
                    attrs: {
                      disabled: _vm.isButtonDisabled(true),
                      type: "button",
                    },
                    on: { click: _vm.archiveWaitListItems },
                  },
                  [_vm._v("\n                Archive\n            ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "mb-2 btn btn-primary",
                attrs: { disabled: _vm.isButtonDisabled(true), type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addMultipleToBookings($event)
                  },
                },
              },
              [_vm._v("\n                Add to bookings\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "mb-2 btn btn-primary",
                attrs: { disabled: _vm.isButtonDisabled(true), type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.sendWaitListReminders($event)
                  },
                },
              },
              [_vm._v("\n                Send reminder\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "mb-2 btn btn-primary",
                class: { "btn-warning": _vm.parentsEmailSending },
                attrs: {
                  disabled:
                    _vm.isButtonDisabled(true) || _vm.parentsEmailSending,
                  type: "button",
                },
                on: {
                  click: function ($event) {
                    return _vm.sendParentsEmail(true)
                  },
                },
              },
              [_vm._v("\n                Email parents\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "mb-2 btn btn-primary",
                attrs: { disabled: _vm.isButtonDisabled(true), type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.openWorksheet(136, true, "Booking table")
                  },
                },
              },
              [_vm._v("\n                Open booking table\n            ")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.waitingListBookings.length
        ? _c("p", [_c("em", [_vm._v("empty")])])
        : _vm._e(),
      _vm._v(" "),
      _vm.waitingListBookings.length
        ? _c("table", { staticClass: "table table-condensed" }, [
            _c("thead", [
              _c("tr", [
                _c("td", [
                  _c("input", {
                    attrs: { type: "checkbox" },
                    domProps: { checked: _vm.allWaitingListCheckboxesChecked },
                    on: {
                      click: function ($event) {
                        return _vm.selectAllCheckboxes(true)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td", [_vm._v("Child")]),
                _vm._v(" "),
                _c("td", [_vm._v("Parent")]),
                _vm._v(" "),
                _c("td", [_vm._v("Agreed price")]),
                _vm._v(" "),
                _c("td", [_vm._v("Booking reminder (last sent)")]),
                _vm._v(" "),
                _c("td", [_vm._v("Voucher provider")]),
                _vm._v(" "),
                _c("td"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.waitingListBookings, function (booking, index) {
                return _c("tr", { key: booking.id }, [
                  _c("td", [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.isChecked(booking.id, true) },
                      on: {
                        click: function ($event) {
                          return _vm.toggleCheckbox(booking.id, true)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        attrs: { title: booking.id, href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goToBooking(booking.id, true)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(index + 1) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm.waitingListChildNames
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.goToChild(booking.childId)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.waitingListChildNames[booking.childId]
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm.waitingListParents[booking.childId]
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.goToParent(
                                    _vm.waitingListParents[booking.childId].id
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.waitingListParents[booking.childId]
                                    .firstName
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.waitingListParents[booking.childId]
                                      .lastName
                                  )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.waitingListParents[booking.childId].email
                              ) +
                              "\n                        "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.waitingListParents[booking.childId]
                                  .telephone
                              ) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("formatCurrency")(booking.agreedPrice)) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [
                      _c(
                        "button",
                        {
                          ref: "sendWaitingListBookingReminderBtn",
                          refInFor: true,
                          staticClass: "btn btn-light btn-sm",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.sendWaitingListBookingReminder(
                                booking.id
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Send\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      booking.bookingNotificationLastSent
                        ? _c("small", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.getDate(
                                    booking.bookingNotificationLastSent * 1000
                                  )
                                ) +
                                "\n                        "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.voucherCodeProviders[
                            booking.franchiseVoucherCodeId
                          ]
                        ) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-end" }, [
                    _c("input", {
                      staticClass: "btn btn-light btn-sm",
                      attrs: { type: "button", value: "Add to bookings" },
                      on: {
                        click: function ($event) {
                          return _vm.addToBookings($event, booking.id)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("h2", [_vm._v("New course dates email")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-light",
          attrs: {
            disabled: _vm.isButtonDisabled(false) && _vm.isButtonDisabled(true),
            type: "button",
          },
          on: { click: _vm.sendNewCourseDatesEmail },
        },
        [_vm._v("\n            Send\n        ")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "ms-3" }, [
        _vm._v("\n            Last sent:\n            "),
        _vm.newCourseDatesEmailLastSent
          ? _c("strong", [
              _vm._v(
                _vm._s(_vm.getDate(_vm.newCourseDatesEmailLastSent * 1000))
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.newCourseDatesEmailLastSent
          ? _c("em", [_vm._v("\n                never\n            ")])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }