<template>
    <div class="root">
        <div>
            <h5 class="list-group-item-heading">Delete this {{ label }}</h5>

            <p class="mb-0">
                Once you delete a {{ label }}, all data is deleted and there is no going back. Please
                be certain.
            </p>
        </div>

        <button class="btn btn-danger ms-2" href="" type="button" @click="deleteForm">
            Delete this {{ label }}
        </button>
    </div>
</template>

<script>
export default {
    name: "ComponentSettingsDeleteFormItem",
    components: {},
    computed: {
        tableName() {
            return this.$route.params.component
        },
        component() {
            return this.$store.state.components.items.find(o => o.tableName === this.tableName)
        },
        isForm() {
            return this.component.navigationGroup === 'Forms'
        },
        label() {
            return this.isForm ? 'form' : 'content type'
        }
    },
    methods: {
        deleteForm() {
            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you absolutely sure?',
                    modalBody: `
                        <div class="alert alert-danger">
                            <strong>This action will result in loss of data.</strong>
                        </div>
                        <p>
                            This action <strong>cannot</strong> be undone. This will permanently delete the ` + this.label + `
                            <strong>` + this.component.title + `</strong> and all of its data.
                        </p>
                    `,
                    confirmStr: this.component.title,
                    onConfirm: () => {
                        this.$store.dispatch('itemData/delete', {
                                tableName: 'modulecomponents',
                                id: this.component.id
                            })
                            .finally(() => {
                                this.$router.push({name: 'dashboard'})
                            })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.root {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.root > :first-child {
    flex: auto;
}
</style>