var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 px-2" },
    [
      _c("header", { staticClass: "d-flex align-items-center py-2" }, [
        _c(
          "div",
          { staticClass: "flex-fill" },
          [
            _c("ThemesEditorSectionMenu"),
            _vm._v(" "),
            _c("ThemesEditorCssGroupsMenu"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dropdown" },
          [
            _c(
              "Button",
              {
                staticClass: "btn-sm",
                attrs: { "data-bs-toggle": "dropdown" },
              },
              [_c("i", { staticClass: "bi-three-dots" })]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-menu dropdown-menu-end" },
              [
                _c("ThemesEditorAddBtn"),
                _vm._v(" "),
                _c("ThemesEditorSearchBtn"),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("ThemesEditorNavSection"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }