var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.templateId
    ? _c("div", [
        _c(
          "div",
          { staticClass: "mt-1" },
          [
            _c(
              "Button",
              {
                ref: "button",
                staticClass: "btn-sm",
                attrs: {
                  "data-bs-toggle": "collapse",
                  "data-bs-target": "#collapseExample",
                  "aria-expanded": "false",
                  "aria-controls": "collapseExample",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleText.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(_vm.buttonText) + "\n        ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "collapse", attrs: { id: "collapseExample" } },
          [
            _c(
              "p",
              {
                staticClass: "form-text ms-2 mt-0 mb-2",
                staticStyle: { "font-size": "13px" },
              },
              [
                _vm.items?.length
                  ? [
                      _vm._v(
                        "\n                Content embedded directly into the page template.\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                Changes will affect all pages using the same template.\n            "
                      ),
                    ]
                  : [_vm._v("\n                No content.\n            ")],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "list-group" },
              _vm._l(_vm.items, function (item) {
                return _c(
                  "li",
                  {
                    staticClass:
                      "list-group-item py-1 pe-2 d-flex align-items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-fill" },
                      [
                        _c("FormPageContentField", {
                          attrs: { "content-id": item.id },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("ItemStatusIcons", {
                      attrs: { id: item.id, "table-name": "content" },
                    }),
                    _vm._v(" "),
                    _c(
                      "Button",
                      {
                        staticClass: "btn-sm",
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goToContent(item.id)
                          },
                        },
                      },
                      [_c("i", { staticClass: "bi-pencil-square" })]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }