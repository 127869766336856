var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasExportAccess
    ? _c(
        "button",
        {
          staticClass: "d-flex align-items-center",
          attrs: { type: "button" },
          on: { click: _vm.exportCsv },
        },
        [
          _c("i", { staticClass: "me-2 bi-upload" }),
          _vm._v("\n    Export CSV\n"),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }