<script>
import FormControlTypeText from '../form-control-type/FormControlTypeText'

export default {
    name: "FormControlAccountSettingsPrimaryDomain",
    extends: FormControlTypeText,
    watch: {
        value(newValue) {
            this.validate('')

            let restrictedDomains = [
                'creativecms.io'//,
                //'creativecms.co.uk'
            ]

            restrictedDomains.forEach((domain) => {
                if (
                    newValue
                    && newValue.indexOf(domain) >= 0
                ) {
                    let msg = '<strong>' + domain + '</strong> domains may not be used.'
                    this.validate(msg)
                }
            })
        }
    },
    methods: {
        validate(msg) {
            this.field.error = msg

            // The form service checks the form el's validity.
            let el = this.$refs.field
            el.setCustomValidity(msg)
            // Fires the invalid event immediately so that the error state appears immediately,
            // else there's a slight delay.
            el.checkValidity()
        }
    }
}
</script>

<style scoped>

</style>