var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "wrapper" },
    _vm._l(_vm.items, function (item, index) {
      return _vm.items
        ? _c(
            "span",
            {
              directives: [{ name: "tooltip", rawName: "v-tooltip" }],
              key: index,
              staticClass: "badge me-1",
              class: _vm.getClass(item.event),
              staticStyle: { "text-transform": "capitalize" },
              attrs: {
                title: item.recipient,
                "data-bs-placement": "bottom",
                "data-bs-toggle": "tooltip",
              },
            },
            [_vm._v("\n        " + _vm._s(item.event) + "\n    ")]
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }