import axios from 'axios'
import param from 'jquery-param'

export default {
    namespaced: true,
    state: {
        token: undefined,
    },
    mutations: {},
    getters: {
        getAuth(state) {
            if (!state.token) {
                state.token = localStorage.getItem('token')
            }
            
            return 'Bearer ' + state.token
        },
        // Providing rootState in the first parentheses generated errors because the property wasn't set.
        prefixUrl: (state, getters, rootState) => (url) => {
            return url.indexOf('http') === 0 ? url : rootState.apiEndpoint + '/' + url
        }
    },
    actions: {
        get({getters, dispatch, rootState}, {url, params}) {
            
            let headers = {
                'Content-Type': 'application/json'
            }
            
            // The following requests are sent pre authentication so must provide the site name, which allows the API
            // to (in the case of 'login') authenticate or (in the case of 'check-build') check if the site name
            // matches an existing database.
            url === 'api/check-build'
                ? headers['Db-Name'] = rootState.sitename
                : headers['Authorization'] = getters.getAuth
            
            return dispatch('createHttp', {
                url: getters.prefixUrl(url),
                method: 'GET',
                headers: headers,
                params: params,
            })
                .catch(() => {
                }) // Added to suppress Uncaught (in promise) Error
        },
        post({getters, dispatch, rootState}, {url, postData, customHeaders, onUploadProgress}) {
            
            let config = {
                // Only apply the endpoint prefix if the URL doesn't already include a host
                url: getters.prefixUrl(url),
                method: 'POST',
                data: postData,
                headers: {
                    'Content-Type': 'application/json'
                },
                onUploadProgress: onUploadProgress
            }
            
            // The following requests are sent pre authentication so must provide the site name, which allows the API
            // to (in the case of 'login') authenticate or (in the case of 'check-build') check if the site name
            // matches an existing database.
            if (
                url === 'api/auto-login'
                || url === 'api/login'
                || url === 'api/login/forgot-password'
            ) {
                config.headers['Db-Name'] = rootState.sitename
            } else {
                config.headers['Authorization'] = getters.getAuth
            }
            
            if (customHeaders) {
                Object.assign(config.headers, customHeaders)
            }
            
            return dispatch('createHttp', config)
                //.catch((error) => {
                //    console.log('x', error.response.status);
                //}) // Added to suppress Uncaught (in promise) Error
        },
        patch({getters, dispatch, rootState}, {url, postData}) {
            return dispatch('createHttp', {
                url: getters.prefixUrl(url),
                method: 'PATCH',
                data: postData,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getters.getAuth
                }
            })
        },
        delete({getters, dispatch, rootState}, {url, params}) {
            return dispatch('createHttp', {
                url: getters.prefixUrl(url),
                method: 'DELETE',
                headers: {
                    'Authorization': getters.getAuth
                },
                params: params
            })
                .catch(() => {
                }) // Added to suppress Uncaught (in promise) Error
        },
        createHttp({state, dispatch}, config) {
            
            config.paramsSerializer = (params) => {
                //console.log('params', param(params));
                // Build the query string
                return param(params)
            }
            
            let http = axios(config)
            
            http
                .catch((error) => {
                    switch (error.response.status) {
                        
                        // Unauthorized exception
                        case 401:
                            if (state.token) {
                                state.token = false
                                localStorage.removeItem('token')
                                /*
                                User.reset();
                                */
                                window.location = '/'
                            }
                            break
                        
                        // Conflict exception
                        case 409:
                            dispatch('toasts/add', {
                                body: error.response.data.message
                            }, {root: true})
                            break
                        
                    }
                })
    
            // By returning a Promise, its resolve or reject will be bound to the then or catch of all scripts which
            // handle the response. Without this the scripts cannot use catch.
            return new Promise((resolve, reject) => {
                http.then(resolve).catch(reject)
            })
        }
    },
}