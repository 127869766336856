<template>
    <div v-if="show && cssGroupNames" class="dropdown">
        <button class="btn btn-light btn-sm dropdown-toggle" data-bs-toggle="dropdown" type="button">
            {{ activeCssGroupName }}
        </button>
        <div class="dropdown-menu">
            <button
                type="button"
                v-for="groupName in groupNames"
                :key="groupName"
                :class="{ 'active': groupName === activeCssGroupName }"
                class="dropdown-item"
                @click="setActiveCssGroupName(groupName)"
            >
                {{ groupName }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ThemesEditorCssGroupsMenu",
    computed: {
        activeCssGroupName() {
            return this.$store.state.themes.activeCssGroupName
        },
        cssGroupNames() {
            return this.$store.state.themes.cssGroupNames
        },
        tableName() {
            return this.$store.state.themes.tableName
        },
        // todo - Can't this just be in data as it doesn't change?
        noneGroupName() {
            return this.$store.state.themes.noneGroupName
        },
        allGroupName() {
            return this.$store.state.themes.allGroupName
        },
        show() {
            return this.tableName === 'themes_css'
        },
        groupNames() {
            // Make a copy so we don't modified ThemesService.
            let groupNames = JSON.parse(JSON.stringify(this.cssGroupNames))
            groupNames.unshift(this.noneGroupName)
            groupNames.unshift(this.allGroupName)
            return groupNames
        }
    },
    methods: {
        setActiveCssGroupName(groupName) {
            this.$store.dispatch('themes/setActiveCssGroupName', groupName)
        }
    },
}
</script>

<style scoped>

</style>