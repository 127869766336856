<template>
    <a :title="fileName" href @click.prevent="onClick">{{ fileName }}</a>
</template>

<script>
export default {
    name: "TableCellTypeImage",
    props: {
        item: Object,
        value: Number,
        viewId: String,
    },
    data() {
        return {
            fileName: '',
            isImage: false,
        }
    },
    created() {
        this.$store.dispatch('itemData/get', {
                tableName: 'files',
                id: this.value
            })
            .then((obj) => {
                if (!obj) {
                    return
                }

                this.fileName = obj.fileName

                this.isImage = (
                    obj.mimetype
                    && obj.mimetype.indexOf('image') === 0 // e.g. image/jpeg image/gif
                )

                if (this.isImage) {
                    this.$store.commit('lightbox/add', {
                        id: this.value,
                        title: obj.fileName,
                        src: this.$store.getters.fileSrc(obj.directory, obj.fileName),
                        onShow: () => {
                            this.$store.dispatch(this.viewId + '/onClick', this.item.id)
                        }
                    })
                }
            })
    },
    methods: {
        onClick() {
            if (this.isImage) {
                this.$store.dispatch('lightbox/show', this.value)
            }
        }
    }
}
</script>

<style scoped>

</style>