<template>
    <div>
        <div v-if="alphaMode" class="d-flex h-100">
            <!-- Without w-100 and overflow-hidden different code mirror's widths jump around -->
            <main class="flex-fill w-100 overflow-hidden">
                <CodemirrorEditor v-if="editor && show" :data="editor"/>
            </main>

            <aside class="w-50">
                <ThemesEditorPreviewPane :id="editor.id" :code="editor.code" class="themes-editor-preview-pane"/>
            </aside>
        </div>

        <CodemirrorEditor v-else-if="editor && show" :data="editor" class="themes-editor--codemirror--main"/>

        <ThemesEditorFooter :editor="editor" :save-editor="saveEditor"/>
    </div>
</template>

<script>
import CodemirrorEditor from '../../common/codemirror/CodemirrorEditor'
import ThemesEditorPreviewPane from './ThemesEditorPreviewPane'
import ThemesEditorFooter from './ThemesEditorFooter'

export default {
    name: "ThemesEditorCodemirror",
    components: {
        CodemirrorEditor,
        ThemesEditorPreviewPane,
        ThemesEditorFooter,
    },
    props: {
        editor: Object,
        latestCode: String,
        focused: Boolean,
    },
    data() {
        return {
            show: true,
            showPreview: false,
        }
    },
    computed: {
        alphaMode() {
            return 0//this.$store.state.alphaMode;
        }
    },
    created() {
        if (this.editor.tableName === 'templates_templates') {
            this.showPreview = true
        }
    },
    destroyed() {
        window.removeEventListener('keydown', this.onKeyDown)
    },
    watch: {
        // When the code mirror is focused we take control of CMS + S
        focused(newValue) {
            if (newValue) {
                window.addEventListener('keydown', this.onKeyDown)
            }
        },
        latestCode() {
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    methods: {
        saveEditor() {
            if (this.editor.isModified) {
                let settings = this.$store.getters['themes/getSectionsSettings'](this.editor.tableName)
                let fieldName = settings.fieldName

                let data = {}
                data[fieldName] = this.editor.code

                this.$store.dispatch('request/patch', {
                        url: 'api/component/' + this.editor.tableName + '/' + this.editor.id,
                        postData: data
                    })
                    .then(() => {
                        this.$store.dispatch('itemData/set', {
                            tableName: this.editor.tableName,
                            id: this.editor.id,
                            property: fieldName,
                            value: this.editor.code
                        })
                        this.editor.isModified = false
                        this.editor.originalCode = this.editor.code

                        this.$store.commit('cacheNeedsClearing')
                    })
            }
        },
        onKeyDown(e) {
            if (
                e.metaKey // CMD key
                && e.keyCode === 83 // S key
            ) {
                e.preventDefault()
                this.saveEditor()
                //return false;
            }
        }
    }
}
</script>

<style scoped>
.themes-editor--codemirror--main {
    flex: auto;
    position: relative;
    height: calc(100% - 30px);
}

.themes-editor-preview-pane {
    display: block;
    /*padding: 10px;*/
    height: 100%;
}

.themes-editor-preview-pane iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.themes-editor-preview-pane-ajax-loader-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}
</style>