<template>
    <Button v-if="show" @click.native="goToComponentSettings">
        <i class="bi-gear-fill me-2"/>
        Settings
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "ComponentSettingsBtn",
    components: {Button},
    props: {
        tableName: String,
    },
    computed: {
        component() {
            return this.$store.state.components.items.find(o => o.tableName === this.tableName)
        },
        user() {
            return this.$store.state.user.user
        },
        show() {
            return (
                this.user.administrator
                || (
                    this.user.access?.__formAccess
                    && this.component.navigationGroup === 'Forms'
                )
            )
        }
    },
    methods: {
        goToComponentSettings() {
            let tableName = this.$route.params.component
            this.$router.push({name: 'componentSettings', params: {component: tableName}})
        },
    },
}
</script>

<style scoped>

</style>