var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { ref: "dropdown", staticClass: "dropdown d-inline-block" },
    [
      _c(
        "Button",
        {
          staticClass: "rounded-0",
          attrs: { "data-bs-toggle": "dropdown", type: "button" },
        },
        [_c("i", { staticClass: "bi bi-list" })]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dropdown-menu",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _vm._l(_vm.contentAreaNames, function (contentAreaName) {
            return [
              _c(
                "a",
                {
                  staticClass:
                    "content-area-item dropdown-item small strong text-body-tertiary text-uppercase d-flex align-items-center py-0 pe-1",
                  attrs: { role: "button" },
                  on: {
                    mouseover: function ($event) {
                      return _vm.mouseoverContentArea(contentAreaName)
                    },
                    mouseout: _vm.mouseoutContentArea,
                    click: function ($event) {
                      return _vm.selectContentArea(contentAreaName)
                    },
                  },
                },
                [
                  _c("small", { staticClass: "flex-fill" }, [
                    _vm._v(_vm._s(contentAreaName)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link btn-sm py-1",
                      attrs: {
                        title: "Add content to: " + contentAreaName,
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showAddContentModal(contentAreaName)
                        },
                      },
                    },
                    [_c("i", { staticClass: "bi bi-plus-lg" })]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(
                _vm.getContentAreaContent(contentAreaName),
                function (content, i) {
                  return _c(
                    "a",
                    {
                      key: contentAreaName + "." + i,
                      staticClass: "dropdown-item small",
                      attrs: { role: "button" },
                      on: {
                        mouseover: function ($event) {
                          return _vm.mouseoverContent(content.pageContentId)
                        },
                        mouseout: _vm.mouseoutContent,
                        click: function ($event) {
                          return _vm.selectContent(content.pageContentId)
                        },
                      },
                    },
                    [
                      _c("PageEditorToolbarContentDropdownContentType", {
                        attrs: { "page-content-id": content.pageContentId },
                      }),
                    ],
                    1
                  )
                }
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }