<template>
    <FormControlTypeNumber v-if="!value" ref="input" :current-data="currentData" :field="field" :form-id="formId"/>
    <div v-else-if="description">
        <template v-for="(name, value) in description">
            {{ name }}: {{ value }}<br>
        </template>
    </div>
    <!-- When I originally created this component, I thought it would be useful to show
         ALL of the variation's data, by loading it from the variations table, but this
         caused an issue with BBB when he imported new variables as the variation IDs
         were modified. I've therefore reverted back to using the data from the
         description field.
    <FormControlPlainText v-else-if="items?.length">
        <template v-for="item in items">
            {{ item.name }}: {{ item.value }}<br>
        </template>
    </FormControlPlainText>-->
</template>

<script>
import FormControlTypeNumber from '../form-control-type/FormControlTypeNumber'
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlMBasketOrderitemsVariationId",
    components: {
        FormControlPlainText,
        FormControlTypeNumber
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: [Number, String]
    },
    computed: {
        description() {
            return this.currentData.description
        }
    },
    asyncComputed: {
        items() {
            return this.$store.dispatch('productVariations/getVariation', {
                productId: this.currentData.itemId,
                variationId: this.value
            })
        }
    },
}
</script>

<style scoped>

</style>