<template>
    <form v-if="currentData" ref="form" autocomplete="off" novalidate @submit.prevent="save">
        <FormFieldsets v-if="formShow" :form-id="formId" :show-legends="showLegends"/>

        <!-- Must go here else not accessible in SiteTreePanes -->
        <Dump>{{
                {
                    isModified: $store.getters[formId + '/isModified'](),
                    modifiedData: $store.getters[formId + '/modifiedData'](),
                    currentData: $store.state[formId].currentData,
                    originalData: $store.state[formId].originalData
                }
            }}
        </Dump>

        <!-- Required for the form's @submit to work.
             The form's @submit is triggered when the Enter key is used inside an input,
             but only if a submit button exists.
        --
        <button style="position: absolute; left: -9999px" tabindex="-1"/>-->
    </form>
</template>

<script>

import SidebarToggle from '../../common/sidebar-toggle/SidebarToggle'
import FormFieldsetAnchors from './FormFieldsetAnchors'
import FormFieldsets from './FormFieldsets'

export default {
    name: "Form",
    components: {
        SidebarToggle,
        FormFieldsetAnchors,
        FormFieldsets,
    },
    props: {
        formId: String,
        showLegends: Boolean,
        showOptions: Boolean,
    },
    computed: {
        formShow() {
            return this.$store.state[this.formId].show
        },
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        legends() {
            return this.$store.state[this.formId].legends
        },
        activeFieldset() {
            return this.$store.state.activeFieldsets[this.tableName]
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            // Fix for Cannot read properties of undefined
            return this.$store.state[this.formId] ? this.$store.state[this.formId].id : 0
        },
        top() {
            // Sets the top position of the first fieldset before scrolling.
            let legend = this.legends[0]
            let fieldset = this.getFieldset(legend)
            if (fieldset) {
                return fieldset.getBoundingClientRect().top
            }
        },
        scrollableEl() {
            return this.$refs.form.closest('.sidebar-toggle-section')
        },
        clickedAnchor() {
            return this.$store.state[this.formId].clickedAnchor
        }
    },
    watch: {
        formId: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.$store.commit(this.formId + '/setFormEl', this.$refs.form)

                    // Always scroll to the top when showing new forms.
                    this.scrollTop()
                })
            },
        },
        clickedAnchor(newValue) {
            if (newValue) {
                this.scrollToLegend(newValue)
            }
        }
    },
    created() {
        /* todo - https://creativecms.atlassian.net/browse/UI2-7
        if (this.id) {
            this.$store.dispatch('userTracking/start', {
                componentId: this.componentId,
                itemId: this.id,
            });
            //
        }*/
    },
    destroyed() {
        this.$store.unregisterModule(this.formId)

        /* todo - https://creativecms.atlassian.net/browse/UI2-7
        if (this.id) {
            this.$store.dispatch('userTracking/stop', {
                componentId: this.componentId,
                itemId: this.id,
            });
            //
        }*/
    },
    methods: {
        getFieldset(legend) {
            if (legend) {
                legend = legend.replace(/[^A-Z0-9]+/ig, '_')
                return this.$refs.form.querySelector('fieldset#' + legend)
            }
        },
        scrollTop() {
            if (this.scrollableEl) {
                this.scrollableEl.scroll(0, 0)
            }
        },
        scrollToLegend(legend) {
            const fieldset = this.getFieldset(legend)

            // The form must be scrolled top before we obtain the fieldset's top position below.
            this.scrollTop()

            // Without this the top wasn't always calculated correctly when changing site tree pages whilst
            // an activeFieldset was preset.
            this.$nextTick(() => {
                const top = fieldset.getBoundingClientRect().top - this.top
                this.scrollableEl.scroll(0, top)
            })
        },
        save() {
            this.$store.dispatch(this.formId + '/save')
        }
    }
}
</script>

<style scoped>

</style>