<template>
    <div v-if="options" class="list-group">
        <div v-for="(groupLayouts, groupTitle) in options" :key="groupTitle" class="list-group-item clearfix">
            <span class="list-group-item-heading">{{ groupTitle }}</span>
            <div class="btn-group float-end">
                <button v-for="(layout, layoutClass) in groupLayouts" :key="layoutClass"
                        :class="{ 'active': layoutClass === currentData[field.name] }"
                        class="btn btn-light btn-sm page-layout-button" type="button" @click="setLayout(layoutClass)">
                    <span :class="layout.className" class="page-layout-button-icon"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormControlPagesLayout",
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            templateId: undefined,
            options: undefined,
        }
    },
    created() {
        this.$store.dispatch('itemData/get', {
                tableName: 'pages',
                id: this.currentData.id
            })
            .then((pageData) => {
                this.templateId = pageData.templateId

                return this.$store.dispatch('itemData/get', {
                    tableName: 'templates_templates',
                    id: this.templateId
                })
            })
            .then((obj) => {
                (
                    !obj.templateHtml
                    || obj.templateHtml.indexOf('page_layout_areas') === -1
                )
                    ? this.field.hidden = true
                    : this.init()
            })
    },
    methods: {
        init() {
            // Remove the form control if Account Settings defaultContentLayout field is not set.
            this.$store.dispatch('itemData/get', {
                    tableName: 'account_settings',
                    id: 1
                })
                .then((obj) => {
                    if (obj.defaultContentLayout === "") {
                        this.field.hidden = true
                    }
                })

            this.setDefault()

            this.options = this.$store.state.pageContentLayouts.layouts
        },
        setLayout(layoutClass) {
            this.currentData[this.field.name] = layoutClass
        },
        setDefault() {
            if (!this.currentData[this.field.name]) {
                this.$store.dispatch('itemData/get', {
                        tableName: 'account_settings',
                        id: 1
                    })
                    .then((obj) => {
                        this.$store.dispatch(this.formId + '/setDefaultValue', {
                            name: 'layout',
                            value: obj.defaultContentLayout
                        })
                    })
            }
        }
    }
}
</script>

<style scoped>
.page-layout-button-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('../../img/page-layout-icons.png') no-repeat;
}

.page-layout-button .sidebar-left {
    background-position-x: -32px;
}

.page-layout-button .sidebar-right {
    background-position-x: -64px;
}

.page-layout-button .sidebars {
    background-position-x: -96px;
}

.page-layout-button .two-column {
    background-position-x: -128px;
}

.page-layout-button .three-column {
    background-position-x: -160px;
}

.page-layout-button .header {
    background-position-y: -32px;
}

.page-layout-button .footer {
    background-position-y: -64px;
}

.page-layout-button .header-footer {
    background-position-y: -96px;
}

.page-layout-button .narrow {
    background-position-y: -128px;
}

.page-layout-button .narrow-header {
    background-position-x: -32px;
}

.page-layout-button .narrow-footer {
    background-position-x: -64px;
}

.page-layout-button .narrow-header-footer {
    background-position-x: -96px;
}
</style>