<template>
    <FormControlPlainText v-if="dateString" :value="dateString"/>
    <FormControlPlainText v-else>
        <i class="small text-dark-emphasis">unset</i>
    </FormControlPlainText>
</template>

<script>
import moment from 'moment'

import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeDate",
    components: {
        FormControlPlainText
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object
    },
    computed: {
        dateString() {
            const timestamp = this.currentData[this.field.name]
            if (timestamp) {
                return moment(timestamp * 1000).format('DD MMM YYYY, HH:mm')
            }
        }
    }
}
</script>

<style scoped>

</style>