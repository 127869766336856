var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "button",
        {
          staticClass: "d-flex align-items-center",
          attrs: { type: "button" },
          on: { click: _vm.openModal },
        },
        [
          _c("i", { staticClass: "me-2 bi-arrow-up-circle-fill" }),
          _vm._v("\n    Bulk upload\n    "),
          _c("small", { staticClass: "ms-1 badge text-bg-warning" }, [
            _vm._v("beta"),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }