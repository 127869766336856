var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.modals, function (modal) {
        return _c(
          "div",
          {
            key: modal.index,
            staticClass: "modal",
            class: { in: modal.show },
            staticStyle: { display: "block" },
            attrs: { role: "dialog", tabindex: "-1" },
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.hideModal(modal.index)
              },
            },
          },
          [
            _c(modal.componentName, {
              tag: "component",
              attrs: { modal: modal, options: modal.options },
              on: {
                "hide-modal": function ($event) {
                  return _vm.hideModal(modal.index)
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.modals, function (modal) {
        return _c("div", { staticClass: "modal-backdrop show" })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }