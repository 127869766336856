<template>
    <button v-if="0" :disabled="!activeItemIdsLength" class="float-start btn btn-light btn-sm"
            type="button" @click.prevent.stop="onClick">
        Clear active items
    </button>
</template>

<script>
// todo - This has been disabled since implementing a dedicated notifications table.
//        If resurrected, I think it should just delete notifications which are dismissed.
export default {
    name: "NotificationsMenuClearActiveBtn",
    computed: {
        activeItemIdsLength() {
            // todo - Disabled until notifications table enhanced with active column
            //return this.$store.state.notificationsMenu.activeItemIds.length
            return 0
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch('notificationsMenu/clearActiveItems')
        }
    }
}
</script>

<style scoped>

</style>