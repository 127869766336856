<script>
import FormControlTypeSelect from '../form-control-type/FormControlTypeSelect'

// todo - In nicks-business this field was varChar, so the default value should be "", but it was set to 0.
//  The field should be upgraded to a number field and the default value set back to 0.
export default {
    name: "FormControlModulestructureSelectType",
    extends: FormControlTypeSelect,
    props: {
        formId: String,
    },
    created() {
        if (this.currentData.type === 'select') {
            // todo - I don't think this ever does anything.
            this.$store.dispatch(this.formId + '/setDefaultValue', {
                name: this.field.name,
                value: ''
            })
        } else {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>