<template>
    <input v-if="show" :checked="isChecked" type="checkbox" @change="setValue">
</template>

<script>
export default {
    name: "FormControlUsersGroupsRolesCheckbox",
    props: {
        formId: String,
        rolesGroup: String,
        component: Object,
        option: Object
    },
    computed: {
        show() {
            let optionEnabled = true

            switch (this.option.name) {
                case 'add':
                    optionEnabled = !!this.component.addNew
                    break
                case 'delete':
                    optionEnabled = !!this.component.deleteOption
                    break
                case 'archive':
                    optionEnabled = !!this.component.useArchive
                    break
            }

            return (
                optionEnabled
                && this.roles[this.rolesGroup][this.component.title][this.privilegeName]
            )
        },
        roles() {
            return this.$store.state.userRoles.roles
        },
        privilegeName() {
            return this.option.name
        },
        roleId() {
            return this.$store.state[this.formId].id
        },
        privileges() {
            return this.$store.state.userRoles.privileges?.[this.roleId]?.[this.component.id]
        },
        isChecked() {
            return this.privileges?.indexOf(this.privilegeName) > -1
        }
    },
    methods: {
        setValue() {
            this.$store.commit('userRoles/togglePrivilege', {
                roleId: this.roleId,
                componentId: this.component.id,
                privilege: this.privilegeName
            })
        }
    }
}
</script>

<style scoped>

</style>