<template>
    <button class="dropdown-item d-flex align-items-center" type="button" @click="openFindModal">
        <i class="me-2 bi-search"/>
        Find
    </button>
</template>

<script>
export default {
    name: "ThemesEditorSearchBtn",
    methods: {
        openFindModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'ThemesFindModal',
            })
        }
    }
}
</script>

<style scoped>

</style>