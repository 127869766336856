var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.components && _vm.components.length,
          expression: "components && components.length",
        },
      ],
      staticClass: "list-group-item px-2",
      attrs: { href: "" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.setActiveGroupName(_vm.groupName)
        },
      },
    },
    [
      _c("div", { staticClass: "ps-2" }, [_vm._v(_vm._s(_vm.groupName))]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "list-group mt-2",
          class: {
            "d-none":
              _vm.$store.state.navigation.openGroupName !== _vm.groupName,
            "active d-block":
              _vm.$store.state.navigation.openGroupName === _vm.groupName,
          },
        },
        _vm._l(_vm.components, function (component) {
          return _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showComponent(component.tableName),
                  expression: "showComponent(component.tableName)",
                },
              ],
              staticClass: "list-group-item px-2",
              class: {
                active:
                  component.tableName === _vm.$store.state.navigationComponent,
              },
              attrs: { role: "menuitem", tabindex: "-1" },
              on: {
                click: function ($event) {
                  return _vm.goToComponent(component.tableName)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(component.title) + "\n        ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }