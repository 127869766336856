<template>
    <span v-if="content" class="form-group-pages-content-title">
        {{ content.title }}
        <ContentTypeLabel v-if="content" :content-type-id="content.contentType"/>
    </span>
</template>

<script>
import ContentTypeLabel from '../ContentTypeLabel'

export default {
    name: "FormPageContentField",
    components: {
        ContentTypeLabel,
    },
    props: {
        contentId: Number,
    },
    data() {
        return {
            content: undefined,
        }
    },
    created() {
        this.$store.dispatch('itemData/get', {
                tableName: 'content',
                id: this.contentId
            })
            .then((item) => {
                this.content = item
            })
    }
}
</script>

<style scoped>

</style>