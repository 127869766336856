<template>
    <div>
        <form class="mb-3 d-flex align-items-center" @submit.prevent="createGroup">
            <div class="dropdown">
                <input v-model="groupStr" class="form-control form-control-lg" data-bs-toggle="dropdown">

                <div v-show="groupNames.length" class="dropdown-menu">
                    <a v-for="group in groupNames" :key="group" class="dropdown-item" @click="addGroup(group)">
                        {{ group }}
                    </a>
                </div>
            </div>

            <button class="ms-1 btn btn-sm btn-light" type="submit">
                <i class="bi-plus-lg"/>
            </button>
        </form>

        <div class="d-flex align-items-center">
            <span v-for="(group, index) in groups" :key="group" class="me-2 bg-dark text-light border rounded p-1 ps-2 d-flex align-items-center">
                {{ group }}
                <button aria-label="Close" class="btn-close btn-close-white ms-1" type="button" @click="removeGroup(index)"></button>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormControlThemesCssGroups",
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    data() {
        return {
            groupStr: '',
        }
    },
    computed: {
        groups() {
            return this.currentData.groups
        },
        groupNames() {
            let groupNames = this.groups
            let allGroupNames = this.$store.state.themes.cssGroupNames

            // [1, 2, 3] & [2, 3] = [1];
            return allGroupNames.filter(x => !groupNames.includes(x))
        }
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: 'groups',
            value: []
        })
    },
    methods: {
        createGroup() {
            let str = this.groupStr

            if (/,/.test(str)) {
                let groups = str.split(',')
                groups.forEach((group) => {
                    this.addGroup(group)
                })
            } else {
                this.addGroup(str)
            }
        },
        removeGroup(i) {
            this.groups.splice(i, 1)
        },
        addGroup(group) {
            group = group.trim()
            if (group !== '') {
                this.groups.push(group)
                this.groupStr = ''
            }
        }
    }
}
</script>

<style scoped>

</style>