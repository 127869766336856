var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userId
    ? _c("UserAvatar", {
        staticClass: "user-tracking-avatar",
        attrs: {
          "user-id": _vm.userId,
          "tooltip-placement": "right",
          "is-small": true,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }