<template>
    <SidebarToggle :name="'site_tree'" class="d-flex h-100 overflow-auto">
        <template v-slot:leftSidebar>
            <div class="m-3 me-2 flex-grow-1">
                <SiteTreeNavigationMenu :site-tree-id="siteTreeId" class="mb-3"/>

                <SiteTree :select-content="selectContent" :select-page="selectPage" :site-tree-id="siteTreeId"/>
            </div>
        </template>

        <section class="d-flex h-100 overflow-auto flex-grow-1 flex-column">
            <template v-if="pageEditorMode && pageId">
                <PageEditorIframe :site-tree-page-id="pageId" :key="pageId"/>

                <!--<PageEditorOffCanvas/>-->
            </template>

            <template v-else>
                <FormHeader v-if="formId" :form-id="formId" :show-add-btn="true"
                            :show-save-and-close-btn="showSaveAndCloseBtn" :show-save-btn="true"/>

                <FormNavAndForm v-if="formId" :form-id="formId" :show-archive-btn="true" :show-duplicate-btn="true"
                                :show-history-btn="true"/>
            </template>
        </section>
    </SidebarToggle>
</template>

<script>
import SidebarToggle from '../../common/sidebar-toggle/SidebarToggle'
import SiteTreeNavigationMenu from '../../common/site-tree/SiteTreeNavigationMenu'
import SiteTree from '../../common/site-tree/SiteTree'
import FormNavAndForm from '../../common/form/FormNavAndForm'
import FormHeader from '../../common/form/FormHeader'
import PageEditorIframe from "../page-editor/PageEditorIframe.vue"
import PageEditorOffCanvas from "../page-editor/PageEditorOffCanvas.vue"

export default {
    name: "SiteTreePanes",
    components: {
        PageEditorOffCanvas,
        PageEditorIframe,
        SidebarToggle,
        SiteTreeNavigationMenu,
        SiteTree,
        FormNavAndForm,
        FormHeader,
    },
    data() {
        return {
            formId: undefined,
            tableName: undefined,
            itemId: undefined,
            siteTreeId: 'siteTree',
        }
    },
    computed: {
        showSaveAndCloseBtn() {
            return this.tableName === 'content'
        },
        pageId() {
            return this.$store.state[this.siteTreeId]?.selectedPageId
        },
        pageEditorMode() {
            return this.$store.state.pageEditorMode
        }
    },
    watch: {
        $route(to, from) {
            if (!this.pageEditorMode) {
                this.init()
            }
        }
    },
    created() {
        this.$store.commit('navigationComponent', 'site_tree')

        let parentChildIds = this.$store.state.siteTree.parentChildIds
        if (Object.keys(parentChildIds).length === 0) {
            this.$store.dispatch(this.siteTreeId + '/init')
        }

        this.init()
    },
    methods: {
        init() {
            let id = this.$route.params.pageOrContentId

            // When deleting a page in the site tree the state is set with pageOrContentId: false
            /*if ($stateParams.pageOrContentId === 'false') {
                goToHome();

            } else */

            if (id) {

                // Content IDs in the URL are prefixed with a 'c'.
                if (
                    typeof id === 'string'
                    && id.indexOf('c') === 0
                ) {
                    id = parseInt(id.substring(1))

                    this.$store.dispatch(this.siteTreeId + '/setContentId', id)

                    this.tableName = 'content'

                    if (id > 0) {
                        const obj = this.$store.state.pageContentData.items.find(o => o.id === id)
                        // Archiving content reloads the state but the archived content is no longer available.
                        if (!obj) {
                            this.goToHome()
                        } else {
                            this.itemId = obj.contentId

                            let pageId = obj.pageId
                            this.$store.commit(this.siteTreeId + '/addExpandedPageId', pageId)
                            this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', pageId)

                            this.createForm()
                        }
                    } else {
                        this.itemId = 0
                        this.createForm()
                    }

                } else {
                    this.tableName = 'pages'
                    id = parseInt(id)
                    this.$store.dispatch(this.siteTreeId + '/setPageId', id)
                    this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', id)
                    this.itemId = id
                    this.createForm()
                }

            } else {
                // When using the navigation to go to the site tree it will always target /site-tree so this is
                // required to forward to the previously selected page or content.
                if (this.$store.state.siteTree.selectedPageId) {
                    this.goTo(this.$store.state.siteTree.selectedPageId)
                } else if (this.$store.state.siteTree.selectedContentId) {
                    this.goTo('c' + this.$store.state.siteTree.selectedContentId)
                } else {
                    this.goToHome()
                }
            }
        },
        goTo(id) {
            this.$router.push({name: 'siteTree', params: {pageOrContentId: id}})
        },
        goToHome() {
            this.goTo(this.$store.state.settings.homePage)
        },
        selectPage(pageId) {
            this.$store.dispatch(this.siteTreeId + '/setPageId', pageId)
            this.goTo(pageId)
        },
        selectContent(contentId) {
            this.$store.dispatch(this.siteTreeId + '/setContentId', contentId)
            this.goTo('c' + contentId)
        },
        createForm() {

            // Before loading a new route we must remove the previous site tree form's modal
            // else, memory issues aside, reusing the same namespace will generate errors.
            if (this.formId) {
                this.$store.unregisterModule(this.formId)
            }

            this.formId = 'form:' + this.tableName + ':' + this.itemId

            this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.itemId,
                onSave: this.onSave,
                onInit: () => {
                    // Presetting form data
                    if (this.itemId === 0) {
                        if (
                            this.tableName === 'pages'
                            && this.$store.state.siteTree.lastSelectedPageId
                        ) {
                            this.$store.dispatch('itemData/get', {
                                    tableName: 'pages',
                                    id: this.$store.state.siteTree.lastSelectedPageId
                                })
                                .then((obj) => {
                                    this.$store.commit(this.formId + '/presetData', {
                                        name: 'navigation',
                                        value: obj.navigation
                                    })
                                    this.$store.commit(this.formId + '/presetData', {
                                        name: 'parent',
                                        value: obj.parent
                                    })
                                })
                        }
                    }
                }
            })
        },
        onSave(obj) {
            // When inserting a new page select it in the site tree.
            if (
                this.tableName === 'pages'
                && this.$store.state.siteTree.selectedPageId !== obj.id
            ) {
                this.goTo(obj.id)
            }

            // Refresh site tree pane
            this.$store.dispatch('siteTree/init')
        }
    }

}
</script>

<style scoped>

</style>