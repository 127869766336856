<template>
    <FormControlPlainText :value="fileSize"/>
</template>

<script>
import humanFileSize from '../../vue/mixins/human-file-size'
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlFilesFileSize",
    components: {FormControlPlainText},
    mixins: [humanFileSize],
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        fileSize() {
            return this.currentData.fileSize ? this.humanFileSize(this.currentData.fileSize) : ''
        }
    }
}
</script>

<style scoped>

</style>