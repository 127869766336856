var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isImage
    ? _c(
        "a",
        {
          attrs: { title: _vm.value, href: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.value) + "\n")]
      )
    : _c("a", { attrs: { href: _vm.href, title: _vm.value } }, [
        _vm._v(_vm._s(_vm.value)),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }