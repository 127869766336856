var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.id
        ? _c(
            "FormSidebarBtn",
            {
              attrs: {
                "data-bs-toggle": "offcanvas",
                "data-bs-target": "#historyOffcanvas",
              },
            },
            [
              _c("i", { staticClass: "me-2 bi-clock-history" }),
              _vm._v("\n        Open history\n    "),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "offcanvas offcanvas-end w-50",
          attrs: {
            tabindex: "-1",
            id: "historyOffcanvas",
            "aria-labelledby": "historyOffcanvasLabel",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "offcanvas-body" },
            [
              _vm.open
                ? _c("ItemHistory", { attrs: { "form-id": _vm.formId } })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "p-3 text-end" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "offcanvas-header" }, [
      _c(
        "h5",
        {
          staticClass: "offcanvas-title",
          attrs: { id: "historyOffcanvasLabel" },
        },
        [_vm._v("History")]
      ),
      _vm._v(" "),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "offcanvas",
          "aria-label": "Close",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }