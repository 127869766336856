<template>
    <div class="d-flex align-items-center">
        <FormControlCategoryTitle v-if="oldValue" class="border rounded py-1 px-2 small" :table-name="tableName"
                                  :id="oldValue"/>
        <i v-else>none</i>

        <i class="mx-2 bi-caret-right-fill"/>

        <FormControlCategoryTitle v-if="newValue" class="border rounded py-1 px-2 small" :table-name="tableName"
                                  :id="newValue"/>
        <i v-else>none</i>
    </div>
</template>

<script>
import FormControlCategoryTitle from "../form-control/FormControlCategoryTitle.vue"

export default {
    name: "ItemHistoryChangeTypeRelationshipOneToMany",
    components: {
        FormControlCategoryTitle
    },
    props: {
        oldValue: Number,
        newValue: Number,
        categoryComponentId: Number
    },
    computed: {
        component() {
            return this.$store.state.components.items.find(o => o.id === this.categoryComponentId)
        },
        tableName() {
            return this.component?.tableName
        }
    }
}
</script>

<style scoped>

</style>