<template>
    <section class="row text-center">
        <div class="offset-3 col-6 offset-sm-4 col-sm-4 offset-md-5 col-md-2 pt-5 pb-4">
            <img :src="logo" alt="" class="mt-2 w-100"/>
        </div>

        <form v-if="view === 'login'"
              class="col-10 offset-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-4 offset-lg-4 col-xl-2 offset-xl-5"
              @submit.prevent="processForm">
            <div class="mb-4">
                <label class="visually-hidden form-label">Username</label>

                <input v-model="loginFormData.username" v-focus autocapitalize="off" autocorrect="off"
                       class="form-control input-lg" name="username" placeholder="Username" type="text">
            </div>

            <div class="mb-4">
                <label class="visually-hidden form-label">Password</label>

                <input v-model="loginFormData.password" autocomplete="off" class="form-control input-lg" name="password"
                       placeholder="Password" type="password">
            </div>

            <div class="mb-4">
                <button class="btn btn-primary">Sign in</button>
            </div>

            <div class="mb-4">
                <button class="btn btn-sm btn-light" type="button" @click="view = 'password'">Forgot password?</button>
            </div>
        </form>

        <form v-else-if="view === 'password'"
              class="col-10 offset-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-4 offset-lg-4 col-xl-2 offset-xl-5"
              @submit.prevent="processPasswordForm">
            <div class="mb-4">
                <label class="visually-hidden form-label">Email</label>

                <input ref="forgotPasswordEmail" v-model="loginFormData.email" autocomplete="false"
                       class="form-control input-lg" name="email" placeholder="Email" type="text">
            </div>

            <div class="mb-4">
                <button class="btn btn-primary">Submit</button>
            </div>

            <div class="mb-4">
                <button class="btn btn-light btn-sm" type="button" @click="view = 'login'">« back</button>
            </div>
        </form>

        <div v-else-if="view === 'password-email-sent'"
             class="col-10 offset-1 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4">
            <div class="alert alert-info text-start">
                <h3 class="mt-0">Check your email</h3>

                <p>
                    If you have entered a registered email address then you will shortly receive a reset password email.
                </p>

                <p>
                    If you can't find the email, you may need to check your spam folder.
                </p>
            </div>

            <p class="mb-4">
                <button class="btn btn-primary" type="button" @click="view = 'login'">Go to login</button>
            </p>
        </div>
    </section>
</template>

<script>
import searchParams from '../vue/mixins/search-params'

export default {
    name: "LoginForm",
    mixins: [searchParams],
    data() {
        return {
            loginFormData: {},
            view: '',
        }
    },
    computed: {
        logo() {
            return require('../img/creativecms-logo.png')
        }
    },
    created() {
        let params = this.getSearchParams()
        if (params.login) {
            this.autoLogin(params.login)
        } else if (!localStorage.getItem('token')) {
            this.view = 'login'
        }
    },
    watch: {
        view(newValue) {
            if (newValue === 'password') {
                this.$nextTick(() => {
                    this.$refs.forgotPasswordEmail.focus()
                })
            }
        }
    },
    methods: {
        autoLogin(loginToken) {

            // If an existing token and user exists in local storage then we must remove them first.
            // This must occur here, along with the redirect, to ensure that the previous user's initials
            // aren't displayed.
            let existingToken = localStorage.getItem('token')
            if (existingToken) {
                localStorage.removeItem('token')
                window.location.reload()
                return
            }

            this.$store.dispatch('request/post', {
                    url: 'api/auto-login',
                    postData: {
                        key: loginToken
                    }
                })
                .then((obj) => {
                    localStorage.setItem('token', obj.data.token)
                    this.loginUser(obj.data.user)

                    /*
                    // Remove the login query parameter.
                    $location.search('login', null);
                    */

                    // The redirect is only needed when we're clearing a previous user's token, see above.
                    //if (existingToken) {
                    window.location.href = '/'
                    //}
                }, (obj) => {
                    if (obj.status === 409) {
                        this.view = 'password'
                    }
                })
        },
        processForm() {
            if (!this.loginFormData.username) {
                this.$store.dispatch('toasts/add', {
                    title: 'Error',
                    body: 'Please enter a username.'
                })
                return false
            }

            if (!this.loginFormData.password) {
                this.$store.dispatch('toasts/add', {
                    title: 'Error',
                    body: 'Please enter a password.'
                })
                return false
            }

            this.$store.dispatch('request/post', {
                    url: 'api/login',
                    postData: {
                        username: this.loginFormData.username,
                        password: this.loginFormData.password
                    }
                })
                .then((obj) => {
                    if (obj.data.token) {
                        localStorage.setItem('token', obj.data.token)
                    }
                    if (obj.data.user) {
                        this.loginUser(obj.data.user)
                    }
                })
        },
        processPasswordForm() {
            if (!this.loginFormData.email) {
                this.$store.dispatch('toasts/add', {
                    title: 'Error',
                    body: 'Please enter an email address.'
                })
                return false
            }

            this.$store.dispatch('request/post', {
                    url: 'api/login/forgot-password',
                    postData: {
                        email: this.loginFormData.email
                    }
                })
                .then(() => {
                    this.view = 'password-email-sent'
                })
        },
        loginUser(user) {

            // This has been implemented to fix the issue where logging in fails to present the navigation
            // and generates loads of errors. By reloading the page after setting the token everything loads
            // error free. This may be the way to go long term for its simplicity.
            window.location.reload()
            return

            // Clear the form's fields, so when logging out the fields aren't pre-populated.
            this.loginFormData = {}

            this.$store.dispatch('user/set', user)

            this.$store.dispatch('setSite')
            this.$store.dispatch('setSettings')
                .then((obj) => {
                    /*
                    // Load the Welcome to CreativeCMS wizard
                    if (this.$store.state.alphaMode) { // DEPRECATED - Don't show for OmCore upgrades.
                        setTimeout(() => {
                            let wizardId = 1;
                            // todo - The wizard VueX module just contains loads of old AngularJS.
                            //  It needs complete refactoring.
                            this.$store.dispatch('wizard/load', wizardId)
                        }, 1000);
                    }
                    */
                })
        }
    }
}
</script>

<style scoped>

</style>