<template>
    <select
        :id="field.id"
        v-model="currentData[field.name]"
        v-form-validation
        :name="field.name"
        :required="field.required"
        class="form-select"
    >
        <option :value="''">- none -</option>
        <option
            v-for="option in options"
            :value="option.value"
        >
            {{ option.text }}
        </option>
    </select>
</template>

<script>
import formValidation from '../../vue/directives/formValidation'

export default {
    name: "FormControlTemplatesTemplatesCssGroupName",
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        options() {
            if (this.components) {
                // Extract the item's CSS groups into an array.
                var groups = []
                this.components.forEach((component) => {
                    if (component.groups) {
                        component.groups.forEach((group) => {
                            if (groups.indexOf(group) === -1) {
                                groups.push(group)
                            }
                        })
                    }
                })
                groups = groups.sort()

                let options = []
                groups.forEach((group) => {
                    options.push({
                        value: group,
                        text: group
                    })
                })

                return options
            }
        }
    },
    asyncComputed: {
        components() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/themes_css',
                })
                .then((obj) => {
                    return obj.data.items
                })
        },
    }
}
</script>

<style scoped>

</style>