var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-light",
        attrs: { type: "button" },
        on: { click: _vm.resetDisplayOrders },
      },
      [_vm._v("\n        Display orders\n    ")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-light",
        attrs: { type: "button" },
        on: { click: _vm.resetFileUsage },
      },
      [_vm._v("\n        File usage\n    ")]
    ),
    _vm._v(" "),
    _vm.betaMode
      ? _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { type: "button" },
            on: { click: _vm.purgeCache },
          },
          [_vm._v("\n        Purge cache\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }