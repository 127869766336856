<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Import CSV</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <div v-if="!importComplete">
                    <div class="mb-4">
                        <AttachmentDropzone :set-file="setFile" :use-import-service="true"/>

                        <div v-if="loading" class="text-center">
                            Processing data&hellip;
                        </div>

                        <div v-if="importingMessage" class="text-center">
                            <div v-html="importingMessage"/>

                            <div class="row mt-2">
                                <div class="col-sm-offset-4 col-sm-4">
                                    <ProgressBar :percentage="percentage"/>
                                </div>
                            </div>
                        </div>

                        <div v-if="errors.length && !loading" class="panel panel-danger">
                            <div class="card-header">
                                <h3 class="panel-title">The data contains the following error(s).</h3>
                            </div>

                            <ul class="list-group">
                                <li
                                    v-for="(error, index) in errors"
                                    :key="index"
                                    class="list-group-item"
                                    v-html="error"
                                />
                            </ul>
                        </div>

                        <div v-if="error" class="panel panel-danger">
                            <div class="card-header">
                                <h3 class="panel-title" v-html="error.title"/>
                            </div>

                            <div class="card-body" v-html="error.body"/>
                        </div>

                        <div v-if="rowCount && !loading && !importingMessage" class="panel panel-info">
                            <div class="card-header">
                                <h3 class="panel-title">
                                    <strong>{{ rowCount }} rows</strong> ready to import
                                </h3>
                            </div>

                            <div class="card-body">
                                <div class="text-center">
                                    <button class="btn btn-light" type="button" @click="importData">
                                        Import
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="importComplete" class="alert alert-success text-center">
                        Import successful!
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

import AttachmentDropzone from '../../common/AttachmentDropzone'
import ProgressBar from '../../vue/common/ProgressBar'

export default {
    name: "ImportModal",
    components: {
        AttachmentDropzone,
        ProgressBar,
    },
    props: {
        options: Object,
    },
    data() {
        return {
            tableName: this.options.tableName,
            productId: this.options.productId,
            error: false,
            errors: false,
            loading: false,
            rowCount: false,
            filePath: undefined,
            objectName: undefined,
            importComplete: false,
            textareaStyle: {},
            importingMessage: false,
            percentage: 0,
        }
    },
    computed: {
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        }
    },
    created() {
        this.resetScope()
    },
    methods: {
        resetScope() {
            this.loading = false
            this.errors = false
            this.error = false
            this.rowCount = false
            this.importComplete = false
            this.textareaStyle = {}
            this.importingMessage = false
            this.percentage = 0
        },
        setFile(obj) {
            if (obj) {
                this.error = false
                this.loading = true

                // todo filePath is legacy support. Remove once import service upgraded
                this.filePath = obj.filePath
                this.objectName = obj.objectName

                axios({
                    method: 'post',
                    url: this.$store.state.importServiceEndpoint + '/validate',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': this.$store.state.importServiceToken
                    },
                    data: {
                        authorization: 'Bearer ' + localStorage.getItem('token'),
                        buildName: this.$store.state.sitename,
                        tableName: this.tableName,
                        filePath: this.filePath,
                        objectName: this.objectName
                    }
                })
                    .then((obj) => {
                        this.errors = obj.data.errors
                        this.rowCount = obj.data.rowCount
                        this.loading = false
                    })
            }
        },
        importData() {
            this.$emit('hide-modal')

            let notificationId

            this.$store.dispatch('request/post', {
                    url: 'api/notification',
                    postData: {
                        title: 'Import',
                        data: {
                            title: this.tableName,
                            message: 'Starting&hellip;',
                            percentage: 0,
                        },
                    }
                })
                .then((obj) => {
                    notificationId = obj.data.id

                    return axios({
                        method: 'post',
                        url: this.$store.state.importServiceEndpoint + '/import',
                        headers: {
                            'Content-Type': 'application/json',
                            // Used to authenticate with the service
                            'X-Auth-Token': this.$store.state.importServiceToken
                        },
                        data: {
                            // Used by the Import service's API class to allow it to authenticate with the API.
                            authorization: 'Bearer ' + localStorage.getItem('token'),
                            buildName: this.$store.state.sitename,
                            tableName: this.tableName,
                            filePath: this.filePath,
                            objectName: this.objectName,
                            productId: this.productId,
                            activityId: notificationId,
                        }
                    })
                })
                .then(() => {
                    this.showProgressModal(notificationId)
                })

            this.$store.commit('cacheNeedsClearing')
        },
        showProgressModal(notificationId) {
            let label = this.productId ? 'variation' : 'item'
            this.$store.dispatch('modals/show', {
                componentName: 'ProgressModal',
                obj: {
                    modalTitle: 'Importing ' + label + 's…',
                    activityId: notificationId,
                    onComplete: () => {
                        let viewId = this.$store.state.mainViewId
                        this.$store.dispatch(viewId + '/scheduleLoad')
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>