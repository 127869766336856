var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex h-100 overflow-auto flex-column" },
    [
      _vm.componentHeading
        ? _c(
            "header",
            { staticClass: "d-flex align-items-center border-bottom" },
            [
              _c(
                "span",
                {
                  staticClass: "ms-2 flex-fill d-flex align-items-center small",
                },
                [
                  _c(
                    "Button",
                    {
                      staticClass: "btn-sm lh-1 me-1 p-1",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goToListing.apply(null, arguments)
                        },
                      },
                    },
                    [_c("small", [_vm._v(_vm._s(_vm.component.title))])]
                  ),
                  _vm._v(" "),
                  _c("i", { staticClass: "bi-chevron-compact-right" }),
                  _vm._v(" "),
                  _c("small", { staticClass: "px-2 d-inline-block" }, [
                    _vm._v(_vm._s(_vm.componentHeading)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("ComponentEditorBtnGroup"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", { staticClass: "overflow-auto" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }