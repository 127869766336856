<template>
    <button v-if="hasExportAccess" class="d-flex align-items-center" type="button" @click="importCsv">
        <i class="me-2 bi-download"/>
        Import CSV
    </button>
</template>

<script>
export default {
    name: "ListingOptionsImportBtn",
    props: {
        viewId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        productId() {
            return parseInt(this.$route.query.productId)
        },
        hasExportAccess() {
            return this.$store.getters['user/access'](this.tableName, 'export')
        }
    },
    methods: {
        importCsv() {
            this.$store.dispatch('modals/show', {
                componentName: 'ImportModal',
                obj: {
                    tableName: this.tableName,
                    productId: this.productId,
                }
            })
        }
    }
}
</script>

<style scoped>

</style>