<template>
    <span>
        <svg
            v-if="!options"
            class="loader uil-default"
            height="22px"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 100 100"
            width="22px"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect class="bk" fill="none" height="100" width="100" x="0" y="0"></rect><rect fill="#999999" height="20"
                                                                                           rx="5"
                                                                                           ry="5"
                                                                                           transform="rotate(0 50 50) translate(0 -30)"
                                                                                           width="7"
                                                                                           x="46.5"
                                                                                           y="40">  <animate
            attributeName="opacity" begin="0s" dur="1s" from="1" repeatCount="indefinite" to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(30 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.08333333333333333s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(60 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.16666666666666666s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(90 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.25s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(120 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.3333333333333333s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(150 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.4166666666666667s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(180 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.5s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(210 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.5833333333333334s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(240 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.6666666666666666s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(270 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.75s" dur="1s" from="1"
                              repeatCount="indefinite" to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(300 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.8333333333333334s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect><rect
            fill="#999999" height="20" rx="5" ry="5" transform="rotate(330 50 50) translate(0 -30)" width="7" x="46.5"
            y="40">  <animate attributeName="opacity" begin="0.9166666666666666s" dur="1s" from="1"
                              repeatCount="indefinite"
                              to="0"></animate></rect>
        </svg>
        <select
            v-if="options"
            v-model="value"
            class="form-select"
        >
            <option v-for="option in options" :value="option.value">
                {{ option.text }}
            </option>
        </select>
    </span>
</template>

<script>
export default {
    name: "SearchFilterFieldSelect",
    props: {
        listingName: String,
        filter: Object,
        viewId: String,
    },
    data() {
        return {
            tableName: this.listingName.split('.')[0],
            options: undefined,
            selectedValue: undefined,
        }
    },
    computed: {
        value: {
            get() {
                return this.filters.advancedFilter[this.filter.id]
            },
            set(newValue) {
                this.$store.commit(this.viewId + '/setFilter', {
                    id: this.filter.id,
                    value: newValue
                })
            }
        },
        filters() {
            return this.$store.state[this.viewId].filters
        },
    },
    created() {
        this.setOptions()
    },
    methods: {
        setOptions() {
            this.$store.dispatch('request/post', {
                    url: 'api/ListingController/getSelectFilterOptions',
                    postData: {
                        tableName: this.tableName,
                        columnName: this.filter.filterData.columnName
                    }
                })
                .then((obj) => {
                    let options = [
                        {
                            value: undefined,
                            text: 'Select…',
                        },
                        {
                            value: '--unassigned--',
                            text: '-- Unassigned --'
                        },
                    ]
                    this.options = options.concat(obj.data)
                })
        }
    }
}
</script>

<style scoped>

</style>