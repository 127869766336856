<template>
    <select v-model="categoryFilter.courseId" class="form-select form-select-sm w-auto" @change="onChange">
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "OdpCourseDatesCoursesMenu",
    props: {
        viewId: String,
    },
    data() {
        return {
            options: [],
        }
    },
    computed: {
        isSelectListing() {
            return this.$store.state[this.viewId].listingName.indexOf('.') > -1
        },
        categoryFilter() {
            return this.$store.state[this.viewId].categoryFilter
        },
        showArchive() {
            return this.$store.state[this.viewId].showArchive
        },
    },
    asyncComputed: {
        options() {
            const params = {
                fields: ['id', 'title'],
                sort: 'title',
                isArchived: this.showArchive ? 1 : 0
            }
            if (this.$store.state.user.user.franchiseId) {
                params.franchiseId = this.$store.state.user.user.franchiseId
            }

            return this.$store.dispatch('request/get', {
                    url: 'api/component/odp_courses',
                    params: params
                })
                .then((obj) => {
                    let options = [{
                        value: undefined,
                        text: 'Select a course…'
                    }]
                    obj.data.items.forEach((obj) => {
                        options.push({
                            value: obj.id,
                            text: obj.title
                        })
                    })
                    return options
                })
        }
    },
    created() {
        this.setValue()
    },
    methods: {
        setValue() {
            let s = this.$route.query
            if (s.courseId) {
                this.categoryFilter.courseId = parseInt(s.courseId)
                // todo - In the AngularJS UI, if I set a filter, left and then returned to the Course Dates
                //  component, the filter would still be active. This isn't possible with VueX modules being
                //  recreated each time. I need to look into reusing them.
                //} else if ($scope.filterSettings.categoryFilter['courseId']) {
                //    $scope.odpCourseDatesCoursesMenu = $scope.filterSettings.categoryFilter['courseId'];
            }
        },
        onChange() {
            if (this.categoryFilter.courseId) {

                /* Vue conversion - I've commented this out as I didn't see that it served any purpose.
                // Select modals should not affect query parameters.
                if (!this.isSelectListing) {
                    let query = Object.assign({}, this.$route.query, {
                        courseId: this.categoryFilter.courseId,
                    });
                    this.$router.push({ query: query });
                }*/

                this.$store.commit(this.viewId + '/categoryFilter', this.categoryFilter)

            } else {

                /* Vue conversion - I've commented this out as I didn't see that it served any purpose.
                if (!this.isSelectListing) {
                    let query = Object.assign({}, this.$route.query);
                    delete query.courseId;
                    this.$router.push({ query: query });
                }*/

                this.categoryFilter.courseId = undefined
                this.$store.commit(this.viewId + '/categoryFilter', this.categoryFilter)
            }
        }
    }
}
</script>

<style scoped>
select {
    width: 200px !important;
}
</style>