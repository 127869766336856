var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      ref: "overlay",
    },
    [
      _c(
        "a",
        {
          staticClass:
            "d-block w-100 h-100 d-flex align-items-center justify-content-center h1",
          attrs: { role: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.edit.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "bi bi-pencil-square" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }