<template>
    <span :class="classNames">{{ value }}</span>
</template>

<script>
export default {
    name: "TableCellOrderOrderStatus",
    props: {
        value: String,
    },
    computed: {
        classNames() {
            switch (this.value) {
                case 'Payment abandoned':
                case 'Payment declined':
                    return 'badge bg-danger'

                case 'Payment pending':
                case 'Payment accepted':
                    return 'badge bg-warning'

                case 'Enquiry sent':
                case 'Payment complete':
                case 'Completed':
                case 'Refunded':
                    return 'badge bg-success'

                default:
                    return 'badge bg-secondary'
            }
        }
    }
}
</script>

<style scoped>

</style>