<template>
    <ul class="list-group">
        <li v-for="item in items" :key="item.id" class="list-group-item">
            <FormControlFilesUsageItem :item="item"/>
        </li>
    </ul>
</template>

<script>
import FormControlFilesUsageItem from './FormControlFilesUsageItem'

export default {
    name: "FormControlFilesUsage",
    components: {
        FormControlFilesUsageItem,
    },
    props: {
        currentData: Object,
    },
    asyncComputed: {
        items() {
            let fileId = this.currentData.id
            if (fileId) {
                return this.$store.dispatch('request/get', {
                        url: 'api/file-usage',
                        params: {
                            fileId: fileId
                        }
                    })
                    .then((obj) => {
                        return obj.data.items
                    })
            }
        },
    }
}
</script>

<style scoped>

</style>