<script>
import FormControlTypeText from '../form-control-type/FormControlTypeText'

export default {
    name: "FormControlModulecomponentsTableName",
    extends: FormControlTypeText,
    computed: {
        titleValue() {
            return this.currentData.title
        }
    },
    watch: {
        value(val) {
            this.currentData[this.field.name] = this.formatValue(val)
        },
        titleValue(val, oldVal) {
            // If this field's current value is the formatted version of the old title value then
            // the value's are currently in sync, so set it to the formatted version of the new value.
            if (
                this.currentData[this.field.name] === this.formatValue(oldVal)
                || this.currentData[this.field.name] === ''
            ) {
                this.currentData[this.field.name] = this.formatValue(val)
            }
        }
    },
    methods: {
        formatValue(str) {
            return str
                .replace("'", "")
                .replace(/[^a-zA-Z 0-9_]+/g, '_')
                .toLowerCase()
                .replace(/ +/g, '_')
        }
    }
}
</script>

<style scoped>

</style>