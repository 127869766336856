<template>
    <span>
        <span>{{ item.title }}</span>
        <span
            v-if="count !== undefined"
            :class="{ 'bg-secondary': !count, 'bg-success': count }"
            class="badge pull-right"
            title="The number of variations using this variable."
        >{{ count }}</span>
    </span>
</template>

<script>
export default {
    name: "TableCellProductVariablesTitle",
    props: {
        item: Object,
    },
    data() {
        return {
            count: undefined,
        }
    },
    created() {
        this.$store.dispatch('request/get', {
                url: 'api/ProductOptions/getVariablesUsageCount/' + this.item.id
            })
            .then((obj) => {
                this.count = obj.data.count
            })
    }
}
</script>

<style scoped>

</style>