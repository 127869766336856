<template>
    <div ref="element" class="m-3 mt-4 me-2" role="tablist">
        <FormFieldsetAnchor v-for="(legend, index) in legends" :key="legend"
                            :class="{ 'active': isActive(legend, index) }" :title="legend"
                            @click.native.prevent="setActiveFieldset(legend)"/>
    </div>
</template>

<script>
import FormFieldsetAnchor from './FormFieldsetAnchor'

export default {
    name: "FormFieldsetAnchors",
    components: {
        FormFieldsetAnchor
    },
    props: {
        formId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        legends() {
            return this.$store.state[this.formId].legends
        },
        activeFieldset() {
            return this.$store.state.activeFieldsets[this.tableName]
        },
        showAllFieldsets() {
            return this.$store.state[this.formId].showAllFieldsets
        }
    },
    methods: {
        isActive(legend, index) {
            return (
                !this.showAllFieldsets
                && (
                    legend === this.activeFieldset
                    || (
                        !this.activeFieldset
                        && index === 0
                    )
                )
            )
        },
        setActiveFieldset(legend) {
            this.$set(this.$store.state.activeFieldsets, this.tableName, legend)

            this.$store.state[this.formId].clickedAnchor = legend
        },
    }
}
</script>

<style scoped>

</style>