var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        [
          _vm.tableName
            ? _c("ComponentSettingsBtn", {
                staticClass: "me-1",
                class: { active: _vm.routeName === "componentSettings" },
                attrs: { "table-name": _vm.tableName },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.tableName
            ? _c("ComponentFieldsBtn", {
                class: { active: _vm.routeName === "componentFields" },
                attrs: { "table-name": _vm.tableName },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }