<template>
    <dl v-if="variationData" class="dl-horizontal">
        <template v-for="o in variationData">
            <dt>{{ o.option }}</dt>
            <dd>{{ o.variable }} {{ o.imperial }}</dd>
        </template>
    </dl>
    <!-- deprecated -->
    <div v-else-if="typeof description === 'string'"></div><!-- FS's description text -->
    <dl v-else-if="description" class="dl-horizontal">
        <template v-for="(variableName, optionName) in description">
            <dt>{{ optionName }}</dt>
            <dd>{{ variableName }}</dd>
        </template>
    </dl>
</template>

<script>
export default {
    name: "TableCellOrderItemsVariationId",
    props: {
        item: Object,
        value: Number,
    },
    computed: {
        variationData() {
            return this.item.variationData
        },
        description() {
            return this.item.description
        }
    },
    asyncComputed: {
        items() {
            if (this.value) {
                return this.$store.dispatch('productVariations/getVariation', {
                    productId: this.item.itemId,
                    variationId: this.value
                })
            }
        }
    }
}
</script>

<style scoped>

</style>