<template>
    <div class="d-flex align-items-center mt-1">
        <span v-if="currentData.amountPaid" class="fs-6 me-2">£{{ currentData.amountPaid }}</span>

        <!-- A button must be used for :disabled to work -->
        <button
            :disabled="!currentData.id"
            class="btn btn-light btn-sm me-2"
            type="button"
            @click="openAddPaymentModal"
        >
            Add payment
        </button>

        <button
            class="btn btn-light btn-sm"
            type="button"
            @click.prevent="openPaymentLogModal"
        >
            Open payment log
        </button>
    </div>
</template>

<script>
export default {
    name: "FormControlOdpCourseBookingsAmountPaid",
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: String
    },
    methods: {
        openAddPaymentModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'OdpAddPaymentModal',
                obj: {
                    bookingFormId: this.formId,
                }
            })
        },
        openPaymentLogModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'OdpPaymentLogModal',
                obj: {
                    bookingId: this.currentData.id,
                }
            })
        }
    }
}
</script>

<style scoped>

</style>