<template>
    <FormControlTypeSelect v-if="options" :current-data="currentData" :field="field" :form-id="formId"
                           :options="options"/>
</template>

<script>

import FormControlTypeSelect from "../../common/form-control-type/FormControlTypeSelect.vue"

export default {
    name: "FormControlOdpCourseDatesEvent",
    components: {FormControlTypeSelect},
    props: {
        currentData: Object,
        field: Object,
        formId: String,
    },
    asyncComputed: {
        options() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/odp_event',
                    params: {
                        fields: ['id', 'name'],
                        isArchived: 0,
                        sort: 'displayOrder'
                    }
                })
                .then(o => o.data.items.map(o => ({ value: o.id, text: o.name })))
        }
    }
}
</script>

<style scoped>

</style>