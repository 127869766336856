<template>
    <div>
        <FormControlPlainText v-if="promotions.length === 0">
            <em>No promotions available.</em>
        </FormControlPlainText>

        <div v-for="promotion in promotions" class="radio">
            <label>
                <input
                    :checked="currentData.promotionId.indexOf(promotion.id) > -1"
                    type="checkbox"
                    @click="toggleSelection(promotion.id)"
                >
                {{ promotion.title }}
            </label>
        </div>
    </div>
</template>

<script>
import FormControlPlainText from "../../common/form-control/FormControlPlainText.vue"

export default {
    name: "FormControlOdpCourseDatesPromotionId",
    components: {FormControlPlainText},
    props: {
        currentData: Object,
        field: Object,
        formId: String,
    },
    created() {
        this.setIds()
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        }
    },
    asyncComputed: {
        promotions: {
            default: [],
            get() {
                if (!this.currentData.courseId) {
                    return []
                }

                return this.$store.dispatch('itemData/get', {
                        tableName: 'odp_courses',
                        id: this.currentData.courseId
                    })
                    .then((obj) => {
                        return this.$store.dispatch('request/get', {
                            url: 'api/component/odp_promotions',
                            params: {
                                franchiseId: obj.franchiseId
                            }
                        })
                    })
                    .then((obj) => {
                        return obj.data.items
                    })
            }
        }
    },
    methods: {
        toggleSelection(promotionId) {
            let i = this.currentData.promotionId.indexOf(promotionId)

            i === -1
                ? this.currentData.promotionId.push(promotionId)
                : this.currentData.promotionId.splice(i, 1)
        },
        setIds() {
            if (!this.id) {
                this.$store.commit(this.formId + '/presetData', {
                    name: this.field.name,
                    value: []
                })
                return
            }

            let categoryIds = this.$store.getters['categories/get'](this.tableName, this.field.name, this.id)
                .map(o => o.catId)

            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: categoryIds
            })
        }
    }
}
</script>

<style scoped>

</style>