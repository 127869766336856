var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-3 ps-1 small" },
    [
      _c("FormPublishBtn", {
        staticClass: "mb-3",
        attrs: { "form-id": _vm.formId },
      }),
      _vm._v(" "),
      _vm.currentData.createdDate
        ? _c("div", { staticClass: "mb-3" }, [
            _c("label", { staticClass: "form-sidebar-label" }, [
              _vm._v("Created"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-start" },
              [
                _vm.currentData.createdBy
                  ? _c("UserAvatar", {
                      staticClass: "mt-1 me-2",
                      attrs: {
                        "user-id": _vm.currentData.createdBy,
                        "is-small": false,
                        "tooltip-placement": "right",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm.currentData.createdBy
                    ? _c(
                        "div",
                        [
                          _c("UserName", {
                            attrs: {
                              id: _vm.currentData.createdBy,
                              "no-user-found-str": "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.getDate(_vm.currentData.createdDate * 1000)) +
                      "\n            "
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentData.modifiedDate
        ? _c("div", { staticClass: "mb-3" }, [
            _c("label", { staticClass: "form-sidebar-label" }, [
              _vm._v("Modified"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-start" },
              [
                _vm.currentData.modifiedBy
                  ? _c("UserAvatar", {
                      staticClass: "mt-1 me-2",
                      attrs: {
                        "user-id": _vm.currentData.modifiedBy,
                        "is-small": false,
                        "tooltip-placement": "right",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm.currentData.modifiedBy
                    ? _c(
                        "div",
                        [
                          _c("UserName", {
                            attrs: {
                              id: _vm.currentData.modifiedBy,
                              "no-user-found-str": "<em>user not found</em>",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.getDate(_vm.currentData.modifiedDate * 1000)) +
                      "\n            "
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showDisplayOrderOption && _vm.currentData.id
        ? _c("FormDisplayOrderMenu", {
            staticClass: "mb-2",
            attrs: { "form-id": _vm.formId },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showHistoryBtn
        ? _c("FormHistoryBtn", { attrs: { "form-id": _vm.formId } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDuplicateBtn
        ? _c("FormDuplicateBtn", { attrs: { "form-id": _vm.formId } })
        : _vm._e(),
      _vm._v(" "),
      _c("TagsBtn", { attrs: { "form-id": _vm.formId } }),
      _vm._v(" "),
      _c("OrderRequestReview", { attrs: { "form-id": _vm.formId } }),
      _vm._v(" "),
      _c("PrintBtn", { attrs: { "form-id": _vm.formId, "is-invoice": true } }),
      _vm._v(" "),
      _c("PrintBtn", { attrs: { "form-id": _vm.formId } }),
      _vm._v(" "),
      _vm.showArchiveBtn
        ? _c("FormArchiveBtn", { attrs: { "form-id": _vm.formId } })
        : _vm._e(),
      _vm._v(" "),
      _c("FormDeleteBtn", { attrs: { "form-id": _vm.formId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }