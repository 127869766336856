<template>
    <picture ref="picture">
        <!-- Default source -->
        <source v-if="file" :sizes="sizes" :srcset="getSrcset()">

        <!-- Additional mime-types e.g webp -->
        <source
            v-for="(format, mimeType) in types"
            v-if="file"
            :key="format"
            :sizes="sizes"
            :srcset="getSrcset(format)"
            :type="mimeType"
        >

        <img v-if="show" :alt="alt" :src="src" @click.prevent.stop="onClick">
    </picture>
</template>

<script>
export default {
    name: "Picture",
    props: {
        fileId: Number,
        sizes: String,
        alt: String,
        onClick: Function,
    },
    data() {
        return {
            types: {
                //'image/webp': 'webp',
            },
            show: false,
        }
    },
    computed: {
        widths() {
            if (this.file) {
                let pictureWidth = this.$refs.picture.clientWidth
                let widths = []
                for (var i = 100; i <= this.file.width; i += 100) {
                    // This avoids small images, smaller than the picture element's width, from being included in
                    // the srcset. If this were to happen then the image would be stretched. For example, if
                    // the picture element was output at 300w and the image was 250w then it would be stretched to
                    // 300. Without providing it no widths will be set, resulting in an empty source element, and
                    // so the browser falls back to using the original img.
                    if (i >= pictureWidth) {
                        widths.push(i)
                    }
                }

                return widths
            }
        },
        src() {
            return this.$store.getters.cdnSrc(this.file.directory, this.file.fileName)
        }
    },
    asyncComputed: {
        file() {
            return this.$store.dispatch('itemData/get', {
                    tableName: 'files',
                    id: this.fileId
                })
                .then((obj) => {
                    this.setHeight()

                    return obj
                })
        }
    },
    methods: {
        getSrcset(format) {
            let srcs = []

            this.widths.forEach((width) => {
                let src = this.$store.getters.imageWidthSrc(this.file.directory, this.file.fileName, this.file.width, this.file.modifiedDate, width)
                src += ' ' + width + 'w'
                srcs.push(src)
            })

            let lastWidth = this.widths[this.widths.length - 1]
            if (
                lastWidth // If there were no widths then the
                && lastWidth < this.file.width
            ) {
                let nextWidth = lastWidth + 100
                let src = this.$store.getters.fileSrc(this.file.directory, this.file.fileName)
                src += ' ' + nextWidth + 'w'
                srcs.push(src)
            }

            this.$nextTick(() => {
                this.show = true
            })

            return srcs.join(',')
        },
        setHeight() {
            let picture = this.$refs.picture
            picture.style.maxHeight = picture.clientWidth + 'px'
        }
    }
}
</script>

<style scoped>
picture {
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    justify-content: center;
}

img {
    max-width: 100%;
}
</style>