<template>
    <div class="list-group list-group-flush me-0">
        <a v-for="column in columns" :key="column.id" class="list-group-item p-1 d-flex align-items-center"
           @click="toggleColumnVisibility(column.id)">
            <i class="me-1 bi-check-lg" :class="{'invisible': activeColumns.indexOf(column.id) === -1}"/>
            {{ column.title }}
        </a>
    </div>
</template>

<script>
export default {
    name: "ListingColumnsList",
    props: {
        viewId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        activeColumns() {
            return this.$store.state[this.viewId].settings.columns
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        columns() {
            let objs = this.$store.getters['componentStructure/get'](this.componentId)

            // Remove devOnly columns.
            if (!this.$store.state.betaMode) {
                objs.forEach((obj, i) => {
                    if (obj.devOnly) {
                        objs.splice(i, 1)
                    }
                })
            }

            // Remove hidden fields
            objs.forEach((obj, i) => {
                if (
                    obj.type === 'hidden'
                    || obj.type === 'fieldset'
                ) {
                    objs.splice(i, 1)
                }
            })

            return objs
        },
    },
    methods: {
        toggleColumnVisibility(columnId) {

            // Create a copy of the columns array.
            let columns = JSON.parse(JSON.stringify(this.activeColumns))
            let i = columns.indexOf(columnId)

            // Append or remove the item to/from it.
            // If appending a column this will add the new column onto the end of the array
            // but, we need the ids to be listed in the display order.
            i === -1
                ? columns.push(columnId)
                : columns.splice(i, 1)

            // So we must now recreate the columns array by the order of the component's fields array.
            let viewColumns = []
            this.columns.forEach((field) => {
                if (columns.indexOf(field.id) > -1) {
                    viewColumns.push(field.id)
                }
            })

            this.$store.dispatch(this.viewId + '/setSettings', {
                columns: viewColumns,
            })
        }
    }
}
</script>

<style scoped>

</style>