<template>
    <div class="inner">
        <div
            v-for="item in items"
            :key="item.id"
            class="card"
        >
            <div class="card-header clearfix">
                <span class="float-end">{{ getDate(item.createdDate * 1000) }}</span>
            </div>
            <div class="card-body">
                <dl class="dl-horizontal">
                    <template
                        v-for="(value, name) in item.data"
                        v-if="showField(name)"
                    >
                        <dt>{{ name }}</dt>
                        <dd>{{ value }}</dd>
                    </template>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "FormControlMRegistrantsRegistrantsFormEnquiries",
    props: {
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            items: undefined,
        }
    },
    created() {
        !this.currentData.id
            ? this.field.hidden = true
            : this.setItems()
    },
    methods: {
        setItems() {
            this.$store.dispatch('request/get', {
                    url: 'api/component/form_enquiries',
                    params: {
                        customerId: this.currentData.id,
                        sort: '-createdDate'
                    }
                })
                .then((obj) => {
                    this.items = obj.data.items
                })
        },
        showField(name) {
            // Don't show password fields.
            return name.substr(0, 8) !== 'password'
        },
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YYYY, HH:mm')
        }
    }
}
</script>

<style scoped>

</style>