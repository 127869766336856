export default {
    namespaced: true,
    state: {
        promises: {},
    },
    getters: {
        typesOptions: (state) => (type) => {
            let options = []
            switch (type) {
                
                case 'checkbox':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        }
                        /*
                         {
                         condition: 'is checked',
                         text: 'is checked'
                         },
                         {
                         condition: 'is not checked',
                         text: 'is not checked'
                         }
                         */
                    ]
                    break
    
                case 'color':
                case 'range':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not',
                            text: 'is not',
                            type: type
                        }
                    ]
                    break
                
                case 'custom':
                case 'customscript':
                    break
                
                case 'date':
                case 'dateDate':
                case 'datetime-local':
                case 'time':
                    options = [
                        {
                            condition: 'date is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'date is not',
                            text: 'is not',
                            type: type
                        },
                        {
                            condition: 'date is after',
                            text: 'is after',
                            type: type
                        },
                        {
                            condition: 'date is before',
                            text: 'is before',
                            type: type
                        },
                        /*{
                            condition: 'date is in the last',
                            text: 'is in the last',
                            type: type
                        },
                        {
                            condition: 'date is not in the last',
                            text: 'is not in the last',
                            type: type
                        }/*,
                        {
                            condition: 'date is in the range',
                            text: 'is in the range',
                            type: 'dateRange'
                        }*/
                    ]
                    break
                
                case 'file':
                    options = [
                        {
                            condition: 'has file',
                            //text: 'has file'
                            text: 'exists'
                        },
                        {
                            condition: 'does not have file',
                            //text: 'does not have file'
                            text: 'does not exist'
                        }
                    ]
                    break
                
                case 'hidden':
                    break
                
                case 'internalLink':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not',
                            text: 'is not',
                            type: type
                        }
                    ]
                    break
                
                case 'number':
                case 'currency':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not',
                            text: 'is not',
                            type: type
                        },
                        {
                            condition: 'is less than',
                            text: 'is less than',
                            type: type
                        },
                        {
                            condition: 'is greater than',
                            text: 'is greater than',
                            type: type
                        }
                    ]
                    break
                
                case 'password':
                    options = [
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        }
                    ]
                    break
                
                case 'relationshipOneToMany':
                    options = [
                        {
                            condition: 'is associated to',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not associated to',
                            text: 'is not',
                            type: type
                        }
                    ]
                    break
                
                case 'relationshipManyToMany':
                    options = [
                        {
                            condition: 'is associated to many',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not associated to many',
                            text: 'is not',
                            type: type
                        }
                    ]
                    break
                
                case 'select':
                    
                    options = [
                        {
                            condition: 'select is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'select is not',
                            text: 'is not',
                            type: type
                        }
                    ]
                    break
                
                case 'email':
                case 'tel':
                case 'text':
                case 'textarea':
                case 'url':
                    options = [
                        {
                            condition: 'contains',
                            text: 'contains',
                            type: type
                        },
                        {
                            condition: 'does not contain',
                            text: 'does not contain',
                            type: type
                        },
                        {
                            condition: 'is',
                            text: 'is',
                            type: type
                        },
                        {
                            condition: 'is not',
                            text: 'is not',
                            type: type
                        },
                        {
                            condition: 'starts with',
                            text: 'starts with',
                            type: type
                        },
                        {
                            condition: 'does not start with',
                            text: 'does not start with',
                            type: type
                        },
                        {
                            condition: 'ends with',
                            text: 'ends with',
                            type: type
                        },
                        {
                            condition: 'does not end with',
                            text: 'does not end with',
                            type: type
                        }
                    ]
                    break
                
                case 'texteditor':
                    options = [
                        {
                            condition: 'contains',
                            text: 'contains',
                            type: type
                        },
                        {
                            condition: 'does not contain',
                            text: 'does not contain',
                            type: type
                        }
                    ]
                    break
            }
            
            return options
        }
    },
    actions: {
        get({state, dispatch, rootState}, obj) {
            let tableName = obj.tableName
            let refresh = obj.refresh
            
            if (
                state.promises[tableName]
                && !refresh
            ) {
                return state.promises[tableName]
                
            } else {
                state.promises[tableName] = new Promise((resolve, reject) => {
                    let componentId = rootState.components.componentIds[tableName]
                    dispatch('request/get', {
                        url: 'api/component/modules_filters',
                        params: {
                            modulecomponentsID: componentId,
                            userId: rootState.user.user.id
                        }
                    }, {root: true})
                        .then((obj) => {
                            resolve(obj.data.items)
                        })
                })
                
                return state.promises[tableName]
            }
        },
        save({dispatch, rootState}, obj) {
            let tableName = obj.tableName
            let id = obj.id
            let filterData = obj.filterData
            
            if (id) {
                return dispatch('request/patch', {
                    url: 'api/component/modules_filters/' + id,
                    postData: {
                        filterData: filterData
                    }
                }, {root: true})
            } else {
                let componentId = rootState.components.componentIds[tableName]
                return dispatch('request/post', {
                    url: 'api/component/modules_filters',
                    postData: {
                        userId: rootState.user.user.id,
                        modulecomponentsID: componentId,
                        filterData: filterData
                    }
                }, {root: true})
            }
        },
    },
}