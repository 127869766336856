export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        set({rootState, state, dispatch, rootGetters}, {stateName, tableName, itemId}) {
            switch (stateName) {
                
                case 'dashboard':
                    dispatch('setDocumentTitle', 'Dashboard')
                    break
                
                case 'siteTreePanes':
                    dispatch('setDocumentTitle', 'Site tree')
                    break
                
                case 'themesEditor':
                    dispatch('setDocumentTitle', 'Editor')
                    break
                
                case 'list':
                case 'form':
                    let component = rootState.components.items.find(o => o.tableName === tableName)
                    
                    // todo - On refreshing the browser components.items was not set, which generated a JS error.
                    if (!component) {
                        return
                    }
                    
                    let title = component.title
                    
                    if (!itemId) {
                        dispatch('setDocumentTitle', title)
                        return
                    }
                    
                    return dispatch('itemData/get', {
                        tableName: tableName,
                        id: itemId,
                    }, {root: true})
                        .then((obj) => {
                            if (obj) {
                                const nameField = rootGetters['components/nameField'](component.id)
                                
                                let title = obj[nameField]
                                if (title) {
                                    title += ' / ' + title
                                }
    
                                dispatch('setDocumentTitle', title)
                                
                            } else {
                                dispatch('setDocumentTitle', title)
                            }
                        })
            }
        },
        setDocumentTitle({}, title) {
            window.document.title = title
        }
    },
}