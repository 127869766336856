<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">&nbsp;</h4>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="mb-4 row">
                        <label class="col-sm-4 col-lg-3 form-label">Image</label>
                        <div class="col-sm-8 col-lg-9 control-wrapper">
                            <ImageSelection :file-id="fileId" :on-change="onChange"/>
                        </div>
                    </div>

                    <div class="mb-4 row">
                        <label class="col-sm-4 col-lg-3 form-label" for="altText">Alternative text</label>
                        <div class="col-sm-8 col-lg-9 control-wrapper">
                            <input id="altText" v-model="altText" :disabled="!fileId" class="form-control">
                        </div>
                    </div>

                    <div class="mb-4 row">
                        <label class="col-sm-4 col-lg-3 form-label" for="title">Title</label>
                        <div class="col-sm-8 col-lg-9 control-wrapper">
                            <input id="title" v-model="title" :disabled="!fileId" class="form-control">
                        </div>
                    </div>

                    <div class="mb-4 row">
                        <label class="col-sm-4 col-lg-3 form-label">Alignment</label>
                        <div class="col-sm-8 col-lg-9 control-wrapper">
                            <div class="btn-group">
                                <button :class="{ 'active': align === false }" :disabled="!fileId" class="btn btn-light"
                                        type="button" @click="align = false">
                                    none
                                </button>

                                <button :class="{ 'active': align === 'left'}" :disabled="!fileId" class="btn btn-light"
                                        type="button" @click="align = 'left'">
                                    <i class="bi-align-start"/>
                                </button>

                                <button :class="{ 'active': align === 'center' }" :disabled="!fileId"
                                        class="btn btn-light" type="button" @click="align = 'center'">
                                    <i class="bi-align-center"/>
                                </button>

                                <button :class="{ 'active': align === 'right' }" :disabled="!fileId"
                                        class="btn btn-light" type="button" @click="align = 'right'">
                                    <i class="bi-align-end"/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4 row">
                        <label class="col-sm-4 col-lg-3 form-label" for="width">Dimensions</label>
                        <div class="col-sm-8 col-lg-9 d-flex align-items-center">
                            <input id="width" v-model="width" :disabled="!fileId" class="form-control me-1"
                                   placeholder="w" size="3"
                                   type="number" @keyup="setWidth">
                            x
                            <input id="height" v-model="height" :disabled="!fileId" class="form-control ms-1"
                                   placeholder="h" size="3"
                                   type="number" @keyup="setHeight">

                            <button :disabled="!fileId" class="ms-1 btn btn-sm btn-light" type="button" @click="setLockRatio">
                                    <span :class="{ 'bi-unlock': lockRatio === false }"
                                          class="bi-lock"/>
                            </button>

                            <button :disabled="!fileId" class="ms-1 btn btn-sm btn-light" type="button" @click="refreshDimensions">
                                <i class="bi-arrow-clockwise"/>
                            </button>
                        </div>
                    </div>

                    <div class="mb-4 row">
                        <label class="col-sm-4 col-lg-3 form-label" for="sizes">Sizes</label>
                        <div class="col-sm-8 col-lg-9">
                            <input id="sizes" v-model="sizes" :disabled="!fileId" class="form-control">
                        </div>
                    </div>
                </form>
            </div>
        </div>

        {{ fileId }}
    </div>
</template>

<script>
import ImageSelection from '../../common/ImageSelection'

export default {
    name: "ImageSelectionModal",
    components: {
        ImageSelection
    },
    props: {
        options: Object,
    },
    data() {
        return {
            fileId: 0,
            editor: this.options.editor,
            image: this.options.image,
            parent: undefined,
            alignmentClasses: [
                'text-editor-image-left',
                'text-editor-image-center',
                'text-editor-image-right'
            ],
            align: false,
            altText: '',
            height: '',
            lockRatio: true,
            progress: false,
            sizes: '',
            title: '',
            width: '',
        }
    },
    created() {
        if (this.image) {
            this.fileId = parseInt(this.image.$.currentSrc.split('/').pop())

            this.parent = this.image.getParent()

            this.altText = this.image.getAttribute('alt') || ''
            this.height = this.image.getAttribute('height') || ''
            this.sizes = this.image.getAttribute('sizes') || ''
            this.title = this.image.getAttribute('title') || ''
            this.width = this.image.getAttribute('width') || ''

            this.setAlignment()
        }
    },
    watch: {
        title(newValue) {
            if (this.image) {
                this.image.setAttribute('title', newValue)
            }
            this.editor.fire('change')
        },
        altText(newValue) {
            if (this.image) {
                this.image.setAttribute('alt', newValue)
            }
            this.editor.fire('change')
        },
        width(newValue) {
            if (this.image) {
                this.image.setAttribute('width', newValue)
            }
            this.editor.fire('change')
        },
        height(newValue) {
            if (this.image) {
                this.image.setAttribute('height', newValue)
            }
            this.editor.fire('change')
        },
        sizes(newValue) {
            if (this.image) {
                this.image.setAttribute('sizes', newValue)
            }
            this.editor.fire('change')
        },
        align(newValue) {

            if (this.image) {
                this.image.removeClass(this.alignmentClasses[0])
            }
            this.parent.removeClass(this.alignmentClasses[1])
            if (this.image) {
                this.image.removeClass(this.alignmentClasses[2])
            }

            // Ckeditor fails to assign the class on the first attempt.
            // So assigning a blank class here works around this issue.
            // UPDATE: This started throwing error, I believe due to it being an empty string.
            //this.image.addClass('');

            switch (newValue) {
                case 'left':
                    if (this.image) {
                        this.image.addClass(this.alignmentClasses[0])
                    }
                    break
                case 'center':
                    this.parent.addClass(this.alignmentClasses[1])
                    break
                case 'right':
                    if (this.image) {
                        this.image.addClass(this.alignmentClasses[2])
                    }
                    break
                default:
                    break
            }

            this.editor.fire('change')
        }
    },
    methods: {
        onChange(item) {
            this.fileId = item.id

            if (item.width) {
                this.width = item.width
            }

            if (item.height) {
                this.height = item.height
            }

            let src = '/asset/file/' + this.fileId

            // When an existing image is being replaced we manipulate the image element.
            if (this.image) {
                this.image.setAttribute('src', src)
                this.image.data('cke-saved-src', src)

                // When inserting a new image we must create the img element.
            } else {
                let style = new CKEDITOR.style({
                    element: 'img',
                    attributes: {
                        src: src,
                        id: 'newElementsTempId'
                    }
                })
                style.type = CKEDITOR.STYLE_INLINE
                style.apply(this.editor)

                // Select the new image
                let element = this.editor.document.getById('newElementsTempId')
                element.removeAttribute('id') // Remove the temp ID.
                this.editor.getSelection().selectElement(element)

                this.image = element
                this.parent = this.image.getParent()
            }

            this.editor.fire('change')
        },
        // Determines the image's current alignment by checking it and it's
        // parent element for the alignment classes.
        setAlignment() {
            let align = false
            if (this.image.hasClass(this.alignmentClasses[0])) {
                align = 'left'
            } else if (this.parent.hasClass(this.alignmentClasses[1])) {
                align = 'center'
            } else if (this.image.hasClass(this.alignmentClasses[2])) {
                align = 'right'
            }
            this.align = align
        },
        getOriginalDimensions() {
            let w = this.image.getSize('width')
            let h = this.image.getSize('height')

            // Get the original width and height of the image so that we can determine the width/height ratio
            this.image.removeAttributes(['width', 'height'])

            let vars = {
                width: this.image.getSize('width'),
                height: this.image.getSize('height')
            }

            this.image.setAttributes({
                width: w,
                height: h
            })

            return vars
        },
        setWidth() {
            if (this.lockRatio) {
                if (this.width === '') {
                    this.height = ''
                } else {
                    let a = this.getOriginalDimensions()
                    this.height = Math.round(this.width * (a.height / a.width))
                }
            }
        },
        setHeight() {
            if (this.lockRatio) {
                if (this.height === '') {
                    this.width = ''
                } else {
                    let a = this.getOriginalDimensions()
                    this.width = Math.round(this.height * (a.width / a.height))
                }
            }
        },
        refreshDimensions() {
            let a = this.getOriginalDimensions()
            this.width = a.width
            this.height = a.height
        },
        setLockRatio() {
            if (this.fileId) {
                this.lockRatio = !this.lockRatio
                // When enabling the lock ratio update the height to match the current width.
                this.setWidth()
            }
        },
    }
}
</script>

<style scoped>

</style>