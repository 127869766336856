<template>
    <button class="d-flex align-items-center" type="button" @click="openModal">
        <i class="me-2 bi-list-columns-reverse"/>
        Columns
    </button>
</template>

<script>
export default {
    name: "ListingOptionsColumnsBtn",
    props: {
        viewId: String,
    },
    methods: {
        openModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'ListingColumnsModal',
                obj: {
                    viewId: this.viewId
                }
            })
        }
    },
}
</script>

<style scoped>

</style>