<template>
    <div class="search-field">
        <div class="dropdown">
            <button class="btn btn-light btn-lg dropdown-toggle" data-bs-toggle="dropdown" type="button">
                <small v-if="searchColumn">{{ searchColumnTitle }}</small>
            </button>

            <ul class="dropdown-menu" @click.stop>
                <li @click="selectColumn(false)">
                    <a :class="{ active: searchColumn === false }" class="dropdown-item">-- All --</a>
                </li>

                <li @click="selectColumn(primaryKey)">
                    <a :class="{ active: searchColumn.columnName === primaryKey }" class="dropdown-item">ID</a>
                </li>

                <li v-for="column in searchColumns" :key="column.id" @click="selectColumn(column.columnName)">
                    <a :class="{ active: column.columnName === searchColumn }" class="dropdown-item">{{ column.title}}</a>
                </li>
            </ul>
        </div>

        <input v-model="searchTerm" v-focus autocomplete="off" class="mx-1 form-control" name="search"
               placeholder="Search" tabindex="1" type="text" @keydown.enter="hideModal">

        <button class="btn btn-light btn-lg search-field-search-btn" type="button" @click="hideModal">
            <i class="bi-search"/>
        </button>
    </div>
</template>

<script>
export default {
    name: "SearchField",
    props: {
        viewId: String,
        hideModal: Function,
    },
    data() {
        return {
            timeout: false,
        }
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        tableName() {
            return this.listingName.split('.')[0]
        },
        isNamespacedListing() {
            return this.listingName.indexOf('.') > -1
        },
        primaryKey() {
            return this.tableName + 'ID'
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        searchColumn() {
            return this.$store.state[this.viewId].searchColumn
        },
        searchColumnTitle() {
            if (this.searchColumn && this.searchColumns) {
                return this.searchColumn === this.primaryKey
                    ? 'ID'
                    : this.searchColumns.find(o => o.columnName === this.searchColumn).title
            }
        },
        searchColumns() {
            return this.$store.getters['componentStructure/get'](this.componentId)
                .filter(o => {
                    return ['text', 'textarea', 'texteditor', 'file', 'number', 'currency'].indexOf(o.type) > -1
                })
        },
        searchTerm: {
            get() {
                return this.$store.state[this.viewId].searchTerm
            },
            set(newValue) {
                if (this.timeout) {
                    clearTimeout(this.timeout)
                }

                this.timeout = setTimeout(() => {
                    this.$store.commit(this.viewId + '/searchTerm', newValue)
                }, 500)

                // Only the search field for the main listing should modify the query string other it will generate
                // the "Your changes will be lost" error.
                if (!this.isNamespacedListing) {
                    const query = this.$route.query
                    delete query.page // Reset pagination when changing the page size
                    this.$router.push({
                        query: {
                            ...query,
                            searchTerm: newValue
                        }
                    })
                }
            }
        }
    },
    methods: {
        selectColumn(columnName) {
            this.$store.commit(this.viewId + '/searchColumn', columnName)

            const query = this.$route.query
            delete query.page // Reset pagination when changing the page size
            delete query.searchColumn // Reset pagination when changing the page size
            if (columnName === false) {
                // This approach, of setting it to null before setting the query again, was required because without
                // it Vue failed to remove query.searchColumn even though it's been unset above, and instead it
                // generated Vue's duplicate navigation error.
                this.$router.push({query: null})
                this.$nextTick(() => {
                    this.$router.push({query: {...query}})
                })

            } else if (
                // Only the search field for the main listing should modify the query string other it will generate
                // the "Your changes will be lost" error.
                !this.isNamespacedListing
            ) {
                this.$router.push({
                    query: {
                        ...query,
                        searchColumn: this.$store.state[this.viewId].searchColumn
                    }
                })
            }

        }
    }
}
</script>

<style scoped>
.search-field {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 2px;
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.search-field input {
    flex: auto;
    border: none;
}
</style>