<template>
    <div class="d-flex align-items-center">
        <div class="flex-fill">
            {{ componentTitle }}
            >
            <template v-if="itemTitle" class="title">{{ itemTitle }}</template>
            <em v-else>untitled</em>
            >
            {{ formControlLabel }}
        </div>

        <div>
            <button class="btn btn-light btn-sm" @click="goToItem">
                <i class="bi-pencil-square"/>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormControlFilesUsageItem",
    props: {
        item: Object
    },
    computed: {
        formControlLabel() {
            if (this.column) {
                return this.column.title
            }
        },
        component() {
            if (this.column) {
                return this.$store.state.components.items.find(o => o.id === this.column.modulecomponentsID)
            }
        },
        isPageComponent() {
            return this.component?.tableName.indexOf('component_') === 0
        },
        componentTitle() {
            if (this.componentItem) {
                return this.isPageComponent ? 'Content #' + this.componentItem.contentId : this.component?.title
            }
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.component.id)
        }
    },
    asyncComputed: {
        column() {
            return this.$store.dispatch('itemData/get', {
                    tableName: 'modulestructure',
                    id: this.item.structureId
                })
                .then((obj) => {
                    return obj
                })
        },
        componentItem() {
            if (this.component) {
                return this.$store.dispatch('itemData/get', {
                        tableName: this.component.tableName,
                        id: this.item.itemId
                    })
                    .then((obj) => {
                        return obj
                    })
            }
        },
        itemTitle() {
            if (this.componentItem) {
                // If this is a page component, we need to source the contentId from the component_x table, use it to load
                // the record from the content table and present the content's title.
                if (this.isPageComponent) {
                    return this.$store.dispatch('itemData/get', {
                            tableName: 'content',
                            id: this.componentItem.contentId
                        })
                        .then((obj) => {
                            return obj.title
                        })
                } else if (this.component) {
                    return this.componentItem[this.nameField]
                }
            }
        }
    },
    methods: {
        goToItem() {
            if (this.isPageComponent) {
                this.$router.push({
                    name: 'form',
                    params: {
                        component: 'content',
                        id: this.componentItem.contentId
                    }
                })
            } else if (this.component.tableName === 'templates_templates') {
                this.$router.push({
                    name: 'themesEditor',
                    query: {
                        section: 'templates_templates',
                        id: this.item.itemId
                    }
                })
            } else {
                this.$router.push({
                    name: 'form',
                    params: {
                        component: this.component.tableName,
                        id: this.item.itemId
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
em {
    padding: 0 3px
}
</style>