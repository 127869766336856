var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.rolesGroups, function (rolesGroup) {
      return _c("div", { key: rolesGroup.id, staticClass: "mb-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("label", { staticClass: "col-sm-3 form-label" }, [
            _vm._v(_vm._s(rolesGroup)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-9 control-wrapper" },
            [
              _c("FormControlUsersGroupsRolesGroup", {
                attrs: { "form-id": _vm.formId, "roles-group": rolesGroup },
              }),
            ],
            1
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }