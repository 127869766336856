<template>
    <button v-if="isSet" class="btn btn-link" type="button" @click="onClick">
        <i class="bi-x-lg"/>
    </button>
</template>

<script>
export default {
    name: "SearchFilterItemResetBtn",
    props: {
        filterId: Number,
        viewId: String,
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        isSet() {
            return this.filters.advancedFilter[this.filterId] !== undefined
        }
    },
    methods: {
        onClick() {
            this.$store.commit(this.viewId + '/setFilter', {
                id: this.filterId,
                value: undefined
            })
        }
    }
}
</script>

<style scoped>

</style>