<template>
    <button @click="moveDown" :disabled="isDisabled" class="btn btn-dark btn-sm">
        <i class="bi bi-chevron-down"></i>
    </button>
</template>

<script>
import {Offcanvas} from "bootstrap"

export default {
    name: "PageEditorContentMoveDownBtn",
    computed: {
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        content() {
            return this.$store.state.pageEditor.contentItems.find(o => o.pageContentId === this.pageContentId)
        },
        isDisabled() {
            return !this.pageContentId || this.isLast
        },
        isLast() {
            return false

            const pageContent = this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)
            const contentItems = this.$store.state.pageContentData.items.filter(o => o.pageId === pageContent.pageId && o.contentArea === pageContent.contentArea)
            const index = contentItems.map(o => o.id).indexOf(this.pageContentId)
            return index === contentItems.length - 1
        }
    },
    methods: {
        moveDown() {
            this.$store.dispatch('pageEditor/moveDown', {
                pageContentId: this.pageContentId
            })
        }
    }
}
</script>

<style scoped>
[disabled] {
    color: #999;
}
</style>