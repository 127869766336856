var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v("How may we help you?"),
        ]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("p", [
          _vm._v(
            "Please fill in details below, and we'll get back to you as soon as possible."
          ),
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("fieldset", { staticClass: "form-fieldset" }, [
              _c("div", { staticClass: "mb-4" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label visually-hidden",
                    attrs: { for: "" },
                  },
                  [_vm._v("Subject")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.subject,
                      expression: "subject",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Subject", required: "", type: "text" },
                  domProps: { value: _vm.subject },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.subject = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4" }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label visually-hidden",
                    attrs: { for: "" },
                  },
                  [_vm._v("Message")]
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message,
                      expression: "message",
                    },
                    {
                      name: "textarea-auto-grow",
                      rawName: "v-textarea-auto-grow",
                    },
                  ],
                  ref: "field",
                  staticClass: "form-control",
                  attrs: { placeholder: "Message", required: "" },
                  domProps: { value: _vm.message },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.message = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: { disabled: _vm.disabled, type: "submit" },
                  },
                  [_vm._v("Submit")]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }