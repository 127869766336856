var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentData
    ? _c(
        "form",
        {
          ref: "form",
          attrs: { autocomplete: "off", novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [
          _vm.formShow
            ? _c("FormFieldsets", {
                attrs: {
                  "form-id": _vm.formId,
                  "show-legends": _vm.showLegends,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("Dump", [
            _vm._v(
              _vm._s({
                isModified: _vm.$store.getters[_vm.formId + "/isModified"](),
                modifiedData:
                  _vm.$store.getters[_vm.formId + "/modifiedData"](),
                currentData: _vm.$store.state[_vm.formId].currentData,
                originalData: _vm.$store.state[_vm.formId].originalData,
              }) + "\n    "
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }