<template>
    <th :class="[typeClass, {'disabled': !isClickable}]" @click="onClick" role="button">
        <span>{{ title }}</span>
        <span :class="{ 'bi-chevron-up': sortedAsc, 'bi-chevron-down': sortedDesc }" class="text-danger"/>
    </th>
</template>

<script>
export default {
    name: "TableViewHeadCell",
    props: {
        viewId: String,
        columnId: Number,
        column: Object,
    },
    computed: {
        orderBy() {
            return this.$store.state[this.viewId].settings.orderBy
        },
        orderDirection() {
            return this.$store.state[this.viewId].settings.orderDirection
        },
        // Table cell type class names are used by CSS for flex box positioning.
        typeClass() {
            return this.type ? 'table-cell-type-' + this.type : ''
        },
        sortedAsc() {
            return (
                this.orderBy === this.columnName
                && this.orderDirection === 'Asc'
            )
        },
        sortedDesc() {
            return (
                this.orderBy === this.columnName
                && this.orderDirection === 'Desc'
            )
        },
        title() {
            if (this.column) {
                return this.column.title
            } else if (this.structure) {
                return this.structure.title
            }
        },
        columnName() {
            if (this.column) {
                return this.column.name
            } else if (this.structure) {
                return this.structure.columnName
            }
        },
        type() {
            // Table cell type class names are used by CSS for flex box positioning.
            if (this.structure) {
                return this.structure.type
            }
        },
        isClickable() {
            return this.type !== 'relationshipManyToMany'
        },
        isNamespacedListing() {
            return this.viewId.indexOf('.') > -1
        }
    },
    asyncComputed: {
        structure() {
            if (this.columnId) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'modulestructure',
                        id: this.columnId
                    })
                    .then((obj) => {
                        return obj
                    })
            }
        },
    },
    methods: {
        onClick() {
            // The Relationship-Many-to-Many column is not sortable.
            if (!this.isClickable) {
                return
            }

            let direction = (
                this.orderBy === this.columnName
                && this.orderDirection === 'Asc'
            )
                ? 'Desc'
                : 'Asc'

            this.$store.dispatch(this.viewId + '/setSettings', {
                orderBy: this.columnName,
                orderDirection: direction,
            })

            if (!this.isNamespacedListing) {
                const query = this.$route.query
                const prefix = direction === 'Desc' ? '-' : ''
                this.$router.push({
                    query: {
                        ...query,
                        sort: prefix + this.columnName
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.disabled {
    cursor: not-allowed !important;
}

/* Tone down the default colour so that it becomes brighter on hover */
[data-bs-theme="light"] th:not(:hover) {
    color: #666
}

[data-bs-theme="dark"] th:not(:hover) {
    color: #999
}
</style>