var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("input", {
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.isChecked },
        on: { change: _vm.setValue },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }