<template>
    <select :id="field.id" v-model="currentData[field.name]" :name="field.name" :required="field.required"
            class="form-select">
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "FormControlModulecomponentsNameField",
    props: {
        formId: String,
        field: Object,
        currentData: Object
    },
    computed: {
        componentIds() {
            return this.$store.state.components.componentIds
        },
        options() {
            if (this.currentData.id) {
                let componentId = this.componentIds[this.currentData.tableName]

                let objs = this.$store.getters['componentStructure/get'](componentId)
                    .filter(obj => obj.type === 'text')

                if (objs.length) {
                    objs = objs.sort((a, b) => (a.title > b.title) ? 1 : -1)

                    let options = [{
                        value: 0,
                        text: 'Select…'
                    }]
                    objs.forEach((obj) => {
                        options.push({
                            value: obj.id,
                            text: obj.title
                        })
                    })

                    return options
                } else {
                    this.noOptions()
                }

            } else {
                this.noOptions()
            }
        }
    },
    methods: {
        noOptions() {
            // todo - Update with form-text feature once implemented. It should state that no image columns
            //  exist.
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>