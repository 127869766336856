<template>
    <span>{{ value | numberFormat }}</span>
</template>

<script>
export default {
    name: "TableCellTypeNumber",
    props: {
        value: Number,
    },
    filters: {
        numberFormat(value) {
            return new Intl.NumberFormat().format(value)
        }
    }
}
</script>

<style scoped>
</style>