var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.readonly
    ? _c("FormControlPlainText", {
        attrs: { value: _vm.currentData[_vm.field.name] },
      })
    : _c(
        "div",
        { staticClass: "input-group" },
        [
          !_vm.other
            ? _c("FormControlTypeSelect", {
                attrs: {
                  "current-data": _vm.currentData,
                  field: _vm.field,
                  "form-id": _vm.formId,
                  options: _vm.options,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          [
            !_vm.other
              ? _c(
                  "button",
                  {
                    ref: "btn",
                    staticClass: "btn btn-outline-secondary btn-sm",
                    attrs: { type: "button" },
                    on: { click: _vm.showOther },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.otherOption) + "\n        "
                    ),
                  ]
                )
              : [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentData[_vm.field.name],
                        expression: "currentData[field.name]",
                      },
                    ],
                    ref: "input",
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.currentData[_vm.field.name] },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.hideOther.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.currentData,
                          _vm.field.name,
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-secondary",
                      attrs: { type: "button" },
                      on: { click: _vm.hideOther },
                    },
                    [_c("i", { staticClass: "bi-x-lg" })]
                  ),
                ],
          ],
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }