var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Add payment")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _vm.formId
            ? _c("Form", { attrs: { "form-id": _vm.formId } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "footer",
            { staticClass: "text-center mt-3" },
            [
              _c("FormSaveBtn", {
                attrs: {
                  "button-text": "Save",
                  "cancel-on-save": true,
                  "form-id": _vm.formId,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }