<template>
    <select v-model="formData.franchiseId" class="ms-2 form-select form-select-sm"
            name="franchiseId" required>
        <option v-if="allowAll" :value="0">- All -</option>
        <option v-else value="" selected disabled>Select&hellip;</option>
        <option v-for="franchise in franchises" v-if="franchises" :key="franchise.id"
                :value="franchise.id">
            {{ franchise.title }}
        </option>
    </select>
</template>

<script>
export default {
    name: "FranchisesFilter",
    props: {
        allowAll: Boolean
    },
    computed: {
        formData() {
            return this.$store.state.odpReports.formData
        },
        franchises() {
            return this.$store.state.odpReports.franchises
        },
        unsetValue() {
            return this.allowAll ? 0 : ''
        }
    },
    created() {
        const franchiseId = this.$route.query.franchiseId ? parseInt(this.$route.query.franchiseId) : this.unsetValue
        this.$set(this.formData, 'franchiseId', franchiseId)
    },
    destroyed() {
        this.$delete(this.formData, 'franchiseId');
    }
}
</script>

<style scoped>

</style>