<template>
    <div class="d-flex pe-2 border-bottom">
        <div class="flex-fill d-flex align-items-center">
            <PageEditorToolbarContentDropdown/>
            <PageEditorToolbarContentAreaBtn/>
            <PageEditorToolbarContentBtn/>
        </div>

        <div>
            <PageEditorToolbarShowBtn/>
        </div>
    </div>
</template>

<script>
import PageEditorToolbarContentDropdown from "./PageEditorToolbarContentDropdown.vue"
import PageEditorToolbarContentAreaBtn from "./PageEditorToolbarContentAreaBtn.vue"
import PageEditorToolbarContentBtn from "./PageEditorToolbarContentBtn.vue"
import PageEditorToolbarShowBtn from "./PageEditorToolbarShowBtn.vue"

export default {
    name: "PageEditorToolbar",
    components: {
        PageEditorToolbarShowBtn,
        PageEditorToolbarContentBtn, PageEditorToolbarContentAreaBtn, PageEditorToolbarContentDropdown
    }
}
</script>

<style scoped>

</style>