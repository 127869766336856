<template>
    <FormControlTypeSelect
        v-if="options"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
        :options="options"
    />
</template>

<script>
import FormControlTypeSelect from '../form-control-type/FormControlTypeSelect'

export default {
    name: "FormControlModulestructureTableTargetColumn",
    components: {
        FormControlTypeSelect,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        options() {
            let items = this.$store.state.componentStructure.items
            items = items.filter((obj) => {
                return (
                    obj.categoryComponentId === this.currentData.modulecomponentsID
                    && obj.type === 'relationshipOneToMany'
                )
            })

            let options = []
            items.forEach((item) => {
                let component = this.$store.state.components.items.filter((obj) => {
                    return obj.id === item.modulecomponentsID
                })[0]

                options.push({
                    value: item.id,
                    text: component.title + ' > ' + item.title
                })
            })
            return options
        }
    },
    created() {
        // Hide the field from all other field types
        if (this.currentData.type !== 'table') {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>