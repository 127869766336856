<template>
    <div ref="dropzone" class="dropzone">
        <div class="d-flex align-items-start">
            <div v-if="thumbnailSrc" class="me-3">
                <a href @click.prevent="showLightbox">
                    <img :alt="fileData.fileName" :class="{ 'form-field-image-img-progress': progress }"
                         :src="thumbnailSrc">
                </a>
            </div>

            <div class="media-body">
                <div class="">
                    <FormControlPlainText v-if="fileData.fileName" :value="fileData.fileName"/>

                    <!-- This must be placed here, outside of v-if="showUploadOptions" so that its @change works.
                         Otherwise the upload fails if the Upload button isn't immediately available -->
                    <input ref="fileInput" :accept="inputAccept" class="d-none" type="file" @change="onChangeFileInput">

                    <div v-if="showUploadOptions">
                        <span v-if="tableName === filesTableName">Drag file here or</span>
                        <span v-else>Drag file here,</span>

                        <span v-if="tableName !== filesTableName">
                            <button class="btn btn-light btn-sm" type="button" @click="openImageBrowser">
                                Browse
                            </button>

                            <span>or</span>
                        </span>

                        <button class="btn btn-light btn-sm wizard-form-field-file-choose-file" type="button"
                                @click="chooseFile">
                            Upload
                        </button>
                    </div>

                    <div v-if="progress" class="progress mt-2 mb-1" style="height: 5px;">
                        <div :style="{ 'width': progress + '%' }" class="progress-bar" role="progressbar"/>
                    </div>

                    <div v-if="fileData.id && !readonly" class="d-flex align-items-center mt-3">
                        <button v-if="tableName !== filesTableName" class="btn btn-light btn-sm" type="button"
                                @click="openImageBrowser">
                            Browse
                        </button>

                        <template v-if="removeOption">
                            <span class="ms-2">or</span>

                            <button class="btn btn-light btn-sm ms-2" type="button" @click="removeFile">
                                Remove
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormControlPlainText from "./form-control/FormControlPlainText.vue"

export default {
    name: "ImageSelection",
    components: {FormControlPlainText},
    props: {
        fileId: Number,
        // This component was originally set up for FormControlTypeImage.
        // This indicates it's being used for FormControlTypeFile
        fileBrowser: Boolean,
        onChange: Function,
        tableName: String,
        columnName: String,
        readonly: Boolean,
        rowId: Number,
        removeOption: Boolean,
    },
    data() {
        return {
            fileInput: undefined,
            progress: 0,
        }
    },
    mounted() {
        this.fileInput = this.$refs.fileInput

        this.applyDropzoneEvents()
    },
    computed: {
        thumbnailSrc() {
            let obj = this.fileData
            if (
                obj
                && obj.fileName
                && this.isImage
            ) {
                return this.$store.getters.imageWidthSrc(obj.directory, obj.fileName, obj.width, obj.modifiedDate, 200)
            }
        },
        filesTableName() {
            return 'files'
        },
        isImage() {
            return (
                this.fileData?.mimetype.indexOf('image') === 0 // e.g. image/jpeg image/gif
                && this.fileData?.mimetype !== 'image/svg+xml'
            )
        },
        inputAccept() {
            return this.fileBrowser ? '' : 'image/*'
        },
        showUploadOptions() {
            return (
                (
                    !this.fileData.id
                    // If this is the Files component, the upload options should
                    // always be presented to allow users to replace existing files.
                    || this.tableName === this.filesTableName
                )
                && !this.progress
                && !this.readonly
            )
        }
    },
    asyncComputed: {
        fileData: {
            default: {},
            get() {
                if (this.fileId) {
                    return this.$store.dispatch('itemData/get', {
                            tableName: 'files',
                            id: this.fileId
                        })
                        .then((obj) => {
                            return obj
                        })
                }

                return {}
            }
        }
    },
    methods: {
        applyDropzoneEvents() {
            if (!this.onChange) {
                return
            }

            let dropzone = this.$refs.dropzone

            dropzone.ondragenter = () => {
                dropzone.classList.add('active')
            }
            dropzone.ondragleave = () => {
                dropzone.classList.remove('active')
            }
            dropzone.ondragover = (e) => {
                e.preventDefault()
                dropzone.classList.add('active')
            }
            dropzone.ondrop = (e) => {
                e.preventDefault()

                let data = e.dataTransfer || e.originalEvent.dataTransfer
                let file = data.files[0]

                if (this.validateFileType(file.type)) {
                    this.uploadFile(file)
                    dropzone.classList.remove('active')
                }
            }
        },
        validateFileType(type) {
            if (this.fileBrowser) {
                return true
            }

            // Images only

            let parts = type.split('/')

            if (
                parts[0] !== 'image'
                || parts[1] === 'svg+xml'
            ) {
                let msg = parts[1] === 'svg+xml'
                    ? 'SVGs cannot be uploaded to the Images component.'
                    : 'The file must be an image.'

                this.$store.dispatch('toasts/add', {
                    body: msg
                })

                return false
            }

            return true
        },
        openImageBrowser() {
            let id = 0 // todo - Needs to be set to the image's ID.

            // If targeting the files component filter it to only
            // show images.
            // todo - The condition can be removed and the object merged below once all sites using Files.
            let hiddenFilters = {}
            if (
                this.filesTableName === 'files'
                // If being used for files ALL content should be listed,
                && !this.fileBrowser
            ) {
                hiddenFilters = {
                    mimetype: {
                        startsWith: 'image/',
                        ne: 'image/svg+xml'
                    }
                }
            }

            let modal = this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    listingName: this.filesTableName,
                    selectedIds: id ? [id] : [],
                    hiddenFilters: hiddenFilters,
                    onSelect: (selectedIds) => {
                        let id = selectedIds[0]

                        this.$store.dispatch('itemData/get', {
                                tableName: this.filesTableName,
                                id: id
                            })
                            .then((obj) => {
                                this.applyChange(obj)

                                modal.then((obj) => {
                                    this.$store.dispatch('modals/remove', obj.index)
                                })
                            })
                    }
                }
            })
        },
        uploadFormData(formData) {
            // Immediately trigger the progress UI changes.
            this.progress = 1

            let xhr = new XMLHttpRequest()
            xhr.open('POST', this.$store.state.apiEndpoint + '/api/FilesController/upload')
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'))
            xhr.onload = () => {
                //progress.value = progress.innerHTML = 100;
            }
            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    this.progress = (e.loaded / e.total * 100 | 0)
                }
            }
            xhr.onreadystatechange = () => {
                let obj

                if (xhr.readyState === 4) {
                    try {
                        obj = JSON.parse(xhr.responseText)
                    } catch (err) {
                        console.log(xhr.responseText)
                        console.log(err.message)
                    }

                    switch (xhr.status) {
                        case 200:
                            if (
                                obj
                                && obj.id
                            ) {
                                this.fileData.id = obj.id
                                this.fileData.fileName = obj.fileName

                                // If adding a new file record to the Files component, load the new records URL.
                                // This routes the user from /files/0 -> files/<fileId>. This is required because this
                                // form has no save buttons and the file is uploaded immediately. It has the added
                                // benefit of loading the thumbnail.
                                if (
                                    this.tableName === this.filesTableName
                                    && parseInt(this.$route.params.id) === 0
                                ) {
                                    this.$router.push({params: {id: obj.id}})
                                }

                                this.applyChange(obj)
                            }

                            this.progress = 0
                            break

                        // Thrown when a duplicate file name is uploaded.
                        case 409:
                            this.$store.dispatch('toasts/add', {
                                body: obj.message
                            })

                            // If uploading a replacement file to the Files component generates an error we need to
                            // reset back to the form's initial state, rather than clearing it of all data.
                            if (this.tableName !== this.filesTableName) {
                                this.fileInput.value = ''
                                this.fileData = {}
                            }

                            this.progress = 0
                            break
                    }
                }
            }
            xhr.send(formData)
        },
        applyChange(fileObj) {
            if (this.onChange) {
                this.onChange(fileObj)
            }
        },
        uploadFile(file) {
            // Present the file's name as soon as the upload starts
            this.fileData.fileName = file.name
                .normalize("NFD") // "è" becomes  "e`̀".
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-zA-Z 0-9\-.]+/g, '')
                .toLowerCase()
                .replace(/ +/g, '-')

            let formData = new FormData()
            formData.append('file', file)

            if (
                this.rowId
                && this.tableName === this.filesTableName
            ) {
                formData.append('existingFileId', this.rowId)
            }

            /*
            for (var pair of formData.entries()) {
                console.log(pair[0], pair[1])
            }
            //*/

            this.uploadFormData(formData)
        },
        onChangeFileInput() {
            if (this.fileInput.value) {
                let file = this.fileInput.files[0]
                if (this.validateFileType(file.type)) {
                    this.uploadFile(file)
                }
            }
        },
        chooseFile() {
            this.fileInput.click()
        },
        removeFile() {
            this.fileInput.value = ''
            this.progress = 0
            this.fileData = {}

            // Unset the form's data so the change can be saved
            this.applyChange({
                id: 0
            })
        },
        showLightbox() {
            let src = this.$store.getters.fileSrc(this.fileData.directory, this.fileData.fileName)

            this.$store.commit('lightbox/reset')
            this.$store.commit('lightbox/add', {
                id: 0,
                title: '',
                src: src
            })
            this.$store.dispatch('lightbox/show', 0)
        }
    }
}
</script>

<style scoped>
.dropzone {
    position: relative;
    margin: -10px 0 0 -10px;
    padding: 10px;
    border-radius: 5px;
}

.dropzone.active {
    background-color: #e3e3e3;
}
</style>