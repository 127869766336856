<template>
    <section v-if="contentTypeTitle" class="d-flex flex-column h-100">
        <header>
            <h2 class="h5"><small>{{ contentTypeTitle }}</small></h2>
        </header>

        <div class="flex-fill">
            <PageEditorContentPanelForm v-if="tableNameId" :table-name-id="tableNameId" :key="tableNameId"/>
        </div>

        <footer class="pb-3 text-center">
            <div class="mb-3">
                <PageEditorContentMoveUpBtn/>
                <PageEditorContentMoveDownBtn/>
            </div>

            <PageEditorContentRemoveBtn/>
        </footer>
    </section>
</template>

<script>
import PageEditorContentRemoveBtn from "./PageEditorContentRemoveBtn.vue"
import PageEditorContentMoveDownBtn from "./PageEditorContentMoveDownBtn.vue"
import PageEditorContentMoveUpBtn from "./PageEditorContentMoveUpBtn.vue"
import FormControl from "../../common/form/FormControl.vue"
import PageEditorContentPanelForm from "./PageEditorContentPanelForm.vue"

export default {
    name: "PageEditorContentPanel",
    components: {
        PageEditorContentPanelForm,
        FormControl, PageEditorContentMoveUpBtn, PageEditorContentMoveDownBtn, PageEditorContentRemoveBtn},
    computed: {
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        pageContent() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)
        },
        contentId() {
            return this.pageContent?.contentId
        },
        contentTypeId() {
            return this.content?.contentType
        },
        contentTypeTitle() {
            return this.$store.getters['templates/contentTypes'].find(o => o.id === this.contentTypeId)?.title
        }
    },
    asyncComputed: {
        content() {
            if (this.contentId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
            }
        },
        tableNameId() {
            if (this.contentTypeId) {
                const tableName = 'component_' + this.contentTypeId

                return this.$store.dispatch('request/get', {
                        url: 'api/component/' + tableName,
                        params: {
                            contentId: this.contentId
                        }
                    })
                    .then(o => {
                        const data = o.data.items[0]

                        const id = data.id

                        return tableName + '.' + id
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>