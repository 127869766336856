<template>
    <div ref="overlay" v-show="show">
        <a @click.prevent="edit" role="button" href
           class="d-block w-100 h-100 d-flex align-items-center justify-content-center h1 text-body-tertiary text-decoration-none">
        </a>
    </div>
</template>

<script>
export default {
    name: "PageEditorContentAreaOverlay",
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        contentAreaName() {
            return this.pageEditor.hoverContentAreaName || this.pageEditor.selectedContentAreaName || this.pageEditor.highlightContentAreaName
        },
        activeContentArea() {
            return this.$store.state.pageEditor.contentAreas.find(o => o.name === this.contentAreaName)
        },
        show() {
            return !!this.contentAreaName
        },
        isEmpty() {
            return this.pageEditor.contentItems.filter(o => o.contentAreaName === this.contentAreaName).length === 0
        },
        showEmpty() {
            return this.isEmpty && this.pageEditor.showEmptyContentAreas
        }
    },
    mounted() {
        this.activeContentArea.iframe.contentDocument.body.append(this.$refs.overlay)
    },
    methods: {
        edit() {
            const contentAreaName = this.contentAreaName

            this.$router.push({
                name: 'siteTree',
                params: {
                    pageOrContentId: this.$store.state.pageEditor.pageId,
                },
                query: {
                    area: contentAreaName
                }
            })

            this.pageEditor.toolbarDropdown.click() // Hide the menu

            return
            this.$store.dispatch('pageEditor/deselectAll')
            this.$store.commit('pageEditor/selectedContentAreaName', contentAreaName)
        }
    }
}
</script>

<style scoped>

</style>