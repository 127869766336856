var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        { name: "disable-shift-select", rawName: "v-disable-shift-select" },
      ],
      staticClass: "container-fluid grid-view",
    },
    [
      _c("div", [
        _vm.itemCount === 0
          ? _c(
              "div",
              [
                _c("ListingNoContent", { attrs: { "view-id": _vm.viewId } }),
                _vm._v(" "),
                _c("ListingNoContentOptions", {
                  attrs: { "view-id": _vm.viewId },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.itemCount > 0
          ? _c("div", [
              _c(
                "div",
                { staticClass: "row clearfix" },
                _vm._l(_vm.ids, function (id) {
                  return _c(
                    "div",
                    {
                      key: id,
                      staticClass: "col-xs-6 col-sm-3 col-md-2 col-lg-2",
                    },
                    [
                      _c("GridViewThumbnail", {
                        attrs: { id: id, "view-id": _vm.viewId },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }