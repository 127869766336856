<template>
    <Button v-if="componentId" class="btn-sm" @click.native="openFieldsModal">
        Fields
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "ThemesEditorContentTypeFieldsBtn",
    components: {Button},
    props: {
        id: Number,
    },
    computed: {
        componentId() {
            let tableName = 'component_' + this.id
            return this.$store.state.components.componentIds[tableName] || 0
        }
    },
    methods: {
        openFieldsModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'ContentTypeFieldsModal',
                obj: {
                    componentId: this.componentId
                }
            })
        }
    }
}
</script>

<style scoped>

</style>