export default {
    namespaced: true,
    state: {},
    mutations: {},
    getters: {
        items: () => {
            let cache = localStorage.getItem('stateHistory')
            return cache ? JSON.parse(cache) : []
        }
    },
    actions: {
        set({rootState, dispatch, rootGetters}, {stateName, tableName, id, fullPath}) {
            
            let data = {
                fullPath: fullPath
            }
            
            if (
                stateName === 'form'
                //|| stateName === 'list'
            ) {
                // Skip the following tables.
                let tableNames = [
                    'modules_filters'
                ]
                if (tableNames.indexOf(tableName) !== -1) {
                    return false
                }
                
                //if (stateName === 'list') {
                // I don't believe the following will work as the params are part of AngularJS's hash.
                //data.searchParams = (new URL(document.location)).searchParams;
                //}
                
                // The path is used for the key so that it doesn't contain pagination parameters. This way
                // if the user pages through a listing it won't store a history item for each page, it'll
                // only store the last used page.
                data.key = document.location.pathname
                
                let component = rootState.components.items.find(o => o.tableName === tableName)
                
                // todo - On refreshing the browser components.items was not set, which generated a JS error.
                if (!component) {
                    return
                }
                
                data.componentTitle = component.title
                
                if (id) {
                    return dispatch('itemData/get', {
                        tableName: tableName,
                        id: id,
                    }, {root: true})
                        .then((obj) => {
                            if (obj) {
                                const nameField = rootGetters['components/nameField'](component.id)
                                
                                let title = obj[nameField]
                                if (title) {
                                    data.itemTitle = title
                                }
    
                                dispatch('setData', data)
                                
                            } else {
                                // We only want to store the history state for listings if query parameters, such
                                // as pagination, are in use. We don't want to fill up the history panel with links
                                // to the root of components.
                                let href = document.location.href
                                let path = document.location.pathname
                                if (href !== path) {
                                    dispatch('setData', data)
                                }
                            }
                        })
                }
            }
        },
        setData({}, data) {
            
            let cache = localStorage.getItem('stateHistory')
            let array = cache ? JSON.parse(cache) : []
            
            // Remove duplicate paths
            array = array.filter((obj) => {
                return obj.fullPath !== data.fullPath
            })
            
            // We want the most recent activity first
            array.unshift(data)
            
            // Reduce the history down to only 10 items
            while (array.length > 10) {
                array.pop()
            }
            
            //console.log('count: ' + array.length, array);
            localStorage.setItem('stateHistory', JSON.stringify(array))
        }
    },
}