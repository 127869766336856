export default {
    namespaced: true,
    state: {
        data: {},
    },
    mutations: {
        set(state, {tableName, id, displayOrder}) {
            if (!state.data[tableName]) {
                this._vm.$set(state.data, tableName, {})
            }
            
            this._vm.$set(state.data[tableName], id, displayOrder)
        },
    },
    actions: {},
}