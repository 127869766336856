<template>
    <span v-if="userData" ref="tooltip" :class="{ 'user-avatar-with-text': initials, 'user-avatar-sm': isSmall }"
          :data-bs-placement="tooltipPlacement" :title="userData.displayName" class="user-avatar"
          data-bs-toggle="tooltip">
        <img v-if="imgSrc" :src="imgSrc" alt=""/>
        <span v-if="initials">{{ initials }}</span>
    </span>
</template>

<script>
import {Tooltip} from 'bootstrap'

export default {
    name: "UserAvatar",
    props: {
        userId: Number,
        tooltipPlacement: String,
        isSmall: Boolean
    },
    asyncComputed: {
        userData() {
            return this.$store.dispatch('itemData/get', {
                    tableName: 'users',
                    id: this.userId
                })
                .then((obj) => {
                    return obj
                })
        },
    },
    computed: {
        imgSrc() {
            if (this.userData.gravatarImageSrc) {
                return this.userData.gravatarImageSrc + '?s=32'
            }
        },
        initials() {
            return this.userData.firstName?.charAt(0) + this.userData.lastName?.charAt(0)
        }
    },
    watch: {
        userData() {
            // Only present a tooltip if its placement has been set.
            // This ensures it only appears if explicitly set and avoids it appearing in odd locations, such as above
            // the avatar in the main nav.
            if (this.tooltipPlacement) {
                this.$nextTick(() => {
                    if (this.$refs.tooltip) {
                        new Tooltip(this.$refs.tooltip)
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.user-avatar {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 100%;
    vertical-align: middle;
    overflow: hidden;
}

.user-avatar-with-text {
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    color: #3373f3;
    background: #ccc;
    font-weight: 500;
}

.user-avatar > img {
    width: 100%;
    height: 100%;
}

.user-avatar-sm {
    margin-top: 3px;
    width: 21px;
    height: 21px;
}

.user-avatar-sm.user-avatar-with-text {
    padding-left: 1px;
    line-height: 22px;
    font-size: 10px;
    font-weight: 600;
}
</style>