export default {
    namespaced: true,
    state: {
        items: []
    },
    actions: {
        notification({dispatch, rootState}, {title, body}) {
            let notificationId
            
            // Initiate a new notification.
            return dispatch('request/post', {
                url: 'api/notification',
                postData: {
                    title: title,
                    data: {
                        message: body,
                        percentage: 0,
                    },
                }
            }, {root: true})
                .then((obj) => {
                    notificationId = obj.data.id
                    
                    // Present the notification in a toast.
                    return dispatch('toasts/add', {
                        title: title,
                        body: body,
                        disableAutoHide: true,
                        percentage: 0,
                        notificationId: notificationId,
                        createdBy: rootState.user.user.id
                    }, {root: true})
                })
                .then(() => {
                    return {notificationId}
                })
        },
        add({state, dispatch}, {title, body, disableAutoHide, percentage, notificationId, colourScheme, createdBy}) {
            const item = {
                title: title,
                body: body,
                disableAutoHide: disableAutoHide,
                percentage: percentage,
                colourScheme: colourScheme,
                createdBy: createdBy,
            }
            
            state.items.push(item)
            
            if (notificationId) {
                dispatch('startInterval', {notificationId, item})
            }
        },
        startInterval({state, dispatch}, {notificationId, item}) {
            let promise = setInterval(() => {
                dispatch('request/get', {
                    url: 'api/notification/' + notificationId,
                }, {root: true})
                    .then((obj) => {
                        const data = obj.data.item.data
                        
                        if (data.percentage) {
                            item.percentage = data.percentage
                        }
                        
                        // A toast should only be created with a user. Adding it here shouldn't ever be necessary.
                        // It also creates an off affect if it's not initially set and then subsequently appears.
                        //if (obj.data.item.createdBy) {
                        //    item.createdBy = obj.data.item.createdBy
                        //}
                        
                        if (data.message) {
                            item.body = data.message
                        }
                        
                        if (data.downloadUrl) {
                            item.downloadUrl = data.downloadUrl
                        }
                        
                        if (data.percentage === 100) {
                            clearInterval(promise)
                        }
                    })
            }, 1000)
        }
    }
}