<template>
    <span class="ms-2 d-flex align-items-center">
        <!--<input required v-model="formData.startDate" class="form-control form-control-inline"
               name="start_date" placeholder="dd/mm/yyyy" size="12"
               oninvalid="this.setCustomValidity('Please enter a start date')" oninput="this.setCustomValidity('')">-->
        <input type="date" required v-model="formData.startDate" class="form-control form-control-sm"
               oninvalid="this.setCustomValidity('Please enter a start date')" oninput="this.setCustomValidity('')">
        <span class="ms-2 text-muted">to</span>
        <!--<input required v-model="formData.endDate" class="form-control form-control-inline" name="end_date"
               placeholder="dd/mm/yyyy" size="12"
               oninvalid="this.setCustomValidity('Please enter an end date')" oninput="this.setCustomValidity('')">-->
        <input type="date" required v-model="formData.endDate" class="ms-2 form-control form-control-sm"
               oninvalid="this.setCustomValidity('Please enter an end date')" oninput="this.setCustomValidity('')">
    </span>
</template>

<script>
export default {
    name: "DateRangeFilter",
    computed: {
        formData() {
            return this.$store.state.odpReports.formData
        }
    },
    created() {
        const startDate = this.$route.query.startDate || ''
        this.$set(this.formData, 'startDate', startDate)

        const endDate = this.$route.query.endDate || ''
        this.$set(this.formData, 'endDate', endDate)
    },
    destroyed() {
        this.$delete(this.formData, 'startDate');
        this.$delete(this.formData, 'endDate');
    }
}
</script>

<style scoped>

</style>