var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.count
    ? _c(
        "div",
        { staticClass: "dropdown" },
        [
          _c(
            "Button",
            {
              staticClass: "btn-sm",
              attrs: { title: "Usage count", "data-bs-toggle": "dropdown" },
            },
            [_vm._v("\n        " + _vm._s(_vm.count) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-menu dropdown-menu-end p-1",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c(
                "Button",
                {
                  staticClass: "btn-sm w-100 text-start",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.unlinkContent.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            Unlink content\n        ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }