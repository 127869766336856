<template>
    <div class="d-flex align-items-center">
        <small v-if="oldDate" class="border rounded py-1 px-2" v-html="oldDate"></small>
        <i v-else class="small">empty</i>

        <i class="mx-2 bi-caret-right-fill"/>

        <small v-if="newDate" class="border rounded py-1 px-2" v-html="newDate"></small>
        <i v-else class="small">empty</i>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "ItemHistoryChangeTypeDate",
    props: {
        oldValue: String,
        newValue: String,
        dateFormat: String
    },
    computed: {
        oldDate() {
            return this.oldValue ? moment(this.oldValue).format('DD MMM YYYY, HH:mm') : '<i>empty</i>'
        },
        newDate() {
            return this.newValue ? moment(this.newValue).format('DD MMM YYYY, HH:mm') : '<i>unset</i>'
        }
    }
}
</script>

<style scoped>

</style>