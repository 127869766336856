var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "themesEditorTabs", staticClass: "d-flex align-items-center py-1" },
    _vm._l(_vm.editors, function (editor, index) {
      return _c("ThemesEditorTab", {
        key: index,
        attrs: { editor: editor, index: index },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }