var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("PageContentMobile", {
    attrs: { "page-id": _vm.pageId, "hide-modal": () => {} },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }