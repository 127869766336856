<template>
    <span v-if="value" class="table-cell-hidden-icon-parent">
        <small><code v-if="value">{{ value }}</code></small>
        <a v-if="!item.regex && value" class="bi-box-arrow-up-right table-cell-hidden-icon" href @click.prevent="openLink"></a>
    </span>
</template>

<script>
export default {
    name: "TableCellRedirectsSourceUrl",
    props: {
        item: Object,
        value: String
    },
    methods: {
        openLink() {
            // "If url is an absolute URL, a given base will be ignored."
            // - Which means it's okay to provide the websiteEndpoint for absolute URLs as it will be ignored.
            window.open(new URL(this.value, this.$store.state.websiteEndpoint))
        }
    }
}
</script>

<style scoped>

</style>