<template>
    <Button class="visible-on-hover-hidden px-1 btn-sm p-0" @click.stop.prevent.native="openEditModal">
        <i class="bi-pencil-square"/>
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "ThemesEditorEditBtn",
    components: {Button},
    props: {
        item: Object,
    },
    methods: {
        openEditModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'ThemesEditorAddModal',
                obj: {
                    id: this.item.id,
                }
            })
        }
    }
}
</script>

<style scoped>
a {
    color: rgba(0,0,0,.6);
}

a:hover {
    color: #000;
}

[data-bs-theme="dark"] a,
.btn-primary a {
    color: rgba(255,255,255,.8);
}

[data-bs-theme="dark"] a:hover,
.btn-primary a:hover {
    color: #fff;
}
</style>