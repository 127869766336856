<template>
    <FormControlTypeSelectOther v-if="options.length" :current-data="currentData" :field="field" :form-id="formId"
                                :options="options"/>
</template>

<script>
import FormControlTypeSelectOther from '../form-control-type/FormControlTypeSelectOther'

export default {
    name: "FormControlPagesNavigation",
    components: {
        FormControlTypeSelectOther,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        parent() {
            return this.currentData.parent
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        navigationGroups() {
            return this.$store.state.siteTree.navigationGroups
        },
        options() {
            if (this.navigationGroups) {
                let options = []

                this.navigationGroups.forEach((str) => {
                    options.push({
                        value: str,
                        text: str
                    })
                })

                return options
            }
        }
    },
    watch: {
        parent() {
            this.toggleField()
        }
    },
    created() {
        this.toggleField()

        this.$store.dispatch('siteTree/setNavigationGroups')
    },
    methods: {
        toggleField() {
            this.field.visibility = this.parent === 0
                ? ''
                : 'hidden'

            if (this.parent > 0) {
                // Set it with the parent's navigation.
                this.$store.dispatch('itemData/get', {
                        tableName: 'pages',
                        id: this.parent
                    })
                    .then((obj) => {
                        this.currentData[this.field.name] = obj.navigation
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>