export default {
    created() {
        //this.hello()
    },
    methods: {
        getSearchParams() {
            let params = {}
            // With AngularJS the query params are after a hash so they cannot be retrieved using the following.
            //let params = (new URL(document.location)).searchParams;
            // We must instead glean them from the hash.
            let search = document.location.hash.split('?')[1]
            if (search) {
                // https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
                params = JSON.parse(
                    '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
                )
            }
            return params
        }
    }
}