<template>
    <div class="site-tree">
        <div v-for="(pageIds, navigationGroup) in groupedPageIds" v-show="navigationGroup === selectedNavigationGroup"
             :key="navigationGroup">
            <SiteTreePageItem v-for="pageId in pageIds" :key="pageId" :page-id="pageId" :select-content="selectContent"
                              :select-page="selectPage" :selected-page-ids="selectedPageIds" :site-tree-id="siteTreeId"
                              :show-content="showContent"/>
        </div>
    </div>
</template>

<script>
import SiteTreePageItem from './SiteTreePageItem'

export default {
    name: "SiteTree",
    components: {
        SiteTreePageItem,
    },
    props: {
        siteTreeId: String,
        selectedPageIds: Array,
        selectPage: Function,
        selectContent: Function,
        showContent: Boolean,
    },
    computed: {
        groupedPageIds() {
            return this.$store.state[this.siteTreeId]?.groupedPageIds
        },
        selectedNavigationGroup() {
            return this.$store.state[this.siteTreeId]?.selectedNavigationGroup
        },
    },
    beforeCreate() {
        this.$store.dispatch('templates/init')
    }
}
</script>

<style scoped>
.site-tree {
    font-size: 85%;
}
</style>