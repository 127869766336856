<template>
    <div>
        <Form :id="id" :form-id="formId" :show-legends="false"/>

        <footer class="text-center mt-3 mb-3">
            <FormSaveBtn :button-text="'Save'" :cancel-on-save="true" :form-id="formId" :default-btn-class="'btn-secondary'"/>
        </footer>
    </div>
</template>

<script>
import Form from '../../common/form/Form'
import FormSaveBtn from '../../common/form/FormSaveBtn'

export default {
    name: "ComponentSettings",
    components: {
        Form,
        FormSaveBtn
    },
    props: {},
    data() {
        return {
            tableName: 'modulecomponents',
        }
    },
    computed: {
        component() {
            return this.$store.state.components.items.find(o => o.tableName === this.$route.params.component)
        },
        id() {
            return this.component.id
        },
        formId() {
            return 'form:' + this.tableName + ':' + this.id
        },
        isEditable() {
            return this.component.isEditable
        },
        hasFieldsets() {
            return this.$store.state.fieldsets.items.length
        },
        user() {
            return this.$store.state.user.user
        },
    },
    created() {
        this.initForm()
    },
    watch: {
        id() {
            // On duplicating a form, the URL updates from the old form to the new so this watch is required to
            // initialise the new form and prevent a bunch of errors.
            this.initForm()
        }
    },
    methods: {
        initForm() {
            const fieldNames = this.user.administrator && this.component.navigationGroup !== 'Forms'
                ? [
                    'title',
                    'addNew',
                    //'displayName',
                    'updateOption',
                    'deleteOption',
                    //'listing',
                    //'roles',
                    //'rolesGroup',
                    'showStatus',
                    'showMetadata',
                    'showScheduling',
                    'showDisplayOrder',
                    //'tableName',
                    //'nameField',
                    //'thumbnailColumn',
                    'useArchive'
                ]
                : ['title']

            this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.id,
                fieldNames: fieldNames
            })
        }
    }
}
</script>

<style scoped>
legend {
    border: none;
}

.list-group-item {
    border-color: #ebccd1;
}
</style>