<template>
    <a
        v-if="tooltip"
        ref="popover"
        :title="title"
        :data-bs-content="tooltip"
        data-bs-container="body"
        data-bs-html="true"
        data-bs-placement="right"
        data-bs-toggle="popover"
        :data-bs-trigger="trigger || 'click'"
    >
        <i class="bi-info-circle-fill" style="color: #aaa"/>
    </a>
</template>

<script>
export default {
    name: "Tooltip",
    props: {
        tooltip: String,
        title: String,
        show: Number,
        hide: Number,
        trigger: String,
    },
    mounted() {
        $(this.$refs.popover).popover({
            delay: {
                // Don't show immediately, so that if the cursor unintentionally moves
                // across the icon the popover doesn't appear.
                show: this.show || 0,
                hide: this.hide || 0
            }
        })
    }

}
</script>

<style scoped>
a {
    font-size: 13px;
    color: #bbb;
}

a:hover {
    color: #999;
}
</style>