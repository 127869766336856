var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.variationData
    ? _c(
        "dl",
        { staticClass: "dl-horizontal" },
        [
          _vm._l(_vm.variationData, function (o) {
            return [
              _c("dt", [_vm._v(_vm._s(o.option))]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(o.variable) + " " + _vm._s(o.imperial))]),
            ]
          }),
        ],
        2
      )
    : typeof _vm.description === "string"
    ? _c("div")
    : _vm.description
    ? _c(
        "dl",
        { staticClass: "dl-horizontal" },
        [
          _vm._l(_vm.description, function (variableName, optionName) {
            return [
              _c("dt", [_vm._v(_vm._s(optionName))]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(variableName))]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }