var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.franchiseId
    ? _c("FormControlPlainText", { attrs: { value: _vm.franchiseTitle } })
    : _c(
        "div",
        [
          _c("FormControlTypeRelationshipOneToMany", {
            attrs: {
              "current-data": _vm.currentData,
              field: _vm.field,
              "form-id": _vm.formId,
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }