var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tooltip
    ? _c(
        "a",
        {
          ref: "popover",
          attrs: {
            title: _vm.title,
            "data-bs-content": _vm.tooltip,
            "data-bs-container": "body",
            "data-bs-html": "true",
            "data-bs-placement": "right",
            "data-bs-toggle": "popover",
            "data-bs-trigger": _vm.trigger || "click",
          },
        },
        [
          _c("i", {
            staticClass: "bi-info-circle-fill",
            staticStyle: { color: "#aaa" },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }