<template>
    <div class="modal-dialog modal-fullscreen text-light">
        <div class="modal-content bg-transparent">
            <div class="modal-header">
                <button aria-label="Close" class="btn-close btn-close-white fs-4 opacity-100" type="button"
                        @click="$emit('hide-modal')"/>
            </div>

            <div class="modal-body d-flex align-items-center text-center" @click.self="$emit('hide-modal')">
                <button :class="{'invisible': itemNumber <= 1}" class="btn btn-lg btn-link fs-1 text-light"
                        type="button">
                    <i class="bi-chevron-left" @click="showPrevious"/>
                </button>

                <div class="flex-fill">
                    <img :alt="item.title" :src="item.src" class="shadow">
                </div>

                <button :class="{'invisible': itemNumber >= itemCount}" class="btn btn-lg btn-link fs-1 text-light"
                        type="button">
                    <i class="bi-chevron-right" @click="showNext"/>
                </button>
            </div>

            <div class="d-flex align-items-center px-3 fs-6 text-light">
                <p class="flex-fill">
                    {{ item.title }}
                </p>

                <p class="count">
                    {{ itemNumber }} of {{ itemCount }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LightboxModal",
    props: {
        options: Object,
    },
    data() {
        return {
            items: this.options.items,
            id: this.options.id,
            itemCount: 0,
            itemNumber: undefined,
            item: undefined,
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.itemCount = this.items.length

            this.itemNumber = 1
            this.item = false
            this.items.forEach((item) => {
                if (item.id === this.id) {
                    this.item = item
                }
                if (!this.item) {
                    this.itemNumber++
                }
            })
        },
        showPrevious() {
            this.itemNumber--
            this.item = this.items[this.itemNumber - 1]

            if (this.item.onShow) {
                this.item.onShow.call()
            }
        },
        showNext() {
            this.itemNumber++
            this.item = this.items[this.itemNumber - 1]

            if (this.item.onShow) {
                this.item.onShow.call()
            }
        },
    }
}
</script>

<style scoped>

</style>