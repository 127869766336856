var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return 0
    ? _c(
        "button",
        {
          staticClass: "float-start btn btn-light btn-sm",
          attrs: { disabled: !_vm.activeItemIdsLength, type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    Clear active items\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }