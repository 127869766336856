<template>
    <textarea ref="codeMirror">{{ value }}</textarea>
</template>

<script>
export default {
    name: "FormControlTypeJson",
    props: {
        field: Object,
        currentData: Object,
    },
    data() {
        return {
            value: undefined,
        }
    },
    computed: {
        prefs() {
            return this.$store.getters['user/pref']('codeMirror')
        }
    },
    mounted() {
        let indent = 2

        this.value = this.currentData[this.field.name]
        if (typeof this.value === 'object') {
            this.value = JSON.stringify(this.value, undefined, indent)
        }

        let options = {
            //value: value,
            mode: 'application/json',
            lineNumbers: true,
            // Line wrapping must be enabled when presenting CodeMirror in a form else long lines will
            // stretch the form beyond the viewport.
            lineWrapping: true,
            // todo - Changes aren't being detected by the form so this has been disabled.
            readOnly: true,//ctrl.field.readonly,
            indentUnit: indent,
            tabMode: 'spaces',
            enterMode: 'indent',
            electricChars: false//, // Prevents '{' from resetting the indent
        }

        if (this.prefs) {
            if (this.prefs.theme) {
                options['theme'] = this.prefs.theme
            }
        }

        this.$nextTick(() => {
            CodeMirror.fromTextArea(this.$refs.codeMirror, options)
        })
    }
}
</script>

<style scoped>

</style>