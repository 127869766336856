<template>
    <span class="wrapper">
        <span v-for="(item, index) in items" v-if="items" :key="index" v-tooltip :class="getClass(item.event)"
              :title="item.recipient" class="badge me-1" data-bs-placement="bottom" data-bs-toggle="tooltip"
              style="text-transform: capitalize">
            {{ item.event }}
        </span>
    </span>
</template>

<script>
import tooltip from '../../vue/directives/tooltip'

export default {
    name: "TableCellEmailMessageEvent",
    directives: {
        tooltip
    },
    props: {
        item: Object,
        value: Object
    },
    computed: {
        items() {
            if (this.allEvents) {
                // We only want to present the most recent event for each recipient, otherwise it will
                // clutter the UI with lots of "Accepted" events. So by sorting by -eventDate (above)
                // we can just include the first event for each recipient.
                const items = []
                const recipients = []
                this.allEvents.forEach((o) => {
                    if (recipients.indexOf(o.recipient) === -1) {
                        items.push(o)
                        recipients.push(o.recipient)
                    }
                })

                return items
            }
        }
    },
    asyncComputed: {
        allEvents() {
            return this.$store.dispatch('emailEvents/get', this.item.id)
        }
    },
    methods: {
        getClass(event) {
            let labels = {
                accepted: 'bg-warning',
                rejected: 'bg-danger',
                delivered: 'bg-success',
                failed: 'bg-danger',
                opened: 'bg-success',
                clicked: 'bg-success',
                unsubscribed: 'bg-danger',
                complained: 'bg-danger',
                stored: 'bg-warning'
            }

            if (
                event
                && labels.hasOwnProperty(event)
            ) {
                return labels[event]
            }
        }
    }
}
</script>

<style scoped>
.wrapper {
    display: inline-block;
    min-width: 80px;
}
</style>