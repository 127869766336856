<template>
    <button v-if="enabled" class="btn btn-light btn-sm" type="button" @click="onClick">
        View variations
    </button>
</template>

<script>
export default {
    name: "TableCellProductTypeId",
    props: {
        item: Object,
        value: Number,
    },
    data() {
        return {
            enabled: false
        }
    },
    created() {
        if (this.value) {
            this.enabled = true
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch('request/get', {
                    url: 'api/Products/getTypesTableNames'
                })
                .then((obj) => {
                    let typeTableNames = obj.data
                    let tableName = typeTableNames[this.value]
                    window.location = '/#/' + tableName + '?productId=' + this.item.id
                })
        }
    },
}
</script>

<style scoped>

</style>