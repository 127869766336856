<template>
    <FormControlTypeSelect
        v-if="options"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
        :options="options"
        :required="field.required"
    />
</template>

<script>
import FormControlTypeSelect from '../../common/form-control-type/FormControlTypeSelect'

// todo - This can be removed once relationship One-to-Many fields can be presented as select menus
//        https://creativecms.atlassian.net/browse/CMS-3209
export default {
    name: "FormControlOdpCreditLogFranchiseId",
    components: {
        FormControlTypeSelect
    },
    props: {
        currentData: Object,
        field: Object,
        formId: String,
    },
    asyncComputed: {
        options() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/odp_franchises',
                    params: {
                        fields: ['id', 'title'],
                        sort: 'title',
                        isArchived: 0
                    }
                })
                .then((obj) => {
                    let options = []
                    obj.data.items.forEach((obj) => {
                        options.push({
                            value: obj.id,
                            text: obj.title
                        })
                    })
                    return options
                })
        }
    },
}
</script>

<style scoped>

</style>