var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-tree-content-areas" },
    _vm._l(_vm.contentAreas, function (contentArea) {
      return _c("SiteTreeContentArea", {
        key: contentArea,
        attrs: {
          "content-area": contentArea,
          "page-id": _vm.pageId,
          "select-content": _vm.selectContent,
          "site-tree-id": _vm.siteTreeId,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }