<template>
    <div class="dropdown">
        <Button class="btn-sm dropdown-toggle" data-bs-toggle="dropdown">
            {{ selectedSectionTitle }}
        </Button>

        <div class="dropdown-menu">
            <button
                v-for="section in sectionsData"
                :key="section.tableName"
                :class="{'active': section.tableName === sectionName}"
                class="dropdown-item"
                type="button"
                @click="setSectionName(section.tableName)"
            >
                {{ section.title }}
            </button>
        </div>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "ThemesEditorSectionMenu",
    components: {Button},
    computed: {
        sectionName() {
            return this.$store.state.themes.sectionName
        },
        sectionsData() {
            return this.$store.state.themes.sectionsData
        },
        selectedSectionTitle() {
            return this.sectionsData.filter(obj => obj.tableName === this.sectionName)[0].title
        }
    },
    methods: {
        setSectionName(tableName) {
            this.$store.dispatch('themes/setSectionName', tableName)
        }
    }
}
</script>

<style scoped>

</style>