var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("table", { staticClass: "table table-sm mb-0" }, [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.columns, function (column) {
              return _c("th", { key: column.id }, [
                _vm._v(_vm._s(column.title)),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.items, function (item) {
            return _c(
              "tr",
              { key: item.id },
              _vm._l(_vm.columns, function (column) {
                return _vm.viewId
                  ? _c(
                      "td",
                      { key: column.id },
                      [
                        _c("TableCell", {
                          attrs: {
                            id: item.id,
                            "column-id": column.id,
                            "view-id": _vm.viewId,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              0
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }