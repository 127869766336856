var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "button",
        {
          staticClass: "btn wizard-form-control-publish",
          class: _vm.currentData.status ? "btn-success" : "btn-danger",
          attrs: { disabled: !_vm.allowPublish, type: "button" },
          on: { click: _vm.onClick },
        },
        [_c("span", [_vm._v(_vm._s(_vm.statusLabel))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }