var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model.number",
          value: _vm.item.fieldId,
          expression: "item.fieldId",
          modifiers: { number: true },
        },
      ],
      staticClass: "form-select form-select-sm d-inline-block w-auto",
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return _vm._n(val)
            })
          _vm.$set(
            _vm.item,
            "fieldId",
            $event.target.multiple ? $$selectedVal : $$selectedVal[0]
          )
        },
      },
    },
    [
      _c("option", { attrs: { selected: "", disabled: "", value: "null" } }, [
        _vm._v("Select…"),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "0" } }, [
        _vm._v(_vm._s(_vm.component?.title) + " items"),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { disabled: "" } }, [_vm._v("──────────")]),
      _vm._v(" "),
      _vm._l(_vm.fields, function (field) {
        return _c("option", { domProps: { value: field.id } }, [
          _vm._v(_vm._s(field.title)),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }