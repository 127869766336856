var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _c(
        "Button",
        {
          staticClass: "me-2",
          nativeOn: {
            click: function ($event) {
              return _vm.internalLink.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n        Browse…\n    ")]
      ),
      _vm._v(" "),
      _vm.pageId || _vm.contentId
        ? _c(
            "p",
            { staticClass: "well m-0 p-1 text-nowrap" },
            [
              _c("PagePath", {
                attrs: {
                  "page-id": _vm.pageId,
                  "page-content-id": _vm.contentId,
                },
              }),
              _vm._v(" "),
              !_vm.removeDisabledBtn
                ? _c(
                    "Button",
                    {
                      staticClass: "btn-sm",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.removeInternalLink.apply(null, arguments)
                        },
                      },
                    },
                    [_c("i", { staticClass: "bi-x-lg" })]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }