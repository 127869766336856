var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items?.length
    ? _c("section", [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list-group" },
          _vm._l(_vm.items, function (item) {
            return _c("PageContentOrphanedItem", {
              key: item.id,
              staticClass: "list-group-item",
              attrs: {
                "page-content-data-item": item,
                "last-content-area": _vm.lastContentArea,
              },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-2" }, [
      _c("label", { staticClass: "form-label mb-1 small" }, [
        _vm._v("Unassigned content"),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "form-text mt-0 mb-2",
          staticStyle: { "font-size": "13px" },
        },
        [
          _vm._v(
            "\n            Content not assigned to a page template's content area will not be displayed.\n        "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }