var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.modalTitle)),
        ]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _vm.formId
            ? _c("Form", { attrs: { id: _vm.id, "form-id": _vm.formId } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _vm.id
            ? _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.deleteItem.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "bi-trash3" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("FormSaveBtn", { attrs: { "form-id": _vm.formId } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }