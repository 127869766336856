<template>
    <button
        v-if="show"
        class="btn btn-light btn-sm"
        type="button"
        @click="onClick"
    >
        Login
    </button>
</template>

<script>
export default {
    name: "TableCellCustomersLogin",
    props: {
        item: Object
    },
    computed: {
        categoryIds() {
            if (
                this.item.username
                && this.item.username.trim().length > 0
            ) {
                return this.$store.getters['categories/get']('m_registrants_registrants', 'contactGroup', this.item.id)
                    .map(o => o.catId)
            }
        },
        show() {
            if (this.categoryIds?.length) {
                const groupIds = this.$store.state.accessGroups.items.map(o => o.id)
                return groupIds.some(item => this.categoryIds.includes(item))
            }
        }
    },
    methods: {
        onClick() {
            let url = 'api/customer/get-auto-login-key?customerId=' + this.item.id
            this.$store.dispatch('request/get', {
                    url: url
                })
                .then((obj) => {
                    if (obj.data.key) {
                        let url = this.$store.state.websiteEndpoint + '/controller/Customers/autoLogin/' + obj.data.key
                        window.open(url)
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>