<template>
    <span v-if="value" class="table-cell-hidden-icon-parent">
        {{ value }}
        <a :href="'tel:' + value" class="bi-telephone table-cell-hidden-icon"></a>
    </span>
</template>

<script>
export default {
    name: "TableCellTypeTel",
    props: {
        value: String,
    },
}
</script>

<style scoped>

</style>