var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasGroupAccess ||
    _vm.isUnpublished ||
    _vm.isScheduled ||
    _vm.isArchived
    ? _c("span", [
        _vm.hasGroupAccess
          ? _c(
              "span",
              { staticClass: "icon", staticStyle: { cursor: "default" } },
              [
                _c("i", {
                  staticClass: "bi-person-fill",
                  attrs: { title: "This page is assigned group access" },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isUnpublished
          ? _c(
              "span",
              {
                staticClass: "icon icon-unpublished",
                staticStyle: { cursor: "default" },
                attrs: { title: "Unpublished" },
              },
              [
                _c("i", {
                  staticClass: "bi-x-circle-fill",
                  attrs: { title: "Unpublished" },
                }),
              ]
            )
          : _vm.isScheduled
          ? _c(
              "span",
              {
                staticClass: "icon",
                staticStyle: { cursor: "default" },
                attrs: { title: _vm.schedulingTitle },
              },
              [
                _c("i", {
                  staticClass: "bi-clock-history",
                  class: {
                    "icon-pending": _vm.isPending,
                    "icon-expiring": _vm.isExpiring,
                    "icon-expired": _vm.isExpired,
                  },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isArchived
          ? _c("small", { staticClass: "badge text-bg-secondary pe-none" }, [
              _vm._v("archived"),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }