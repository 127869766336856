<template>
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Print report</h4>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <iframe ref="iframe" :srcdoc="iframeHtml"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrintReportModal",
    props: {
        options: Object,
    },
    data() {
        return {
            html: this.options.html
        }
    },
    computed: {
        iframeHtml() {
            return `
<html>
    <body>
        <link rel="stylesheet" href="//maxcdn.bootstrapcdn.com/bootstrap/3.2.0/css/bootstrap.min.css">` +
            this.html +
            `<script>setTimeout(function() { window.print() }, 200)<\/script>
    </body>
</html>
            `
        }
    }
}
</script>

<style scoped>
iframe {
    width: 100%;
    height: 100%;
    border: none
}
</style>