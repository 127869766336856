export default {
    namespaced: true,
    state: {
        data: {},
        steps: [],
        targetElement: undefined,
        parentElement: undefined,
        parentMask: undefined,
    },
    mutations: {},
    actions: {
        // Loads the wizard's data, and evals its steps
        load({}, id) {
            angular.element('body').addClass('body-fixed')
            
            ItemData.get('wizards', id)
                .then(function(obj) {
                    
                    // Setting these before the eval allows JavaScript in the steps to
                    // access them.
                    let fn = WizardFunctionsService,
                        wizard = service
                    
                    eval('steps = ' + obj.script)
                    
                    setStep(0)
                })
        },
        resetData() {
            steps = []
            
            angular.extend(data, {
                step: false,
                targetElement: false,
                maskedElement: false
            })
        },
        historyBackWatch() {
            // Detects if the user uses the browser's back/next buttons
            // If they do then close the wizard.
            $rootScope.$on('$locationChangeSuccess', function() {
                $rootScope.actualLocation = $location.path()
            })
            
            $rootScope.$watch(function() {
                return $location.path()
            }, function(newLocation, oldLocation) {
                if ($rootScope.actualLocation === newLocation) {
                    closeWizard()
                }
            })
        },
        getData() {
            return data
        },
        getStep() {
            return data.step
        },
        setStep(step) {
            resetTargetElement(data)
            data.maskedElement = false
            resetParentElement()
            
            data.step = false
            
            // Why 2 timeouts?
            // This hack is to get around the fact that clicking the add page button in the site tree
            // resulted in the form being targeted, but then the form was reloaded, which meant that
            // the indicator wasn't able to target it correctly.
            $timeout(function() {
                $timeout(function() {
                    data.stepObj = steps[step]
                    
                    // If no step object exists then this is the end of the wizard
                    if (!data.stepObj) {
                        closeWizard()
                        
                    } else if (data.stepObj.targetElement) {
                        // Incase the element isn't yet in the DOM user an interval to target it when
                        // it appears. This is required for circumstances such as when creating a new
                        // page in the site tree, as it takes a few milliseconds for the new page item
                        // to be compiled in the site tree.
                        //console.log('data.stepObj.targetElement', data.stepObj.targetElement);
                        let maxIterations = 10,
                            interations = 0,
                            interval = $interval(function() {
                                targetElement = angular.element(data.stepObj.targetElement)
                                if (targetElement.length) {
                                    //console.log(targetElement);
                                    
                                    $interval.cancel(interval)
                                    //console.log(data.stepObj.targetElement, targetElement);
                                    setTargetElement(targetElement, data.stepObj)
                                    
                                    $timeout(function() {
                                        // Don't update the step until the element has loaded because the wizard
                                        // directive watches the step, to trigger the indicator lines to be positioned.
                                        data.step = step
                                    })
                                    
                                } else if (interations > maxIterations) {
                                    //console.error("Wizard step's target element not found", data.stepObj.targetElement);
                                    $interval.cancel(interval)
                                } else {
                                    interations++
                                }
                            }, 50)
                        
                    } else {
                        data.step = step
                    }
                })
            })
        },
        nextStep() {
            let step = data.step + 1
            
            let goStep = function(step, delayNext) {
                $timeout(function() {
                    setStep(step)
                }, delayNext)
            }
            
            let stepObj = service.getStepObj()
            if (stepObj) {
                if (stepObj.onNext) {
                    // onNext must return true in order to invoke the next step.
                    // This allows the onNext functions to make checks and prevent
                    // the next step from loading until requirements are met such
                    // as filling in a form field.
                    if (stepObj.onNext() !== false) {
                        goStep(step, stepObj.delayNext)
                    }
                } else {
                    goStep(step, stepObj.delayNext)
                }
            } else {
                closeWizard()
            }
        },
        hasSteps() {
            return steps.length
        },
        getStepObj() {
            return steps[data.step]
        },
        targetElementExists() {
            return !!targetElement
        },
        getTargetElement() {
            return targetElement
        },
        close() {
            closeWizard()
        },
        positionElement(el) {
            // Only set the element's position to relative if it's not set yet.
            let position = el.css('position'),
                newPosition = position == 'static'
                    ? 'relative'
                    : position
            el.css('position', newPosition)
        },
        setTargetElement(el, stepObj) {
            
            // Always start by scrolling top. We will then detect if the
            // element is out of the viewport and if so adjust the scroll
            // position accordingly.
            angular.element('body').scrollTop(0)
            
            // Ensure the element is within the viewport
            // Scroll up to the element if the user has scrolled down.
            let viewportHeight = angular.element(window).height(),
                elWidth = el.outerWidth(),
                elHeight = el.outerHeight(),
                offset = el.offset()
            
            // Centrally position the element.
            let topMargin = (viewportHeight - elHeight) / 2,
                scrollTop = offset.top - topMargin
            angular.element('body').scrollTop(scrollTop)
            
            positionElement(el)
            el.css('z-index', 10010)
            
            if (stepObj.eventType) {
                // If the event doesn't have a namespace place it in the wizard namespace.
                if (stepObj.eventType.indexOf('.') === -1) {
                    stepObj.eventType = stepObj.eventType + '.wizard'
                }
                
                el
                    .on(stepObj.eventType, {
                        wizard: service,
                        fn: WizardFunctionsService
                    }, stepObj.eventCallback)
                
            } else if (stepObj.eventType === false) {
                // Do nothing. Prevents a default click event be applied below.
                
                // By default, if there is no eventType, apply a click event
                // on the element to load the next step. The reason for this
                // approach is it means wizards can be built
            } else {
                el
                    .on('click.wizard', function() {
                        nextStep()
                    })
            }
            
            if (stepObj.targetElementClassName) {
                //console.log('stepObj.targetElementClassName', [stepObj.targetElementClassName, el]);
                el.addClass(stepObj.targetElementClassName)
            }
            
            if (stepObj.onLoad) {
                //console.log('stepObj.onLoad', stepObj.onLoad);
                stepObj.onLoad()
            }
            
            // Check if the targetElement is in a dropdown menu, and if so
            // set the dropdown as the parent element. This is required because
            // Bootstrap dropdown menus have an z-index which must be overwritten.
            parentElement = false
            if (stepObj.parentElement) {
                parentElement = angular.element(stepObj.parentElement)
                //console.log('stepObj.parentElement', [stepObj.parentElement, parentElement]);
            } else {
                let dropdownMenu = el.closest('.dropdown-menu')
                if (dropdownMenu.length) {
                    parentElement = dropdownMenu
                }
            }
            
            // The point of the additional element property is to allow another element to
            // be raised above the overlay, but for it to not be clickable. For example, when
            // targeting a navigation menu we want to raise the whole dropdown menu but only
            // allow a the
            if (parentElement) {
                positionElement(parentElement)
                parentElement.css('z-index', 10005)
                
                // Stop Bootstrap dropdown menus from hiding if clicked
                // We want to stop the click event on the parentElement, but not if
                // the user is hovering over the targetElement, as they will be clicking
                // the target element at that point.
                let targetElementActive = false
                parentElement
                    .on('click.wizard', function(e) {
                        if (!targetElementActive) {
                            e.preventDefault()
                            e.stopPropagation()
                        }
                    })
                
                targetElement
                    .on('mouseover.wizard', function() {
                        targetElementActive = true
                    })
                    .on('mouseout.wizard', function() {
                        targetElementActive = false
                    })
                
                // When a parent element is specified we don't want it to be accessible
                parentMask = angular.element('<div />')
                    .css({
                        position: 'absolute',
                        zIndex: 1,
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        //opacity: 0,
                        cursor: 'not-allowed',
                        background: 'rgba(0,0,0,.25)'
                    })
                    .appendTo(parentElement)
                
            } else if (stepObj.maskElement) {
                //console.log('stepObj.maskElement', stepObj.maskElement);
                data.maskedElement = false
                $timeout(function() {
                    data.maskedElement = el
                })
            }
        },
        resetTargetElement(data) {
            if (targetElement) {
                let stepObj = data.stepObj
                
                if (stepObj.eventType) {
                    targetElement
                        .off(stepObj.eventType, stepObj.eventCallback)
                }
                
                if (stepObj.targetElementClassName) {
                    targetElement.removeClass(stepObj.targetElementClassName)
                }
                
                targetElement
                    .off('.wizard')
                    .css('z-index', '')
                targetElement = false
            }
        },
        resetParentElement() {
            if (parentElement) {
                parentMask.remove()
                parentElement
                    .off('.wizard')
                    .css('z-index', '')
            }
        },
        closeWizard() {
            angular.element('body').removeClass('body-fixed')
            resetTargetElement(data)
            resetParentElement()
            resetData()
        }
    },
}