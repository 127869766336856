<script>
import FormControlTypeRelationshipManyToMany from '../form-control-type/FormControlTypeRelationshipManyToMany'

export default {
    name: "FormControlUsersRoles",
    extends: FormControlTypeRelationshipManyToMany,
    computed: {
        administrator() {
            return parseInt(this.currentData.administrator)
        }
    },
    watch: {
        administrator(newValue) {
            this.toggleVisibility(newValue)
        }
    },
    created() {
        this.toggleVisibility(this.administrator)
    },
    methods: {
        toggleVisibility(value) {
            // Without this visibility: hidden isn't detected when the field is first created.
            this.$nextTick(() => {
                this.field.visibility = value ? 'hidden' : null
            })
        }
    }
}
</script>

<style scoped>

</style>