<script>
import FormControlUrlSlug from './FormControlUrlSlug'

export default {
    name: "FormControlPortalSitesBuildName",
    extends: FormControlUrlSlug,
}
</script>

<style scoped>

</style>