var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.itemCount <= 0
    ? _c(
        "div",
        [
          _c("ListingNoContent", { attrs: { "view-id": _vm.viewId } }),
          _vm._v(" "),
          _c("ListingNoContentOptions", { attrs: { "view-id": _vm.viewId } }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "list-group list-group-flush" },
        _vm._l(_vm.ids, function (id) {
          return _c("ListViewRow", {
            key: id,
            staticClass: "list-group-item",
            class: { active: _vm.selectedIds.indexOf(id) > -1 },
            attrs: { id: id, "view-id": _vm.viewId },
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }