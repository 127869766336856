<template>
    <div>
        <FormSidebarBtn v-if="id" data-bs-toggle="offcanvas" data-bs-target="#historyOffcanvas">
            <i class="me-2 bi-clock-history"/>
            Open history
        </FormSidebarBtn>

        <div class="offcanvas offcanvas-end w-50" tabindex="-1" id="historyOffcanvas"
             aria-labelledby="historyOffcanvasLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="historyOffcanvasLabel">History</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body">
                <ItemHistory v-if="open" :form-id="formId"/>
            </div>

            <div class="p-3 text-end">

            </div>
        </div>
    </div>
</template>

<script>
import FormSidebarBtn from "./FormSidebarBtn.vue"
import ItemHistory from "../item-history/ItemHistory.vue"

export default {
    name: "FormHistoryBtn",
    components: {
        ItemHistory,
        FormSidebarBtn
    },
    props: {
        formId: String,
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        id() {
            return this.$store.state[this.formId].id
        }
    },
    created() {
        const self = this
        this.$nextTick(() => {
            var myOffcanvas = document.getElementById('historyOffcanvas')
            myOffcanvas.addEventListener('show.bs.offcanvas', function() {
                self.open = true
            })
            myOffcanvas.addEventListener('hide.bs.offcanvas', function() {
                self.open = false
            })
        })
    }
}
</script>

<style scoped>

</style>