var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-group" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v(_vm._s(_vm.item.firstName) + " " + _vm._s(_vm.item.lastName)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "card-text" }, [
          _c(
            "a",
            {
              attrs: { href: "mailto:" + _vm.item.email },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [_vm._v(_vm._s(_vm.item.email))]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "card-text" }, [
          _c(
            "a",
            {
              attrs: { href: "tel:" + _vm.item.mobile || _vm.item.telephone },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [_vm._v(_vm._s(_vm.item.mobile || _vm.item.telephone))]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [_vm._v("Billing address")]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _vm._v("\n            " + _vm._s(_vm.item.billingAddress1)),
        _vm.item.billingAddress1 ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.billingAddress2)),
        _vm.item.billingAddress2 ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.billingTown)),
        _vm.item.billingTown ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.billingCounty)),
        _vm.item.billingCounty ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.billingPostcode)),
        _vm.item.billingPostcode ? _c("br") : _vm._e(),
        _vm._v(
          "\n            " + _vm._s(_vm.item.billingCountry) + "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [_vm._v("Delivery address")]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _vm._v("\n            " + _vm._s(_vm.item.address1)),
        _vm.item.address1 ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.address2)),
        _vm.item.address2 ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.townCity)),
        _vm.item.townCity ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.county)),
        _vm.item.county ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.postcode)),
        _vm.item.postcode ? _c("br") : _vm._e(),
        _vm._v("\n            " + _vm._s(_vm.item.country) + "\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }