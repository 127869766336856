<template>
    <FormSelectOptions v-if="currentData.type === 'select'" :current-data="currentData" :field="field"
                       :form-id="formId"/>
</template>

<script>
import FormSelectOptions from '../form/FormSelectOptions'

export default {
    name: "FormControlModulestructureOptions",
    components: {
        FormSelectOptions
    },
    props: {
        formId: String,
        field: Object
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        type() {
            return this.currentData.type
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        }
    },
    watch: {
        type() {
            this.init()
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.field.visibility = ''

            switch (this.currentData.type) {
                case 'select':
                    // This was required otherwise once link (above) has changed it to "Link type" it then needs to
                    // be changed back.
                    this.field.label = 'Options'

                    // The following was used when the JSON was presented in a textarea.
                    // It might be worth retaining this for reference.
                    // Use stringify's built in prettify.
                    //ctrl.form.currentData.options = JSON.stringify(ctrl.form.currentData.options, undefined, 2);
                    //ctrl.form.presetData(ctrl.field.name, ctrl.form.currentData.options);
                    break

                default:
                    // field.hidden cannot be used here because it removes this component completely, and so its
                    // watch and computed don't operate.
                    this.field.visibility = 'hidden'
                    break
            }
        }
    }
}
</script>

<style scoped>

</style>