<template>
    <span>
        <button class="btn btn-light me-2" type="button" @click="openSiteTreeModal">
            Browse…
        </button>

        <span v-if="pageId" class="well well-sm m-0 p-1 text-nowrap">
            <PagePath :page-id="pageId"/>
        </span>
    </span>
</template>

<script>
import PagePath from '../../common/PagePath'

export default {
    name: "SearchFilterFieldInternalLink",
    components: {
        PagePath
    },
    props: {
        filter: Object,
        viewId: String,
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        pageId() {
            return this.filters.advancedFilter[this.filter.id]
        }
    },
    methods: {
        openSiteTreeModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    linkOptions: 'ip',
                    pageId: this.pageId,
                    //contentId: ctrl.contentId,
                    onSelect: (obj) => {
                        this.$store.commit(this.viewId + '/setFilter', {
                            id: this.filter.id,
                            value: obj.pageId || false
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>