<template>
    <div v-model="ids" :name="field.name">
        <div v-if="!field.readonly" class="mt-1 mb-2">
            <Button :disabled="field.disabled" @click.native="selectCategories">
                <i class="bi-plus-lg"/>
            </Button>

            <Button v-if="targetTableName" v-user-access="targetTableName" :disabled="field.disabled"
                    @click.native="manageCategories">
                <i class="bi-pencil-square"/>
            </Button>
        </div>

        <div v-if="targetTableName" v-show="!field.disabled" class="list-group mb-0">
            <a v-for="(id, index) in ids" v-if="!restrictedIds || restrictedIds.indexOf(id) === -1" :key="id"
               class="list-group-item d-flex align-items-center" href @click.prevent="editItem(id)">
                <FormControlCategoryTitle :id="id" :table-name="targetTableName" class="flex-fill"/>

                <ItemStatusIcons :id="id" :table-name="targetTableName"/>

                <template v-if="!field.readonly">
                    <Button v-if="!selectLimit || selectLimit > 1" class="btn-sm" :disabled="!index"
                            @click.prevent.stop.native="moveUp(id)">
                        <i class="bi-chevron-up"/>
                    </Button>

                    <Button v-if="!selectLimit || selectLimit > 1" class="btn-sm" :disabled="index === ids.length - 1"
                            @click.prevent.stop.native="moveDown(id)">
                        <i class="bi-chevron-down"/>
                    </Button>

                    <Button @click.prevent.stop.native="removeCategory(id)" class="btn-sm">
                        <i class="bi-x-lg"/>
                    </Button>
                </template>
            </a>
        </div>
    </div>
</template>

<script>
import FormControlCategoryTitle from '../../common/form-control/FormControlCategoryTitle'
import ItemStatusIcons from '../../common/ItemStatusIcons'

import userAccess from '../../vue/directives/userAccess'
import Button from "../Button.vue"

export default {
    name: "FormControlTypeRelationshipManyToMany",
    components: {
        Button,
        FormControlCategoryTitle,
        ItemStatusIcons
    },
    directives: {
        userAccess,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        restrictedIds: Array,
        selectLimit: Number,
    },
    computed: {
        components() {
            return this.$store.state.components.items
        },
        targetTableName() {
            return this.field?.tableName || this.components.find(o => o.id === this.field.categoryComponentId)?.tableName
        },
        tableName() {
            let tableName = this.$store.state[this.formId].tableName

            // If this is the content component then this field may belong to a CT component, in which case the
            // categories request must target the component_<id> table and not "content". The ID of the table's record
            // is assigned by FormControlContentContent to the field's contentComponentsItemId property.
            if (tableName === 'content') {
                let componentId = this.field.modulecomponentsID
                let component = this.$store.state.components.items.find(obj => obj.id === componentId)
                if (component) {
                    tableName = component.tableName
                }
            }

            return tableName
        },
        id() {
            let id = this.$store.state[this.formId].id

            // If this is the content component then this field may belong to a CT component, in which case the
            // categories request must target the component_<id> table and not "content". The ID of the table's record
            // is assigned by FormControlContentContent to the field's contentComponentsItemId property.
            if (this.tableName.indexOf('component_') === 0) {
                id = this.field.contentComponentsItemId || id
            }

            return id
        },
        ids: {
            get() {
                return this.currentData[this.field.name]
            },
            set(value) {
                this.currentData[this.field.name] = value
            }
        },
        categoryIds() {
            let ids = this.$store.getters['categories/get'](this.tableName, this.field.name, this.id) || []

            if (ids.length) {
                ids = ids.map(o => o.catId)
                // Remove duplicate values. Fix for miar5's voucher data which contained duplicate IDs.
                ids = [...new Set(ids)]
            }

            return ids
        }
    },
    watch: {
        /*
        This broke the SelectListModal because it overwrote this.ids when the modal is initially opened, so changes to
        the selected items weren't reflected in the list-group.
        // When duplicating an item, the form control is created before the categories VueX module is updated with the
        // new item's data, so we must update the control once they're populated.
        categoryIds: {
            //immediate: true,
            handler(newValue) {
                this.ids = newValue
            }
        }*/
    },
    created() {
        let ids = []

        // Site tree options
        if (this.field.name.indexOf('content__') === 0) {
            ids = this.ids
                ? this.ids.map((x) => parseInt(x)) // Fix arrays of number strings e.g. ["1", "2"]
                : []

        } else if (this.id) {
            ids = this.categoryIds
        }

        this.$store.commit(this.formId + '/presetData', {
            name: this.field.name,
            value: ids,
        })

        if (this.field.required) {
            // todo - Required validation
        }

        /*
         This is not in use. Once the modulestructure table has been stripped down and
         specific field type's custom options are supported through JSON objects, this
         can be reactivated.
         this.selectLimit = this.field.selectLimit;
         */
    },
    methods: {
        selectCategories() {
            this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    formId: this.formId,
                    listingName: this.targetTableName + '.' + this.field.name,
                    selectedIds: this.ids,
                    // Providing an empty onSelect function triggers the view to use onMetaClick in place of onClick.
                    onSelect: (ids) => {
                        // this.ids = ids
                    }
                }
            })
        },
        manageCategories() {
            this.$router.push({name: 'list', params: {component: this.targetTableName}})
        },
        editItem(id) {
            this.$router.push({name: 'form', params: {component: this.targetTableName, id: id}})
        },
        moveUp(id) {
            let i = this.ids.indexOf(id)
            this.ids.splice(i, 1)
            this.ids.splice(i - 1, 0, id)
        },
        moveDown(id) {
            let i = this.ids.indexOf(id)
            this.ids.splice(i, 1)
            this.ids.splice(i + 1, 0, id)
        },
        removeCategory(id) {
            let i = this.ids.indexOf(id)
            this.ids.splice(i, 1)
        }
    }
}
</script>

<style scoped>

</style>