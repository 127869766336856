<template>
    <a
        v-if="isImage"
        :title="value"
        href
        @click.prevent="onClick"
    >
        {{ value }}
    </a>
    <a v-else :href="href" :title="value">{{ value }}</a>
</template>

<script>
export default {
    name: "TableCellFilesFileName",
    props: {
        item: Object,
        value: String,
        viewId: String,
    },
    computed: {
        isImage() {
            return (
                this.item.mimetype
                && this.item.mimetype.indexOf('image') === 0 // e.g. image/jpeg image/gif
            )
        },
        href() {
            return this.$store.getters.fileSrc(this.item.directory, this.value)
        }
    },
    created() {
        if (this.isImage) {
            this.$store.commit('lightbox/add', {
                id: this.value,
                title: this.value,
                src: this.$store.getters.fileSrc(this.item.directory, this.value),
                onShow() {
                    this.$store.dispatch(this.viewId + '/onClick', this.item.id)
                }
            })
        }
    },
    methods: {
        onClick() {
            if (this.isImage) {
                this.$store.dispatch('lightbox/show', this.value)
            }
        }
    }
}
</script>

<style scoped>

</style>