export default {
    namespaced: true,
    state: {
        requestLastSent: Math.ceil(Date.now() / 1000),
        requestInProgress: false,
        recentlyModified: [],
    },
    actions: {
        clearModifiedItems({state, dispatch, rootState}) {
            
            if (state.requestInProgress) {
                return false
            }
            
            state.requestInProgress = true
            
            dispatch('request/get', {
                url: 'api/component/activity',
                params: {
                    createdDate: {
                        ge: state.requestLastSent
                    }
                }
            }, {root: true})
                .then((obj) => {
                    state.requestLastSent = Math.ceil(Date.now() / 1000)
                    
                    let modifiedItems = obj.data.items
                    //console.log({modifiedItems})
                    
                    // This is to prevent multiple requests being sent in quick succession. For example, without this
                    // loading a new route that contains a listing would generate 2 requests because both a route
                    // change and view.load run this script.
                    setTimeout(() => {
                        state.requestInProgress = false
                    }, 200)
                    
                    if (modifiedItems.length) {
                        modifiedItems.forEach((obj) => {
                            let activityId = obj.id
                            
                            if (state.recentlyModified.indexOf(activityId) > -1) {
                                return
                            }
                            
                            state.recentlyModified.push(activityId)
                            // So the number of keys doesn't stack up make sure there's never more than 100.
                            if (state.recentlyModified.length > 100) {
                                state.recentlyModified = state.recentlyModified.slice(-100)
                            }
                            
                            let component = rootState.components.items.find(o => o.id === obj.componentId)
                            
                            // todo - Won't this be running multiple times if multiple items of the same component are updated?
                            dispatch('itemData/updateDisplayOrders', {
                                componentId: obj.componentId,
                            }, {root: true})
                            
                            dispatch('processTableName', {
                                tableName: component.tableName,
                                obj: obj
                            })
                            
                            dispatch('updateRelationshipManyToManyData', {
                                tableName: component.tableName,
                                componentId: obj.componentId,
                                itemId: obj.rowId
                            })
                        })
                    }
                })
        },
        updateRelationshipManyToManyData({dispatch, rootGetters}, {tableName, componentId, itemId}) {
            let columnNames = rootGetters['componentStructure/get'](componentId)
                .filter(o => o.type === 'relationshipManyToMany')
                .map(a => a.columnName)
            if (columnNames) {
                columnNames.forEach((columnName) => {
                    dispatch('categories/modifiedItemsUpdate', {
                        tableName: tableName,
                        columnName: columnName,
                        itemId: itemId
                    }, {root: true})
                })
            }
        },
        processTableName({dispatch, rootState, commit}, {tableName, obj}) {
            
            switch (tableName) {
                case 'templates_templates':
                    dispatch('templates/init', true, {root: true})
            }
            
            if (obj.activity === 'Update') {
                dispatch('itemData/get', {
                    tableName: tableName,
                    id: obj.rowId,
                    refresh: true,
                }, {root: true})
                
                dispatch('themes/onModifiedItems', {
                    userId: obj.createdBy,
                    tableName: tableName,
                    id: obj.rowId
                }, {root: true})
                
                //commit('contentTypeTitles/reset', tableName, {root: true})
                
                /* todo - This doesn't currently work. It doesn't update the form's values and as clearModifiedItems
                          is no longer called in a loop, saves to an open form do not trigger it. What should happen
                          is that the loop is reintroduced, but only when a form is active.
                // Forms
                if (rootState.user.user.id !== obj.createdBy) {
                    let formId = 'form:' + tableName + ':' + obj.rowId
                    if (this.hasModule(formId)) {
                        dispatch('modals/show', {
                            componentName: 'ConfirmModal',
                            obj: {
                                modalTitle: 'This item has been modified.',
                                modalBody: `<p>Do you wish to reload the form?</p>`,
                                onConfirm: () => {
                                    
                                    // todo - This can no longer be used as obj.data is no longer set.
                                    let keys = Object.keys(obj.data)
                                    
                                    dispatch('itemData/get', {
                                        tableName: tableName,
                                        id: obj.rowId,
                                    }, {root: true})
                                        .then((obj) => {
                                            keys.forEach((key) => {
                                                commit(formId + '/presetData', {
                                                    name: key,
                                                    value: obj[key],
                                                }, {root: true})
                                            })
                                            
                                            commit(formId + '/toggleShow', null, {root: true})
                                        })
                                }
                            }
                        }, {root: true})
                    }
                }
                */
            }
        }
    },
}