var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.item.value,
        expression: "item.value",
      },
    ],
    staticClass: "form-control form-control-sm d-inline-block w-auto",
    attrs: { type: "datetime-local" },
    domProps: { value: _vm.item.value },
    on: {
      input: function ($event) {
        if ($event.target.composing) return
        _vm.$set(_vm.item, "value", $event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }