var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.items, function (item) {
      return _c(
        "div",
        { staticClass: "text-nowrap d-flex align-items-center" },
        [
          _c("span", {
            directives: [{ name: "tooltip", rawName: "v-tooltip" }],
            staticClass: "me-2 d-inline-block rounded-circle p-2",
            class: _vm.getClass(item.event),
            attrs: {
              title: "Delivery status: " + item.status,
              "data-placement": "right",
            },
          }),
          _vm._v("\n\n        " + _vm._s(item.address) + "\n    "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }