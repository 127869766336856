<template>
    <div class="card dashboard-panel-activity-stream">
        <div class="card-header">
            Activity stream
        </div>

        <div class="card-body pt-0">
            <DashboardActivityStreamItem v-for="item in items" :key="item.id" :get-user-id="getUserId" :item="item"
                                         :set-user-id="setUserId"/>
        </div>
    </div>
</template>

<script>
import DashboardActivityStreamItem from './DashboardActivityStreamItem'

export default {
    name: "DashboardActivityStream",
    components: {
        DashboardActivityStreamItem,
    },
    data() {
        return {
            // This parent component keeps track of the current userId. As the child items are created they use
            // the getter and setter functions to detect if the item's userId matches the current one, and it
            // only generates the user avatar if the id changes.
            userId: 0,
        }
    },
    asyncComputed: {
        items() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/activity',
                    params: {
                        createdBy: {
                            gt: 0 // Items must be assigned to users.
                        },
                        sort: '-activityID',
                        limit: 30,
                        field: 'id',
                    }
                })
                .then((obj) => {
                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'activity',
                        ids: obj.data.values,
                    })
                })
                .then((objs) => {
                    return objs
                })
        }
    },
    methods: {
        setUserId(userId) {
            this.userId = userId
        },
        getUserId() {
            return this.userId
        }
    }
}
</script>

<style scoped>
.card-body {
    height: 405px;
    overflow: scroll;
}
</style>