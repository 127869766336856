var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative", class: { "mt-2": _vm.showAvatar } },
    [
      _vm.showAvatar
        ? _c("UserAvatar", {
            staticClass: "position-absolute",
            attrs: {
              "user-id": _vm.item.createdBy,
              "is-small": true,
              "tooltip-placement": "right",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "ms-4 ps-2 pt-1 small" }, [
        _vm.item.componentId
          ? _c(
              "span",
              {
                staticClass: "breadcrumb mb-0",
                attrs: { role: "button" },
                on: { click: _vm.goToItem },
              },
              [
                _vm.component
                  ? _c("span", { staticClass: "breadcrumb-item" }, [
                      _vm._v(_vm._s(_vm.component.title)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "breadcrumb-item" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]
            )
          : _c("span", [
              _vm._v(
                "\n            " + _vm._s(_vm.item.activity) + "\n        "
              ),
            ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "small",
            { staticClass: "text-secondary" },
            [
              _c("Timeago", {
                attrs: { datetime: _vm.item.createdDate * 1000 },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }