var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "FormSidebarBtn",
        {
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "me-2 bi-trash" }),
          _vm._v("\n    Delete item\n"),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }