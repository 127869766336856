<template>
    <span v-html="html"/>
</template>

<script>
export default {
    name: "TableCellTypeText",
    props: {
        value: String
    },
    data() {
        return {
            html: '',
        }
    },
    created() {
        // Escape < and >.
        // Required by email_messages component for embedded email addresses e.g. <nick@omcore.net>.
        if (this.value) {
            this.html = this.value.replace(/</g, '&lt;').replace(/>/g, '&gt;')
        }
    }
}
</script>

<style scoped>

</style>