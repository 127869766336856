<template>
    <header class="d-flex border-bottom" @click="formScrollTop">
        <div class="flex-fill d-flex align-items-center">
            <ShowSiteTreeBtn :form-id="formId"/>
            <FormAddBtn v-if="show" :form-id="formId"/>
            <FormDisplayTitle :form-id="formId"/>
        </div>

        <FormSaveBtn v-if="showSaveBtn" :form-id="formId" class="rounded-0"/>
        <FormSaveCloseBtn v-if="showSaveAndCloseBtn" :form-id="formId"/>
    </header>
</template>

<script>
import FormAddBtn from './FormAddBtn'
import FormDisplayTitle from './FormDisplayTitle'
import ShowSiteTreeBtn from '../../common/ShowSiteTreeBtn'
import FormSaveBtn from './FormSaveBtn'
import FormSaveCloseBtn from './FormSaveCloseBtn'

export default {
    name: "FormHeader",
    components: {
        FormAddBtn,
        FormDisplayTitle,
        ShowSiteTreeBtn,
        FormSaveBtn,
        FormSaveCloseBtn
    },
    props: {
        formId: String,
        showAddBtn: Boolean,
        showSaveBtn: Boolean,
        showSaveAndCloseBtn: Boolean
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        show() {
            return this.showAddBtn// && !this.id
        }
    },
    methods: {
        formScrollTop(e) {
            /* todo
            // Only scroll top if the toolbar itself is clicked, not a button or menu etc.
            if (
                e.target.className === 'toolbar-left'
                || e.target.className === 'toolbar-right'
            ) {
                $element.find('.form-container')[0].scrollTo(0, 0);
            }
             */
        }
    }
}
</script>

<style scoped>

</style>