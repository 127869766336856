<template>
    <span v-if="value" class="table-cell-hidden-icon-parent">
        <small><PagePath :page-id="pageId" :page-content-id="pageContentId"/></small>
        <a class="bi-box-arrow-up-right table-cell-hidden-icon" href @click.prevent="openLink"></a>
    </span>
</template>

<script>
import PagePath from '../../../common/PagePath'

export default {
    name: "TableCellTypeInternalLink",
    components: {
        PagePath
    },
    props: {
        column: Object,
        value: Number
    },
    computed: {
        pageId() {
            return this.column.targetContent ? 0 : this.value
        },
        pageContentId() {
            return this.column.targetContent ? this.value : 0
        }
    },
    methods: {
        openLink() {
            let pageId
            if (this.pageContentId) {
                pageId = this.$store.state.pageContentData.items.filter((obj) => {
                    return obj.id === this.value
                })[0].pageId
            } else {
                pageId = this.value
            }

            this.$store.dispatch('pagePath/get', {
                    pageId: pageId
                })
                .then((path) => {
                    let url = this.$store.state.websiteEndpoint + path

                    if (this.pageContentId) {
                        url += '#c' + this.pageContentId
                    }

                    window.open(url)
                })
        }
    }
}
</script>

<style scoped>

</style>