<template>
    <FormControlPlainText v-if="field.readonly" :value="readOnlyValue"/>
    <select v-else-if="!field.readonly" :id="field.id" v-model="currentData[field.name]" :name="field.name"
            :required="field.required" class="form-select">
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlModulestructureType",
    components: {FormControlPlainText},
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    computed: {
        readOnlyValue() {
            if (this.options) {
                return this.options
                    .filter(obj => obj.value === this.currentData[this.field.name])
                    .pop()?.text
            }
        },
        isFormComponent() {
            return this.$store.getters['componentFields/isForm']
        },
        isContentTypeComponent() {
            return this.$store.getters['componentFields/isContentType']
        },
        betaMode() {
            return this.$store.state.betaMode
        },
        component() {
            return this.$store.state.components.items.find(o => o.id === this.currentData.modulecomponentsID)
        }
    },
    asyncComputed: {
        // Used to restrict the collectionFilters field type to listing or teaser types.
        isTeaserOrListing() {
            if (
                this.$route.name === 'themesEditor'
                && this.$route.query.section === 'content_types'
            ) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'templates_templates',
                        id: this.$route.query.id
                    })
                    .then((o) => {
                        return o.contentType === 'Listing' || o.contentType === 'Teaser'
                    })
            }

            return false
        },
        options() {
            const isStructureComponent = (
                this.$route.name === 'form'
                && this.$route.params.component === 'modulestructure'
            )

            return this.$store.dispatch('request/get', {
                    url: 'api/get-components-structure-types'
                })
                .then((obj) => {
                    let opts = obj.data

                    let options = [{
                        value: '',
                        text: 'Select…',
                    }]

                    if (!isStructureComponent) {
                        // Only list the fields which are set to be available for the different collection types.
                        if (this.isFormComponent) {
                            opts = opts.filter(o => o.form)
                        } else if (this.isContentTypeComponent) {
                            opts = opts.filter(o => o.contentType)

                            // If the CT is not a listing or teaser remove the collectionFilters field type
                            if (!this.isTeaserOrListing) {
                                opts = opts.filter(o => o.type !== 'collectionFilters')
                            }
                        } else {
                            opts = opts.filter(o => o.collection)
                        }

                        // Remove beta fields unless in betaMode
                        opts = opts.filter(o => !o.beta || this.betaMode)
                    }

                    opts.forEach((type) => {
                        options.push({
                            value: type.type,
                            text: type.title
                        })
                    })

                    return options
                })
        }
    },
    created() {
        this.field.label = 'Type'

        // The field's type should never be changed. This is because each field has a specific data type so
        // changing it would result in data loss. It would also require extensive testing to ensure converting
        // between all data types worked.
        if (this.currentData.id) {
            this.field.readonly = true
        }

        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: ''
        })
    }
}
</script>

<style scoped>

</style>