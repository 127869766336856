var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "toast",
      staticClass: "toast",
      class: _vm.containerColourClass,
      attrs: { role: "alert", "aria-live": "assertive", "aria-atomic": "true" },
    },
    [
      _vm.title
        ? [
            _c(
              "div",
              { staticClass: "toast-header" },
              [
                _vm.createdBy
                  ? _c("UserAvatar", {
                      staticClass: "me-2",
                      attrs: { "user-id": _vm.createdBy },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("strong", { staticClass: "me-auto" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _vm._v(" "),
                _vm.createdDate
                  ? _c(
                      "small",
                      { staticClass: "text-muted" },
                      [
                        _c("Timeago", {
                          attrs: { datetime: _vm.createdDate * 1000 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.disableClose
                  ? _c("button", {
                      staticClass: "btn-close small pe-2",
                      class: _vm.buttonColourClass,
                      attrs: {
                        type: "button",
                        "data-bs-dismiss": "toast",
                        "aria-label": "Close",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "toast-body" },
              [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.body) } }),
                _vm._v(" "),
                _vm.downloadUrl
                  ? _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.downloadExport.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Download")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.percentage >= 0 && _vm.percentage < 100
                  ? _c("ProgressBar", {
                      staticClass: "mt-1",
                      attrs: { percentage: _vm.percentage },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.moreInfo
                  ? _c("div", {
                      staticClass:
                        "mt-2 py-1 px-2 border rounded bg-light small",
                      domProps: { innerHTML: _vm._s(_vm.moreInfo) },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _c("div", { staticClass: "d-flex" }, [
            _c("div", {
              staticClass: "toast-body",
              domProps: { innerHTML: _vm._s(_vm.body) },
            }),
            _vm._v(" "),
            !_vm.disableClose
              ? _c("button", {
                  staticClass: "btn-close me-2 m-auto",
                  class: _vm.buttonColourClass,
                  attrs: {
                    type: "button",
                    "data-bs-dismiss": "toast",
                    "aria-label": "Close",
                  },
                })
              : _vm._e(),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }