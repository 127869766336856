<template>
    <textarea v-if="show" ref="codeMirror">{{ value }}</textarea>
</template>

<script>
export default {
    name: "CodemirrorEditor",
    props: {
        data: Object,
    },
    data() {
        return {
            show: false,
            value: this.data.code,
            codeMirror: undefined,
        }
    },
    computed: {
        selections() {
            return this.$store.state.themes.findModalSelections
        },
        theme() {
            return this.$store.getters['user/pref']('codeMirror_theme')
        },
        lineNumbers() {
            return this.$store.getters['user/pref']('codeMirror_lineNumbers')
        },
        lineWrapping() {
            return this.$store.getters['user/pref']('codeMirror_lineWrapping')
        },
        twigMode() {
            return this.$store.getters['user/pref']('codeMirror_twigMode')
        }
    },
    watch: {
        theme(newValue) {
            this.codeMirror.setOption('theme', newValue)
        },
        lineNumbers(newValue) {
            this.codeMirror.setOption('lineNumbers', newValue)
        },
        lineWrapping(newValue) {
            this.codeMirror.setOption('lineWrapping', newValue)
        },
        twigMode(newValue) {
            if (
                this.data.mode === 'text/html'
                || this.data.mode === 'twig'
            ) {
                this.data.mode = newValue ? 'twig' : 'text/html'
            }
            this.codeMirror.setOption('mode', this.data.mode)
        }
    },
    mounted() {
        let codeMirrorOptions = {
            mode: this.data.mode,
            lineNumbers: true,
            lineWrapping: false,
            readOnly: this.data.readOnly,
            indentUnit: 4,
            tabMode: 'spaces',
            enterMode: 'indent',
            electricChars: false, // Prevents '{' from resetting the indent
            //scrollbarStyle: 'simple',
            autofocus: true,

            styleActiveLine: {
                nonEmpty: true
            },
            //styleSelectedText: true,

            // Add ons
            autoCloseTags: true
        }

        if (this.theme) {
            codeMirrorOptions['theme'] = this.theme
        }

        if (this.lineNumbers !== undefined) {
            codeMirrorOptions['lineNumbers'] = this.lineNumbers
        }

        if (this.lineWrapping !== undefined) {
            codeMirrorOptions['lineWrapping'] = this.lineWrapping
        }

        if (this.twigMode !== undefined) {
            if (
                this.data.mode === 'text/html'
                || this.data.mode === 'twig'
            ) {
                this.data.mode = this.twigMode ? 'twig' : 'text/html'
            }
            codeMirrorOptions['mode'] = this.data.mode
        }

        if (
            this.data.mode === 'javascript'
            || this.data.mode === 'css'
        ) {
            Object.assign(codeMirrorOptions, {
                gutters: ['CodeMirror-lint-markers'],
                lint: true
            })
        }

        this.show = true

        // Allow the textarea to render.
        this.$nextTick(() => {
            this.codeMirror = CodeMirror.fromTextArea(this.$refs.codeMirror, codeMirrorOptions)

            this.codeMirror.setSize('100%', '100%')

            this.setSelections()

            this.setCodeMirrorOptions()
            this.setCodeMirrorEvents()
        })
    },
    methods: {
        setSelections() {
            if (this.selections.length) {
                // The selections is an array of selections on a single line. We only want to highlight the first one
                // otherwise if the user starts to type oth instances will be replace.
                //let selections = [this.selection];
                let selections = [this.selections[0]]
                this.codeMirror.setSelections(selections)
                this.$store.commit('themes/findModalSelections', [])
            }
        },
        setCodeMirrorOptions() {
            // If the history property is set then we're reloading an existing editor.
            if (this.data.history) {
                this.codeMirror.setHistory(this.data.history)
            } else {
                // Used to determine when the code is modified. As code mirror formats the code
                // slightly when it's initialize we must use getValue here as opposed to simply
                // comparing against the code provided to this.codeMirror.
                this.data.originalCode = this.codeMirror.getValue()
            }

            if (this.data.scrollInfo) {
                this.codeMirror.scrollTo(this.data.scrollInfo.left, this.data.scrollInfo.top)
            }

            // Replace tabs with spaces
            this.codeMirror.setOption('extraKeys', {
                Tab: (cm) => {
                    let spaces = Array(cm.getOption('indentUnit') + 1).join(' ')
                    cm.replaceSelection(spaces)
                }
            })
        },
        setCodeMirrorEvents() {

            this.codeMirror.on('scroll', () => {
                this.data.scrollInfo = this.codeMirror.getScrollInfo()
            })

            this.codeMirror.on('change', () => {
                // Every time the editor is modified we retain a copy of the history. This is stored in the
                // editor's data object so it can be reused when the editor is regenerated. This is required when
                // tabbing and reloading the editors.
                this.data.history = this.codeMirror.getHistory()

                this.data.code = this.codeMirror.getValue()

                this.data.isModified = JSON.stringify(this.data.originalCode)
                    !== JSON.stringify(this.data.code)
            })

            this.codeMirror.on('focus', () => {
                this.data.focused = true
                // Make the codeMirror available to other directives.
                this.$store.commit('themes/codeMirror', this.codeMirror)
            })

            this.codeMirror.on('blur', () => {
                this.data.focused = false
            })
        }
    }
}
</script>

<style>

</style>