<template>
    <select v-model="formData.reportName" class="form-select form-select-sm" name="reportName">
        <option value="">Select a report&hellip;</option>
        <option v-for="report in reports" :value="report.name">
            {{ report.label }}
        </option>
    </select>
</template>

<script>
export default {
    name: "ReportMenu",
    computed: {
        formData() {
            return this.$store.state.odpReports.formData
        },
        reports() {
            return this.$store.state.odpReports.reports
        }
    },
    created() {
        const reportName = this.$route.query.reportName || ''
        this.$set(this.formData, 'reportName', reportName)
    }
}
</script>

<style scoped>

</style>