var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "dropdown" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-light",
        attrs: { "data-bs-toggle": "dropdown", type: "button" },
      },
      [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-menu" },
      _vm._l(_vm.columns, function (column) {
        return _c(
          "li",
          {
            key: column.id,
            on: {
              click: function ($event) {
                return _vm.setColumn(column.columnName)
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                class: { active: column.columnName === _vm.columnName },
              },
              [
                _vm._v(
                  "\n                " + _vm._s(column.title) + "\n            "
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }