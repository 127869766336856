<template>
    <FormControlTypeSelect
        v-if="options"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
        :options="options"
    />
</template>

<script>
import FormControlTypeSelect from '../form-control-type/FormControlTypeSelect'

export default {
    name: "FormControlContentContentTargetContentArea",
    components: {
        FormControlTypeSelect,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    asyncComputed: {
        options() {
            let pageId = this.currentData.content__target_page
            return this.$store.dispatch('pageTemplatesContentAreas/getPagesContentAreas', pageId)
                .then((contentAreas) => {
                    let options = []
                    contentAreas.forEach((contentArea) => {
                        options.push({
                            value: contentArea,
                            text: contentArea
                        })
                    })
                    return options
                })
        }
    }
}
</script>

<style scoped>

</style>