var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card mb-3" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        Introduction\n    "),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.$store.state.betaMode
      ? _c(
          "div",
          [
            _c(
              "Button",
              {
                nativeOn: {
                  click: function ($event) {
                    return _vm.addToast.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n            Toast\n        ")]
            ),
            _vm._v(" "),
            _c(
              "Button",
              {
                nativeOn: {
                  click: function ($event) {
                    return _vm.addToastNoTitle.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n            Simple toast\n        ")]
            ),
            _vm._v(" "),
            _c(
              "Button",
              {
                nativeOn: {
                  click: function ($event) {
                    return _vm.addToastWarning.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n            Warning\n        ")]
            ),
            _vm._v(" "),
            _c(
              "Button",
              {
                nativeOn: {
                  click: function ($event) {
                    return _vm.addToastDanger.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n            Danger\n        ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$store.state.alphaMode ? _c("span") : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body" }, [
      _c("p", { staticClass: "lead" }, [_vm._v("Welcome to CreativeCMS")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }