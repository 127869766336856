var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "list-group" },
    _vm._l(_vm.items, function (item) {
      return _c(
        "li",
        { key: item.id, staticClass: "list-group-item" },
        [_c("FormControlFilesUsageItem", { attrs: { item: item } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }