<template>
    <select v-if="options?.length" v-model="item.value" class="form-select form-select-sm d-inline-block w-auto">
        <option selected disabled value="null">Select&hellip;</option>
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
    <small v-else-if="options?.length === 0" class="form-text ms-2 mb-1 fst-italic small">No options available</small>
</template>

<script>

export default {
    name: "CollectionFilterControlTypeSelect",
    props: {
        field: Object,
        item: Object
    },
    computed: {
        tableName() {
            return this.$store.state.components.items.find(o => o.id === this.field.modulecomponentsID).tableName
        }
    },
    asyncComputed: {
        options() {
            return this.$store.dispatch('request/post', {
                    url: 'api/ListingController/getSelectFilterOptions',
                    postData: {
                        tableName: this.tableName,
                        columnName: this.field.columnName
                    }
                })
                .then((obj) => {
                    if (obj.data.length) {
                        let options = [
                            {
                                value: '--unassigned--',
                                text: '-- Unassigned --'
                            },
                        ]
                        return options.concat(obj.data)
                    } else {
                        return []
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>