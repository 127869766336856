<template>
    <input v-model="item.value" type="number" step="0.01" class="form-control form-control-sm d-inline-block w-auto"/>
</template>

<script>

export default {
    name: "CollectionFilterControlTypeCurrency",
    props: {
        field: Object,
        item: Object
    },
}
</script>

<style scoped>

</style>