var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-md-none navigation-mobile d-flex align-items-stretch" },
    [
      _c(
        "div",
        { ref: "dropdown", staticClass: "dropdown" },
        [
          _c(
            "Button",
            {
              staticClass: "rounded-0 h-100",
              attrs: { "data-bs-toggle": "dropdown" },
            },
            [_c("i", { staticClass: "bi-list" })]
          ),
          _vm._v(" "),
          _c("NavigationMobileMenu", {
            staticClass:
              "dropdown-menu dropdown-mobile-fullscreen navigation-mobile-menu",
            attrs: {
              "close-menu": _vm.closeMenu,
              "aria-labelledby": "mobileDropdownMenu",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }