<template>
    <div class="modal-dialog modal-fullscreen code-mirror-modal">
        <div class="modal-content">
            <div class="modal-header d-flex py-1">
                <h4 class="modal-title flex-fill fs-6" v-html="modalTitle"/>

                <div class="d-flex align-items-center">
                    <div class="dropdown">
                        <button class="btn btn-sm btn-light" data-bs-toggle="dropdown" type="button">
                            <i class="bi-three-dots"/>
                        </button>

                        <ul class="dropdown-menu dropdown-menu-end" @click.stop>
                            <li class="dropdown-item text-center">
                                <CodemirrorModeMenu/>
                            </li>
                            <CodemirrorLineNumbersBtn/>
                            <CodemirrorLineWrappingBtn/>
                            <CodemirrorTwigModeBtn v-if="showTwigControl"/>
                        </ul>
                    </div>

                    <button aria-label="Close" class="btn-close ms-2" type="button"
                            @click="$emit('hide-modal')"></button>
                </div>
            </div>

            <div class="modal-body p-0 h-100">
                <textarea ref="codeMirror">{{ value }}</textarea>
            </div>

            <div class="modal-footer py-1">
                <button v-if="!readOnly" :class="{ 'btn-light': !changesExist, 'btn-warning': changesExist }"
                        :disabled="!changesExist || !idExists" class="btn btn-sm d-flex align-items-center"
                        type="button" @click="saveChanges"
                >
                    Save
                </button>

                <button v-if="!readOnly" :class="{ 'btn-light': !changesExist, 'btn-warning': changesExist }"
                        class="btn btn-sm" type="button" @click="saveChangesAndCloseModal"
                >
                    Close
                </button>

                <button v-if="changesExist" :disabled="!changesExist" class="btn btn-light btn-sm modal-discard-changes"
                        type="button" @click="discardChanges"
                >
                    Discard changes
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import CodemirrorModeMenu from '../../common/codemirror/CodemirrorModeMenu'
import CodemirrorLineNumbersBtn from '../../common/codemirror/CodemirrorLineNumbersBtn'
import CodemirrorLineWrappingBtn from '../../common/codemirror/CodemirrorLineWrappingBtn'
import CodemirrorTwigModeBtn from '../../common/codemirror/CodemirrorTwigModeBtn'

export default {
    name: "CodeMirrorModal",
    components: {
        CodemirrorModeMenu,
        CodemirrorLineNumbersBtn,
        CodemirrorLineWrappingBtn,
        CodemirrorTwigModeBtn,
    },
    props: ['options'],
    data() {
        return {
            formId: this.options.formId,
            codeMirror: undefined,
            currentData: this.options.currentData,
            currentValue: undefined,
            field: this.options.field,
            idExists: false,
            modalTitle: this.options.modalTitle || '&nbsp;',
            mode: this.options.mode,
            onSaveToField: this.options.onSaveToField,
            originalValue: this.options.originalValue || '',
            readOnly: !!this.options.readOnly,
            showTwigControl: false,
            state: this.options.state,
            tableName: this.options.tableName,
            value: this.options.value,
        }
    },
    computed: {
        changesExist() {
            return this.originalValue !== this.currentValue
        },
        theme() {
            return this.$store.getters['user/pref']('codeMirror_theme')
        },
        lineNumbers() {
            return this.$store.getters['user/pref']('codeMirror_lineNumbers')
        },
        lineWrapping() {
            return this.$store.getters['user/pref']('codeMirror_lineWrapping')
        },
        twigMode() {
            return this.$store.getters['user/pref']('codeMirror_twigMode')
        }
    },
    watch: {
        theme(newValue) {
            this.codeMirror.setOption('theme', newValue)
        },
        lineNumbers(newValue) {
            this.codeMirror.setOption('lineNumbers', newValue)
        },
        lineWrapping(newValue) {
            this.codeMirror.setOption('lineWrapping', newValue)
        },
        twigMode(newValue) {
            let mode = this.mode
            if (
                mode === 'text/html'
                || mode === 'twig'
            ) {
                mode = newValue ? 'twig' : 'text/html'
            }
            this.codeMirror.setOption('mode', mode)
        },
    },
    created() {
        this.idExists = !!this.currentData.id
        this.currentValue = this.currentData[this.field.name] || ''

        this.init()
    },
    methods: {
        init() {
            if (
                this.mode === 'twig'
                || this.mode === 'text/html'
            ) {
                this.showTwigControl = true
            }

            let options = {
                value: this.value || '',
                mode: this.mode,
                lineNumbers: true,
                lineWrapping: false,
                readOnly: this.readOnly,
                indentUnit: 4,
                tabMode: 'spaces',
                enterMode: 'indent',
                electricChars: false, // Prevents '{' from resetting the indent
                //scrollbarStyle: 'simple',
                autofocus: true,

                // Add ons
                autoCloseTags: true
            }

            if (this.theme) {
                options['theme'] = this.theme
            }
            if (this.lineNumbers !== undefined) {
                options['lineNumbers'] = this.lineNumbers
            }
            if (this.lineWrapping !== undefined) {
                options['lineWrapping'] = this.lineWrapping
            }
            if (this.twigMode !== undefined) {
                let mode = this.mode
                if (
                    mode === 'text/html'
                    || mode === 'twig'
                ) {
                    mode = this.twigMode ? 'twig' : 'text/html'
                }
                options['mode'] = mode
            }

            // Lint options, only use if the right mode.
            if (
                this.mode === 'javascript'
                || this.mode === 'css'
                //|| this.mode === 'text/x-yaml' // todo - Causes a JS error.
            ) {
                Object.assign(options, {
                    gutters: ['CodeMirror-lint-markers'],
                    lint: true
                })
            }

            this.$nextTick(() => {
                this.codeMirror = CodeMirror.fromTextArea(this.$refs.codeMirror, options)

                this.codeMirror.setSize('100%', '100%')

                if (this.state) {
                    if (this.state.history) {
                        this.codeMirror.setHistory(this.state.history)
                    }
                    this.codeMirror.on('change', () => {
                        this.state.history = this.codeMirror.getHistory()
                    })
                }

                // Replace tabs with spaces
                this.codeMirror.setOption('extraKeys', {
                    Tab: (cm) => {
                        let spaces = Array(cm.getOption('indentUnit') + 1).join(' ')
                        cm.replaceSelection(spaces)
                    }
                })

                // This may come in handy if I look to reduce the amount of data saved in the history
                // table. If we only saved the data contained in the last object, this could then be
                // used to apply the changes back.
                // this.codeMirror.on('changes', function(e, obj) {
                //     console.log(obj);
                // });

                this.codeMirror.on('change', () => {
                    this.currentValue = this.codeMirror.getValue()
                })
            })
        },
        saveToField() {

            // Reset changesExist.
            this.originalValue = this.currentValue

            if (this.field.name) {
                this.currentData[this.field.name] = this.currentValue
            }

            if (this.onSaveToField) {
                this.onSaveToField.call(this, {
                    newValue: newValue
                })
            }
        },
        saveToDatabase(btn) {

            /*
            let btnScope = btn.scope().$parent;
            */

            // Only save to database if this.field.name is provided.
            // Without this the site tree template's save button was throwing an error
            // because the hidden textarea which stores the HTML doesn't have a name attribute.
            if (this.field.name) {
                this.$store.dispatch('request/patch', {
                        url: 'api/component/' + this.tableName + '/' + this.currentData.id,
                        postData: {
                            [this.field.name]: this.currentValue
                        }
                    })
                    .then(() => {
                        this.$store.commit('cacheNeedsClearing')
                    })

                // As we're saving to the database we don't want this data to be flagged as modified
                // as this will make the form's save button active.
                this.$store.commit(this.formId + '/presetData', {
                    name: this.field.name,
                    value: this.currentValue,
                })
            }
        },
        discardChanges() {
            // This shouldn't be required because the modal's hide.bs.modal script should
            // suffice, but for some reason it was causing CodeMirror to be removed.
            if (confirm('Are you sure? You have unsaved changes')) {
                // todo - I wasn't sure if this is needed in Vue.
                //this.originalValue = this.currentValue; // Prevent the confirm dialog in hide.bs.modal
                this.$emit('hide-modal')
            }
        },
        saveChanges(e) {
            this.saveToField()
            /*
            let btn = angular.element(e.target);
            this.saveToDatabase(btn);
            */
        },
        saveChangesAndCloseModal() {
            this.saveToField()
            this.$emit('hide-modal')
        }
    }
}
</script>

<style scoped>

</style>