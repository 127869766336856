var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-dialog modal-lg modal-fullscreen-sm-down" },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("h4", { staticClass: "modal-title" }, [_vm._v("Search")]),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn-close",
            attrs: { "aria-label": "Close", type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("hide-modal")
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.tableName
          ? _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("SearchField", {
                  staticClass: "mb-3 text-center",
                  attrs: { "hide-modal": _vm.hideModal, "view-id": _vm.viewId },
                }),
                _vm._v(" "),
                _c("SearchFilters", {
                  attrs: {
                    "listing-name": _vm.listingName,
                    "view-id": _vm.viewId,
                  },
                }),
                _vm._v(" "),
                _c("SearchFilterAddFilterBtn", {
                  attrs: {
                    "listing-name": _vm.listingName,
                    "view-id": _vm.viewId,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }