<template>
    <span v-html="name"/>
</template>

<script>
export default {
    name: "UserName",
    props: {
        id: Number,
        noUserStr: String,
        noUserFoundStr: String,
    },
    asyncComputed: {
        name() {
            if (this.id === 0) {
                return this.noUserStr || ''
            } else {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'users',
                        id: this.id
                    })
                    .then((obj) => {
                        if (obj) {
                            return obj.firstName + ' ' + obj.lastName
                        }

                        if (this.noUserFoundStr === '') {
                            return ''
                        }

                        return this.noUserFoundStr || '<em style="font-weight: normal">User not found.</em>'
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>