var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "mb-3 d-flex align-items-center",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.createGroup.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "dropdown" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.groupStr,
                expression: "groupStr",
              },
            ],
            staticClass: "form-control form-control-lg",
            attrs: { "data-bs-toggle": "dropdown" },
            domProps: { value: _vm.groupStr },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.groupStr = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.groupNames.length,
                  expression: "groupNames.length",
                },
              ],
              staticClass: "dropdown-menu",
            },
            _vm._l(_vm.groupNames, function (group) {
              return _c(
                "a",
                {
                  key: group,
                  staticClass: "dropdown-item",
                  on: {
                    click: function ($event) {
                      return _vm.addGroup(group)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(group) +
                      "\n                "
                  ),
                ]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      _vm._l(_vm.groups, function (group, index) {
        return _c(
          "span",
          {
            key: group,
            staticClass:
              "me-2 bg-dark text-light border rounded p-1 ps-2 d-flex align-items-center",
          },
          [
            _vm._v("\n            " + _vm._s(group) + "\n            "),
            _c("button", {
              staticClass: "btn-close btn-close-white ms-1",
              attrs: { "aria-label": "Close", type: "button" },
              on: {
                click: function ($event) {
                  return _vm.removeGroup(index)
                },
              },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "ms-1 btn btn-sm btn-light", attrs: { type: "submit" } },
      [_c("i", { staticClass: "bi-plus-lg" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }