<template>
    <div>
        <div v-for="rolesGroup in rolesGroups" :key="rolesGroup.id" class="mb-4">
            <div class="row">
                <label class="col-sm-3 form-label">{{ rolesGroup }}</label>
                <div class="col-sm-9 control-wrapper">
                    <FormControlUsersGroupsRolesGroup :form-id="formId" :roles-group="rolesGroup"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormControlUsersGroupsRolesGroup from './FormControlUsersGroupsRolesGroup'

export default {
    name: "FormControlUsersGroupsRoles",
    components: {
        FormControlUsersGroupsRolesGroup
    },
    props: {
        formId: String,
        field: Object,
    },
    computed: {
        components() {
            return this.$store.state.components.items
        },
        rolesGroups() {
            let rolesGroups = []

            this.components.forEach((obj) => {
                if (
                    obj.roles
                    && obj.rolesGroup
                    && rolesGroups.indexOf(obj.rolesGroup) === -1
                ) {
                    rolesGroups.push(obj.rolesGroup)
                }
            })

            return rolesGroups
        }
    },
    created() {
        const roleId = this.$store.state[this.formId].id
        let privileges = this.$store.state.userRoles.privileges[roleId]
        if (privileges === undefined) {
            this.$store.commit('userRoles/initRole', roleId)
            privileges = this.$store.state.userRoles.privileges[roleId]
        }

        // By assigning the state to the value, modifications to the state will automatically be detected by the form
        this.$store.commit(this.formId + '/presetData', {
            name: 'roles',
            value: privileges
        })
    }
}
</script>

<style scoped>

</style>