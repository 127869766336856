<template>
    <button class="dropdown-item" type="button" @click="setPref">
        <span :class="{ 'bi-square': !twigMode, 'bi-check-square': twigMode }" class="me-2"/>
        Highlight Twig code
    </button>
</template>

<script>
export default {
    name: "CodemirrorTwigModeBtn",
    computed: {
        twigMode() {
            return this.$store.getters['user/pref']('codeMirror_twigMode')
        }
    },
    methods: {
        setPref() {
            this.$store.dispatch('user/setPref', {
                name: 'codeMirror_twigMode',
                value: !this.twigMode
            })
        }
    }
}
</script>

<style scoped>

</style>