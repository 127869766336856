var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.className
    ? _c("i", {
        staticClass: "bi",
        class: _vm.className,
        attrs: { title: _vm.type },
      })
    : _c("span", [
        _vm._v(
          _vm._s(
            this.type === "customscript" || this.type === "fieldset"
              ? ""
              : this.type
          )
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }