var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.usage !== undefined
    ? _c(
        "Button",
        {
          staticClass: "btn-sm p-0 px-1",
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.showPanel.apply(null, arguments)
            },
          },
        },
        [_c("small", [_vm._v(_vm._s(_vm.usage))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }