<template>
    <button
        class="btn btn-light btn-sm"
        @click="onClick"
    >
        Documentation
    </button>
</template>

<script>
export default {
    name: "SupportOpenDocumentationBtn",
    methods: {
        onClick() {
            window.open('http://support.omcore.net/home/')
        }
    }
}
</script>

<style scoped>

</style>