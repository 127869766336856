export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        reset(state) {
            state.items.length = 0
        },
        add(state, obj) {
            state.items.push(obj)
        },
    },
    actions: {
        show({state, dispatch}, id) {
            dispatch('modals/show', {
                componentName: 'LightboxModal',
                obj: {
                    items: state.items,
                    id: id
                }
            }, {root: true})
        }
    },
}