<template>
    <div>
        <div v-for="location in locations" v-if="hiddenLocationIds.indexOf(location.id) === -1" :key="location.id"
             class="d-flex align-items-center mb-2">
            <input :id="'shipping-' + location.id" v-model="currentData.shipping[location.id]['in_use']" type="checkbox"
                   @change="syncLocations(location.id)">

            <input v-model="currentData.shipping[location.id]['shipping_fee']"
                   :readonly="!currentData.shipping[location.id]['in_use']" class="ms-2 form-control w-auto" size="7"
                   type="text" @click="currentData.shipping[location.id]['in_use'] = true">

            <span class="ms-2">{{ location.location }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormControlProductTypesShipping",
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            locations: undefined,
            // The following 2 scripts are a workaround for the issue where a shipping
            // location may be duplicated (e.g. 3 instances of "UK") to cover different
            // thresholds. In order to not present duplicates here, the duplicates are
            // hidden and when the controls are updated the duplicates are synced with
            // the values of the control. For example, if the UK is set with a fee then
            // all other (hidden) UK fields are also set to the same value.
            hiddenLocationIds: [],
        }
    },
    computed: {
        typeId() {
            return this.currentData.typeId
        }
    },
    watch: {
        typeId() {
            this.setLocations()
        }
    },
    created() {
        this.field.tooltip = "Assign default variable rates to all products of this type." +
            "<br>Shipping fees assigned to individual products of this type will override these settings." +
            "<br>Shipping fees set here will not be charged if the order is outside of the deliver location's minimum/maximum threshold." +
            "<br>For more information go to: eCommerce / Shipping."

        this.setLocations()
    },
    methods: {
        setLocations() {
            this.$store.dispatch('request/get', {
                    url: 'api/products-shipping-fee-data',
                    params: {
                        typeId: this.currentData.typeId
                    }
                })
                .then((obj) => {
                    this.locations = obj.data

                    this.setShippingData()

                    // See note above on shipping fees workaround.
                    let usedLocations = []
                    this.locations.forEach((obj) => {
                        (usedLocations.indexOf(obj.location) !== -1)
                            ? this.hiddenLocationIds.push(obj.id)
                            : usedLocations.push(obj.location)
                    })
                })
        },
        setShippingData() {
            let shipping = {}

            // Preset new data
            if (!this.currentData.shipping) {
                this.locations.forEach((obj) => {
                    shipping[obj.id] = {
                        in_use: false,
                        shipping_fee: ''
                    }
                })

                this.$store.commit(this.formId + '/presetData', {
                    name: 'shipping',
                    value: shipping
                })

                // Fix for old data format.
                // Similar to above but checks if the data exists for any of the locations
                // and if so it updates the "1"|"0" checkbox value to true|false
            } else {
                this.locations.forEach((obj) => {

                    let inUse = false
                    let shippingFee = ''
                    let locationData = this.currentData.shipping[obj.id]

                    if (locationData) {
                        inUse = (
                            locationData['in_use'] === '1'
                            || (
                                locationData['in_use']
                                && locationData['in_use'] !== '0'
                            )
                        )
                        shippingFee = locationData['shipping_fee']
                    }

                    shipping[obj.id] = {
                        in_use: inUse,
                        shipping_fee: shippingFee
                    }
                })

                this.$store.commit(this.formId + '/presetData', {
                    name: 'shipping',
                    value: shipping,
                })
            }
        },
        // This is a hack to get around the issue whereby if multiple locations are
        // set with the same title (e.g. UK) then only one control is displayed.
        syncLocations(locationId) {
            let location = this.locations.filter(obj => obj.id === locationId)[0]
            let shippingData = this.currentData.shipping[locationId]

            this.locations.forEach((obj) => {
                if (obj.location === location) {
                    this.currentData.shipping[obj.id] = shippingData
                }
            })
        }
    }
}
</script>

<style scoped>

</style>