<template>
    <div>
        <table :class="{ 'draggable-list--hidden': optionsCopy }" class="table rounded mb-0 w-auto">
            <thead>
            <tr>
                <th/>
                <th>Value</th>
                <th>Text</th>
                <th/>
            </tr>
            </thead>

            <tbody ref="draggableParent">
            <tr v-for="(option, index) in options" :key="option.id" class="visible-on-hover" draggable="true">
                <td><DragHandle/></td>
                <td>{{ option.value }}</td>
                <td>{{ option.text }}</td>
                <!-- Remove icon -->
                <td><small class="bi-trash3 visible-on-hover-hidden" @click="removeOption(index)"/></td>
            </tr>

            <tr v-if="!options">
                <td class="text-center" colspan="4">
                    <em>none</em>
                </td>
            </tr>
            </tbody>
        </table>

        <table v-if="optionsCopy" class="table table-condensed mb-0 w-auto">
            <thead>
            <tr>
                <th/>
                <th>Value</th>
                <th>Text</th>
                <th/>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(option, index) in optionsCopy" :key="option.id"
                :class="{ 'draggable-list--item-drag-active': index === dragIndex }"
            >
                <td><DragHandle/></td>
                <td>{{ option.value }}</td>
                <td>{{ option.text }}</td>
                <!-- Remove icon -->
                <td><small class="bi-trash3 visible-on-hover-hidden"/></td>
            </tr>
            </tbody>
        </table>

        <!-- Add option button and form -->
        <div class="mt-1">
            <!-- Add option button -->
            <button v-show="!formIsShown" ref="addOptionBtn" class="btn btn-light btn-sm" name="addOptionBtn"
                    @click="showForm()">
                <i class="bi-plus-lg"/>
            </button>

            <!-- Add option form -->
            <form v-if="formIsShown" class="row gy-2 gx-3 align-items-center" @submit.prevent="addOption">
                <div class="col-auto">
                    <!-- Value input -->
                    <input ref="valueInput" v-model="formData.value" v-select :class="{'is-invalid': errorValue}"
                           class="form-control" placeholder="Value" type="text" @keyup="valueInputKeyup">

                    <div class="invalid-feedback">
                        <span v-if="errorValue">{{ errorValue }}</span>&nbsp;
                    </div>
                </div>

                <div class="col-auto">
                    <!-- Text input -->
                    <input v-model="formData.text" :class="{'is-invalid': errorText}" class="form-control"
                           placeholder="Text" type="text"
                           @keyup="textInputKeyup">

                    <div class="invalid-feedback">
                        <span v-if="errorText">{{ errorText }}</span>&nbsp;
                    </div>
                </div>

                <div class="col-auto">
                    <!-- Save btn -->
                    <button :disabled="errorValue" class="btn btn-light btn-sm" type="submit">
                        <i class="bi-plus-lg"/>
                    </button>

                    <!-- Remove form btn -->
                    <button class="btn btn-light btn-sm" type="button" @click="hideForm">
                        <i class="bi-x-lg"/>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import DragHandle from '../../common/DragHandle'

export default {
    name: "FormSelectOptions",
    props: {
        currentData: Object,
        field: Object
    },
    components: {
        DragHandle
    },
    data() {
        return {
            options: this.currentData.options,
            optionsCopy: undefined,
            formIsShown: false,
            formData: {
                value: '',
                text: '',
            },
            doAutofill: false,
            errorText: false,
            errorValue: false,
            dragIndex: undefined,
        }
    },
    mounted() {
        this.addDragEvents()
    },
    methods: {
        valueInputKeyup(event) {
            this.autofill()
            this.escape(event)
            this.validate()
        },
        textInputKeyup(event) {
            this.setAutofill(false)
            this.escape(event)
            this.validate()
        },
        addDragEvents() {

            let draggableParent = this.$refs.draggableParent

            // An invisible pixel for the ghost image
            let img = document.createElement('img')
            img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

            // children does not return an Array, but an array-like object, so we must convert it to an array.
            var children = Array.from(draggableParent.children)

            children.forEach((el, key) => {

                el.addEventListener('dragstart', (e) => {
                    e.dataTransfer.setDragImage(img, 0, 0)
                    e.dataTransfer.setData('text/plain', key)
                    this.dragIndex = key

                    // Setting optionsCopy hides the draggable elements and shows their copies. The drag events
                    // then update optionsCopy, which updates the copies. If we were to update dragEls directly
                    // then AngularJS would recompile the elements and they'd lose their drag events.
                    this.optionsCopy = JSON.parse(JSON.stringify(this.options))
                })

                el.addEventListener('dragenter', (e) => {
                    e.preventDefault() // Required for drop event to work

                    let el = this.optionsCopy.splice(this.dragIndex, 1)[0]
                    this.optionsCopy.splice(key, 0, el)
                    this.dragIndex = key
                })

                el.addEventListener('dragover', (e) => {
                    e.preventDefault() // Required for drop event to work
                })

                el.addEventListener('drop', (e) => {
                    this.setOptions(this.optionsCopy)

                    this.optionsCopy = false
                    this.dragIndex = false
                })

            })
        },
        showForm() {
            this.resetForm()
            this.formIsShown = true
        },
        hideForm() {
            this.formIsShown = false
            this.resetForm()
        },
        resetForm() {
            this.formData.value = ''
            this.formData.text = ''

            this.doAutofill = true
        },
        addOption() {
            let options = this.currentData[this.field.name] || []
            let option = JSON.parse(JSON.stringify(this.formData))
            options.push(option)

            this.setOptions(options)

            this.resetForm()
        },
        removeOption(i) {
            this.options.splice(i, 1)
            this.setOptions(this.options)
        },
        setOptions(options) {
            options = JSON.parse(JSON.stringify(options))

            this.currentData[this.field.name] = options

            // Toggling options with a timeout recompiles the options elements,
            // after which the drag events must be reapplied.
            this.options = false
            this.$nextTick(() => {
                this.options = options
                this.$nextTick(() => {
                    this.addDragEvents()
                })
            })
        },
        setAutofill(boolean) {
            this.doAutofill = boolean
        },
        autofill() {
            if (this.doAutofill) {
                this.formData.text = this.formData.value
            }
        },
        escape(e) {
            if (e.keyCode === 27) {
                this.hideForm()

                //this.$nextTick(() => {
                this.$refs.addOptionBtn.focus()
                //});
            }
        },
        validate() {
            // Reset the error each time.
            this.errorValue = false
            this.errorText = false

            if (this.options) {
                // Check value properties
                let options = this.options.filter((option) => {
                    return option.value === this.formData.value
                })
                if (options.length) {
                    this.errorValue = 'Value exists.'
                }

                // Check text properties
                options = this.options.filter((option) => {
                    return option.value === this.formData.text
                })
                if (options.length) {
                    this.errorText = 'Text exists.'
                }
            }
        }
    }
}
</script>

<style scoped>
.draggable-list--hidden {
    position: absolute;
    z-index: 100;
    opacity: 0;
}

.draggable-list--item-drag-active {
    background: #ddd;
}
</style>