<template>
    <select
        v-if="show"
        :id="field.id"
        v-model="currentData[field.name]"
        v-form-validation
        :name="field.name"
        :required="field.required"
        class="form-select"
    >
        <option :value="topOptionValue">Select…</option>
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
import formValidation from '../../vue/directives/formValidation'

export default {
    name: "FormControlTemplatesTemplatesComponentId",
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        components() {
            return this.$store.state.components.items
        },
        options() {
            let listingComponents = this.components.filter((o) => {
                return (
                    o.listing === 1
                    || o.tableName === 'm_registrants_registrants'
                )
            })
            let options = []
            listingComponents.forEach((obj) => {
                options.push({
                    value: obj.id,
                    text: obj.title
                })
            })
            options = options.sort((a, b) => (a.text > b.text) ? 1 : -1)

            return options
        },
        contentType() {
            return this.currentData.contentType
        },
        topOptionValue() {
            return this.field.required ? '' : 0
        },
        show() {
            return true

            /*
            This hid the Component option unless specific types were selected, but it prevented PG and BBB's
            Products - Recently viewed CT from targeting a componentId, and this CT didn't relate to any of the types
            available. I tried setting it as a teaser but this resulted in the CT processing ALL of the component's
            items and seizing things up.

            if (['Categories', 'Filter', 'Listing', 'Tags', 'Teaser'].indexOf(this.currentData.contentType) !== -1) {
                this.field.visibility = '';
                return true;
            }

            this.field.visibility = 'hidden';
            this.currentData.componentId = 0;
            return false;
            */
        }
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: 'componentId',
            value: 0
        })
    },
    watch: {
        contentType() {
            this.field.required = ['Categories', 'Filter', 'Listing', 'Tags', 'Teaser']
                .indexOf(this.currentData.contentType) !== -1

            // When changing the content type, if no componentId has been selected the value must be set
            // to match the first option's value other a blank option is presented instead of "Select..."
            if (
                this.currentData.componentId === ''
                || this.currentData.componentId === 0
            ) {
                this.currentData.componentId = this.field.required ? '' : 0
            }
        }
    },
}
</script>

<style scoped>

</style>