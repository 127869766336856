<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">&nbsp;</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <div class="list-group">
                    <div
                        v-for="(log, index) in logs"
                        :key="log.id"
                        class="list-group-item pb-0"
                    >
                        <div :data-bs-target="'#log-' + index" data-bs-toggle="collapse" style="cursor: pointer;">
                            <span class="float-end">{{ getDate(log.createdDate * 1000) }}</span>

                            <p>
                                <strong>{{ log.title }}&nbsp;</strong>
                            </p>
                        </div>

                        <div :id="'log-' + index" class="collapse well well-sm well-pre">
                            <pre>{{ log.data }}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "OrdersLogsModal",
    props: {
        options: Object,
    },
    data() {
        return {
            orderId: this.options.orderId,
        }
    },
    asyncComputed: {
        logs() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/order_log',
                    params: {
                        orderId: this.orderId
                    }
                })
                .then((obj) => {
                    return obj.data.items.reverse()
                })
        }
    },
    methods: {
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YYYY, HH:mm')
        }
    }
}
</script>

<style scoped>

</style>