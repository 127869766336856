<template>
    <!-- Bootstrap's Progress component -->
    <div class="progress">
        <div
            :style="{ width: percentageWidth }"
            aria-valuemax="100"
            aria-valuemin="0"
            class="progress-bar"
            role="progressbar"
        >
            <span class="visually-hidden">{{ percentage }}% Complete</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        percentage: Number,
    },
    computed: {
        percentageWidth: function() {
            return this.percentage + '%'
        }
    },
}
</script>

<style scoped>
/* Overwrite Bootstrap's height */
.progress {
    margin-bottom: 0;
    height: 3px;
}
</style>