<template>
    <div class="mt-3 pt-3 mb-3 pb-3">
        <p v-if="!bookings.length">
            <em>empty</em>
        </p>

        <p v-if="bookings.length">
            <button v-if="courseType === 'After school club' || courseType === 'Forest school'"
                    :disabled="isButtonDisabled(false)" class="mb-2 btn btn-primary" type="button"
                    @click="openWorksheet(78, false, 'Contacts worksheet')">
                Contacts worksheet
            </button>

            <button v-if="courseType === 'After school club' || courseType === 'Forest school'"
                    :disabled="isButtonDisabled(false)" class="mb-2 btn btn-primary" type="button"
                    @click="openWorksheet(80, false, 'Sign in worksheet')">
                Sign in worksheet
            </button>

            <button v-if="courseType === 'Event' || courseType === 'Mac\'s Farm event' || courseType === 'Saplings' || courseType === 'Other event'"
                    :disabled="isButtonDisabled(false)" class="mb-2 btn btn-primary" type="button"
                    @click="openWorksheet(79, false, 'Holiday Clubs registration form')">
                Holiday Clubs registration form
            </button>

            <!-- https://omcore.zendesk.com/agent/tickets/12341
            <button
                :disabled="isButtonDisabled(false)"
                class="mb-2 btn btn-primary"
                type="button"
                @click="sendNewHolidayClubEmail"
            >
                New holiday club email
            </button>-->

            <button :class="{ 'btn-warning': parentsEmailSending }"
                    :disabled="isButtonDisabled(false) || parentsEmailSending" class="mb-2 btn btn-primary"
                    type="button" @click="sendParentsEmail(false)">
                Email parents
            </button>

            <button :class="{ 'btn-warning': confirmationEmailSending }"
                    :disabled="isButtonDisabled(false) || confirmationEmailSending" class="mb-2 btn btn-primary"
                    type="button" @click="sendConfirmationEmail">
                Confirmation email
            </button>

            <button :disabled="isButtonDisabled(false)" class="mb-2 btn btn-primary" type="button"
                    @click="openWorksheet(136, false, 'Booking table')">
                Open booking table
            </button>
        </p>

        <table v-if="bookings.length" class="table table-sm small">
            <thead>
            <tr>
                <td><input :checked="allCheckboxesChecked" type="checkbox" @click="selectAllCheckboxes()"></td>
                <td/>
                <td>Child</td>
                <td>Parent</td>
                <!--<td>Email</td>
                <td>Telephone</td>-->
                <td>Agreed price</td>
                <td>Amount paid</td>
                <td>Credit amount</td>
                <td>Payment reminder (last sent)</td>
                <td>Voucher provider</td>
                <td>Voucher payment</td>
                <td>Booking note</td>
                <!--<td>T-shirt</td>-->
                <td v-if="hasArchiveAccess"/>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(booking, index) in bookings" :key="booking.id">
                <td>
                    <input :checked="isChecked(booking.id)" type="checkbox" @click="toggleCheckbox(booking.id)">
                </td>

                <td>
                    <a :title="booking.id" href @click.prevent="goToBooking(booking.id)">
                        {{ index + 1 }}
                    </a>
                </td>

                <td>
                    <span v-if="childNames">
                        <a href @click.prevent="goToChild(booking.childId)">{{ childNames[booking.childId] }}</a>
                    </span>
                </td>

                <td>
                    <span v-if="parents[booking.childId]">
                        <a href @click.prevent="goToParent(parents[booking.childId].id)">
                            <!--{{ parents[booking.childId].id }}-->
                            {{ parents[booking.childId].firstName }} {{ parents[booking.childId].lastName }}
                        </a>
                        <br>
                        {{ parents[booking.childId].email }}
                        <br>
                        {{ parents[booking.childId].telephone }}
                    </span>
                </td>

                <!--<td>
                    <span v-if="parents[booking.childId]">
                        {{ parents[booking.childId].email }}
                    </span>
                </td>

                <td>
                    <span v-if="parents[booking.childId]">
                        {{ parents[booking.childId].telephone }}
                    </span>
                </td>-->

                <td>
                    {{ booking.agreedPrice | formatCurrency }}
                </td>

                <td>
                    <span>
                        <a href title="Open Payment Log modal" @click.prevent="openPaymentLogModal(booking.id)">
                            {{ booking.amountPaid | formatCurrency }}
                        </a>
                    </span>
                </td>

                <td>
                    {{ booking.creditAmountUsed | formatCurrency }}
                </td>

                <td>
                    <span v-if="booking.placeConfirmed">Confirmed</span>

                    <span v-if="isNotPaid(booking.amountPaid) && !booking.placeConfirmed && !booking.franchiseVoucherCodeId">
                        <button ref="sendPaymentReminderBtn" class="btn btn-light btn-sm" type="button"
                                @click="sendPaymentReminder($event, booking.id)">
                            Send
                        </button>

                        <small v-if="booking.paymentReminderLastSent" class="text-nowrap">
                            {{ getDate(booking.paymentReminderLastSent * 1000) }}
                        </small>
                    </span>
                </td>

                <td>
                    <span ref="tooltip" :style="{ color: booking.voucherPaymentConfirmed ? '#5cb85c' : '#ec971f' }"
                          :title="booking.voucherPaymentConfirmed ? 'Voucher payment confirmed' : 'Voucher payment NOT confirmed'"
                          data-bs-toggle="tooltip">{{ voucherCodeProviders[booking.franchiseVoucherCodeId] }}</span>
                </td>

                <td>
                    <span v-if="booking.voucherPaymentConfirmed">Confirmed</span>

                    <span v-if="booking.franchiseVoucherCodeId && !booking.voucherPaymentConfirmed">
                        <button ref="sendVoucherPaymentReminderBtn" class="btn btn-light btn-sm" type="button"
                                @click="sendVoucherPaymentReminder($event, booking.id)">
                            Chase voucher
                        </button>

                        <small v-if="booking.voucherPaymentReminderLastSent" class="text-nowrap">
                            {{ getDate(booking.voucherPaymentReminderLastSent * 1000) }}
                        </small>
                    </span>
                </td>

                <td>
                    <a v-if="!booking.paymentNote" href @click.prevent="showNotes(booking)">
                        <i class="bi-pencil-square"/>
                    </a>

                    <a href @click.prevent="showNotes(booking)">
                        {{ booking.paymentNote }}
                    </a>
                </td>

                <!--<td>
                    {{ booking.tshirt !== 0.00 ? booking.tshirt : '' }}
                </td>-->

                <td v-if="hasArchiveAccess" class="text-end">
                    <div class="dropdown">
                        <button class="btn btn-light btn-sm" data-bs-toggle="dropdown" type="button">
                            Action
                        </button>

                        <div class="dropdown-menu dropdown-menu-end" @click.stop>
                            <a class="dropdown-item" @click="archiveBooking(booking.id)">Archive</a>
                            <a class="dropdown-item" @click="cancelBooking(booking.id)">Cancel and credit</a>
                        </div>
                    </div>

                    <!--<button
                        v-else
                        class="btn btn-primary btn-sm"
                        type="button"
                        @click="archiveBooking(booking.id)"
                    >
                        Archive
                    </button>-->
                </td>
            </tr>
            </tbody>
        </table>

        <div class="my-3 py-3">
            <h2>
                Waiting list
            </h2>

            <p v-if="waitingListBookings.length">
                <button v-if="hasArchiveAccess" :disabled="isButtonDisabled(true)" class="mb-2 btn btn-primary"
                        type="button" @click="archiveWaitListItems">
                    Archive
                </button>

                <button :disabled="isButtonDisabled(true)" class="mb-2 btn btn-primary" type="button"
                        @click="addMultipleToBookings($event)">
                    Add to bookings
                </button>

                <button :disabled="isButtonDisabled(true)" class="mb-2 btn btn-primary" type="button"
                        @click="sendWaitListReminders($event)">
                    Send reminder
                </button>

                <button :class="{ 'btn-warning': parentsEmailSending }"
                        :disabled="isButtonDisabled(true) || parentsEmailSending" class="mb-2 btn btn-primary"
                        type="button" @click="sendParentsEmail(true)">
                    Email parents
                </button>

                <button :disabled="isButtonDisabled(true)" class="mb-2 btn btn-primary" type="button"
                        @click="openWorksheet(136, true, 'Booking table')">
                    Open booking table
                </button>
            </p>

            <p v-if="!waitingListBookings.length">
                <em>empty</em>
            </p>

            <!--<p ng-if="waitingListBookings.length">
                <button
                        class="btn btn-light"
                        ng-click="sendWaitingListConfirmationEmail()"
                        ng-disabled="isButtonDisabled(true)"
                        type="button"
                >Notification email
                </button>
            </p>-->

            <table v-if="waitingListBookings.length" class="table table-condensed">
                <thead>
                <tr>
                    <td>
                        <input :checked="allWaitingListCheckboxesChecked" type="checkbox"
                               @click="selectAllCheckboxes(true)">
                    </td>
                    <td/>
                    <td>Child</td>
                    <td>Parent</td>
                    <td>Agreed price</td>
                    <!--<td>Email</td>
                    <td>Telephone</td>-->
                    <td>Booking reminder (last sent)</td>
                    <td>Voucher provider</td>
                    <td/>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(booking, index) in waitingListBookings" :key="booking.id">
                    <td>
                        <input :checked="isChecked(booking.id, true)" type="checkbox"
                               @click="toggleCheckbox(booking.id, true)">
                    </td>

                    <td>
                        <a :title="booking.id" href @click.prevent="goToBooking(booking.id, true)">
                            {{ index + 1 }}
                        </a>
                    </td>

                    <td>
                        <span v-if="waitingListChildNames">
                            <a href
                               @click.prevent="goToChild(booking.childId)">{{
                                    waitingListChildNames[booking.childId]
                                }}</a>
                        </span>
                    </td>

                    <td>
                        <span v-if="waitingListParents[booking.childId]">
                            <a href
                               @click.prevent="goToParent(waitingListParents[booking.childId].id)">{{
                                    waitingListParents[booking.childId].firstName
                                }} {{
                                    waitingListParents[booking.childId].lastName
                                }}</a>
                            <br>
                            {{ waitingListParents[booking.childId].email }}
                            <br>
                            {{ waitingListParents[booking.childId].telephone }}
                        </span>
                    </td>

                    <td>
                        {{ booking.agreedPrice | formatCurrency }}
                    </td>

                    <!--<td>
                        <span v-if="waitingListParents[booking.childId]">
                            {{ waitingListParents[booking.childId].email }}
                        </span>
                    </td>

                    <td>
                        <span v-if="waitingListParents[booking.childId]">
                            {{ waitingListParents[booking.childId].telephone }}
                        </span>
                    </td>-->

                    <td>
                        <span>
                            <button ref="sendWaitingListBookingReminderBtn" class="btn btn-light btn-sm" type="button"
                                    @click="sendWaitingListBookingReminder(booking.id)">
                                Send
                            </button>

                            <small v-if="booking.bookingNotificationLastSent">
                                {{ getDate(booking.bookingNotificationLastSent * 1000) }}
                            </small>
                        </span>
                    </td>

                    <td>
                        {{ voucherCodeProviders[booking.franchiseVoucherCodeId] }}
                    </td>

                    <td class="text-end">
                        <input class="btn btn-light btn-sm" type="button" value="Add to bookings"
                               @click="addToBookings($event, booking.id)">
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div>
            <h2>New course dates email</h2>

            <button :disabled="isButtonDisabled(false) && isButtonDisabled(true)" class="btn btn-light" type="button"
                    @click="sendNewCourseDatesEmail">
                Send
            </button>

            <span class="ms-3">
                Last sent:
                <strong v-if="newCourseDatesEmailLastSent">{{ getDate(newCourseDatesEmailLastSent * 1000) }}</strong>
                <em v-if="!newCourseDatesEmailLastSent">
                    never
                </em>
            </span>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import restrictToUsers from '../../vue/directives/restrictToUsers'

export default {
    name: "FormControlOdpCourseDatesBookings",
    directives: {
        restrictToUsers,
    },
    props: {
        currentData: Object,
        field: Object,
        formId: String,
    },
    data() {
        return {
            bookings: [],
            waitingListBookings: [],
            selectedBookingsIds: [],
            selectedWaitingListIds: [],
            voucherCodeProviders: {},
            parentsEmailSending: false,
            confirmationEmailSending: false,
            parents: {},
            waitingListParents: {},
            childNames: {},
            waitingListChildNames: {},
        }
    },
    computed: {
        newCourseDatesEmailLastSent() {
            return this.currentData.newCourseDatesEmailLastSent
        },
        allCheckboxesChecked() {
            return this.bookings.length === this.selectedBookingsIds.length
        },
        allWaitingListCheckboxesChecked() {
            return this.waitingListBookings.length === this.selectedWaitingListIds.length
        },
        isTest() {
            return this.$store.state.sitename === 'outdoorsproject5e'
        },
        hasArchiveAccess() {
            return this.$store.getters['user/access']('odp_course_bookings', 'archive')
        }
    },
    asyncComputed: {
        courseType() {
            return this.$store.dispatch('itemData/get', {
                    tableName: 'odp_courses',
                    id: this.currentData.courseId
                })
                .then((obj) => {
                    return obj.courseType
                })
        }
    },
    created() {
        if (!this.currentData.id) {
            this.field.hidden = true
            return
        }

        this.field.replaceFormgroup = true

        this.setBookings()
        this.setWaitingListBookings()

        // Remove the newCourseDatesEmailLastSend field, as this is handled by this component.
        this.$store.commit(this.formId + '/setFieldProperty', {
            fieldName: 'newCourseDatesEmailLastSent',
            property: 'hidden',
            value: true
        })
    },
    watch: {
        bookings() {
            this.$nextTick(() => {
                $(this.$refs.tooltip).tooltip()
            })
        }
    },
    methods: {
        isNotPaid(amountPaid) {
            return (
                amountPaid === null
                // Decimal numbers were being provided as strings
                || parseFloat(amountPaid) === 0
            )
        },
        setBookings() {
            let voucherProviders = {}

            this.$store.dispatch('request/get', {
                    url: 'api/component/voucher_provider'
                })
                .then((obj) => {
                    obj.data.items.forEach((obj) => {
                        voucherProviders[obj.id] = obj.title
                    })

                    return this.$store.dispatch('request/get', {
                        url: 'api/component/franchise_voucher_code'
                    })
                })
                .then((obj) => {
                    let voucherCodeProviders = {}
                    obj.data.items.forEach((obj) => {
                        voucherCodeProviders[obj.id] = voucherProviders[obj.voucherProviderId]
                    })
                    this.voucherCodeProviders = voucherCodeProviders

                    return this.$store.dispatch('request/get', {
                        url: 'api/component/odp_course_bookings',
                        params: {
                            courseDateId: this.currentData.id,
                            hasExpired: 0,
                            archived: 0,
                            isArchived: 0,
                            field: 'id',
                        }
                    })
                })
                .then((obj) => {
                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'odp_course_bookings',
                        ids: obj.data.values
                    })
                })
                .then((objs) => {
                    if (objs) {
                        this.bookings = objs
                        if (objs) {
                            let childIds = objs.map(o => o.childId)
                            this.setChildNames(childIds)
                            this.setParentData(childIds)
                        }
                    }
                })
        },
        setWaitingListBookings() {
            this.$store.dispatch('request/get', {
                    url: 'api/component/odp_waiting_list',
                    params: {
                        courseDateId: this.currentData.id,
                        isArchived: 0,
                        field: 'id',
                    }
                })
                .then((obj) => {
                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'odp_waiting_list',
                        ids: obj.data.values
                    })
                })
                .then((objs) => {
                    this.waitingListBookings = objs
                    if (objs) {
                        let childIds = this.waitingListBookings.map(o => o.childId)
                        this.setChildNames(childIds, true)
                        this.setParentData(childIds, true)
                    }
                })
        },
        goToBooking(bookingId, waitingList) {
            let tableName = waitingList ? 'odp_waiting_list' : 'odp_course_bookings'
            window.location = '/#/' + tableName + '/' + bookingId
        },
        toggleCheckbox(bookingId, waitingList) {
            let array = waitingList ? this.selectedWaitingListIds : this.selectedBookingsIds
            let i = array.indexOf(bookingId)
            i === -1
                ? array.push(bookingId)
                : array.splice(i, 1)
        },
        selectAllCheckboxes(waitingList) {
            let array = waitingList ? this.selectedWaitingListIds : this.selectedBookingsIds
            let objs = waitingList ? this.waitingListBookings : this.bookings

            if (objs.length > array.length) {
                array = []
                objs.forEach((obj) => {
                    array.push(obj.id)
                })
            } else {
                array = []
            }

            waitingList
                ? this.selectedWaitingListIds = array
                : this.selectedBookingsIds = array
        },
        isChecked(bookingId, waitingList) {
            let array = waitingList ? this.selectedWaitingListIds : this.selectedBookingsIds
            return array.indexOf(bookingId) > -1
        },
        isButtonDisabled(waitingList) {
            let array = waitingList ? this.selectedWaitingListIds : this.selectedBookingsIds
            return array.length === 0
        },
        archiveBooking(bookingId) {
            this.removeBookingFromUi(bookingId)

            this.$store.dispatch('itemData/update', {
                tableName: 'odp_course_bookings',
                id: bookingId,
                data: {
                    archived: 1
                }
            })
        },
        cancelBooking(bookingId) {
            this.removeBookingFromUi(bookingId)

            this.$store.dispatch('request/post', {
                    url: this.$store.state.websiteEndpoint + '/controller/odp/cancel-booking',
                    postData: {
                        bookingId: bookingId
                    }
                })
                .then((obj) => {
                    console.log('bookingId', bookingId)
                    console.log('obj', obj)
                })
        },
        removeBookingFromUi(bookingId) {
            let i = this.bookings
                .map((e) => {
                    return e.id
                })
                .indexOf(bookingId)
            this.bookings.splice(i, 1)

            // Remove booking ID from the selected IDs.
            i = this.selectedBookingsIds.indexOf(bookingId)
            if (i > -1) {
                this.selectedBookingsIds.splice(i, 1)
            }
        },
        // Creates a new booking using a waiting list item's data,
        // deletes the waiting list item and then refreshes the tables.
        addToBookings(e, waitingListId) {
            // Disable the button so it cannot be clicked twice.
            e.target.setAttribute('disabled', '')

            let waitListItem

            // Load the waiting list item's data.
            this.$store.dispatch('itemData/get', {
                    tableName: 'odp_waiting_list',
                    id: waitingListId
                })
                .then((obj) => {
                    waitListItem = obj

                    return this.$store.dispatch('itemData/get', {
                        tableName: 'odp_course_dates',
                        id: obj.courseDateId
                    })
                })
                .then((obj) => {
                    // If the wait list item's course date has already started then ignore the wait list's agreedPrice
                    // and use the course date's current price. This caters for when course dates have their price
                    // dropped weekly once a course has started. https://omcore.zendesk.com/agent/tickets/12766
                    const now = Math.ceil(Date.now() / 1000)
                    const hasStarted = now >= obj.startDate
                    const agreedPrice = hasStarted ? obj.price : waitListItem.agreedPrice

                    // Create a new booking using the waiting list item's data.
                    return this.$store.dispatch('request/post', {
                        url: 'api/component/odp_course_bookings',
                        postData: {
                            childId: waitListItem.childId,
                            courseDateId: waitListItem.courseDateId,
                            amountPaid: 0.00,
                            // todo - These are to avoid 'paymentReminderLastSent' doesn't have a default value error.
                            // The database needs fixing.
                            paymentReminderLastSent: 0,
                            hasExpired: 0,
                            archived: 0,
                            franchiseVoucherCodeId: waitListItem.franchiseVoucherCodeId,
                            agreedPrice: agreedPrice,
                            permissionToWalkHome: waitListItem.permissionToWalkHome
                        }
                    })
                })
                .then(() => {
                    return this.$store.dispatch('itemData/update', {
                        tableName: 'odp_waiting_list',
                        id: waitingListId,
                        data: {
                            isArchived: 1
                        }
                    })
                })
                .then(() => {
                    // Reload the tables.
                    this.setBookings()
                    this.setWaitingListBookings()
                })
        },
        sendPaymentReminder(e, bookingId) {

            // Disable the button so users can't accidentally send it twice.
            this.$refs.sendPaymentReminderBtn[0].setAttribute('disabled', '')

            this.$store.dispatch('itemData/get', {
                    tableName: 'odp_course_bookings',
                    id: bookingId
                })
                .then((obj) => {
                    // todo - This only works the first time. After the UI has updated the date once then
                    // subsequent clicks fails to update it.
                    // Force ng-if to toggle the UI and present the new date.
                    obj.paymentReminderLastSent = false

                    this.$store.dispatch('itemData/update', {
                        tableName: 'odp_course_bookings',
                        id: bookingId,
                        data: {
                            // Prevents booking from expiring.
                            // The reasoning behind this is that if ODP send a reminder then it shouldn't expire.
                            amountPaid: 0.00,
                            paymentReminderLastSent: Math.ceil(Date.now() / 1000)
                        }
                    })

                    let params = {
                        //subject: 'The Outdoors Project – spaces now available',
                        //subject: 'New club spaces now available - Action required -',
                        subject: 'Waiting list - a space has come available!',
                        templateId: 17,
                        templateVars: {
                            bookingId: bookingId
                        }
                    }
                    this.getBookingsParent(bookingId)
                        .then((parent) => {
                            params.to = parent.email
                            return this.getBookingsFranchise(bookingId)
                        })
                        .then((franchise) => {
                            params.replyTo = franchise.email
                            return this.sendEmail(params)
                        })
                        .then(() => {
                            params.to = 'info@theoutdoorsproject.co.uk'
                            delete params.replyTo
                            return this.sendEmail(params)
                        })
                })
        },
        sendVoucherPaymentReminder(e, bookingId) {
            // Disable the button so users can't accidentally send it twice.
            this.$refs.sendVoucherPaymentReminderBtn[0].setAttribute('disabled', '')

            this.$store.dispatch('itemData/get', {
                    tableName: 'odp_course_bookings',
                    id: bookingId
                })
                .then((obj) => {
                    // Update voucherPaymentReminderLastSent
                    obj.voucherPaymentReminderLastSent = false
                    this.$store.dispatch('itemData/update', {
                        tableName: 'odp_course_bookings',
                        id: bookingId,
                        data: {
                            voucherPaymentReminderLastSent: Math.ceil(Date.now() / 1000)
                        }
                    })

                    let params = {
                        subject: 'Voucher payment reminder - ACTION REQUIRED',
                        templateId: 128,
                        templateVars: {
                            bookingId: bookingId
                        }
                    }
                    this.getBookingsParent(bookingId)
                        .then((parent) => {
                            params.to = parent.email
                            return this.getBookingsFranchise(bookingId)
                        })
                        .then((franchise) => {
                            params.replyTo = franchise.email
                            return this.sendEmail(params)
                        })
                        .then(() => {
                            params.to = 'info@theoutdoorsproject.co.uk'
                            delete params.replyTo
                            return this.sendEmail(params)
                        })
                })
        },
        sendWaitingListBookingReminder(bookingId) {

            // Disable the button so users can't accidentally send it twice.
            this.$refs.sendWaitingListBookingReminderBtn[0].setAttribute('disabled', '')

            this.$store.dispatch('itemData/update', {
                tableName: 'odp_waiting_list',
                id: bookingId,
                data: {
                    bookingNotificationLastSent: Math.ceil(Date.now() / 1000)
                }
            })

            let params = {
                templateId: 19,
                templateVars: {
                    waitingListId: bookingId
                }
            }
            this.getBookingsParent(bookingId, true)
                .then((parent) => {
                    params.to = parent.email
                    params.subject = parent.firstName + ', The Outdoors Project - New Course Dates Available'
                    return this.getBookingsFranchise(bookingId, true)
                })
                .then((franchise) => {
                    params.replyTo = franchise.email
                    return this.sendEmail(params)
                })
                .then(() => {
                    params.to = 'info@theoutdoorsproject.co.uk'
                    delete params.replyTo
                    return this.sendEmail(params)
                })
                .then(() => {
                    this.setWaitingListBookings()
                })
        },
        sendParentsEmail(waitingList) {
            this.parentsEmailSending = true

            // Create a notification and retrieve its ID. The ID is used in an alert
            // so that the alert can be updated
            this.$store.dispatch('request/post', {
                    url: 'api/notification',
                    postData: {
                        title: 'Email parents',
                        data: {
                            //title: 'Email parents',
                            message: 'Starting&hellip;',
                            percentage: 0,
                        },
                    }
                })
                .then((obj) => {
                    const notificationId = obj.data.id

                    this.$store.dispatch('toasts/add', {
                        title: 'Email parents',
                        body: 'Starting&hellip;',
                        disableAutoHide: true,
                        percentage: 0,
                        notificationId: notificationId,
                        createdBy: this.$store.state.user.user.id
                    })

                    const selectedIds = waitingList ? this.selectedWaitingListIds : this.selectedBookingsIds
                    const tableName = waitingList ? 'odp_waiting_list' : 'odp_course_bookings'

                    return this.$store.dispatch('request/post', {
                        url: 'api/odp/send-parents-emails',
                        postData: {
                            notificationId: notificationId,
                            bookingIds: selectedIds,
                            tableName: tableName
                        }
                    })
                })
                .then(() => {
                    this.parentsEmailSending = false
                })
        },
        sendConfirmationEmail() {
            this.confirmationEmailSending = true

            let franchise
            let bookings
            let franchiseVoucherCodes
            let voucherProviders
            let courseDate
            let school
            let course
            let children
            let parents
            let childIds

            this.getBookingsFranchise(this.selectedBookingsIds[0])
                .then((obj) => {
                    franchise = obj

                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'odp_course_bookings',
                        ids: this.selectedBookingsIds
                    })
                })
                .then((objs) => {
                    bookings = objs

                    let franchiseVoucherCodeIds = bookings.map(o => o.franchiseVoucherCodeId)
                    franchiseVoucherCodeIds = [...new Set(franchiseVoucherCodeIds)] // Remove dupes

                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'franchise_voucher_code',
                        ids: franchiseVoucherCodeIds
                    })
                })
                .then((objs) => {
                    franchiseVoucherCodes = objs

                    let voucherProviderIds = franchiseVoucherCodes.map(o => o.voucherProviderId)
                    voucherProviderIds = [...new Set(voucherProviderIds)] // Remove dupes

                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'voucher_provider',
                        ids: voucherProviderIds
                    })
                })
                .then((objs) => {
                    voucherProviders = objs

                    return this.$store.dispatch('itemData/get', {
                        tableName: 'odp_course_dates',
                        id: bookings[0].courseDateId
                    })
                })
                .then((obj) => {
                    courseDate = obj

                    return this.$store.dispatch('itemData/get', {
                        tableName: 'odp_schools',
                        id: courseDate.schoolId
                    })
                })
                .then((obj) => {
                    school = obj

                    return this.$store.dispatch('itemData/get', {
                        tableName: 'odp_courses',
                        id: courseDate.courseId
                    })
                })
                .then((obj) => {
                    course = obj

                    childIds = bookings.map(o => o.childId)

                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'odp_children',
                        ids: childIds
                    })
                })
                .then((objs) => {
                    children = objs

                    const parentIds = this.$store.getters['categories/getContentIdsMulti']('m_registrants_registrants', 'children', childIds)

                    return this.$store.dispatch('itemData/preload', {
                        tableName: 'm_registrants_registrants',
                        ids: parentIds
                    })
                })
                .then((objs) => {
                    parents = objs

                    const emailItems = []
                    bookings.forEach((booking) => {

                        const child = children.find(o => o.id === booking.childId)
                        const childName = child.firstName + ' ' + child.lastName

                        const parentId = this.$store.getters['categories/getContentIds']('m_registrants_registrants', 'children', booking.childId)[0]
                        const parent = parents.find(o => o.id === parentId)

                        const subject = 'The Outdoors Project, Booking confirmation #' + booking.id + ' - ' + childName

                        const franchiseVoucherCode = franchiseVoucherCodes.find(o => o.id === booking.franchiseVoucherCodeId)
                        const voucherProvider = voucherProviders.find(o => o.id === franchiseVoucherCode?.voucherProviderId)

                        const templateVars = {
                            bookingId: booking.id,
                            booking: booking,
                            child: child,
                            customer: parent,
                            voucherProvider: voucherProvider,
                            franchiseVoucherCode: franchiseVoucherCode,
                            course: course,
                            courseDate: courseDate,
                            school: school
                        }

                        // CUSTOMER EMAIL

                        emailItems.push({
                            to: parent.email,
                            subject: subject,
                            templateId: 18,
                            templateVars: templateVars,
                            replyTo: franchise.email
                        })

                        // INTERNAL EMAIL

                        let to = franchise.email
                        // Also send to the default address, if different to the franchise's
                        let defaultEmailAddress = 'info@theoutdoorsproject.co.uk'
                        if (to !== defaultEmailAddress) {
                            to += ' ' + defaultEmailAddress
                        }
                        emailItems.push({
                            to: to,
                            subject: subject,
                            templateId: 18,
                            templateVars: templateVars
                        })
                    })

                    return this.sendEmailMulti('Confirmation emails', emailItems)
                })
                .then(() => {
                    this.confirmationEmailSending = false
                })
        },
        openBookingTable() {

        },
        sendWaitingListConfirmationEmail() {
            this.selectedWaitingListIds.forEach((waitingListId) => {
                this.getBookingsParent(waitingListId, true)
                    .then((parent) => {
                        return this.sendEmail({
                            to: parent.email,
                            subject: parent.firstName + ', The Outdoors Project - New Course Dates Available',
                            templateId: 19,
                            templateVars: {
                                waitingListId: waitingListId
                            }
                        })
                    })
            })

            this.$store.dispatch('toasts/add', {
                body: 'Emails sent.'
            })
        },
        sendNewCourseDatesEmail() {
            let subject = 'Next Term After School Club - Now Booking'

            // Send to bookings
            this.selectedBookingsIds.forEach((bookingId) => {
                this.getBookingsParent(bookingId)
                    .then((parent) => {
                        return this.sendEmail({
                            to: parent.email,
                            subject: subject,
                            templateId: 20,
                            templateVars: {
                                bookingId: bookingId
                            }
                        })
                    })
            })

            // Send to waiting list
            this.selectedWaitingListIds.forEach((waitingListId) => {
                this.getBookingsParent(waitingListId, true)
                    .then((parent) => {
                        return this.sendEmail({
                            to: parent.email,
                            subject: subject,
                            templateId: 20,
                            templateVars: {
                                waitingListId: waitingListId
                            }
                        })
                    })
            })

            // Update the last sent timestamp.
            let date = Math.ceil(Date.now() / 1000)
            this.currentData.newCourseDatesEmailLastSent = date
            this.$store.dispatch('itemData/update', {
                tableName: 'odp_course_dates',
                id: this.currentData.id,
                data: {
                    newCourseDatesEmailLastSent: date
                }
            })
        },
        sendNewHolidayClubEmail() {
            this.selectedBookingsIds.forEach((bookingId) => {
                let params = {
                    to: '',
                    subject: 'Holiday Clubs on sale. Book Now',
                    templateId: 21,
                    templateVars: {
                        bookingId: bookingId
                    }
                }
                this.getBookingsParent(bookingId)
                    .then((parent) => {
                        params.to = parent.email
                        return this.getBookingsFranchise(bookingId)
                    })
                    .then((franchise) => {
                        params.replyTo = franchise.email
                        this.sendEmail(params)
                    })
            })

            this.$store.dispatch('toasts/add', {
                body: 'Emails sent.'
            })
        },
        archiveWaitListItems() {
            if (confirm('Are you sure?')) {
                this.selectedWaitingListIds.forEach((waitingListId) => {
                    // Remove booking from the UI.
                    let i = this.waitingListBookings
                        .map((e) => {
                            return e.id
                        })
                        .indexOf(waitingListId)
                    this.waitingListBookings.splice(i, 1)

                    this.$store.dispatch('itemData/update', {
                        tableName: 'odp_waiting_list',
                        id: waitingListId,
                        data: {
                            isArchived: 1
                        }
                    })
                })
                this.selectedWaitingListIds.length = 0
            }
        },
        addMultipleToBookings(e) {
            if (confirm('Are you sure?')) {
                this.selectedWaitingListIds.forEach((waitingListId) => {
                    this.addToBookings(e, waitingListId)
                })
                this.selectedWaitingListIds.length = 0
            }
        },
        sendWaitListReminders() {
            if (confirm('Are you sure?')) {
                this.selectedWaitingListIds.forEach((waitingListId) => {
                    this.sendWaitingListBookingReminder(waitingListId)
                })
                this.selectedWaitingListIds.length = 0
            }
        },
        getBookingsFranchise(bookingId, isWaitingList) {
            let tableName = isWaitingList ? 'odp_waiting_list' : 'odp_course_bookings'

            return this.$store.dispatch('itemData/get', {
                    tableName: tableName,
                    id: bookingId
                })
                .then((obj) => {
                    return this.$store.dispatch('itemData/get', {
                        tableName: 'odp_course_dates',
                        id: obj.courseDateId
                    })
                })
                .then((obj) => {
                    return this.$store.dispatch('itemData/get', {
                        tableName: 'odp_courses',
                        id: obj.courseId
                    })
                })
                .then((obj) => {
                    return this.$store.dispatch('itemData/get', {
                        tableName: 'odp_franchises',
                        id: obj.franchiseId
                    })
                })
        },
        openWorksheet(templateId, waitingList, documentTitle) {
            this.$store.dispatch('request/get', {
                    url: 'api/controller/document/' + templateId,
                    params: {
                        courseDateId: this.currentData.id,
                        bookingIds: waitingList ? [] : this.selectedBookingsIds,
                        waitListIds: waitingList ? this.selectedWaitingListIds : []
                    }
                })
                .then((o) => {
                    const html = o.data
                    const newWindow = window.open()
                    newWindow.document.write('<title>' + documentTitle + '</title>')
                    newWindow.document.write(html)
                })
        },
        showNotes(row) {
            this.$store.dispatch('modals/show', {
                componentName: 'OdpPaymentNoteModal',
                obj: {
                    row: row,
                }
            })
        },
        getBookingsParent(bookingId, waitingList) {
            return new Promise((resolve, reject) => {
                // load the parent email of the booking's child.
                let tableName = waitingList ? 'odp_waiting_list' : 'odp_course_bookings'

                this.$store.dispatch('itemData/get', {
                        tableName: tableName,
                        id: bookingId
                    })
                    .then((obj) => {
                        let parentIds = this.$store.getters['categories/getContentIds']('m_registrants_registrants', 'children', obj.childId)
                        if (parentIds.length) {
                            return this.$store.dispatch('itemData/get', {
                                tableName: 'm_registrants_registrants',
                                id: parentIds[0]
                            })
                        }
                    })
                    .then((obj) => {
                        resolve(obj)
                    })
            })
        },
        sendEmail(params) {
            return this.$store.dispatch('request/post', {
                url: 'api/email/send',
                postData: params
            })
        },
        sendEmailMulti(notificationTitle, emails) {
            return this.$store.dispatch('request/post', {
                    url: 'api/notification',
                    postData: {
                        title: 'Bulk email',
                        data: {
                            title: notificationTitle,
                            message: 'Starting&hellip;',
                            percentage: 0,
                        },
                    }
                })
                .then((obj) => {
                    const notificationId = obj.data.id

                    this.$store.dispatch('toasts/add', {
                        title: 'Bulk email',
                        disableAutoHide: true,
                        percentage: 0,
                        notificationId: notificationId
                    })

                    return this.$store.dispatch('request/post', {
                        url: 'api/email/send-multi',
                        postData: {
                            notificationId: notificationId,
                            emails: emails
                        }
                    })
                })
        },
        setChildNames(childIds, waitingList) {
            if (childIds.length) {
                // Preloading the data removes the need to re-request it when sending the emails.
                this.$store.dispatch('itemData/preload', {
                        tableName: 'odp_children',
                        ids: childIds
                    })
                    .then((objs) => {
                        let childNames = {}
                        objs.forEach((obj) => {
                            childNames[obj.id] = obj.firstName + ' ' + obj.lastName
                        })

                        waitingList
                            ? this.waitingListChildNames = childNames
                            : this.childNames = childNames
                    })
            }
        },
        setParentData(childIds, waitingList) {
            if (childIds.length) {

                waitingList
                    ? this.waitingListParents = {}
                    : this.parents = {}

                // Create an array of the parent IDs for loading in a single request.
                let parentIds = []
                // Create a map of the parent -> child IDs for assigning parent objects to their child IDs.
                let childParentIds = {}
                childIds.forEach((childId) => {
                    let parentId = this.$store.getters['categories/getContentIds']('m_registrants_registrants', 'children', childId)[0]
                    parentIds.push(parentId)
                    childParentIds[childId] = parseInt(parentId)
                })

                this.$store.dispatch('itemData/preload', {
                        tableName: 'm_registrants_registrants',
                        ids: parentIds
                    })
                    .then((objs) => {
                        let data = {}
                        for (const [childId, parentId] of Object.entries(childParentIds)) {
                            data[childId] = objs.filter(obj => obj.id === parseInt(parentId))[0]
                        }

                        waitingList
                            ? this.waitingListParents = data
                            : this.parents = data
                    })
            }
        },
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YYYY, HH:mm')
        },
        openPaymentLogModal(bookingId) {
            this.$store.dispatch('modals/show', {
                componentName: 'OdpPaymentLogModal',
                obj: {
                    bookingId: bookingId,
                }
            })
        },
        goToChild(childId) {
            this.$router.push({
                name: 'form',
                params: {
                    component: 'odp_children',
                    id: childId
                }
            })
        },
        goToParent(parentId) {
            this.$router.push({
                name: 'form',
                params: {
                    component: 'm_registrants_registrants',
                    id: parentId
                }
            })
        }
    },
    filters: {
        formatCurrency(value) {
            if (value) {
                return new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'gbp'
                })
                    .format(value)
            }
        }
    }
}
</script>

<style scoped>

</style>