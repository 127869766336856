<script>
import FormControlTypeCheckbox from '../form-control-type/FormControlTypeCheckbox'

export default {
    name: "FormControlModulestructurePassword",
    extends: FormControlTypeCheckbox,
    created() {
        if (this.currentData.type !== 'text') {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>