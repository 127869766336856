var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.alphaMode
        ? _c("div", { staticClass: "d-flex h-100" }, [
            _c(
              "main",
              { staticClass: "flex-fill w-100 overflow-hidden" },
              [
                _vm.editor && _vm.show
                  ? _c("CodemirrorEditor", { attrs: { data: _vm.editor } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "aside",
              { staticClass: "w-50" },
              [
                _c("ThemesEditorPreviewPane", {
                  staticClass: "themes-editor-preview-pane",
                  attrs: { id: _vm.editor.id, code: _vm.editor.code },
                }),
              ],
              1
            ),
          ])
        : _vm.editor && _vm.show
        ? _c("CodemirrorEditor", {
            staticClass: "themes-editor--codemirror--main",
            attrs: { data: _vm.editor },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ThemesEditorFooter", {
        attrs: { editor: _vm.editor, "save-editor": _vm.saveEditor },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }