var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid dashboard" }, [
    _c("h1", { staticClass: "h4 my-4" }, [_vm._v("Dashboard")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [_c("DashboardWelcome"), _vm._v(" "), _c("DashboardStateHistory")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [_c("DashboardActivityStream")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }