export default {
    namespaced: true,
    state: {
        roles: {},
        options: [
            {name: 'add', title: 'Add'},
            {name: 'edit', title: 'Edit'},
            {name: 'archive', title: 'Archive'},
            {name: 'delete', title: 'Delete'},
            {name: 'publish', title: 'Publish'},
            {name: 'export', title: 'Export'}
        ],
        privileges: {}
    },
    mutations: {
        roles(state, roles) {
            state.roles = roles
        },
        privileges(state, privileges) {
            state.privileges = privileges
        },
        initRole(state, roleId) {
            if (!state.privileges[roleId]) {
                this._vm.$set(state.privileges, roleId, {})
            }
        },
        togglePrivilege(state, {roleId, componentId, privilege}) {
            if (!state.privileges[roleId]) {
                this._vm.$set(state.privileges, roleId, {})
            }
            if (state.privileges[roleId][componentId] === undefined) {
                this._vm.$set(state.privileges[roleId], componentId, [])
            }
            
            const i = state.privileges[roleId][componentId]?.indexOf(privilege)
            const privileges = state.privileges[roleId][componentId]
            if (i === -1) {
                privileges.push(privilege)
                // Without sorting the privileges, newly added privileges will always be placed at the end of the array.
                // This would result in the save button being active if a user toggled a privilege. For example,
                // toggling 'edit' in the following:
                // Before: ['edit', 'delete', 'add'], After: ['delete', 'add', 'edit']
                // By sorting them they'll always be the same: ['add', 'delete', 'edit']
                privileges.sort()
            } else {
                privileges.splice(i, 1)
                
                if (Object.keys(privileges).length === 0) {
                    delete state.privileges[roleId][componentId]
                }
                
                // This breaks the reference between the form and the roles data
                //if (Object.keys(state.privileges[roleId]).length === 0) {
                    //delete state.privileges[roleId]
                //}
            }
        }
    },
    getters: {
        getPrivileges: (state) => (roleId, componentId) => {
            if (state.privileges[roleId] === undefined) {
                this._vm.$set(state.privileges, roleId, {})
            }
            if (state.privileges[roleId][componentId] === undefined) {
                this._vm.$set(state.privileges[roleId], componentId, [])
            }
            
            return state.privileges[roleId][componentId]
        }
    },
    actions: {
        init({state, commit, rootState, dispatch, rootGetters}) {
            let roles = {}
            
            let user = rootState.user.user
            
            // todo - This should be split into computed methods etc. if possible.
            rootState.components.items.forEach((obj) => {
                let rolesGroup = obj.rolesGroup
                if (
                    obj.roles
                    && rolesGroup
                ) {
                    if (roles[rolesGroup] === undefined) {
                        roles[rolesGroup] = {}
                    }
                    if (roles[rolesGroup][obj.title] === undefined) {
                        roles[rolesGroup][obj.title] = {}
                    }
                    
                    state.options.forEach((option) => {
                        let name = option.name
                        let title = option.title
                        if (
                            (name === 'add' && obj.addNew)
                            || (name === 'delete' && obj.deleteOption)
                            || (name === 'publish' && obj.showStatus)
                            || ['add', 'delete', 'publish'].indexOf(name) === -1
                        ) {
                            if (
                                user.administrator
                                || rootGetters['user/access'](obj.tableName, name)
                            ) {
                                roles[rolesGroup][obj.title][name] = {
                                    label: title,
                                    name: name,
                                    tableName: obj.tableName
                                }
                            }
                        }
                    })
                }
            })
            
            commit('roles', roles)
            
            dispatch('setPrivileges')
        },
        setPrivileges({dispatch, commit}) {
            dispatch('request/get', {
                url: 'api/roles',
            }, {root: true})
                .then((o) => {
                    commit('privileges', o.data.privileges)
                })
        }
    },
}