var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("span", [_vm._v(_vm._s(_vm.item.title))]),
    _vm._v(" "),
    _vm.count !== undefined
      ? _c(
          "span",
          {
            staticClass: "badge pull-right",
            class: { "bg-secondary": !_vm.count, "bg-success": _vm.count },
            attrs: { title: "The number of variations using this variable." },
          },
          [_vm._v(_vm._s(_vm.count))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }