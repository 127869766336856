export default {
    namespaced: true,
    state: {
        promises: {},
        data: {},
    },
    mutations: {},
    getters: {
        _getKey: () => (componentId, itemId) => {
            return componentId + ':' + itemId
        },
    },
    actions: {
        init({dispatch}) {
            // Lock items as soon as the browser loads.
            // Without this there would be a 1s delay before the avatar appeared
            dispatch('_setData')
            
            setInterval(() => {
                dispatch('_setData')
            }, 1000)
        },
        start({state, getters, dispatch, rootState}, {componentId, itemId}) {
            // New item form
            if (itemId === 0) {
                return
            }
            
            dispatch('stop', {
                componentId: componentId,
                itemId: itemId
            })
            
            let key = getters._getKey(componentId, itemId)
            
            state.promises[key] = setInterval(() => {
                let userId = rootState.user.user.id
                if (userId) {
                    dispatch('request/post', {
                        url: 'api/user-tracking',
                        postData: {
                            userId: userId,
                            componentId: componentId,
                            itemId: itemId
                        }
                    }, {root: true})
                        .then(() => {
                            //console.log('post user-tracking');
                        })
                }
            }, 1000)
        },
        stop({state, dispatch, getters}, {componentId, itemId}) {
            let key = getters._getKey(componentId, itemId)
            if (state.promises[key]) {
                clearInterval(state.promises[key])
                delete state.promises[key]
                
                dispatch('request/delete', {
                    url: 'api/user-tracking/' + key
                }, {root: true})
                    .then((obj) => {
                        //console.log('obj', obj);
                    })
            }
        },
        // Used to check if an item is in use by another user.
        // Returns a promise that resolves to either a user's ID or false.
        inUse({dispatch}, {componentId, itemId}) {
            return dispatch('_setData')
                .then(() => {
                    return dispatch('checkKeys', {
                        componentId: componentId,
                        itemId: itemId,
                    })
                })
        },
        checkKeys({state, getters}, {componentId, itemId}) {
            let key = getters._getKey(componentId, itemId)
            let keys = Object.keys(state.data)
            return keys.indexOf(key) > -1 ? state.data[key] : false
        },
        _setData({state, dispatch, rootState}) {
            let userId = rootState.user.user.id
            if (userId) {
                return dispatch('request/get', {
                    url: 'api/user-tracking',
                    params: {
                        userId: userId,
                    }
                }, {root: true})
                    .then((obj) => {
                        let items = obj.data.items
                        items.forEach((item) => {
                            let key = item.componentId + ':' + item.itemId
                            state.data[key] = item.userId
                        })
                    })
            }
        }
    },
}