<template>
    <div class="themes-editor-nav-section overflow-y-scroll h-100">
        <HelpBox v-if="sectionData.helpText && alphaMode" :id="helpBlockId">
            <div class="mb-1 fw-bold">{{ sectionData.title }}</div>
            <div v-html="sectionData.helpText"></div>
        </HelpBox>

        <ThemesEditorNavLink v-for="item in items" :key="item.id" :active-editor-key="activeEditorKey" :item="item"/>

        <!-- Add some white space to the bottom of the scrollable area -->
        <div class="my-5 py-5">&nbsp;</div>
    </div>
</template>

<script>
import HelpBox from '../../common/HelpBox'
import ThemesEditorNavLink from './ThemesEditorNavLink'

export default {
    name: "ThemesEditorNavSection",
    components: {
        HelpBox,
        ThemesEditorNavLink,
    },
    computed: {
        alphaMode() {
            return this.$store.state.alphaMode
        },
        activeEditorKey() {
            return this.$store.state.themes.activeEditorKey
        },
        items() {
            return this.$store.state.themes.items
        },
        sectionName() {
            return this.$store.state.themes.sectionName
        },
        helpBlockId() {
            return 'themesEditorNavSection-' + this.sectionName
        },
        sectionData() {
            return this.$store.getters['themes/getSectionsSettings'](this.sectionName)
        }
    }
}
</script>

<style scoped>

</style>