var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.locations, function (location) {
      return _vm.hiddenLocationIds.indexOf(location.id) === -1
        ? _c(
            "div",
            { key: location.id, staticClass: "d-flex align-items-center mb-2" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentData.shipping[location.id]["in_use"],
                    expression: "currentData.shipping[location.id]['in_use']",
                  },
                ],
                attrs: { id: "shipping-" + location.id, type: "checkbox" },
                domProps: {
                  checked: Array.isArray(
                    _vm.currentData.shipping[location.id]["in_use"]
                  )
                    ? _vm._i(
                        _vm.currentData.shipping[location.id]["in_use"],
                        null
                      ) > -1
                    : _vm.currentData.shipping[location.id]["in_use"],
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.currentData.shipping[location.id]["in_use"],
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.currentData.shipping[location.id],
                              "in_use",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.currentData.shipping[location.id],
                              "in_use",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.currentData.shipping[location.id],
                          "in_use",
                          $$c
                        )
                      }
                    },
                    function ($event) {
                      return _vm.syncLocations(location.id)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.currentData.shipping[location.id]["shipping_fee"],
                    expression:
                      "currentData.shipping[location.id]['shipping_fee']",
                  },
                ],
                staticClass: "form-control ms-2 w-auto",
                attrs: {
                  readonly: !_vm.currentData.shipping[location.id]["in_use"],
                  size: "7",
                  type: "text",
                },
                domProps: {
                  value: _vm.currentData.shipping[location.id]["shipping_fee"],
                },
                on: {
                  click: function ($event) {
                    _vm.currentData.shipping[location.id]["in_use"] = true
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.currentData.shipping[location.id],
                      "shipping_fee",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ms-2" }, [
                _vm._v(" " + _vm._s(location.location)),
              ]),
            ]
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }