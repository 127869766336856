<template>
    <code v-if="path">{{ path }}</code>
</template>

<script>
export default {
    name: "PagePath",
    props: {
        pageId: Number,
        pageContentId: Number,
        suffix: String
    },
    asyncComputed: {
        path() {
            if (
                !this.pageId
                && !this.pageContentId
            ) {
                return ''
            }

            // todo - This should be refactored into the pagePath VueX module
            if (this.pageContentId) {
                let pageId = this.$store.state.pageContentData.items.filter((obj) => {
                    return obj.id === this.pageContentId
                })[0].pageId

                return this.$store.dispatch('pagePath/get', {
                        pageId: pageId
                    })
                    .then((path) => {
                        return path + '#' + this.pageContentId
                    })
            }

            return this.$store.dispatch('pagePath/get', {
                    pageId: this.pageId
                })
                .then((path) => {
                    return path
                })
        }
    }
}
</script>

<style scoped>

</style>