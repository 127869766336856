var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.typeOptions.length
    ? _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.operator,
              expression: "item.operator",
            },
          ],
          staticClass: "form-select form-select-sm d-inline-block w-auto",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.item,
                "operator",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { attrs: { selected: "", disabled: "", value: "" } }, [
            _vm._v("Select…"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.typeOptions, function (option) {
            return _c("option", { domProps: { value: option.value } }, [
              _vm._v(_vm._s(option.text)),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }