<template>
    <select
        v-model="value"
        class="form-select"
    >
        <option value="">Select…</option>
        <optgroup v-for="(group, name) in optionGroups" :label="name">
            <option v-for="(props, value) in group" :value="value">
                {{ props.title }}
            </option>
        </optgroup>
    </select>
</template>

<script>
export default {
    name: "FormControlAccountSettingsDefaultContentLayout",
    props: {
        field: Object,
        value: String,
    },
    data() {
        return {
            optionGroups: this.$store.state.pageContentLayouts.layouts,
        }
    }
}
</script>

<style scoped>

</style>