var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "mb-3",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.createTag.apply(null, arguments)
          },
        },
      },
      [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.tagStr,
                expression: "tagStr",
              },
            ],
            staticClass: "form-control",
            attrs: { "ng-select": "", type: "text" },
            domProps: { value: _vm.tagStr },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.tagStr = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      _vm._l(_vm.tags, function (tag, index) {
        return _c(
          "span",
          {
            key: tag.id,
            staticClass:
              "me-2 bg-dark text-light border rounded p-1 ps-2 d-flex align-items-center",
          },
          [
            _vm._v("\n            " + _vm._s(tag.tag) + "\n            "),
            _c("button", {
              staticClass: "btn-close btn-close-white ms-1",
              attrs: { "aria-label": "Close", type: "button" },
              on: {
                click: function ($event) {
                  return _vm.removeTag(index)
                },
              },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn btn-light", attrs: { type: "submit" } },
      [_c("i", { staticClass: "bi-plus-lg" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }