<template>
    <div v-if="data">
        <div class="p-3 text-center">
            <div class="btn-group">
                <button v-for="year in years" :key="year" :class="{ 'active': selectedYear === year }"
                        class="btn btn-light" type="button" @click="setYear(year)">
                    {{ year }}
                </button>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <table class="table table-condensed table-striped">
                    <thead>
                    <tr>
                        <th>Month</th>
                        <th class="text-end">Orders</th>
                        <th class="text-end">Net</th>
                        <th class="text-end">VAT</th>
                        <th class="text-end">Gross</th>
                        <th class="text-end">Shipping</th>
                        <th class="text-end">Total</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(monthName, i) in monthNames" v-if="monthTotals" :key="i">
                        <td>
                            <a v-if="monthTotals[i]" href @click.prevent="showMonth(i)">
                                {{ monthName }}
                            </a>
                            <span v-else>{{ monthName }}</span>
                        </td>
                        <td class="text-end">
                            {{ monthTotals[i] && monthTotals[i].orders ? monthTotals[i].orders : 0 }}
                        </td>
                        <td class="text-end">
                            &pound;{{ (monthTotals[i] && monthTotals[i].net ? monthTotals[i].net : 0) | numberFormat }}
                        </td>
                        <td class="text-end">
                            &pound;{{ (monthTotals[i] && monthTotals[i].tax ? monthTotals[i].tax : 0) | numberFormat }}
                        </td>
                        <td class="text-end">
                            &pound;{{
                                (monthTotals[i] && monthTotals[i].gross ? monthTotals[i].gross : 0) | numberFormat
                            }}
                        </td>
                        <td class="text-end">
                            &pound;{{
                                (monthTotals[i] && monthTotals[i].shipping ? monthTotals[i].shipping : 0) |
                                    numberFormat
                            }}
                        </td>
                        <td class="text-end">
                            &pound;{{
                                (monthTotals[i] && monthTotals[i].total ? monthTotals[i].total : 0) | numberFormat
                            }}
                        </td>
                    </tr>
                    </tbody>

                    <tfoot v-if="yearTotals">
                    <tr>
                        <th>Total</th>
                        <th class="text-end">{{ yearTotals.orders }}</th>
                        <th class="text-end">&pound;{{ yearTotals.net | numberFormat }}</th>
                        <th class="text-end">&pound;{{ yearTotals.tax | numberFormat }}</th>
                        <th class="text-end">&pound;{{ yearTotals.gross | numberFormat }}</th>
                        <th class="text-end">&pound;{{ yearTotals.shipping | numberFormat }}</th>
                        <th class="text-end">&pound;{{ yearTotals.total | numberFormat }}</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "SalesReports",
    data() {
        return {
            selectedYear: new Date().getFullYear(),
            monthNames: [
                'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
                'November', 'December'
            ],
        }
    },
    created() {
        this.$store.commit('navigationComponent', 'm_basket_reports')
    },
    asyncComputed: {
        data() {
            return this.$store.dispatch('request/post', {
                    url: 'api/OrdersController/getReportsData',
                    postData: {
                        year: this.selectedYear
                    }
                })
                .then((obj) => {
                    return obj.data
                })
        }
    },
    computed: {
        years() {
            return this.data.years
        },
        monthTotals() {
            return this.data.monthTotals
        },
        yearTotals() {
            return this.data.yearTotals
        },
    },
    filters: {
        numberFormat(value) {
            return new Intl.NumberFormat().format(value)
        }
    },
    methods: {
        requestData() {
            this.$store.dispatch('request/post', {
                    url: 'api/OrdersController/getReportsData',
                    postData: {
                        year: this.selectedYear
                    }
                })
                .then((obj) => {
                    this.years = obj.data.years
                    this.monthTotals = obj.data.monthTotals
                    this.yearTotals = obj.data.yearTotals
                })
        },
        setYear(year) {
            this.selectedYear = year
        },
        showMonth(i) {
            this.$store.dispatch('modals/show', {
                componentName: 'OrdersReportsModal',
                obj: {
                    month: i + 1,
                    year: this.selectedYear,
                    monthName: this.monthNames[i]
                }
            })
        }
    }
}
</script>

<style scoped>

</style>