var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center mt-1" }, [
    _vm.currentData.amountPaid
      ? _c("span", { staticClass: "fs-6 me-2" }, [
          _vm._v("£" + _vm._s(_vm.currentData.amountPaid)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm me-2",
        attrs: { disabled: !_vm.currentData.id, type: "button" },
        on: { click: _vm.openAddPaymentModal },
      },
      [_vm._v("\n        Add payment\n    ")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-light btn-sm",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.openPaymentLogModal.apply(null, arguments)
          },
        },
      },
      [_vm._v("\n        Open payment log\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }