<template>
    <form class="form form-inline search-filters" role="search" @submit.prevent>
        <div class="list-group list-group-flush">
            <SearchFilterItem v-for="filter in filterItems" :key="filter.id" :filter="filter"
                              :listing-name="listingName" :set-filter-items="setFilterItems" :view-id="viewId"/>
        </div>
    </form>
</template>

<script>
import SearchFilterItem from './SearchFilterItem'

export default {
    name: "SearchFilters",
    components: {
        SearchFilterItem
    },
    props: {
        listingName: String,
        viewId: String,
    },
    data() {
        return {
            tableName: '',
            filterItems: [],
        }
    },
    created() {
        this.tableName = this.listingName.split('.')[0]

        this.setFilterItems()
    },
    methods: {
        setFilterItems(refresh) {
            return this.$store.dispatch('advancedFilters/get', {
                    tableName: this.tableName,
                    refresh: refresh,
                })
                .then((obj) => {
                    this.filterItems = obj

                    // Only set the data when the modal is first opened, so existing filters are retained when
                    // reopening the modal.
                    let count = Object.keys(this.$store.state[this.viewId].filters.advancedFilter).length
                    if (!count) {
                        // Preset the filters' data so they're computed.
                        this.$store.dispatch(this.viewId + '/resetAdvancedFilters', {
                            refresh: refresh
                        })
                    }
                })
        },
    }
}
</script>

<style scoped>

</style>