export default {
    namespaced: true,
    state: {
        data: {},
    },
    getters: {
        set(state, {tableName, id, displayOrder}) {
            if (!state.data[tableName]) {
                this._vm.$set(state.data, tableName, {})
            }
            
            this._vm.$set(state.data[tableName], id, displayOrder)
        },
    },
    actions: {
        getVariation({dispatch}, {productId, variationId}) {
            if (productId) {
                return dispatch('request/get', {
                    url: 'api/product-variation-option-data',
                    params: {
                        productId: productId,
                        variationId: variationId
                    }
                }, {root: true})
                    .then(o => {
                        return o.data.map(o => ({
                            name: o.option.title,
                            value: o.variable.title
                        }))
                    })
            }
        }
    },
}