<template>
</template>

<script>
export default {
    name: "TableCellTypeCodeEditor",
    props: {
        value: [String, Object],
    },
}
/*
Code copied from AngularJS.
This component was never in use.
// I had to add this because for some odd reason changes to $scope.value
        // are being detected but are not being applied.
        ctrl.$onChanges = (changes) => {
            if (changes.value.previousValue !== changes.value.currentValue) {
                //$scope.value = changes.value.currentValue;
            }
        }
 */
</script>

<style scoped>

</style>