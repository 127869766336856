var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "visible-on-hover-hidden px-1 btn-sm p-0",
      nativeOn: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.openEditModal.apply(null, arguments)
        },
      },
    },
    [_c("i", { staticClass: "bi-pencil-square" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }