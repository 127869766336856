<template>
    <button v-if="hasAccess" class="btn btn-light btn-sm" type="button" @click="onClick">
        View options
    </button>
</template>

<script>
export default {
    name: "TableCellProductTypesButtons",
    props: {
        item: Object,
    },
    computed: {
        hasAccess() {
            return !!this.$store.getters['user/access']('product_options')
        }
    },
    methods: {
        onClick() {
            window.location = '/#/product_options?productTypeId=' + this.item.id
        }
    }
}
</script>

<style scoped>

</style>