var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-light me-2",
          attrs: { type: "button" },
          on: { click: _vm.openSiteTreeModal },
        },
        [_vm._v("\n        Browse…\n    ")]
      ),
      _vm._v(" "),
      _vm.pageId
        ? _c("PagePath", {
            staticClass: "small",
            attrs: { "page-id": _vm.pageId },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }