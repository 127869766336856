var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-tree" },
    _vm._l(_vm.groupedPageIds, function (pageIds, navigationGroup) {
      return _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: navigationGroup === _vm.selectedNavigationGroup,
              expression: "navigationGroup === selectedNavigationGroup",
            },
          ],
          key: navigationGroup,
        },
        _vm._l(pageIds, function (pageId) {
          return _c("SiteTreePageItem", {
            key: pageId,
            attrs: {
              "page-id": pageId,
              "select-content": _vm.selectContent,
              "select-page": _vm.selectPage,
              "selected-page-ids": _vm.selectedPageIds,
              "site-tree-id": _vm.siteTreeId,
              "show-content": _vm.showContent,
            },
          })
        }),
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }