<template>
    <div v-if="showSupport" class="overlay">
        <div class="container-fluid mt-3">
            <div class="row">

                <div class="col-sm-12">
                    <button class="btn btn-light btn-sm float-end" @click="hideSupport()">
                        <i class="bi-box-arrow-right"/>
                        Exit
                    </button>
                </div>

                <div class="col-sm-8 col-sm-offset-2 guides-browser">
                    <header class="help-header">
                        <div class="btn-group float-end">
                            <SupportOpenTicketBtn :hide-support="hideSupport" class="btn-group"/>
                            <SupportOpenDocumentationBtn class="btn-group"/>
                        </div>

                        <h1>
                            Support
                        </h1>

                        <p class="lead">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                        </p>
                    </header>
                </div>

                <div class="col-sm-4 col-sm-offset-2 guides-browser">
                    <h2>
                        Guides
                    </h2>

                    <p>
                        Guides specific to your current activity.
                    </p>

                    <div v-if="componentsWizards" class="list-group">
                        <a v-for="(id, title) in componentsWizards" :key="id" :wizard-id="id" class="list-group-item" wizard-link>
                            {{ title }}
                        </a>
                    </div>

                    <p v-if="!componentsWizards">
                        No wizards
                    </p>
                </div>

                <div class="col-sm-4 component-guides">
                    <h2>
                        All guides
                    </h2>

                    <div all-wizards>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import SupportOpenTicketBtn from './SupportOpenTicketBtn'
import SupportOpenDocumentationBtn from './SupportOpenDocumentationBtn'

export default {
    name: "Support",
    components: {
        SupportOpenTicketBtn,
        SupportOpenDocumentationBtn,
    },
    data() {
        return {
            componentsWizards: false,
        }
    },
    computed: {
        showSupport() {
            document.getElementsByTagName('body')[0].classList.toggle('body-fixed', this.$store.state.showSupport)
            return this.$store.state.showSupport
        }
    },
    methods: {
        hideSupport() {
            this.$store.commit('showSupport', false)
        }
    }
}
</script>

<style scoped>

</style>