var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticStyle: { "font-size": "16px" } }, [
      _vm._v("\n        " + _vm._s(_vm.heading) + "\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { "font-size": "11px" } }, [
      _c("p", [
        _vm._v("\n            " + _vm._s(_vm.reportDate) + "\n        "),
      ]),
      _vm._v(" "),
      _vm.startDate && _vm.endDate
        ? _c("p", [
            _vm._v(
              "\n            Period - " +
                _vm._s(_vm.getPeriodDate(_vm.startDate)) +
                " to " +
                _vm._s(_vm.getPeriodDate(_vm.endDate)) +
                "\n        "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("table", { staticClass: "table table-condensed" }, [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.rows[0], function (value, heading) {
              return _c("td", [
                _vm._v(
                  "\n                    " +
                    _vm._s(heading) +
                    "\n                "
                ),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.rows, function (row) {
            return _c(
              "tr",
              _vm._l(row, function (value, heading) {
                return _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(value) +
                      "\n                "
                  ),
                ])
              }),
              0
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }