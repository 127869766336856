<template>
    <section class="d-flex flex-column h-100">
        <header>
            <h2 class="h5 mb-1">{{ contentAreaName }}<br><small class="lh-1 text-body-tertiary">area</small></h2>
        </header>

        <div class="flex-fill py-4">
            <!--<div class="mb-3">
                <div class="label mb-0 fw-bold text-body-tertiary">Type</div>
                <small class="lh-1">Content area</small>
            </div>-->

            <PageEditorAddContentBtn/>
        </div>

        <footer></footer>
    </section>
</template>

<script>
import PageEditorAddContentBtn from "./PageEditorAddContentBtn.vue"

export default {
    name: "PageEditorContentAreaPanel",
    components: {
        PageEditorAddContentBtn
    },
    computed: {
        contentAreaName() {
            return this.$store.state.pageEditor.selectedContentAreaName
        }
    }
}
</script>

<style scoped>

</style>