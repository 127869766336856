<template>
    <span>{{ humanFileSize(value) }}</span>
</template>

<script>
import humanFileSize from '../../vue/mixins/human-file-size'

export default {
    name: "TableCellFilesFileSize",
    mixins: [humanFileSize],
    props: {
        value: Number,
    }
}
</script>

<style scoped>

</style>