var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Usage")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { "aria-label": "Close", type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c(
          "ul",
          { staticClass: "list-group list-group-flush" },
          _vm._l(_vm.contentData, function (content, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "list-group-item d-flex align-items-center",
              },
              [
                _c("div", { staticClass: "flex-fill" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(content.title) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    staticClass: "btn-sm",
                    nativeOn: {
                      click: function ($event) {
                        return _vm.goToSiteTree(content.pageContentId)
                      },
                    },
                  },
                  [_c("i", { staticClass: "bi-diagram-3-fill" })]
                ),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    staticClass: "btn-sm",
                    nativeOn: {
                      click: function ($event) {
                        return _vm.goToContent(content.id)
                      },
                    },
                  },
                  [_c("i", { staticClass: "bi-pencil-square" })]
                ),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    staticClass: "btn-sm",
                    nativeOn: {
                      click: function ($event) {
                        return _vm.websiteHref(content.pageContentId)
                      },
                    },
                  },
                  [_c("i", { staticClass: "bi-globe" })]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }