var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: { "content-type--items-is-dragging": _vm.drag } },
      [
        _vm.items.length
          ? _c(
              "draggable",
              _vm._b(
                {
                  attrs: {
                    direction: "vertical",
                    draggable: "[data-draggable-item]",
                  },
                  on: {
                    end: _vm.onDragEnd,
                    start: function ($event) {
                      _vm.drag = true
                    },
                  },
                  model: {
                    value: _vm.items,
                    callback: function ($$v) {
                      _vm.items = $$v
                    },
                    expression: "items",
                  },
                },
                "draggable",
                _vm.dragOptions,
                false
              ),
              [
                _c(
                  "transition-group",
                  {
                    staticClass: "list-group overflow-hidden",
                    attrs: { type: "transition" },
                  },
                  _vm._l(_vm.items, function (item) {
                    return _vm.items
                      ? _c(
                          "a",
                          {
                            key: item.id,
                            ref: "contentTypeItem",
                            refInFor: true,
                            staticClass:
                              "list-group-item ps-1 d-flex align-items-center",
                            class: {
                              active: item.id === _vm.selectedId,
                              disabled: _vm.isDisabled,
                            },
                            attrs: {
                              "data-draggable-item": "",
                              draggable: "true",
                              href: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editItem(item.id)
                              },
                            },
                          },
                          [
                            _c("DragHandle", {
                              staticClass: "content-type--item-drag-handle",
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "flex-fill" }, [
                              _vm._v(_vm._s(item.legend)),
                            ]),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "bi-pencil-square",
                              attrs: {
                                "data-bs-toggle": "offcanvas",
                                "data-bs-target":
                                  "#contentTypeFieldsetOffcanvas",
                                role: "button",
                                "aria-controls": "contentTypeFieldsetOffcanvas",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.setFormData(item)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("pre", [_vm._v(_vm._s(_vm.dragItems))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }