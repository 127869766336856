export default {
    namespaced: true,
    state: {
        assumedByCacheKey: 'userAssumedBy',
    },
    getters: {
        isAssumed(state) {
            return localStorage.getItem(state.assumedByCacheKey)
        },
    },
    actions: {
        assume({state, dispatch, rootState}, userId) {
            // Retain the current user's ID, so we know who to revert back to.
            localStorage.setItem(state.assumedByCacheKey, rootState.user.user.id)
            dispatch('_setUser', userId)
        },
        unassume({state, dispatch}) {
            
            console.error('Unassume is not currently in use.')
            return
            
            let userId = localStorage.getItem(state.assumedByCacheKey)
            
            dispatch('request/get', {
                url: 'api/user/' + userId + '/token'
            }, {root: true})
                .then(() => {
                    dispatch('_setUser', userId)
                    localStorage.removeItem(state.assumedByCacheKey)
                })
        },
        // Requests the userId's user item, JWT token and access data.
        _setUser({commit, dispatch}, userId) {
            let user
            
            dispatch('itemData/get', {
                tableName: 'users',
                id: userId,
            }, {root: true})
                .then((obj) => {
                    user = obj
                    return dispatch('request/get', {
                        url: 'api/user/' + userId + '/token'
                    }, {root: true})
                })
                .then((obj) => {
                    if (obj.data.token) {
                        localStorage.setItem('token', obj.data.token)
                    }
                    return dispatch('request/get', {
                        url: 'api/user/' + userId + '/access'
                    }, {root: true})
                })
                .then((obj) => {
                    user.access = obj.data
                    dispatch('user/set', user, {root: true})
                    window.location = '/'
                })
        }
    },
}