var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card mb-3" }, [
    _c(
      "div",
      { staticClass: "card-header py-1 pe-2 d-flex align-items-center" },
      [
        _c("span", { staticClass: "flex-fill" }, [
          _vm._v(_vm._s(_vm.contentArea)),
        ]),
        _vm._v(" "),
        _c(
          "Button",
          {
            staticClass: "btn-sm",
            nativeOn: {
              click: function ($event) {
                return _vm.showAddContentModal.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "bi-plus-lg" })]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.items.length
      ? _c(
          "ul",
          { staticClass: "list-group list-group-flush" },
          _vm._l(_vm.items, function (item, index) {
            return _c("PageContentMobileContent", {
              key: item.id,
              attrs: {
                "content-areas": _vm.contentAreas,
                "content-area": _vm.contentArea,
                "content-area-first": _vm.contentAreaFirst,
                "content-area-last": _vm.contentAreaLast,
                "content-index": index,
                "content-last": index === _vm.items.length - 1,
                "page-content-link-id": item.id,
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }