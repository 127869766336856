<template>
    <div class="p-3 ps-1 small">
        <FormPublishBtn :form-id="formId" class="mb-3"/>

        <div class="mb-3" v-if="currentData.createdDate">
            <label class="form-sidebar-label">Created</label>

            <div class="d-flex align-items-start">
                <UserAvatar v-if="currentData.createdBy" :user-id="currentData.createdBy" :is-small="false"
                            :tooltip-placement="'right'" class="mt-1 me-2"/>

                <div>
                    <div v-if="currentData.createdBy">
                        <UserName :id="currentData.createdBy" :no-user-found-str="''"/>
                    </div>

                    {{ getDate(currentData.createdDate * 1000) }}
                </div>
            </div>
        </div>

        <div class="mb-3" v-if="currentData.modifiedDate">
            <label class="form-sidebar-label">Modified</label>

            <div class="d-flex align-items-start">
                <UserAvatar v-if="currentData.modifiedBy" :user-id="currentData.modifiedBy" :is-small="false"
                            :tooltip-placement="'right'" class="mt-1 me-2"/>

                <div>
                    <div v-if="currentData.modifiedBy">
                        <UserName :id="currentData.modifiedBy" :no-user-found-str="'<em>user not found</em>'"/>
                    </div>

                    {{ getDate(currentData.modifiedDate * 1000) }}
                </div>
            </div>
        </div>

        <FormDisplayOrderMenu v-if="showDisplayOrderOption && currentData.id" :form-id="formId" class="mb-2"/>

        <FormHistoryBtn v-if="showHistoryBtn" :form-id="formId"/>
        <FormDuplicateBtn v-if="showDuplicateBtn" :form-id="formId"/>
        <TagsBtn :form-id="formId"/>
        <OrderRequestReview :form-id="formId"/>
        <PrintBtn :form-id="formId" :is-invoice="true"/>
        <PrintBtn :form-id="formId"/>
        <FormArchiveBtn v-if="showArchiveBtn" :form-id="formId"/>
        <FormDeleteBtn :form-id="formId"/>
    </div>
</template>

<script>
import moment from 'moment'

import FormSaveBtn from './FormSaveBtn'
import FormSaveCloseBtn from './FormSaveCloseBtn'
import FormPublishBtn from '../../common/form/FormPublishBtn'
import UserName from '../../common/UserName'
import UserAvatar from '../../common/UserAvatar'
import FormDisplayOrderMenu from '../../common/form/FormDisplayOrderMenu'
import FormHistoryBtn from './FormHistoryBtn'
import FormDuplicateBtn from './FormDuplicateBtn'
import FormArchiveBtn from './FormArchiveBtn'
import FormDeleteBtn from './FormDeleteBtn'
import TagsBtn from '../tags/TagsBtn'
import PrintBtn from '../../components/order-details/PrintBtn'
import OrderRequestReview from "../../components/order-details/OrderRequestReview"

export default {
    name: "FormSidebar",
    components: {
        OrderRequestReview,
        FormArchiveBtn,
        FormDeleteBtn,
        FormSaveBtn,
        FormSaveCloseBtn,
        FormPublishBtn,
        UserName,
        UserAvatar,
        FormDisplayOrderMenu,
        FormHistoryBtn,
        FormDuplicateBtn,
        TagsBtn,
        PrintBtn
    },
    props: {
        formId: String,
        showSaveAndCloseBtn: Boolean,
        showSaveBtn: Boolean,
        showHistoryBtn: Boolean,
        showDuplicateBtn: Boolean,
        showArchiveBtn: Boolean,
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        showDisplayOrderOption() {
            return this.$store.getters[this.formId + '/showDisplayOrderOption']
        }
    },
    methods: {
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YYYY @ HH:mm')
        }
    }
}
</script>

<style scoped>
.form-sidebar-label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    color: #999 !important;
}
</style>