<template>
    <div v-if="showEditable">
        <FormControlTypeCheckbox :current-data="currentData" :field="field" :form-id="formId"/>
        <span class="ms-3">
            <label>
                <small>Make editable:</small>
                <input type="checkbox" @checked="isEditable" @click="toggleEditable">
            </label>
        </span>
    </div>

    <FormControlTypeCheckbox v-else :current-data="currentData" :field="field" :form-id="formId"/>
</template>

<script>
import FormControlTypeCheckbox from '../form-control-type/FormControlTypeCheckbox'

export default {
    name: "FormControlModulestructureBrowser",
    components: {
        FormControlTypeCheckbox,
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    computed: {
        showEditable() {
            return (
                ['text', 'currency'].indexOf(this.currentData.type) > -1
                && this.currentData.browser
            )
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    methods: {
        toggleEditable() {
            this.currentData.browser = this.currentData.browser === 1 ? 2 : 1
        },
        isEditable() {
            return this.currentData.browser === 2
        }
    }
}
</script>

<style scoped>

</style>