var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.componentName, {
    tag: "component",
    attrs: { field: _vm.field, item: _vm.item },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }