<template>
    <span v-if="text" v-html="text"/>
</template>

<script>
export default {
    name: "TableCellTypeSelect",
    props: {
        column: Object,
        value: String,
    },
    data() {
        return {
            text: '',
        }
    },
    created() {
        // If the select menu's options property contains an object then the value
        // represents a key. So output the key's value.
        let options = this.column.options

        // todo - I think the idea here was to replace the option's "value" property with its "text" property.
        if (
            options // Fix for typeof null === object JavaScript bug. https://stackoverflow.com/questions/18808226/why-is-typeof-null-object
            && typeof options === 'object'
            && options[this.value]
        ) {
            this.text = options[this.value]
        } else {
            this.text = this.value
        }
    }
}
</script>

<style scoped>

</style>