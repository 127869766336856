<template>
    <button
        v-if="show"
        class="btn btn-light btn-sm"
        type="button"
        @click="openLogsModal"
    >
        view
    </button>
</template>

<script>
export default {
    name: "TableCellOrderLogs",
    props: {
        item: Object,
    },
    asyncComputed: {
        show() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/order_log',
                    params: {
                        orderId: this.item.id
                    }
                })
                .then((obj) => {
                    return !!obj.data.items.length
                })
        }
    },
    methods: {
        openLogsModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'OrdersLogsModal',
                obj: {
                    orderId: this.item.id
                }
            })
        }
    },
}
</script>

<style scoped>

</style>