<template>
    <div class="outer">
        <div class="d-flex align-items-center">
            <FormControlPlainText v-if="currentData.creditAmount" class="d-inline m-0">
                £{{ currentData.creditAmount }}
            </FormControlPlainText>

            <Button :disabled="!currentData.id" class="btn-sm ms-2" @click.native="openAddCreditModal">
                Add credit
            </Button>

            <Button :disabled="!currentData.id" class="btn-sm ms-2" @click.native="openDeleteCreditModal">
                Delete credit
            </Button>

            <Button class="btn-sm ms-2" @click.native="openCreditHistoryModal">
                View credit history
            </Button>
        </div>
    </div>
</template>

<script>
import FormControlPlainText from "../../common/form-control/FormControlPlainText.vue"
import Button from "../../common/Button.vue"

export default {
    name: "FormControlMRegistrantsRegistrantsCreditAmount",
    components: {Button, FormControlPlainText},
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: String
    },
    computed: {
        id() {
            return this.$store.state[this.formId].id
        }
    },
    methods: {
        openAddCreditModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'OdpAddCreditModal',
                obj: {
                    customerFormId: this.formId,
                }
            })
        },
        openDeleteCreditModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'OdpDeleteCreditModal',
                obj: {
                    customerFormId: this.formId,
                }
            })
        },
        openCreditHistoryModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'OdpCreditHistoryModal',
                obj: {
                    customerId: this.id,
                }
            })
        }
    }
}
</script>

<style scoped>
.outer {
    display: flex;
    align-items: flex-start;
}
</style>