<template>
    <span>{{ formattedValue }}</span>
</template>

<script>
export default {
    name: "TableCellTypeCurrency",
    props: {
        value: String,
        item: Object,
        column: Object,
        viewId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        formattedValue() {
            let currency = 'gbp'

            // Supports Fly Spain's currency field within the Orders component
            if (this.tableName === 'm_basket_orders' && this.item.currency) {
                currency = this.item.currency
            }

            // Currency fields can be set with null values, so only output a
            // formatted value if the value is 0.00 or higher.
            if (this.value !== null) {
                return new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: currency
                })
                    .format(this.value)
            }
        }
    }
}
</script>

<style scoped>

</style>