<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add payment</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <Form v-if="formId" :form-id="formId"/>

                <footer class="text-center mt-3">
                    <FormSaveBtn :button-text="'Save'" :cancel-on-save="true" :form-id="formId"/>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import Form from '../../common/form/Form'
import FormSaveBtn from '../../common/form/FormSaveBtn'

export default {
    name: "OdpAddPaymentModal",
    components: {
        Form,
        FormSaveBtn
    },
    props: {
        modal: Object,
        options: Object,
    },
    data() {
        return {
            formId: '',
            tableName: 'odp_bookings_payment_log',
            id: 0, // This form is only used for inserting payment logs
            bookingFormId: this.options.bookingFormId,
        }
    },
    computed: {
        bookingId() {
            return this.$store.state[this.bookingFormId].id
        }
    },
    created() {
        this.createForm()
    },
    methods: {
        createForm() {
            this.formId = 'form:' + this.tableName + ':' + this.id

            // Assigning the form ID to the modal object triggers the unsaved confirmation modal
            this.modal.formId = this.formId

            this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.id,
                fieldNames: [
                    'amount',
                    'paymentNote',
                ],
                currentData: {
                    bookingId: this.bookingId,
                    label: 'Manual payment',
                    date: Math.ceil(Date.now() / 1000)
                },
                onSave: (obj) => {
                    const amount = obj.amount

                    // Saves the booking's new amountPaid value and updates the form.
                    this.$store.dispatch('itemData/get', {
                            tableName: 'odp_course_bookings',
                            id: this.bookingId
                        })
                        .then((obj) => {
                            let amountPaid = obj.amountPaid || 0
                            amountPaid = parseFloat(amountPaid) + parseFloat(amount)
                            amountPaid = amountPaid.toFixed(2)

                            // Update the active form to show the new value, without triggering activating save button.
                            this.$store.commit(this.bookingFormId + '/presetData', {
                                name: 'amountPaid',
                                value: amountPaid
                            })

                            // Save the booking's new amountPaid
                            return this.$store.dispatch('itemData/update', {
                                tableName: 'odp_course_bookings',
                                id: this.bookingId,
                                data: {
                                    amountPaid: amountPaid
                                }
                            })
                        })
                        .then(() => {
                            this.$emit('hide-modal')
                        })
                },
            })
        },
    },
}
</script>

<style scoped>

</style>