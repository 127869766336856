<template>
    <Button v-if="hasEditAccess && viewMode === 'thumbnail'" :class="{ disabled: selectedIds.length !== 1 }"
            class="rounded-0" title="Edit" @click.native="onClick">
        <i class="bi-pencil-square"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsEditBtn",
    components: {Button},
    props: {
        viewId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        selectedIds() {
            return this.$store.state[this.viewId].selectedIds
        },
        viewMode() {
            return this.$store.state[this.viewId].settings.viewMode
        },
        hasEditAccess() {
            return this.$store.getters['user/access'](this.tableName, 'edit')
        },
    },
    methods: {
        onClick() {
            window.location = '/#/' + this.tableName + '/' + this.selectedIds[0]
        }
    }
}
</script>

<style scoped>

</style>