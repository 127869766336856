<template>
    <div v-if="show" class="d-flex align-items-center">
        <ComponentSettingsBtn v-if="tableName" :class="{ 'active': routeName === 'componentSettings' }"
                              :table-name="tableName" class="me-1"/>

        <ComponentFieldsBtn v-if="tableName" :class="{ 'active': routeName === 'componentFields' }"
                            :table-name="tableName"/>
    </div>
</template>

<script>
import ComponentSettingsBtn from './component-settings/ComponentSettingsBtn'
import ComponentFieldsBtn from './component-fields/ComponentFieldsBtn'

export default {
    name: "ComponentEditorBtnGroup",
    components: {
        ComponentSettingsBtn,
        ComponentFieldsBtn
    },
    computed: {
        routeName() {
            return this.$route.name
        },
        component() {
            return this.$store.state.componentRoot.component
        },
        tableName() {
            return this.$route.params.component
        },
        show() {
            return true
            // Settings should not be available to forms, so there's no need for the button group
            return this.component.navigationGroup !== 'Forms'
        },
    }
}
</script>

<style scoped>

</style>