var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "d-flex align-items-center" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.number",
          value: _vm.pageSize,
          expression: "pageSize",
          modifiers: { number: true },
        },
      ],
      staticClass: "me-2 form-control input-sm listing-options-page-size",
      attrs: { max: "50", min: "1", size: "3", type: "number" },
      domProps: { value: _vm.pageSize },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.pageSize = _vm._n($event.target.value)
        },
        blur: function ($event) {
          return _vm.$forceUpdate()
        },
      },
    }),
    _vm._v("\n    Page size\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }