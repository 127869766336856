<template>
    <span v-html="html"/>
</template>

<script>
export default {
    name: "TableCellTypeTexteditor",
    props: {
        value: String,
    },
    data() {
        return {
            html: '',
        }
    },
    created() {
        // Strip HTML tags from the editor's content.
        this.html = this.value ? String(this.value).replace(/<[^>]+>/gm, '') : ''
    }
}
</script>

<style scoped>

</style>