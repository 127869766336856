export default {
    namespaced: true,
    state: {
        index: 0,
        modals: [],
    },
    mutations: {
        hideAll(state) {
            state.modals = []
        },
        pop(state) {
            state.modals.pop()
        }
    },
    actions: {
        show({state}, {componentName, obj}) {
            let modal = {
                index: state.index++,
                componentName: componentName,
                show: false,
                options: obj,
                formId: ''
            }
            
            state.modals.push(modal)
            
            setTimeout(() => {
                modal.show = true
            }, 100)
            
            return modal
        },
        remove({state, rootGetters, dispatch}, index) {
            let i
            state.modals.forEach((modal, key) => {
                if (modal.index === index) {
                    i = key
                }
            })
            
            // Unsaved form changes confirmation modal
            // Modals provided a formId property contain a form. If the form has unmodified data present a confirmation.
            const modal = state.modals[i]
            const formId = modal?.formId
            if (formId) {
                const isModified = rootGetters[formId + '/isModified']()
                if (isModified) {
                    dispatch('modals/show', {
                        componentName: 'ConfirmModal',
                        obj: {
                            modalTitle: 'Are you sure?',
                            modalBody: `
                        <p class="alert alert-danger">
                            Your unsaved changes will be lost.
                        </p>
                    `,
                            onConfirm: () => {
                                state.modals.splice(i, 1)
                            }
                        }
                    }, {root: true})
                    return
                }
            }
            
            state.modals.splice(i, 1)
        },
    },
}