var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value",
        },
      ],
      staticClass: "form-select",
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.value = $event.target.multiple ? $$selectedVal : $$selectedVal[0]
        },
      },
    },
    [
      _c("option", { attrs: { value: "" } }, [_vm._v("Select…")]),
      _vm._v(" "),
      _vm._l(_vm.optionGroups, function (group, name) {
        return _c(
          "optgroup",
          { attrs: { label: name } },
          _vm._l(group, function (props, value) {
            return _c("option", { domProps: { value: value } }, [
              _vm._v("\n            " + _vm._s(props.title) + "\n        "),
            ])
          }),
          0
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }