<template>
    <div v-if="show">
        <select v-if="options && options.length" :id="field.id" v-model="currentData[field.name]" :name="field.name"
                class="form-select" required>
            <option :value="''">Select…</option>
            <option v-for="option in options" :value="option.value">
                {{ option.text }}
            </option>
        </select>

        <FormControlPlainText v-else>
            No supported attributes are available.
        </FormControlPlainText>
    </div>
</template>

<script>
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlTemplatesTemplatesFilterAttribute",
    components: {FormControlPlainText},
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    computed: {
        show() {
            if (
                this.currentData.contentType === 'Filter'
                && this.currentData.componentId
            ) {
                this.field.visibility = ''
                return true
            }

            this.field.visibility = 'hidden'
            this.currentData.filterAttribute = ''
            return false
        },
        componentId() {
            return this.currentData.componentId
        }
    },
    asyncComputed: {
        options: {
            default: [],
            get() {
                if (this.currentData.componentId) {
                    return this.$store.dispatch('componentStructure/get', this.currentData.componentId)
                        .then((objs) => {
                            var options = []
                            objs.forEach(obj => {
                                if (
                                    obj.type !== 'fieldset'
                                    && [
                                        'validFrom',
                                        'validTo',
                                        'createdBy',
                                        //'createdDate',
                                        'modifiedBy',
                                        //'modifiedDate',
                                        'status'
                                    ].indexOf(obj.columnName) === -1
                                    // Only date is currently supported
                                    && ['date', 'datetime-local'].indexOf(obj.type) !== -1
                                ) {
                                    options.push({
                                        value: obj.columnName,
                                        text: obj.title
                                    })
                                }
                            })

                            return options
                        })
                }
            }
        }
    },
    watch: {
        componentId(newValue, oldValue) {
            if (newValue !== oldValue) {
                // Unset the selected value whenever the component changes so that if a component has no supported
                // attributes a previously selected attribute won't erroneously be saved.
                this.currentData.filterAttribute = ''
            }
        }
    }
}
</script>

<style scoped>

</style>