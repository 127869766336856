<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Delete credit</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <Form v-if="formId" :form-id="formId"/>

                <footer class="text-center mt-3">
                    <FormSaveBtn :button-text="'Save'" :cancel-on-save="true" :form-id="formId"/>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import Form from '../../common/form/Form'
import FormSaveBtn from '../../common/form/FormSaveBtn'

export default {
    name: "OdpDeleteCreditModal",
    components: {
        Form,
        FormSaveBtn
    },
    props: {
        modal: Object,
        options: Object,
    },
    data() {
        return {
            formId: '',
            tableName: 'odp_credit_log',
            id: 0, // Form inserts only
            customerFormId: this.options.customerFormId,
        }
    },
    computed: {
        customerId() {
            return this.$store.state[this.customerFormId].id
        }
    },
    created() {
        this.createForm()
    },
    methods: {
        createForm() {
            this.formId = 'form:' + this.tableName + ':' + this.id

            // Assigning the form ID to the modal object triggers the unsaved confirmation modal
            this.modal.formId = this.formId

            this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.id,
                fieldNames: [
                    'amount',
                ],
                currentData: {
                    debited: 1,
                    customerId: this.customerId,
                },
                onSave: (obj) => {
                    const amount = obj.amount

                    // Saves the booking's new amountPaid value and updates the form.
                    this.$store.dispatch('itemData/get', {
                            tableName: 'm_registrants_registrants',
                            id: this.customerId
                        })
                        .then((obj) => {
                            let creditAmount = obj.creditAmount || 0
                            creditAmount = parseFloat(creditAmount) - parseFloat(amount)
                            creditAmount = creditAmount.toFixed(2)

                            // Update the active form to show the new value, without triggering activating save button.
                            this.$store.commit(this.customerFormId + '/presetData', {
                                name: 'creditAmount',
                                value: creditAmount
                            })

                            // Save the booking's new amountPaid
                            return this.$store.dispatch('itemData/update', {
                                tableName: 'm_registrants_registrants',
                                id: this.customerId,
                                data: {
                                    creditAmount: creditAmount
                                }
                            })
                        })
                        .then(() => {
                            this.$emit('hide-modal')
                        })
                },
            })
        },
    },
}
</script>

<style scoped>

</style>