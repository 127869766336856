<template>
    <FormSidebarBtn v-if="show" :disabled="isDisabled" @click.prevent.stop.native="onClick">
        <span :class="{ 'bi-square': !order.requestAReview, 'bi-check-square': order.requestAReview }"
              class="me-2"/>
        Request a review
    </FormSidebarBtn>
</template>

<script>
import FormSidebarBtn from "../../common/form/FormSidebarBtn.vue"

export default {
    name: "OrderRequestReview",
    components: {FormSidebarBtn},
    props: {
        formId: String
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        show() {
            return this.tableName === 'm_basket_orders'
        },
        isDisabled() {
            return this.order.orderStatus !== 'Completed'
        }
    },
    asyncComputed: {
        order: {
            default: {},
            get() {
                if (this.show) {
                    return this.$store.dispatch('itemData/get', {
                            tableName: 'm_basket_orders',
                            id: this.id
                        })
                        .then((obj) => {
                            return obj
                        })
                }
            }
        }
    },
    methods: {
        onClick() {
            if (this.isDisabled) {
                alert("The order's status must be 'Completed'.")
                return
            }

            let newValue = this.order.requestAReview
                ? 0
                : Math.floor(Date.now() / 1000)

            this.order.requestAReview = newValue

            let id = this.order.id

            this.$store.dispatch('request/patch', {
                    url: 'api/component/m_basket_orders/' + id,
                    postData: {
                        requestAReview: newValue
                    }
                })
                .then(() => {
                    this.$store.dispatch('itemData/set', {
                        tableName: 'm_basket_orders',
                        id: id,
                        property: 'requestAReview',
                        value: newValue
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>