<script>
import FormControlTypeCheckbox from '../form-control-type/FormControlTypeCheckbox'

export default {
    name: "FormControlUsersAdministrator",
    extends: FormControlTypeCheckbox,
    computed: {
        user() {
            return this.$store.state.user.user
        },
    },
    created() {
        if (!this.user.administrator) {
            this.field.hidden = true
        } else if (this.user.id === this.currentData.id) {
            // Administrators cannot edit themselves.
            // This ensures that there is always one administrator because the last administrator
            // must assign someone else as an administrator before they can be removed.
            this.field.disabled = 1

            // todo - I should implement a generic form-text element in the form group component.
            //  I could then use this.field.formText = '' to present this message.
            //$element
            //    .find('.checkbox-wrapper')
            //    .append(' <em><small>Administrators may not unset themselves.</small></em>');
        }
    }
}
</script>

<style scoped>

</style>