var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "Button",
        {
          staticClass: "btn-sm me-1 p-1 d-flex align-items-center text-nowrap",
          attrs: {
            "active-value": _vm.index === _vm.activeEditorKey,
            "active-class": "btn-primary",
            "default-class": _vm.typeColourClass,
          },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.selectTab.apply(null, arguments)
            },
          },
        },
        [
          _c("i", {
            staticClass: "me-1 bi-x",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.removeEditor.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c("small", [_vm._v(_vm._s(_vm.item.title))]),
          _vm._v(" "),
          _c("span", {
            staticClass: "rounded ps-1 pt-1 ms-2 me-1",
            class: { "bg-warning": _vm.editor.isModified },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }