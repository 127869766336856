var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    staticClass: "bi-grip-vertical",
    staticStyle: { cursor: "grab" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }