<template>
    <div class="d-flex flex-column h-100 overflow-hidden">
        <PageEditorToolbar/>

        <div v-if="pageId" ref="container" class="flex-fill d-flex h-100 overflow-hidden">
            <div class="flex-fill position-relative overflow-hidden w-100 h-100 d-flex">
                <iframe ref="iframe" :srcdoc="iframeSrcdoc" @load="init" class="flex-fill"></iframe>

                <template v-if="contentAreas.length" v-for="contentArea in contentAreas">
                    <PageEditorContentAreaIframe :content-area="contentArea" :key="contentArea.name"/>

                    <template v-for="o in contentAreaContent(contentArea.name)">
                        <PageEditorContentIframe :content-item="o" :key="o.pageContentId"/>
                    </template>
                </template>
            </div>

            <!-- Bottom padding wasn't scrolling, so I've removed it here and it's added to the child elements -->
            <aside class="p-3 pb-0 border-start overflow-y-scroll" style="flex: 0 0 250px">
                <!--<pre><small><small>{{ {
                    hoverContentAreaName,
                    selectedContentAreaName,
                    highlightContentAreaName,
                    hoverPageContentId,
                    activePageContentId
                } }}</small></small></pre>-->
                <PageEditorContentAreaPanel v-if="selectedContentAreaName"/>
                <PageEditorContentPanel v-if="activePageContentId"/>
            </aside>
        </div>

        <PageEditorContentAreaOverlay v-if="hoverContentAreaName" :key="hoverContentAreaName"/>
        <PageEditorContentOverlay v-if="hoverPageContentId" :key="hoverPageContentId"/>
    </div>
</template>

<script>
import PageEditorContentOverlay from "./PageEditorContentOverlay.vue"
import PageEditorContentAreaOverlay from "./PageEditorContentAreaOverlay.vue"
import FormFieldsetAnchors from "../../common/form/FormFieldsetAnchors.vue"
import Form from "../../common/form/Form.vue"
import SidebarToggle from "../../common/sidebar-toggle/SidebarToggle.vue"
import FormSidebar from "../../common/form/FormSidebar.vue"
import PageEditorContentMoveUpBtn from "./PageEditorContentMoveUpBtn.vue"
import PageEditorContentMoveDownBtn from "./PageEditorContentMoveDownBtn.vue"
import PageEditorContentRemoveBtn from "./PageEditorContentRemoveBtn.vue"
import PageEditorContentIframe from "./PageEditorContentIframe.vue"
import PageEditorContentPanel from "./PageEditorContentPanel.vue"
import PageEditorAddContentBtn from "./PageEditorAddContentBtn.vue"
import PageEditorContentAreaIframe from "./PageEditorContentAreaIframe.vue"
import PageEditorContentAreaPanel from "./PageEditorContentAreaPanel.vue"
import PageEditorToolbar from "./PageEditorToolbar.vue"

export default {
    name: "PageEditorIframe",
    components: {
        PageEditorToolbar,
        PageEditorContentAreaPanel,
        PageEditorContentAreaIframe,
        PageEditorAddContentBtn,
        PageEditorContentPanel,
        PageEditorContentIframe,
        PageEditorContentRemoveBtn,
        PageEditorContentMoveDownBtn,
        PageEditorContentMoveUpBtn,
        FormSidebar, SidebarToggle, Form, FormFieldsetAnchors,
        PageEditorContentAreaOverlay,
        PageEditorContentOverlay
    },
    data() {
        return {
            activeIframe: undefined
        }
    },
    props: {
        siteTreePageId: Number
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        contentAreas() {
            return this.pageEditor.contentAreas
        },
        selectedContentAreaName() {
            return this.pageEditor.selectedContentAreaName
        },
        hoverContentAreaName() {
            return this.pageEditor.hoverContentAreaName
        },
        highlightContentAreaName() {
            return this.pageEditor.highlightContentAreaName
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        hoverPageContentId() {
            return this.pageEditor.hoverPageContentId
        }
    },
    asyncComputed: {
        iframeSrcdoc() {
            if (this.pageId) {
                return this.$store.dispatch('request/get', {
                        url: 'controller/page-editor/' + this.pageId
                    })
                    .then(o => {
                        return o.data.html
                    })
            }
        }
    },
    mounted() {
        this.$store.commit('pageEditor/pageId', this.siteTreePageId)
    },
    destroyed() {
        this.$store.dispatch('pageEditor/unset')
    },
    watch: {
        $route: {
            // This doesn't work because the VueX module's init has yet to be called
            //immediate: true,
            handler(to, from) {
                //console.log('aaa', to.query.area)
                this.$store.commit('pageEditor/selectedContentAreaName', to.query.area)
                this.$store.commit('pageEditor/activePageContentId', parseInt(to.query.id))
            }
        }
    },
    methods: {
        init() {
            this.$store.dispatch('pageEditor/init', {
                router: this.$router,
                iframe: this.$refs.iframe,
                area: this.$route.query.area,
                pageContentId: parseInt(this.$route.query.id)
            })
        },
        contentAreaContent(name) {
            return this.pageEditor.contentItems.filter(o => o.contentAreaName === name)
        }
    }
}
</script>

<style scoped>

</style>