var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-group-item d-flex align-items-center" },
    [
      _vm.columns
        ? _c("SearchFilterColumnMenu", {
            attrs: {
              columns: _vm.columns,
              filter: _vm.filter,
              "listing-name": _vm.listingName,
              "set-filter-items": _vm.setFilterItems,
              "view-id": _vm.viewId,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.columns
        ? _c("SearchFilterColumnOptions", {
            attrs: {
              columns: _vm.columns,
              filter: _vm.filter,
              "listing-name": _vm.listingName,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.columns
        ? _c("SearchFilterField", {
            attrs: {
              filter: _vm.filter,
              "listing-name": _vm.listingName,
              "view-id": _vm.viewId,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "ms-1 btn btn-light component-filters-item-remove-btn",
          attrs: { type: "button" },
          on: { click: _vm.removeFilter },
        },
        [_c("small", { staticClass: "bi-trash3" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }