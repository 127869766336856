<template>
    <span v-if="contentTypeTitle">
        <i class="bi-chevron-compact-right mx-1 align-middle"/>

        <Button class="btn-sm lh-1 p-1" type="button" @click.native="selectContent">
            <small>{{ contentTypeTitle }}</small>
        </Button>
    </span>
</template>

<script>
import Button from "../../common/Button.vue"
import PageEditorToolbarContentDropdown from "./PageEditorToolbarContentDropdown.vue"

export default {
    name: "PageEditorToolbarContentBtn",
    components: {PageEditorToolbarContentDropdown, Button},
    computed: {
        activePageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        activeContent() {
            return this.$store.state.pageEditor.contentItems.find(o => o.pageContentId === this.activePageContentId)
        },
        pageContent() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.activePageContentId)
        },
        contentId() {
            return this.pageContent?.contentId
        },
        contentTypeTitle() {
            return this.$store.getters['templates/contentTypes'].find(o => o.id === this.content?.contentType)?.title
        }
    },
    asyncComputed: {
        content() {
            if (this.contentId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
            }
        }
    },
    methods: {
        selectContent() {
            this.$store.dispatch('pageEditor/selectContent', {
                pageContentId: this.activePageContentId
            })
        }
    }
}
</script>

<style scoped>

</style>