var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(
            "Products for " + _vm._s(_vm.monthName) + " " + _vm._s(_vm.year)
          ),
        ]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _vm.productData
          ? _c("table", { staticClass: "table table-condensed" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.productData, function (data) {
                  return _c("tr", { key: data.orderId }, [
                    _c("td", [
                      _c(
                        "a",
                        {
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goToOrder(data.orderId)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(data.title) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-end" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(data.quantity) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-end" }, [
                      _vm._v(
                        "\n                        £" +
                          _vm._s(_vm._f("numberFormat")(data.net)) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-end" }, [
                      _vm._v(
                        "\n                        £" +
                          _vm._s(_vm._f("numberFormat")(data.tax)) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-end" }, [
                      _vm._v(
                        "\n                        £" +
                          _vm._s(_vm._f("numberFormat")(data.gross)) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-end" }, [
                      _vm._v(
                        "\n                        £" +
                          _vm._s(_vm._f("numberFormat")(data.shipping)) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-end" }, [
                      _vm._v(
                        "\n                        £" +
                          _vm._s(
                            _vm._f("numberFormat")(data.gross + data.shipping)
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Title")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Net")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("VAT")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Gross")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Shipping")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Total")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }