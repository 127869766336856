<template>
    <div v-if="item">
        <!--<FormHeader :form-id="formId"/>-->

        <Form v-if="formId" :form-id="formId" :show-legends="true"/>

        <div class="clearfix text-center mt-3">
            <FormSaveBtn :button-text="'Save'" :form-id="formId"/>

            <Button v-if="item.id" @click.native="confirmDelete($event, item)">
                <!--<i class="bi-trash3"/>-->
                Delete
            </Button>
        </div>
    </div>
</template>

<script>
import Form from '../form/Form'
import FormSaveBtn from '../form/FormSaveBtn'
import Button from "../Button.vue"

export default {
    name: "ContentTypeFieldEditForm",
    components: {
        Button,
        Form,
        FormSaveBtn,
    },
    props: {
        modal: Object
    },
    data() {
        return {
            tableName: 'modulestructure',
        }
    },
    computed: {
        activeEditId() {
            return this.$store.state.componentFields.activeEditId
        },
        componentId() {
            return this.$store.state.componentFields.componentId
        },
        formId() {
            // By referencing item formId will update when the async computed item updates. We can then watch
            // formId (see watches) and create the form when formId updates.
            if (this.item) {
                const id = this.item.id || 0 // When the New Attribute form is enabled, item will be an empty object
                return 'form:' + this.tableName + ':' + id
            }
        },
        items() {
            return this.$store.state.componentFields.items
        },
        titleValue() {
            if (this.$store.state[this.formId]) {
                return this.$store.state[this.formId].currentData.title
            }
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.activeEditId
                })
                .then((obj) => {
                    return obj
                })
        }
    },
    watch: {
        items() {
            // This has been placed in a watch because when placed inside createForm below this.items wasn't set
            // on refreshing the page.
            // The new item must be positioned as the last item.
            // Form forms, as items does not include the emailMessageId, it will be placed above this field.
            if (this.formId) { // Prevents undefined error when creating new forms
                const displayOrder = this.items.length + 1
                this.$store.commit(this.formId + '/presetData', {
                    name: 'displayOrder',
                    value: displayOrder,
                })
            }
        },
        formId() {
            this.createForm()
        },
        titleValue(val) {
            this.$store.commit(this.formId + '/setFieldProperty', {
                fieldName: 'title',
                property: 'error',
                value: ''
            })

            if (val) {
                // todo - This isn't a Vue way of doing things!
                const el = document.querySelector('[name="title"]')

                let hasError = false
                this.items.forEach((item) => {
                    if (
                        item.id !== this.activeEditId
                        && item.title === val
                    ) {
                        hasError = true

                        el.setCustomValidity('Label in use.')
                        el.checkValidity()
                    }
                })

                if (!hasError) {
                    if (el) {
                        el.setCustomValidity('')
                        el.checkValidity()
                    }

                    /*
                    let columnName = this.$store.getters["componentFields/toCamelCase"](val)
                    // /set is used here, and not /presetData, so that the column name is always updated to be the
                    // camelCase version of the field's label. This is required because there is no columnName field
                    // allowing users to set the column name.
                    this.$store.commit(this.formId + '/set', {
                        name: 'columnName',
                        value: columnName,
                    })
                    //*/
                }
            }
        }
    },
    methods: {
        createForm() {
            let fieldNames = this.$store.getters['componentFields/getFields'](this.item.type)

            if (this.modal) {
                this.modal.formId = this.formId
            }

            this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.activeEditId,
                fieldNames: fieldNames,
                onInit: () => {
                    let fields = this.$store.state[this.formId].fields
                    let field = fields.filter((field) => {
                        return field.columnName === 'title'
                    })[0]
                    field.label = this.item.type === 'fieldset' ? 'Legend' : 'Label'

                    if (this.activeEditId === 0) {
                        this.$store.commit(this.formId + '/presetData', {
                            name: 'modulecomponentsID',
                            value: this.componentId,
                        })

                        if (this.$store.state.componentFields.activeFieldsetId) {
                            this.$store.commit(this.formId + '/presetData', {
                                name: 'fieldsetId',
                                value: this.$store.state.componentFields.activeFieldsetId,
                            })
                        }

                        this.$store.commit(this.formId + '/presetData', {
                            name: 'browser',
                            value: 1,
                        })
                    }
                }
            })

            // Provide the form state's modifiedData getter to contentTypeFields so that when the service's
            // closeEditFieldForm is triggered it can check if there is unsaved data and so display a confirmation.
            this.$store.state.componentFields.getModifiedData = () => {
                return this.$store.getters[this.formId + '/modifiedData']()
            }
        },
        confirmDelete(e, item) {
            e.stopPropagation()

            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you sure?',
                    modalBody: `
                          <div class="alert alert-danger">
                            This action will result in loss of data.
                          </div>
                          <p>
                            This action <strong>cannot</strong> be undone. This will permanently
                            delete all data from the <strong>` + item.title + `</strong> field.
                          </p>
                        `,
                    confirmStr: item.title,
                    onConfirm: () => {
                        this.$store.dispatch('componentFields/deleteField', {
                            fieldId: item.id,
                            router: this.$router
                        })
                    }
                }
            })
        }
    }
}
</script>

<style>
/* This was generating errors due to use of ">>>", so I've updated it to use :deep() but I couldn't
   see the font-size have an effect anywhere so I've disabled it.
.content-type--field-edit-form :deep(.form-label) {
    font-size: 30px;
}
*/
</style>