var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card dashboard-panel-activity-stream" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v("\n        Activity stream\n    "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body pt-0" },
      _vm._l(_vm.items, function (item) {
        return _c("DashboardActivityStreamItem", {
          key: item.id,
          attrs: {
            "get-user-id": _vm.getUserId,
            item: item,
            "set-user-id": _vm.setUserId,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }