<script>
import FormControlTypeText from '../form-control-type/FormControlTypeText'

export default {
    name: "FormControlModulestructureEndYear",
    extends: FormControlTypeText,
    created() {
        if (this.currentData.type !== 'date') {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>