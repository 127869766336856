<template>
    <iframe v-if="html" :srcdoc="html"/>
    <div v-else class="themes-editor-preview-pane-ajax-loader-container">
        <img :src="ajaxLoader" alt="" class="themes-editor-preview-pane-ajax-loader">
    </div>
</template>

<script>
export default {
    name: "ThemesEditorPreviewPane",
    props: {
        id: Number,
        code: String,
    },
    data() {
        return {
            ajaxLoader: require('../../../src/img/ajax-loader.gif')
        }
    },
    asyncComputed: {
        html() {
            // todo - The alphaMode directive doesn't prevent this component from processing
            // even though it removes it from the DOM.
            if (!this.$store.state.alphaMode) {
                return ''
            }

            return this.$store.dispatch('request/post', {
                    url: 'api/template/render',
                    postData: {
                        templateId: this.id,
                        templateHtml: this.code
                    },
                    customHeaders: {
                        'X-Http-Method-Override': 'GET'
                    }
                })
                .then((obj) => {
                    if (obj.data) {

                        // Add a script which disables all click events. This prevents navigation items from
                        // attempting to load etc. whilst still allowing for mouse over effects.
                        let script = `
<script>
    document.addEventListener('click', function(e) {
        e.preventDefault();
    }, true);
<\/script>
`
                        // First attempt to disable clicks using a layer, but this disable mouse over.
                        //let div = '<div style="position: fixed; z-index: 1000; top: 0; left: 0; width: 100%; height: 100%;"></div>';

                        return script + obj.data

                    } else {
                        //this.html = 'no content';
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>