var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show && _vm.tableName
    ? _c(
        "button",
        {
          staticClass: "btn btn-sm btn-light text-start",
          attrs: { type: "button" },
          on: { click: _vm.selectCategory },
        },
        [
          !_vm.item.value ? _c("span", [_vm._v("select")]) : _vm._e(),
          _vm._v(" "),
          _vm.itemId
            ? _c("FormControlCategoryTitle", {
                attrs: { "table-name": _vm.tableName, id: _vm.itemId },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm.show && !_vm.componentId
    ? _c(
        "small",
        { staticClass: "text-danger form-text ms-2 mb-1 fst-italic small" },
        [_vm._v("Error: No\n    collection targeted")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }