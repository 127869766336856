<template>
    <div ref="overlay" v-show="show">
        <a @click.prevent="edit" role="button"
           class="d-block w-100 h-100 d-flex align-items-center justify-content-center h1">
            <i class="bi bi-pencil-square"></i>
        </a>
    </div>
</template>

<script>
export default {
    name: "PageEditorContentOverlay",
    computed: {
        hoverPageContentId() {
            return this.$store.state.pageEditor.hoverPageContentId
        },
        activeContent() {
            return this.$store.state.pageEditor.contentItems.find(o => o.pageContentId === this.hoverPageContentId)
        },
        show() {
            return !!this.hoverPageContentId;
        }
    },
    mounted() {
        this.activeContent.iframe.contentDocument.body.append(this.$refs.overlay)
    },
    methods: {
        edit() {
            const pageContentId = this.hoverPageContentId

            this.$router.push({
                name: 'siteTree',
                params: {
                    pageOrContentId: this.$route.params.pageOrContentId,
                },
                query: {
                    id: pageContentId
                }
            })

            this.$store.state.pageEditor.toolbarDropdown.click() // Hide the menu

            return
            this.$store.dispatch('pageEditor/deselectAll')
            this.$store.commit('pageEditor/activePageContentId', pageContentId)
        }
    }
}
</script>

<style scoped>

</style>