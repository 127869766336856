<template>
    <select required v-model="formData.monthsAgo" class="ms-2 form-select form-select-sm" name="months_ago"
            oninvalid="this.setCustomValidity('Please select a date range')" oninput="this.setCustomValidity('')">
        <option value="">Select&hellip;</option>
        <option
            v-for="(text, value) in { 1: '1 month', 3: '3 months', 6: '6 months', 12: '12 months', 24: '24 months' }"
            :value="value">
            {{ text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "MonthInTheLastFilter",
    computed: {
        formData() {
            return this.$store.state.odpReports.formData
        }
    },
    created() {
        const monthsAgo = this.$route.query.monthsAgo || ''
        this.$set(this.formData, 'monthsAgo', monthsAgo)
    },
    destroyed() {
        this.$delete(this.formData, 'monthsAgo')
    }
}
</script>

<style scoped>

</style>