<template>
    <button class="btn btn-light btn-sm" @click="onClick">
        Raise a ticket
    </button>
</template>

<script>
export default {
    name: "SupportOpenTicketBtn",
    props: {
        hideSupport: Function,
    },
    methods: {
        onClick() {
            this.$store.dispatch('modals/show', {
                componentName: 'ZendeskModal',
            })
            this.hideSupport()
        }
    }
}
</script>

<style scoped>

</style>