var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "themes-editor-nav-section overflow-y-scroll h-100" },
    [
      _vm.sectionData.helpText && _vm.alphaMode
        ? _c("HelpBox", { attrs: { id: _vm.helpBlockId } }, [
            _c("div", { staticClass: "mb-1 fw-bold" }, [
              _vm._v(_vm._s(_vm.sectionData.title)),
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.sectionData.helpText) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function (item) {
        return _c("ThemesEditorNavLink", {
          key: item.id,
          attrs: { "active-editor-key": _vm.activeEditorKey, item: item },
        })
      }),
      _vm._v(" "),
      _c("div", { staticClass: "my-5 py-5" }, [_vm._v(" ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }