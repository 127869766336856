export default {
    inserted: function(el, binding, vnode, old) {
        
        let disabled = false
        
        document.onkeydown = (e) => {
            if (e.shiftKey) {
                el.style.userSelect = 'none'
                disabled = true
            }
        }
        
        document.onkeyup = (e) => {
            if (e.shiftKey) {
                el.style.userSelect = ''
                disabled = false
            }
        }
    }
}