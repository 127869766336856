<template>
    <div v-if="isVisible" class="mb-1 p-1 border bg-light-subtle rounded position-relative">
        <Button class="position-absolute end-0 me-1 p-1 py-0 btn-sm" @click.native="remove">
            ×
        </Button>

        <div class="small"><div class="small"><slot/></div></div>
    </div>
</template>

<script>
import Button from "./Button.vue"

export default {
    name: "HelpBox",
    components: {Button},
    props: {
        id: String,
    },
    computed: {
        hiddenHelpBoxes() {
            return this.$store.getters['user/pref']('hiddenHelpBoxes')
        },
        isVisible() {
            return (
                this.hiddenHelpBoxes
                && this.id
                && this.hiddenHelpBoxes.indexOf(this.id) === -1
            )
        }
    },
    methods: {
        remove() {
            this.hiddenHelpBoxes.push(this.id)

            this.$store.dispatch('user/setPref', {
                name: 'hiddenHelpBoxes',
                value: this.hiddenHelpBoxes
            })
        }
    }
}
</script>

<style scoped>

</style>