var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("textarea", { ref: "textarea", staticStyle: { display: "none" } }, [
      _vm._v(_vm._s(_vm.currentData[_vm.field.name])),
    ]),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.currentData[_vm.field.name],
          expression: "currentData[field.name]",
        },
      ],
      staticStyle: { display: "none" },
      attrs: {
        id: _vm.field.id,
        name: _vm.field.name,
        required: _vm.field.required,
      },
      domProps: { value: _vm.currentData[_vm.field.name] },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.currentData, _vm.field.name, $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm.showIframe && _vm.iframeHtml !== undefined
      ? _c("iframe", {
          ref: "iframe",
          staticStyle: {
            width: "100%",
            height: "200px",
            border: "1px solid",
            "border-radius": "5px",
          },
          style: _vm.iframeStyle,
          attrs: { srcdoc: _vm.iframeHtml },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }