<template>
    <a href @click.prevent="onClick">{{ value }}</a>
</template>

<script>
export default {
    name: "TableCellOrderItemsTitle",
    props: {
        item: Object,
        value: String
    },
    computed: {
        tableName() {
            return this.$store.state.components.items.find(o => o.id === this.item.componentId).tableName
        }
    },
    methods: {
        onClick() {
            this.$router.push({
                name: 'form',
                params: {
                    component: this.tableName,
                    id: this.item.itemId
                }
            })
        }
    }
}
</script>

<style scoped>

</style>