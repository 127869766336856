<template>
    <thead>
    <tr>
        <th v-if="!selectMode" :class="{ selected: allSelected }" class="table-cell-select" title="Select all or none"
            @click="selectAll">
            <small><span :class="{ 'bi-square': !allSelected, 'bi-check-square': allSelected }"/></small>
        </th>

        <TableViewHeadCell :column="{ name: primaryKey, title: 'ID#' }" :view-id="viewId"/>

        <TableViewHeadCell v-for="columnId in columns" :key="columnId" :column-id="columnId" :view-id="viewId"/>
    </tr>
    </thead>
</template>

<script>
import TableViewHeadCell from './TableViewHeadCell'

export default {
    name: "TableViewHead",
    components: {
        TableViewHeadCell
    },
    props: {
        viewId: String,
    },
    computed: {
        primaryKey() {
            return this.$store.state[this.viewId].tableName + 'ID'
        },
        selectMode() {
            return this.$store.state[this.viewId].selectMode
        },
        allSelected() {
            return this.$store.state[this.viewId].allSelected
        },
        columns() {
            return this.$store.getters[this.viewId + '/visibleColumnIds']
        }
    },
    methods: {
        selectAll() {
            this.$store.commit(this.viewId + '/selectAll')
        },
    },
}
</script>

<style scoped>

</style>