var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "offcanvas offcanvas-end",
      attrs: {
        tabindex: "-1",
        id: "offcanvasPageEditorContent",
        "aria-labelledby": "offcanvasPageEditorContentLabel",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "offcanvas-body" }, [
        _c(
          "p",
          [
            _c("PageEditorContentMoveUpBtn"),
            _vm._v(" "),
            _c("PageEditorContentMoveDownBtn"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "offcanvas-footer" }, [
        _c(
          "div",
          { staticClass: "p-3 text-end" },
          [_c("PageEditorContentRemoveBtn")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "offcanvas-header" }, [
      _c(
        "h5",
        {
          staticClass: "offcanvas-title",
          attrs: { id: "offcanvasPageEditorContentLabel" },
        },
        [_vm._v("Offcanvas")]
      ),
      _vm._v(" "),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "offcanvasPageEditorContent",
          "aria-label": "Close",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }