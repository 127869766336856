var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data
    ? _c("div", [
        _c("div", { staticClass: "p-3 text-center" }, [
          _c(
            "div",
            { staticClass: "btn-group" },
            _vm._l(_vm.years, function (year) {
              return _c(
                "button",
                {
                  key: year,
                  staticClass: "btn btn-light",
                  class: { active: _vm.selectedYear === year },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.setYear(year)
                    },
                  },
                },
                [_vm._v("\n                " + _vm._s(year) + "\n            ")]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "table",
              { staticClass: "table table-condensed table-striped" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.monthNames, function (monthName, i) {
                    return _vm.monthTotals
                      ? _c("tr", { key: i }, [
                          _c("td", [
                            _vm.monthTotals[i]
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.showMonth(i)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(monthName) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _c("span", [_vm._v(_vm._s(monthName))]),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.monthTotals[i] &&
                                    _vm.monthTotals[i].orders
                                    ? _vm.monthTotals[i].orders
                                    : 0
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end" }, [
                            _vm._v(
                              "\n                        £" +
                                _vm._s(
                                  _vm._f("numberFormat")(
                                    _vm.monthTotals[i] && _vm.monthTotals[i].net
                                      ? _vm.monthTotals[i].net
                                      : 0
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end" }, [
                            _vm._v(
                              "\n                        £" +
                                _vm._s(
                                  _vm._f("numberFormat")(
                                    _vm.monthTotals[i] && _vm.monthTotals[i].tax
                                      ? _vm.monthTotals[i].tax
                                      : 0
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end" }, [
                            _vm._v(
                              "\n                        £" +
                                _vm._s(
                                  _vm._f("numberFormat")(
                                    _vm.monthTotals[i] &&
                                      _vm.monthTotals[i].gross
                                      ? _vm.monthTotals[i].gross
                                      : 0
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end" }, [
                            _vm._v(
                              "\n                        £" +
                                _vm._s(
                                  _vm._f("numberFormat")(
                                    _vm.monthTotals[i] &&
                                      _vm.monthTotals[i].shipping
                                      ? _vm.monthTotals[i].shipping
                                      : 0
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-end" }, [
                            _vm._v(
                              "\n                        £" +
                                _vm._s(
                                  _vm._f("numberFormat")(
                                    _vm.monthTotals[i] &&
                                      _vm.monthTotals[i].total
                                      ? _vm.monthTotals[i].total
                                      : 0
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ])
                      : _vm._e()
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.yearTotals
                  ? _c("tfoot", [
                      _c("tr", [
                        _c("th", [_vm._v("Total")]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-end" }, [
                          _vm._v(_vm._s(_vm.yearTotals.orders)),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-end" }, [
                          _vm._v(
                            "£" +
                              _vm._s(_vm._f("numberFormat")(_vm.yearTotals.net))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-end" }, [
                          _vm._v(
                            "£" +
                              _vm._s(_vm._f("numberFormat")(_vm.yearTotals.tax))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-end" }, [
                          _vm._v(
                            "£" +
                              _vm._s(
                                _vm._f("numberFormat")(_vm.yearTotals.gross)
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-end" }, [
                          _vm._v(
                            "£" +
                              _vm._s(
                                _vm._f("numberFormat")(_vm.yearTotals.shipping)
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-end" }, [
                          _vm._v(
                            "£" +
                              _vm._s(
                                _vm._f("numberFormat")(_vm.yearTotals.total)
                              )
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Month")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Orders")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Net")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("VAT")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Gross")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Shipping")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-end" }, [_vm._v("Total")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }