<template>
    <div class="modal-dialog modal-narrow">
        <div class="modal-content">
            <div class="modal-header">
                <span>Click a column to toggle its visibility.</span>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <ListingColumnsList :view-id="viewId"/>
            </div>
        </div>
    </div>
</template>

<script>
import ListingColumnsList from '../../common/listing/ListingColumnsList'

export default {
    name: "ListingColumnsModal",
    components: {
        ListingColumnsList
    },
    props: {
        options: Object,
    },
    data() {
        return {
            viewId: this.options.viewId,
        }
    }
}
</script>

<style scoped>

</style>