var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.options
    ? _c("FormControlTypeSelect", {
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
          options: _vm.options,
          required: _vm.field.required,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }