<template>
    <div class="container pt-5 pb-5">
        <ComponentSettingsForm/>

        <!--<form class="container">
            <fieldset>
                <legend>
                    Danger zone
                </legend>-->

        <div class="list-group">
            <div class="list-group-item">
                <ComponentSettingsDuplicateFormItem/>
            </div>
        </div>

        <h3>Danger zone</h3>

        <div class="list-group">
            <div class="list-group-item danger-list-group-item">
                <ComponentSettingsDeleteFormItem/>
            </div>

            <!--<div class="row">
                <div class="col-lg-3 col-xl-2">
                    <label class="form-label">Delete this form</label>
                    <!-<Tooltip :tooltip="field.tooltip"/>->
                </div>

                <div class="col-lg-9 col-xl-10">
                    <div class="control-wrapper">
                        <!-<FormControl :field="field" :form-id="formId"/>

                        <span v-if="field.required && !field.readonly && !field.error" class="help-block">Required</span>

                        <span v-if="field.error" class="help-block" v-html="field.error"/>->
                    </div>
                </div>
            </div>-->
        </div>

        <!--</fieldset>
    </form>-->

    </div>
</template>

<script>
import ComponentSettingsDeleteFormItem from './ComponentSettingsDeleteFormItem'
import ComponentSettingsDuplicateFormItem from './ComponentSettingsDuplicateFormItem'
import ComponentSettingsForm from './ComponentSettingsForm'

export default {
    name: "ComponentSettings",
    components: {
        ComponentSettingsDeleteFormItem,
        ComponentSettingsDuplicateFormItem,
        ComponentSettingsForm
    },
    computed: {},
    methods: {}
}
</script>

<style scoped>
h3 {
    padding: 30px 15px 15px;
}

.danger-list-group-item {
    border-color: #ebccd1;
}
</style>