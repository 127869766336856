var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-group" },
    _vm._l(
      _vm.currentData[_vm.field.name],
      function (fileName, objectName, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "list-group-item",
            attrs: { href: "" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.openFile(objectName)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(fileName) + "\n    ")]
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }