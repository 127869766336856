<template>
    <span
        v-if="count"
        :title="'Assigned to ' + count + ' item' + ( count > 1 ? 's' : '' ) + '.'"
        class="badge bg-secondary"
    >{{ count }}</span>
</template>

<script>
export default {
    name: "TableCellTypeRelationshipManyToMany",
    props: {
        column: Object,
        item: Object,
        viewId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        count() {
            return this.$store.getters['categories/get'](this.tableName, this.column.columnName, this.item.id).length
        }
    }
}
</script>

<style scoped>

</style>