var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Button",
        {
          ref: "button",
          staticClass: "h-100 rounded-0",
          attrs: {
            "data-bs-toggle": "offcanvas",
            "data-bs-target": "#notificationsOffcanvas",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.openMenu.apply(null, arguments)
            },
          },
        },
        [
          _c("i", {
            staticClass: "bi-bell-fill notification-icon",
            class: { active: _vm.active },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "offcanvas offcanvas-end bg-transparent border-0",
          attrs: {
            tabindex: "-1",
            id: "notificationsOffcanvas",
            "aria-labelledby": "notificationsOffcanvasLabel",
          },
          on: { click: _vm.closeMenu },
        },
        [
          _c(
            "div",
            { staticClass: "offcanvas-body pt-5" },
            _vm._l(_vm.items, function (item) {
              return _c("Toast", {
                key: item.id,
                staticClass: "m-0 mb-3 w-100",
                attrs: {
                  title: item.title,
                  body: item.data.message,
                  "created-by": item.createdBy,
                  "created-date": item.createdDate,
                  "download-url": item.data.downloadUrl,
                  "more-info": item.data.moreInfo,
                  percentage: item.data.percentage,
                  "disable-auto-hide": true,
                  "disable-animation": true,
                  "disable-close": false,
                  "on-close": () => {
                    _vm.onCloseToast(item.id)
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "p-3 text-end" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }