export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        items(state, items) {
            state.items = items
        }
    },
    actions: {
        init({dispatch, commit}) {
            return dispatch('request/get', {
                url: 'api/component/fieldset',
                params: {
                    sort: 'displayOrder'
                },
            }, {root: true})
                .then((obj) => {
                    commit('items', obj.data.items || [])
                })
        }
    },
}