<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Payment log</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <div v-if="items" class="list-group">
                    <div v-for="item in items" class="list-group-item">
                        <div>
                            £{{ item.amount }} - {{ item.label }}
                            <span v-if="item.paymentNote"> - {{ item.paymentNote }}</span>
                        </div>

                        <span class="date font-monospace">
                            {{ getDate(item.date) }}
                            <!--<Timeago :datetime="item.date * 1000"/>-->
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "OdpPaymentNoteModal",
    props: {
        options: Object,
    },
    data() {
        return {
            bookingId: this.options.bookingId,
        }
    },
    asyncComputed: {
        items() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/odp_bookings_payment_log',
                    params: {
                        bookingId: this.bookingId,
                        sort: 'date',
                    }
                })
                .then((obj) => {
                    return obj.data.items
                })
        }
    },
    methods: {
        getDate(timestamp) {
            if (!timestamp) return ''

            timestamp *= 1000

            return moment(timestamp).format('DD/MM/YYYY @ HH:mm')
        }
    },
}
</script>

<style scoped>
.list-group-item {
    display: flex;
    align-items: center;
}

.list-group-item > :first-child {
    flex: auto;
}

.date {
    background: #eaeaea;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 12px;
    color: #333;
}
</style>