var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "offcanvas offcanvas-end",
      attrs: {
        tabindex: "-1",
        id: "contentTypeFieldsetOffcanvas",
        "aria-labelledby": "contentTypeFieldsetOffcanvasLabel",
      },
    },
    [
      _c("div", { staticClass: "offcanvas-header" }, [
        _c(
          "h5",
          {
            staticClass: "offcanvas-title",
            attrs: { id: "contentTypeFieldsetOffcanvasLabel" },
          },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: {
            type: "button",
            "data-bs-dismiss": "offcanvas",
            "aria-label": "Close",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "offcanvas-body" }, [
        _c("form", { ref: "form" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.legend,
                expression: "formData.legend",
              },
            ],
            staticClass: "form-control",
            attrs: { name: "legend", placeholder: "Legend" },
            domProps: { value: _vm.formData.legend },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "legend", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mt-3 d-flex" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.formData.legend === "" },
              },
              [_vm._v("Save")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "p-3 text-end" }, [
        _vm.fieldsetId
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteFieldset.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Delete")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }