var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex h-100 overflow-auto flex-column" },
    [
      _c("FormHeader", {
        attrs: {
          "form-id": _vm.formId,
          "show-add-btn": true,
          "show-save-and-close-btn": true,
          "show-save-btn": true,
        },
      }),
      _vm._v(" "),
      _c("FormNavAndForm", {
        attrs: {
          "form-id": _vm.formId,
          "show-archive-btn": true,
          "show-duplicate-btn": true,
          "show-history-btn": true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }