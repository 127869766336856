var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        { name: "disable-shift-select", rawName: "v-disable-shift-select" },
      ],
    },
    [
      _vm.itemCount === 0
        ? _c(
            "div",
            [
              _c("ListingNoContent", { attrs: { "view-id": _vm.viewId } }),
              _vm._v(" "),
              _c("ListingNoContentOptions", {
                attrs: { "view-id": _vm.viewId },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.itemCount,
              expression: "itemCount",
            },
          ],
          staticClass: "overflow-scroll",
        },
        [
          _c("ListingSelectAll", { attrs: { "view-id": _vm.viewId } }),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table table-hover table-dashed" },
            [
              _c("TableViewHead", { attrs: { "view-id": _vm.viewId } }),
              _vm._v(" "),
              _c(
                "tbody",
                { staticClass: "bg-white" },
                _vm._l(_vm.ids, function (id) {
                  return _c("TableViewRow", {
                    key: id,
                    attrs: { id: id, "view-id": _vm.viewId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }