<template>

</template>

<script>
export default {
    name: "FormControlPagesUrlSlug",
    props: {
        field: Object,
    },
    created() {
        this.field.hidden = true
    }
}
</script>

<style scoped>

</style>