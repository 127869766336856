<template>
    <table v-if="items" class="table table-responsive">
        <thead>
        <tr>
            <th>Event</th>
            <th>Recipient</th>
            <th>Date</th>
            <th>Message</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="item in items" :key="item.id">
            <td>
                <span :class="getClass(item.event)" class="badge" style="text-transform: capitalize">
                    {{ item.event }}
                </span>
            </td>

            <td>{{ item.recipient }}</td>

            <td>{{ getDate(item.eventDate * 1000) }}</td>

            <td>{{ item.eventData.deliveryStatus.message }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import moment from 'moment'

export default {
    name: "FormControlEmailMessageEvent",
    props: {
        currentData: Object,
    },
    asyncComputed: {
        items() {
            return this.$store.dispatch('emailEvents/get', this.currentData.id)
        },
    },
    methods: {
        getClass(event) {
            let labels = {
                accepted: 'bg-warning text-dark',
                rejected: 'bg-danger',
                delivered: 'bg-success',
                failed: 'bg-danger',
                opened: 'bg-success',
                clicked: 'bg-success',
                unsubscribed: 'bg-danger',
                complained: 'bg-danger',
                stored: 'bg-warning text-dark'
            }

            if (
                event
                && labels.hasOwnProperty(event)
            ) {
                return labels[event]
            }
        },
        getDate(timestamp) {
            return moment(timestamp).format('DD MMM YYYY, HH:mm')
        }
    }
}
</script>

<style scoped>

</style>