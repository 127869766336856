var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "d-flex h-100 overflow-auto flex-column",
      attrs: { id: _vm.tableName },
    },
    [
      _c("header", { staticClass: "d-flex border-bottom" }, [
        _c(
          "div",
          { staticClass: "flex-fill d-flex align-items-center" },
          [
            !_vm.selectMode
              ? [
                  _c("ListingOptionsAddBtn", {
                    attrs: { "view-id": _vm.viewId },
                  }),
                  _vm._v(" "),
                  _c("ListingOptionsArchiveBtn", {
                    staticClass: "btn rounded-0",
                    attrs: { "view-id": _vm.viewId },
                  }),
                  _vm._v(" "),
                  _c("ListingOptionsDeleteBtn", {
                    staticClass: "btn rounded-0",
                    attrs: { "view-id": _vm.viewId },
                  }),
                  _vm._v(" "),
                  _c("ListingOptionsEditBtn", {
                    attrs: { "view-id": _vm.viewId },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("ListingOptionsSearchModalBtn", {
              attrs: { "view-id": _vm.viewId },
            }),
            _vm._v(" "),
            _c("SearchFiltersResetBtn", { attrs: { "view-id": _vm.viewId } }),
            _vm._v(" "),
            _c("ShowSiteTreeBtn", { attrs: { "view-id": _vm.viewId } }),
            _vm._v(" "),
            _c("ListingBreadcrumbs", { attrs: { "view-id": _vm.viewId } }),
            _vm._v(" "),
            _vm.isOdp
              ? [
                  _c("OdpCourseDatesCoursesMenu", {
                    staticClass: "ms-1",
                    attrs: { "view-id": _vm.viewId },
                  }),
                  _vm._v(" "),
                  _c("OdpCourseDatesSchoolsMenu", {
                    staticClass: "ms-1",
                    attrs: { "view-id": _vm.viewId },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            _c("ListingOptionsPaginationMetrics", {
              staticClass: "mx-3",
              attrs: { "view-id": _vm.viewId },
            }),
            _vm._v(" "),
            _c("ListingOptionsPreviousBtn", {
              attrs: { "view-id": _vm.viewId },
            }),
            _vm._v(" "),
            _c("ListingOptionsNextBtn", { attrs: { "view-id": _vm.viewId } }),
            _vm._v(" "),
            _c("ListingOptionsMenu", { attrs: { "view-id": _vm.viewId } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "listing flex-grow-1 overflow-auto pt-3" },
        [
          _vm.viewMode === "table"
            ? _c(
                "div",
                [
                  _c("ListView", {
                    staticClass: "list-view d-md-none mb-3",
                    attrs: { "view-id": _vm.viewId },
                  }),
                  _vm._v(" "),
                  _c("TableView", {
                    staticClass: "table-view d-none d-md-block mb-3",
                    attrs: { "view-id": _vm.viewId },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.viewMode === "thumbnail"
            ? _c("GridView", { attrs: { "view-id": _vm.viewId } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("Dump", [_vm._v(_vm._s(_vm.$store.state[_vm.viewId]))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }