<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Tags</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <TagsForm :id="options.id" :table-name="options.tableName"/>
            </div>

            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
import TagsForm from './TagsForm'

export default {
    name: "TagsModal",
    props: {
        options: Object
    },
    components: {
        TagsForm
    }
}
</script>

<style scoped>

</style>