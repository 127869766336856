var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-xl" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Fields")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xs-4 col-md-4" },
            [
              _c("ContentTypeFieldsList"),
              _vm._v(" "),
              _c("ContentTypeNewAttributeBtn"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-8 col-md-8" },
            [
              _vm.activeEditId >= 0
                ? _c("ContentTypeFieldEditForm", {
                    attrs: { modal: _vm.modal },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }