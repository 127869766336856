<template>
    <div>
        <FormControlPlainText v-if="typeTitle && !showMenu" :value="typeTitle"/>

        <select v-if="showMenu" v-model="currentData[field.name]" class="form-select">
            <!-- ng-options="option.value as option.text group by option.group for option in options"-->
            <option v-for="option in options" :value="option.value">
                {{ option.text }}
            </option>
        </select>

        <button v-if="currentData.typeId && !showSaveNotice" :disabled="!showEditVariationsButton" class="btn btn-light"
                type="button" @click="editVariations">
            Edit variations
        </button>

        <small v-if="showSaveNotice">
            <em>Save required</em>
        </small>

        <!--<pre>{{
            {
                isTypeModified: isTypeModified,
                typeHasOptions: typeHasOptions,
                typeTitle: typeTitle,
                typesWithOptions: typesWithOptions
            }
        }}</pre>-->
    </div>
</template>

<script>
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlMProductsProductsTypeId",
    components: {FormControlPlainText},
    data() {
        return {
            showMenu: false,
            typeTitle: undefined,
            options: undefined,
            stockQuantity: null
        }
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    computed: {
        originalData() {
            return this.$store.state[this.formId].originalData
        },
        typeHasOptions() {
            return (
                this.typesWithOptions
                && this.typesWithOptions.indexOf(this.currentData.typeId) >= 0
            )
        },
        showSaveNotice() {

            this.toggleFields()

            return (
                this.isTypeModified
                && this.typeHasOptions
            )
        },
        showEditVariationsButton() {
            return (
                !this.isTypeModified
                && this.currentData.typeId
                && this.typeHasOptions
            )
        },
        isTypeModified() {
            return this.originalData.typeId !== this.currentData.typeId
        }
    },
    asyncComputed: {
        typesWithOptions() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/product_options',
                    params: {
                        field: 'DISTINCT productTypeId',
                        sort: 'productTypeId',
                    }
                })
                .then((obj) => {
                    return obj.data.values
                })
        },
        typeTitle() {
            if (this.currentData.typeId) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'product_types',
                        id: this.currentData.typeId
                    })
                    .then((obj) => {
                        if (
                            obj
                            // In case this is a type which has been deleted. Otherwise it would prevent the
                            // user from selecting another.
                            && obj.title
                        ) {
                            return obj.title
                        }
                    })
            }
        }
    },
    created() {
        // Load all product types and populate the select menu. If none exist yet then remove the select menu.
        this.$store.dispatch('request/get', {
                url: 'api/component/product_types',
                params: {
                    field: 'id',
                    __isPublished: true,
                }
            })
            .then((obj) => {
                let typeIds = obj.data.values
                if (
                    typeIds
                    && typeIds.length
                ) {
                    this.setOptions(typeIds)
                } else {
                    this.field.hidden = true
                    return
                }

                if (!this.currentData.id) {
                    this.$store.dispatch(this.formId + '/setDefaultValue', {
                        name: this.field.name,
                        value: 0
                    })

                    this.showMenu = true
                    return
                }

                if (!this.currentData.typeId) {
                    this.showMenu = true

                    return false
                }

                return this.$store.dispatch('request/get', {
                    url: 'api/Products/getTypesTableNames'
                })
            })
            .then((obj) => {
                // The if (obj) conditions in the remaining then() are all required because even if the above
                // returns false the chain continues. It would be better to break it up into separate functions.
                if (obj) {
                    let tableName = obj.data[this.currentData.typeId]
                    return this.$store.dispatch('request/get', {
                        url: 'api/component/' + tableName,
                        params: {
                            field: 'id',
                            productId: this.currentData.id
                        }
                    })
                }
            })
            .then((obj) => {
                if (obj) {
                    let variationsIds = obj.data.values

                    // If the product has variations then disable the select menu.
                    // A product's variations must be deleted before its type can be modified.
                    if (!variationsIds.length) {
                        this.showMenu = true
                    }
                }
            })
    },
    methods: {
        setOptions(typeIds) {
            this.$store.dispatch('itemData/preload', {
                    tableName: 'product_types',
                    ids: typeIds,
                })
                .then((objs) => {
                    let options = [{
                        value: 0,
                        text: '- none -'
                    }]
                    objs.forEach((obj) => {
                        options.push({
                            value: obj.id,
                            text: obj.title
                        })
                    })
                    options = options.sort((a, b) => (a.text > b.text) ? 1 : -1)
                    this.options = options
                })
        },
        editVariations() {
            this.$store.dispatch('request/get', {
                    url: 'api/Products/getTypesTableNames'
                })
                .then((obj) => {
                    let tableName = obj.data[this.currentData.typeId]
                    if (tableName) {
                        window.location = '/#/' + tableName + '?productId=' + this.currentData.id
                    }
                })
        },
        toggleFields() {

            let fieldNames = [
                'trackStock',
                'stockQuantity',
                'stockLevel', // todo - deprecated
                'hideWhenSold',
                'taxExempt',
                'comparisonPrice',
                'sellingPrice',
                'specialOffer',
                'wasPrice' // BBB only
            ]

            let fields = this.$store.state[this.formId].fields
            fields.forEach((field) => {
                if (fieldNames.indexOf(field.columnName) > -1) {
                    field.hidden = this.typeHasOptions
                }
            })

            // When a typeId is assigned the stockQuantity value must be set to null, but if the user then unsets the
            // type again repopulate the stockQuantity with the previously set value so the form is unmodified.
            if (this.currentData.typeId) {
                this.stockQuantity = this.currentData.stockQuantity
                this.currentData.stockQuantity = null
            } else if (this.stockQuantity) {
                this.currentData.stockQuantity = this.stockQuantity
            }
        }
    }
}
</script>

<style scoped>

</style>