<template>
    <span>{{ title }}</span>
</template>

<script>
export default {
    name: "FormControlCategoryTitle",
    props: {
        tableName: String,
        id: Number,
    },
    data() {
        return {
            title: '',
        }
    },
    computed: {
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
    },
    created() {
        this.createCategoryTitle()
    },
    watch: {
        id(newValue) {
            newValue
                ? this.createCategoryTitle()
                : this.title = ''
        }
    },
    methods: {
        createCategoryTitle() {
            this.appendCategorySlug(this.id)
        },
        appendCategorySlug(id, append) {
            this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: id
                })
                .then((obj) => {
                    this.title = append
                        ? obj[this.nameField] + ' > ' + this.title
                        : obj[this.nameField]

                    if (obj.parentId) {
                        this.appendCategorySlug(obj.parentId, true)
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>