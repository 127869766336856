var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-danger",
      attrs: { disabled: _vm.isDisabled },
      on: { click: _vm.remove },
    },
    [_c("i", { staticClass: "bi bi-x-lg" }), _vm._v(" Remove\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }