var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "d-block btn-sm w-100 text-start",
      attrs: { title: _vm.title },
    },
    [
      _vm.className
        ? _c("i", { staticClass: "me-2", class: _vm.className })
        : _vm._e(),
      _vm._v("\n    " + _vm._s(_vm.title) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }