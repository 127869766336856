<template>
    <Button @click.native="showAddContentModal" :default-class="'btn-secondary'">
        <i class="bi-plus-lg"/>
        Add content
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "PageEditorAddContentBtn",
    components: {Button},
    computed: {
        pageId() {
            return this.$store.state.pageEditor.pageId
        },
        contentAreaName() {
            return this.$store.state.pageEditor.selectedContentAreaName
        }
    },
    methods: {
        showAddContentModal() {
            this.$store.dispatch('pageEditor/showAddContentModal', {
                contentAreaName: this.contentAreaName
            })
        }
    }
}
</script>

<style scoped>

</style>