<template>
    <div class="d-flex align-items-center small">
        <div class="flex-fill d-flex align-items-center">
            <div class="dropup ms-1 p-1">
                <Button class="btn-sm" data-bs-toggle="dropdown">
                    <i class="bi-three-dots"/>
                </Button>
                <ul class="dropdown-menu" @click.stop>
                    <li class="dropdown-item text-center">
                        <CodemirrorModeMenu/>
                    </li>
                    <CodemirrorLineNumbersBtn/>
                    <CodemirrorLineWrappingBtn/>
                    <CodemirrorTwigModeBtn/>
                </ul>
            </div>

            <ThemesEditorContentTypeFieldsBtn v-if="isContentType" :id="editor.id"/>
        </div>

        <div class="d-flex align-items-center">
            <small class="me-1 text-body-secondary">
                <strong>
                    <Timeago v-if="item" :datetime="(item.modifiedDate || item.createdDate) * 1000"/>
                </strong>
                by
                <strong class="text-secondary">
                    <UserName v-if="modifiedBy" :id="modifiedBy" :no-user-found-str="'user not found'"
                              :no-user-str="'system'"/>
                </strong>
            </small>

            <Button class="me-1 btn-sm" @click.native="saveEditor" :active-value="editor.isModified" :active-class="'btn-warning'">
                <i class="bi-save"/>
            </Button>
        </div>
    </div>
</template>

<script>
import CodemirrorModeMenu from '../../common/codemirror/CodemirrorModeMenu'
import CodemirrorLineNumbersBtn from '../../common/codemirror/CodemirrorLineNumbersBtn'
import CodemirrorLineWrappingBtn from '../../common/codemirror/CodemirrorLineWrappingBtn'
import CodemirrorTwigModeBtn from '../../common/codemirror/CodemirrorTwigModeBtn'
import UserName from '../../common/UserName'
import ThemesEditorContentTypeFieldsBtn from './ThemesEditorContentTypeFieldsBtn'
import Button from "../../common/Button.vue"

export default {
    name: "ThemesEditorFooter",
    components: {
        Button,
        CodemirrorModeMenu,
        CodemirrorLineNumbersBtn,
        CodemirrorLineWrappingBtn,
        CodemirrorTwigModeBtn,
        UserName,
        ThemesEditorContentTypeFieldsBtn,
    },
    props: {
        editor: Object,
        saveEditor: Function,
    },
    data() {
        return {
            item: {},
        }
    },
    computed: {
        alphaMode() {
            return this.$store.state.alphaMode
        },
        showTwigControl() {
            return (
                this.editor
                && this.editor.tableName === 'templates_templates'
            )
        },
        isContentType() {
            return (
                this.editor
                && this.editor.tableName === 'templates_templates'
                && this.item
                && this.item.type === 'contentType'
            )
        },
        modifiedBy() {
            if (this.item) {
                return this.item.modifiedBy || this.item.createdBy
            }
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.editor.tableName,
                    id: this.editor.id
                })
                .then((obj) => {
                    return obj
                })
        }
    }
}
</script>

<style scoped>

</style>