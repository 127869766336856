<template>
    <div class="list-group-item d-flex align-items-center">
        <SearchFilterColumnMenu v-if="columns" :columns="columns" :filter="filter" :listing-name="listingName"
                                :set-filter-items="setFilterItems" :view-id="viewId"/>

        <SearchFilterColumnOptions v-if="columns" :columns="columns" :filter="filter" :listing-name="listingName"/>

        <SearchFilterField v-if="columns" :filter="filter" :listing-name="listingName" :view-id="viewId"/>

        <button class="ms-1 btn btn-light component-filters-item-remove-btn" type="button" @click="removeFilter">
            <small class="bi-trash3"/>
        </button>
    </div>
</template>

<script>
import SearchFilterColumnMenu from './SearchFilterColumnMenu'
import SearchFilterColumnOptions from './SearchFilterColumnOptions'
import SearchFilterField from './SearchFilterField'

export default {
    name: "SearchFilterItem",
    components: {
        SearchFilterColumnMenu,
        SearchFilterColumnOptions,
        SearchFilterField,
    },
    props: {
        listingName: String,
        filter: Object,
        setFilterItems: Function,
        viewId: String,
    },
    data() {
        return {
            tableName: '',
            columns: undefined,
        }
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        }
    },
    created() {
        this.tableName = this.listingName.split('.')[0]
        return this.$store.dispatch('componentStructure/get', this.componentId)
            .then((objs) => {
                let component = this.$store.state.components.items.find(o => o.id === this.componentId)
                this.setColumns(component, objs)
            })
    },
    methods: {
        setColumns(component, objs) {

            let columns = [
                {
                    columnName: this.tableName + 'ID',
                    title: 'ID',
                    type: 'number'
                }
            ]

            let activeDataTypes = [
                'checkbox',
                'color',
                'currency',
                'date',
                'dateDate',
                'datetime-local',
                'email',
                'internalLink',
                //'file',
                'number',
                //'password',
                'range',
                'relationshipOneToMany',
                'relationshipManyToMany',
                'select',
                'tel',
                'text',
                'textarea',
                'texteditor',
                'time',
                'url',
            ]
            objs.forEach((obj) => {
                if (activeDataTypes.indexOf(obj.type) >= 0) {
                    columns.push(obj)
                }
            })

            this.columns = columns
        },
        removeFilter() {
            if (this.filter.id) {
                this.$store.commit(this.viewId + '/deleteFilter', this.filter.id)

                this.$store.dispatch('request/delete', {
                        url: 'api/component/modules_filters/' + this.filter.id
                    })
                    .then(() => {
                        this.setFilterItems(true)
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>