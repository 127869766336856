var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "picture",
    { ref: "picture" },
    [
      _vm.file
        ? _c("source", { attrs: { sizes: _vm.sizes, srcset: _vm.getSrcset() } })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.types, function (format, mimeType) {
        return _vm.file
          ? _c("source", {
              key: format,
              attrs: {
                sizes: _vm.sizes,
                srcset: _vm.getSrcset(format),
                type: mimeType,
              },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.show
        ? _c("img", {
            attrs: { alt: _vm.alt, src: _vm.src },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.onClick.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }