var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.field.hidden && !_vm.field.replaceFormgroup
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.field.visibility !== "hidden",
              expression: "field.visibility !== 'hidden'",
            },
          ],
          class: ["n-" + _vm.field.name, _vm.field.class],
          attrs: { "data-form-group": "" },
        },
        [
          _vm.field.type !== "checkbox"
            ? [
                _c(
                  "label",
                  {
                    staticClass: "form-label",
                    class: { "mb-1": _vm.field.helpText },
                    attrs: { for: _vm.field.id },
                  },
                  [_vm._v(_vm._s(_vm.field.label))]
                ),
                _vm._v(" "),
                _vm.field.helpText
                  ? _c("p", {
                      staticClass: "form-text mt-0 mb-2",
                      domProps: { innerHTML: _vm._s(_vm.field.helpText) },
                    })
                  : _c("Tooltip", {
                      attrs: {
                        hide: 100,
                        show: 250,
                        tooltip: _vm.field.helpText,
                        trigger: "hover",
                      },
                    }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "has-validation" },
            [
              _vm.field.type === "checkbox"
                ? _c(
                    "div",
                    { staticClass: "form-check" },
                    [
                      _c("FormControl", {
                        class: { "is-invalid": _vm.field.error },
                        attrs: { field: _vm.field, "form-id": _vm.formId },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: _vm.field.id },
                        },
                        [_vm._v(_vm._s(_vm.field.label))]
                      ),
                      _vm._v(" "),
                      _vm.field.helpText
                        ? _c("p", {
                            staticClass: "form-text mt-0",
                            domProps: { innerHTML: _vm._s(_vm.field.helpText) },
                          })
                        : _c("Tooltip", {
                            staticClass: "ms-1",
                            attrs: {
                              hide: 100,
                              show: 250,
                              tooltip: _vm.field.helpText,
                              trigger: "hover",
                            },
                          }),
                    ],
                    1
                  )
                : _c("FormControl", {
                    class: { "is-invalid": _vm.field.error },
                    attrs: { field: _vm.field, "form-id": _vm.formId },
                  }),
              _vm._v(" "),
              _vm.field.error
                ? _c("div", {
                    staticClass: "invalid-feedback",
                    domProps: { innerHTML: _vm._s(_vm.field.error) },
                  })
                : _vm.field.required &&
                  !_vm.field.readonly &&
                  !_vm.currentData[_vm.field.name] &&
                  !_vm.field.error
                ? _c("div", { staticClass: "form-text" }, [_vm._v("Required")])
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      )
    : _vm.field.replaceFormgroup
    ? _c(
        "div",
        [
          _c("FormControl", {
            attrs: { field: _vm.field, "form-id": _vm.formId },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }