var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-dark btn-sm",
      attrs: { disabled: _vm.isDisabled },
      on: { click: _vm.moveUp },
    },
    [_c("i", { staticClass: "bi bi-chevron-up" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }