<template>
    <div v-show="show" class="dropdown">
        <Button ref="button" :class="{ active: groupName === activeGroup }" class="px-3 h-100 btn-sm rounded-0"
                data-bs-toggle="dropdown">
            {{ groupName }}
        </Button>

        <div class="dropdown-menu">
            <!-- todo - I removed wizard-nav-component="{{ component.tableName }}" -->
            <button v-for="component in components" :key="component.id"
                    :class="{ active: $store.state.navigationComponent === component.tableName }" class="dropdown-item"
                    type="button" @click="goTo(component.tableName);">
                {{ component.title }}
            </button>

            <button v-if="formAccess" class="dropdown-item" type="button"
                    @click="openNewContentTypeModal(true)">
                + New form
            </button>

            <button v-if="createContentTypeAccess()" class="dropdown-item" type="button"
                    @click="openNewContentTypeModal()">
                + New collection
            </button>
        </div>
    </div>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "NavigationGroup",
    components: {Button},
    props: {
        groupName: String,
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        components() {
            return this.$store.state.navigation.components.filter((obj) => {
                return obj.navigationGroup === this.groupName
            })
        },
        activeGroup() {
            let component = this.components.find(obj => obj.tableName === this.$store.state.navigationComponent)

            if (component) {
                return component.navigationGroup
            }

            switch (this.$route.name) {
                case 'themesEditor':
                    return 'Themes'
                case 'siteTree':
                    return 'Website'
            }
        },
        formAccess() {
            return (
                this.groupName === 'Forms'
                && (
                    this.user.administrator
                    || this.user.access.__formAccess
                )
            )
        },
        show() {
            return (
                this.components
                && (
                    // Hide groups with no components, unless it's the Form group
                    this.components.length
                    || (
                        this.groupName === 'Forms'
                        && this.formAccess
                    )
                )
            )
        }
    },
    methods: {
        goTo(tableName) {
            this.$refs.button.$el.click()

            switch (tableName) {
                case 'm_basket_reports':
                    this.$router.push({name: 'salesReports'})
                    break
                case 'site_tree':
                    this.$router.push({name: 'siteTree'})
                    break
                case 'themes_editor':
                    this.$router.push({name: 'themesEditor'})
                    break
                default:
                    this.$router.push({name: 'list', params: {component: tableName}})
            }
        },
        openNewContentTypeModal(isForm) {
            this.$store.dispatch('modals/show', {
                componentName: 'NewContentTypeModal',
                obj: {
                    isForm: isForm,
                }
            })
        },
        createContentTypeAccess() {
            return (
                this.groupName === 'Add ons'
                && this.user.administrator
            )
        }
    }
}
</script>

<style scoped>

</style>