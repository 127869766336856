<template>
    <div
        v-if="ajaxRequestInProgress"
        class="ajax-loader"
    />
</template>

<script>
export default {
    name: "AjaxLoading",
    data() {
        return {
            ajaxRequestInProgress: false,
        }
    },
    created() {
        /* todo - Should be updated to use CmsData.ajaxRequestInProgress
        $rootScope.$on('loading:progress', () => {
            $scope.ajaxRequestInProgress = true;
        });

        $rootScope.$on('loading:finish', () => {
            $scope.ajaxRequestInProgress = false;
        });
        */
    }
}
</script>

<style scoped>
.ajax-loader {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    animation: glowingLine 500ms infinite;
}

@keyframes glowingLine {
    0% {
        background-color: #fff;
    }
    50% {
        background-color: aqua;
    }
    100% {
        background-color: #fff;
    }
}
</style>