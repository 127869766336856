var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-light m-3",
      attrs: { type: "button" },
      on: { click: _vm.addFilter },
    },
    [_c("i", { staticClass: "bi-plus-lg" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }