var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.administrator
    ? _c(
        "a",
        {
          attrs: { href: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onClick.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.value))]
      )
    : _c("span", [_vm._v(_vm._s(_vm.value))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }