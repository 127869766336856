<template>
    <span class="dropdown">
        <span v-if="options.length === 1">
            {{ filter.filterData.conditionText }}
        </span>

        <button
            v-if="options.length > 1"
            class="btn btn-light ms-1"
            data-bs-toggle="dropdown"
            type="button"
        >
            {{ filter.filterData.conditionText }}
        </button>

        <ul class="dropdown-menu">
            <li v-for="(option, index) in options" :key="option.id" @click="setOption(index)">
                <a :class="{'active': option.text === filter.filterData.conditionText}" class="dropdown-item">{{ option.text }}</a>
            </li>
        </ul>
    </span>
</template>

<script>
export default {
    name: "SearchFilterColumnOptions",
    props: {
        listingName: String,
        filter: Object,
        columns: Array,
    },
    data() {
        return {
            tableName: '',
        }
    },
    computed: {
        options() {
            let options = []

            let columns = this.columns.filter((column) => {
                return column.columnName === this.filter.filterData.columnName
            })
            if (columns) {
                let column = columns[0]
                options = this.$store.getters['advancedFilters/typesOptions'](column.type)
            }

            this.updateFilterData(options)

            return options
        }
    },
    created() {
        this.tableName = this.listingName.split('.')[0]
    },
    methods: {
        updateFilterData(options) {
            let filterData = this.filter.filterData

            // todo - There seems to be a lot of duplication in the scripts below and in setOption.
            //        Can I simplify it?

            let option
            if (filterData.condition) {
                option = options.filter((option) => {
                    return option.condition === filterData.condition
                })[0]
            } else {
                option = options[0]
                filterData.condition = option.condition
                filterData.conditionText = option.text
            }

            filterData.type = option.type
        },
        setOption(i) {
            let filterData = this.filter.filterData

            let option = this.options[i]
            filterData.condition = option.condition
            filterData.conditionText = option.text
            filterData.type = option.type

            this.$store.dispatch('advancedFilters/save', {
                tableName: this.tableName,
                id: this.filter.id,
                filterData: filterData,
            })
        }
    }
}
</script>

<style scoped>

</style>