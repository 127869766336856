<template>
    <div class="h-100 px-2">
        <header class="d-flex align-items-center py-2">

            <div class="flex-fill">
                <ThemesEditorSectionMenu/>
                <ThemesEditorCssGroupsMenu/>
            </div>

            <div class="dropdown">
                <Button class="btn-sm" data-bs-toggle="dropdown">
                    <i class="bi-three-dots"/>
                </Button>

                <div class="dropdown-menu dropdown-menu-end">
                    <ThemesEditorAddBtn/>
                    <ThemesEditorSearchBtn/>
                </div>
            </div>
        </header>

        <ThemesEditorNavSection/>
    </div>
</template>

<script>
import ThemesEditorSectionMenu from './ThemesEditorSectionMenu'
import ThemesEditorCssGroupsMenu from './ThemesEditorCssGroupsMenu'
import ThemesEditorAddBtn from './ThemesEditorAddBtn'
import ThemesEditorSearchBtn from './ThemesEditorSearchBtn'
import ThemesEditorNavSection from './ThemesEditorNavSection'
import Button from "../../common/Button.vue"

export default {
    name: "ThemesEditorSidebar",
    components: {
        Button,
        ThemesEditorSectionMenu,
        ThemesEditorCssGroupsMenu,
        ThemesEditorAddBtn,
        ThemesEditorSearchBtn,
        ThemesEditorNavSection,
    },
    computed: {
        activeCssGroupName() {
            return this.$store.state.themes.activeCssGroupName
        },
        activeEditorKey() {
            return this.$store.state.themes.activeEditorKey
        },
        cssGroupNames() {
            return this.$store.state.themes.cssGroupNames
        },
        items() {
            return this.$store.state.themes.items
        },
        sectionName() {
            return this.$store.state.themes.sectionName
        },
        tableName() {
            return this.$store.state.themes.tableName
        },
    },
}
</script>

<style scoped>

</style>