export default {
    namespaced: true,
    state: {
        promises: {}
    },
    actions: {
        get({state, dispatch, commit}, emailMessageId) {
            if (!state.promises[emailMessageId]) {
                let promise = new Promise((resolve, reject) => {
                    dispatch('request/get', {
                        url: 'api/component/email_event',
                        params: {
                            fields: ['event', 'recipient', 'eventDate', 'eventData'],
                            messageId: emailMessageId,
                            sort: '-eventDate'
                        }
                    }, {root: true})
                        .then(o => {
                            resolve(o.data.items)
                        })
                })
                
                this._vm.$set(state.promises, emailMessageId, promise)
            }
            
            return state.promises[emailMessageId]
        }
    },
}