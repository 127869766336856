var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content
    ? _c(
        "span",
        { staticClass: "form-group-pages-content-title" },
        [
          _vm._v("\n    " + _vm._s(_vm.content.title) + "\n    "),
          _vm.content
            ? _c("ContentTypeLabel", {
                attrs: { "content-type-id": _vm.content.contentType },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }