<template>
    <span v-if="show" class="ms-1 d-none d-md-inline-flex align-items-center small">
        <template v-if="productTitle">
            <small role="button" @click="goToProductListing" class="px-2">Products</small>
            <i class="bi-chevron-compact-right"/>
            <small role="button" @click="goToProduct" class="px-2">{{ productTitle }}</small>
            <i class="bi-chevron-compact-right"/>
            <small class="px-2">Variations</small>
        </template>
        <small v-else class="px-2">{{ component.title }}</small>
    </span>
</template>

<script>
export default {
    name: "ListingBreadcrumbs",
    props: {
        viewId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        component() {
            return this.$store.state.components.items.find(o => o.tableName === this.tableName)
        },
        show() {
            return this.tableName !== 'account_settings'
        },
        productId() {
            return parseInt(this.$route.query.productId)
        }
    },
    asyncComputed: {
        productTitle() {
            if (
                this.tableName.indexOf('product_variations__') === 0
                && this.productId
            ) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'm_products_products',
                        id: this.productId
                    })
                    .then((obj) => {
                        return obj.title
                    })
            }

            return ''
        }
    },
    methods: {
        goToProductListing() {
            this.$router.push({
                name: 'list',
                params: {
                    component: 'm_products_products'
                }
            })
        },
        goToProduct() {
            this.$router.push({
                name: 'form',
                params: {
                    component: 'm_products_products',
                    id: this.productId
                }
            })
        }
    }
}

</script>

<style scoped>

</style>