<template>
    <div class="d-flex h-100 overflow-auto flex-column">
        <header v-if="componentHeading" class="d-flex align-items-center border-bottom">
            <span class="ms-2 flex-fill d-flex align-items-center small">
                <Button class="btn-sm lh-1 me-1 p-1" @click.native="goToListing">
                    <small>{{ component.title }}</small>
                </Button>

                <i class="bi-chevron-compact-right"/>

                <small class="px-2 d-inline-block">{{ componentHeading }}</small>
            </span>

            <ComponentEditorBtnGroup/>
        </header>

        <router-view class="overflow-auto"/>
    </div>
</template>

<script>
import ComponentEditorBtnGroup from "../ComponentEditorBtnGroup.vue"
import Button from "../../common/Button.vue"

export default {
    name: "ComponentRoot",
    components: {
        Button,
        ComponentEditorBtnGroup
    },
    computed: {
        component() {
            return this.$store.state.componentRoot.component
        },
        showHeader() {
            return (
                this.$route.name === 'componentSettings'
                || this.$route.name === 'componentFields'
            )
        },
        componentHeading() {
            switch (this.$route.name) {
                case 'componentSettings':
                    return 'Settings'
                case 'componentFields':
                    return 'Fields'
                default:
                    return ''
            }
        }
    },
    watch: {
        $route: {
            // Required so component is initialized when refreshing the browser.
            immediate: true,
            handler() {
                this.$store.dispatch('componentRoot/init', {
                    tableName: this.$route.params.component
                })
            },
        }
    },
    methods: {
        goToListing() {
            this.$router.push({
                name: 'list',
                params: {
                    component: this.component.tableName
                }
            })
        }
    }
}
</script>

<style scoped>

</style>