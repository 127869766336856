var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-start" }, [
    _c(
      "div",
      { staticClass: "flex-fill d-flex flex-wrap align-items-center" },
      [
        _c("CollectionFilterFieldsMenu", {
          staticClass: "me-1 mb-1",
          attrs: { "content-id": _vm.contentId, item: _vm.item },
        }),
        _vm._v(" "),
        _c("CollectionFilterOperatorMenu", {
          staticClass: "me-1 mb-1",
          attrs: { "content-id": _vm.contentId, item: _vm.item },
        }),
        _vm._v(" "),
        _vm.item.operator !== ""
          ? _c("CollectionFilterControl", {
              staticClass: "mb-1",
              attrs: { item: _vm.item },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-light",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.deleteFilter(_vm.item.id)
          },
        },
      },
      [_c("i", { staticClass: "bi-trash3" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }