var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.html
    ? _c("iframe", { attrs: { srcdoc: _vm.html } })
    : _c(
        "div",
        { staticClass: "themes-editor-preview-pane-ajax-loader-container" },
        [
          _c("img", {
            staticClass: "themes-editor-preview-pane-ajax-loader",
            attrs: { src: _vm.ajaxLoader, alt: "" },
          }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }