export default {
    namespaced: true,
    state: {
        tableName: '',
        componentId: 0,
        component: {},
    },
    mutations: {
        componentId(state, componentId) {
            state.componentId = componentId
        },
        component(state, component) {
            state.component = component
        },
        tableName(state, tableName) {
            state.tableName = tableName
        },
    },
    actions: {
        init({state, commit, dispatch}, {tableName}) {
            commit('tableName', tableName)
            
            dispatch('setComponentId', tableName)
        },
        setComponentId({rootState, state, commit, dispatch}) {
            let componentId = rootState.components.componentIds[state.tableName]
            commit('componentId', componentId)
            dispatch('setComponent')
        },
        setComponent({state, commit, rootState}) {
            let component = rootState.components.items.find(o => o.id === state.componentId)
            commit('component', component)
        },
    },
}