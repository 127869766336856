var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSupport
    ? _c("div", { staticClass: "overlay" }, [
        _c("div", { staticClass: "container-fluid mt-3" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-light btn-sm float-end",
                  on: {
                    click: function ($event) {
                      return _vm.hideSupport()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bi-box-arrow-right" }),
                  _vm._v("\n                    Exit\n                "),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-8 col-sm-offset-2 guides-browser" },
              [
                _c("header", { staticClass: "help-header" }, [
                  _c(
                    "div",
                    { staticClass: "btn-group float-end" },
                    [
                      _c("SupportOpenTicketBtn", {
                        staticClass: "btn-group",
                        attrs: { "hide-support": _vm.hideSupport },
                      }),
                      _vm._v(" "),
                      _c("SupportOpenDocumentationBtn", {
                        staticClass: "btn-group",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h1", [
                    _vm._v(
                      "\n                        Support\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "lead" }, [
                    _vm._v(
                      "\n                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.\n                    "
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-4 col-sm-offset-2 guides-browser" },
              [
                _c("h2", [
                  _vm._v("\n                    Guides\n                "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                    Guides specific to your current activity.\n                "
                  ),
                ]),
                _vm._v(" "),
                _vm.componentsWizards
                  ? _c(
                      "div",
                      { staticClass: "list-group" },
                      _vm._l(_vm.componentsWizards, function (id, title) {
                        return _c(
                          "a",
                          {
                            key: id,
                            staticClass: "list-group-item",
                            attrs: { "wizard-id": id, "wizard-link": "" },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(title) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.componentsWizards
                  ? _c("p", [
                      _vm._v(
                        "\n                    No wizards\n                "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-4 component-guides" }, [
      _c("h2", [_vm._v("\n                    All guides\n                ")]),
      _vm._v(" "),
      _c("div", { attrs: { "all-wizards": "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }