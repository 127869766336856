<template>
    <td v-if="column && item" :class="[typeClass, { 'editable': editable && !editMode, 'editing': editMode }]">

        <input v-if="editMode" ref="editableInput" v-model="inlineEditValue" :pattern="pattern"
               class="editableInput form-control" @blur="saveEditable" @click.stop=""
               @keydown.tab="tabToNext" @keyup.enter="saveEditable" @keyup.esc.prevent="editMode = false"
               @keyup.tab.stop.prevent="">

        <span v-else-if="editable" class="editable-container" @mousedown="edit">
            <component :is="componentName" :column="column" :item="item" :value="value" :view-id="viewId"/>
        </span>

        <component v-else :is="componentName" :column="column" :item="item" :value="value" :view-id="viewId"/>
    </td>

    <td v-else-if="column && item && !componentName">
        <span v-html="value"/>
    </td>
</template>

<script>
import camelCase from 'lodash/camelCase'

// Bespoke
import TableCellOdpCreditLogAmount from '../../bespoke/odp/TableCellOdpCreditLogAmount'

// Custom field components
// Their component names consist of 'TableCell' + tableName + columnName.
import TableCellCustomersLogin from './TableCellCustomersLogin'
import TableCellEmailEventEvent from './TableCellEmailEventEvent'
import TableCellEmailMessageEvent from './TableCellEmailMessageEvent'
import TableCellEmailMessageTo from './TableCellEmailMessageTo'
import TableCellFilesFileName from './TableCellFilesFileName'
import TableCellFilesFileSize from './TableCellFilesFileSize'
import TableCellOrderItemsSku from './TableCellOrderItemsSku'
import TableCellOrderItemsTitle from './TableCellOrderItemsTitle'
import TableCellOrderItemsVariationId from './TableCellOrderItemsVariationId'
import TableCellOrderLogs from './TableCellOrderLogs'
import TableCellOrderOrderStatus from './TableCellOrderOrderStatus'
import TableCellProductOptionsButtons from "./TableCellProductOptionsButtons"
import TableCellProductTypeId from './TableCellProductTypeId'
import TableCellProductTypesButtons from './TableCellProductTypesButtons'
import TableCellProductVariablesTitle from './TableCellProductVariablesTitle'
import TableCellUsersAssume from './TableCellUsersAssume'
import TableCellRedirectsSourceUrl from "./TableCellRedirectsSourceUrl"
import TableCellRedirectsTargetUrl from "./TableCellRedirectsTargetUrl"

// Column name components
// Their component names consist of 'TableCell' + columnName.
import TableCellDisplayOrder from './TableCellDisplayOrder'
// Type components
// Their component names consist of 'TableCellType' + type.
import TableCellTypeCheckbox from "./type/TableCellTypeCheckbox"
import TableCellTypeCodeEditor from "./type/TableCellTypeCodeEditor"
import TableCellTypeColor from "./type/TableCellTypeColor"
import TableCellTypeCurrency from "./type/TableCellTypeCurrency"
import TableCellTypeDate from "./type/TableCellTypeDate"
import TableCellTypeDateDate from "./type/TableCellTypeDateDate"
import TableCellTypeDatetimeLocal from "./type/TableCellTypeDatetimeLocal"
import TableCellTypeEmail from "./type/TableCellTypeEmail"
import TableCellTypeFile from "./type/TableCellTypeFile"
import TableCellTypeImage from "./type/TableCellTypeImage"
import TableCellTypeInternalLink from "./type/TableCellTypeInternalLink"
import TableCellTypeJson from "./type/TableCellTypeJson"
import TableCellTypeNumber from "./type/TableCellTypeNumber"
import TableCellTypePassword from "./type/TableCellTypePassword"
import TableCellTypeRange from "./type/TableCellTypeRange"
import TableCellTypeRelationshipManyToMany from "./type/TableCellTypeRelationshipManyToMany"
import TableCellTypeRelationshipOneToMany from "./type/TableCellTypeRelationshipOneToMany"
import TableCellTypeSelect from "./type/TableCellTypeSelect"
import TableCellTypeTel from "./type/TableCellTypeTel"
import TableCellTypeText from "./type/TableCellTypeText"
import TableCellTypeTextarea from "./type/TableCellTypeTextarea"
import TableCellTypeTexteditor from "./type/TableCellTypeTexteditor"
import TableCellTypeTime from "./type/TableCellTypeTime"
import TableCellTypeUrl from "./type/TableCellTypeUrl"

export default {
    name: "TableCell",
    components: {
        TableCellOdpCreditLogAmount,

        TableCellMRegistrantsRegistrantsLogin: TableCellCustomersLogin,
        TableCellEmailEventEvent,
        TableCellEmailMessageEvent,
        TableCellEmailMessageTo,
        TableCellFilesFileName,
        TableCellFilesFileSize,
        TableCellMBasketOrderitemsSku: TableCellOrderItemsSku,
        TableCellMBasketOrderitemsTitle: TableCellOrderItemsTitle,
        TableCellMBasketOrderitemsVariationId: TableCellOrderItemsVariationId,
        TableCellOrderLogs,
        TableCellOrderOrderStatus,
        TableCellProductOptionsButtons,
        TableCellMProductsProductsTypeId: TableCellProductTypeId,
        TableCellProductTypesButtons,
        TableCellProductVariablesTitle,
        TableCellUsersAssume,
        TableCellRedirectsSourceUrl,
        TableCellRedirectsTargetUrl,

        TableCellDisplayOrder,

        TableCellTypeCheckbox,
        TableCellTypeCodeEditor,
        TableCellTypeColor,
        TableCellTypeCurrency,
        TableCellTypeDate,
        TableCellTypeDateDate,
        TableCellTypeDatetimeLocal,
        TableCellTypeEmail,
        TableCellTypeFile,
        TableCellTypeImage,
        TableCellTypeInternalLink,
        TableCellTypeJson,
        TableCellTypeNumber,
        TableCellTypePassword,
        TableCellTypeRange,
        TableCellTypeRelationshipManyToMany,
        TableCellTypeRelationshipOneToMany,
        TableCellTypeSelect,
        TableCellTypeTel,
        TableCellTypeText,
        TableCellTypeTextarea,
        TableCellTypeTexteditor,
        TableCellTypeTime,
        TableCellTypeUrl,
    },
    props: {
        id: Number,
        columnId: Number,
        viewId: String,
    },
    data() {
        return {
            editMode: false,
            inlineEditValue: '',
            pattern: false,
            errorText: '',
            nextElement: []
        }
    },
    computed: {
        // Table cell type class names are used by CSS for flex box positioning.
        typeClass() {
            return this.column ? 'table-cell-type-' + this.column.type : ''
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        // Provided to custom table cell directives, such as defaultValue.
        //column() {
        //    return this.$store.state.componentStructure.items.find(o => o.id === this.columnId)
        //}
        value: {
            get() {
                if (
                    this.item
                    && this.column
                ) {
                    return this.item[this.column.columnName]
                }
            },
            set(newValue) {
                this.item[this.column.columnName] = newValue
            }
        },
        editable() {
            return this.column.browser === 2
        },
        componentName() {
            // This prevents errors caused if the column ID does not exist.
            // I think the errors were a result of a column's ID being stored in the visible columns arrays.
            // So that script may need to be updated to double-check that the column exists.
            if (this.column) {
                let columnName = this.column.columnName

                // Custom field components.
                // Their component names consist of 'TableCell' + tableName + columnName.
                // E.g. TableCellArticlesAuthor
                let tableName = this.tableName.toLowerCase().replace(/_/g, '-')
                if (this.tableName === 'm_basket_orders') {
                    tableName = 'order'
                }
                let componentName = 'table-cell-' + tableName + '-' + columnName
                let camelcase = camelCase(componentName) // E.g. tableCellMBasketOrdersFieldname
                let vueComponentName = camelcase.charAt(0).toUpperCase() + camelcase.slice(1)
                //console.log('vueComponentName', vueComponentName);
                if (vueComponentName in this.$options.components) {
                    return vueComponentName
                }

                // Column name components.
                // Their component names consist of 'TableCell' + columnName.
                // E.g. TableCellDisplayOrder
                vueComponentName = 'TableCell' + (columnName.charAt(0).toUpperCase() + columnName.slice(1))
                //console.log('vueComponentName', vueComponentName);
                if (vueComponentName in this.$options.components) {
                    return vueComponentName
                }

                // Type components.
                // Their component names consist of 'TableCellType' + type.
                // E.g. TableCellTypeText
                let type = camelCase(this.column.type)
                vueComponentName = 'TableCellType' + (type.charAt(0).toUpperCase() + type.slice(1))
                //console.log('vueComponentName', vueComponentName);
                if (vueComponentName in this.$options.components) {
                    return vueComponentName
                }
            }
        }
    },
    asyncComputed: {
        // Provided to custom table cell directives, such as defaultValue.
        column() {
            return this.$store.dispatch('itemData/get', {
                    tableName: 'modulestructure',
                    id: this.columnId
                })
                .then((obj) => {
                    return obj
                })
        },
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
                .then((obj) => {
                    return obj
                })
        }
    },
    methods: {
        edit(e) {
            if (this.editable) {
                // Must be placed here, as opposed to using Vue's event modifiers ".stop" else it conflicts
                // with the select modal's onSelect event.
                e.preventDefault()
                e.stopPropagation()

                this.setNextElement(e)

                this.editMode = true

                if (this.column.type === 'currency') {
                    this.pattern = '[0-9]+(\\.[0-9][0-9]?)?'
                    this.errorText = 'Invalid price.'
                }

                this.inlineEditValue = this.value

                this.$nextTick(() => {
                    this.$refs.editableInput.select()
                })
            }
        },
        saveEditable() {
            // If the escape key has just cancelled edit mode then this will be triggered but it should not save.
            if (!this.editMode) {
                return
            }

            if (this.$refs.editableInput.checkValidity()) {

                this.value = this.inlineEditValue

                this.$store.dispatch('itemData/set', {
                    tableName: this.tableName,
                    id: this.id,
                    property: this.column.columnName,
                    value: this.value
                })

                // Save new value to database
                this.$store.dispatch('request/patch', {
                    url: 'api/component/' + this.tableName + '/' + this.id,
                    postData: {
                        [this.column.columnName]: this.value
                    }
                })

                setTimeout(() => {
                    this.editMode = false
                }, 100)

            } else if (this.errorText) {
                // todo - Needs to display validation error.
                console.error('Needs to display validation error')
            }
        },
        // todo - Both of the following methods were an attempt to allow users to tab through the editable fields.
        // It wouldn't work because the field's focus() wasn't selecting the input, but was instead selecting the
        // "something" after the input; using shift+tab selected the input.
        setNextElement(e) {
            return
            // Find all editable elements in the table before we convert this element into a field.
            // This allows us to determine the next element in the array when the user tabs through the fields.
            let table = e.currentTarget.closest('.table')
            let editableElements = table.querySelectorAll('.editable')

            editableElements.forEach((element, key) => {
                if (element === e.currentTarget) {
                    this.nextElement = editableElements[key + 1]
                }
            })
        },
        tabToNext() {
            return
            if (this.nextElement) {
                setTimeout(() => {
                    this.nextElement.click()
                }, 100)
            }
        }
    }
}
</script>

<style scoped>
.editing {
    position: relative;
}

.editable > span {
    padding: 10px 0;
    cursor: pointer;
}

.editable > span > span {
    cursor: pointer;
    color: #103a7c;
    border-bottom: 1px dashed #ccc;
}

.editable > span:hover > span {
    border-bottom-color: #666;
}

.editableInput {
    position: absolute;
    width: calc(100% - 1rem);
    font-size: inherit;
    text-align: inherit;
    border: 1px solid #ccc;
    padding: 0 3px;
}
</style>