var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "rounded-0",
      attrs: { disabled: _vm.isDisabled },
      nativeOn: {
        click: function ($event) {
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [_c("i", { staticClass: "bi-chevron-left" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }