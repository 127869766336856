var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.contentAreas, function (contentArea, index) {
        return _vm.contentAreas && _vm.contentAreas.length
          ? _c("PageContentMobileContentArea", {
              key: contentArea,
              attrs: {
                "page-id": _vm.pageId,
                "content-area": contentArea,
                "content-area-first": index === 0,
                "content-area-last": index === _vm.contentAreas.length - 1,
                "content-areas": _vm.contentAreas,
              },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _c("PageContentOrphaned", {
        staticClass: "mb-3",
        attrs: { "page-id": _vm.pageId, "content-areas": _vm.contentAreas },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }