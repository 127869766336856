<template>
    <div class="site-tree-content-areas">
        <SiteTreeContentArea v-for="contentArea in contentAreas" :key="contentArea" :content-area="contentArea"
                             :page-id="pageId" :select-content="selectContent" :site-tree-id="siteTreeId"/>
    </div>
</template>

<script>
import SiteTreeContentArea from './SiteTreeContentArea'

export default {
    name: "SiteTreeContentAreas",
    components: {
        SiteTreeContentArea,
    },
    props: {
        siteTreeId: String,
        pageId: Number,
        selectContent: Function,
    },
    asyncComputed: {
        contentAreas() {
            return this.$store.dispatch('pageTemplatesContentAreas/getPagesContentAreas', this.pageId)
        }
    }
}
</script>

<style scoped>
.site-tree-content-areas {
    margin: 2px 0 5px 8px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #eaeaea;
    overflow: hidden;
}
</style>