var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSelectAllMessage
    ? _c("div", { staticClass: "ms-3 mb-2 py-1" }, [
        _c(
          "strong",
          [
            _vm.allItemsSelected
              ? [
                  _vm._v(
                    "\n            All " +
                      _vm._s(_vm.itemCount) +
                      " items are selected.\n            "
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "ms-1 btn btn-light btn-sm",
                      attrs: { type: "button" },
                      on: { click: _vm.clearAllItemsSelection },
                    },
                    [_vm._v("\n                Clear selection\n            ")]
                  ),
                ]
              : [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.ids.length) +
                      " of " +
                      _vm._s(_vm.itemCount) +
                      " items are selected.\n            "
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "ms-1 btn btn-light btn-sm",
                      attrs: { type: "button" },
                      on: { click: _vm.selectAllItems },
                    },
                    [
                      _vm._v(
                        "\n                Select all " +
                          _vm._s(_vm.itemCount) +
                          " items\n            "
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }