<template>
    <div v-else class="d-flex align-items-center">
        <small v-if="oldFileName" class="border rounded py-1 px-2" v-html="oldFileName"></small>
        <i v-else class="small">empty</i>

        <i class="mx-2 bi-caret-right-fill"/>

        <small v-if="newFileName" class="border rounded py-1 px-2" v-html="newFileName"></small>
        <i v-else class="small">empty</i>
    </div>
</template>

<script>
export default {
    name: "ItemHistoryChangeTypeFile",
    props: {
        oldValue: Number,
        newValue: Number
    },
    asyncComputed: {
        oldFileName() {
            if (this.oldValue) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'files',
                        id: this.oldValue
                    })
                    .then((obj) => {
                        return obj.fileName
                    })
            }
        },
        newFileName() {
            if (this.newValue) {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'files',
                        id: this.newValue
                    })
                    .then((obj) => {
                        return obj.fileName
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>