<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Credit history</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <table v-if="items" class="table table-condensed">
                    <thead>
                    <tr>
                        <td>Amount</td>
                        <td>Franchise</td>
                        <td>Booking</td>
                        <td>User</td>
                        <td class="text-end">Date</td>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="item in items">
                        <td>
                            <span v-if="item.debited">(£{{ item.amount }})</span>
                            <span v-else>£{{ item.amount }}</span>
                        </td>

                        <td>
                            <DisplayTitle :table-name="'odp_franchises'" :id="item.franchiseId"/>
                        </td>

                        <td>
                            <a v-if="item.bookingId" href @click.prevent="openBooking(item.bookingId)">
                                <!--<DisplayTitle :table-name="'odp_course_bookings'" :id="item.bookingId"/>-->
                                {{ item.bookingId }}
                            </a>
                        </td>

                        <td>
                            <DisplayTitle v-if="item.createdBy" :table-name="'users'" :id="item.createdBy"/>
                        </td>

                        <td class="text-end">
                            <span>
                                {{ getDate(item.createdDate) }}
                                <!--<Timeago :datetime="item.date * 1000"/>-->
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import DisplayTitle from '../../common/DisplayTitle'

export default {
    name: "OdpCreditHistoryModal",
    components: {
        DisplayTitle
    },
    props: {
        options: Object,
    },
    data() {
        return {
            customerId: this.options.customerId,
        }
    },
    asyncComputed: {
        items() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/odp_credit_log',
                    params: {
                        customerId: this.customerId,
                        sort: '-createdDate',
                    }
                })
                .then((obj) => {
                    return obj.data.items
                })
        }
    },
    methods: {
        getDate(timestamp) {
            if (!timestamp) return ''

            timestamp *= 1000

            return moment(timestamp).format("DD MMM 'YY @ HH:mm")
        },
        openBooking(bookingId) {
            this.$router.push({
                name: 'form',
                params: {
                    component: 'odp_course_bookings',
                    id: bookingId
                }
            })

            this.$emit('hide-modal')
        }
    },
}
</script>

<style scoped>

</style>