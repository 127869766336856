var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.typeTitle && !_vm.showMenu
        ? _c("FormControlPlainText", { attrs: { value: _vm.typeTitle } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showMenu
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentData[_vm.field.name],
                  expression: "currentData[field.name]",
                },
              ],
              staticClass: "form-select",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.currentData,
                    _vm.field.name,
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            _vm._l(_vm.options, function (option) {
              return _c("option", { domProps: { value: option.value } }, [
                _vm._v("\n            " + _vm._s(option.text) + "\n        "),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentData.typeId && !_vm.showSaveNotice
        ? _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: {
                disabled: !_vm.showEditVariationsButton,
                type: "button",
              },
              on: { click: _vm.editVariations },
            },
            [_vm._v("\n        Edit variations\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSaveNotice
        ? _c("small", [_c("em", [_vm._v("Save required")])])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }