<template>
    <a v-show="components && components.length" class="list-group-item px-2" href
       @click.prevent.stop="setActiveGroupName(groupName)">
        <div class="ps-2">{{ groupName }}</div>

        <div
            :class="{
                'd-none': $store.state.navigation.openGroupName !== groupName,
                'active d-block': $store.state.navigation.openGroupName === groupName
            }"
            class="list-group mt-2"
        >
            <a v-for="component in components" v-show="showComponent(component.tableName)"
               :class="{
                   'active': component.tableName === $store.state.navigationComponent
               }"
               class="list-group-item px-2" role="menuitem" tabindex="-1" @click="goToComponent(component.tableName)">
                {{ component.title }}
            </a>
        </div>
    </a>
</template>

<script>
export default {
    name: "NavigationMobileGroup",
    props: {
        groupName: String,
        closeMenu: Function,
    },
    computed: {
        components() {
            return this.$store.state.navigation.components.filter((obj) => {
                return obj.navigationGroup === this.groupName
            })
        }
    },
    methods: {
        setActiveGroupName(groupName) {
            this.$store.state.navigation.openGroupName = this.$store.state.navigation.openGroupName === groupName ? undefined : groupName
        },
        goToComponent(tableName) {
            window.location = '/#/' + tableName
            this.closeMenu()
        },
        user() {
            return this.$store.state.user.user
        },
        showComponent(tableName) {
            return (
                this.user.administrator
                || this.$store.getters['user/access'](tableName)
            )
        },
    }
}
</script>

<style scoped>

</style>