<template>
    <button v-if="show" class="d-flex align-items-center" type="button" @click="openModal">
        <i class="me-2 bi-arrow-up-circle-fill"/>
        Bulk upload
        <small class="ms-1 badge text-bg-warning">beta</small>
    </button>
</template>

<script>
export default {
    name: "ListingOptionsBulkUploadBtn",
    props: {
        viewId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        show() {
            return this.tableName === 'files' && this.$store.state.betaMode
        }
    },
    methods: {
        openModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'BulkUploadModal',
                obj: {
                    modalTitle: 'Bulk upload',
                    onComplete: () => {
                        //let viewId = this.$store.state.mainViewId
                        //this.$store.dispatch(viewId + '/scheduleLoad')
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>