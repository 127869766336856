<script>
export default {
    name: "FieldTypeIcon",
    props: {
        type: String
    },
    computed: {
        className() {
            switch (this.type) {
                case 'checkbox':
                    return 'bi-check2-square'
                case 'color':
                    return 'bi-palette'
                case 'collectionFilters':
                    return 'bi-filter'
                case 'currency':
                    return 'bi-currency-pound'
                case 'date':
                case 'dateDate':
                case 'datetime-local':
                    return 'bi-calendar-date'
                case 'email':
                    return 'bi-envelope'
                case 'file':
                    return 'bi-file-earmark-pdf'
                case 'image':
                    return 'bi-card-image'
                case 'internalLink':
                    return 'bi-link-45deg'
                case 'json':
                    return 'bi-braces'
                case 'number':
                    return 'bi-123'
                case 'relationshipOneToMany':
                case 'relationshipManyToMany':
                    return 'bi-diagram-3'
                case 'select':
                    return 'bi-menu-button-wide'
                case 'table':
                    return 'bi-table'
                case 'tel':
                    return 'bi-telephone'
                case 'text':
                    return 'bi-fonts'
                case 'textarea':
                    return 'bi-textarea-t'
                case 'texteditor':
                    return 'bi-body-text'
                case 'time':
                    return 'bi-clock'
                case 'url':
                    return 'bi-link'
            }
        }
    }
}
</script>

<template>
    <i v-if="className" :class="className" class="bi" :title="type"/>
    <span v-else>{{ this.type === 'customscript' || this.type === 'fieldset' ? '' : this.type }}</span>
</template>

<style scoped>

</style>