<template>
    <span>{{ displayTitle }}</span>
</template>

<script>
export default {
    name: "DisplayTitle",
    props: {
        tableName: String,
        id: Number
    },
    computed: {
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
        displayTitle() {
            if (this.item) {
                return this.item[this.nameField]
            }
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
                .then((obj) => {
                    return obj
                })
        }
    }
}

</script>

<style scoped>

</style>