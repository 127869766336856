var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "content-type--items-is-dragging": _vm.drag } },
    [
      _vm.items.length
        ? _c(
            "draggable",
            _vm._b(
              {
                attrs: {
                  direction: "vertical",
                  draggable: "[data-draggable-item]",
                },
                on: {
                  end: _vm.onDragEnd,
                  start: function ($event) {
                    _vm.drag = true
                  },
                },
                model: {
                  value: _vm.items,
                  callback: function ($$v) {
                    _vm.items = $$v
                  },
                  expression: "items",
                },
              },
              "draggable",
              _vm.dragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                {
                  staticClass: "overflow-hidden",
                  attrs: { type: "transition" },
                },
                _vm._l(_vm.items, function (item) {
                  return _vm.items
                    ? _c(
                        "Button",
                        {
                          key: item.id,
                          ref: "contentTypeItem",
                          refInFor: true,
                          staticClass:
                            "ps-0 btn-sm w-100 d-flex align-items-top text-start",
                          attrs: {
                            disabled: _vm.isDisabled,
                            "active-class": "btn-primary",
                            "active-value": item.id === _vm.selectedId,
                            "data-draggable-item": "",
                            draggable: "true",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.editItem(item.id)
                            },
                          },
                        },
                        [
                          _c("DragHandle", {
                            staticClass: "content-type--item-drag-handle",
                          }),
                          _vm._v(" "),
                          _c("FieldTypeIcon", {
                            staticClass: "me-2",
                            attrs: { type: item.type },
                          }),
                          _vm._v(" "),
                          _vm._v(
                            "\n\n                " +
                              _vm._s(item.title) +
                              "\n            "
                          ),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pre", [_vm._v(_vm._s(_vm.dragItems))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }