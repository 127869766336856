<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "FormControlPlainText",
    props: {
        value: [String, Number]
    }
})
</script>

<template>
    <p class="p-2 bg-body-tertiary rounded">
        {{ value }}
        <slot/>
    </p>
</template>

<style scoped>
/* Force the paragraph to have height even if the value is empty */
p::after {
    content: " ";
    font-size: 0;
    white-space: pre;
}
</style>