var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { ref: "form" },
    _vm._l(_vm.fields, function (field, i) {
      return _vm.formId
        ? _c(
            "div",
            { key: i, staticClass: "mb-3" },
            [
              _c("label", [_vm._v(_vm._s(field.title))]),
              _vm._v(" "),
              _c("FormControl", {
                attrs: { field: field, "form-id": _vm.formId },
              }),
            ],
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }