var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "p",
    { staticClass: "p-2 bg-body-tertiary rounded" },
    [_vm._v("\n    " + _vm._s(_vm.value) + "\n    "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }