var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasThumbnailColumn
    ? _c(
        "button",
        {
          staticClass: "d-flex align-items-center",
          attrs: { type: "button" },
          on: { click: _vm.changeView },
        },
        [
          _c("i", {
            staticClass: "me-2",
            class: {
              "bi-grid-3x3-gap-fill": _vm.viewMode === "table",
              "bi-list": _vm.viewMode === "thumbnail",
            },
          }),
          _vm._v(
            "\n    " +
              _vm._s(_vm.viewMode === "thumbnail" ? "List" : "Thumbnail") +
              " view\n"
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }