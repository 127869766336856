<template>
    <div class="root">
        <div>
            <h5 class="list-group-item-heading">Duplicate this {{ label }}</h5>

            <p class="mb-0">
                Duplicates the {{ label }}'s settings and fields.
            </p>
        </div>

        <button class="btn btn-success ms-2" href="" type="button" @click="duplicateForm">
            Duplicate this {{ label }}
        </button>
    </div>
</template>

<script>
export default {
    name: "ComponentSettingsDuplicateFormItem",
    components: {},
    computed: {
        tableName() {
            return this.$route.params.component
        },
        component() {
            return this.$store.state.components.items.find(o => o.tableName === this.tableName)
        },
        isForm() {
            return this.component.navigationGroup === 'Forms'
        },
        label() {
            return this.isForm ? 'form' : 'content type'
        }
    },
    methods: {
        duplicateForm() {
            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you sure?',
                    onConfirm: () => {
                        let tableName
                        this.$store.dispatch('request/post', {
                                url: 'api/component/modulecomponents?copyId=' + this.component.id
                            })
                            .then((obj) => {
                                tableName = obj.data.tableName

                                return this.$store.dispatch('components/init')
                            })
                            .then(() => {
                                this.$router.push({name: 'componentSettings', params: {component: tableName}})
                            })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.root {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.root > :first-child {
    flex: auto;
}
</style>