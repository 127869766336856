var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-dialog modal-fullscreen code-mirror-modal" },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header d-flex py-1" }, [
          _c("h4", {
            staticClass: "modal-title flex-fill fs-6",
            domProps: { innerHTML: _vm._s(_vm.modalTitle) },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("div", { staticClass: "dropdown" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "dropdown-menu dropdown-menu-end",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "li",
                    { staticClass: "dropdown-item text-center" },
                    [_c("CodemirrorModeMenu")],
                    1
                  ),
                  _vm._v(" "),
                  _c("CodemirrorLineNumbersBtn"),
                  _vm._v(" "),
                  _c("CodemirrorLineWrappingBtn"),
                  _vm._v(" "),
                  _vm.showTwigControl ? _c("CodemirrorTwigModeBtn") : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-close ms-2",
              attrs: { "aria-label": "Close", type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("hide-modal")
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body p-0 h-100" }, [
          _c("textarea", { ref: "codeMirror" }, [_vm._v(_vm._s(_vm.value))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer py-1" }, [
          !_vm.readOnly
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm d-flex align-items-center",
                  class: {
                    "btn-light": !_vm.changesExist,
                    "btn-warning": _vm.changesExist,
                  },
                  attrs: {
                    disabled: !_vm.changesExist || !_vm.idExists,
                    type: "button",
                  },
                  on: { click: _vm.saveChanges },
                },
                [_vm._v("\n                Save\n            ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.readOnly
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm",
                  class: {
                    "btn-light": !_vm.changesExist,
                    "btn-warning": _vm.changesExist,
                  },
                  attrs: { type: "button" },
                  on: { click: _vm.saveChangesAndCloseModal },
                },
                [_vm._v("\n                Close\n            ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.changesExist
            ? _c(
                "button",
                {
                  staticClass: "btn btn-light btn-sm modal-discard-changes",
                  attrs: { disabled: !_vm.changesExist, type: "button" },
                  on: { click: _vm.discardChanges },
                },
                [_vm._v("\n                Discard changes\n            ")]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-sm btn-light",
        attrs: { "data-bs-toggle": "dropdown", type: "button" },
      },
      [_c("i", { staticClass: "bi-three-dots" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }