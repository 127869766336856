var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn",
        class: {
          "btn-primary": !_vm.hasFieldsets,
          "btn-light": _vm.hasFieldsets,
        },
        attrs: {
          type: "button",
          "data-bs-toggle": "offcanvas",
          "data-bs-target": "#contentTypeFieldsetOffcanvas",
          role: "button",
          "aria-controls": "contentTypeFieldsetOffcanvas",
        },
      },
      [
        _c("i", { staticClass: "bi-plus-lg" }),
        _vm._v("\n        Fieldset\n    "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }