<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Notes</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <textarea
                    v-model="row.paymentNote"
                    placeholder="Type here…"
                    rows="10"
                    style="padding: 15px; width: 100%; font-size: 18px; background: #fff; border: none;"
                    @change="saveNote"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OdpPaymentNoteModal",
    props: {
        options: Object,
    },
    data() {
        return {
            row: this.options.row,
        }
    },
    methods: {
        saveNote() {
            this.$store.dispatch('itemData/update', {
                tableName: 'odp_course_bookings',
                id: this.row.id,
                data: {
                    paymentNote: this.row.paymentNote
                }
            })
        }
    },
}
</script>

<style scoped>

</style>