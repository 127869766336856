<template>
    <div>
        <div :class="{ 'content-type--items-is-dragging': drag }">
            <draggable v-if="items.length" v-model="items" direction="vertical" draggable="[data-draggable-item]"
                       v-bind="dragOptions" @end="onDragEnd" @start="drag = true">
                <transition-group class="list-group overflow-hidden" type="transition">
                    <a v-for="item in items" v-if="items" :key="item.id" ref="contentTypeItem"
                       :class="{ 'active': item.id === selectedId, 'disabled': isDisabled }"
                       class="list-group-item ps-1 d-flex align-items-center" data-draggable-item draggable="true"
                       href @click.prevent="editItem(item.id)"
                    >
                        <DragHandle class="content-type--item-drag-handle"/>

                        <!--<code v-if="$store.state.alphaMode">{{ item.displayOrder }}&nbsp;</code>-->

                        <span class="flex-fill">{{ item.legend }}</span>

                        <i data-bs-toggle="offcanvas" data-bs-target="#contentTypeFieldsetOffcanvas"
                           class="bi-pencil-square" role="button" aria-controls="contentTypeFieldsetOffcanvas"
                           @click.prevent.stop="setFormData(item)"/>
                    </a>
                </transition-group>
            </draggable>

            <pre>{{ dragItems }}</pre>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import DragHandle from '../../common/DragHandle'

export default {
    name: "ContentTypeFieldsetsList",
    components: {
        draggable,
        DragHandle
    },
    data() {
        return {
            dragIndex: undefined,
            dragItems: undefined,
            drag: false,
            dragImg: undefined,
            ajaxInProgress: false
        }
    },
    computed: {
        dragOptions() {
            return {
                //https://github.com/SortableJS/sortablejs#options
                animation: 200,
                ghostClass: 'ghost',
                setData: (dataTransfer) => {
                    // By default, browsers present a ghost version of the element being dragged, to show nothing
                    // the drag image is set as a blank img.
                    dataTransfer.setDragImage(this.dragImg, 0, 0)
                }
            }
        },
        selectedId() {
            return parseInt(this.$route.params.fieldsetId)
        },
        items: {
            // Get and set are required because the drag and drop sets items
            get() {
                return this.$store.state.componentFields.fieldsets
            },
            set(value) {
                this.$store.commit('componentFields/fieldsets', value)
            }
        },
        isDisabled() {
            return this.ajaxInProgress
        }
    },
    created() {
        let img = new Image()
        img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
        this.dragImg = img
    },
    methods: {
        editItem(id) {
            this.$router.push({
                name: 'componentFields',
                params: {
                    fieldsetId: id,
                    fieldId: null
                }
            })
        },
        onDragEnd(obj) {
            this.drag = false
            let item = this.items[obj.newIndex]
            this.ajaxInProgress = true
            this.$store.dispatch('itemData/update', {
                    tableName: 'fieldset',
                    id: item.id,
                    data: {
                        displayOrder: obj.newIndex + 1
                    }
                })
                .then(() => {
                    this.$store.dispatch('componentFields/reloadFieldsets')
                        .finally(() => {
                            this.ajaxInProgress = false
                        })
                    /*this.$store.dispatch('componentStructure/init')
                        .finally(() => {
                            this.ajaxInProgress = false
                        })*/
                })
        },
        setFormData(obj) {
            this.$store.state.componentFields.fieldsetFormData = JSON.parse(JSON.stringify(obj))
        }
    }
}
</script>

<style scoped>
.ghost {
    background: #666 !important;
    border-color: #666 !important;
    color: #fff !important;
}

.content-type--item-drag-handle {
    opacity: 0;
    color: #999;
}

.list-group-item:hover .content-type--item-drag-handle {
    opacity: 1;
}

.list-group-item i {
    opacity: 0;
}

.list-group-item:hover i {
    opacity: 1;
}

/* Hide the drag handle when moving avoids an issue where the wrong item's handle is shown */
.content-type--items-is-dragging .content-type--item-drag-handle {
    opacity: 0 !important;
}
</style>