var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "element",
      staticClass: "m-3 mt-4 me-2",
      attrs: { role: "tablist" },
    },
    _vm._l(_vm.legends, function (legend, index) {
      return _c("FormFieldsetAnchor", {
        key: legend,
        class: { active: _vm.isActive(legend, index) },
        attrs: { title: legend },
        nativeOn: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.setActiveFieldset(legend)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }