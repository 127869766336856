<template>
    <div class="d-flex align-items-start">
        <div class="flex-fill d-flex flex-wrap align-items-center">
            <CollectionFilterFieldsMenu :content-id="contentId" :item="item" class="me-1 mb-1"/>

            <CollectionFilterOperatorMenu :content-id="contentId" :item="item" class="me-1 mb-1"/>

            <CollectionFilterControl v-if="item.operator !== ''" :item="item" class="mb-1"/>
        </div>

        <button type="button" class="btn btn-sm btn-light" @click="deleteFilter(item.id)">
            <i class="bi-trash3"/>
        </button>

        <!--<pre><small>{{ item }}</small></pre>-->
        <!--<pre><small>{{ item.value }}</small></pre>-->
    </div>
</template>

<script>
import CollectionFilterFieldsMenu from "./CollectionFilterFieldsMenu.vue"
import CollectionFilterOperatorMenu from "./CollectionFilterOperatorMenu.vue"
import CollectionFilterControl from "./CollectionFilterControl.vue"

export default {
    name: "CollectionFilter",
    components: {CollectionFilterControl, CollectionFilterOperatorMenu, CollectionFilterFieldsMenu},
    props: {
        contentId: Number,
        item: Object,
    },
    data() {
        return {
            oldItem: structuredClone(this.item)
        }
    },
    watch: {
        item: {
            handler(newValue) {
                this.$store.dispatch('collectionFilters/updateItem', {
                    item: newValue,
                    oldItem: this.oldItem
                })

                // Note, when deep watching objects, both newValue and oldValue will provide the latest object because
                // they're passed by reference, so we must retain a copy of the old object for reference.
                this.oldItem = structuredClone(newValue)
            },
            deep: true
        }
    },
    methods: {
        deleteFilter(filterId) {
            this.$store.dispatch('collectionFilters/deleteFilter', {
                filterId: filterId,
                contentId: this.contentId
            })
        },
    }
}
</script>

<style scoped>

</style>