export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        items(state, items) {
            state.items = items
        }
    },
    actions: {
        init({commit, dispatch}) {
            return dispatch('request/get', {
                url: 'api/page-content-link-data'
            }, {root: true})
                .then((obj) => {
                    commit('items', obj.data)
                })
        }
    }
}