<template>
    <Button v-if="usage !== undefined" class="btn-sm p-0 px-1" @click.prevent.stop.native="showPanel">
        <small>{{ usage }}</small>
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "ThemesEditorUsage",
    components: {Button},
    props: {
        id: Number,
    },
    data() {
        return {
            usage: undefined,
        }
    },
    computed: {
        sectionName() {
            return this.$store.state.themes.sectionName
        },
    },
    created() {
        // Only display on the Content Types tab.
        if (this.sectionName !== 'content_types') {
            return
        }

        this.$store.dispatch('themes/getItemsUsage')
            .then((obj) => {
                this.usage = obj[this.id] || 0
            })
    },
    methods: {
        showPanel() {
            this.$store.dispatch('modals/show', {
                componentName: 'ThemesUsageModal',
                obj: {
                    id: this.id
                }
            })
        }
    }
}
</script>

<style scoped>

</style>