<template>
    <span v-if="hasGroupAccess || isUnpublished || isScheduled || isArchived">
        <span v-if="hasGroupAccess" class="icon" style="cursor: default">
            <i class="bi-person-fill" title="This page is assigned group access"/>
        </span>

        <span v-if="isUnpublished" class="icon icon-unpublished" style="cursor: default"
              title="Unpublished">
            <i class="bi-x-circle-fill" title="Unpublished"/>
        </span>

        <span v-else-if="isScheduled" :title="schedulingTitle" class="icon" style="cursor: default">
            <i :class="{ 'icon-pending': isPending, 'icon-expiring': isExpiring, 'icon-expired': isExpired }"
               class="bi-clock-history"/>
        </span>

        <small v-if="isArchived" class="badge text-bg-secondary pe-none">archived</small>
    </span>
</template>

<script>
export default {
    name: "ItemStatusIcons",
    props: {
        tableName: String,
        id: Number
    },
    computed: {
        isUnpublished() {
            return this.item?.status === 0
        },
        isArchived() {
            return this.item?.isArchived === 1
        },
        isScheduled() {
            return (
                this.isPending
                || this.isExpiring
                || this.isExpired
            )
        },
        isPending() {
            let now = Date.now() / 1000
            return this.item?.validFrom >= now
        },
        isExpiring() {
            let now = Date.now() / 1000
            return (
                this.item?.validTo >= now
                // If it's pending ignore expiring
                && !this.isPending
            )
        },
        isExpired() {
            let now = Date.now() / 1000
            return (
                this.item?.validTo
                && this.item.validTo <= now
            )
        },
        schedulingTitle() {
            switch (true) {
                case this.isPending:
                    return 'Pending'
                case this.isExpiring:
                    return 'Expiring'
                case this.isExpired:
                    return 'Expired'
            }
        },
        hasGroupAccess() {
            const objs = this.$store.getters['categories/get'](this.tableName, 'groupAccess', this.item?.id)
            return objs ? objs.length > 0 : false
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
                .then((obj) => {
                    return obj
                })
        }
    }
}
</script>

<style scoped>
.icon {
    padding: 0 10px;
}

.icon-unpublished {
    color: #d9534f;
}

.icon-pending {
    color: #198754;
}

.icon-expiring {
    color: #ffc107;
}

.icon-expired {
    color: #dc3545;
}
</style>