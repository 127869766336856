export default {
    props: {
        formId: String,
        field: Object,
        value: [String, Number, Date], // FormControlTypeInternalLink uses Number
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        isModified() {
            return this.$store.getters[this.formId + '/isModified']()
        },
        franchiseId() {
            return this.$store.state.user.user.franchiseId
        },
        hasFranchiseIdColumn() {
            if (this.franchiseId) {
                const componentId = this.$store.state.components.componentIds[this.tableName]
                const item = this.$store.state.componentStructure.items.find(obj => obj.modulecomponentsID === componentId && obj.columnName === 'franchiseId')
                return item !== undefined
            }
        }
    },
    watch: {
        value: {
            handler(val) {
                if (
                    this.field.uniqueValuesOnly
                    
                    // This was disabled because duplicated Redirect item's Source URL wasn't invalidating until
                    // modified.
                    // Update: I had to enable it again because it resulted in validation errors appearing on load
                    // for new forms where this was applied to required fields. For example, when creating a new
                    // product in PG, the Title and URL Slug fields both presented "Please fill in this field." errors.
                    // Update: Using && this.value below, has fixed the issue
                    //&& this.isModified
                    && this.value
                ) {
                    //console.log(this.value)
                    
                    const params = {
                        [this.field.name]: val,
                        [this.tableName + 'ID']: {ne: this.id}
                    }
                    
                    // If the user is assigned to a franchise, then we must check if this component has a franchiseId field.
                    // If so, the validation must allow for each franchise to have its own instance of the value.
                    if (this.hasFranchiseIdColumn) {
                        params.franchiseId = this.franchiseId
                    }
                    
                    return this.$store.dispatch('request/get', {
                            url: 'api/component/' + this.tableName,
                            params: params
                        })
                        .then((obj) => {
                            let msg = obj.data.items.length
                                ? 'Duplicate value. A unique value is required.'
                                : ''
                            
                            // todo - field.error is not needed if the field element is directly accessed as
                            //  el.checkValidity will trigger the error. I can't remove it though as not all uses of
                            //  this set $refs.field. To fix this:
                            //  1. All components which use uniqueValuesOnly should be updated so that they use a new
                            //     formInput component, which only contains the input element.
                            //  2. uniqueValuesOnly can then be applied to that component.
                            //  3. Once in place this.$el can be then be used here to target the element.
                            this.field.error = msg
                            
                            let el = this.$refs.field
                            if (el) {
                                el.setCustomValidity(msg)
                                el.checkValidity()
                            }
                        })
                }
            },
            immediate: true
        }
    }
}