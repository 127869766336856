var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formData.monthsAgo,
          expression: "formData.monthsAgo",
        },
      ],
      staticClass: "ms-2 form-select form-select-sm",
      attrs: {
        required: "",
        name: "months_ago",
        oninvalid: "this.setCustomValidity('Please select a date range')",
        oninput: "this.setCustomValidity('')",
      },
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.$set(
            _vm.formData,
            "monthsAgo",
            $event.target.multiple ? $$selectedVal : $$selectedVal[0]
          )
        },
      },
    },
    [
      _c("option", { attrs: { value: "" } }, [_vm._v("Select…")]),
      _vm._v(" "),
      _vm._l(
        {
          1: "1 month",
          3: "3 months",
          6: "6 months",
          12: "12 months",
          24: "24 months",
        },
        function (text, value) {
          return _c("option", { domProps: { value: value } }, [
            _vm._v("\n        " + _vm._s(text) + "\n    "),
          ])
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }