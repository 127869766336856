var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormControlTypeRelationshipManyToMany", {
        ref: "myChild",
        attrs: {
          "current-data": _vm.currentData,
          field: _vm.field,
          "form-id": _vm.formId,
        },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "mt-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { disabled: _vm.btnDisabled },
            on: { click: _vm.sendEmail },
          },
          [_vm._v("\n            Send email\n        ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }