var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.readonly
    ? _c("FormControlPlainText", {
        attrs: { value: _vm.currentData[_vm.field.name] },
      })
    : _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentData[_vm.field.name],
              expression: "currentData[field.name]",
            },
            { name: "form-validation", rawName: "v-form-validation" },
          ],
          staticClass: "form-select w-auto",
          attrs: {
            id: _vm.field.id,
            name: _vm.field.name,
            required: _vm.field.required,
          },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.currentData,
                _vm.field.name,
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _vm.firstOption
            ? _c(
                "option",
                {
                  attrs: { disabled: _vm.field.required },
                  domProps: {
                    value: _vm.firstOption.value,
                    selected: _vm.field.required,
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.firstOption.text) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.optionsArray, function (option) {
            return _c("option", { domProps: { value: option.value } }, [
              _vm._v("\n        " + _vm._s(option.text) + "\n    "),
            ])
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }