var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field.readonly
    ? _c("FormControlPlainText", {
        attrs: { value: _vm.currentData[_vm.field.name] },
      })
    : _c(
        "div",
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentData[_vm.field.name],
                expression: "currentData[field.name]",
              },
              { name: "form-validation", rawName: "v-form-validation" },
              { name: "textarea-auto-grow", rawName: "v-textarea-auto-grow" },
            ],
            ref: "field",
            staticClass: "form-control",
            attrs: {
              id: _vm.field.id,
              cols: _vm.field.cols,
              disabled: _vm.field.disabled,
              minlength: _vm.field.minlength,
              name: _vm.field.name,
              placeholder: _vm.field.placeholder || false,
              required: _vm.field.required,
              rows: _vm.field.rows,
              wrap: _vm.field.wrap,
            },
            domProps: { value: _vm.currentData[_vm.field.name] },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.currentData, _vm.field.name, $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.field.maxlength
            ? _c("MaxLengthCounter", {
                attrs: {
                  maxlength: _vm.field.maxlength,
                  value: _vm.currentData[_vm.field.name],
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }