<template>
    <i class="d-flex align-items-center small">
        modified
    </i>
</template>

<script>
export default {
    name: "ItemHistoryChangeTypePassword",
    props: {
        oldValue: String,
        newValue: String
    }
}
</script>

<style scoped>

</style>