<template>
    <div v-show="contentIds.length" class="site-tree-content-area clearfix">
        <div class="site-tree-content-area-label">
            {{ contentArea }}
        </div>

        <div class="site-tree-content-area-inner">
            <SiteTreeContentItem v-for="contentId in contentIds" :key="contentId" :page-content-id="contentId"
                                 :select-content="selectContent" :site-tree-id="siteTreeId"/>
        </div>
    </div>
</template>

<script>
import SiteTreeContentItem from './SiteTreeContentItem'

export default {
    name: "SiteTreeContentArea",
    components: {
        SiteTreeContentItem,
    },
    props: {
        siteTreeId: String,
        pageId: Number,
        contentArea: String,
        selectContent: Function
    },
    computed: {
        contentIds() {
            return this.$store.state.pageContentData.items.filter(
                    o => o.pageId === this.pageId
                        && o.contentArea === this.contentArea
                )
                .map(o => o.id) // Reduce the objects to an array of pageContentIds.
        }
    }
}
</script>

<style scoped>
.site-tree-content-area {
    position: relative;
    z-index: 1;
    padding: 5px 0;
    border-bottom: 1px solid #eaeaea;
    line-height: 1;
}

.site-tree-content-area:last-child {
    border-bottom: none;
}

.site-tree-content-area-label {
    float: right;
    margin-right: 5px;
    font-size: 11px;
    color: #999;
}

.site-tree-content-area-inner {
    float: left;
}
</style>