var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _vm.oldFileName
      ? _c("small", {
          staticClass: "border rounded py-1 px-2",
          domProps: { innerHTML: _vm._s(_vm.oldFileName) },
        })
      : _c("i", { staticClass: "small" }, [_vm._v("empty")]),
    _vm._v(" "),
    _c("i", { staticClass: "mx-2 bi-caret-right-fill" }),
    _vm._v(" "),
    _vm.newFileName
      ? _c("small", {
          staticClass: "border rounded py-1 px-2",
          domProps: { innerHTML: _vm._s(_vm.newFileName) },
        })
      : _c("i", { staticClass: "small" }, [_vm._v("empty")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }