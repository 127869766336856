var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "dropzone", staticClass: "well dropzone" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center" },
      [
        _vm._v(
          "\n        Drag " + _vm._s(_vm.fileStr) + " here, or\n\n        "
        ),
        _c(
          "Button",
          {
            staticClass: "ms-2 btn-sm",
            attrs: { "default-class": "btn-secondary" },
            nativeOn: {
              click: function ($event) {
                return _vm.chooseFile.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n            Browse\n        ")]
        ),
        _vm._v(" "),
        _c("input", {
          ref: "fileInput",
          staticClass: "d-none",
          attrs: { multiple: _vm.multiple, type: "file" },
          on: { change: _vm.onChangeFileInput },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.files.length
      ? _c("div", { staticClass: "mt-3" }, [
          _c(
            "div",
            { staticClass: "list-group" },
            _vm._l(_vm.files, function (file) {
              return _c(
                "div",
                { staticClass: "list-group-item" },
                [
                  _c("p", { staticClass: "file-info" }, [
                    _c("span", [_vm._v(_vm._s(file.name))]),
                    _vm._v(" "),
                    file.percentage >= 0
                      ? _c("code", [
                          _vm._v(_vm._s(_vm.humanFileSize(file.size))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    file.error
                      ? _c("span", { staticClass: "badge bg-danger" }, [
                          _vm._v(_vm._s(file.error)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    file.percentage >= 0
                      ? _c("span", [_vm._v(_vm._s(file.percentage) + "%")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  file.percentage >= 0
                    ? _c("ProgressBar", {
                        attrs: { percentage: file.percentage },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }