var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "button",
        {
          staticClass: "btn btn-light btn-sm",
          attrs: { type: "button" },
          on: { click: _vm.login },
        },
        [_vm._v("\n    Login\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }