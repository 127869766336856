<template>
    <div>
        <button class="btn btn-light" type="button" @click="resetDisplayOrders">
            Display orders
        </button>

        <button class="btn btn-light" type="button" @click="resetFileUsage">
            File usage
        </button>

        <button v-if="betaMode" class="btn btn-light" type="button" @click="purgeCache">
            Purge cache
        </button>
    </div>
</template>

<script>
export default {
    name: "FormControlAccountSettingsReorderScripts",
    props: {
        field: Object,
    },
    computed: {
        betaMode() {
            return this.$store.state.betaMode
        }
    },
    created() {
        this.field.label = 'Reset'
    },
    methods: {
        resetDisplayOrders() {
            document.body.style.cursor = 'wait'

            this.$store.dispatch('request/get', {
                    url: 'api/reset-all-display-orders'
                })
                .then(() => {
                    this.$store.dispatch('toasts/add', {
                        title: 'Reset display orders',
                        body: '<p>Complete!</p><strong>Please refresh your browser.</strong>'
                    })
                })
                .finally(() => {
                    document.body.style.cursor = 'initial'
                })
        },
        resetFileUsage() {
            document.body.style.cursor = 'wait'
            this.$store.dispatch('request/get', {
                    url: 'api/reset-file-usage'
                })
                .then(() => {
                    this.$store.dispatch('toasts/add', {
                        title: 'Reset file usage',
                        body: '<p>Complete!</p><strong>Please refresh your browser.</strong>'
                    })
                })
                .finally(() => {
                    document.body.style.cursor = 'initial'
                })
        },
        purgeCache() {
            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you sure?',
                    modalBody: `
                          <div class="alert alert-danger">
                            This will negatively impact the performance of the site until the caches are rebuilt.
                          </div>
                          <p>
                            This action should be a last resort.
                          </p>
                        `,
                    //confirmStr: item.title,
                    onConfirm: () => {
                        document.body.style.cursor = 'wait'
                        this.$store.dispatch('request/get', {
                                url: 'api/purge-cache'
                            })
                            .then((o) => {
                                document.body.style.cursor = 'initial'

                                console.log({o})

                                return this.$store.dispatch('toasts/notification', {
                                    title: 'Clear the cache',
                                    message: 'Starting&hellip;'
                                })

                            })
                            .then((obj) => {
                                this.$store.dispatch('clearCache', {
                                    notificationId: obj.notificationId
                                })
                            })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>