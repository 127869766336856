<template>
    <Button :title="title" class="d-block btn-sm w-100 text-start">
        <i v-if="className" class="me-2" :class="className"/>
        {{ title }}
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "FormFieldsetAnchor",
    components: {Button},
    props: {
        title: String,
    },
    data() {
        return {
            className: this.getClassName(this.title),
        }
    },
    methods: {
        getClassName(title) {
            let icons = {
                'Access': 'bi-user',
                'General': 'bi-pencil-square',
                'Google data feed': 'bi-google',
                'Media': 'bi-image',
                'Pricing': 'bi-currency-pound',
                'Scheduling': 'bi-calendar',
                'SEO': 'bi-graph-up',
                'Settings': 'bi-gear-fill',
                'Shipping': 'bi-truck',
                'Stock': 'bi-123',
                'Tags': 'bi-tags',
            }

            return icons[title] || 'bi-dash'
        }
    }
}
</script>

<style scoped>

</style>