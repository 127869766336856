<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <p v-html="message"></p>
                <ProgressBar :percentage="percentage"/>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from '../common/ProgressBar'

export default {
    name: "ProgressModal",
    props: {
        options: Object,
    },
    components: {
        ProgressBar,
    },
    data() {
        return {
            modalTitle: this.options.modalTitle,
            onComplete: this.options.onComplete,
            item: {},
            interval: undefined
        }
    },
    computed: {
        data() {
            return this.item ? this.item.data : {}
        },
        message() {
            if (this.data) {
                return this.data.message || ''
            }
        },
        percentage() {
            if (this.data) {
                return this.data.percentage || 0
            }
        }
    },
    created() {
        this.interval = setInterval(() => {
            this.$store.dispatch('request/get', {
                    url: 'api/notification/' + this.options.activityId
                })
                .then((obj) => {
                    this.item = obj.data.item
                })
        }, 500)
    },
    watch: {
        percentage(val) {
            if (val === 100) {
                clearInterval(this.interval)

                if (this.onComplete) {
                    this.onComplete()
                }
            }
        }
    },
}
</script>

<style scoped>

</style>