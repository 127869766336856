<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">How may we help you?</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <p>Please fill in details below, and we'll get back to you as soon as possible.</p>

                <form @submit.prevent="onSubmit">
                    <fieldset class="form-fieldset">
                        <!-- Subject -->
                        <div class="mb-4">
                            <label class="form-label visually-hidden" for="">Subject</label>
                            <input v-model="subject" class="form-control" placeholder="Subject" required type="text">
                        </div>

                        <!-- Message -->
                        <div class="mb-4">
                            <label class="form-label visually-hidden" for="">Message</label>
                            <textarea ref="field" v-model="message" v-textarea-auto-grow class="form-control"
                                      placeholder="Message" required/>
                        </div>

                        <!-- Submit btn -->
                        <div class="text-center">
                            <button :disabled="disabled" class="btn btn-light" type="submit">Submit</button>
                        </div>
                    </fieldset>
                </form>
            </div>

        </div>
    </div>
</template>

<script>
import textareaAutoGrow from '../../vue/directives/textareaAutoGrow'

export default {
    name: "ZendeskModal",
    directives: {
        textareaAutoGrow,
    },
    data: function() {
        return {
            subject: '',
            message: '',
            disabled: false,
        }
    },
    methods: {
        onSubmit: function() {
            this.disabled = true

            this.$store.dispatch('request/post', {
                    url: 'api/SupportController/sendZendeskEmail',
                    postData: {
                        subject: this.subject,
                        message: this.message
                    }
                })
                .then(() => {
                    this.disabled = false // Reset the form for its next use
                    this.subject = ''
                    this.message = ''
                    this.$emit('hide-modal')
                })
        }
    },
}
</script>

<style scoped>

</style>