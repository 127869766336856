<template>
    <div>
        <FormControlPlainText>
            {{ currentData[field.name] }}
        </FormControlPlainText>

        <textarea ref="codeMirror" style="display: none"></textarea>
    </div>
</template>

<script>
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlActivityRowId",
    components: {
        FormControlPlainText
    },
    props: {
        field: Object,
        currentData: Object,
        value: Number,
    },
    created() {
        this.getItemData()
    },
    methods: {
        getItemData() {
            this.$store.dispatch('request/get', {
                    url: 'api/activity/' + this.currentData.id
                })
                .then((o) => {
                    const indent = 2

                    const editor = CodeMirror.fromTextArea(this.$refs.codeMirror, {
                        mode: 'application/json',
                        lineNumbers: true,
                        // Line wrapping must be enabled when presenting CodeMirror in a form else long lines will
                        // stretch the form beyond the viewport.
                        lineWrapping: true,
                        // todo - Changes aren't being detected by the form so this has been disabled.
                        readOnly: true,//ctrl.field.readonly,
                        indentUnit: indent,
                        tabMode: 'spaces',
                        enterMode: 'indent',
                        electricChars: false//, // Prevents '{' from resetting the indent
                    })

                    const data = o.data.item.data
                    const json = JSON.stringify(data, undefined, indent)
                    editor.getDoc().setValue(json)
                })
        }
    }
}
</script>

<style scoped>

</style>