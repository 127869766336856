var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Notes")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.row.paymentNote,
              expression: "row.paymentNote",
            },
          ],
          staticStyle: {
            padding: "15px",
            width: "100%",
            "font-size": "18px",
            background: "#fff",
            border: "none",
          },
          attrs: { placeholder: "Type here…", rows: "10" },
          domProps: { value: _vm.row.paymentNote },
          on: {
            change: _vm.saveNote,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.row, "paymentNote", $event.target.value)
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }