var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "dropdown-item",
      attrs: { type: "button" },
      on: { click: _vm.setPref },
    },
    [
      _c("span", {
        staticClass: "me-2",
        class: { "bi-square": !_vm.twigMode, "bi-check-square": _vm.twigMode },
      }),
      _vm._v("\n    Highlight Twig code\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }