<template>
    <div>
        <h1 style="font-size: 16px">
            {{ heading }}
        </h1>

        <div style="font-size: 11px">
            <p>
                {{ reportDate }}
            </p>

            <p v-if="startDate && endDate">
                Period - {{ getPeriodDate(startDate) }} to {{ getPeriodDate(endDate) }}
            </p>

            <table class="table table-condensed">
                <thead>
                <tr>
                    <td v-for="(value, heading) in rows[0]">
                        {{ heading }}
                    </td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="row in rows">
                    <td v-for="(value, heading) in row">
                        {{ value }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "PrintReport",
    props: {
        heading: String,
        date: String,
        startDate: String,
        endDate: String,
        rows: Array
    },
    computed: {
        reportDate() {
            return this.getDate(new Date())
        }
    },
    methods: {
        getDate(timestamp) {
            return moment(timestamp).format('Do MMMM YYYY')
        },
        getPeriodDate(timestamp) {
            return moment(timestamp).format('Do MMMM YYYY')
        }
    }
}
</script>

<style scoped>

</style>