<template>
    <select v-model="theme" class="form-select" @change="setPref">
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "CodemirrorModeMenu",
    data() {
        return {
            theme: this.$store.getters['user/pref']('codeMirror_theme'),
            options: [
                {value: 'default', text: 'Default'},
                {value: '3024-day', text: '3024 day'},
                {value: '3024-night', text: '3024-night'},
                {value: 'ambiance', text: 'Ambiance'},
                {value: 'base16-dark', text: 'Base16 dark'},
                {value: 'base16-light', text: 'Base16 light'},
                {value: 'blackboard', text: 'Blackboard'},
                {value: 'cobalt', text: 'Cobalt'},
                {value: 'eclipse', text: 'Eclipse'},
                {value: 'elegant', text: 'Elegant'},
                {value: 'erlang-dark', text: 'Erlang dark'},
                {value: 'lesser-dark', text: 'Lesser dark'},
                {value: 'midnight', text: 'Midnight'},
                {value: 'monokai', text: 'Monokai'},
                {value: 'neat', text: 'Neat'},
                {value: 'night', text: 'Night'},
                {value: 'rubyblue', text: 'Rubyblue'},
                {value: 'solarized', text: 'Solarized'},
                {value: 'tomorrow-night-eighties', text: 'Tomorrow night eighties'},
                {value: 'twilight', text: 'Twilight'},
                {value: 'vibrant-ink', text: 'Vibrant ink'}
            ],
        }
    },
    mounted() {
        // If the user's theme pref has not yet been set, default it to Monokai.
        if (this.theme === undefined) {
            this.theme = 'monokai'
            this.setPref()
        }
    },
    methods: {
        setPref() {
            this.$store.dispatch('user/setPref', {
                name: 'codeMirror_theme',
                value: this.theme
            })
        }
    }
}
</script>

<style scoped>

</style>