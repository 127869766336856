<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]"/>
    <div v-else>
        <textarea :id="field.id" ref="field" v-model="currentData[field.name]" v-form-validation v-textarea-auto-grow
                  :cols="field.cols" :disabled="field.disabled" :minlength="field.minlength" :name="field.name"
                  :placeholder="field.placeholder || false" :required="field.required" :rows="field.rows"
                  :wrap="field.wrap" class="form-control"/>
        <MaxLengthCounter v-if="field.maxlength" :maxlength="field.maxlength" :value="currentData[field.name]"/>
    </div>
</template>

<script>
import MaxLengthCounter from '../../vue/common/MaxLengthCounter'

import textareaAutoGrow from '../../vue/directives/textareaAutoGrow'
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeTextarea",
    components: {
        FormControlPlainText,
        MaxLengthCounter,
    },
    directives: {
        textareaAutoGrow,
        formValidation,
    },
    props: {
        field: Object,
        currentData: Object,
        value: String,
    },
}
</script>

<style scoped>

</style>