var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("thead", [
    _c(
      "tr",
      [
        !_vm.selectMode
          ? _c(
              "th",
              {
                staticClass: "table-cell-select",
                class: { selected: _vm.allSelected },
                attrs: { title: "Select all or none" },
                on: { click: _vm.selectAll },
              },
              [
                _c("small", [
                  _c("span", {
                    class: {
                      "bi-square": !_vm.allSelected,
                      "bi-check-square": _vm.allSelected,
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("TableViewHeadCell", {
          attrs: {
            column: { name: _vm.primaryKey, title: "ID#" },
            "view-id": _vm.viewId,
          },
        }),
        _vm._v(" "),
        _vm._l(_vm.columns, function (columnId) {
          return _c("TableViewHeadCell", {
            key: columnId,
            attrs: { "column-id": columnId, "view-id": _vm.viewId },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }