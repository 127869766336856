<template>
    <button @click="remove" :disabled="isDisabled" class="btn btn-danger">
        <i class="bi bi-x-lg"></i> Remove
    </button>
</template>

<script>
import {Offcanvas} from "bootstrap"

export default {
    name: "PageEditorContentRemoveBtn",
    computed: {
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        isDisabled() {
            return !this.pageContentId
        }
    },
    methods: {
        remove() {
            this.$store.dispatch('pageEditor/removeContent', {
                pageContentId: this.pageContentId
            })
        }
    }
}
</script>

<style scoped>

</style>