var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "ms-1 d-inline-flex align-items-center small text-nowrap" },
    [
      _c(
        "Button",
        {
          staticClass: "btn-sm lh-1 me-1 p-1",
          nativeOn: {
            click: function ($event) {
              return _vm.goToListing.apply(null, arguments)
            },
          },
        },
        [_c("small", [_vm._v(_vm._s(_vm.component.title))])]
      ),
      _vm._v(" "),
      _vm.showTitle
        ? [
            _c("i", { staticClass: "bi-chevron-compact-right" }),
            _vm._v(" "),
            _c("small", { staticClass: "px-2 text-truncate" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }