<template>
    <FormControlPlainText v-if="currentData.id && !betaMode" :value="currentData[field.name]"/>
    <select v-else :id="field.id" v-model="currentData[field.name]" v-form-validation :name="field.name"
            :required="field.required" class="form-select">
        <option :value="''">Select…</option>
        <option v-for="option in options" :value="option">
            {{ option }}
        </option>
    </select>
</template>

<script>
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "./FormControlPlainText.vue"

export default {
    name: "FormControlTemplatesTemplatesContentType",
    components: {FormControlPlainText},
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
        value: String,
    },
    data() {
        return {
            options: [
                'Basket',
                'Categories',
                'Filter',
                'Form',
                'Form - Forgot password',
                'Form - Registration',
                'Form builder',
                'Form builder - Registration',
                'Listing',
                'Navigation',
                'Product filter',
                'Product navigation',
                'Tags',
                'Teaser',
            ],
        }
    },
    computed: {
        betaMode() {
            return this.$store.state.betaMode
        },
    },
    created() {
        this.$store.dispatch(this.formId + '/setDefaultValue', {
            name: this.field.name,
            value: '',
        })
    }
}
</script>

<style scoped>

</style>