<template>
    <span ref="dropdown" class="dropdown d-inline-block">
        <Button class="rounded-0" data-bs-toggle="dropdown" type="button">
            <i class="bi bi-list"/>
        </Button>

        <span class="dropdown-menu" @click.stop>
            <template v-for="contentAreaName in contentAreaNames">
                <a role="button"
                   class="content-area-item dropdown-item small strong text-body-tertiary text-uppercase d-flex align-items-center py-0 pe-1"
                   @mouseover="mouseoverContentArea(contentAreaName)"
                   @mouseout="mouseoutContentArea"
                   @click="selectContentArea(contentAreaName)">
                    <small class="flex-fill">{{ contentAreaName }}</small>
                    <button @click="showAddContentModal(contentAreaName)" :title="'Add content to: ' + contentAreaName"
                            class="btn btn-link btn-sm py-1" type="button"><i class="bi bi-plus-lg"/></button>
                </a>

                <a v-for="(content, i) in getContentAreaContent(contentAreaName)" :key="contentAreaName + '.' + i"
                   role="button" class="dropdown-item small"
                   @mouseover="mouseoverContent(content.pageContentId)"
                   @mouseout="mouseoutContent"
                   @click="selectContent(content.pageContentId)"
                ><PageEditorToolbarContentDropdownContentType :page-content-id="content.pageContentId"/></a>
            </template>
        </span>
    </span>
</template>

<script>
import {Tooltip} from "bootstrap"

import Button from "../../common/Button.vue"
import PageEditorToolbarContentDropdownContentType from "./PageEditorToolbarContentDropdownContentType.vue"

export default {
    name: "PageEditorToolbarContentDropdown",
    components: {PageEditorToolbarContentDropdownContentType, Button},
    data() {
        return {
            tooltip: undefined
        }
    },
    computed: {
        activePageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        pageContent() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.activePageContentId)
        },
        contentId() {
            return this.pageContent?.contentId
        },
        contentTypeTitle() {
            return this.$store.getters['templates/contentTypes'].find(o => o.id === this.content?.contentType)?.title
        },
        selectedContentAreaName() {
            return this.$store.state.pageEditor.selectedContentAreaName
        },
        pageId() {
            return this.$store.state.pageEditor.pageId
        },
        contentAreaNames() {
            return this.$store.state.pageEditor.contentAreas.map(o => o.name)
        }
    },
    asyncComputed: {
        content() {
            if (this.contentId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'content',
                    id: this.contentId
                })
            }
        }
    },
    mounted() {
        this.$store.commit('pageEditor/toolbarDropdown', this.$refs.dropdown)
    },
    methods: {
        getContentAreaContent(name) {
            return this.$store.state.pageEditor.contentItems.filter(o => o.contentAreaName === name)
        },
        mouseoverContentArea(contentAreaName) {
            this.$store.commit('pageEditor/highlightContentAreaName', contentAreaName)
            this.$store.dispatch('pageEditor/scrollToContentAreaIframe', {contentAreaName})
        },
        mouseoutContentArea() {
            this.$store.commit('pageEditor/highlightContentAreaName', '')
        },
        selectContentArea(contentAreaName) {
            this.$store.dispatch('pageEditor/selectContentArea', {contentAreaName})

            this.$refs.dropdown.click() // Hide the menu
        },
        mouseoverContent(pageContentId) {
            this.$store.commit('pageEditor/highlightPageContentId', pageContentId)
            this.$store.dispatch('pageEditor/scrollToContentIframe', {pageContentId})
        },
        mouseoutContent() {
            this.$store.commit('pageEditor/highlightPageContentId', 0)
        },
        selectContent(pageContentId) {
            this.$store.dispatch('pageEditor/selectContent', {pageContentId})

            this.$refs.dropdown.click() // Hide the menu
        },
        showAddContentModal(contentAreaName) {
            this.$store.dispatch('pageEditor/showAddContentModal', {contentAreaName})
        },
        showTooltip(e) {
            /* I couldn't get the tooltips to close. I think I'll need to set the links up as components.
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-custom-class="custom-tooltip"
            data-bs-title="This top tooltip is themed via CSS variables."
            @mouseover="showTooltip"
            @mouseout="hideTooltip"
            //*/

            this.tooltip = new Tooltip(e.target, {})
            this.tooltip.show()
        },
        hideTooltip() {
            this.tooltip?.hide()

        }
    }
}
</script>

<style scoped>
.content-area-item > button {
    visibility: hidden;
}

.content-area-item:hover > button {
    visibility: visible;
}
</style>