<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]"/>
    <input v-else :id="field.id" v-model="currentData[field.name]" v-form-validation :max="field.max" :min="field.min"
           :name="field.name" :placeholder="field.placeholder || false" :required="field.required"
           :step="field.step" class="form-control w-auto" type="number">
</template>

<script>
import formValidation from '../../vue/directives/formValidation'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeNumber",
    components: {FormControlPlainText},
    directives: {
        formValidation,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    created() {
        if (this.field.defaultValue) {
            this.$store.dispatch(this.formId + '/setDefaultValue', {
                name: this.field.name,
                value: this.field.defaultValue
            })
        }
    }
}
</script>

<style scoped>

</style>