<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Products for {{ monthName }} {{ year }}</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table
                    v-if="productData"
                    class="table table-condensed"
                >
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th class="text-end">Quantity</th>
                        <th class="text-end">Net</th>
                        <th class="text-end">VAT</th>
                        <th class="text-end">Gross</th>
                        <th class="text-end">Shipping</th>
                        <th class="text-end">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="data in productData"
                        :key="data.orderId"
                    >
                        <td>
                            <a
                                href
                                @click.prevent="goToOrder(data.orderId)"
                            >
                                {{ data.title }}
                            </a>
                        </td>
                        <td class="text-end">
                            {{ data.quantity }}
                        </td>
                        <td class="text-end">
                            &pound;{{ data.net | numberFormat }}
                        </td>
                        <td class="text-end">
                            &pound;{{ data.tax | numberFormat }}
                        </td>
                        <td class="text-end">
                            &pound;{{ data.gross | numberFormat }}
                        </td>
                        <td class="text-end">
                            &pound;{{ data.shipping | numberFormat }}
                        </td>
                        <td class="text-end">
                            &pound;{{ data.gross + data.shipping | numberFormat }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrdersReportsModal",
    props: {
        options: Object,
    },
    data() {
        return {
            month: this.options.month,
            year: this.options.year,
            monthName: this.options.monthName,
            productData: undefined,
        }
    },
    created() {
        this.setProductData()
    },
    filters: {
        numberFormat(value) {
            return new Intl.NumberFormat().format(value)
        }
    },
    methods: {
        setProductData() {
            this.$store.dispatch('request/post', {
                    url: 'api/OrdersController/getMonthsProducts',
                    postData: {
                        year: this.year,
                        month: this.month
                    }
                })
                .then((obj) => {
                    this.productData = obj.data.productData
                })
        },
        goToOrder(orderId) {
            window.location = '/#/m_basket_orders/' + orderId
            this.$emit('hide-modal')
        }
    }
}
</script>

<style scoped>

</style>