<template>
    <div class="d-flex h-100 overflow-auto flex-column">
        <FormHeader :form-id="formId" :show-save-btn="true"/>

        <FormNavAndForm :form-id="formId" class="flex-grow-1"/>
    </div>
</template>

<script>
import FormNavAndForm from '../common/form/FormNavAndForm'
import FormHeader from '../common/form/FormHeader'
import Form from '../common/form/Form'

export default {
    name: "Settings",
    components: {
        FormNavAndForm,
        FormHeader,
        Form,
    },
    data() {
        return {
            formId: undefined,
            id: 1,
            tableName: 'account_settings',
        }
    },
    created() {
        this.$store.commit('navigationComponent', 'account_settings')

        this.formId = 'form:' + this.tableName + ':' + this.id

        this.$store.dispatch('forms/createForm', {
            tableName: this.tableName,
            id: this.id
        })
    }
}
</script>

<style scoped>

</style>