<template>
    <button class="dropdown-item d-flex align-items-center" type="button" @click="openThemesEditorAddModal">
        <i class="me-2 bi-plus-lg"/>
        New
    </button>
</template>

<script>
export default {
    name: "ThemesEditorAddBtn",
    methods: {
        openThemesEditorAddModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'ThemesEditorAddModal',
                obj: {
                    id: 0,
                }
            })
        }
    }
}
</script>

<style scoped>

</style>