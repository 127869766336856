export default {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        items(state, items) {
            state.items = items
        }
    },
    getters: {
        isAssumed(state) {
            return localStorage.getItem(state.assumedByCacheKey)
        },
    },
    actions: {
        init({dispatch, commit}) {
            dispatch('request/get', {
                url: 'api/component/m_registrants_groups',
                params: {
                    accessGroup: 1,
                    isArchived: 0
                }
            }, {root: true})
                .then((o) => {
                    commit('items', o.data.items)
                })
        }
    },
}