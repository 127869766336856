var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.page
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center mb-1",
              class: { "page-selection-mode": _vm.selectedPageIds },
            },
            [
              _c(
                "Button",
                {
                  staticClass: "btn-sm p-0",
                  attrs: { disabled: !_vm.hasChildPages && !_vm.hasContent },
                },
                [
                  _c("i", {
                    class: {
                      "bi-caret-down-fill": _vm.isOpen,
                      "bi-caret-right-fill": !_vm.isOpen,
                      invisible: !_vm.hasChildPages && !_vm.hasContent,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.togglePage(_vm.pageId)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.selectedPageIds
                ? _c("span", {
                    staticClass: "me-2",
                    class: {
                      "bi-square": !_vm.isChecked,
                      "bi-check-square": _vm.isChecked,
                    },
                    on: { click: _vm.updateSelectedPageIds },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Button",
                {
                  staticClass:
                    "ms-1 btn-sm py-0 px-1 w-100 text-start d-flex align-items-center",
                  attrs: {
                    "active-class": "btn-primary",
                    "active-value":
                      _vm.isLastSelected ||
                      _vm.isSelectedContentsPageId ||
                      _vm.isSelected,
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onSelectPage.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "flex-fill" }, [
                    _vm._v(_vm._s(_vm.page.title)),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-body-secondary" }, [
                    _vm._v(_vm._s(_vm.page.pageLabel)),
                  ]),
                  _vm._v(" "),
                  _vm.isRestricted
                    ? _c("i", {
                        staticClass: "mx-1 bi-person-fill",
                        attrs: { title: "This page is assigned group access" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isHomePage
                    ? _c("i", {
                        staticClass: "mx-1 bi-house",
                        attrs: { title: "Home page" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.page.hidden
                    ? _c("i", { staticClass: "mx-1 bi-eye-slash-fill" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isUnpublished
                    ? _c("i", { staticClass: "mx-1 bi-x-circle-fill" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isScheduled
                    ? _c("i", {
                        staticClass: "bi-clock-fill",
                        class: {
                          "text-success": _vm.isPending,
                          "text-warning": _vm.isExpiring,
                          "text-danger": _vm.isExpired,
                        },
                        attrs: { title: _vm.schedulingTitle },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasContent && _vm.isOpen && _vm.showContent
            ? [
                _c("SiteTreeContentAreas", {
                  attrs: {
                    "page-id": _vm.pageId,
                    "select-content": _vm.selectContent,
                    "site-tree-id": _vm.siteTreeId,
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showChildPages
            ? _c(
                "div",
                { staticClass: "ms-3" },
                _vm._l(_vm.parentChildIds[_vm.pageId], function (pageId) {
                  return _c("SiteTreePageItem", {
                    key: pageId,
                    attrs: {
                      "page-id": pageId,
                      "select-content": _vm.selectContent,
                      "select-page": _vm.selectPage,
                      "selected-page-ids": _vm.selectedPageIds,
                      "show-content": _vm.showContent,
                      "site-tree-id": _vm.siteTreeId,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }