var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-4 px-3 d-flex h-100 overflow-auto" }, [
    _c(
      "div",
      { staticClass: "row d-flex h-100 overflow-auto flex-fill" },
      [
        _vm.hasFieldsets
          ? [
              _c(
                "div",
                { staticClass: "col-3 col-lg-2 pb-3 overflow-auto h-100" },
                [
                  _c("ContentTypeFieldsetsList"),
                  _vm._v(" "),
                  _c("ContentTypeNewFieldsetBtn"),
                  _vm._v(" "),
                  _c("ContentTypeFieldsetOffcanvas"),
                ],
                1
              ),
              _vm._v(" "),
              _vm.fieldsetId
                ? _c(
                    "div",
                    { staticClass: "col-3 col-lg-2 pb-3 overflow-auto h-100" },
                    [
                      _c("ContentTypeFieldsList"),
                      _vm._v(" "),
                      _c("ContentTypeNewAttributeBtn"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-6 col-lg-8 overflow-auto h-100" },
                [
                  _vm.activeEditId >= 0
                    ? _c("ContentTypeFieldEditForm")
                    : _vm._e(),
                ],
                1
              ),
            ]
          : [
              _c(
                "div",
                { staticClass: "col-sm-3 overflow-auto h-100" },
                [
                  _c("ContentTypeFieldsList"),
                  _vm._v(" "),
                  _c("ContentTypeNewAttributeBtn"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9 overflow-auto h-100" },
                [
                  _vm.activeEditId >= 0
                    ? _c("ContentTypeFieldEditForm")
                    : _vm._e(),
                ],
                1
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }