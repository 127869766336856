var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item && _vm.show
    ? _c(
        "div",
        {
          staticClass: "d-flex align-items-center",
          on: {
            dblclick: _vm.onDblClick,
            click: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "left", 37, $event.key, [
                    "Left",
                    "ArrowLeft",
                  ])
                )
                  return null
                if ("button" in $event && $event.button !== 0) return null
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                )
                  return null
                return _vm.onClick.apply(null, arguments)
              },
              function ($event) {
                if (!$event.shiftKey) return null
                return _vm.onShiftClick.apply(null, arguments)
              },
              function ($event) {
                if (!$event.metaKey) return null
                return _vm.onMetaClick.apply(null, arguments)
              },
            ],
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex-fill" },
            [
              _c("UserTrackingAvatar", {
                attrs: { "item-id": _vm.id, "table-name": _vm.tableName },
              }),
              _vm._v(" "),
              _c("ListViewRowTitle", {
                attrs: { id: _vm.id, "view-id": _vm.viewId },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "text-nowrap" },
            [
              _c("TableViewEditBtn", {
                attrs: {
                  id: _vm.id,
                  "select-mode": _vm.selectMode,
                  "view-id": _vm.viewId,
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }