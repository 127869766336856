var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("InternalLinkBtn", {
        attrs: {
          id: _vm.currentData[_vm.field.name],
          field: _vm.field,
          "form-id": _vm.formId,
          "link-options": _vm.linkOptions,
          "on-change": _vm.onChange,
          "remove-disabled-btn": _vm.removeDisabledBtn,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }