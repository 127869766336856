<template>
    <div>
        <PageContentMobileContentArea v-if="contentAreas && contentAreas.length"
                                      v-for="(contentArea, index) in contentAreas"
                                      :key="contentArea" :page-id="pageId" :content-area="contentArea"
                                      :content-area-first="index === 0"
                                      :content-area-last="index === contentAreas.length - 1"
                                      :content-areas="contentAreas"/>

        <PageContentOrphaned :page-id="pageId" :content-areas="contentAreas" class="mb-3"/>
    </div>
</template>

<script>
import PageContentMobileContentArea from './PageContentMobileContentArea'
import PageContentOrphaned from "./PageContentOrphaned.vue"

export default {
    name: "PageContentMobile",
    components: {
        PageContentOrphaned,
        PageContentMobileContentArea
    },
    props: {
        pageId: Number,
    },
    asyncComputed: {
        contentAreas() {
            return this.$store.dispatch('pageTemplatesContentAreas/getPagesContentAreas', this.pageId)
        }
    }
}
</script>

<style scoped>

</style>