import form from '../../store/modules/form'

export default {
    namespaced: true,
    state() {
        return {
            formId: '',
        }
    },
    actions: {
        createForm({dispatch}, d) {
            const formId = 'form:' + d.tableName + ':' + d.id
            
            if (this.hasModule(formId)) {
                this.unregisterModule(formId)
            }
            
            this.registerModule(formId, form)
            
            dispatch(formId + '/init', d, {root: true})
        }
    },
}