var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.useArchive
    ? _c(
        "button",
        {
          staticClass: "d-flex align-items-center",
          class: { warning: _vm.showArchive },
          attrs: { type: "button" },
          on: { click: _vm.toggleArchive },
        },
        [
          _c("i", { staticClass: "me-2 bi-archive" }),
          _vm._v("\n    " + _vm._s(_vm.text) + " archive\n"),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }