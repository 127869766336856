export default {
    namespaced: true,
    state: {
        itemsUsagePromise: false,
        allGroupName: '- All -',
        items: [],
        codeMirror: false,
        editors: [],
        
        searchStr: '',
        
        sectionName: false,
        tableName: false,
        
        activeId: undefined, // Must be undefined so if referenced in routes will not add an empty param
        activeEditorKey: 0,
        
        findModalItemIndex: 0,
        findModalSelections: [],
        
        key: 0, // Incremental key for v-for key binding.
        
        noneGroupName: '- None -',
        activeCssGroupName: '- All -',
        cssGroupNames: [],
        
        sectionsData: [
            {
                title: 'Page templates',
                tableName: 'page_templates',
                fieldName: 'templateHtml',
                mode: 'text/html',
                helpText: `
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua.
<br>
<a href="">link</a>
`
            },
            {
                title: 'Templates',
                tableName: 'templates_templates',
                fieldName: 'templateHtml',
                mode: 'text/html',
                helpText: `
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua.
<br>
<a href="">link</a>
`
            },
            {
                title: 'Content types',
                tableName: 'content_types',
                fieldName: 'templateHtml',
                mode: 'text/html',
                helpText: `
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua.
<br>
<a href="">link</a>
`
            },
            {
                title: 'Scripts',
                tableName: 'themes_javascript',
                fieldName: 'script',
                mode: 'javascript',
                helpText: `
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua.
<br>
<a href="">link</a>
`
            },
            {
                title: 'CSS',
                tableName: 'themes_css',
                fieldName: 'css',
                mode: 'css',
                helpText: `
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua.
<br>
<a href="">link</a>
`
            }/*,
                {
                    title: 'Images',
                    tableName: 'themes_images'
                    //fieldName: 'css',
                    //mode: 'css'
                },
                {
                    title: 'Fonts',
                    tableName: 'themes_fonts'
                    //fieldName: 'css',
                    //mode: 'css'
                }*/
        ],
    },
    mutations: {
        activeId(state, activeId) {
            state.activeId = activeId >= 0 ? parseInt(activeId) : undefined
        },
        activeEditorKey(state, activeEditorKey) {
            state.activeEditorKey = activeEditorKey
        },
        codeMirror(state, codeMirror) {
            state.codeMirror = codeMirror
        },
        findModalSelections(state, selections) {
            state.findModalSelections = selections
        }
    },
    getters: {
        getSectionsSettings: (state) => (tableName) => {
            return state.sectionsData.filter((obj) => {
                return obj.tableName === tableName
            })[0]
        },
        getSearchParams() {
            let params = {}
            // With AngularJS the query params are after a hash so they cannot be retrieved using the following.
            //let params = (new URL(document.location)).searchParams;
            // We must instead glean them from the hash.
            let search = document.location.hash.split('?')[1]
            if (search) {
                // https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
                params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            }
            return params
        },
        //Searches through the editors to find one with a matching tableName and id and removes it.
        editorTabIndex: (state) => (tableName, id) => {
            let index = -1
            state.editors.forEach((editor, key) => {
                if (
                    editor.tableName === tableName
                    && editor.id === id
                ) {
                    index = key
                }
            })
            return index
        }
    },
    actions: {
        onModifiedItems({dispatch}, {userId, tableName, id}) {
            dispatch('updateEditors', {
                userId: userId,
                tableName: tableName,
                id: id
            })
            dispatch('updateNavSections', {
                userId: userId,
                tableName: tableName
            })
            dispatch('setCssGroupNames')
        },
        init({state, getters, dispatch}) {
            let params = getters.getSearchParams
            let sectionName = params['section'] || 'page_templates'
            dispatch('setSectionName', sectionName)
            
            // Shows an "Are you sure you wish to close this window?" message if modified editor panels exist.
            window.addEventListener('beforeunload', (e) => {
                if (state.editors.filter(editor => editor.isModified).length) {
                    e.returnValue = true
                }
            })
        },
        setSectionName({state, dispatch}, sectionName) {
            state.sectionName = sectionName
            
            state.tableName = ['page_templates', 'templates_templates', 'content_types'].indexOf(sectionName) > -1
                ? 'templates_templates'
                : sectionName
            
            dispatch('setItems') // Must be called after tableName is set.
            dispatch('setCssGroupNames')
            dispatch('updateItemsUsage')
        },
        setItems({state, dispatch}) {
            
            // This is so that no items are presented when the help box appears. Otherwise, when changing section, the
            // new section's help box appears before the old section's items are removed, which looks odd.
            state.items.length = 0
            
            let sectionName = state.sectionName
            let tableName = state.tableName
            
            let type = ''
            if (sectionName === 'page_templates') {
                type = 'page'
            } else if (sectionName === 'content_types') {
                type = 'contentType'
            }
            
            dispatch('request/get', {
                url: 'api/component/' + tableName,
                params: {
                    field: 'id',
                    sort: 'title',
                    type: type
                }
            }, {root: true})
                .then((obj) => {
                    return dispatch('itemData/preload', {
                        tableName: tableName,
                        ids: obj.data.values,
                    }, {root: true})
                })
                .then((items) => {
                    if (
                        state.activeCssGroupName
                        && state.activeCssGroupName !== state.allGroupName
                    ) {
                        items = items.filter((obj) => {
                            if (state.activeCssGroupName === state.noneGroupName) {
                                return obj.groups.length === 0
                            }
                            
                            return obj.groups.indexOf(state.activeCssGroupName) > -1
                        })
                    }
                    
                    state.items = items
                })
        },
        setCssGroupNames({state, dispatch}) {
            if (state.tableName === 'themes_css') {
                dispatch('request/get', {
                    url: 'api/component/themes_css',
                    params: {
                        sort: 'title'
                    }
                }, {root: true})
                    .then((obj) => {
                        let groupNames = []
                        obj.data.items.forEach((item) => {
                            if (item.groups) {
                                item.groups.forEach((group) => {
                                    if (groupNames.indexOf(group) === -1) {
                                        groupNames.push(group)
                                    }
                                })
                            }
                        })
                        state.cssGroupNames = groupNames.sort()
                        
                        // If the last instance of the group has been removed, and it was the
                        // menu's active group, then unset the active group to show all again.
                        if (
                            state.activeCssGroupName
                            && groupNames.indexOf(state.activeCssGroupName) === -1
                        ) {
                            dispatch('setActiveCssGroupName', state.allGroupName)
                        }
                    })
            }
        },
        setActiveCssGroupName({state, dispatch}, groupName) {
            state.activeCssGroupName = groupName
            dispatch('setItems')
        },
        createEditor({rootState, state, commit, getters, dispatch}, {sectionName, id}) {
            commit('activeId', id)
            
            let tableName = sectionName === 'content_types' || sectionName === 'page_templates'
                ? 'templates_templates'
                : sectionName
            
            // Search for an existing editor for this item.
            let foundKey = false
            state.editors.forEach((obj, key) => {
                if (
                    foundKey === false
                    && obj.tableName === tableName
                    && obj.id === state.activeId
                ) {
                    foundKey = key
                }
            })
            
            if (foundKey !== false) {
                commit('activeEditorKey', foundKey)
            } else {
                /*
                // Start user tracking the item
                let componentId = rootState.components.componentIds[tableName];
                dispatch('userTracking/start', {
                    componentId: componentId,
                    itemId: state.activeId,
                }, {root: true});
                //*/
                dispatch('itemData/get', {
                    tableName: tableName,
                    id: state.activeId,
                }, {root: true})
                    .then((obj) => {
                        let settings = getters.getSectionsSettings(sectionName)
                        
                        let mode = settings.mode
                        if (
                            mode === 'css'
                            && obj.mode !== ''
                        ) {
                            mode = obj.mode
                        }
                        
                        state.key++
                        
                        state.editors.push({
                            // Incremental key for v-for :key
                            key: state.key,
                            sectionName: sectionName,
                            tableName: tableName,
                            id: state.activeId,
                            mode: mode,
                            // Stores an instance of the Code Mirror doc element, returned by getDoc().
                            // This is integral to the ability of allowing the editor to retain its
                            // history state when flicking between tabs.
                            doc: false,
                            code: obj[settings.fieldName] || '',
                            // The code mirror's focus and blur events toggle this, which
                            // is targeted to highlight the border.
                            focused: false,
                            isModified: false,
                            
                            // Used to propagate new code if another user updates it.
                            // The modified check in Themes set this and a watch in themes-editor-code-mirror
                            // applies it.
                            latestCode: ''
                        })
                        
                        commit('activeEditorKey', state.editors.length - 1)
                    })
            }
        },
        getEditor({state}, {tableName, id}) {
            let editor = false
            
            state.editors.forEach((obj) => {
                if (
                    obj.tableName === tableName
                    && obj.id === id
                ) {
                    editor = obj
                }
            })
            
            return editor
        },
        removeEditorByIndex({rootState, state, commit, dispatch}, {router, key}) {
            
            /*
            let editor = state.editors[key];
            let id = editor.id;
            let componentId = rootState.components.componentIds[editor.tableName];
            dispatch('userTracking/stop', {
                componentId: componentId,
                itemId: id,
            }, {root: true});
            //*/
            
            let activeEditor = state.editors[state.activeEditorKey]
            
            state.editors.splice(key, 1)
            
            // If the editor being removed is to the left of the active editor, or is the last tab,
            // then the key must be reduced by one so the active tab remains active.
            if (
                state.activeEditorKey > key
                || key === state.editors.length
            ) {
                commit('activeEditorKey', state.activeEditorKey - 1)
            }
            
            // If the active editor is changing load the new editor's route
            let newEditor = state.editors[state.activeEditorKey]
            if (!newEditor) {
                commit('activeId', undefined)
                router.push({query: {section: undefined, id: undefined}})
            } else if (
                newEditor.sectionName !== activeEditor.sectionName
                || newEditor.id !== activeEditor.id
            ) {
                router.push({query: {section: newEditor.sectionName, id: newEditor.id}})
            }
        },
        getItemsUsage({state, dispatch}) {
            if (!state.itemsUsagePromise) {
                state.itemsUsagePromise = dispatch('getContentTypesUsageCount')
            }
            
            return state.itemsUsagePromise
        },
        updateItemsUsage({state, dispatch}) {
            if (
                state.sectionName === 'content_types'
                && state.itemsUsagePromise
            ) {
                state.itemsUsagePromise = dispatch('getContentTypesUsageCount')
            }
        },
        getContentTypesUsageCount({dispatch}) {
            return new Promise((resolve, reject) => {
                dispatch('request/get', {
                    url: 'api/component/content',
                    params: {
                        field: 'contentType',
                    }
                }, {root: true})
                    .then((obj) => {
                        let usage = {}
                        obj.data.values.forEach((id) => {
                            usage[id] === undefined
                                ? usage[id] = 1
                                : usage[id]++
                        })
                        resolve(usage)
                    })
            })
        },
        updateEditors({dispatch, rootState}, {userId, tableName, id}) {
            
            // If the item was edited by this user do nothing.
            if (userId === rootState.user.user.id) {
                return
            }
            
            dispatch('getEditor', {
                tableName: tableName,
                id: id
            })
                .then((editor) => {
                    if (editor) {
                        let code = editor.code
                        //let tableName = tableName;
                        //let id = id;
                        
                        let user
                        dispatch('itemData/get', {
                            tableName: 'users',
                            id: userId,
                        }, {root: true})
                            .then((obj) => {
                                user = obj
                                console.log('user', user)
                                return dispatch('itemData/get', {
                                    tableName: tableName,
                                    id: id,
                                }, {root: true})
                            })
                            .then((obj) => {
                                let latestCode
                                switch (tableName) {
                                    case 'templates_templates':
                                        latestCode = obj.templateHtml
                                        break
                                    case 'themes_css':
                                        latestCode = obj.css
                                        break
                                    case 'themes_javascript':
                                        latestCode = obj.script
                                        break
                                }
                                console.log('latestCode', latestCode)
                                
                                let userName = user.firstName + ' ' + user.lastName
                                let msg = '"' + userName + '" has saved changes to "' + obj.title + '".' +
                                    '\nDo you wish to apply the changes?' +
                                    '\n' +
                                    '\nAny unsaved changes will be lost.'
                                
                                if (
                                    code !== latestCode
                                    && confirm(msg)
                                ) {
                                    editor.code = latestCode
                                    editor.originalCode = latestCode
                                    editor.latestCode = latestCode
                                }
                            })
                    }
                })
        },
        updateNavSections({dispatch, rootState}, {userId, tableName}) {
            if (
                rootState.user.user.id !== userId
                && ['templates_templates', 'themes_css', 'themes_javascript'].indexOf(tableName) > -1
            ) {
                dispatch('setItems')
            }
        },
        deleteItem({state, dispatch, getters}, {router, id, onSuccess}) {
            dispatch('itemData/get', {
                tableName: state.tableName,
                id: id,
            }, {root: true})
                .then((obj) => {
                    let msg = '<p>Deleting <strong>' + obj.title + '</strong>.</p>'
                    
                    if (obj.type === 'contentType') {
                        msg += '<p class="alert alert-warning">' +
                            '<i class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>' +
                            'Content associated to this content type will be deleted.' +
                            '</p>'
                    }
                    
                    dispatch('modals/show', {
                        componentName: 'ConfirmModal',
                        obj: {
                            modalTitle: 'Are you sure?',
                            modalBody: msg,
                            confirmStr: obj.title,
                            onConfirm: () => {
                                dispatch('request/delete', {
                                    url: 'api/component/' + state.tableName + '/' + id
                                }, {root: true})
                                    .then(() => {
                                        dispatch('setItems')
                                        dispatch('setCssGroupNames')
                                        
                                        let i = getters['editorTabIndex'](state.tableName, id)
                                        if (i > -1) {
                                            dispatch('removeEditorByIndex', {
                                                router: router,
                                                key: i
                                            })
                                        }
                                        
                                        onSuccess()
                                    })
                            }
                        }
                    }, {root: true})
                })
        }
    },
}