var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c(
      "button",
      {
        staticClass: "btn btn-light me-2",
        attrs: { type: "button" },
        on: { click: _vm.openSiteTreeModal },
      },
      [_vm._v("\n        Browse…\n    ")]
    ),
    _vm._v(" "),
    _vm.pageId
      ? _c(
          "span",
          { staticClass: "well well-sm m-0 p-1 text-nowrap" },
          [_c("PagePath", { attrs: { "page-id": _vm.pageId } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }