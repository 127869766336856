var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "small",
    {
      staticClass: "float-end p-1 text-body-secondary",
      attrs: { title: "The number of allowed characters remaining" },
    },
    [_vm._v("\n    " + _vm._s(_vm.remaining) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }