<template>
    <div class="d-flex align-items-center">
        <input type="color" v-model="item.value" class="form-control form-control-sm form-control-color d-inline-block rounded-0 rounded-start">
        <input v-model="item.value" class="form-control form-control-sm d-inline-block w-auto rounded-0 rounded-end border-start-0" maxlength="7" minlength="7" placeholder="#000000" size="8">
    </div>
</template>

<script>

export default {
    name: "CollectionFilterControlTypeColour",
    props: {
        field: Object,
        item: Object
    },
}
</script>

<style scoped>

</style>