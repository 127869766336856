<template>
    <button
        v-if="show"
        class="btn btn-light"
        type="button"
        @click="openLogsModal"
    >
        Open logs
    </button>
</template>

<script>
export default {
    name: "FormControlMBasketOrdersLogs",
    props: {
        field: Object,
        currentData: Object,
    },
    asyncComputed: {
        show() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/order_log',
                    params: {
                        orderId: this.currentData.id
                    }
                })
                .then((obj) => {
                    return !!obj.data.items.length
                })
        }
    },
    watch: {
        show(newValue) {
            this.field.hidden = !newValue
        }
    },
    methods: {
        openLogsModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'OrdersLogsModal',
                obj: {
                    orderId: this.currentData.id
                }
            })
        }
    },
}
</script>

<style scoped>

</style>