<template>
    <SidebarToggle :name="'form_nav'" class="flex-grow-1 overflow-auto">
        <template v-slot:leftSidebar>
            <FormFieldsetAnchors :form-id="formId"/>
        </template>

        <Form :form-id="formId" :show-field-names="true" :show-legends="true" class="container-fluid mt-4 mb-5"/>

        <template v-slot:rightSidebar>
            <FormSidebar :form-id="formId" :show-archive-btn="showArchiveBtn" :show-duplicate-btn="showDuplicateBtn"
                         :show-history-btn="showHistoryBtn"/>
        </template>
    </SidebarToggle>
</template>

<script>
import SidebarToggle from '../../common/sidebar-toggle/SidebarToggle'
import FormFieldsetAnchors from './FormFieldsetAnchors'
import Form from './Form'
import FormSidebar from './FormSidebar'

export default {
    name: "FormNavAndForm",
    components: {
        SidebarToggle,
        FormFieldsetAnchors,
        Form,
        FormSidebar,
    },
    props: {
        formId: String,
        showArchiveBtn: Boolean,
        showDuplicateBtn: Boolean,
        showHistoryBtn: Boolean,
        showSaveAndCloseBtn: Boolean,
        showSaveBtn: Boolean,
    }
}
</script>

<style scoped>

</style>