<template>
    <span class="ms-1 d-inline-flex align-items-center small text-nowrap">
        <Button class="btn-sm lh-1 me-1 p-1" @click.native="goToListing">
            <small>{{ component.title }}</small>
        </Button>

        <template v-if="showTitle">
            <i class="bi-chevron-compact-right"/>
            <small class="px-2 text-truncate">{{ title }}</small>
        </template>
    </span>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "FormDisplayTitle",
    components: {Button},
    props: {
        formId: String,
    },
    computed: {
        originalData() {
            return this.$store.state[this.formId].originalData
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        component() {
            return this.$store.state.components.items.find(o => o.tableName === this.tableName)
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
        title() {
            return this.originalData[this.nameField]
        },
        showTitle() {
            return this.tableName !== 'account_settings' && this.title
        }
    },
    methods: {
        goToListing() {
            this.$router.push({
                name: 'list',
                params: {
                    component: this.tableName
                }
            })
        }
    }
}

</script>

<style scoped>
.text-truncate {
    max-width: 200px;
}

@media (min-width: 768px) {
    .text-truncate {
        max-width: inherit;
    }
}
</style>