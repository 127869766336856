<template>
    <UserAvatar v-if="userId" :user-id="userId" :tooltip-placement="'right'" :is-small="true" class="user-tracking-avatar"/>
</template>

<script>
import UserAvatar from './UserAvatar'

export default {
    name: "UserTrackingAvatar",
    components: {
        UserAvatar
    },
    props: {
        tableName: String,
        itemId: Number,
    },
    data() {
        return {
            userId: undefined,
            userTrackingData: {},
        }
    },
    computed: {
        /*
        userTrackingData() {
            // todo - https://creativecms.atlassian.net/browse/UI2-7
            return this.$store.state.userTracking.data
        }
        //*/
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        }
    },
    watch: {
        // todo - This does not work.
        userTrackingData: {
            handler(newValue) {
                //console.log('newValue', newValue);
            },
            deep: true
        }
    },
    created() {
        /*
        if (this.componentId) {
            this.userId = this.$store.dispatch('userTracking/checkKeys', {
                componentId: this.componentId,
                itemId: this.itemId,
            });
        }
        //*/
    }
}
</script>

<style scoped>
.table-view .user-tracking-avatar {
    position: absolute;
    right: 5px;
}

/* List view */

.list-view .user-tracking-avatar {
    position: absolute;
    left: -10px;
    top: 10px;
}

.themes-editor-nav-link .user-tracking-avatar {
    margin-left: -10px;
}
</style>