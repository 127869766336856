export default {
    inserted: function(el, binding, vnode, old) {
        const store = vnode.context.$store;
        
        const userIds = binding.value
        const userId = store.state.user.user.id
        
        if (!Array.isArray(userIds)) {
            console.error('v-restrict-to-users value must be an array')
            el.remove()
            return
        }
        
        // If the current user's ID is not provided, remove the element
        if (userIds.indexOf(userId) === -1) {
            el.remove()
        }
    }
}