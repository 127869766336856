var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Form", {
        attrs: { id: _vm.id, "form-id": _vm.formId, "show-legends": false },
      }),
      _vm._v(" "),
      _c(
        "footer",
        { staticClass: "text-center mt-3 mb-3" },
        [
          _c("FormSaveBtn", {
            attrs: {
              "button-text": "Save",
              "cancel-on-save": true,
              "form-id": _vm.formId,
              "default-btn-class": "btn-secondary",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }