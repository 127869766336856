<template>
    <Button class="h-100 rounded-0" title="Preview website" @click.native="openPreviewMode">
        <i class="bi-globe"></i>
    </Button>
</template>

<script>
import param from 'jquery-param'
import Button from "../Button.vue"

export default {
    name: "NavigationPreviewBtn",
    components: {Button},
    methods: {
        getParams() {
            let params = {}

            let stripParams = (str) => {
                return str ? str.split('?')[0] : null
            }

            // E.g. /#/site_tree?foo=bar or /#/site_tree/c174?foo=bar
            let hash = window.location.hash.split('/')
            // site_tree?foo=bar -> site_tree
            let tableName = stripParams(hash[1])
            // c174?foo=bar -> c174
            let id = stripParams(hash[2])

            params['tableName'] = tableName

            if (tableName === 'site_tree') {
                if (
                    typeof id === 'string'
                    && id.indexOf('c') === 0
                ) {
                    params['pageContentId'] = parseInt(id.substr(1))
                } else {
                    params['pageId'] = parseInt(id)
                }
            } else if (tableName === 'pages') {
                params['pageId'] = parseInt(id) || this.getListingId()
            } else if (tableName === 'content') {
                params['contentId'] = parseInt(id) || this.getListingId()
            } else {
                params['id'] = parseInt(id) || this.getListingId()
            }

            params['token'] = localStorage.getItem('token')

            return params
        },
        getListingId() {
            let viewId = this.$store.state.mainViewId
            if (
                viewId
                && this.$store.hasModule(viewId)
            ) {
                let selectedIds = this.$store.state[viewId].selectedIds
                if (selectedIds.length === 1) {
                    return selectedIds[0]
                }
            }
        },
        openPreviewMode() {
            const href = this.$store.state.ccmsSchemeAndHost + '/controller/preview-open?' + param(this.getParams())
            window.open(href)
        }
    }
}
</script>

<style scoped>

</style>