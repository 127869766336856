<script>
import FormControlTypeText from '../form-control-type/FormControlTypeText'

export default {
    name: "FormControlUrlSlug",
    extends: FormControlTypeText,
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    data() {
        return {
            doSync: true,
        }
    },
    computed: {
        titleValue() {
            if (this.nameField) {
                return this.currentData[this.nameField]
            }
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
    },
    created() {
        // If the field is already populated then syncing should be disabled.
        if (this.currentData[this.field.name]) {
            this.doSync = false
        }
    },
    watch: {
        value(val) {
            this.currentData[this.field.name] = this.formatValue(val)

            // If the user manually empties the field then start syncing again.
            if (val === '') {
                this.doSync = true
            }
        },
        titleValue(val, oldVal) {
            // If this field's current value is the formatted version of the old title value then
            // the value's are currently in sync, so set it to the formatted version of the new value.
            if (
                this.doSync
                && (
                    this.currentData[this.field.name] === this.formatValue(oldVal)
                    || this.currentData[this.field.name] === ''
                )
            ) {
                this.currentData[this.field.name] = this.formatValue(val)
            }
        }
    },
    methods: {
        formatValue(str) {
            return str === undefined
                ? ''
                : str
                    .normalize("NFD") // "è" becomes  "e`̀".
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/[^a-zA-Z 0-9.\-]+/g, '')
                    .toLowerCase()
                    .replace(/ +/g, '-')
        }
    }
}
</script>

<style scoped>

</style>