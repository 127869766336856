var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "dropdown-item d-flex align-items-center",
      attrs: { type: "button" },
      on: { click: _vm.onClick },
    },
    [
      _c("span", {
        staticClass: "me-2",
        class: {
          "bi-square": !_vm.lineWrapping,
          "bi-check-square": _vm.lineWrapping,
        },
      }),
      _vm._v("\n    Line wrapping\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }