var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "root" }, [
    _c("div", [
      _c("h5", { staticClass: "list-group-item-heading" }, [
        _vm._v("Duplicate this " + _vm._s(_vm.label)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "\n            Duplicates the " +
            _vm._s(_vm.label) +
            "'s settings and fields.\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-success ms-2",
        attrs: { href: "", type: "button" },
        on: { click: _vm.duplicateForm },
      },
      [_vm._v("\n        Duplicate this " + _vm._s(_vm.label) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }