var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-center" }, [
    _c(
      "div",
      { staticClass: "flex-fill" },
      [
        _vm._v(
          "\n        " + _vm._s(_vm.componentTitle) + "\n        >\n        "
        ),
        _vm.itemTitle
          ? [_vm._v(_vm._s(_vm.itemTitle))]
          : _c("em", [_vm._v("untitled")]),
        _vm._v(
          "\n        >\n        " + _vm._s(_vm.formControlLabel) + "\n    "
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        { staticClass: "btn btn-light btn-sm", on: { click: _vm.goToItem } },
        [_c("i", { staticClass: "bi-pencil-square" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }