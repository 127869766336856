<template>
    <FormSidebarBtn v-if="show" @click.prevent.native="onClick">
        <i class="me-2 bi-trash"/>
        Delete item
    </FormSidebarBtn>
</template>

<script>
import FormSidebarBtn from "./FormSidebarBtn.vue"

export default {
    name: "FormDeleteBtn",
    components: {FormSidebarBtn},
    props: {
        formId: String,
    },
    data() {
        return {
            hasVariations: false,
        }
    },
    computed: {
        isArchived() {
            return this.$store.state[this.formId].currentData.isArchived
        },
        productTypeId() {
            return this.$store.state[this.formId].currentData.productTypeId
        },
        show() {
            return (
                this.id
                && this.component.deleteOption
                && (
                    this.isArchived
                    || !this.component.useArchive
                )
                && this.tableName !== 'product_options'
                && !this.hasVariations
                && this.hasAccess
            )
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        component() {
            return this.$store.state.components.items.find(obj => obj.id === this.componentId)
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        hasAccess() {
            return this.$store.getters['user/access'](this.tableName, 'delete')
        },
    },
    watch: {
        productTypeId(value) {
            if (
                this.tableName === 'product_options'
                && value
            ) {
                this.$store.dispatch('request/post', {
                        url: 'api/ProductTypes/hasVariations',
                        postData: {
                            typeId: value
                        }
                    })
                    .then((obj) => {
                        this.hasVariations = obj.data.count > 0
                    })
            } else {
                this.hasVariations = false
            }
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Delete item',
                    modalBody: `
                        <p class="alert alert-danger">
                            This <strong>cannot</strong> be undone.
                        </p>

                        <p>
                            Are you sure?
                        </p>
                    `,
                    onConfirm: () => {
                        this.$store.dispatch('itemData/delete', {
                                tableName: this.tableName,
                                id: this.id
                            })
                            .then(() => {
                                window.location = this.$route.name === 'siteTree'
                                    ? '/#/site_tree/'
                                    : '/#/' + this.tableName
                            })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>