<template>
    <div class="card mb-3">
        <div class="card-header">
            Introduction
        </div>

        <div class="card-body">
            <p class="lead">Welcome to CreativeCMS</p>
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6"></div>
            </div>
        </div>

        <div v-if="$store.state.betaMode">
            <Button @click.native="addToast">
                Toast
            </Button>

            <Button @click.native="addToastNoTitle">
                Simple toast
            </Button>

            <Button @click.native="addToastWarning">
                Warning
            </Button>

            <Button @click.native="addToastDanger">
                Danger
            </Button>
        </div>

        <span v-if="$store.state.alphaMode">
            <!--<a class="btn btn-light" @click="openComponentEdit">Add component</a>-->
        </span>
    </div>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "DashboardWelcome",
    components: {
        Button
    },
    methods: {
        addToast() {
            this.$store.dispatch('toasts/add', {
                title: 'Duis aute irure',
                body: 'Lorem ipsum dolor sit amet.'
            })
        },
        addToastNoTitle() {
            this.$store.dispatch('toasts/add', {
                body: 'Lorem ipsum dolor sit amet.'
            })
        },
        addToastWarning() {
            this.$store.dispatch('toasts/add', {
                body: 'This has auto hide disabled.',
                colourScheme: 'warning',
                disableAutoHide: true
            })
        },
        addToastDanger() {
            this.$store.dispatch('toasts/add', {
                body: 'This has auto hide disabled.',
                colourScheme: 'danger',
                disableAutoHide: true
            })
        }
    }
}
</script>

<style scoped>

</style>