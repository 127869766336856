var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "span",
        {
          staticClass: "ms-1 d-none d-md-inline-flex align-items-center small",
        },
        [
          _vm.productTitle
            ? [
                _c(
                  "small",
                  {
                    staticClass: "px-2",
                    attrs: { role: "button" },
                    on: { click: _vm.goToProductListing },
                  },
                  [_vm._v("Products")]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "bi-chevron-compact-right" }),
                _vm._v(" "),
                _c(
                  "small",
                  {
                    staticClass: "px-2",
                    attrs: { role: "button" },
                    on: { click: _vm.goToProduct },
                  },
                  [_vm._v(_vm._s(_vm.productTitle))]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "bi-chevron-compact-right" }),
                _vm._v(" "),
                _c("small", { staticClass: "px-2" }, [_vm._v("Variations")]),
              ]
            : _c("small", { staticClass: "px-2" }, [
                _vm._v(_vm._s(_vm.component.title)),
              ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }