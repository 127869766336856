<template>
    <div class="d-flex align-items-center">
        <input :id="field.id" :checked="isTracked" type="checkbox" @click="toggleTrackStock">

        <FormControlTypeNumber ref="input" :current-data="currentData" :field="field"
                               :form-id="formId" class="ms-2"/>

        <span v-if="basketCount && isTracked" class="ms-2">Basket count: {{ basketCount }}</span>
    </div>
</template>

<script>
import FormControlTypeNumber from '../form-control-type/FormControlTypeNumber'

export default {
    name: "FormControlStockQuantity",
    components: {
        FormControlTypeNumber,
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            fieldEnabled: Number.isInteger(this.currentData.stockQuantity),
            oldValue: 0,
            basketCount: 0,
            intervalPromise: undefined
        }
    },
    computed: {
        isTracked() {
            return this.fieldEnabled
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
    },
    beforeDestroy() {
        clearInterval(this.intervalPromise)
    },
    watch: {
        isTracked: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.intervalPromise = setInterval(() => {
                        this.getBasketCount()
                    }, 1000)
                } else if (this.intervalPromise) {
                    clearInterval(this.intervalPromise)
                }
            }
        }
    },
    methods: {
        toggleTrackStock() {
            this.fieldEnabled = !this.fieldEnabled

            // If stock has been set then retain its value in case the user toggles the options back again.
            if (this.currentData.stockQuantity !== null) {
                this.oldValue = this.currentData.stockQuantity
            }

            this.currentData.stockQuantity = this.currentData.stockQuantity === null
                ? this.oldValue
                : null

            if (this.fieldEnabled) {
                this.$nextTick(() => {
                    this.$refs.input.$el.select()
                })
            }
        },
        getBasketCount() {
            this.$store.dispatch('request/get', {
                url: 'api/order/basket-quantity/' + this.componentId + '/' + this.id
            })
                .then((o) => {
                    this.basketCount = o.data.quantity
                })
        }
    }
}
</script>

<style scoped>

</style>