<template>
    <span>
        <button v-if="categoryTableName" class="btn btn-light" type="button" @click="selectCategory">
            <span v-if="!value">select</span>

            <FormControlCategoryTitle v-if="value" :id="value" :table-name="categoryTableName"/>
        </button>
    </span>
</template>

<script>
import FormControlCategoryTitle from '../../common/form-control/FormControlCategoryTitle'
import searchParams from '../../vue/mixins/search-params'

export default {
    name: "SearchFilterFieldCategory",
    components: {
        FormControlCategoryTitle,
    },
    props: {
        listingName: String,
        filter: Object,
        value: Number,
        viewId: String,
    },
    mixins: [searchParams],
    data() {
        return {
            tableName: this.listingName.split('.')[0],
            categoryTableName: undefined,
        }
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        }
    },
    watch: {
        // Update the table name whenever the filter is modified, and when the component is generated (immediate).
        // Otherwise, for example, if changing from Author to Category the latter select list would show authors.
        filter: {
            deep: true,
            immediate: true,
            handler() {
                this.setCategoryTableName()
            }
        }
    },
    methods: {
        setCategoryTableName() {
            // Load the name of the category component for use by the select listing service.
            this.$store.dispatch('componentStructure/get', this.componentId)
                .then((objs) => {
                    let structure = objs.find(obj => obj.columnName === this.filter.filterData.columnName)
                    let component = this.$store.state.components.items.find(o => o.id === structure.categoryComponentId)
                    this.categoryTableName = component.tableName
                })
        },
        selectCategory() {
            let id = this.filters.advancedFilter[this.filter.id]

            // If presenting the variables listing when filtering by a type's option
            // we must filter the variables by the columnName's optionId.
            if (
                this.tableName.indexOf('product_variations__') === 0
                && this.filter.filterData.columnName.indexOf('option') === 0
            ) {
                let params = this.getSearchParams()
                let productId = params.productId
                this.$store.dispatch('itemData/get', {
                        tableName: 'm_products_products',
                        id: productId
                    })
                    .then((item) => {
                        return this.$store.dispatch('request/post', {
                            url: 'api/products/get-types-column-names',
                            postData: {
                                typeId: item.typeId
                            }
                        })
                    })
                    .then((obj) => {
                        let columnNames = obj.data.columnNames
                        for (let optionId in columnNames) {
                            if (columnNames.hasOwnProperty(optionId)) {
                                let columnName = columnNames[optionId]
                                if (columnName === this.filter.filterData.columnName) {
                                    let categoryListingName = this.categoryTableName + '.selectList'
                                    /* todo - This cannot be reintroduced until views are reusable
                                    let categoryFilterSettings = this.$filterSettingsService.get(categoryListingName);
                                    categoryFilterSettings.categoryFilter['optionId'] = parseInt(optionId);
                                    */
                                }
                            }
                        }
                    })
            }

            let modal = this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    // Use the column's name as a namespace for the listing name to isolate it from the main listing.
                    // This allows its options, such as sort by, to operate independently.
                    listingName: this.categoryTableName + '.' + this.filter.filterData.columnName,
                    selectedIds: id ? [id] : [],
                    onSelect: (selectedIds) => {
                        // input targets the component's v-model property.
                        this.$emit('input', selectedIds.pop() || undefined)

                        modal.then((obj) => {
                            this.$store.dispatch('modals/remove', obj.index)
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>