var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress" }, [
    _c(
      "div",
      {
        staticClass: "progress-bar",
        style: { width: _vm.percentageWidth },
        attrs: {
          "aria-valuemax": "100",
          "aria-valuemin": "0",
          role: "progressbar",
        },
      },
      [
        _c("span", { staticClass: "visually-hidden" }, [
          _vm._v(_vm._s(_vm.percentage) + "% Complete"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }