<template>
    <Button v-if="item" class="btn-sm me-1 p-1 d-flex align-items-center text-nowrap" @click.prevent.native="selectTab"
            :active-value="index === activeEditorKey" :active-class="'btn-primary'" :default-class="typeColourClass">
        <i class="me-1 bi-x" @click.stop.prevent="removeEditor"></i>

        <small>{{ item.title }}</small>

        <span :class="{ 'bg-warning': editor.isModified }" class="rounded ps-1 pt-1 ms-2 me-1"/>
    </Button>
</template>

<script>
import Button from "../../common/Button.vue"

export default {
    name: "ThemesEditorTab",
    components: {Button},
    props: {
        index: Number,
        editor: Object,
    },
    computed: {
        activeEditorKey() {
            return this.$store.state.themes.activeEditorKey
        },
        typeColourClass() {
            const classNames = {
                'templates_templates': '',//'text-warning-emphasis',
                //'content_types': 'text-danger-emphasis',
                'themes_javascript': 'text-danger',
                'themes_css': 'text-success',
            }

            return classNames[this.editor.tableName]
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                tableName: this.editor.tableName,
                id: this.editor.id
            })
        }
    },
    methods: {
        selectTab() {
            this.$router.push({query: {section: this.editor.sectionName, id: this.editor.id}})
        },
        removeEditor() {
            if (
                !this.editor.isModified
                || confirm('Are you sure?')
            ) {
                this.$store.dispatch('themes/removeEditorByIndex', {
                    router: this.$router,
                    key: this.index
                })
            }
        }
    }
}
/*
        $element
            .bind('dragstart', function() {
                $scope.$parent.dragIndex = ctrl.index;
                $element.addClass('dragging');
            })
            .bind('dragenter', function() {
                let editor = ThemesService.editors[$scope.$parent.dragIndex];
                ThemesService.editors.splice($scope.$parent.dragIndex, 1);
                ThemesService.editors.splice(ctrl.index, 0, editor);

                // If either of the tabs are the active one
                if (this.activeEditorKey === $scope.$parent.dragIndex) {
                    this.$store.commit('themes/activeEditorKey', ctrl.index);
                } else if (this.activeEditorKey === ctrl.index) {
                    this.$store.commit('themes/activeEditorKey', $scope.$parent.dragIndex);
                }

                $scope.$parent.dragIndex = ctrl.index;
                $timeout(function() {
                    $scope.$apply();
                });
            })
            .bind('dragend', function() {
                $element.removeClass('dragging');
            });
            */
</script>

<style scoped>
button > :first-child {
    visibility: hidden;
}

button:hover > :first-child {
    visibility: visible;
}
</style>