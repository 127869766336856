<template>
    <select v-model="categoryFilter.schoolId" class="form-select form-select-sm w-auto" @change="onChange">
        <option v-for="option in options" :value="option.value">
            {{ option.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "OdpCourseDatesSchoolsMenu",
    props: {
        viewId: String,
    },
    data() {
        return {
            options: [],
        }
    },
    computed: {
        isSelectListing() {
            return this.$store.state[this.viewId].listingName.indexOf('.') > -1
        },
        categoryFilter() {
            return this.$store.state[this.viewId].categoryFilter
        },
        showArchive() {
            return this.$store.state[this.viewId].showArchive
        },
    },
    asyncComputed: {
        options() {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/odp_course_dates',
                    params: {
                        field: 'DISTINCT schoolId',
                        schoolId: {gt: 0},
                        isArchived: this.showArchive ? 1 : 0
                    }
                })
                .then((obj) => {
                    const schoolIds = obj.data.values

                    const params = {
                        fields: ['id', 'name'],
                        id: schoolIds,
                        sort: 'name'
                    }
                    if (this.$store.state.user.user.franchiseId) {
                        params.franchiseId = this.$store.state.user.user.franchiseId
                    }

                    return this.$store.dispatch('request/get', {
                        url: 'api/component/odp_schools',
                        params: params
                    })
                })
                .then((obj) => {
                    let options = [{
                        value: undefined,
                        text: 'Select a school…'
                    }]

                    obj.data.items.forEach((obj) => {
                        options.push({
                            value: obj.id,
                            text: obj.name
                        })
                    })

                    return options
                })
        }
    },
    created() {
        this.setValue()
    },
    methods: {
        setValue() {
            let s = this.$route.query
            if (s.schoolId) {
                this.categoryFilter.schoolId = parseInt(s.schoolId)
                // todo - In the AngularJS UI, if I set a filter, left and then returned to the Course Dates
                //  component, the filter would still be active. This isn't possible with VueX modules being
                //  recreated each time. I need to look into reusing them.
                //} else if ($scope.filterSettings.categoryFilter['schoolId']) {
                //$scope.odpCourseDatesSchoolsMenu = $scope.filterSettings.categoryFilter['schoolId'];
            }
        },
        onChange() {
            if (this.categoryFilter.schoolId) {

                /* Vue conversion - I've commented this out as I didn't see that it served any purpose.
                // Select modals should not affect query parameters.
                if (!this.isSelectListing) {
                    let query = Object.assign({}, this.$route.query, {
                        schoolId: this.categoryFilter.schoolId,
                    });
                    this.$router.push({ query: query });
                }*/

                this.$store.commit(this.viewId + '/categoryFilter', this.categoryFilter)

            } else {

                /* Vue conversion - I've commented this out as I didn't see that it served any purpose.
                if (!this.isSelectListing) {
                    let query = Object.assign({}, this.$route.query);
                    delete query.schoolId;
                    this.$router.push({ query: query });
                }*/

                this.categoryFilter.schoolId = undefined
                this.$store.commit(this.viewId + '/categoryFilter', this.categoryFilter)
            }
        }
    }
}
</script>

<style scoped>

</style>