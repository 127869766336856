<template>
    <FormSidebarBtn v-if="show" @click.prevent.native="modal">
        <i class="me-2 bi-tags-fill"/>
        Open tags
    </FormSidebarBtn>
</template>

<script>
import FormSidebarBtn from "../form/FormSidebarBtn.vue"

export default {
    name: "TagsBtn",
    components: {FormSidebarBtn},
    props: {
        formId: String,
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        tagsEnabled() {
            return this.$store.state.components.items
                .find(obj => obj.tableName === this.tableName)
                .showTags
        },
        show() {
            return this.tagsEnabled && this.id
        }
    },
    methods: {
        modal: function() {
            this.$store.dispatch('modals/show', {
                componentName: 'TagsModal',
                obj: {
                    tableName: this.tableName,
                    id: this.id,
                }
            })
        }
    }
}
</script>

<style scoped>

</style>