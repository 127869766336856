<template>
    <div v-if="itemCount <= 0">
        <ListingNoContent :view-id="viewId"/>
        <ListingNoContentOptions :view-id="viewId"/>
    </div>

    <div v-else class="list-group list-group-flush">
        <ListViewRow v-for="id in ids" :id="id" :key="id" :class="{ 'active': selectedIds.indexOf(id) > -1 }"
                     :view-id="viewId" class="list-group-item"/>
    </div>
</template>

<script>
import ListingNoContent from './ListingNoContent'
import ListingNoContentOptions from './ListingNoContentOptions'
import ListViewRow from './ListViewRow'

export default {
    name: "ListView",
    components: {
        ListingNoContent,
        ListingNoContentOptions,
        ListViewRow
    },
    props: {
        viewId: String
    },
    computed: {
        itemCount() {
            return this.$store.state[this.viewId].itemCount
        },
        selectedIds() {
            return this.$store.state[this.viewId].selectedIds
        },
        ids() {
            return this.$store.state[this.viewId].ids
        }
    }
}
</script>

<style scoped>
.list-group-item {
    border-style: dashed;
}
</style>