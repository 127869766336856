<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ modalTitle }}</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <!--<FormHeader
                    :form-id="formId"
                    :show-add-btn="false"
                    :show-archive-btn="false"
                    :show-duplicate-btn="false"
                />-->

                <Form v-if="formId" :form-id="formId"/>

                <footer class="text-center mt-3">
                    <FormSaveBtn :button-text="'Save'" :form-id="formId"/>
                </footer>
            </div>

            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
import Form from '../form/Form'
import FormSaveBtn from '../form/FormSaveBtn'

export default {
    name: "NewContentTypeModal",
    components: {
        Form,
        FormSaveBtn,
    },
    props: {
        modal: Object,
        options: Object,
    },
    data() {
        return {
            formId: undefined,
            tableName: 'modulecomponents',
            id: 0,
            isForm: this.options ? this.options.isForm : false
        }
    },
    computed: {
        modalTitle() {
            return this.isForm ? 'New form' : 'New collection'
        }
    },
    created() {
        this.createForm()
    },
    methods: {
        createForm() {
            this.formId = 'form:' + this.tableName + ':' + this.id

            this.modal.formId = this.formId

            this.$store.dispatch('forms/createForm', {
                tableName: this.tableName,
                id: this.id,
                onSave: this.onSave,
                fieldNames: [
                    'title',
                    //'tableName',
                ],
                currentData: {
                    addNew: 1,
                    useArchive: 1,
                    allowFormAccess: this.isForm ? 1 : 0,
                    createdModified: 1,
                    deleteOption: 1,
                    // Form components have no need for publishing, whereas Add-ons ones must always have it
                    showStatus: this.isForm ? 0 : 1,
                    isEditable: 1,
                    navigationGroup: this.isForm ? 'Forms' : 'Add ons',
                    roles: 1,
                    rolesGroup: this.isForm ? 'Forms' : 'Add ons',
                    updateOption: 1,
                    listing: this.isForm ? 0 : 1,
                }
            })
        },
        onSave(obj) {
            let id = obj.id

            // Reload the component items to trigger the navigation's state change.
            this.$store.dispatch('components/init')
                // Wait for components/init to finish updating the component data before
                // updating the route so the new component's data is loaded.
                .finally(() => {
                    // Highlight the component in the navigation.
                    this.$store.commit('navigationComponent', obj.tableName)

                    // Forms should always display the Created Date column
                    if (this.isForm) {
                        // Refresh componentStructure's items to include the new component's fields.
                        this.$store.dispatch('componentStructure/init')
                            .then(() => {
                                let objs = this.$store.getters['componentStructure/get'](id)
                                let obj = objs.filter(obj => obj.columnName === 'createdDate')[0]

                                this.$store.dispatch('itemData/update', {
                                    tableName: 'modulestructure',
                                    id: obj.id,
                                    data: {
                                        browser: 1,
                                    },
                                })
                            })
                    }

                    this.$emit('hide-modal')

                    this.$store.dispatch('componentFields/reloadFieldsets')
                        .then(() => {
                            this.$router.push({
                                name: 'componentFields',
                                params: {
                                    component: obj.tableName,
                                    fieldId: id
                                }
                            })
                        })
                })
        }
    }
}
</script>

<style scoped>

</style>