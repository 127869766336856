<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ options.label }}</h4>

                <button type="button" class="btn-close" @click="$emit('hide-modal')" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <iframe
                    :srcdoc="iframeHtml"
                    style="width: 100%; height: 100%; border: none"
                />

            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HistoryTexteditorModal",
    props: {
        options: Object
    },
    data: function() {
        return {
            iframeHtml: false
        }
    },
    created() {
        this.iframeHtml = `
<link href="https://stackpath.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet">
<link href="ckeditor/contents.css" rel="stylesheet">
<link href="ckeditor-custom/styles.css" rel="stylesheet">

<!-- Image's src are set as /asset/file/<id>, so we must prefix them with the website's URL.
Must be placed after the styles above so that those remain relative. -->
<base href="` + this.$store.state.websiteEndpoint + `">

` + this.options.value + `

<!-- overlay to prevent links etc. -->
        <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%;"></div>
        `

    }
}
</script>

<style scoped>

</style>