var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "text-end mb-2",
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
        },
      },
      [
        _c(
          "Button",
          {
            staticClass: "btn-sm",
            nativeOn: {
              click: function ($event) {
                return _vm.closeMenu.apply(null, arguments)
              },
            },
          },
          [
            _c("i", { staticClass: "bi-x-lg" }),
            _vm._v("\n            close\n        "),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group navigation-mobile-group-list-group" },
      _vm._l(_vm.navigationGroups, function (groupName) {
        return _c("NavigationMobileGroup", {
          key: groupName,
          attrs: { "close-menu": _vm.closeMenu, "group-name": groupName },
        })
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-end" },
      [
        _c(
          "Button",
          {
            staticClass: "btn-sm",
            nativeOn: {
              click: function ($event) {
                return _vm.closeMenu.apply(null, arguments)
              },
            },
          },
          [
            _c("i", { staticClass: "bi-x-lg" }),
            _vm._v("\n            close\n        "),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }