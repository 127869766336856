var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table table-condensed" }, [
    _c("thead", [
      _c(
        "tr",
        [
          _c("td", { staticClass: "col-xs-7" }),
          _vm._v(" "),
          _vm._l(_vm.options, function (option) {
            return _c("td", { key: option.id, staticClass: "col-xs-1" }, [
              _vm._v("\n            " + _vm._s(option.title) + "\n        "),
            ])
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.groupedComponents, function (component) {
        return _c(
          "tr",
          { key: component.id },
          [
            _c(
              "th",
              {
                staticClass: "roles-component",
                on: { click: _vm.toggleAllCheckboxes },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(component.title) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.options, function (option) {
              return _c(
                "td",
                { key: option.id },
                [
                  _c("FormControlUsersGroupsRolesCheckbox", {
                    attrs: {
                      component: component,
                      "form-id": _vm.formId,
                      option: option,
                      "roles-group": _vm.rolesGroup,
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }