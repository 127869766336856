var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    !_vm.options
      ? _c(
          "svg",
          {
            staticClass: "loader uil-default",
            attrs: {
              height: "22px",
              preserveAspectRatio: "xMidYMid",
              viewBox: "0 0 100 100",
              width: "22px",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("rect", {
              staticClass: "bk",
              attrs: {
                fill: "none",
                height: "100",
                width: "100",
                x: "0",
                y: "0",
              },
            }),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(0 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(30 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.08333333333333333s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(60 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.16666666666666666s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(90 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.25s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(120 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.3333333333333333s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(150 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.4166666666666667s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(180 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.5s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(210 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.5833333333333334s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(240 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.6666666666666666s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(270 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.75s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(300 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.8333333333333334s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
            _c(
              "rect",
              {
                attrs: {
                  fill: "#999999",
                  height: "20",
                  rx: "5",
                  ry: "5",
                  transform: "rotate(330 50 50) translate(0 -30)",
                  width: "7",
                  x: "46.5",
                  y: "40",
                },
              },
              [
                _c("animate", {
                  attrs: {
                    attributeName: "opacity",
                    begin: "0.9166666666666666s",
                    dur: "1s",
                    from: "1",
                    repeatCount: "indefinite",
                    to: "0",
                  },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.options
      ? _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value,
                expression: "value",
              },
            ],
            staticClass: "form-select",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.value = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.options, function (option) {
            return _c("option", { domProps: { value: option.value } }, [
              _vm._v("\n            " + _vm._s(option.text) + "\n        "),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }