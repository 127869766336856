import CodeMirror from 'codemirror'
// Codemirror - CSS
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/scroll/simplescrollbars.css'

// Codemirror - Modes
import 'codemirror/mode/css/css'
import 'codemirror/mode/htmlembedded/htmlembedded'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/twig/twig'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/yaml/yaml'

// Codemirror - Add-ons
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/dialog/dialog'
import 'codemirror/addon/hint/javascript-hint'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/yaml-lint'
import 'codemirror/addon/scroll/simplescrollbars'
import 'codemirror/addon/search/match-highlighter'
import 'codemirror/addon/search/search'
import 'codemirror/addon/search/searchcursor'
import 'codemirror/addon/selection/active-line'
import 'codemirror/addon/selection/mark-selection'

window.CodeMirror = CodeMirror

let req = require.context('codemirror/theme', true, /^(.*\.(css$))[^.]*$/im)
req.keys().forEach(function(key) {
    req(key)
})

