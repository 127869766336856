<template>
    <div class="card-group">
        <div class="card">
            <div class="card-header">{{ item.firstName }} {{ item.lastName }}</div>
            <div class="card-body">
                <p class="card-text">
                    <a :href="'mailto:' + item.email" @click.stop>{{ item.email }}</a>
                </p>
                <p class="card-text">
                    <a :href="'tel:' + item.mobile || item.telephone" @click.stop>{{
                            item.mobile || item.telephone
                        }}</a>
                </p>
            </div>
        </div>

        <div class="card">
            <div class="card-header">Billing address</div>
            <div class="card-body">
                {{ item.billingAddress1 }}<br v-if="item.billingAddress1">
                {{ item.billingAddress2 }}<br v-if="item.billingAddress2">
                {{ item.billingTown }}<br v-if="item.billingTown">
                {{ item.billingCounty }}<br v-if="item.billingCounty">
                {{ item.billingPostcode }}<br v-if="item.billingPostcode">
                {{ item.billingCountry }}
            </div>
        </div>

        <div class="card">
            <div class="card-header">Delivery address</div>
            <div class="card-body">
                {{ item.address1 }}<br v-if="item.address1">
                {{ item.address2 }}<br v-if="item.address2">
                {{ item.townCity }}<br v-if="item.townCity">
                {{ item.county }}<br v-if="item.county">
                {{ item.postcode }}<br v-if="item.postcode">
                {{ item.country }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderDetailsCustomerPanel",
    props: {
        customerId: Number
    },
    asyncComputed: {
        item: {
            default: {},
            get() {
                return this.$store.dispatch('itemData/get', {
                        tableName: 'm_registrants_registrants',
                        id: this.customerId
                    })
                    .then((obj) => {
                        return obj
                    })
            }
        }
    },
}
</script>

<style scoped>
.card-body {
    font-size: .8rem;
}
</style>