var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v("Credit history")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _vm.items
          ? _c("table", { staticClass: "table table-condensed" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.items, function (item) {
                  return _c("tr", [
                    _c("td", [
                      item.debited
                        ? _c("span", [_vm._v("(£" + _vm._s(item.amount) + ")")])
                        : _c("span", [_vm._v("£" + _vm._s(item.amount))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("DisplayTitle", {
                          attrs: {
                            "table-name": "odp_franchises",
                            id: item.franchiseId,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      item.bookingId
                        ? _c(
                            "a",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openBooking(item.bookingId)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.bookingId) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        item.createdBy
                          ? _c("DisplayTitle", {
                              attrs: {
                                "table-name": "users",
                                id: item.createdBy,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-end" }, [
                      _c("span", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.getDate(item.createdDate)) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Amount")]),
        _vm._v(" "),
        _c("td", [_vm._v("Franchise")]),
        _vm._v(" "),
        _c("td", [_vm._v("Booking")]),
        _vm._v(" "),
        _c("td", [_vm._v("User")]),
        _vm._v(" "),
        _c("td", { staticClass: "text-end" }, [_vm._v("Date")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }