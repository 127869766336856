<template>
    <Button :active-class="'btn-primary'" :active-value="isActive"
            class="w-100 text-start btn-sm p-1 ps-2 d-flex align-items-center visible-on-hover"
            @click.prevent.native="onClick">
        <div class="flex-fill overflow-hidden text-nowrap small">
            <UserTrackingAvatar :item-id="item.id" :table-name="tableName"/>

            {{ item.title }}

            <!--<span v-if="item.contentType" class="ms-1 badge text-primary-emphasis">
                {{ item.contentType }}
            </span>-->

            <span v-if="item.mode === 'text/x-scss'" class="ms-1 badge text-primary-emphasis">SCSS</span>

            <span v-if="item.mode === 'text/x-less'" class="ms-1 badge text-primary-emphasis">LESS</span>

            <!--<span ng-repeat="group in $ctrl.item.groups" class="ms-1 badge text-primary-emphasis">{{ group }}</span>-->
        </div>

        <ThemesEditorEditBtn :item="item"/>

        <ThemesEditorUsage :id="item.id" class="ms-1"/>
    </Button>
</template>

<script>
import UserTrackingAvatar from '../../common/UserTrackingAvatar'
import ThemesEditorEditBtn from './ThemesEditorEditBtn'
import ThemesEditorUsage from './ThemesEditorUsage'
import Button from "../../common/Button.vue"

export default {
    name: "ThemesEditorNavLink",
    components: {
        Button,
        UserTrackingAvatar,
        ThemesEditorEditBtn,
        ThemesEditorUsage,
    },
    props: {
        item: Object,
        activeEditorKey: Number,
    },
    computed: {
        tableName() {
            return this.$store.state.themes.tableName
        },
        editors() {
            return this.$store.state.themes.editors
        },
        sectionName() {
            return this.$store.state.themes.sectionName
        },
        isActive() {
            let key = this.activeEditorKey

            return (
                key !== false
                && this.editors[key]?.tableName === this.tableName
                && this.editors[key]?.id === this.item.id
            )
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        betaMode() {
            return this.$store.state.betaMode
        }
    },
    methods: {
        onClick() {
            /*
            // todo - https://creativecms.atlassian.net/browse/UI2-7
            return this.$store.dispatch('inUse', {
                    componentId: this.componentId,
                    itemId: this.item.id,
                })
                .then((userId) => {
                    if (userId) {
                        // don't open the item.
                    } else {
                        this.$store.dispatch('themes/createEditor', {
                            sectionName: this.sectionName,
                            id: this.item.id,
                        })
                    }
                });
            //*/

            if (this.$store.state.themes.activeId !== this.item.id) {
                this.$router.push({query: {section: this.sectionName, id: this.item.id}})
            }
        }
    }
}
</script>

<style scoped>

</style>