var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "row text-center" }, [
    _c(
      "div",
      {
        staticClass:
          "offset-3 col-6 offset-sm-4 col-sm-4 offset-md-5 col-md-2 pt-5 pb-4",
      },
      [
        _c("img", {
          staticClass: "mt-2 w-100",
          attrs: { src: _vm.logo, alt: "" },
        }),
      ]
    ),
    _vm._v(" "),
    _vm.view === "login"
      ? _c(
          "form",
          {
            staticClass:
              "col-10 offset-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-4 offset-lg-4 col-xl-2 offset-xl-5",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.processForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "visually-hidden form-label" }, [
                _vm._v("Username"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginFormData.username,
                    expression: "loginFormData.username",
                  },
                  { name: "focus", rawName: "v-focus" },
                ],
                staticClass: "form-control input-lg",
                attrs: {
                  autocapitalize: "off",
                  autocorrect: "off",
                  name: "username",
                  placeholder: "Username",
                  type: "text",
                },
                domProps: { value: _vm.loginFormData.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.loginFormData, "username", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "visually-hidden form-label" }, [
                _vm._v("Password"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginFormData.password,
                    expression: "loginFormData.password",
                  },
                ],
                staticClass: "form-control input-lg",
                attrs: {
                  autocomplete: "off",
                  name: "password",
                  placeholder: "Password",
                  type: "password",
                },
                domProps: { value: _vm.loginFormData.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.loginFormData, "password", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-light",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.view = "password"
                    },
                  },
                },
                [_vm._v("Forgot password?")]
              ),
            ]),
          ]
        )
      : _vm.view === "password"
      ? _c(
          "form",
          {
            staticClass:
              "col-10 offset-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-4 offset-lg-4 col-xl-2 offset-xl-5",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.processPasswordForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "visually-hidden form-label" }, [
                _vm._v("Email"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginFormData.email,
                    expression: "loginFormData.email",
                  },
                ],
                ref: "forgotPasswordEmail",
                staticClass: "form-control input-lg",
                attrs: {
                  autocomplete: "false",
                  name: "email",
                  placeholder: "Email",
                  type: "text",
                },
                domProps: { value: _vm.loginFormData.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.loginFormData, "email", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-light btn-sm",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.view = "login"
                    },
                  },
                },
                [_vm._v("« back")]
              ),
            ]),
          ]
        )
      : _vm.view === "password-email-sent"
      ? _c(
          "div",
          {
            staticClass:
              "col-10 offset-1 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4",
          },
          [
            _vm._m(2),
            _vm._v(" "),
            _c("p", { staticClass: "mb-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.view = "login"
                    },
                  },
                },
                [_vm._v("Go to login")]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("button", { staticClass: "btn btn-primary" }, [_vm._v("Sign in")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-4" }, [
      _c("button", { staticClass: "btn btn-primary" }, [_vm._v("Submit")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-info text-start" }, [
      _c("h3", { staticClass: "mt-0" }, [_vm._v("Check your email")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                If you have entered a registered email address then you will shortly receive a reset password email.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                If you can't find the email, you may need to check your spam folder.\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }