<template>
    <div class="container-fluid">


        <template v-if="!subscriptionActive">
            <h1>
                Subscribe to a plan
            </h1>

            <div class="text-center plans">

                <div v-if="!plans.length" class="ajax-spinner">
                    <span class="ajax-spinner-icon mt-3"/>
                </div>

                <div
                    v-for="(plan, index) in plans"
                    v-else
                    :key="index"
                    :class="{'active': index === activeIndex}"
                    class="card plan"
                >
                    <div class="card-body text-left">
                        <h2 class="plan-heading">
                            {{ plan.name }}
                        </h2>

                        <p class="price">
                            £{{ plan.amount }}
                        </p>

                        <p class="price-label">
                            Per month
                            <br>
                            Billed monthly
                        </p>

                        <div class="text-center">
                            <button
                                class="btn btn-light"
                                @click="selectPlan(index)"
                            >
                                {{ index === activeIndex ? 'Selected' : 'Select' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="selectedPlan"
                class="card subscription-panel"
            >
                <div class="card-body">
                    <h2 class="subscription-card-header">
                        Enter your card details.
                        <br>
                        Your subscription will start <span class="highlight">XXX</span>.
                    </h2>
                    <p class="subscription-panel-info">
                        → Total due now <strong>£{{ selectedPlan.amount }}</strong>
                        <br>
                        → Subscribing to <strong>{{ selectedPlan.name }}</strong>
                    </p>

                    <div>
                        <form
                            id="payment-form"
                            :disabled="subscribing"
                            @submit.prevent="onSubmit()"
                        >
                            <label>Card</label>
                            <div id="card-element">
                                <!-- Elements will create input elements here -->
                            </div>

                            <!-- We'll put the error messages in this element -->
                            <div
                                v-if="cardError"
                                id="card-element-errors"
                                class="alert alert-danger"
                                role="alert"
                            >
                                {{ cardError }}
                            </div>

                            <p class="text-center">
                                <button
                                    :class="{'active': subscribing}"
                                    :disabled="subscribing"
                                    class="btn btn-warning"
                                    type="submit"
                                >
                                    {{ subscribing ? 'Subscribing' : 'Subscribe' }}
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="subscriptionActive && plan && paymentMethod">
            <h1>
                Current plan
            </h1>

            <div class="card subscribed-panel">
                <div class="card-body">
                    <h2>
                        {{ plan.name }}
                    </h2>

                    <p class="subscription-panel-info">
                        Amount <strong class="float-end">£{{ plan.amount }}</strong>
                        <br>
                        Credit card <strong class="float-end">{{ brand }} •••• {{ paymentMethod.card.last4 }}</strong>
                    </p>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
// https://github.com/stripe/stripe-js
import {loadStripe} from '@stripe/stripe-js/pure'

export default {
    name: "SubscriptionPlan",
    components: {},
    data() {
        return {
            activeIndex: -1,
            selectedPlan: undefined,
            plans: [],
            card: undefined,
            cardError: '',
            subscribing: false,
        }
    },
    computed: {
        priceId() {
            return this.$store.state.settings.subscriptionPriceId
        },
        subscriptionActive() {
            return this.$store.state.settings.subscriptionActive
        },
        paymentMethodId() {
            if (this.$store.state.settings.subscriptionData) {
                return this.$store.state.settings.subscriptionData.paymentMethodId
            }
        },
        plan() {
            if (this.subscriptionActive) {
                return this.plans[0]
            }
        },
        brand() {
            if (this.paymentMethod) {
                let brand = this.paymentMethod.card.brand
                return brand.charAt(0).toUpperCase() + brand.slice(1)
            }
        }
    },
    asyncComputed: {
        paymentMethod() {
            if (this.paymentMethodId) {
                return this.$store.dispatch('request/get', {
                        url: 'api/stripe-payment-method/' + this.paymentMethodId
                    })
                    .then((obj) => {
                        return obj.data.paymentMethod
                    })
            }
        },
        stripe() {
            return loadStripe('pk_test_KOopaRx8A8XR0p9QhesQ1b5R')
                .then((stripe) => {
                    console.log(stripe)
                    return stripe
                })
        }
    },
    created() {
        this.setPlan()
    },
    methods: {
        setPlan() {
            let price
            let product
            let plan = {}

            if (this.priceId) {
                this.$store.dispatch('request/get', {
                        url: 'api/stripe-price/' + this.priceId
                    })
                    .then((obj) => {
                        price = obj.data.price
                        //console.log('price', price);
                        plan.amount = Math.abs(price.unit_amount / 100)

                        return this.$store.dispatch('request/get', {
                            url: 'api/stripe-product/' + price.product
                        })
                    })
                    .then((obj) => {
                        product = obj.data.product
                        //console.log('product', product);
                        plan.name = product.name
                        //console.log('product', product);
                        this.plans.push(plan)
                    })
            }
        },
        selectPlan(index) {
            this.activeIndex = index
            this.selectedPlan = this.plans[index]

            this.$nextTick(() => {
                let elements = this.stripe.elements()
                this.card = elements.create('card', {
                    //https://stripe.com/docs/js/appendix/style
                    /*style: {
                        base: {
                            iconColor: '#c4f0ff',
                            color: '#fff',
                            fontWeight: 500,
                            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                            fontSize: '16px',
                            fontSmoothing: 'antialiased',
                            ':-webkit-autofill': {
                                color: '#fce883',
                            },
                            '::placeholder': {
                                color: '#87BBFD',
                            },
                        },
                        invalid: {
                            iconColor: '#FFC7EE',
                            color: '#FFC7EE',
                        },
                    },*/
                })
                this.card.mount('#card-element')

                this.card.on('change', (event) => {
                    this.displayError(event)
                })
            })
        },
        displayError(event) {
            //changeLoadingStatePrices(false);
            this.cardError = event.error
                ? event.error.message
                : ''
        },
        onSubmit() {
            this.subscribing = true

            this.createCustomer()
                .then((customer) => {
                    this.createPaymentMethod({
                        customerId: customer.id
                    })
                })
        },
        createCustomer() {
            return this.$store.dispatch('request/post', {
                    url: 'api/stripe-customer',
                    postData: {
                        email: this.$store.state.user.user.email,
                        name: this.$store.state.user.user.displayName,
                    }
                })
                .then((obj) => {
                    return obj.data.customer
                })
        },
        createPaymentMethod({customerId}) {
            this.stripe
                .createPaymentMethod({
                    type: 'card',
                    card: this.card,
                    billing_details: {
                        name: this.$store.state.user.user.displayName,
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.displayError(result)
                        this.subscribing = false

                    } else {
                        this.createSubscription({
                            customerId: customerId,
                            paymentMethodId: result.paymentMethod.id,
                            priceId: this.priceId
                        })
                    }
                })
        },
        createSubscription({customerId, paymentMethodId, priceId}) {
            return this.$store.dispatch('request/post', {
                    url: 'api/stripe-subscription',
                    postData: {
                        customerId: customerId,
                        paymentMethodId: paymentMethodId,
                        priceId: priceId,
                    }
                })
                .then((obj) => {

                    // If PaymentMethod::retrieve throws an error it will be returned as "error"
                    // E.g. "Your card was declined."
                    if (obj.data.error) {
                        throw obj.data.error
                    }

                    // Return the properties required by the following functions.
                    return {
                        paymentMethodId: paymentMethodId,
                        priceId: priceId,
                        subscription: obj.data,
                    }
                })

                // Some payment methods require a customer to be on session
                // to complete the payment process. Check the status of the
                // payment intent to handle these actions.
                .then(this.handlePaymentThatRequiresCustomerAction)

                // If attaching this card to a Customer object succeeds,
                // but attempts to charge the customer fail, you
                // get a requires_payment_method error.
                .then(this.handleRequiresPaymentMethod)

                // No more actions required. Provision your service for the user.
                .then(this.onSubscriptionComplete)

                .catch((error) => {
                    // Enable the form once the subscription request is complete.
                    this.subscribing = false

                    this.cardError = error
                })
        },
        handlePaymentThatRequiresCustomerAction({
                                                    subscription,
                                                    invoice,
                                                    priceId,
                                                    paymentMethodId,
                                                    isRetry,
                                                }) {

            // Subscription is active, no customer actions required.
            if (subscription && subscription.status === 'active') {
                return {subscription, priceId, paymentMethodId}
            }

            // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
            // If it's a retry, the payment intent will be on the invoice itself.
            let paymentIntent = invoice
                ? invoice.payment_intent
                : (
                    subscription.latest_invoice
                        ? subscription.latest_invoice.payment_intent : undefined
                )

            if (
                // If this is a trial then there will no payment_intent
                paymentIntent
                && (
                    paymentIntent.status === 'requires_action'
                    || (
                        isRetry === true
                        && paymentIntent.status === 'requires_payment_method'
                    )
                )
            ) {
                return this.stripe
                    .confirmCardPayment(paymentIntent.client_secret, {
                        payment_method: paymentMethodId,
                    })
                    .then((result) => {
                        if (result.error) {
                            // Start code flow to handle updating the payment details.
                            // Display error message in your UI.
                            // The card was declined (i.e. insufficient funds, card has expired, etc).
                            throw result
                        } else {
                            console.log('result.paymentIntent', result.paymentIntent)
                            if (result.paymentIntent.status === 'succeeded') {
                                // Show a success message to your customer.
                                return {
                                    priceId: priceId,
                                    subscription: subscription,
                                    invoice: invoice,
                                    paymentMethodId: paymentMethodId,
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        this.displayError(error)
                    })
            } else {
                // No customer action needed.
                return {subscription, priceId, paymentMethodId}
            }
        },
        handleRequiresPaymentMethod({
                                        subscription,
                                        paymentMethodId,
                                        priceId,
                                    }) {

            if (subscription.status === 'active') {
                // subscription is active, no customer actions required.
                return {subscription, priceId, paymentMethodId}

            } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
                // Using localStorage to manage the state of the retry here,
                // feel free to replace with what you prefer.
                // Store the latest invoice ID and status.
                localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
                localStorage.setItem(
                    'latestInvoicePaymentIntentStatus',
                    subscription.latest_invoice.payment_intent.status
                )
                throw {error: {message: 'Your card was declined.'}}

            } else {
                return {subscription, priceId, paymentMethodId}
            }
        },
        onSubscriptionComplete({paymentMethodId, priceId, subscription}) {
            // Enable the form once the subscription request is complete.
            this.subscribing = false

            let subscriptionActive = 0

            switch (subscription.status) {

                // 3D Secure was authorised.
                // No payment was taken, the invoice is not paid.
                // Note: 3DS will never been triggered when creating a trial
                case 'incomplete':
                    break

                // Payment was successful.
                case 'active':
                // Trial was set up.
                case 'trialing':
                    subscriptionActive = 1
                    break

            }

            this.$store.dispatch('itemData/update', {
                tableName: 'account_settings',
                id: 1,
                data: {
                    subscriptionActive: subscriptionActive,
                    subscriptionData: {
                        subscriptionStatus: subscription.status,
                        paymentMethodId: paymentMethodId,
                        subscriptionId: subscription.id,
                        customerId: subscription.customer,
                        priceId: priceId,
                        productId: subscription.items.data[0].price.product,
                        currentPeriodEnd: subscription.current_period_end,
                    }
                },
            })
        }
    }
}
</script>

<style scoped>
h1 {
    margin: 50px 0 30px;
    text-align: center;
    color: tomato;
    font-size: 28px;
}

.plans {
    margin-bottom: 30px;
}

.plan {
    display: inline-block;
    margin: 0 15px;
    width: 200px;
}

.plan-heading {
    margin-top: 0;
    color: #aaa;
    font-weight: 400;
    font-size: 18px;
}

.price {
    margin-bottom: 0;
    font-size: 26px;
    font-weight: bold;
    color: tomato;
}

.price-label {
    margin-bottom: 15px;
    font-size: 12px;
    color: #999;
}

.plan.active button {
    background-color: white;
    color: tomato;
}

.subscription-panel {
    margin: 0 auto;
    width: 500px;
}

.subscription-card-header {
    margin-top: 0;
    font-size: 21px;
    line-height: 1.4;
}

.subscription-panel-info {
    margin-bottom: 15px;
    font-size: 18px;
    color: #666;
}

.subscription-panel-info strong {
    color: #333;
}

label {
    text-transform: uppercase;
    font-weight: 700;
    color: #666;
    font-size: 13px;
}

#card-element {
    margin: 0 0 15px;
    padding: 15px;
    background: whitesmoke;
    border: 1px solid lavender;
    border-radius: 5px;
}

.subscribed-panel {
    margin: 0 auto;
    width: 500px;
}

.highlight {
    color: red !important;
}

</style>