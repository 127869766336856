<template>
    <div>
        <div class="text-end mb-2" @click.stop>
            <Button class="btn-sm" @click.native="closeMenu">
                <i class="bi-x-lg"/>
                close
            </Button>
        </div>

        <div class="list-group navigation-mobile-group-list-group">
            <NavigationMobileGroup v-for="groupName in navigationGroups" :key="groupName" :close-menu="closeMenu"
                                   :group-name="groupName"/>
        </div>

        <div class="text-end">
            <Button class="btn-sm" @click.native="closeMenu">
                <i class="bi-x-lg"/>
                close
            </Button>
        </div>
    </div>
</template>

<script>
import NavigationMobileGroup from './NavigationMobileGroup'
import Button from "../Button.vue"

export default {
    name: "NavigationMobileMenu",
    components: {
        Button,
        NavigationMobileGroup
    },
    props: {
        closeMenu: Function,
    },
    computed: {
        navigationGroups() {
            return this.$store.state.navigation.groups
        }
    }
}
</script>

<style scoped>
.navigation-mobile-group-list-group {
    margin-bottom: 10px;
}
</style>