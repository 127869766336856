<template>
    <div v-disable-shift-select>
        <div v-if="itemCount === 0">
            <ListingNoContent :view-id="viewId"/>
            <ListingNoContentOptions :view-id="viewId"/>
        </div>

        <div v-show="itemCount" class="overflow-scroll">
            <ListingSelectAll :view-id="viewId"/>

            <table class="table table-hover table-dashed">
                <TableViewHead :view-id="viewId"/>

                <tbody class="bg-white">
                <TableViewRow v-for="id in ids" :id="id" :key="id" :view-id="viewId"/>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import ListingNoContent from './ListingNoContent'
import ListingNoContentOptions from './ListingNoContentOptions'
import ListingSelectAll from './ListingSelectAll'
import TableViewHead from './TableViewHead'
import TableViewRow from './TableViewRow'

import disableShiftSelect from '../../vue/directives/disableShiftSelect'

export default {
    name: "TableView",
    components: {
        ListingNoContent,
        ListingNoContentOptions,
        ListingSelectAll,
        TableViewHead,
        TableViewRow
    },
    directives: {
        disableShiftSelect
    },
    props: {
        viewId: String
    },
    computed: {
        listingName() {
            return this.$store.state[this.viewId].listingName
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        itemCount() {
            return this.$store.state[this.viewId].itemCount
        },
        ids() {
            return this.$store.state[this.viewId].ids
        }
    },
    created() {
        this.$store.commit('lightbox/reset')
    }
}
</script>

<style scoped>

</style>