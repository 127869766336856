<script>
import FormControlTypeText from '../form-control-type/FormControlTypeText'

export default {
    name: "FormControlModulestructureColumnName",
    extends: FormControlTypeText,
    computed: {
        titleValue() {
            return this.currentData.title
        }
    },
    watch: {
        titleValue(val, oldVal) {
            // Only format the value when creating new fields.
            // This is so that modifying labels for existing fields does not alter their names.
            // Names for existing fields must be changed directly, as they can cause referencing issues
            if (!this.currentData.id) {
                // If this field's current value is the formatted version of the old title value then
                // the values are currently in sync, so set it to the formatted version of the new value.
                if (
                    this.currentData[this.field.name] === this.formatValue(oldVal)
                    || this.currentData[this.field.name] === ''
                ) {
                    this.currentData[this.field.name] = this.formatValue(val)
                }
            }
        }
    },
    methods: {
        formatValue(str) {
            str = str.replace(/[^a-zA-Z 0-9]+/g, '') // Remove all special characters

            str = str.substring(0, 1).toLowerCase() + str.substring(1) // First character to lowercase
            // Update: I've replaced the above with this, so that it converts Basket URL to basketUrl and not basketURL
            // See also VueX componentFields.js
            //str = str.toLowerCase()

            // Replace all double spaces left by removing special chars
            while (str.match('  ')) {
                str = str.replace('  ', ' ')
            }

            return str.replace(/ (.)/g, (match, group1) => {
                return group1.toUpperCase()
            })
        }
    }
}
</script>

<style scoped>

</style>