<template>
    <div>
        <FormControlTypeSelect v-if="options" :current-data="currentData" :field="field" :form-id="formId"
                               :options="options"/>

        <PageContentTemplateContent v-if="currentData.templateId" :template-id="currentData.templateId"/>
    </div>
</template>

<script>
import FormControlTypeSelect from '../form-control-type/FormControlTypeSelect'
import PageContentTemplateContent from "../page-content/PageContentTemplateContent.vue"

export default {
    name: "FormControlPagesTemplateId",
    components: {
        PageContentTemplateContent,
        FormControlTypeSelect,
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    computed: {
        id() {
            return this.$store.state[this.formId].id
        },
        pageTemplates() {
            return this.$store.getters['templates/pageTemplates'];
        },
        options() {
            if (this.pageTemplates.length) {
                let options = [{
                    value: '',
                    text: 'Select…'
                }]

                this.pageTemplates.forEach((o) => {
                    options.push({
                        value: o.id,
                        text: o.title
                    })
                })

                return options
            }
        }
    },
    created() {
        this.$store.dispatch('templates/init')
    }
}
</script>

<style scoped>

</style>