<template>
    <div v-if="componentIsEmpty && betaMode" class="text-center">
        <div class="mt-1">
            <Button data-bs-toggle="dropdown">
                <i class="bi-three-dots"/>
            </Button>

            <div class="dropdown-menu">
                <a href @click.prevent="resetIds" class="dropdown-item">
                    Reset IDs (auto increment)
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingNoContentOptions",
    components: {Button},
    props: {
        viewId: String,
    },
    data() {
        return {
            componentIsEmpty: false,
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
        betaMode() {
            return this.$store.state.betaMode
        }
    },
    created() {
        this.$store.dispatch('request/get', {
                url: 'api/component/' + this.tableName,
                params: {
                    field: 'id',
                }
            })
            .then((obj) => {
                this.componentIsEmpty = (
                    !obj.data.values
                    || obj.data.values.length === 0
                )
            })
    },
    methods: {
        resetIds() {
            this.$store.dispatch('request/post', {
                url: 'api/reset-components-auto-increment',
                postData: {
                    tableName: this.tableName
                }
            })
        }
    }
}
</script>

<style scoped>

</style>