var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.error
        ? _c(
            "div",
            { staticClass: "alert alert-danger", attrs: { role: "alert" } },
            [_vm._v(_vm._s(_vm.error))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "p-3 clearfix" }, [
        _c("div", { staticClass: "float-start" }, [
          _c(
            "form",
            {
              staticClass: "d-flex align-items-center",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.loadRows.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.report,
                      expression: "formData.report",
                    },
                  ],
                  staticClass: "form-select me-2",
                  attrs: { name: "report" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.formData,
                        "report",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Select a report…"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.reports, function (report) {
                    return _c("option", { domProps: { value: report } }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(report) +
                          "\n                    "
                      ),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.formData.report
                ? [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.startDate,
                          expression: "formData.startDate",
                        },
                      ],
                      staticClass: "form-control me-2",
                      attrs: {
                        name: "start_date",
                        placeholder: "dd/mm/yyyy",
                        size: "12",
                      },
                      domProps: { value: _vm.formData.startDate },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.formData,
                            "startDate",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "form-text me-2" }, [
                      _vm._v("to"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.endDate,
                          expression: "formData.endDate",
                        },
                      ],
                      staticClass: "form-control me-2",
                      attrs: {
                        name: "end_date",
                        placeholder: "dd/mm/yyyy",
                        size: "12",
                      },
                      domProps: { value: _vm.formData.endDate },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.formData, "endDate", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-sm",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.loadRows.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Go\n                    "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _vm.rows && _vm.rows.length
          ? _c("div", { staticClass: "float-end" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-light",
                  attrs: { type: "button" },
                  on: { click: _vm.openPrintModal },
                },
                [
                  _c("i", { staticClass: "bi-printer" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "visually-hidden" }, [
                    _vm._v("Print report"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-light",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.downloadCSVData.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "bi-download" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "visually-hidden" }, [
                    _vm._v("Download CSV"),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.resultMessage
        ? _c("p", { staticClass: "text-center" }, [
            _vm._v("\n        " + _vm._s(_vm.resultMessage) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.rows && _vm.rows.length
        ? _c("div", { staticClass: "p-2" }, [
            _c(
              "table",
              { staticClass: "table table-condensed table-striped" },
              [
                _c("thead", [
                  _c(
                    "tr",
                    _vm._l(_vm.rows[0], function (value, heading) {
                      return _c(
                        "td",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.setSortBy(heading)
                            },
                          },
                        },
                        [
                          heading === _vm.sortBy
                            ? _c("span", {
                                class: {
                                  "bi-chevron-up": _vm.sortDir === "asc",
                                  "bi-chevron-down": _vm.sortDir === "desc",
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n                    " +
                              _vm._s(heading) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.sortedRows, function (row) {
                    return _c(
                      "tr",
                      _vm._l(row, function (value, heading) {
                        return _c("td", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(value) +
                              "\n                "
                          ),
                        ])
                      }),
                      0
                    )
                  }),
                  0
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.rows
        ? _c("PrintReport", {
            ref: "toPrint",
            staticStyle: { display: "none" },
            attrs: {
              "end-timestamp": _vm.endTimestamp,
              heading: _vm.formData.report,
              rows: _vm.sortedRows,
              "start-timestamp": _vm.startTimestamp,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }