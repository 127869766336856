<template>
    <div>
        <p class="form-control-tags-usage--heading">
            {{ component.title }}
        </p>

        <div class="list-group">
            <div v-for="item in items" :key="item.id" class="list-group-item form-control-tags-usage--list-group-item">
                <a href @click.prevent="onClick(item.id)">
                    {{ item[nameField] }}
                </a>

                <button class="btn btn-sm" type="button" @click="removeTag(item.id)">
                    <i class="bi-trash3"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormControlTagsUsageComponent",
    props: {
        componentId: Number,
        tagId: Number,
        itemIds: Array,
    },
    computed: {
        component() {
            return this.$store.state.components.items.find(o => o.id === this.componentId)
        },
        tableName() {
            return this.component.tableName
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
    },
    asyncComputed: {
        items() {
            return this.$store.dispatch('itemData/preload', {
                    tableName: this.tableName,
                    ids: this.itemIds,
                })
                .then((objs) => {
                    return objs
                })
        }
    },
    methods: {
        onClick(id) {
            this.$router.push({name: 'form', params: {component: this.tableName, id: id}})
        },
        removeTag(itemId) {
            this.$store.dispatch('request/post', {
                    url: 'api/tags/remove-tag-from-item',
                    postData: {
                        tableName: this.tableName,
                        itemId: itemId,
                        tagId: this.tagId
                    }
                })
                .then(() => {
                    this.items.forEach((item, key) => {
                        if (item.id === itemId) {
                            this.items.splice(key, 1)
                        }
                    })
                })
        }
    }
}
</script>

<style scoped>
.form-control-tags-usage--heading {
    margin: 9px 10px;
    font-size: 13px;
    font-weight: 700;
}

.form-control-tags-usage--list-group-item {
    display: flex;
}

.form-control-tags-usage--list-group-item > :first-child {
    flex: auto;
}
</style>