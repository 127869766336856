<template>
    <div ref="dropzone" class="well dropzone">
        <div class="d-flex align-items-center justify-content-center">
            Drag {{ fileStr }} here, or

            <Button class="ms-2 btn-sm" @click.native="chooseFile" :default-class="'btn-secondary'">
                Browse
            </Button>

            <input ref="fileInput" :multiple="multiple" class="d-none" type="file" @change="onChangeFileInput">

            <!--<button
                v-show="files.length"
                class="float-end"
                title="Remove file"
                @click.prevent="removeFile"
            >
                <i class="bi-x-lg"/>
            </button>-->
        </div>

        <div v-if="files.length" class="mt-3">
            <div class="list-group">
                <div v-for="file in files" class="list-group-item">
                    <p class="file-info">
                        <span>{{ file.name }}</span>

                        <code v-if="file.percentage >= 0">{{ humanFileSize(file.size) }}</code>
                        <span v-if="file.error" class="badge bg-danger">{{ file.error }}</span>
                        <span v-if="file.percentage >= 0">{{ file.percentage }}%</span>
                    </p>

                    <ProgressBar v-if="file.percentage >= 0" :percentage="file.percentage"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import ProgressBar from '../vue/common/ProgressBar'

import humanFileSize from '../vue/mixins/human-file-size'
import Button from "./Button.vue"

export default {
    name: "AttachmentDropzone",
    components: {
        Button,
        ProgressBar
    },
    mixins: [humanFileSize],
    props: {
        controller: String,
        mimeTypes: String,
        multiple: Boolean,
        setFile: Function,
        useImportService: Boolean,
    },
    data() {
        return {
            fileInput: undefined,
            files: []
        }
    },
    computed: {
        fileStr() {
            return this.multiple ? 'files' : 'file'
        },
        acceptedMimeTypes() {
            return this.mimeTypes ? this.mimeTypes.split(',') : []
        }
    },
    mounted() {
        this.fileInput = this.$refs.fileInput

        this.applyDropzoneEvents()
    },
    methods: {
        onChangeFileInput() {
            if (this.fileInput.value) {
                this.uploadFiles(this.fileInput.files)
            }
        },
        applyDropzoneEvents() {
            let dropzone = this.$refs.dropzone

            dropzone.ondragenter = () => {
                dropzone.classList.add('active')
            }
            dropzone.ondragleave = () => {
                dropzone.classList.remove('active')
            }
            dropzone.ondragover = (e) => {
                e.preventDefault()
                dropzone.classList.add('active')
            }
            dropzone.ondrop = (e) => {
                e.preventDefault()

                let data = e.dataTransfer || e.originalEvent.dataTransfer
                this.uploadFiles(data.files)

                dropzone.classList.remove('active')
            }
        },
        chooseFile() {
            this.fileInput.click()
        },
        removeFile() {
            this.setFile(false)
        },
        uploadFiles(files) {
            const filesArray = Array.from(files)

            // Before we start to upload each file, we want to add their info (name, size, type) to this.files
            // so that the files can be presented before their progress commences.
            filesArray.forEach((file) => {
                let fileData = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    percentage: undefined,
                    error: undefined
                }

                // todo - NOT TESTED!
                if (
                    this.acceptedMimeTypes.length
                    && this.acceptedMimeTypes.indexOf(file.type) === -1
                ) {
                    fileData.error = 'Mimetype not accepted'
                }

                this.files.push(fileData)
            })

            this.validateFileNames()
                .finally(() => {
                    this.fileUploadLoop(filesArray)
                })
        },
        validateFileNames() {
            const fileNames = this.files.map(o => o.name)

            return this.$store.dispatch('request/get', {
                    url: 'api/component/files',
                    params: {
                        fileName: fileNames
                    }
                })
                .then((obj) => {
                    if (obj.data.items?.length) {
                        obj.data.items.forEach((o) => {
                            const fileName = o.fileName
                            const fileData = this.files.find(o => o.name === fileName)
                            fileData.error = 'File exists'
                        })
                    }
                })
        },
        fileUploadLoop(filesArray) {
            // Now start the uploads
            filesArray.forEach((file) => {
                let fileData = this.files.find(o => o.name === file.name)

                if (fileData.error === undefined) {
                    let formData = new FormData()
                    formData.append('file', file)

                    fileData.percentage = 0

                    this.useImportService
                        ? this.sendToImportService(formData, fileData)
                        : this.sendToApi(formData, fileData)
                }
            })
        },
        sendToImportService(formData, fileData) {
            formData.append('buildName', this.$store.state.sitename)

            axios({
                method: 'post',
                url: this.$store.state.importServiceEndpoint + '/upload',
                headers: {
                    'Content-Type': undefined,
                    'X-Auth-Token': this.$store.state.importServiceToken
                },
                data: formData,
                onUploadProgress: (e) => {
                    fileData.percentage = (e.loaded / e.total * 100 | 0)
                }
            })
                .then((obj) => {
                    this.setFile(obj.data)
                })
        },
        sendToApi(formData, fileData) {
            let controller = this.controller || 'api/FilesController/upload'

            this.$store.dispatch('request/post', {
                    url: this.$store.state.apiEndpoint + '/' + controller,
                    postData: formData,
                    customHeaders: {
                        'Content-Type': undefined,
                        //'X-Auth-Token': this.$store.state.importServiceToken
                    },
                    onUploadProgress: (e) => {
                        fileData.percentage = (e.loaded / e.total * 100 | 0)
                    }
                })
                .then((obj) => {
                    this.setFile(obj.data)
                })
        }
    }
}
</script>

<style scoped>
.dropzone {
    position: relative;
    margin: -10px 0 0 -10px;
    padding: 10px;
    border-radius: 5px;
}

.dropzone.active {
    background-color: #e3e3e3;
}

.file-info {
    display: flex;
    align-items: center;
}

.file-info > * {
    margin-left: 5px;
}

.file-info > :first-child {
    flex: auto;
    margin-left: 0;
}
</style>