<script>
import FormControlTypeRelationshipOneToMany from '../form-control-type/FormControlTypeRelationshipOneToMany'

export default {
    name: "FormControlProductOptionsProductTypeId",
    extends: FormControlTypeRelationshipOneToMany,
    created() {
        if (this.currentData.productTypeId) {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>