var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "div",
      {
        staticClass: "d-inline-block",
        attrs: { "data-bs-toggle": "dropdown" },
      },
      [
        _c(
          "Button",
          {
            staticClass: "rounded-0",
            attrs: {
              "active-class": "btn-warning",
              "active-value": _vm.showArchive,
            },
          },
          [_c("i", { staticClass: "bi-three-dots" })]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dropdown-menu" },
      [
        _c("ListingOptionsPageSize", {
          staticClass: "dropdown-item",
          attrs: { "view-id": _vm.viewId },
        }),
        _vm._v(" "),
        _c("ListingOptionsArchiveBtn", {
          staticClass: "dropdown-item",
          attrs: { "show-text": true, "view-id": _vm.viewId },
        }),
        _vm._v(" "),
        _c("ListingOptionsDeleteBtn", {
          staticClass: "dropdown-item",
          attrs: { "show-text": true, "view-id": _vm.viewId },
        }),
        _vm._v(" "),
        _c("ListingOptionsViewMode", {
          staticClass: "dropdown-item",
          attrs: { "view-id": _vm.viewId },
        }),
        _vm._v(" "),
        !_vm.selectMode
          ? _c("ListingOptionsShowArchiveBtn", {
              staticClass: "dropdown-item",
              attrs: { "view-id": _vm.viewId },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.selectMode
          ? _c("ListingOptionsExportBtn", {
              staticClass: "dropdown-item",
              attrs: { "view-id": _vm.viewId },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.selectMode
          ? _c("ListingOptionsImportBtn", {
              staticClass: "dropdown-item",
              attrs: { "view-id": _vm.viewId },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.selectMode
          ? _c("ListingOptionsBulkUploadBtn", {
              staticClass: "dropdown-item",
              attrs: { "view-id": _vm.viewId },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("ListingOptionsColumnsBtn", {
          staticClass: "dropdown-item",
          attrs: { "view-id": _vm.viewId },
        }),
        _vm._v(" "),
        !_vm.isSelectListing
          ? _c("ComponentSettingsBtn", {
              staticClass: "dropdown-item",
              attrs: { "table-name": _vm.tableName },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isSelectListing
          ? _c("ComponentFieldsBtn", {
              staticClass: "dropdown-item",
              attrs: { "table-name": _vm.tableName },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }