var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fieldData.tableName
    ? _c(
        "div",
        [
          _vm.field.readonly
            ? _c(
                "FormControlPlainText",
                [
                  _c("FormControlCategoryTitle", {
                    attrs: {
                      id: _vm.currentData[_vm.field.name],
                      "table-name": _vm.fieldData.tableName,
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentData[_vm.field.name],
                        expression: "currentData[field.name]",
                      },
                    ],
                    staticClass: "visually-hidden",
                    attrs: {
                      name: _vm.field.name,
                      required: _vm.field.required,
                    },
                    domProps: { value: _vm.currentData[_vm.field.name] },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.currentData,
                          _vm.field.name,
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "Button",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.selectCategories.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "bi-plus-lg" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "user-access",
                              rawName: "v-user-access",
                              value: _vm.fieldData.tableName,
                              expression: "fieldData.tableName",
                            },
                          ],
                          nativeOn: {
                            click: function ($event) {
                              return _vm.manageCategories.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "bi-pencil-square" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentData[_vm.field.name]
                    ? [
                        _vm.isOrderCustomerControl
                          ? _c("OrderDetailsCustomerPanel", {
                              attrs: { "customer-id": _vm.value },
                            })
                          : _c("div", { staticClass: "list-group" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "list-group-item d-flex align-items-center",
                                  attrs: { href: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.editItem.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c("FormControlCategoryTitle", {
                                    staticClass: "flex-fill",
                                    attrs: {
                                      id: _vm.currentData[_vm.field.name],
                                      "table-name": _vm.fieldData.tableName,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ItemStatusIcons", {
                                    attrs: {
                                      id: _vm.currentData[_vm.field.name],
                                      "table-name": _vm.fieldData.tableName,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "Button",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.removeCategory.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "bi-x-lg" })]
                                  ),
                                ],
                                1
                              ),
                            ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }