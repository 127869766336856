<template>
    <div :class="{ 'content-type--items-is-dragging': drag }">
        <draggable v-if="items.length" v-model="items" direction="vertical" draggable="[data-draggable-item]"
                   v-bind="dragOptions" @end="onDragEnd" @start="drag = true">
            <transition-group class="overflow-hidden" type="transition">
                <Button v-for="item in items" v-if="items" :key="item.id" ref="contentTypeItem" :disabled="isDisabled"
                        :active-class="'btn-primary'" :active-value="item.id === selectedId"
                        class="ps-0 btn-sm w-100 d-flex align-items-top text-start" data-draggable-item
                        draggable="true" @click.prevent.native="editItem(item.id)">
                    <DragHandle class="content-type--item-drag-handle"/>

                    <FieldTypeIcon :type="item.type" class="me-2"/>

                    <!--<code v-if="$store.state.alphaMode">{{ item.displayOrder }}&nbsp;</code>-->

                    {{ item.title }}
                </Button>
            </transition-group>
        </draggable>

        <pre>{{ dragItems }}</pre>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import DragHandle from '../../common/DragHandle'
import FieldTypeIcon from "./FieldTypeIcon.vue"
import Button from "../Button.vue"

export default {
    name: "ContentTypeFieldsList",
    components: {
        Button,
        FieldTypeIcon,
        draggable,
        DragHandle
    },
    data() {
        return {
            dragIndex: undefined,
            dragItems: undefined,
            drag: false,
            dragImg: undefined,
            ajaxInProgress: false
        }
    },
    computed: {
        dragOptions() {
            return {
                //https://github.com/SortableJS/sortablejs#options
                animation: 200,
                ghostClass: 'ghost',
                setData: (dataTransfer) => {
                    // By default browsers present a ghost version of the element being dragged, to show nothing
                    // the drag image is set as a blank img.
                    dataTransfer.setDragImage(this.dragImg, 0, 0)
                }
            }
        },
        selectedId() {
            return this.$store.getters['componentFields/isContentType']
                ? this.$store.state.componentFields.activeEditId
                : parseInt(this.$route.params.fieldId)
        },
        activeFieldsetId() {
            return this.$store.state.componentFields.activeFieldsetId
        },
        items: {
            // Get and set are required because the drag and drop sets items
            get() {
                return this.$store.state.componentFields.items
            },
            set(value) {
                this.$store.commit('componentFields/items', value)
            }
        },
        isDisabled() {
            return this.ajaxInProgress
        },
        isContentTypeComponent() {
            return this.$store.getters['componentFields/isContentType']
        }
    },
    created() {
        let img = new Image()
        img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
        this.dragImg = img
    },
    methods: {
        editItem(id) {
            if (this.isContentTypeComponent) {
                this.$store.commit('componentFields/activeEditId', id)
            } else {
                this.$router.push({
                    name: 'componentFields',
                    params: {
                        fieldsetId: this.$store.state.componentFields.activeFieldsetId || 0,
                        fieldId: id
                    }
                })
            }
        },
        onDragEnd(obj) {
            this.drag = false
            let item = this.items[obj.newIndex]
            this.ajaxInProgress = true
            this.$store.dispatch('itemData/update', {
                    tableName: 'modulestructure',
                    id: item.id,
                    data: {
                        displayOrder: obj.newIndex + 1
                    }
                })
                .then(() => {
                    this.$store.dispatch('componentStructure/init')
                        .finally(() => {
                            this.ajaxInProgress = false
                        })
                })
        },
    }
}
</script>

<style scoped>
.ghost {
    background: #666 !important;
    border-color: #666 !important;
    color: #fff !important;
}

.content-type--item-drag-handle {
    opacity: 0;
    color: #999;
}

.list-group-item:hover .content-type--item-drag-handle {
    opacity: 1;
}

/* Hide the drag handle when moving avoids an issue where the wrong item's handle is shown */
.content-type--items-is-dragging .content-type--item-drag-handle {
    opacity: 0 !important;
}
</style>