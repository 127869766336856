import { render, staticRenderFns } from "./MonthInTheLastFilter.vue?vue&type=template&id=bff9b2ac&scoped=true&"
import script from "./MonthInTheLastFilter.vue?vue&type=script&lang=js&"
export * from "./MonthInTheLastFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bff9b2ac",
  null
  
)

export default component.exports