export default {
    namespaced: true,
    state: {
        exists: false,
        user: false,
        prefs: []
    },
    mutations: {
        reset(state) {
            state.exists = false
            state.user = false
        },
        user(state, user) {
            state.user = user
        },
        exists(state, bool) {
            state.exists = bool
        },
        prefs(state, prefs) {
            state.prefs = prefs
        }
    },
    getters: {
        pref: (state) => (name) => {
            return state.prefs.find(o => o.name === name)?.value
        },
        // todo This is being processed many times, can be optimised?
        access: (state, getters, rootState) => (tableName, privilegeName) => {
            // Product variation hacks. Provides access to all product variation's component
            // if the user has access to the products component.
            // See below for publish specific scripts.
            let variationsTable = tableName.indexOf('product_variations__') === 0
            if (variationsTable) {
                tableName = 'm_products_products'
            }
            
            if (state.user.administrator === 1) {
                // "themes_editor" and "site_tree" do not have components
                let component = rootState.components.items.find(o => o.tableName === tableName)
                if (!component) {
                    //console.log('No component', {tableName})
                    return false
                }
                
                // As administrators have no access privileges their access is governed by the component's
                // settings.
                let access = {
                    add: !!component.addNew,
                    edit: !!component.updateOption,
                    archive: !!component.useArchive,
                    'delete': !!component.deleteOption,
                    publish: !!component.showStatus,
                    'export': true
                }
                
                // These components must not have export/import options
                if (['files', 'product_options', 'product_variables'].indexOf(tableName) > -1) {
                    access.export = false
                }
                
                if (privilegeName) {
                    if (variationsTable && privilegeName === 'publish') {
                        return false
                    }
                    
                    return access[privilegeName]
                }
                
                return access
            }
            
            // No component access
            if (!state.user.access?.[tableName]) {
                return false
            }
            
            if (!privilegeName) {
                return state.user.access[tableName]
            }
            
            // No privilege access
            if (!state.user.access[tableName][privilegeName]) {
                return false
            }
            
            if (
                variationsTable
                && privilegeName === 'publish'
            ) {
                return false
            }
            
            return !!state.user.access[tableName][privilegeName]
        }
    },
    actions: {
        set({commit, dispatch}, obj) {
            commit('user', obj)
            
            dispatch('navigation/init', null, {root: true})
            dispatch('userRoles/init', null, {root: true})
            
            return dispatch('getPrefs')
        },
        getPrefs({state, dispatch, commit}) {
            return dispatch('request/get', {
                url: 'api/component/user_preferences',
                params: {
                    fields: ['id', 'name', 'value'],
                    userId: state.user.id
                }
            }, {root: true})
                .then((obj) => {
                    commit('prefs', obj.data.items)
                    
                    commit('exists', true)
                })
        },
        setPref({state, commit, dispatch, rootState}, {name, value}) {
            let item = state.prefs.find(o => o.name === name)
            
            if (item) {
                return dispatch('request/patch', {
                    url: 'api/component/user_preferences/' + item.id,
                    postData: {
                        value: value
                    }
                }, {root: true})
                    .then(o => Object.assign(item, o.data))
            } else {
                return dispatch('request/post', {
                    url: 'api/component/user_preferences',
                    postData: {
                        userId: rootState.user.user.id,
                        name: name,
                        value: value
                    }
                }, {root: true})
                    .then(o => state.prefs.push(o.data))
            }
        },
        toggleDarkMode({getters, dispatch}) {
            const isDark = !getters['pref']('darkMode')
            
            // Allows the login form to use the correct mode.
            //localStorage.setItem('darkMode', isDark)
            
            dispatch('setPref', {
                name: 'darkMode',
                value: isDark
            })
                .then(() => {
                    dispatch('applyDarkMode')
                })
        },
        applyDarkMode({getters}) {
            const isDark = getters['pref']('darkMode')// || (localStorage.getItem('darkMode') === 'true')
            
            document.querySelectorAll('.btn').forEach((el) => {
                // Prevent BS's button transitions fading between the dark and light styles
                el.classList.add('no-transition')
                
                // Without a timeout no-transition has no affect
                setTimeout(() => {
                    el.classList.remove('no-transition')
                }, 50)
            })
            
            document.documentElement.setAttribute('data-bs-theme', isDark ? 'dark' : 'light')
        }
    }
}