var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-dialog modal-lg" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h4", { staticClass: "modal-title" }, [_vm._v(" ")]),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function ($event) {
              return _vm.$emit("hide-modal")
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-body" }, [
        _c(
          "div",
          { staticClass: "list-group" },
          _vm._l(_vm.logs, function (log, index) {
            return _c(
              "div",
              { key: log.id, staticClass: "list-group-item pb-0" },
              [
                _c(
                  "div",
                  {
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      "data-bs-target": "#log-" + index,
                      "data-bs-toggle": "collapse",
                    },
                  },
                  [
                    _c("span", { staticClass: "float-end" }, [
                      _vm._v(_vm._s(_vm.getDate(log.createdDate * 1000))),
                    ]),
                    _vm._v(" "),
                    _c("p", [_c("strong", [_vm._v(_vm._s(log.title) + " ")])]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapse well well-sm well-pre",
                    attrs: { id: "log-" + index },
                  },
                  [_c("pre", [_vm._v(_vm._s(log.data))])]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }