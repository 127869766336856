<template>
    <FormControlPlainText v-if="field.readonly" :value="currentData[field.name]"/>
    <div v-else class="input-group">
        <!-- v-if is used to remove element from the DOM, otherwise validation will fail -->
        <FormControlTypeSelect v-if="!other" :current-data="currentData" :field="field" :form-id="formId"
                               :options="options"/>

        <template v-show="otherOption">
            <button v-if="!other" ref="btn" class="btn btn-outline-secondary btn-sm" type="button" @click="showOther">
                {{ otherOption }}
            </button>

            <template v-else>
                <input ref="input" v-model="currentData[field.name]" class="form-control" type="text"
                       @keyup.esc="hideOther">

                <button class="btn btn-outline-secondary" type="button" @click="hideOther">
                    <i class="bi-x-lg"/>
                </button>
            </template>
        </template>
    </div>
</template>

<script>
import FormControlTypeSelect from './FormControlTypeSelect'
import FormControlPlainText from "../form-control/FormControlPlainText.vue"

export default {
    name: "FormControlTypeSelectOther",
    components: {
        FormControlPlainText,
        FormControlTypeSelect,
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
        // Support for legacy values
        // todo - All data should be provided as arrays in the format of: [ { text: '', value: '' } ]
        options: [String, Array, Object],
    },
    data() {
        return {
            otherOption: this.field.other || 'Other…',
            other: false,
            storedValue: undefined,
        }
    },
    methods: {
        showOther() {
            this.other = true

            this.storedValue = this.currentData[this.field.name]
            this.currentData[this.field.name] = ''

            this.$nextTick(() => {
                this.$refs.input.select()
            })
        },
        hideOther() {
            this.other = false

            this.currentData[this.field.name] = this.storedValue

            this.$nextTick(() => {
                this.$refs.btn.focus()
            })
        }
    }
}
</script>

<style scoped>

</style>