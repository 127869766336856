var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-group list-group-flush me-0" },
    _vm._l(_vm.columns, function (column) {
      return _c(
        "a",
        {
          key: column.id,
          staticClass: "list-group-item p-1 d-flex align-items-center",
          on: {
            click: function ($event) {
              return _vm.toggleColumnVisibility(column.id)
            },
          },
        },
        [
          _c("i", {
            staticClass: "me-1 bi-check-lg",
            class: { invisible: _vm.activeColumns.indexOf(column.id) === -1 },
          }),
          _vm._v("\n        " + _vm._s(column.title) + "\n    "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }