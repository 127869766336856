<template>
    <div class="text-center">
        {{ noContentMessage }}
    </div>
</template>

<script>
export default {
    name: "ListingNoContent",
    props: {
        viewId: String,
    },
    computed: {
        noContentMessage() {
            return this.tableName.indexOf('product_variations__') === 0
                ? 'No variations.'
                : 'No content.'
        },
        tableName() {
            return this.$store.state[this.viewId].tableName
        },
    }
}
</script>

<style scoped>

</style>