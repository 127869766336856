var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Button",
    {
      staticClass: "h-100 rounded-0",
      attrs: { title: "Preview website" },
      nativeOn: {
        click: function ($event) {
          return _vm.openPreviewMode.apply(null, arguments)
        },
      },
    },
    [_c("i", { staticClass: "bi-globe" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }