var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.options
    ? _c(
        "div",
        { staticClass: "list-group" },
        _vm._l(_vm.options, function (groupLayouts, groupTitle) {
          return _c(
            "div",
            { key: groupTitle, staticClass: "list-group-item clearfix" },
            [
              _c("span", { staticClass: "list-group-item-heading" }, [
                _vm._v(_vm._s(groupTitle)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-group float-end" },
                _vm._l(groupLayouts, function (layout, layoutClass) {
                  return _c(
                    "button",
                    {
                      key: layoutClass,
                      staticClass: "btn btn-light btn-sm page-layout-button",
                      class: {
                        active: layoutClass === _vm.currentData[_vm.field.name],
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.setLayout(layoutClass)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "page-layout-button-icon",
                        class: layout.className,
                      }),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }