var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ImageSelection", {
    attrs: {
      "column-name": _vm.field.name,
      "file-browser": true,
      "file-id": _vm.fileId,
      "on-change": _vm.onChange,
      "remove-option": true,
      "row-id": _vm.currentData.id,
      "table-name": _vm.tableName,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }