<template>
    <span v-if="isDebited">({{ formattedValue }})</span>
    <span v-else>{{ formattedValue }}</span>
</template>

<script>
export default {
    name: "TableCellOdpCreditLogAmount",
    props: {
        value: String,
        item: Object,
    },
    computed: {
        formattedValue() {
            // Currency fields can be set with null values, so only output a
            // formatted value if the value is 0.00 or higher.
            if (this.value !== null) {
                return new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    // If the Orders component contains a currency field, e.g. Fly Spain, override the default.
                    currency: this.item.currency || 'gbp'
                })
                    .format(this.value)
            }
        },
        isDebited() {
            return this.item.debited
        }
    },
}
</script>

<style scoped>

</style>