<template>
    <FormControlTypeCurrency
        v-if="currentData.type === 'currency'"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />

    <FormControlTypeCheckbox
        v-else-if="currentData.type === 'date'"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />

    <FormControlTypeNumber
        v-else-if="currentData.type === 'number'"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />

    <FormControlTypeText
        v-else-if="currentData.type === 'select'"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />

    <FormControlTypeText
        v-else-if="currentData.type === 'text'"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />

    <FormControlTypeTextarea
        v-else-if="currentData.type === 'textarea'"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />

    <FormControlTypeTextarea
        v-else-if="currentData.type === 'texteditor'"
        :current-data="currentData"
        :field="field"
        :form-id="formId"
    />
</template>

<script>
import FormControlTypeCheckbox from '../form-control-type/FormControlTypeCheckbox'
import FormControlTypeCurrency from '../form-control-type/FormControlTypeCurrency'
import FormControlTypeText from '../form-control-type/FormControlTypeText'
import FormControlTypeTextarea from '../form-control-type/FormControlTypeTextarea'
import FormControlTypeNumber from "../form-control-type/FormControlTypeNumber"

export default {
    name: "FormControlModulestructureDefaultValue",
    components: {
        FormControlTypeNumber,
        FormControlTypeCheckbox,
        FormControlTypeCurrency,
        FormControlTypeText,
        FormControlTypeTextarea,
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    created() {
        switch (this.currentData.type) {

            case 'currency':
                break

            case 'date':
                this.field.label = 'Default to current time'
                break

            case 'hidden':
                break

            case 'number':
                break

            case 'select':
                this.field.label = 'Default option'
                break

            case 'text':
            case 'textarea':
                break

            case 'texteditor':
                // todo - This is very odd! If it needs to be a unique ID then use a node library.
                this.field.id = 'iugreuygwytweg'
                break

            default:
                this.field.hidden = true
                break
        }
    }
}
</script>

<style scoped>

</style>