<template>
    <component :is="componentName" :field="field" :item="item"/>
</template>

<script>
import CollectionFilterControlTypeColour from "./CollectionFilterControlTypeColour.vue"
import CollectionFilterControlTypeCurrency from "./CollectionFilterControlTypeCurrency.vue"
import CollectionFilterControlTypeDate from "./CollectionFilterControlTypeDate.vue"
import CollectionFilterControlTypeDateDate from "./CollectionFilterControlTypeDateDate.vue"
import CollectionFilterControlTypeDateTimeLocal from "./CollectionFilterControlTypeDateTimeLocal.vue"
import CollectionFilterControlTypeEmail from "./CollectionFilterControlTypeEmail.vue"
import CollectionFilterControlTypeInternalLink from "./CollectionFilterControlTypeInternalLink.vue"
import CollectionFilterControlTypeNumber from "./CollectionFilterControlTypeNumber.vue"
import CollectionFilterControlTypeRelationship from "./CollectionFilterControlTypeRelationship.vue"
import CollectionFilterControlTypeRelationshipManyToMany from "./CollectionFilterControlTypeRelationshipManyToMany.vue"
import CollectionFilterControlTypeSelect from "./CollectionFilterControlTypeSelect.vue"
import CollectionFilterControlTypeTelephone from "./CollectionFilterControlTypeTelephone.vue"
import CollectionFilterControlTypeText from "./CollectionFilterControlTypeText.vue"
import CollectionFilterControlTypeTime from "./CollectionFilterControlTypeTime.vue"
import CollectionFilterControlTypeUrl from "./CollectionFilterControlTypeUrl.vue"

export default {
    name: "CollectionFilterControl",
    components: {
        CollectionFilterControlTypeColour,
        CollectionFilterControlTypeCurrency,
        CollectionFilterControlTypeDate,
        CollectionFilterControlTypeDateDate,
        CollectionFilterControlTypeDateTimeLocal,
        CollectionFilterControlTypeEmail,
        CollectionFilterControlTypeInternalLink,
        CollectionFilterControlTypeNumber,
        CollectionFilterControlTypeRelationship,
        CollectionFilterControlTypeRelationshipManyToMany,
        CollectionFilterControlTypeSelect,
        CollectionFilterControlTypeTelephone,
        CollectionFilterControlTypeText,
        CollectionFilterControlTypeTime,
        CollectionFilterControlTypeUrl
    },
    props: {
        item: Object
    },
    computed: {
        componentName() {
            let type = this.field?.type

            if (this.item.fieldId === 0) {
                type = 'relationshipManyToMany'
            }

            switch(type) {
                case 'checkbox':
                    return ''
                case 'color':
                    return 'CollectionFilterControlTypeColour'
                case 'currency':
                    return 'CollectionFilterControlTypeCurrency'
                case 'date':
                    return 'CollectionFilterControlTypeDate'
                case 'dateDate':
                    return 'CollectionFilterControlTypeDateDate'
                case 'datetime-local':
                    return 'CollectionFilterControlTypeDateTimeLocal'
                case 'email':
                    return 'CollectionFilterControlTypeEmail'
                case 'file':
                    return ''
                case 'image':
                    return ''
                case 'internalLink':
                    return 'CollectionFilterControlTypeInternalLink'
                case 'number':
                    return 'CollectionFilterControlTypeNumber'
                case 'relationshipOneToMany':
                    return 'CollectionFilterControlTypeRelationship'
                case 'relationshipManyToMany':
                    return 'CollectionFilterControlTypeRelationshipManyToMany'
                case 'select':
                    return 'CollectionFilterControlTypeSelect'
                case 'tel':
                    return 'CollectionFilterControlTypeTelephone'
                case 'text':
                case 'texteditor':
                case 'textarea':
                    return 'CollectionFilterControlTypeText'
                case 'time':
                    return 'CollectionFilterControlTypeTime'
                case 'url':
                    return 'CollectionFilterControlTypeUrl'
                default:
                    //console.log(this.field?.type)
            }
        }
    },
    asyncComputed: {
        field() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'modulestructure',
                id: this.item.fieldId
            })
        }
    }
}
</script>

<style scoped>

</style>