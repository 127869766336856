var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      {
        staticClass: "table rounded mb-0 w-auto",
        class: { "draggable-list--hidden": _vm.optionsCopy },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          { ref: "draggableParent" },
          [
            _vm._l(_vm.options, function (option, index) {
              return _c(
                "tr",
                {
                  key: option.id,
                  staticClass: "visible-on-hover",
                  attrs: { draggable: "true" },
                },
                [
                  _c("td", [_c("DragHandle")], 1),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(option.value))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(option.text))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("small", {
                      staticClass: "bi-trash3 visible-on-hover-hidden",
                      on: {
                        click: function ($event) {
                          return _vm.removeOption(index)
                        },
                      },
                    }),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            !_vm.options ? _c("tr", [_vm._m(1)]) : _vm._e(),
          ],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _vm.optionsCopy
      ? _c("table", { staticClass: "table table-condensed mb-0 w-auto" }, [
          _vm._m(2),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.optionsCopy, function (option, index) {
              return _c(
                "tr",
                {
                  key: option.id,
                  class: {
                    "draggable-list--item-drag-active": index === _vm.dragIndex,
                  },
                },
                [
                  _c("td", [_c("DragHandle")], 1),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(option.value))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(option.text))]),
                  _vm._v(" "),
                  _vm._m(3, true),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "mt-1" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.formIsShown,
              expression: "!formIsShown",
            },
          ],
          ref: "addOptionBtn",
          staticClass: "btn btn-light btn-sm",
          attrs: { name: "addOptionBtn" },
          on: {
            click: function ($event) {
              return _vm.showForm()
            },
          },
        },
        [_c("i", { staticClass: "bi-plus-lg" })]
      ),
      _vm._v(" "),
      _vm.formIsShown
        ? _c(
            "form",
            {
              staticClass: "row gy-2 gx-3 align-items-center",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addOption.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.value,
                      expression: "formData.value",
                    },
                    { name: "select", rawName: "v-select" },
                  ],
                  ref: "valueInput",
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.errorValue },
                  attrs: { placeholder: "Value", type: "text" },
                  domProps: { value: _vm.formData.value },
                  on: {
                    keyup: _vm.valueInputKeyup,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "value", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm.errorValue
                    ? _c("span", [_vm._v(_vm._s(_vm.errorValue))])
                    : _vm._e(),
                  _vm._v(" \n                "),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-auto" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.text,
                      expression: "formData.text",
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.errorText },
                  attrs: { placeholder: "Text", type: "text" },
                  domProps: { value: _vm.formData.text },
                  on: {
                    keyup: _vm.textInputKeyup,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "text", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm.errorText
                    ? _c("span", [_vm._v(_vm._s(_vm.errorText))])
                    : _vm._e(),
                  _vm._v(" \n                "),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light btn-sm",
                    attrs: { disabled: _vm.errorValue, type: "submit" },
                  },
                  [_c("i", { staticClass: "bi-plus-lg" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light btn-sm",
                    attrs: { type: "button" },
                    on: { click: _vm.hideForm },
                  },
                  [_c("i", { staticClass: "bi-x-lg" })]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Value")]),
        _vm._v(" "),
        _c("th", [_vm._v("Text")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-center", attrs: { colspan: "4" } }, [
      _c("em", [_vm._v("none")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Value")]),
        _vm._v(" "),
        _c("th", [_vm._v("Text")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("small", { staticClass: "bi-trash3 visible-on-hover-hidden" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }