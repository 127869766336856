<template>
    <pre v-if="show"><small><slot/></small></pre>
</template>

<script>
export default {
    name: "Dump",
    data() {
        return {
            show: false,
        }
    },
    created() {
        if (parseInt(localStorage.getItem('showOutput'))) {
            this.show = true
        }
    }
}
</script>

<style scoped>

</style>