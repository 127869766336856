<template>
    <div ref="themesEditorTabs" class="d-flex align-items-center py-1">
        <ThemesEditorTab v-for="(editor, index) in editors" :key="index" :editor="editor" :index="index"/>
    </div>
</template>

<script>
import ThemesEditorTab from './ThemesEditorTab'

export default {
    name: "ThemesEditorTabs",
    components: {
        ThemesEditorTab,
    },
    computed: {
        activeEditorKey() {
            return this.$store.state.themes.activeEditorKey
        },
        editors() {
            return this.$store.state.themes.editors
        },
    },
    watch: {
        // When a tab becomes active, scroll it into view
        activeEditorKey(val) {
            return

            // Removing the last tab sets the key to -1
            if (val >= 0) {
                // Required to allow Vue time to render the new tab.
                setTimeout(() => {
                    let tabsBox = this.$refs.themesEditorTabs

                    let container = tabsBox.closest('[data-themes-editor-toolbar]')
                    let containerRect = container.getBoundingClientRect()
                    // The container's position doesn't change. It's the box which the scrollable elements sit in.
                    let containerLeft = containerRect.left
                    let containerRight = containerRect.right

                    let nodes = tabsBox.querySelectorAll('.themes-editor-tab')
                    let els = Array.from(nodes)
                    let activeTab = els[val]
                    let activeTabRect = activeTab.getBoundingClientRect()
                    let activeTabLeft = activeTabRect.left
                    let activeTabRight = activeTabRect.right

                    if (activeTabRight > containerRight) {
                        // Calculate the distance to the right of the visible toolbar that the tab sits.
                        let dist = Math.abs(containerRight - activeTabRight)
                        // And add it to the current scroll distance.
                        container.scrollLeft = container.scrollLeft + dist
                    }
                    // If the tab's left is less than the container's left then it is scrolled out of view.
                    else if (activeTabLeft < containerLeft) {
                        // The no. of pixels the tab's left is scrolled out of view.
                        let dist = Math.abs(containerLeft - activeTabLeft)
                        // Subtracting from the scrollLeft brings the tab's left inline with the container's left.
                        container.scrollLeft = container.scrollLeft - dist
                    }
                }, 50)
            }
        }
    }
}
</script>

<style scoped>

</style>