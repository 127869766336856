<template>
    <span v-if="value">{{ date }}</span>
</template>

<script>
import moment from 'moment'

export default {
    name: "TableCellTypeDate",
    props: {
        column: Object,
        value: Number,
    },
    computed: {
        date() {
            // todo - Hardcoded conversion of AngularJS date format to Moment.
            //  AngularJS https://docs.angularjs.org/api/ng/filter/date
            //  Moment https://momentjs.com/docs/#/displaying/
            //  Once all sites are upgraded to Vue I should run an upgrade script to convert the formats.
            let format
            switch (this.column.dateFormat) {
                case 'dd MMM yyyy':
                    format = 'DD MMM YYYY'
                    break
                case 'dd MMM yyyy, HH:mm':
                default:
                    format = 'DD MMM YYYY, HH:mm'
            }

            return moment(this.value * 1000).format(format)
        }
    }
}
</script>

<style scoped>

</style>