var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("p", [
    _vm.field.readonly
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentData[_vm.field.name],
              expression: "currentData[field.name]",
            },
          ],
          staticClass: "form-control-type-color",
          attrs: { disabled: "", type: "color" },
          domProps: { value: _vm.currentData[_vm.field.name] },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.currentData, _vm.field.name, $event.target.value)
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentData[_vm.field.name],
              expression: "currentData[field.name]",
            },
            {
              name: "select",
              rawName: "v-select",
              value: _vm.field.autofocus,
              expression: "field.autofocus",
            },
            { name: "form-validation", rawName: "v-form-validation" },
          ],
          staticClass: "form-control-type-color",
          attrs: {
            id: _vm.field.id,
            disabled: _vm.field.disabled,
            name: _vm.field.name,
            required: _vm.field.required,
            autocomplete: "off",
            type: "color",
          },
          domProps: { value: _vm.currentData[_vm.field.name] },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.currentData, _vm.field.name, $event.target.value)
            },
          },
        }),
    _vm._v("\n\n    " + _vm._s(_vm.currentData[_vm.field.name]) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }