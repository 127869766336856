<template>
    <div class="modal-dialog modal-lg site-tree-modal">
        <div class="modal-content">
            <div class="modal-header">
                <SiteTreeNavigationMenu :site-tree-id="siteTreeId" class="w-auto"/>

                <button aria-label="Close" class="btn-close" type="button" @click="$emit('hide-modal')"></button>
            </div>

            <div class="modal-body">
                <SiteTree :select-content="selectContent" :select-page="selectPage"
                          :selected-page-ids="selectedPageIds" :show-content="showContent" :site-tree-id="siteTreeId"/>
            </div>

            <div class="modal-footer">
                <form v-if="selectedPageIds" class="d-flex align-items-center" @submit.prevent="saveSelectedPageIds">
                    <select v-model="selectedContentArea" class="form-select">
                        <option v-for="option in contentAreas" :value="option.value">
                            {{ option.text }}
                        </option>
                    </select>

                    <button :class="{ 'btn-light': isSaveBtnDisabled, 'btn-warning': !isSaveBtnDisabled }"
                            :disabled="isSaveBtnDisabled" class="ms-1 btn">
                        Save
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import siteTree from '../../store/modules/siteTree'

import SiteTreeNavigationMenu from '../../common/site-tree/SiteTreeNavigationMenu'
import SiteTree from '../../common/site-tree/SiteTree'

export default {
    name: "SiteTreeModal",
    components: {
        SiteTreeNavigationMenu,
        SiteTree,
    },
    props: {
        options: Object,
    },
    data() {
        return {
            siteTreeId: undefined,
            linkOptions: this.options.linkOptions,
            // todo - This doesn't work, because it cannot detect the pageContentId from a contentId.
            //  For example, click the ShowSiteTreeBtn when editing content doesn't allow the modal to know which
            //  specific content of what page was clicked.
            //contentId: this.options.contentId,
            pageContentLinkId: this.options.pageContentLinkId,
            pageId: this.options.pageId,
            propagatedContentId: this.options.propagatedContentId,
            selectedPageIds: this.options.selectedPageIds,
            onSelect: this.options.onSelect,
            onSave: this.options.onSave,
            newSelectedPageIds: [],
            contentAreas: [],
            selectedContentArea: null,
        }
    },
    computed: {
        isSaveBtnDisabled() {
            // The save button is disabled when selectPageIds has not changed.
            return (
                JSON.stringify(this.newSelectedPageIds) === JSON.stringify(this.selectedPageIds)
                || !this.selectedContentArea
            )
        },
        showContent() {
            if (this.linkOptions) {
                return this.linkOptions.indexOf('c') > -1
            }

            return !(
                this.$store.state.betaMode
                && window.location.hash.split('/')[1] === 'site_tree'
            )
        },
        pageContentLinkItem() {
            if (this.pageContentLinkId) {
                return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentLinkId)
            }
        }
    },
    created() {
        this.siteTreeId = 'siteTreeModal'
        this.$store.registerModule(this.siteTreeId, siteTree)
        this.$store.dispatch(this.siteTreeId + '/init')

        this.setSelectedVars()
        this.initSelectedPageIds()
    },
    destroyed() {
        this.$store.unregisterModule(this.siteTreeId)
    },
    methods: {
        setSelectedVars() {
            if (this.pageId) {
                this.$store.dispatch(this.siteTreeId + '/setPageId', this.pageId)
                this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', this.pageId)
            } else if (this.pageContentLinkId) {
                this.$store.dispatch(this.siteTreeId + '/setContentId', this.pageContentLinkId)
                let pageId = this.pageContentLinkItem.pageId
                this.$store.commit(this.siteTreeId + '/addExpandedPageId', pageId)
                this.$store.dispatch(this.siteTreeId + '/setExpandedPageIds', pageId)
            } else if (this.propagatedContentId) {
                const items = this.$store.state.pageContentData.items.filter((obj) => {
                    return obj.contentId === this.propagatedContentId
                })
                this.$store.commit('propagateContent/propagatedContentData', items)
            }
        },
        initSelectedPageIds() {
            if (this.selectedPageIds) {

                // We compare the arrays to see if they're modified so to do so the values must be in the same order.
                this.selectedPageIds.sort()

                this.newSelectedPageIds = JSON.parse(JSON.stringify(this.selectedPageIds))

                this.$store.dispatch('pageTemplatesContentAreas/getAllContentAreas')
                    .then((contentAreas) => {
                        this.selectedContentArea = contentAreas[0]
                        let options = [{
                            value: null,
                            text: 'Content area…'
                        }]
                        contentAreas.forEach((contentArea) => {
                            options.push({
                                value: contentArea,
                                text: contentArea
                            })
                        })
                        this.contentAreas = options
                    })
            }
        },
        selectPage(pageId) {
            if (this.onSelect) {
                this.onSelect({
                    pageId: pageId
                })

                this.$emit('hide-modal')
            }
        },
        selectContent(contentId) {
            const pageContentLinkItem = this.$store.state.pageContentData.items.find(o => o.id === contentId)

            this.onSelect({
                pageContentLinkId: contentId,
                contentId: pageContentLinkItem.contentId
            })

            this.$emit('hide-modal')
        },
        saveSelectedPageIds() {
            this.onSave({
                contentArea: this.selectedContentArea,
                selectedPageIds: this.selectedPageIds
            })

            this.$emit('hide-modal')
        }
    }
}
</script>

<style scoped>

</style>