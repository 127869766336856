<template>
    <Button class="rounded-0" @click.native="openModal" :active-class="'btn-warning'" :active-value="isFilterActive">
        <i class="bi-search"/>
    </Button>
</template>

<script>
import Button from "../Button.vue"

export default {
    name: "ListingOptionsSearchModalBtn",
    components: {Button},
    props: {
        viewId: String,
    },
    computed: {
        isFilterActive() {
            return !!this.$store.getters[this.viewId + '/isFilterActive']()
        }
    },
    methods: {
        openModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'SearchModal',
                obj: {
                    viewId: this.viewId,
                }
            })
        }
    },
}
</script>

<style scoped>

</style>