<template>
    <div class="d-flex align-items-center">
        <Button class="me-2" @click.native="internalLink">
            Browse…
        </Button>

        <p v-if="pageId || contentId" class="well m-0 p-1 text-nowrap">
            <PagePath :page-id="pageId" :page-content-id="contentId"/>

            <Button v-if="!removeDisabledBtn" class="btn-sm" @click.native="removeInternalLink">
                <i class="bi-x-lg"/>
            </Button>
        </p>
    </div>
</template>

<script>
import FormControlTypeText from './form-control-type/FormControlTypeText'
import PagePath from '../common/PagePath'
import Button from "./Button.vue"

export default {
    name: "InternalLinkBtn",
    components: {
        Button,
        FormControlTypeText,
        PagePath
    },
    props: {
        formId: String,
        field: Object,
        id: [String, Number],
        linkOptions: {
            type: String,
            default: 'ietpc',
        },
        onChange: Function,
        removeDisabledBtn: Boolean,
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        pageId() {
            this.cleanId()

            return this.isContentId(this.id)
                ? null
                : parseInt(this.id)
        },
        contentId() {
            this.cleanId()

            if (this.isContentId(this.id)) {
                const idStr = '' + this.id
                let id = idStr.indexOf('c') === 0 ? this.id.substr(1) : this.id
                return parseInt(id)
            }

            return null
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    methods: {
        cleanId() {
            if (this.id) {
                let isString = typeof this.id === 'string'
                let isObject = typeof this.id === 'object'

                // todo - This is support for the miscellaneous ways in which the internal
                // link data is saved. The internal link button should now always save
                // as an object, or an ID (to be confirmed).
                // Converts {"internal":"1"} to object
                if (
                    isString
                    && this.id.indexOf('{') > -1
                ) {
                    this.id = JSON.parse(this.id)
                }

                // Converts {internal:x} to x
                if (isObject) {
                    this.id = this.id.internal
                }
            }
        },
        isContentId(id) {
            return (
                typeof id === 'string'
                && id.indexOf('c') === 0
                // Or the link is for content only
                || (
                    this.linkOptions.indexOf('c') >= 0
                    && this.linkOptions.indexOf('p') === -1
                )
            )
        },
        internalLink() {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    linkOptions: this.linkOptions,
                    pageId: this.pageId,
                    pageContentLinkId: this.contentId,
                    onSelect: this.onSelect
                }
            })
        },
        onSelect(obj) {

            // Do nothing if page selection is not enabled and the selected item is a page
            if (
                obj.pageId
                && this.linkOptions.indexOf('p') < 0
            ) {
                console.log('Page selection is disabled for this popup')
                return false
            }

            // Custom field validation

            // The page component's parent option
            if (
                this.tableName === 'pages'
                && this.field.name === 'parent'
            ) {
                if (obj.pageId === this.currentData.id) {
                    this.$store.dispatch('toasts/add', {
                        body: 'A page cannot be the parent of itself.'
                    })
                    return false
                }

                let selectedPageId = obj.pageId

                // A category cannot be the child of its own descendant.
                // Recursively check the targetPageId's ancestors to make sure the this.currentData.id isn't found.
                let checkAncestors = (formPageId, pageId) => {
                    this.$store.dispatch('itemData/get', {
                            tableName: 'pages',
                            id: pageId
                        })
                        .then((obj) => {
                            // If the targeted page has a parent then ensure that it's not the page being edited.
                            if (obj.parent === formPageId) {
                                this.$store.dispatch('toasts/add', {
                                    body: 'A page cannot be the child of its descendant.'
                                })
                                // If not then we need to check the parent's parent, and so forth.
                            } else if (obj.parent) {
                                checkAncestors(formPageId, obj.parent)
                            } else {
                                this.onSuccess(selectedPageId)
                            }
                        })
                }

                checkAncestors(this.currentData.id, obj.pageId)
                return false
            }

            let newId
            if (obj.pageId) {
                newId = obj.pageId
            } else if (obj.pageContentLinkId) {
                newId = obj.pageContentLinkId
            }

            this.onSuccess(newId)
        },
        onSuccess(newId) {
            if (this.onChange) {
                this.onChange({
                    id: newId
                })
            }
        },
        removeInternalLink() {
            this.onChange({
                id: 0
            })
        },
    }
}
</script>

<style scoped>

</style>