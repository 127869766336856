import { render, staticRenderFns } from "./FormControlMRegistrantsRegistrantsLogin.vue?vue&type=template&id=b4bf575c&scoped=true&"
import script from "./FormControlMRegistrantsRegistrantsLogin.vue?vue&type=script&lang=js&"
export * from "./FormControlMRegistrantsRegistrantsLogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4bf575c",
  null
  
)

export default component.exports