<template>
    <span class="dropdown">
        <button class="btn btn-light" data-bs-toggle="dropdown" type="button">
            {{ title }}
        </button>
        <ul class="dropdown-menu">
            <li v-for="column in columns" :key="column.id" @click="setColumn(column.columnName)">
                <a :class="{'active': column.columnName === columnName}" class="dropdown-item">
                    {{ column.title }}
                </a>
            </li>
        </ul>
    </span>
</template>

<script>
export default {
    name: "SearchFilterColumnMenu",
    props: {
        listingName: String,
        filter: Object,
        columns: Array,
        setFilterItems: Function,
        viewId: String,
    },
    data() {
        return {
            tableName: '',
            title: '',
        }
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        columnName() {
            return this.filter.filterData.columnName
        }
    },
    created() {
        this.tableName = this.listingName.split('.')[0]

        this.setColumnTitle(this.filter.filterData.columnName)
    },
    methods: {
        setColumn(columnName) {
            // Unset the previously set value, otherwise it might conflict with the new field's props.
            // For example, the relationshipOneToMany field's component expects a String value.
            this.filters.advancedFilter[this.filter.id] = undefined

            let filterData = this.filter.filterData

            filterData.columnName = columnName
            filterData.condition = false

            let columns = this.columns.filter((column) => {
                return column.columnName === columnName
            })

            if (columns) {
                let column = columns[0]
                let options = this.$store.getters['advancedFilters/typesOptions'](column.type)

                filterData.condition = options[0].condition
                filterData.conditionText = options[0].text
            }

            this.$store.dispatch('advancedFilters/save', {
                    tableName: this.tableName,
                    id: this.filter.id,
                    filterData: filterData,
                })
                .then(() => {
                    this.setFilterItems(true)
                })

            this.setColumnTitle(columnName)
        },
        setColumnTitle(columnName) {
            let columns = this.columns.filter((column) => {
                return column.columnName === columnName
            })
            if (columns) {
                this.title = columns[0].title
            }
        }
    }
}
</script>

<style scoped>

</style>