<template>
    <form @submit.prevent>
        <div v-if="items" class="mb-2 list-group">
            <div class="list-group-item px-2" v-for="item in items" :key="item.id">
                <CollectionFilter :content-id="contentId" :item="item"/>
            </div>
        </div>

        <button type="button" class="btn btn-light" @click="addFilter">
            <i class="bi-plus-lg"></i>
        </button>
    </form>
</template>

<script>
import CollectionFilter from "../collection-filter/CollectionFilter.vue"

export default {
    name: "FormControlTypeCollectionFilters",
    components: {CollectionFilter},
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    computed: {
        contentId() {
            return this.currentData.id
        },
        items() {
            return this.$store.state.collectionFilters.data[this.contentId]
        }
    },
    created() {
        this.init()
    },
    methods: {
        addFilter() {
            this.$store.dispatch('collectionFilters/addFilter', {
                contentId: this.contentId
            })
        },
        init() {
            this.$store.dispatch('collectionFilters/init', {
                contentId: this.contentId,
                refresh: true
            })
        }
    }
}
</script>

<style scoped>

</style>